<template>
  <!--  这里是结构模块区域组件-->
  <div class="StructureMajor" style="margin-top: 30px">
    <span class="bottom-span" style="padding-left: 20px">{{ im.DicName }}</span>
    <!--                给水管径-->
    <div style="margin-top: 20px">
      <div style="display: flex" v-if="im.children.length > 0">
        <div
          v-if="
            item.DicKey === 'LiveLoadOnTheBottomPlateSurface' ||
            item.DicKey === 'ConstantLoadOnTheBottomPlateSurface'
          "
          class="flex flex5"
          style=""
          v-for="(item, index) in im.children"
          :key="index"
        >
          <div class="flex5 structure-left background">{{ item.DicName }}</div>
          <div
            v-if="item.DicKey === 'LiveLoadOnTheBottomPlateSurface'"
            class="flex5 structure-right background"
          >
            {{ entity.LiveLoadOnTheBottomPlateSurface }}
          </div>
          <div
            v-if="item.DicKey === 'ConstantLoadOnTheBottomPlateSurface'"
            class="flex5 structure-right background"
          >
            {{ entity.ConstantLoadOnTheBottomPlateSurface }}
          </div>
        </div>
      </div>

      <div style="display: flex" v-if="im.children.length > 0">
        <div
          v-if="
            item.DicKey === 'KitchenPanelLiveLoad' ||
            item.DicKey === 'ConstantLoadOnKitchenPanelSurface'
          "
          class="flex flex5"
          style=""
          v-for="(item, index) in im.children"
          :key="index"
        >
          <div class="flex5 structure-left background">{{ item.DicName }}</div>
          <div
            v-if="item.DicKey === 'KitchenPanelLiveLoad'"
            class="flex5 structure-right background"
          >
            {{ entity.KitchenPanelLiveLoad }}
          </div>
          <div
            v-if="item.DicKey === 'ConstantLoadOnKitchenPanelSurface'"
            class="flex5 structure-right background"
          >
            {{ entity.ConstantLoadOnKitchenPanelSurface }}
          </div>
        </div>
      </div>

      <div style="display: flex" v-if="im.children.length > 0">
        <div
          v-if="item.DicKey === 'LoweringPlateHeight'"
          class="flex flex5"
          style=""
          v-for="(item, index) in im.children"
          :key="index"
        >
          <div class="flex5 structure-left background">{{ item.DicName }}</div>
          <div class="flex5 structure-right background">{{ entity.LoweringPlateHeight }}</div>
        </div>
        <!--        用备注站位布局 后续需要可以打开-->
        <div
          v-if="item.DicKey === 'RemarkStructure'"
          class="flex flex5"
          style=""
          v-for="(item, index) in im.children"
          :key="index"
        >
          <div class="flex5 structure-left background"></div>
          <div class="flex5 structure-right background"></div>
        </div>
      </div>

      <!--    备注-->
      <div class="flex">
        <div style="flex: 0.9" class="structure-left background">备注</div>
        <div style="flex: 9.1" class="structure-right background">
          {{ entity.RemarkStructure }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
  name: "StructureMajor.vue",
  data() {
    // 这里存放数据
    return {};
  },
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    updateRecordType: {
      type: String,
    },
    parentObj: {
      type: Object,
      default: () => {},
    },
    im: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  // 方法集合
  methods: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  //过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {},
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  //进入的时候触发
  activated() {},
  //离开的时候触发
  deactivated() {},
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
.border-top {
  border-top: 1px solid #ccc;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}
.border-left {
  border-left: 1px solid #ccc;
}
.border-right {
  border-right: 1px solid #ccc;
}
.StructureMajor {
  .structure-left.background {
    background-color: #f7eded;
    border: none !important;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .structure-right.background {
    background-color: #ffe3e3;
    border: none !important;
    padding-left: 10px;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
