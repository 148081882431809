
<template>
<!-- // 图片预览组件 -->
  <el-image-viewer
    v-if="show"
    :on-close="handleClose"
    :url-list="urlList"
    :z-index="zIndex"
  />
</template>

<script>
import { Image } from 'element-ui'

export default {
  name: 'WtImageViewer',
  components: {
    ElImageViewer: Image.components.ImageViewer
  },
  props: {
    urlList: {
      type: Array,
      default: () => []
    },
    zIndex: {
      type: Number,
      default: 2000
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>