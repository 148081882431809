<!-- 新建组件 -->
<template>
  <div class="AddDetail">
    <el-dialog
      :title="title"
      :show-close="false"
      :visible.sync="dialogVisible"
      width="95%"
      :fullscreen="fullscreen"
      :before-close="handleClose"
      custom-class="import-dialog"
    >
      <div style="padding: 0 10px 0 0; display: flex">
        <!-- :style="{height:windowHeight+'px'}" -->
        <div class="content">
          <div>
            <!-- 商铺基础信息 -->
            <ShopBaseInfoAdd
              ref="ShopBaseInfoAdd"
              :parentObj="{ ...this }"
              @ShopFormatClassChange="ShopFormatClassChange"
              @closeDialog="handleClose"
            />

            <!-- 设计信息 -->
            <!-- <DesignInfoJson v-if="rowTitle==='设计信息'" ref="DesignInfoJson" :parentObj="{ ...this }" /> -->

            <!-- 工程信息 -->
            <!-- <EngineeringInfoJson v-if="rowTitle==='工程信息'" ref="EngineeringInfoJson" :parentObj="{ ...this }" /> -->

            <!-- 招商信息 -->
            <!-- <ContractInfoJson v-if="rowTitle==='招商信息'" ref="ContractInfoJson" :parentObj="{ ...this }" /> -->
          </div>
          <!-- <div class="right">
            <Log ref="Log"  :Search="logSearchParams"  />
          </div> -->
        </div>
        <div
          style="
            display: flex;
            width: 80px;
            top: 18px;
            position: absolute;
            right: 15px;
            justify-content: space-between;
          "
        >
          <i
            class="el-icon-full-screen"
            style="
              font-size: 30px;
              cursor: pointer;
              font-weight: bold;
              margin-top: 2px;
            "
            @click="
              () => {
                fullscreen = !fullscreen;
              }
            "
          ></i>
          <img
            @click="handleClose"
            style="cursor: pointer; width: 33px"
            src="../../../assets/delete.png"
            alt=""
          />
          <!-- <i
            class="el-icon-close"
            @click="handleClose"
            style="font-size: 30px; cursor: pointer; font-weight: bold"
          ></i> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 商铺基础信息
import ShopBaseInfoAdd from "./form/ShopBaseInfoAdd.vue";
// import DesignInfoJson from './form/DesignInfoJson.vue';
// import EngineeringInfoJson from './form/EngineeringInfoJson.vue';
// import ContractInfoJson from './form/ContractInfoJson.vue';
export default {
  name: "WorkspaceJsonEditDetail",
  props: {
    parentObj: {
      type: Object,
    },
  },
  components: {
    ShopBaseInfoAdd,
    // DesignInfoJson,
    // EngineeringInfoJson,
    // ContractInfoJson,
  },

  data() {
    return {
      // 首页传递过来的相关项目，任务应用id对象
      newTaskObj: {},
      windowHeight: 0,
      fullscreen: false,
      // 弹窗标题
      title: "",
      // 是否显示弹窗
      dialogVisible: false,
      rules: {},
      form: {},
      childrenRow: {},
      // 默认获取业态名称下拉数据
      ShopFormatClassQueryParams: {
        Condition: "DicKey",
        Keyword: "FormatClass",
      },
      queryParams: {
        Search: {
          Condition: "ProjectId",
          Keyword: "",
        },
      },
      // 日志组件传递过来的获取日志列表传递的search参数
      logSearchParams: [
        {
          Condition: "ModuleType",
          Keyword: "",
        },
        {
          Condition: "ShopId", //当前项目当前商铺下的当前模块下的日志
          Keyword: "",
        },
      ],
      rowTitle: "",
    };
  },

  mounted() {},
  watch: {
    // 监听传递过来的图片数据  不要卸载created里面  否则会出现无数据的情况
    windowHeight(val) {
      // console.log('监听到的高度', val)
    },
  },

  methods: {
    //取消弹窗
    handleClose(done) {
      // console.log("父组件addDetail关闭弹窗", done);
      this.dialogVisible = false;
      if (done == true) {
        // 说明此时需要刷新表格数据
        this.$emit("handleCloseResetTableChange");
      }
      this.$refs.ShopBaseInfoAdd.ShopFormatNameOptions = [];
    },
    //弹窗确定
    dialogVisibleYes() {},

    // 传递过来的子组件业态级别下拉选择方法
    ShopFormatClassChange(e) {
      // 获取对应业态级别下的业态名称下拉数据
      this.getShopNameListData();
      // 也太级别下拉参数默认初始化。 否则第二次打开弹窗时候。也太级别和业态名称会混淆
      this.ShopFormatClassQueryParams = {
        Condition: "DicKey",
        Keyword: "FormatClass",
      };
    },
    // 获取楼栋信息
    getBuildingListData() {
      this.$http
        .post(
          "/Base_ProjectManage/Project_Building/GetDataList",
          this.queryParams
        )
        .then((res) => {
          if (res.Success) {
            // 判断是否存在楼栋信息 进行维护楼栋信息
            if (res.Data.length == 0) {
              this.$message.error("没有楼栋信息, 请先去维护楼栋信息");
              return;
            }
            let buildingList = res.Data.map((item) => {
              return {
                value: item.Id,
                // 楼栋编号
                label: item.BuildingCode,
              };
            });
            this.$nextTick(function () {
              // // 赋值楼栋下拉选择数据
              this.$refs.ShopBaseInfoAdd.buildingOptions = buildingList;
              // 默认先赋值业态级别下拉。避免第一次进去没有下拉数据****************************************
              this.$refs.ShopBaseInfoAdd.openSelectOptions("楼栋");
            });
          } else {
            this.entity.ProjectImg = "";
            this.$message.error(res.Msg);
          }
        });
    },
    // 获取级别相关下拉数据
    getFormatClassListData() {
      this.$http
        .post(
          "/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey",
          this.ShopFormatClassQueryParams
        )
        .then((res) => {
          if (res.Success) {
            let ShopFormatClassOptions = res.Data.DictionaryDetailsList.map(
              (item) => {
                return {
                  value: item.DicValue,
                  label: item.DicName,
                };
              }
            );

            this.$nextTick(function () {
              // // 赋值业态名称下拉选择数据
              this.$refs.ShopBaseInfoAdd.ShopFormatClassOptions =
                ShopFormatClassOptions;
              // 默认先赋值业态级别下拉。避免第一次进去没有下拉数据****************************************
              this.$refs.ShopBaseInfoAdd.openSelectOptions("业态级别");
            });
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 获取业态名称下拉相关数据
    // 获取业态名称相关下拉数据
    getShopNameListData() {
      this.$http
        .post(
          "/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey",
          this.ShopFormatClassQueryParams
        )
        .then((res) => {
          if (res.Success) {
            // 判断数组哟没有
            if (res.Data.DictionaryDetailsList == null) return;
            let ShopFormatNameOptions = res.Data.DictionaryDetailsList.map(
              (item) => {
                return {
                  value: item.Id,
                  label: item.DicName,
                };
              }
            );
            // 赋值业态名称下拉选择数据
            this.$nextTick(function () {
              // // 赋值业态名称下拉选择数据
              this.$refs.ShopBaseInfoAdd.ShopFormatNameOptions =
                ShopFormatNameOptions;
              // 默认先赋值业态类型下拉。避免第一次进去没有下拉数据****************************************
              this.$refs.ShopBaseInfoAdd.openSelectOptions("业态类型");
            });
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    init(obj) {
      // 弹窗
      this.dialogVisible = true;
      // 获取业态级别
      this.getFormatClassListData();
      // 获取楼栋列表数据
      this.getBuildingListData();
      // 清空商铺基础信息form表单
      this.$nextTick(function () {
        this.$refs.ShopBaseInfoAdd.resetForm();
        // 项目id
        this.$refs.ShopBaseInfoAdd.ProjectId = obj.ProjectId;
        // 任务id
        this.$refs.ShopBaseInfoAdd.TaskId = obj.TaskId;
        // 禁止相关输入框默认初始化
        // 任务id
        this.$refs.ShopBaseInfoAdd.IsOnGroundDisabled = true;
        // 默认禁用业态名称输入框
        this.$refs.ShopBaseInfoAdd.ShopFormatNameDisabled = true;
        // 默认商铺编号禁止输入
        this.$refs.ShopBaseInfoAdd.ShopCodeDisabled = false;

        this.$refs.ShopBaseInfoAdd.StoreyDisabled = true;
        // 表单初始化
        this.$refs.ShopBaseInfoAdd.resetForm();
      });
    },
    // 打开弹窗
    open(obj, newTaskObj) {
      this.title = obj.title + "商铺基础信息";
      this.newTaskObj = newTaskObj;
      // 赋值项目id
      this.queryParams.Search.Keyword = obj.ProjectId;
      this.$nextTick(function () {
        this.$refs.ShopBaseInfoAdd.newTaskObj = newTaskObj;
        // 清空表单校验
        this.$refs.ShopBaseInfoAdd.clearValidate();
      });
      // 显示弹窗
      this.init(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: -1px !important;
}

::v-deep .el-dialog {
  /* //弹出层的高度防止鼠标放大缩小屏幕都会出现弹窗右侧本身的滚动条问题************************************** */
  margin: 0 !important;
  /* // //弹出层里内容的高度
  // .el-dialog__body {
  // 	padding: 18px 20px !important;
  // 	// max-height: 50vh !important;
  // } */
}

::v-deep .el-dialog__wrapper {
  /* //弹出层的高度防止鼠标放大缩小屏幕都会出现弹窗右侧本身的滚动条问题************************************** */
  display: flex;
  justify-content: center;
  align-items: center;

  .el-dialog.import-dialog {
    margin-top: 0 !important;

    /* //覆盖element 弹窗dialog关闭按钮的样式 */
    .el-dialog__header {
      padding-bottom: 20px;
      .el-dialog__title {
        font-size: 25px !important;
      }
      .el-dialog__headerbtn {
        font-size: 35px;
      }
    }

    .el-dialog__body {
      div {
        &:first-child {
          /* border-top: 0 !important; */
        }
      }

      padding-top: 0px !important;
    }
  }
}

/*覆盖element弹窗的相关距离
  ::v-deep .el-dialog__wrapper{
  .el-dialog{
    margin-top: 6vh !important;
  }
  .el-dialog.import-dialog{
    height:88vh !important;
    max-height:88vh;
  }
  .el-dialog__footer{
    padding: 40px 20px 20px !important;
  }
 

}
  
*/
</style>
