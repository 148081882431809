<template>
  <div>

    <el-dialog :visible.sync="Visible" width="95%" append-to-body :show-close="false" top="2.5vh"
      :close-on-click-modal="false" :close-on-press-escape="false" :fullscreen="fullscreen"
      :custom-class="fullscreen ? '' : 'scheme-stage-main-edit'">
      <div slot="title">
        <el-row style="height: 33px;text-align: left;font-weight: bold;font-size: 18px;">
          {{ title }}

          <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;float: right;"
            src="@/assets/delete.png" alt="" @click="close" />
          <i :class="!fullscreen ? 'el-icon-full-screen' : 'el-icon-copy-document'"
            style="float: right;font-size: 33px;width: 33px;height: 33px;margin-right: 10px;cursor: pointer;"
            @click="fullscreen = !fullscreen"></i>
        </el-row>
      </div>
      <div class="content-Edit" :style="fullscreen ? '' : 'height: calc(95vh - 103px);'">
        <div class="left">
          <div class="left-top" v-loading="formLoading">
            <el-form :model="headForm" inline size="mini" :rules="headFormRules" ref="headForm">
              <el-form-item label="变配电室编号：" prop="TDRCode">
                <el-input v-model="headForm.TDRCode" style="max-width: 120px;"></el-input>
              </el-form-item>
              <el-form-item label="变配电室名称：" prop="TDRName">
                <el-input v-model="headForm.TDRName" style="max-width: 120px;"></el-input>
              </el-form-item>
              <el-form-item label="建筑面积(㎡)：" prop="BuildingArea">
                <el-input v-model="headForm.BuildingArea" oninput="value=value.replace(/[^0-9.]/g,'')"
                  style="max-width: 120px;"></el-input>
              </el-form-item>
              <el-form-item label="服务范围：" prop="ServiceScope">
                <el-cascader :options="BuildingList" v-model="headForm.ServiceScope" :props="{ multiple: true }"
                  collapse-tags @change="changeLouList" ref="cascader" placeholder="请选择" style="max-width: 180px;"
                  class="hide-clear-icon" popper-class="cascader-popper"></el-cascader>
              </el-form-item>
              <el-form-item label="经营模式：" prop="ManagementModel">
                <el-select v-model="headForm.ManagementModel" placeholder="请选择" style="max-width: 120px;" filterable
                  allow-create @change="changeManagementModel" default-first-option>
                  <el-option v-for="item in ManagementModelList" :key="item.DicName" :label="item.DicName"
                    :value="item.DicValue">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button style="width: 80px;" type="primary" @click="saveForm">保存</el-button>
              </el-form-item>
            </el-form>
            <el-divider></el-divider>
          </div>
          <div class="left-center" v-loading="formLoading">
            <div class=" form-item">
              <div class="form-left">
                <!-- :rules="FormDataRules" -->
                <div style="height: 165px;overflow-y: auto;">
                  <el-form :model="FormData" size="mini" style="width: calc(100% - 100px);" ref="FormData">
                    <div style="box-sizing: border-box;display: flex;justify-content: space-between;">
                      <div style="width: 49%;">
                        <el-form-item label="同时使用系数：" label-width="110px" prop="E_SUC">
                          <el-input v-model="FormData.E_SUC" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                        </el-form-item>
                      </div>
                      <div style="width: 49%;">
                        <el-form-item label="低压侧补偿后功率因数：" label-width="160px" prop="E_FALVSC">
                          <el-input v-model="FormData.E_FALVSC" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                        </el-form-item>
                      </div>
                    </div>
                  </el-form>
                  <!-- :rules="FormDataRules" -->
                  <div v-for="(item, index) in FormDataList" :key="index" style="margin-top: 5px;">
                    <el-form :model="item" size="mini" label-position="right" label-width="100px"
                      style="position: relative;width: calc(100% - 100px);" ref="FormDataList">
                      <div style="box-sizing: border-box;display: flex;justify-content: space-between;">
                        <div style="width: 49%;">
                          <el-form-item label="变压器容量：" prop="TransformerCapacity">
                            <el-input v-model="item.TransformerCapacity"
                              oninput="value=value.replace(/[^0-9.]/g,'')"><template
                                slot="append">（kVA/台）</template></el-input>
                          </el-form-item>
                        </div>
                        <div style="width: 49%">
                          <div>
                            <el-form-item label="变压器台数：" prop="TransformerCount">
                              <el-input-number v-model="item.TransformerCount" type="number" :min="0" :max="10"
                                style="width: 100%;" :step="1">
                              </el-input-number>
                            </el-form-item>
                          </div>
                        </div>
                      </div>
                      <div
                        style="position: absolute;right: -100px;top: 0;display: flex;align-items: center;justify-content: center;width: 100px;height: 30px;">

                        <el-button icon="el-icon-plus" type="primary" size="mini" circle style="font-size: 16px;"
                          @click="FromDataPush(index)"></el-button>
                        <el-button icon="el-icon-delete" type="primary" size="mini" circle style="font-size: 16px;"
                          @click="FormDataList.splice(index, 1)" :disabled="FormDataList.length == 1"></el-button>
                      </div>
                    </el-form>

                  </div>
                </div>

              </div>
              <div class="form-right">
                <div style="padding: 0px 10px;width: 100%;">
                  <EchartsIndex ref="Echarts3" :option="EchartsForm" styleStr="width: 100%; height: 155px"
                    v-if="EchartsFormShow">
                  </EchartsIndex>
                </div>
              </div>
            </div>
            <el-divider></el-divider>
          </div>


          <div class="left-table" :style="fullscreen ? 'height: calc(100vh - 340px);' : 'height: calc(93vh - 340px);'">
            <el-row style="margin-bottom: 5px;display: flex;align-items: center;">
              <div style="display: inline-block;line-height: 32px;width: 370px;display: flex;">


                <el-popover placement="top" trigger="click" v-model="showPop">
                  <div style="text-align: center;">
                    <div style="font-weight: bold;font-size: 14px;margin-bottom: 10px;">请输入追加条目</div>
                    <div><el-input-number v-model="copyNum" :min="1" :max="100" :precision="0" label="描述文字"
                        size="mini"></el-input-number></div>
                    <div><el-button size="mini" @click="showPop = false" type="info">取消</el-button><el-button
                        size="mini" type="primary" style="margin-top: 5px;"
                        @click="showPop = false, tableDataCopy()">确认</el-button></div>
                  </div>

                  <el-button icon="el-icon-plus" type="primary" size="mini" slot="reference"
                    style="font-size: 14px;margin-right: 10px;margin-left: 0;"
                    :disabled="TableSelectionList.length == 1 ? false : true" @click="copyNum = 1"> 插入</el-button>
                </el-popover>
                <el-popconfirm title="确定删除选中数据吗？" @confirm="tableDataRowDelete" placement="top"
                  cancel-button-type="info">
                  <el-button slot="reference" icon="el-icon-delete" type="primary" size="mini"
                    style="font-size: 14px;margin-right: 10px;margin-left: 0;"
                    :disabled="TableSelectionList.length == 0">
                    删除</el-button>
                </el-popconfirm>
                <el-button icon="el-icon-plus" type="primary" size="mini"
                  style="font-size: 14px;margin-right: 10px;margin-left: 0;" @click="tableDataMove(1)"
                  :disabled="TableSelectionList.length == 0">移动</el-button>
                <el-button icon="el-icon-plus" type="primary" size="mini"
                  style="font-size: 14px;margin-right: 10px;margin-left: 0;" @click="tableDataMove(2)"
                  :disabled="TableSelectionList.length == 0">复制</el-button>
              </div>
              <div style="line-height: 32px;width: calc(100% - 370px);max-height: 60px;overflow-y: auto;">
                <el-radio-group v-model="radio" @change="changeTableRadio" size="mini"
                  v-if="tablelouDatalist.length > 0">
                  <el-radio-button :label="''">全部</el-radio-button>
                  <el-radio-button :label="item.value" v-for="(item, index) in tablelouDatalist" :key="item.value"> {{
                    item.parentLabel + '-' + item.label
                  }}</el-radio-button>

                </el-radio-group>
              </div>

            </el-row>
            <el-table :data="searchTableShow ? SearchTableData : tableData" border :max-height="tableHeight" ref="table"
              :height="tableHeight" v-loading="tableLoading" @selection-change="changeTableSelection"
              row-class-name="table-row">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column type="selection" width="30" align="center">
              </el-table-column>
              <el-table-column type="index" label="编号" width="40" align="center">
              </el-table-column>
              <el-table-column prop="BuildingCode" label="楼栋" align="center" show-overflow-tooltip :resizable="false"
                :filters="Buildingfilters" :filter-method="filterBuildingInfo">

              </el-table-column>
              <el-table-column prop="BuildingFormatName" label="业态" align="center" show-overflow-tooltip
                :resizable="false" :filters="activitiesfilters" :filter-method="filterActivitiesInfo">
              </el-table-column>
              <el-table-column prop="floor" label="楼层" align="center" :resizable="false" :filters="Floorfilters"
                :filter-method="filterFloorInfo">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.floor" placeholder="请选择" size="mini" filterable
                    @change="changeFloorList(scope.row, $event)" :title="scope.row.floorId">
                    <el-option v-for="item in scope.row.floorList" :key="item.id" :label="item.Value" :value="item.Key">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="BA_HT_PL" label="功能区/用电负荷名称" align="center" :resizable="false"
                :filters="filtersTable('BA_HT_PL')" :filter-method="filtersTableInfo" width="150">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.BA_HT_PL" size="mini" placeholder="请输入"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="LoadingCategories" label="负荷类别" align="center" :resizable="false"
                :filters="filtersTable('LoadingCategories')" :filter-method="filtersTableInfo">
                <template slot-scope="scope">

                  <el-select v-model="scope.row.LoadingCategories" placeholder="请选择" size="mini" filterable allow-create
                    @change="changeLoadingCategoriesList($event, scope.row)" default-first-option
                    :title="scope.row.LoadingCategories">
                    <el-option v-for="item in DictionariesData['LC_' + scope.row.BuildingFormatKey.split('-')[1]]"
                      :key="item.DicName" :label="item.DicName" :value="item.DicValue">
                    </el-option>
                  </el-select>

                </template>
              </el-table-column>
              <el-table-column prop="Region" label="区域" align="center" :resizable="false"
                :filters="filtersTable('Region')" :filter-method="filtersTableInfo" width="150">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.Region" placeholder="请选择" size="mini" filterable allow-create
                    @change="changeE_RegionList($event, scope.row)" default-first-option :title="scope.row.Region">
                    <el-option v-for="item in DictionariesData['ER_' + scope.row.BuildingFormatKey.split('-')[1]]"
                      :key="item.DicName" :label="item.DicName" :value="item.DicValue">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="UA_HC_Count" label="使用面积(㎡)/户数/数量" align="center" :resizable="false"
                :filters="filtersTable('UA_HC_Count')" :filter-method="filtersTableInfo" width="190">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.UA_HC_Count" size="mini" oninput="value=value.replace(/[^0-9.]/g,'')"
                    placeholder="请输入" @input="changeTableInfoUA_HC_Count(scope.row, $event)"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="单位负载(W/㎡)" align="center" :resizable="false">
                <el-table-column prop="Lighting" label="照明" align="center" :resizable="false"
                  :filters="filtersTable('Lighting')" :filter-method="filtersTableInfo">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.Lighting" size="mini" placeholder="请输入"
                      @input="changeTableInfoLighting(scope.row, $event)"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="Power" label="动力" align="center" :resizable="false"
                  :filters="filtersTable('Power')" :filter-method="filtersTableInfo">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.Power" size="mini" oninput="value=value.replace(/[^0-9.]/g,'')"
                      placeholder="请输入" @input="changeTableInfoLighting(scope.row, $event)"></el-input>
                  </template>
                </el-table-column>

              </el-table-column>
              <!-- <el-table-column label="功率密度/设备功率(KW)" align="center" width="150">
               
              </el-table-column> -->
              <el-table-column prop="Subtotal_Quota" label="小计/单间指标(kW)" align="center" :resizable="false"
                :filters="filtersTable('Subtotal_Quota')" :filter-method="filtersTableInfo" width="160">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.Subtotal_Quota" size="mini" oninput="value=value.replace(/[^0-9.]/g,'')"
                    placeholder="请输入" @input="changeTableInfoUA_HC_Count(scope.row, $event)"
                    :disabled="Boolean(scope.row.Lighting) && Boolean(scope.row.Power)"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="EquipmentLoad" label="设备负载(kW)" align="center" show-overflow-tooltip
                :resizable="false" :filters="filtersTable('EquipmentLoad')" :filter-method="filtersTableInfo"
                width="110">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.EquipmentLoad" size="mini" oninput="value=value.replace(/[^0-9.]/g,'')"
                    :disabled="Boolean(scope.row.Subtotal_Quota) && Boolean(scope.row.UA_HC_Count)"
                    placeholder="请输入"></el-input>
                </template>
              </el-table-column>

              <el-table-column label="需求负载" align="center" show-overflow-tooltip :resizable="false">
                <el-table-column prop="DemandCoefficient" label="需要系数(Kx)" align="center" :resizable="false"
                  :filters="filtersTable('DemandCoefficient')" :filter-method="filtersTableInfo" width="110">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.DemandCoefficient" size="mini"
                      oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="ActivePower" label="有功功率(kW)" align="center" show-overflow-tooltip
                  :resizable="false" :filters="filtersTable('ActivePower')" :filter-method="filtersTableInfo"
                  width="110">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.ActivePower" size="mini" disabled></el-input>
                  </template>
                </el-table-column>
              </el-table-column>

              <!-- <el-table-column label="操作" align="center">
                <template slot-scope="scope">

        
                  <el-popover placement="top" trigger="click" v-model="scope.row.showPop">
                    <div style="text-align: center;">
                      <div style="font-weight: bold;font-size: 14px;margin-bottom: 10px;">请输入追加条目</div>
                      <div><el-input-number v-model="copyNum" :min="1" :max="100" :precision="0" label="描述文字"
                          size="mini"></el-input-number></div>
                      <div><el-button size="mini" @click="scope.row.showPop = false"
                          type="info">取消</el-button><el-button size="mini" type="primary" style="margin-top: 5px;"
                          @click="scope.row.showPop = false, tableDataCopy(scope)">确认</el-button></div>
                    </div>
                    <el-button slot="reference" icon="el-icon-plus" type="primary" size="mini" circle
                      style="font-size: 14px;" @click="copyNum = 1"></el-button>
                  </el-popover>
                  <el-popconfirm title="确定删除该条数据吗？" placement="top" @confirm="tableDataDelete(scope)"
                    cancel-button-type="info">
                    <el-button slot="reference" icon="el-icon-delete" type="primary" size="mini" circle
                      style="font-size: 14px;margin-left: 5px;"></el-button>
                  </el-popconfirm>
                </template>
              </el-table-column> -->

            </el-table>
            <div>
              <el-button icon="el-icon-plus" type="primary" size="mini"
                style="font-size: 14px;margin-right: 10px;margin-left: 0;margin-top: 5px;" @click="tableDataPush"
                :disabled="pushTableDataDisabled" circle></el-button>
            </div>
          </div>

          <div class="bottomTotal" style="">
            <div style="display: flex; justify-content: space-between;">
              <div class="grid-content bg-purple" style="">总装机容量(kVA)</div>
              <div class="grid-content bg-purple-light" style="">
                <el-statistic group-separator="," :value="parseFloat(TransformerAllCapacity.toFixed(3))"></el-statistic>
              </div>

              <div class="grid-content bg-purple" style="">负荷需求(kW)</div>
              <!-- <div class="grid-content bg-purple" style="">小计负荷需求(kW)</div> -->
              <div class="grid-content bg-purple-light" style="">
                <el-statistic group-separator="," :value="parseFloat(PowerDemandLoad.toFixed(3))"></el-statistic>

              </div>

              <div class="grid-content bg-purple" style="">需求装机(kVA)</div>
              <!-- <div class="grid-content bg-purple" style="">计算装机容量(kVA)</div> -->
              <div class="grid-content bg-purple-light" style="">
                <el-statistic group-separator="," :value="parseFloat(PowerDemandCapacity.toFixed(3))"></el-statistic>

              </div>
              <div class="grid-content bg-purple" style="">功率指标(W/㎡)</div>
              <!-- <div class="grid-content bg-purple" style="">小计功率指标(W/㎡)</div> -->
              <div class="grid-content bg-purple-light" style="">
                <el-statistic group-separator="," :value="parseFloat(DemandLoadDensity.toFixed(3))"></el-statistic>

              </div>
              <div class="grid-content bg-purple" style="">装机指标(VA/㎡)</div>
              <!-- <div class="grid-content bg-purple" style="">小计装机指标(VA/㎡)</div> -->
              <div class="grid-content bg-purple-light" style="border-right: 2px solid #a8a7a7">
                <el-statistic group-separator="," :value="parseFloat(TXLoadDensity.toFixed(3))"></el-statistic>

              </div>

            </div>
          </div>
        </div>
        <div class="right" style="display: flex;flex-wrap: wrap;align-items: center;">

          <EchartsIndex ref="Echarts3" :option="Options" styleStr="width: 100%; height: 200px">
          </EchartsIndex>
          <EchartsIndex ref="Echarts3" :option="Options2" styleStr="width: 100%; height: 200px">
          </EchartsIndex>
          <EchartsIndex ref="Echarts3" :option="Options3" styleStr="width: 100%; height: 200px">
          </EchartsIndex>

        </div>
      </div>

    </el-dialog>

    <MoveStageMainList ref="MoveStageMainList" @moveChange="moveChange" @copyChange="copyChange"></MoveStageMainList>
  </div>
</template>
<script>
import EchartsIndex from "@/components/Echarts/index";
import MoveStageMainList from './MoveStage_MainList'
import { set } from "nprogress";

var uuid = require("node-uuid");
export default {
  data() {
    return {
      DictionariesData: {},
      newsetData: [],
      showPop: false,
      headFormSaveNum: 0,//表单状态是否已更新
      FormDataListSaveNum: 0,//表单状态是否已更新
      FormDataSaveNum: 0,//表单状态是否已更新
      tableDataSaveNum: 0,//表单状态是否已更新
      formLoading: false,
      moveDialgVisible: false,
      title: '新建',
      radio: "",
      fullscreen: false,
      tableLoading: false,
      Options: {
        title: {
          text: "按功能区域分析",
          subtext: "",
          left: "center",
          top: 0,
        },
        tooltip: {
          trigger: 'item',
          position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = { top: 60 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },
          formatter: '{b}：{c}<br/>占比：{d}%'
        },
        legend: {
          orient: 'horizontal',
          // left: 'left'
          bottom: 10,
        },
        series: [
          {
            name: '按功能区域分析',
            type: 'pie',
            radius: '40%',
            labelLine: {
              length: 2,
              length2: 2,
            },
            label: {
              show: true,
              formatter: (a) => {
                return a.name +
                  '\n' + a.value
              },
            },
            data: [
              { value: 1048, name: '车库负荷' },
              { value: 735, name: '机房负荷' },
              { value: 580, name: '功能区负荷' },
            ],
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: 'rgba(0, 0, 0, 0.5)'
            //   }
            // }
          }
        ]
      },
      Options2: {
        title: {
          text: "按业态分析",
          subtext: "",
          left: "center",
          top: 0,
        },
        tooltip: {
          trigger: 'item',
          position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = { top: 60 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },
          formatter: '{b}：{c}<br/>占比：{d}%'
        },
        legend: {
          orient: 'horizontal',
          // left: 'left'
          bottom: 10,
        },
        series: [
          {
            name: '业态类型分析',
            type: 'pie',
            radius: '40%',
            labelLine: {
              length: 2,
              length2: 2,
            },
            label: {
              show: true,
              formatter: (a) => {
                return a.name +
                  '\n' + a.value
              },
            },
            data: [
              { value: 1048, name: '商业' },
              { value: 735, name: '媒体' },
              { value: 580, name: '酒店' },
            ],
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: 'rgba(0, 0, 0, 0.5)'
            //   }
            // }
          }
        ]
      },
      Options3: {
        title: {
          text: "按负荷类型分析",
          subtext: "",
          left: "center",
          top: 0,
        },
        tooltip: {
          trigger: 'item',
          position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = { top: 60 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },
          formatter: '{b}：{c}<br/>占比：{d}%'
        },
        legend: {
          orient: 'horizontal',
          // left: 'left'
          bottom: 10,
        },
        series: [
          {
            name: '按负荷类型分析',
            type: 'pie',
            radius: '40%',
            labelLine: {
              length: 2,
              length2: 2,
            },
            label: {
              show: true,
              formatter: (a) => {
                return a.name +
                  '\n' + a.value
              },
            },
            data: [
              { value: 1048, name: '照明负荷' },
              { value: 735, name: '厨房负荷' },
              { value: 580, name: '暖通负荷' },
            ],
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: 'rgba(0, 0, 0, 0.5)'
            //   }
            // }
          }
        ]
      },
      EchartsForm: {},
      headForm: {
        TDRCode: '',
        TDRName: '',
        BuildingArea: '',
        ServiceScope: [],
        ManagementModel: '',
      },
      headFormRules: {
        TDRCode: [
          { required: true, message: '请输入配电室编号', trigger: 'blur' }
        ],
        TDRName: [
          { required: true, message: '请输入配电室名称', trigger: 'blur' }
        ],
        BuildingArea: [
          { required: true, message: '请输入建筑面积', trigger: 'blur' }
        ],
        ServiceScope: [
          { required: true, message: '请选择服务范围', trigger: 'change' }
        ],
        ManagementModel: [
          { required: true, message: '请选择经营模式', trigger: 'change' }
        ],

      },
      FormDataRules: {
        E_SUC: [
          { required: true, message: '请输入同时使用系数', trigger: 'blur' }
        ],
        E_FALVSC: [
          { required: true, message: '请输入低压侧补偿后功率因数', trigger: 'blur' }
        ],
        TransformerCapacity: [
          { required: true, message: '请输入变压器容量', trigger: 'blur' }
        ],
        TransformerCount: [
          { required: true, message: '请输入变压器台数', trigger: 'blur' }
        ],
      },
      tableData: [],
      SearchTableData: [],
      FormData: {
        E_SUC: 0.9,
        E_FALVSC: 0.95
      },
      FormDataList: [
        {
          TransformerCapacity: 0,
          TransformerCount: 0
        }
      ],
      Visible: false,
      tableHeight: 0,
      louList: [],
      taskObj: {},
      tablelouDatalist: [],
      ManagementModelList: [

      ],
      yetaiList: [

      ],
      LoadingCategoriesList: [

      ],
      E_RegionList: [

      ],
      floorList: [],
      searchTableShow: false,
      BuildingList: [],
      //总有功功率
      totalNum: 0,
      //表格选中列表
      TableSelectionList: [],
      //追加条数
      copyNum: 0,
      //追加显示
      copyDisabled: true,
      elForm: {
        Id: ""
      },
      row: {},
      EchartsFormShow: false,
      ServiceScopeCopy: []
    };
  },
  props: {},
  components: {
    EchartsIndex,
    MoveStageMainList
  },
  beforeUpdate() {
    this.$nextTick(() => { //在数据加载完，重新渲染表格
      this.$refs['table'].doLayout();
    })
  },
  methods: {
    //复制确定
    copyChange(obj, tableList, value) {
      console.log(obj, 'obj')
      console.log(value, 'value')
      let copyObj = {
        "BuildingId": '',
        "BuildingCode": '',
        "BuildingFormatName": '',
        "BuildingFormatKey": '',
      }
      let ServiceScope = []

      //如选择了服务范围
      if (value) {

        copyObj = {
          "BuildingId": value.parentValue,
          "BuildingCode": value.parentLabel,
          "BuildingFormatName": value.label,
          "BuildingFormatKey": value.value?.split('-')[1],
        }
        ServiceScope = JSON.parse(obj.ServiceScope)
      } else {
        // 未选择服务范围
        //选中的配电室 obj
        let objarr = JSON.parse(obj.ServiceScope).map(item => item.value)
        let newarr = JSON.parse(obj.ServiceScope)
        let seen = {};
        //去重表格内选中的 服务范围 （楼栋+业态）
        let uniqueObjects = tableList.filter(item => {
          if (!seen[item.BuildingFormatKey]) {
            seen[item.BuildingFormatKey] = true;
            return true;
          }
          return false;
        });
        //如选中的配电室 中服务范围里没有 选中表格内的（楼栋+业态）则添加
        uniqueObjects.forEach(item => {
          if (!objarr.includes(item.BuildingFormatKey)) {
            newarr.push({
              label: item.BuildingFormatName,
              value: item.BuildingFormatKey,
              parentLabel: item.BuildingCode,
              parentValue: item.BuildingId,
            })
          }
        })
        newarr.sort((a, b) => {
          return a.label.localeCompare(b.label)
        })
        ServiceScope = [...newarr]
      }
      this.$http.post("/ElectricalGO/Electrical_SchemeStageSum/CopyDataToOtherTDR", {
        ESSS_Id: obj.Id,
        ...copyObj,
        ServiceScope: JSON.stringify(ServiceScope),
        ESSC_Ids: tableList.map(item => item.Id)
      }).then(res => {
        if (res.Success) {
          this.$message.success("复制成功")
          this.TableSelectionList = []
        }
      })
    },
    //移动确定
    moveChange(obj, tableList) {
      console.log(obj, 'obj')

      let objarr = JSON.parse(obj.ServiceScope).map(item => item.value)

      console.log(JSON.parse(obj.ServiceScope), 'ServiceScope')
      console.log(objarr, 'arr')
      let newarr = JSON.parse(obj.ServiceScope)
      // let List = tableList.filter(item => item.BuildingFormatKey)
      let seen = {};
      let uniqueObjects = tableList.filter(item => {
        if (!seen[item.BuildingFormatKey]) {
          seen[item.BuildingFormatKey] = true;
          return true;
        }
        return false;
      });
      uniqueObjects.forEach(item => {
        if (!objarr.includes(item.BuildingFormatKey)) {
          newarr.push({
            label: item.BuildingFormatName,
            value: item.BuildingFormatKey,
            parentLabel: item.BuildingCode,
            parentValue: item.BuildingId,
          })
        }
      })
      console.log(uniqueObjects, 'uniqueObjects')
      console.log(newarr, 'newarr')
      console.log(tableList, 'tableList')
      newarr.sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
      this.$http.post("/ElectricalGO/Electrical_SchemeStageSum/MoveDataToOtherTDR", {
        ESSS_Id: obj.Id,
        ServiceScope: JSON.stringify(newarr),
        ESSC_Ids: tableList.map(item => item.Id)
      }).then(res => {
        if (res.Success) {
          this.$message.success("移动成功,准备保存当前数据")
          //删除逻辑
          let arr = this.TableSelectionList.map(item => {
            return item.uid
          })
          console.log(arr)

          this.tableData = this.tableData.filter(item => arr.indexOf(item.uid) === -1)
          this.TableSelectionList = []
          this.saveForm()
        }
      })
    },
    changeE_RegionList(val, row) {

      let key = 'ER_' + row.BuildingFormatKey?.split('-')[1]

      let obj = this.DictionariesData[key].find(item => item.DicValue === val)
      console.log(val)
      if (!obj) {
        let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
        if (selection[key]) {
          selection[key].push({
            DicName: val,
            DicValue: val
          })
        } else {
          selection[key] = [{
            DicName: val,
            DicValue: val
          }]
        }
        this.DictionariesData[key].unshift({
          DicName: val,
          DicValue: val
        })
        window.sessionStorage.setItem('selection', JSON.stringify(selection))
      }

    },
    changeLoadingCategoriesList(val, row) {
      let key = 'LC_' + row.BuildingFormatKey?.split('-')[1]

      let obj = this.DictionariesData[key].find(item => item.DicValue === val)
      console.log(val)
      if (!obj) {
        let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
        if (selection[key]) {
          selection[key].push({
            DicName: val,
            DicValue: val
          })
        } else {
          selection[key] = [{
            DicName: val,
            DicValue: val
          }]
        }
        this.DictionariesData[key].unshift({
          DicName: val,
          DicValue: val
        })
        window.sessionStorage.setItem('selection', JSON.stringify(selection))
      }
    },
    changeManagementModel(val) {
      let obj = this.ManagementModelList.find(item => item.DicValue === val)
      console.log(val, 'val')
      console.log(obj, 'obj')
      if (!obj) {
        let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
        if (selection.ManagementModelList) {
          selection.ManagementModelList.push({
            DicName: val,
            DicValue: val
          })

        } else {
          selection.ManagementModelList = [{
            DicName: val,
            DicValue: val
          }]

        }
        this.ManagementModelList.unshift({
          DicName: val,
          DicValue: val
        })
        window.sessionStorage.setItem('selection', JSON.stringify(selection))
      }
    },
    getE_RegionList() {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: 'E_Region',
        }).then(res => {
          if (res.Success) {
            this.E_RegionList = res.Data.DictionaryDetailsList || []
            let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
            if (selection.E_RegionList) {
              this.E_RegionList = [...selection.E_RegionList, ...this.E_RegionList]
            }
          }
        })
    },
    getLoadingCategoriesList() {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: 'LoadingCategories',
        }).then(res => {
          if (res.Success) {
            this.LoadingCategoriesList = res.Data.DictionaryDetailsList || []
            let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
            if (selection.LoadingCategoriesList) {
              this.LoadingCategoriesList = [...selection.LoadingCategoriesList, ...this.LoadingCategoriesList]
            }
          }
        })
    },
    getManagementModelList() {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: 'ManagementModel',
        }).then(res => {
          if (res.Success) {
            this.ManagementModelList = res.Data.DictionaryDetailsList || []
            let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
            if (selection.ManagementModelList) {
              this.ManagementModelList = [...selection.ManagementModelList, ...this.ManagementModelList]
            }

          }
        })
    },
    saveTableData() {
      let DataList = this.tableData.map(item => {
        return {
          ...item,
          BuildingFormatKey: item.BuildingFormatKey.split('-')[1],
        }
      })
      this.$http.post("/ElectricalGO/Electrical_SchemeStageCalc/SaveDataListByParentId", {
        ParentId: this.elForm.Id,
        DataList: DataList
      }).then(res => {
        if (res.Success) {
          this.$message.success("保存成功")
          this.formLoading = false
          this.tableLoading = false
          // this.Visible = false
          this.headFormSaveNum = 0
          this.FormDataListSaveNum = 0
          this.FormDataSaveNum = 0
          this.tableDataSaveNum = 0
          this.$emit('save')
          this.openForm(this.taskObj, this.elForm, '编辑')
        } else {
          this.$message.error(res.Msg)
        }
      })
    },
    async saveForm() {
      // await this.$confirm('您确定要保存当前数据吗？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(async () => {
      let flag = false
      await this.$refs.headForm.validate((res) => {
        console.log('headForm', res)
        if (res) {
          flag = true
        } else {
          flag = false
        }
      })
      if (!flag) {
        this.$message.error("表单内未填写完整，请确认后再保存")
        return
      }
      // await this.$refs.FormData.validate((res) => {
      //   console.log('FormData', res)
      //   if (res) {
      //     flag = true
      //   } else {
      //     flag = false
      //   }
      // })
      // if (!flag) {
      //   this.$message.error("表单内未填写完整，请确认后再保存")
      //   return
      // }
      // let arr = []
      // this.$refs.FormDataList.forEach(item => {
      //   item.validate(res => {
      //     if (!res) {
      //       arr.push(item)
      //     }
      //   })
      // })
      // if (arr.length > 0) {
      //   flag = false
      // } else {
      //   flag = true
      // }
      // console.log(arr, 'FormDataList')
      // if (!flag) {
      //   this.$message.error("表单内未填写完整，请确认后再保存")
      //   return
      // }
      // 楼层可以选空白 8.06 未添加

      //表格单元格判断
      // if (this.tableData.length > 0) {
      //   flag = this.tableData.every(item => {
      //     return item.floor && item.floorId && item.EquipmentLoad && item.ActivePower && item.LoadingCategories && item.BA_HT_PL
      //   })
      // } else {
      //   this.$message.error("表格内未填写完整，请确认后再保存")
      //   return
      // }

      // console.log(flag, 'tableData')

      // if (!flag) {
      //   this.$message.error("表格内未填写完整，请确认后再保存")
      //   return
      // }
      this.formLoading = true
      this.tableLoading = true

      this.$http.post("/ElectricalGO/Electrical_SchemeStageSum/SaveData", {
        ...this.elForm,
        ...this.headForm,
        ServiceScope: JSON.stringify(this.tablelouDatalist),
        ...this.FormData,
        TransformerData: JSON.stringify(this.FormDataList),

        TaskId: this.taskObj.TaskId,
        PowerDemandLoad: parseFloat(this.PowerDemandLoad.toFixed(3)),
        PowerDemandCapacity: parseFloat(this.PowerDemandCapacity.toFixed(3)),
        TransformerAllCapacity: parseFloat(this.TransformerAllCapacity.toFixed(3)),
        DemandLoadDensity: parseFloat(this.DemandLoadDensity.toFixed(3)),
        TXLoadDensity: parseFloat(this.TXLoadDensity.toFixed(3)),
        TransformerLoadRate: parseFloat(this.TransformerLoadRate.toFixed(3)),
      }).then(res => {
        if (res.Success) {
          this.elForm = res.Data
          this.saveTableData()
        } else {
          this.formLoading = false
          this.tableLoading = false
          this.$message.error(res.Msg)
        }
      })
      // })

    },
    ////使用面积户数数量 //小计
    changeTableInfoLighting(row, even) {
      // 动力+照明=小计
      this.$set(row, 'Subtotal_Quota', this.$number.dev(this.$number.add(row.Lighting, row.Power), 1000).toFixed(3))
      this.changeTableInfoUA_HC_Count(row)
    },
    // 户数数量 * 单间指标 =设备负载
    changeTableInfoUA_HC_Count(row, even) {


      this.$set(row, 'EquipmentLoad', this.$number.mut(row.UA_HC_Count, row.Subtotal_Quota).toFixed(3))
      // row.EquipmentLoad = row.UA_HC_Count * row.Subtotal_Quota
    },
    filtersTableInfo(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    filterFloorInfo(value, row) {
      return row.floor === value;
    },
    filterActivitiesInfo(value, row) {
      return row.BuildingFormatKey === value;
    },
    filterBuildingInfo(value, row) {
      return row.BuildingId === value;
    },

    //表格楼栋选择回调 增加楼层选择列表
    async changeTableLou(row, event) {
      console.log(row, event)
      await this.$http.post("/Base_ProjectManage/Project_DataAnalysis/GetMG_BaseInfo_BuildingsOrLayers", {
        "ProjectId": this.taskObj.ProjectId,
        "BuildingId": event
      }).then(res => {
        if (res.Success) {
          this.$set(row, 'floorList', res.Data)
        }
      })

    },
    //表格上方选择楼栋回调
    changeTableRadio(value, isAdd) {
      console.log(value)
      //判断是否是搜索与全部
      this.tableLoading = true
      setTimeout(() => {
        try {
          if (value) {
            this.searchTableShow = true
          } else {
            this.searchTableShow = false
          }
          let obj = this.tablelouDatalist.find(item => item.value === value)
          if (obj) {
            console.log(obj)
            this.SearchTableData = []
            let arr = this.tableData.filter(val => {
              return val.BuildingId === obj.parentValue && val.BuildingFormatKey === obj.value
            })
            if (arr.length > 0) {
              //是否是添加 添加不排序
              if (isAdd) {
                this.SearchTableData = arr
                this.tableLoading = false
                return
              }
              arr.sort((aItem, bItem) => {
                // 先按照楼层排序 再按照业态排序 再按照楼层排序
                if (aItem.BuildingCode < bItem.BuildingCode) return -1;
                if (aItem.BuildingCode > bItem.BuildingCode) return 1;
                if (aItem.BuildingFormatName < bItem.BuildingFormatName) return -1;
                if (aItem.BuildingFormatName > bItem.BuildingFormatName) return 1;
                let a = aItem.floorId
                let b = bItem.floorId
                // 首先检查空值（null 或 undefined），将它们视为最低优先级  
                if (a === null || a === undefined || a === '') return -1; // 如果 a 是空值，则将其放在前面  
                if (b === null || b === undefined || a === '') return 1; // 如果 b 是空值，则保持 a 在前面  

                // 接下来检查特殊元素 'RF'  
                if (a === 'RF') return 1; // 如果 a 是 'RF'，则将其放在后面（但不是最前面）  
                if (b === 'RF') return -1; // 如果 b 是 'RF'，则将其放在 a 前面（但实际上在这个逻辑中，由于上面的空值检查，它不会被放在最前面）  

                // 提取前缀和数字  
                const [prefixA, numA = 0] = (a.match(/([BF])?(\d+)/) || ['', '0']).slice(1); // 注意：这里只取捕获组，即前缀和数字  
                const [prefixB, numB = 0] = (b.match(/([BF])?(\d+)/) || ['', '0']).slice(1);

                // 根据前缀进行排序  
                if (prefixA !== prefixB) {
                  return prefixA.localeCompare(prefixB); // 'B' 应该在 'F' 前面  
                }

                // 如果前缀相同，则根据数字部分进行排序  
                // 注意：由于我们想要 'B' 降序，而 'F' 升序，我们需要根据前缀来调整排序方向  
                const direction = prefixA === 'B' ? -1 : 1;
                return direction * (numA - numB);
              })

              this.SearchTableData = arr
            }
          } else {

            //是否是添加 添加不排序
            if (isAdd) {
              this.tableLoading = false
              return
            }
            this.tableData.sort((aItem, bItem) => {
              // 先按照楼层排序 再按照业态排序 再按照楼层排序
              if (aItem.BuildingCode < bItem.BuildingCode) return -1;
              if (aItem.BuildingCode > bItem.BuildingCode) return 1;
              if (aItem.BuildingFormatName < bItem.BuildingFormatName) return -1;
              if (aItem.BuildingFormatName > bItem.BuildingFormatName) return 1;
              let a = aItem.floorId
              let b = bItem.floorId
              // 首先检查空值（null 或 undefined），将它们视为最低优先级  
              if (a === null || a === undefined || a === '') return -1; // 如果 a 是空值，则将其放在前面  
              if (b === null || b === undefined || a === '') return 1; // 如果 b 是空值，则保持 a 在前面  

              // 接下来检查特殊元素 'RF'  
              if (a === 'RF') return 1; // 如果 a 是 'RF'，则将其放在后面（但不是最前面）  
              if (b === 'RF') return -1; // 如果 b 是 'RF'，则将其放在 a 前面（但实际上在这个逻辑中，由于上面的空值检查，它不会被放在最前面）  

              // 提取前缀和数字  
              const [prefixA, numA = 0] = (a.match(/([BF])?(\d+)/) || ['', '0']).slice(1); // 注意：这里只取捕获组，即前缀和数字  
              const [prefixB, numB = 0] = (b.match(/([BF])?(\d+)/) || ['', '0']).slice(1);

              // 根据前缀进行排序  
              if (prefixA !== prefixB) {
                return prefixA.localeCompare(prefixB); // 'B' 应该在 'F' 前面  
              }

              // 如果前缀相同，则根据数字部分进行排序  
              // 注意：由于我们想要 'B' 降序，而 'F' 升序，我们需要根据前缀来调整排序方向  
              const direction = prefixA === 'B' ? -1 : 1;
              return direction * (numA - numB);

            })
          }

        } catch (error) {
          this.tableLoading = false
        }
        this.tableLoading = false

      }, 100)
    },
    //选择楼栋回调 /服务范围
    changeLouList(val, row) {


      console.log(this.headForm.ServiceScope, 'this.ServiceScope')
      console.log(this.ServiceScopeCopy, 'this.ServiceScopeCopy')
      //判断是否是取消选中  
      if (this.ServiceScopeCopy.length > this.headForm.ServiceScope.length) {
        //拿到当前选中的value
        let arr = this.headForm.ServiceScope.map(item => {
          return item[1]
        })
        //拿到取消选中的value
        let narr = []
        this.ServiceScopeCopy.forEach(item => {
          if (arr.indexOf(item[1]) === -1) {
            narr.push(item)
          }
        })
        //查出表格内是否有取消选中的数据
        let nnarr = []
        narr.forEach(item => {
          let obj = this.tableData.find(val => {
            return val.BuildingFormatKey === item[1]
          })
          if (obj) {
            nnarr.push(obj)
          }
        })

        //如有数据则提示弹窗
        if (nnarr.length > 0) {
          this.$confirm('检测到表格内有当前取消选中业态数据将删除数据，是否删除?', '提示', {
            confirmButtonText: '确认删除',
            cancelButtonText: '取消',
            closeOnPressEscape: false,
            zIndex: 99999,
            type: 'warning'
          }).then(() => {
            this.ServiceScopeCopy = JSON.parse(JSON.stringify(this.headForm.ServiceScope))
            setTimeout(() => {
              let List = this.$refs.cascader.getCheckedNodes() || []
              console.log(List)
              if (List.length > 0) {
                this.tablelouDatalist = List.filter(item => item.level != 1 && item.checked).map(item => item.data)
              } else {
                this.tablelouDatalist = []
              }

              this.searchTableShow = false

              let arr = this.tablelouDatalist.map(item => {
                return item.value
              })

              if (this.tablelouDatalist.length == 0) {
                this.radio = ''
              } else if (this.tablelouDatalist.length == 1) {
                this.radio = this.tablelouDatalist[this.tablelouDatalist.length - 1].value
              }
              //删除时清空楼层列表 保证table里都是服务范围内的楼栋
              this.tableData = this.tableData.filter(item => arr.indexOf(item.BuildingFormatKey) !== -1)
            }, 1)

          }).catch(() => {
            this.headForm.ServiceScope = [...this.ServiceScopeCopy]
          })
        } else {
          this.ServiceScopeCopy = JSON.parse(JSON.stringify(this.headForm.ServiceScope))
          setTimeout(() => {
            let List = this.$refs.cascader.getCheckedNodes() || []
            console.log(List)
            if (List.length > 0) {
              this.tablelouDatalist = List.filter(item => item.level != 1 && item.checked).map(item => item.data)
            } else {
              this.tablelouDatalist = []
            }

            this.searchTableShow = false

            let arr = this.tablelouDatalist.map(item => {
              return item.value
            })

            if (this.tablelouDatalist.length == 0) {
              this.radio = ''
            } else if (this.tablelouDatalist.length == 1) {
              this.radio = this.tablelouDatalist[this.tablelouDatalist.length - 1].value
            }
            //删除时清空楼层列表 保证table里都是服务范围内的楼栋
            this.tableData = this.tableData.filter(item => arr.indexOf(item.BuildingFormatKey) !== -1)
          }, 1)

        }

        return
      }


      this.ServiceScopeCopy = JSON.parse(JSON.stringify(this.headForm.ServiceScope))
      setTimeout(() => {
        let List = this.$refs.cascader.getCheckedNodes() || []
        console.log(List)
        if (List.length > 0) {
          this.tablelouDatalist = List.filter(item => item.level != 1 && item.checked).map(item => item.data)
        } else {
          this.tablelouDatalist = []
        }

        this.searchTableShow = false

        let arr = this.tablelouDatalist.map(item => {
          return item.value
        })

        if (this.tablelouDatalist.length == 0) {
          this.radio = ''
        } else if (this.tablelouDatalist.length == 1) {
          this.radio = this.tablelouDatalist[this.tablelouDatalist.length - 1].value
        }
        //删除时清空楼层列表 保证table里都是服务范围内的楼栋
        this.tableData = this.tableData.filter(item => arr.indexOf(item.BuildingFormatKey) !== -1)
      }, 1)



    },
    //楼层选择回调
    changeFloorList(row, eve) {
      console.log(row, eve)
      let obj = row.floorList.find(item => item.Key === eve)
      this.$set(row, 'floorId', obj.Value)
    },
    //中间表单追加
    FromDataPush(index) {
      this.FormDataList.splice(index + 1, 0, {
        TransformerCapacity: 0,
        TransformerCount: 0
      })

    },
    //楼层列表
    //楼栋列表
    getLouData() {
      console.log(this.taskObj)

      this.$http.post("/Base_ProjectManage/Project_Building/GetBuildingInfoForElectricalGO?projectId=" + this.taskObj.ProjectId).then((res) => {
        if (res.Success) {
          if (res.Data?.length > 0) {
            let arr = []
            res.Data.forEach(item => {
              this.$set(item, 'disabled', item.children?.length > 0 ? false : true)
              item.children.forEach(val => {
                arr.push(val.value)
                val.value = item.value + '-' + val.value
              })
            })
            console.log(arr, 'arrarrarrarrarrarrarrarrarr')
            this.newsetData = new Set(arr)

            this.BuildingList = res.Data
            console.log(this.BuildingList, 'BuildingList')
            this.getDictionariesData()

          }

        } else {
          this.louList = []
          this.$message.error('获取楼栋数据失败')
        }

      });
    },
    async getDictionariesData() {

      for (const item of this.newsetData) {
        //负荷类别
        this.$http
          .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
            Condition: "DicKey",
            Keyword: 'LC_' + item,
          }).then(res => {
            if (res.Success) {
              let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
              if (selection['LC_' + item]) {
                this.$set(this.DictionariesData, 'LC_' + item, [...selection['LC_' + item], ...res.Data.DictionaryDetailsList || []])
              } else {
                this.$set(this.DictionariesData, 'LC_' + item, res.Data.DictionaryDetailsList || [])
              }
            }


          })
        //区域
        this.$http
          .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
            Condition: "DicKey",
            Keyword: 'ER_' + item,
          }).then(res => {
            if (res.Success) {
              let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
              if (selection['ER_' + item]) {
                this.$set(this.DictionariesData, 'ER_' + item, [...selection['ER_' + item], ...res.Data.DictionaryDetailsList || []])
              } else {
                this.$set(this.DictionariesData, 'ER_' + item, res.Data.DictionaryDetailsList || [])
              }


            }
          })
      }
      setTimeout(() => {
        console.log(this.DictionariesData, 'objobj')
      })
    },
    //表格选中删除
    async tableDataRowDelete() {
      let arr = this.TableSelectionList.map(item => {
        return item.uid
      })
      console.log(arr)

      this.tableData = this.tableData.filter(item => arr.indexOf(item.uid) === -1)
      this.TableSelectionList = []
      await this.changeTableRadio(this.radio)
    },
    //表格选中
    changeTableSelection(List) {

      this.TableSelectionList = List
      console.log(this.TableSelectionList)
    },
    //表格内容移动/复制
    tableDataMove(num) {
      console.log(this.TableSelectionList,)

      let flag = this.TableSelectionList.every(item => {
        return item.Id
      })
      if (!flag) {
        if (num === 1) {
          return this.$message.error("选中有未保存数据，请保存后移动")
        } else {
          return this.$message.error("选中有未保存数据，请保存后复制")
        }
      }
      if (num === 1) {
        this.$refs.MoveStageMainList.openMoveDialog(this.taskObj.TaskId, this.TableSelectionList, this.elForm.Id, '移动数据')
      } else {
        this.$refs.MoveStageMainList.openMoveDialog(this.taskObj.TaskId, this.TableSelectionList, this.elForm.Id, '复制数据')
      }

    },
    //表格内容删除
    tableDataDelete(scope) {
      let index = this.tableData.findIndex(item => item.uid == scope.row.uid)
      let Id = scope.row.BuildingFormatKey
      this.tableData.splice(index, 1)


      if (this.searchTableShow) {
        this.changeTableRadio(Id)
      }

    },
    async tableDataCopy() {
      console.log(this.TableSelectionList[0].uid)

      let index = this.tableData.findIndex(item => item.uid == this.TableSelectionList[0].uid)
      let nobj = { ...this.TableSelectionList[0] }
      let obj = {}
      await this.changeTableLou(obj, this.tableData[index].BuildingId)
      let arr = []
      for (let index = 0; index < this.copyNum; index++) {

        arr.push({
          uid: uuid.v4(), showPop: false,
          BuildingCode: nobj.BuildingCode,
          BuildingId: nobj.BuildingId,
          BuildingFormatName: nobj.BuildingFormatName,
          BuildingFormatKey: nobj.BuildingFormatKey,
          floor: nobj.floor,
          floorId: nobj.floorId,
          floorList: obj.floorList,
          BA_HT_PL: nobj.BA_HT_PL,
          LoadingCategories: nobj.LoadingCategories,
          Region: nobj.Region,
          UA_HC_Count: '',
          Lighting: '',
          Power: '',
          Subtotal_Quota: '',
          DemandCoefficient: '',
          ActivePower: '',
          EquipmentLoad: '',
        })
      }
      this.tableData.splice(index + 1, 0, ...arr)

      if (this.searchTableShow) { //
        this.changeTableRadio(nobj.BuildingFormatKey, true)
      }
    },
    //表格内容填充
    async tableDataAdd() {
      this.tableLoading = true
      if (this.radio) {
        let floor = {}
        let valueobj = this.tablelouDatalist.find(item => item.value === this.radio)
        await this.changeTableLou(floor, valueobj.parentValue)
        let obj = {}
        let arr = []
        floor.floorList.forEach(item => {
          obj = {
            uid: uuid.v4(), showPop: false,
            BuildingCode: valueobj.parentLabel,
            BuildingId: valueobj.parentValue,
            BuildingFormatName: valueobj.label,
            BuildingFormatKey: valueobj.value,
            floor: item.Key,
            floorId: item.Value,
            floorList: floor.floorList,
            BA_HT_PL: '',
            LoadingCategories: '',
            Region: '',
            UA_HC_Count: '',
            Lighting: '',
            Power: '',
            Subtotal_Quota: '',
            DemandCoefficient: '',
            ActivePower: '',
            EquipmentLoad: '',
          }
          arr.push(obj)
        })
        this.tableData = [...this.tableData, ...arr]
      }


      if (this.searchTableShow) { //
        this.changeTableRadio(this.tableData[this.tableData.length - 1].BuildingFormatKey)
      } else {
        this.tableLoading = false
      }

    },
    //表格内容追加
    tableDataPush() {

      this.tableLoading = true
      try {
        let obj = null
        //如果有选中楼栋
        if (this.radio) {
          let valueobj = this.tablelouDatalist.find(item => item.value === this.radio)
          let nobj = { ...this.SearchTableData[this.SearchTableData.length - 1] }
          obj = {
            uid: uuid.v4(), showPop: false,
            BuildingCode: valueobj.parentLabel,
            BuildingId: valueobj.parentValue,
            BuildingFormatName: valueobj.label,
            BuildingFormatKey: valueobj.value,
            floor: nobj.floor,
            floorId: nobj.floorId,
            BA_HT_PL: nobj.BA_HT_PL,
            LoadingCategories: nobj.LoadingCategories,
            Region: nobj.Region,
            UA_HC_Count: '',
            Lighting: '',
            Power: '',
            Subtotal_Quota: '',
            DemandCoefficient: '',
            ActivePower: '',
            EquipmentLoad: '',
          }

        } else {
          //没有选择楼栋但只有一条数据就默认该条
          if (this.tablelouDatalist.length == 1) {
            let nobj = this.tableData.length > 0 ? this.tableData[this.tableData.length - 1] : {}
            let valueobj = this.tablelouDatalist[this.tablelouDatalist.length - 1]
            obj = {
              uid: uuid.v4(), showPop: false,
              BuildingCode: valueobj.parentLabel,
              BuildingId: valueobj.parentValue,
              BuildingFormatName: valueobj.label,
              BuildingFormatKey: valueobj.value,
              floor: nobj.floor || '',
              floorId: nobj.floorId || '',
              BA_HT_PL: nobj.BA_HT_PL,
              LoadingCategories: nobj.LoadingCategories || '',
              Region: nobj.Region || '',
              UA_HC_Count: '',
              Lighting: '',
              Power: '',
              Subtotal_Quota: '',
              DemandCoefficient: '',
              ActivePower: '',
              EquipmentLoad: '',
            }
          }
        }

        this.tableData.push(
          { ...obj }
        )

        this.changeTableLou(this.tableData[this.tableData.length - 1], this.tableData[this.tableData.length - 1].BuildingId)
        if (this.searchTableShow) { //
          this.changeTableRadio(this.tableData[this.tableData.length - 1].BuildingFormatKey, true)
        } else {
          this.tableLoading = false
        }
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.table.bodyWrapper.scrollTop = this.$refs.table.bodyWrapper.scrollHeight + 30;
          })
        }, 100)
      } catch (error) {
        this.tableLoading = false
      }

    },
    openForm(taskObj, row = {}, title = '新建') {
      this.title = title
      this.row = row
      this.taskObj = taskObj
      this.getLouData()
      this.getManagementModelList()
      // this.getLoadingCategoriesList()
      // this.getE_RegionList()
      this.Visible = true;
      this.fullscreen = true
      this.tableData = []
      this.radio = ''
      this.SearchTableData = []
      this.tablelouDatalist = []

      this.EchartsForm = {
        title: {
          text: "同一配电室内多台变压器平均负载率（柱状图）",
          subtext: "",
          left: "center",
          top: 0,

        },
        grid: {
          top: 50,
          left: 50,
          right: 50,
          bottom: 10
        },
        legend: {
          show: true,
          top: 20,
          itemWidth: 15,
          itemHeight: 15,
          itemStyle: {
            color: ''
          }
        },
        xAxis: {
          type: 'category',
          data: [],

        },
        yAxis: {
          show: true,
          type: 'value',
          min: 0,
          max: 100,
          // interval: 50,
          // splitNumber: 25,
          // maxInterval: 25,
          // minInterval: 10,
          axisLabel: {
            formatter: function (value, index) {

              var texts = [];
              if (value == 0) {
                texts.push('0');
              }
              else if (value <= 25) {
                texts.push('25');
              }
              else if (value <= 50) {
                texts.push('50');
              }
              else if (value <= 75) {
                texts.push('75');
              }
              else if (value <= 85) {
                texts.push('85');
              }
              else {
                texts.push('100');
              }
              return texts;
            }
          },
          // splitNumber: 6,
          axisLine: { // 设置x轴线的样式
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {

              color: ['', '#91CC75', '', '', 'red', 'red'], // 设置x轴线的颜色
            }
            // 取消Y轴的网格线  
          },
          axisTick: {
            show: false, // 不显示刻度线
          }

        },
        tooltip: {
          formatter: "{a}<br/>{c}%"
        },
        label: {
          show: true,
          fontSize: 14,

          fontWeight: 'bold',
          formatter: "{c}%"
        },
        series: [

        ]
      }
      this.EchartsFormShow = true
      this.$nextTick(() => {
        this.$refs.headForm.resetFields()
        this.$refs.headForm.clearValidate('ServiceScope')
        this.FormData = {
          E_SUC: 0.9,
          E_FALVSC: 0.95
        }
        this.FormDataList = [
          {
            TransformerCapacity: 0,
            TransformerCount: 0
          }
        ]
        //异步加载 为监听后再置为0
        setTimeout(() => {
          this.headFormSaveNum = 0
          this.FormDataListSaveNum = 0
          this.FormDataSaveNum = 0
          this.tableDataSaveNum = 0
          if (this.row?.Id) {
            this.getFormData()
          }
        }, 1)

      })

    },
    getFormData() {
      this.formLoading = false
      this.$http.post('/ElectricalGO/Electrical_SchemeStageSum/GetTheData', {
        id: this.row.Id
      }).then(res => {
        if (res.Success) {
          try {
            this.elForm = res.Data
            let arr = JSON.parse(this.elForm.ServiceScope)
            let ServiceScope = []
            arr.forEach(item => {
              ServiceScope.push([item.parentValue, item.value])
            })
            this.headForm = {
              TDRCode: this.elForm.TDRCode,
              TDRName: this.elForm.TDRName,
              BuildingArea: this.elForm.BuildingArea,
              ServiceScope: ServiceScope,
              ManagementModel: this.elForm.ManagementModel,
            }
            this.ServiceScopeCopy = JSON.parse(JSON.stringify(this.headForm.ServiceScope))
            this.FormData = {
              E_SUC: this.elForm.E_SUC,
              E_FALVSC: this.elForm.E_FALVSC
            }
            this.FormDataList = JSON.parse(this.elForm.TransformerData)
            this.tablelouDatalist = JSON.parse(this.elForm.ServiceScope)
            if (this.tablelouDatalist.length == 1) {
              this.radio = this.tablelouDatalist[0].value
              this.changeTableRadio(this.radio)

            }
            this.headFormSaveNum = 0
            this.FormDataListSaveNum = 0
            this.FormDataSaveNum = 0
            this.tableDataSaveNum = 0
            this.formLoading = false
          } catch (error) {
            this.formLoading = false
            this.$message.error('获取表单数据失败')
          }
        } else {
          this.$message.error(res.Msg)
        }

      })
      this.tableLoading = true
      this.$http.post('/ElectricalGO/Electrical_SchemeStageCalc/GetDataList', {
        // "PageIndex": 0,
        // "PageRows": 0,
        // "SortField": "string",
        // "SortType": "string",
        "Search": {
          "Condition": "ParentId",
          "Keyword": this.row.Id
        }
      }).then(async res => {
        if (res.Success) {
          try {
            let BuildingIdArr = []
            for (const item of res.Data) {
              //简化请求次数楼层列表
              let index = BuildingIdArr.findIndex(val => {
                return val.BuildingId === item.BuildingId
              })
              if (index === -1) {
                let obj = {
                  BuildingId: item.BuildingId,
                }
                await this.changeTableLou(obj, obj.BuildingId)
                this.$set(item, 'floorList', obj.floorList)
                BuildingIdArr.push(obj)
              } else {
                console.log(index, 'BuildingIdArr')
                console.log(BuildingIdArr, 'BuildingIdArrBuildingIdArr')
                this.$set(item, 'floorList', BuildingIdArr[index].floorList)
              }
              //补全表格内交互字段
              this.$set(item, 'uid', item.Id)
              this.$set(item, 'showPop', false)
              this.$set(item, 'floor', (item.IsOnGround === '' || item.IsOnGround === null || item.IsOnGround === undefined) ? '' : item.IsOnGround + '-' + item.Storey)
              this.$set(item, 'BuildingFormatKey', item.BuildingId + '-' + item.BuildingFormatKey)
              if (item.IsOnGround == '1') {
                this.$set(item, 'floorId', 'F' + item.Storey)
              } else if (item.IsOnGround == '2') {
                this.$set(item, 'floorId', 'B' + item.Storey)
              } else if (item.IsOnGround == '3') {
                this.$set(item, 'floorId', 'RF')
              }
              // console.log(item.floorList, 'floorListfloorListfloorListfloorList')
              console.log(item.floorId, 'floorIdfloorIdfloorIdfloorIdfloorIdfloorIdfloorIdfloorIdfloorIdfloorId')
              let flo = item.floorList.find(valId => {
                return valId.Value === item.floorId
              })
              console.log(flo, 'floflofloflofloflofloflo')
              if (!flo) {
                this.$set(item, 'floorId', '')
                this.$set(item, 'floor', '')
              }
            }
            console.log(BuildingIdArr, 'BuildingIdArr')
            this.tableData = res.Data
            this.changeTableRadio(this.radio)
            this.tableLoading = false
            this.headFormSaveNum = 0
            this.FormDataListSaveNum = 0
            this.FormDataSaveNum = 0
            this.tableDataSaveNum = 0
          } catch (error) {
            this.tableLoading = false
            this.$message.error('获取表格数据失败')
          }
        } else {
          this.$message.error(res.Msg)
        }
      })

    },
    close() {
      console.log(this.headFormSaveNum)
      console.log(this.FormDataListSaveNum)
      console.log(this.FormDataSaveNum)
      console.log(this.tableDataSaveNum)
      // return
      if (this.title == '新建') {
        if (this.headFormSaveNum > 0 || this.FormDataListSaveNum > 0 || this.FormDataSaveNum > 0 || this.tableDataSaveNum > 0) {
          this.$confirm('检测到未保存数据，是否确认关闭?', '提示', {
            confirmButtonText: '确认关闭',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.Visible = false;
          })
        } else {
          this.Visible = false;
        }
      } else if (this.title == '编辑') {
        if (this.headFormSaveNum > 0 || this.FormDataListSaveNum > 0 || this.FormDataSaveNum > 0 || this.tableDataSaveNum > 3) {
          this.$confirm('检测到未保存数据，是否确认关闭?', '提示', {
            confirmButtonText: '确认关闭',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.Visible = false;
          })
        } else {
          this.Visible = false;
        }
      }
      console.log(this.headFormSaveNum)
      console.log(this.FormDataListSaveNum)
      console.log(this.FormDataSaveNum)
      console.log(this.tableDataSaveNum)
      // if (this.statusSaveNum > 1) {

      // } else {
      //   this.Visible = false;
      // }
    }
  },
  mounted() {
    console.log(this.$number.add(0.1, 0.2))
  },
  created() {
    this.tableHeight = (window.innerHeight * 340 - 40 - 30 - 30) < 300 ? 300 : (window.innerHeight - 340 - 40 - 30 - 30)
  },
  computed: {
    pushTableDataDisabled() {
      if (this.radio == '') {
        if (this.tablelouDatalist.length == 1) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }

    },
    //表格筛选
    filtersTable() {
      return function (text) {
        let arr = []
        if (this.radio) {

          arr = this.SearchTableData.filter(item => Boolean(item[text])).map(item => {

            return {
              text: item[text],
              value: item[text]
            }


          })
        } else {
          arr = this.tableData.filter(item => Boolean(item[text])).map(item => {

            return {
              text: item[text],
              value: item[text]
            }

          })
        }
        // console.log(arr, 'arr')
        let uniqueArr = arr.filter((item, index) => arr.findIndex(i => i.value === item.value) === index);
        return uniqueArr
      }
    },
    Floorfilters() {
      let arr = []
      if (this.radio) {
        arr = this.SearchTableData.filter(item => Boolean(item['floor'])).map(item => {
          let obj = {}
          obj = item.floorList?.find(el => el.Key === item.floor)
          return {
            text: obj?.Value || '',
            value: item.floor
          }
        })
      } else {
        arr = this.tableData.filter(item => Boolean(item['floor'])).map(item => {
          let obj = {}
          obj = item.floorList?.find(el => el.Key === item.floor)
          return {
            text: obj?.Value || '',
            value: item.floor
          }
        })
      }

      let uniqueArr = arr.filter((item, index) => arr.findIndex(i => i.value === item.value) === index);
      return uniqueArr
    },
    Buildingfilters() {
      let arr = []
      if (this.radio) {
        arr = this.SearchTableData.filter(item => Boolean(item['BuildingId'])).map(item => {
          return {
            text: item.BuildingCode,
            value: item.BuildingId
          }
        })
      } else {
        arr = this.tableData.filter(item => Boolean(item['BuildingId'])).map(item => {
          return {
            text: item.BuildingCode,
            value: item.BuildingId
          }
        })
      }

      let uniqueArr = arr.filter((item, index) => arr.findIndex(i => i.value === item.value) === index);
      return uniqueArr
    },
    activitiesfilters() {
      let arr = []
      if (this.radio) {
        arr = this.SearchTableData.filter(item => Boolean(item['BuildingFormatKey'])).map(item => {
          return {
            text: item.BuildingCode + '-' + item.BuildingFormatName,
            value: item.BuildingFormatKey
          }
        })
      } else {
        arr = this.tableData.filter(item => Boolean(item['BuildingFormatKey'])).map(item => {
          return {
            text: item.BuildingCode + '-' + item.BuildingFormatName,
            value: item.BuildingFormatKey
          }
        })
      }

      let uniqueArr = arr.filter((item, index) => arr.findIndex(i => i.value === item.value) === index);
      return uniqueArr
    },
    //小计功率指标
    DemandLoadDensity() {
      return this.$number.mut(this.$number.dev(this.PowerDemandLoad, this.headForm.BuildingArea), 1000)
    },
    // 小计装机指标(VA/㎡)
    TXLoadDensity() {
      return this.$number.mut(this.$number.dev(this.TransformerAllCapacity, this.headForm.BuildingArea), 1000)

    },
    // 主表装机负荷(kVA) 编辑 总装机容量（kVA）
    TransformerAllCapacity() {
      let num = 0
      this.FormDataList.forEach(item => {
        num = this.$number.add(num, this.$number.mut(item.TransformerCapacity || 0, item.TransformerCount || 0))
      })
      return num
    },
    //主表需求功率(kW) 编辑 /小计负荷需求(kW)
    PowerDemandLoad() {
      //  有功功率*同时使用系数=Demand Load (kW)  需求负荷 
      return this.$number.mut(this.totalNum, this.FormData.E_SUC)
    },
    //主表装机需求容量(kVA)  编辑 /装机需求容量
    PowerDemandCapacity() {
      // Demand Load (kW)  需求负荷 / 低压侧补偿后功率因数：=Demand TX Capacity * (KVA) 装机需求容量 
      return this.$number.dev(this.PowerDemandLoad, this.FormData.E_FALVSC)
    },
    // 变压器负载率
    TransformerLoadRate() {
      //  Demand TX Capacity * (KVA) 装机需求容量  / 总装机容量（kVA）= 变压器负载率  * 100 = 百分比%
      let loadRate = this.$number.dev(this.PowerDemandCapacity, this.TransformerAllCapacity)
      //  变压器负载率  * 100 = 百分比%
      return this.$number.mut(loadRate, 100)
    }
  },
  watch: {
    headForm: {
      deep: true,
      handler(val) {
        this.headFormSaveNum++
        console.log(this.headFormSaveNum, ' this.headFormSaveNum')
        console.log(val, 'headForm')

      }
    },
    FormDataList: {
      deep: true,
      handler(val) {
        this.FormDataListSaveNum++
        console.log(this.FormDataListSaveNum, ' this.FormDataListSaveNum')

        console.log(val, 'FormDataList')
      }
    },
    FormData: {
      deep: true,
      handler(val) {
        this.FormDataSaveNum++
        console.log(this.FormDataSaveNum, ' this.FormDataSaveNum')
        console.log(val, 'FormData')
      }
    },

    // 监听显示
    Visible(val) {
      if (!val) {
        this.$emit('close')
      }
    },
    //监听radio
    // radio(val) {
    // if (val) {
    //   if (val) {
    //     let obj = this.tablelouDatalist.find(item => item.value === val)
    //     if (obj) {
    //       let arr = this.tableData.filter(val => {
    //         return val.BuildingId === obj.parentValue && val.BuildingFormatKey === obj.value
    //       })
    //       if (arr.length == 0) {

    //         this.$confirm('当前楼栋业态暂无数据是否填充数据?', '提示', {
    //           confirmButtonText: '确定',
    //           cancelButtonText: '取消',
    //           type: 'warning'
    //         }).then(() => {
    //           this.tableDataAdd()
    //         })

    //       }
    //     }
    //   }


    // }
    // },
    //监听负载率
    TransformerLoadRate(a) {

      let data = []
      let xAxisData = []
      let color = ''
      if (a >= 85) {
        color = 'red'
      } else {

        color = '#91CC75'
      }

      this.FormDataList.forEach(item => {
        if (Number(item.TransformerCount) > 0) {
          for (let i = 0; i < item.TransformerCount; i++) {
            let obj = {
              name: item.TransformerCapacity + 'kVa',
              value: [{
                value: a.toFixed(2),
                itemStyle: {
                  color: color,

                }
              }]
            }
            data.push({ ...obj })
          }
        }

      })
      console.log(data, 'data')
      let seriesData = []
      data.forEach(item => {
        seriesData.push({
          name: item.name,
          type: 'bar',
          // stack: 'total',
          // barWidth: '50px',
          emphasis: {
            focus: 'series'
          },
          data: item.value
        })
      })
      // delete this.EchartsForm.yAxis.min
      // delete this.EchartsForm.yAxis.max
      this.EchartsForm.xAxis.data = [...xAxisData]
      this.EchartsForm.legend.show = true
      this.EchartsForm.legend.itemStyle.color = color
      this.EchartsForm.label.show = true
      this.EchartsForm.series = [...seriesData]
      console.log(this.EchartsForm, 'EchartsForm')
    },
    //是否全屏
    fullscreen(val) {
      if (val) {

        this.tableHeight = (window.innerHeight - 340 - 40 - 30 - 30) < 300 ? 300 : (window.innerHeight - 340 - 40 - 30 - 30)
        console.log(val, this.tableHeight)
      } else {

        this.tableHeight = (window.innerHeight * 0.9 - 340 - 40 - 30 - 30) < 300 ? 300 : (window.innerHeight * 0.9 - 340 - 40 - 30 - 30)
        console.log(val, this.tableHeight)
      }
    },
    tableData: {
      deep: true,
      handler(data) {
        console.log(data, this.tableDataSaveNum, '2222222222')
        this.tableDataSaveNum++
        //表格内计算
        let num = 0
        data.forEach(item => {

          this.$set(item, 'IsOnGround', item.floor?.split('-') ? item.floor.split('-')[0] : "")
          this.$set(item, 'Storey', item.floor?.split('-') ? item.floor.split('-')[1] : "")

          //   // 设备负载*需要系数=有功功率
          item.ActivePower = this.$number.mut(item.DemandCoefficient, item.EquipmentLoad).toFixed(3)
          //   // 总有功功率

          if (!item.LoadingCategories?.includes('消防')) {
            num = this.$number.add(num, item.ActivePower)
          }

        })
        this.totalNum = num
      }
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-dialog {

  .el-dialog__header {
    padding: 10 15px;
  }

  .el-dialog__body {
    padding: 10px 15px;
    overflow-x: hidden;
  }
}

::v-deep .scheme-stage-main-edit {
  margin: 0 auto 2.5vh;
}

::v-deep .content-Edit {
  height: 100%;
  // overflow-y: auto;
  display: flex;

  .el-input__inner {
    background: lemonchiffon;
  }

  .el-table td {
    padding: 0px 0 !important;
    height: 36px;

  }

  .el-table .el-input__inner {
    border: none !important;

    box-shadow: none;
    padding: 0 2px;
    height: 36px;
    font-size: 14px;
    text-align: center;
  }

  .el-table .cell {
    padding: 0 !important;
    height: 100%;


  }

  .el-table th.el-table__cell>.cell.highlight {
    color: #fff !important;
  }

  .el-table__column-filter-trigger {
    line-height: 23px;
  }


  .el-table .el-table__body .cell {
    line-height: 36px;
    height: 36px;
  }

  .el-form-item__label {
    font-size: 12px;
  }

  .left {
    // width: calc(100%);
    width: calc(100% - 240px);
    height: 100%;
    box-sizing: border-box;

    .left-top {
      height: 45px;

      .el-form-item--mini.el-form-item,
      .el-form-item--small.el-form-item {
        margin-bottom: 14px;
      }
    }

    .left-center {
      margin-top: 5px;
      margin-bottom: 5px;

      .el-form-item--mini.el-form-item,
      .el-form-item--small.el-form-item {
        margin-bottom: 5px;
      }
    }

    .form-item {

      display: flex;
      justify-content: space-between;

      .form-left {
        width: calc(35% + 100px);
        height: 165px;
        box-sizing: border-box;

      }

      .form-right {
        width: calc(65% - 100px);
        height: 165px;
        box-sizing: border-box;
        overflow: hidden;
      }
    }

    .left-table {
      overflow: auto;
      // min-height: calc(93vh - 500px);

    }

    // overflow-y: auto;
  }

  .right {
    width: 240px;
    box-sizing: border-box;

    overflow-y: hidden;
    overflow-x: hidden;

  }

  .el-divider--horizontal {
    margin: 0;
  }



  .bottomTotal {
    height: 35px;


    .grid-content.bg-purple {
      padding: 6px 0;
      flex: 1;
      background-color: #d7d7d7;
      border: 2px solid #a8a7a7;
      border-right: 0;
      text-align: center;
      font-size: 13px;
    }

    .grid-content.bg-purple-light {
      background-color: #f2f2f2;
      border: 2px solid #a8a7a7;
      border-right: 0;
      flex: 1;
      line-height: 30px;
      text-align: center;
    }
  }

  .el-cascader__tags {
    max-height: 40px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .el-statistic .con span {
    line-height: 30px;
    font-size: 16px;
  }

}

::v-deep .el-select__tags {
  max-height: 40px;
  overflow-y: auto;
  overflow-x: hidden;
}

::v-deep .el-input-group__append {
  padding: 0;
}

::v-deep .left-table {
  .el-table tbody tr:hover>td {
    font-weight: bold;
    color: #fff;
    background-color: #EA7500 !important
  }

  .el-table tbody tr:hover>td .el-input__inner {
    font-weight: bold;
    color: #fff !important;

    background-color: #EA7500 !important
  }

  .el-table tbody tr:hover>td .el-input__inner::-webkit-input-placeholder {
    color: #fff !important;
  }

  .el-table tbody tr:hover>td .el-input__inner:-ms-input-placeholder {
    color: #fff !important;
  }

  .el-table tbody tr:hover>td .el-input__inner::-moz-placeholder {
    color: #fff !important;
  }

  .el-table--enable-row-transition .el-table__body td.el-table__cell {
    transition: none
  }

  .el-table .el-input__inner {
    color: #000 !important;
  }



  .el-table .el-input.is-disabled .el-input__inner {
    background: #fff;
  }

  .el-table thead.is-group th.el-table__cell {
    background: #C02D20;
    color: #fff;
    font-weight: bold;
  }
}

::v-deep .hide-clear-icon .el-cascader__tags .el-tag__close {
  display: none;
}
</style>