<!-- 表格编辑详情组件 -->
<template>
  <div class="editDetail">
    <div :class="[isAmplify == true ? 'dialogOpen' : 'dialogClose']">
      <el-dialog :title="title" :visible.sync="dialogVisible" :width="dialogWidth" :before-close="handleClose"
        custom-class="import-dialog" :close-on-click-modal="false">
        <!--  -->
        <div style="
            border-bottom: 1px solid #ccc;
            font-size: 20px;
            padding-bottom: 10px;
            flex-direction: column;
          ">
          <!-- -->
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex; width: 100%; justify-content: space-between">
              <div style="align-items: center; flex: 1; margin-right: 15vw">
                <h3 style="margin: 0 30px 10px 0; padding: 0; font-weight: normal">
                  {{ "编辑商铺" + rowTitle }}
                </h3>
                <div style="display: flex; justify-content: space-between; font-size: 16px">
                  <div style="display: flex; justify-content: space-between; width: 100%">
                    <div style="font-size: 14px">
                      <span style="font-weight: 600">商铺编号:</span>
                      <el-tag v-if="childrenRow.ShopCode" type="danger" style="
                          text-align: center;
                          width: 8vw;
                          font-size: 14px;
                          margin-left: 10px;
                          line-height: 15px;
                          padding: 2px 10px;
                          height: 21px;
                        ">{{ childrenRow.ShopCode }}
                      </el-tag>
                    </div>
                    <div style="font-size: 14px">
                      <span style="font-weight: 600">工程编号:</span>
                      <el-tag v-if="childrenRow.ProjectCode" type="danger" style="
                          text-align: center;
                          width: 8vw;
                          font-size: 14px;
                          margin-left: 10px;
                          line-height: 15px;
                          padding: 2px 10px;
                          height: 21px;
                        ">{{ childrenRow.ProjectCode }}
                      </el-tag>
                    </div>
                    <div style="font-size: 14px; display: flex; align-items: center">
                      <span style="font-weight: 600; margin-right: 10px; line-height: 15px">商铺名称:</span>
                      <el-tooltip class="item" popper-class="my-tooltip" effect="dark" :content="childrenRow.ShopName"
                        placement="top" :open-delay="450">
                        <el-tag v-if="childrenRow.ShopName" type="danger" style="
                            text-align: center;
                            width: 9.2vw;
                            font-size: 14px;
                            height: 22px;
                            line-height: 20px; /* 调整为与商铺名称一致的行高 */
                            padding: 2px 10px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                          ">
                          {{ childrenRow.ShopName }}
                        </el-tag>
                      </el-tooltip>
                    </div>

                    <div style="font-size: 14px">
                      <span style="font-weight: 600">业态类型:</span>
                      <el-tag v-if="childrenRow.ShopFormatClass" type="danger" style="
                          text-align: center;
                          width: 6vw;
                          font-size: 14px;
                          margin-left: 10px;
                          line-height: 15px;
                          padding: 2px 10px;
                          height: 21px;
                        ">{{ childrenRow.ShopFormatClass }}
                      </el-tag>
                    </div>
                    <div style="font-size: 14px">
                      <span style="font-weight: 600">商铺面积:</span>
                      <el-tag v-if="childrenRow.ShopArea" type="danger" style="
                          text-align: center;
                          width: 6vw;
                          font-size: 14px;
                          margin-left: 10px;
                          line-height: 15px;
                          padding: 2px 10px;
                          height: 21px;
                        ">{{ childrenRow.ShopArea + "㎡" }}
                      </el-tag>
                    </div>
                    <div style="font-size: 14px">
                      <span style="font-weight: 600">版本:</span>
                      <el-tag v-if="childrenRow.ChangeVersion" type="danger" style="
                          text-align: center;
                          width: 6vw;
                          font-size: 14px;
                          margin-left: 10px;
                          line-height: 15px;
                          padding: 2px 10px;
                          height: 21px;
                        ">{{ childrenRow.ChangeVersion }}
                      </el-tag>
                    </div>
                  </div>
                </div>
                <div style="margin-top: 10px; display: flex">
                  <div style="font-size: 14px">
                    <span style="font-weight: 600">备注:</span>
                    <el-tag v-if="childrenRow.Remark" type="danger" style="
                        font-size: 14px;
                        margin-left: 10px;
                        line-height: 15px;
                        padding: 2px 10px;
                        height: 21px;
                      ">{{ childrenRow.Remark }}
                    </el-tag>
                  </div>
                  <div style="font-size: 14px; margin-left: 20px">
                    <span style="font-weight: 600">变更原因:</span>
                    <el-tag v-if="childrenRow.ShopChangeReason" type="danger" style="
                        text-align: center;
                        font-size: 14px;
                        margin-left: 10px;
                        line-height: 15px;
                        padding: 2px 10px;
                        height: 21px;
                      ">{{ childrenRow.ShopChangeReason }}
                    </el-tag>
                  </div>
                </div>
              </div>

              <div style="align-items: center">
                <div style="display: flex; align-items: center">
                  <div v-show="isRefresh == true" @click="tableClickRefresh" style="cursor: pointer">
                    <img style="width: 33px; margin-right: 10px" src="../../../assets/shuaxin-loading.gif" alt="" />
                  </div>
                  <div v-show="isRefresh == false" style="cursor: pointer" @click="tableClickRefresh">
                    <img style="width: 33px; margin-right: 10px" src="../../../assets/shuaxin.png" alt="" />
                  </div>
                  <!-- <div
                    v-show="isAmplify == true"
                    style="cursor: pointer;margin-right"
                    @click="amplifyChange"
                  >
                    <img
                      style="width: 86%"
                      src="../../../assets/suoxiao.png"
                      alt=""
                    />
                  </div> -->
                  <!-- <div
                    v-show="isAmplify == false"
                    style="cursor: pointer"
                    @click="amplifyChange"
                  >
                    <img
                      style="width: 85%"
                      src="../../../assets/fangda.png"
                      alt=""
                    />
                    <span></span>
                  </div> -->
                  <div style="cursor: pointer">
                    <img @click="handleClose" style="cursor: pointer; width: 33px" src="../../../assets/delete.png"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="border-top: 1px solid #e0dddd; height: 100%">
          <!--  调整弹窗里的内容距离弹窗边缘的高度  -->
          <div class="content">
            <div id="scrollContainer" class="left" style="height: calc(100vh - 200px);">
              <!-- :style="{ height: windowHeight - newWindowHeight + 'px' }" -->

              <!-- 商铺基础信息 -->
              <ShopBaseInfo v-if="rowTitle === '基础信息' && dialogVisible" ref="ShopBaseInfo" :parentObj="{ ...this }"
                @ShopFormatClassChange="ShopFormatClassChange" @closeDialog="handleClose" @editCancel="editCancel" />

              <!-- 设计信息 -->
              <DesignInfoJson v-if="rowTitle === '设计信息'" ref="DesignInfoJson" :parentObj="{ ...this }"
                @closeDialog="handleClose" />

              <!-- 工程信息 -->
              <EngineeringInfoJson v-if="rowTitle === '工程信息'" ref="EngineeringInfoJson" :parentObj="{ ...this }"
                @closeDialog="handleClose" :EngineeringStatusOptions="EngineeringStatusOptions" />

              <!-- 招商信息 -->
              <ContractInfoJson v-if="rowTitle === '招商信息'" ref="ContractInfoJson" :parentObj="{ ...this }"
                @closeDialog="handleClose" :ContractStatusOptions="ContractStatusOptions" />
            </div>
            <div class="right" :style="{ height: 75 + 'vh' }">
              <!-- :style="{ height: windowHeight - newWindowHeight + 'px' }" -->

              <!-- 日志子组件  @newLogListData="newLogListData"@tabClick="tabClick"-->
              <Log ref="Log" :Search="logSearchParams" />
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ContractOptions } from "./mock.js";
// 商铺基础信息
import ShopBaseInfo from "./form/ShopBaseInfo.vue";
import DesignInfoJson from "./form/DesignInfoJson.vue";
import EngineeringInfoJson from "./form/EngineeringInfoJson.vue";
import ContractInfoJson from "./form/ContractInfoJson.vue";
import Log from "./Log.vue";

export default {
  name: "WorkspaceJsonEditDetail",

  components: {
    ShopBaseInfo,
    DesignInfoJson,
    EngineeringInfoJson,
    ContractInfoJson,
    Log,
  },

  data() {
    return {
      EngineeringStatusOptions: [],//工程状态
      ContractStatusOptions: [],

      // 是否刷新
      isRefresh: false,
      // 是否放大缩小弹窗
      isAmplify: false,
      // 弹窗的整体宽度
      dialogWidth: "95%",
      // 首页传递过来的相关项目，任务应用id对象
      newTaskObj: {},
      windowHeight: 0,
      // 没有放大之前的弹窗整体宽度
      newWindowHeight: 120,
      // 放大后的弹窗整体宽度
      // newWindowHeight:-75,
      flag: false,
      // 弹窗标题
      title: "",
      // 是否显示弹窗
      dialogVisible: false,
      rules: {},
      form: {},
      childrenRow: {},
      // 业态级别参数 获取业态级别下拉参数
      ShopFormatClassQueryParams: {
        Condition: "DicKey",
        Keyword: "FormatClass",
      },
      // 业态名称参数 获取业态名称下拉参数
      ShopFormatNameQueryParams: {
        Condition: "DicKey",
        Keyword: "",
      },
      ShopFormatNameOptions: [],
      queryParams: {
        Search: {
          Condition: "ProjectId",
          Keyword: "",
        },
      },
      // 日志组件传递过来的获取日志列表传递的search参数
      logSearchParams: [
        {
          Condition: "ModuleType",
          Keyword: "",
        },
        {
          Condition: "ShopId", //当前项目当前商铺下的当前模块下的日志
          Keyword: "",
        },
      ],
      rowTitle: "",
    };
  },

  mounted() {
  },
  //************************************************ 控制某div区域滚动条的位置  必须卸载updated生命周期内。
  // 否则不生效 ************************************************参考链接 https://www.freesion.com/article/4961923464/  https://codeleading.com/article/89236387088/
  updated() {
    let scrollContainer = document.getElementById("scrollContainer");
    if (scrollContainer) {
      // 每次打开固定在地步************************************************
      // crollContainer.scrollTop = scrollContainer.scrollHeight;
      // 每次打开固定在顶部************************************************
      scrollContainer.scrollTop = 0;
    }
  },
  watch: {
    // 监听传递过来的图片数据  不要卸载created里面  否则会出现无数据的情况
    // windowHeight(val) { },
  },

  methods: {
    // 重新刷新日志列表
    refreshLog() {
      console.log("重新刷新日志列表了");
      // 子组件顶部标题
      this.$nextTick(function () {
        // 刷新日志列表
        this.$refs.Log.pagination.current = 1;
        this.$refs.Log.getLogListData();
      });
    },
    // 弹窗放大缩小
    amplifyChange() {
      this.isAmplify = !this.isAmplify;
      if (this.isAmplify) {
        // 当前是放大情况
        this.dialogWidth = "100%";
        this.newWindowHeight = -155;
      } else {
        // 当前是缩小情况
        this.dialogWidth = "95%";
        this.newWindowHeight = 150;
      }
    },
    // 手动刷新
    // 刷新表格
    tableClickRefresh() {
      var that = this;
      this.isRefresh = true;
      // 重新获取编辑详情
      this.getShopInfoTheListData(this.childrenRow.Id, this.childrenRow.rowTitle);
      // 重新获取日志列表
      this.$nextTick(function () {
        this.$refs.Log.pagination.current = 1;
        this.$refs.Log.getLogListData();
      });

      setTimeout(function () {
        // 取消刷新加载提示
        that.isRefresh = false;
        that.$message({
          message: "已刷新",
          type: "success",
          duration: 700,
        });
      }, 300);
    },
    //取消弹窗
    handleClose() {
      this.dialogVisible = false;
      // 说明此时需要刷新表格数据
      this.$emit("handleCloseResetTableChange");
      // if (done == true) {

      // }
    },
    //弹窗确定
    dialogVisibleYes() {
    },
    // 基础信息编辑传递过来的取消按钮方法
    editCancel() {
      // 重新获取编辑详情
      // 只有编辑的时候才调用详情
      this.getShopInfoTheListData(this.childrenRow.Id, this.rowTitle);
    },

    // 传递过来的子组件业态级别下拉选择方法
    ShopFormatClassChange(e) {
      this.ShopFormatNameQueryParams.Keyword = e[0].value;
      // 获取对应业态级别下的业态名称下拉数据
      this.getShopNameListData();
    },
    // 获取楼栋信息
    getBuildingListData() {
      this.$http
        .post("/Base_ProjectManage/Project_Building/GetDataList", this.queryParams)
        .then((res) => {
          if (res.Success) {
            // 判断是否存在楼栋信息 进行维护楼栋信息
            if (res.Data.length == 0) {
              this.$message.error("没有楼栋信息, 请先去维护楼栋信息");
              return;
            }
            let buildingList = res.Data.map((item) => {
              return {
                value: item.Id,
                // 楼栋编号
                label: item.BuildingCode,
              };
            });
            this.$nextTick(function () {
              // // 赋值楼栋下拉选择数据
              this.$refs.ShopBaseInfo.buildingOptions = buildingList;
              // 默认先赋值楼栋下拉。避免第一次进去没有下拉数据****************************************
              this.$refs.ShopBaseInfo.openSelectOptions("楼栋");
            });
          } else {
            this.entity.ProjectImg = "";
            this.$message.error(res.Msg);
          }
        });
    },
    // 获取级别相关下拉数据
    getFormatClassListData() {
      this.$http
        .post(
          "/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey",
          this.ShopFormatClassQueryParams
        )
        .then((res) => {
          if (res.Success) {
            let ShopFormatNameOptions = res.Data.DictionaryDetailsList.map((item) => {
              return {
                value: item.DicValue,
                label: item.DicName,
              };
            });
            this.ShopFormatNameOptions = ShopFormatNameOptions;
            this.$nextTick(function () {
              // // 赋值业态级别下拉选择数据
              this.$refs.ShopBaseInfo.ShopFormatClassOptions = ShopFormatNameOptions;

              // 默认先赋值业态级别下拉。避免第一次进去没有下拉数据****************************************
              this.$refs.ShopBaseInfo.openSelectOptions("业态级别");
            });
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 获取业态名称下拉相关数据
    // 获取业态名称相关下拉数据
    getShopNameListData() {
      // return;
      this.$http
        .post(
          "/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey",
          this.ShopFormatNameQueryParams
        )
        .then((res) => {
          if (res.Success) {
            // 判断是否有返回数据 拦截
            if (res.Data == null) return;
            let ShopFormatNameOptions;
            if (res.Data.DictionaryDetailsList) {
              ShopFormatNameOptions = res.Data.DictionaryDetailsList.map((item) => {
                return {
                  value: item.Id,
                  label: item.DicName,
                };
              });
            }
            // 赋值业态名称下拉选择数据
            this.$nextTick(function () {
              // // 赋值业态名称下拉选择数据
              this.$refs.ShopBaseInfo.ShopFormatNameOptions = ShopFormatNameOptions;
              // 默认先赋值业态类型名称下拉。避免第一次进去没有下拉数据****************************************
              this.$refs.ShopBaseInfo.openSelectOptions(
                "业态类型",
                ShopFormatNameOptions
              );
            });
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    init() {
      // 弹窗
      this.dialogVisible = true;
    },
    // 获取编辑详情数据
    getShopInfoTheListData(Id, rowTitle) {
      this.$http
        .post("/Shop_Manage/Shop_BaseInfo/GetTheData", {
          Id: Id,
        })
        .then((res) => {
          // this.Form = res.Data;
          // 计算用电指标  用电量/商铺面积
          let EnergyUseIndex = 0;
          // 计算空调冷指标   冷负荷量/商铺面积
          let AirconditionCoolingIndex = 0;
          if (res.Data.PowerConsumption !== "" && res.Data.ShopArea !== "") {
            EnergyUseIndex =
              Number(res.Data.PowerConsumption) / Number(res.Data.ShopArea);
          }
          if (res.Data.PowerConsumption !== "" && res.Data.ShopArea !== "") {
            AirconditionCoolingIndex =
              Number(res.Data.CoolingLoadCapacity) / Number(res.Data.ShopArea);
          }
          // 用电指标和空调冷指标赋值
          res.Data.EnergyUseIndex = EnergyUseIndex.toFixed(2);
          res.Data.AirconditionCoolingIndex = AirconditionCoolingIndex.toFixed(2);

          // 设置相关审核状态的默认值
          // 内审 状态
          if (!res.Data.StatusInternalAudit) {
            this.$set(res.Data, "StatusInternalAudit", "1");
          }
          // 外审状态
          if (!res.Data.StatusExternalAudit) {
            this.$set(res.Data, "StatusExternalAudit", "1");
          }
          // 设备材料审核状态
          if (!res.Data.StatusEAMR) {
            this.$set(res.Data, "StatusEAMR", "1");
          }

          this.loading = false;
          if (res.Success) {
            if (res.Data.IsOnGround == 1) {
              res.Data.IsOnGround = "地上";
            } else if (res.Data.IsOnGround == 2) {
              res.Data.IsOnGround = "地下";
            } else {
              res.Data.IsOnGround = "屋面";
            }
            if (rowTitle === "基础信息") {
              this.ShopFormatNameQueryParams.Keyword = "";
              this.$nextTick(function () {
                // 给编辑基础信息组件
                // 判断返回的业态是哪个类型  后续需要动态修改   **********************************************

                let ShopFormatClassOptions = this.ShopFormatNameOptions;
                console.log(this.ShopFormatNameOptions, 518);
                ShopFormatClassOptions.forEach((item) => {
                  console.log(1234);
                  if (res.Data.ShopFormatClass === item.label) {
                    console.log(12222);
                    this.ShopFormatNameQueryParams.Keyword = item.value;
                  }
                });
                // 获取业态名称下拉数据
                this.getShopNameListData();
                this.$refs.ShopBaseInfo.Form = {};
                this.$refs.ShopBaseInfo.selectOptionsChange(res.Data);
                this.$refs.ShopBaseInfo.Form = res.Data; //详情表单赋值
                this.$refs.ShopBaseInfo.uoloadTypeImage = res.Data.ShopImage; //图片赋值
                // // 单独处理楼栋编号回显
                // let buildingOptions = this.$refs.ShopBaseInfo.buildingOptions;
                // // let buildingCode =
                // //   .filter(
                // //     (item) => item.value === res.Data.BuildingId
                // //   );
                // console.log(
                //   res.Data,
                //   res.Data.BuildingId,
                //   buildingOptions,
                //   res.Data.buildingCode,
                //   "获取楼栋里诶博爱处理---------------------"
                // );
              });
            } else if (rowTitle === "设计信息") {
              this.$nextTick(function () {
                this.$refs.DesignInfoJson.init();

                // 给编辑设计信息组件
                this.$refs.DesignInfoJson.Form = res.Data;
                this.$refs.DesignInfoJson.ShopInfo = res.Data;
                //动态增框的值赋值
                res.Data.ElectricalBox
                  ? (this.$refs.DesignInfoJson.ElectricalBox = res.Data.ElectricalBox)
                  : "";
                // this.$refs.DesignInfoJson.ElectricalBox = res.Data.ElectricalBox;
                if (res.Data.AirConditioningCondition) {
                  this.$refs.DesignInfoJson.AirConditioningCondition =
                    res.Data.AirConditioningCondition;
                  res.Data.AirConditioningCondition.AC_ColdWaterPipeDiameter.length > 0
                    ? (this.$refs.DesignInfoJson.AC_ColdWaterPipeDiameter =
                      res.Data.AirConditioningCondition.AC_ColdWaterPipeDiameter)
                    : "";
                  res.Data.AirConditioningCondition.AC_HotWaterPipeDiameter.length > 0
                    ? (this.$refs.DesignInfoJson.AC_HotWaterPipeDiameter =
                      res.Data.AirConditioningCondition.AC_HotWaterPipeDiameter)
                    : "";
                  res.Data.AirConditioningCondition.FreshAirPipelineSize.length > 0
                    ? (this.$refs.DesignInfoJson.FreshAirPipelineSize =
                      res.Data.AirConditioningCondition.FreshAirPipelineSize)
                    : "";
                }
                if (res.Data.VentilationCondition) {
                  this.$refs.DesignInfoJson.VentilationCondition =
                    res.Data.VentilationCondition;
                  res.Data.VentilationCondition.KitchenExhaustPipeSize.length > 0
                    ? (this.$refs.DesignInfoJson.KitchenExhaustPipeSize =
                      res.Data.VentilationCondition.KitchenExhaustPipeSize)
                    : "";
                  res.Data.VentilationCondition.KitchenNormalAccidentExhaustPipeSize
                    .length > 0
                    ? (this.$refs.DesignInfoJson.KitchenNormalAccidentExhaustPipeSize =
                      res.Data.VentilationCondition.KitchenNormalAccidentExhaustPipeSize)
                    : "";
                  res.Data.VentilationCondition.KitchenAirReplenishmentPipeSize.length > 0
                    ? (this.$refs.DesignInfoJson.KitchenAirReplenishmentPipeSize =
                      res.Data.VentilationCondition.KitchenAirReplenishmentPipeSize)
                    : "";
                  res.Data.VentilationCondition.IndividualProtectiveExhaustAirPipeSize
                    .length > 0
                    ? (this.$refs.DesignInfoJson.IndividualProtectiveExhaustAirPipeSize =
                      res.Data.VentilationCondition.IndividualProtectiveExhaustAirPipeSize)
                    : "";
                }
                res.Data.WeakCurrentBoxInfo
                  ? (this.$refs.DesignInfoJson.WeakCurrentBoxInfo =
                    res.Data.WeakCurrentBoxInfo)
                  : "";
                res.Data.WaterSupplyPipeSize
                  ? res.Data.WaterSupplyPipeSize.length == 0
                    ? (this.$refs.DesignInfoJson.WaterSupplyPipeSize = [""])
                    : (this.$refs.DesignInfoJson.WaterSupplyPipeSize =
                      res.Data.WaterSupplyPipeSize)
                  : (this.$refs.DesignInfoJson.WaterSupplyPipeSize = [""]);
                res.Data.CateringDrainagePipeSize
                  ? res.Data.CateringDrainagePipeSize.length == 0
                    ? (this.$refs.DesignInfoJson.CateringDrainagePipeSize = [""])
                    : (this.$refs.DesignInfoJson.CateringDrainagePipeSize =
                      res.Data.CateringDrainagePipeSize)
                  : [""];
                res.Data.NonCateringDrainagePipeSize
                  ? res.Data.NonCateringDrainagePipeSize.length == 0
                    ? (this.$refs.DesignInfoJson.NonCateringDrainagePipeSize = [""])
                    : (this.$refs.DesignInfoJson.NonCateringDrainagePipeSize =
                      res.Data.NonCateringDrainagePipeSize)
                  : [""];
                res.Data.GasConnectingPipeSize
                  ? res.Data.GasConnectingPipeSize.length == 0
                    ? (this.$refs.DesignInfoJson.GasConnectingPipeSize = [""])
                    : (this.$refs.DesignInfoJson.GasConnectingPipeSize =
                      res.Data.GasConnectingPipeSize)
                  : [""];
                //   电气数组处理
                res.Data.ElectricalBox
                  ? res.Data.ElectricalBox.length == 0
                    ? (this.$refs.DesignInfoJson.ElectricalBox = [
                      { id: new Date().getTime() + "2" },
                    ])
                    : (this.$refs.DesignInfoJson.ElectricalBox = res.Data.ElectricalBox)
                  : [{ id: new Date().getTime() + "2" }];
              });
            } else if (rowTitle === "工程信息") {
              this.$nextTick(function () {
                // 给编辑工程信息组件
                this.$refs.EngineeringInfoJson.Form = res.Data;
              });
              // 图片回显处理
              this.getImageCovers(res.Data);
            } else if (rowTitle === "招商信息") {
              // 给编辑招商信息组件
              this.$refs.ContractInfoJson.Form = res.Data;
            }

            // 用于判断当前行数据的版本提交状态
            // this.rowData = res.Data

            // 存储vux  商铺信息
            this.$store.commit("getShopInfo", res.Data);
          } else {
            this.loading = false;
          }
        });
    },

    // 图片回显
    getImageCovers(data) {
      let fileDataInternalAudit = []; //内审回显
      let fileDataExternalAudit = []; //外审回显
      let fileDataEquipmentAndMater = []; //设备材料审核回显

      if (data) {
        // 判断是否存再内审 ，外审。设备材料审批图片数据  // 图片回显处理
        if (data.InternalAuditUrl) {
          //内审图片
          let arr = data.InternalAuditUrl.split(",");
          fileDataInternalAudit = arr.map((item) => {
            return {
              // name: item.substr(-10),
              name: item.substring(89, item.length),
              url: item,
            };
          });
        }
        if (data.ExternalAuditUrl) {
          //外审图片
          let arr = data.ExternalAuditUrl.split(",");
          fileDataExternalAudit = arr.map((item) => {
            return {
              // name: item.substr(-10),
              name: item.substring(89, item.length),
              url: item,
            };
          });
        }
        if (data.EquipmentAndMaterFile) {
          //设备材料审批图片
          let arr = data.EquipmentAndMaterFile.split(",");
          fileDataEquipmentAndMater = arr.map((item) => {
            return {
              // name: item.substr(-10),
              name: item.substring(89, item.length),
              url: item,
            };
          });
        }

        this.$nextTick(function () {
          // 给编辑工程信息组件
          this.$refs.EngineeringInfoJson.fileDataInternalAudit = fileDataInternalAudit;
          this.$refs.EngineeringInfoJson.fileDataExternalAudit = fileDataExternalAudit;
          this.$refs.EngineeringInfoJson.fileDataEquipmentAndMater = fileDataEquipmentAndMater;
        });
      }
    },

    // 打开弹窗
    open(obj, newTaskObj) {
      console.log(newTaskObj, 123);
      this.rowTitle = obj.rowTitle;
      this.childrenRow = obj.row;
      console.log("获取childrenRow", this.childrenRow);
      this.newTaskObj = newTaskObj;
      // 赋值项目id
      this.queryParams.Search.Keyword = newTaskObj.ProjectId;
      // 初始化缩小当前弹窗
      // 当前是缩小情况
      this.isAmplify = false;
      this.dialogWidth = "95%";
      this.newWindowHeight = 150;

      if (obj.rowTitle === "基础信息") {
        // 初始化业态级别参数
        this.ShopFormatClassQueryParams.Keyword = "FormatClass";
        // 获取业态级别
        this.getFormatClassListData();
        // 获取楼栋列表数据
        this.getBuildingListData();

        this.logSearchParams[0].Keyword = "Shop_BaseInfo";
        this.logSearchParams[1].Keyword = obj.row.Id;
        // 子组件顶部标题
        this.$nextTick(function () {
          // 表单初始化
          this.$refs.ShopBaseInfo.resetForm();
          this.$refs.ShopBaseInfo.title = obj.rowTitle;
          this.$refs.ShopBaseInfo.newTaskObj = newTaskObj;
          //   清除表单校验
          this.$refs.ShopBaseInfo.clearValidate();
          // 初始化禁用保存按钮
          this.$refs.ShopBaseInfo.shopBaseInfoDisabled = true;
          // 刷新日志列表
          this.$refs.Log.pagination.current = 1;
          this.$refs.Log.getLogListData();
        });
      } else if (obj.rowTitle === "设计信息") {
        this.logSearchParams[0].Keyword =
          "ElectricalMajor|HVACMajor|WSADMajor|IntelligentMajor|StructureMajor";
        this.logSearchParams[1].Keyword = obj.row.Id;
        // 子组件顶部标题
        this.$nextTick(function () {
          this.$refs.DesignInfoJson.title = obj.rowTitle;
          this.$refs.DesignInfoJson.newTaskObj = newTaskObj;
          this.$refs.DesignInfoJson.rows = obj.row;
          // 初始化禁用保存按钮
          this.$refs.DesignInfoJson.shopBaseInfoDisabled = true;
          // 电器禁用
          this.$refs.DesignInfoJson.ElectricalMajorDisabled = true;
          // 暖通禁用
          this.$refs.DesignInfoJson.HVACMajorDisabled = true;
          // 给排水禁用
          this.$refs.DesignInfoJson.WSADMajorDisabled = true;
          // 智能化禁用
          this.$refs.DesignInfoJson.IntelligentMajorDisabled = true;
          // 结构禁用
          this.$refs.DesignInfoJson.StructureMajorDisabled = true;
          // 刷新日志列表
          this.$refs.Log.pagination.current = 1;
          this.$refs.Log.getLogListData();
        });
      } else if (obj.rowTitle === "工程信息") {
        this.logSearchParams[0].Keyword =
          "EngineeringBaseInfo|DrawingApproval|EquipmentAndMaterialReview";
        this.logSearchParams[1].Keyword = obj.row.Id;
        // 子组件顶部标题
        this.$nextTick(function () {
          this.$refs.EngineeringInfoJson.title = obj.rowTitle;
          this.$refs.EngineeringInfoJson.newTaskObj = newTaskObj;
          this.$refs.EngineeringInfoJson.rows = obj.row;


          this.getEngineeringStatusOptions()
          // 初始化禁用保存按钮
          // 基本信息禁用
          this.$refs.EngineeringInfoJson.EngineeringBaseInfoDisabled = true;
          // 图纸报审禁用
          this.$refs.EngineeringInfoJson.DrawingApprovalDisabled = true;
          // 设备审核禁用
          this.$refs.EngineeringInfoJson.EquipmentAndMaterialReviewDisabled = true;

          // this.$refs.EngineeringInfoJson.Form.StatusInternalAudit = 1

          // 刷新日志列表
          this.$refs.Log.pagination.current = 1;
          this.$refs.Log.getLogListData();
        });
      } else if (obj.rowTitle === "招商信息") {
        this.logSearchParams[0].Keyword = "ContractInfoJson";
        this.logSearchParams[1].Keyword = obj.row.Id;
        // 子组件顶部标题
        this.$nextTick(function () {
          this.$refs.ContractInfoJson.title = obj.rowTitle;
          this.$refs.ContractInfoJson.newTaskObj = newTaskObj;
          this.$refs.ContractInfoJson.rows = obj.row;
          this.getStatusOptions()
          // 初始化禁用保存按钮
          //  禁用合约信息
          this.$refs.ContractInfoJson.ContractInfoJsonDisabled = true;
          // 刷新日志列表
          this.$refs.Log.pagination.current = 1;
          this.$refs.Log.getLogListData();
        });
      }

      // 只有编辑的时候才调用详情
      this.getShopInfoTheListData(this.childrenRow.Id, obj.rowTitle);
      if (obj.rowTitle === "") {
        return;
      }
      // 获取
      this.init();
    },

    // 自定义关闭弹窗
    handelColse() {
      this.dialogVisible = false;
    },
    async getEngineeringStatusOptions() {
      await this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: "EngineeringStatus",
        })
        .then((res) => {
          if (res.Success) {

            let EngineeringStatusOptions = res.Data.DictionaryDetailsList
            // .map(
            //   (item) => {
            //     return {
            //       value: item.Id,
            //       label: item.DicName,
            //     };
            //   }
            // );
            this.EngineeringStatusOptions = [...EngineeringStatusOptions];
            // this.ContractStatusOptions = JSON.parse(JSON.stringify([{
            //   DicName: '其他',
            //   DicValue: "其他",
            //   ChangeTime: ''
            // }, ...this.EngineeringStatusOptions]))
          }
        });
    },
    getStatusOptions() {
      this.ContractStatusOptions = JSON.parse(JSON.stringify(ContractOptions))
    },
  },
};
</script>

<style lang="scss" scoped>
.margin1 {
  margin-top: 0 !important;
}

.margin2 {
  margin-top: 3px !important;
}

::-webkit-scrollbar {
  width: -1px !important;
}

.editDetail {

  /*改变elemnt弹窗的上边距,内边距等 height: 80vh;
    */
  /* // 放大弹窗宽高 */
  .dialogOpen {

    /* //弹出层的高度//弹出层的高度防止鼠标放大缩小屏幕都会出现弹窗右侧本身的滚动条问题************************************** */
    ::v-deep .el-dialog {
      margin: 0 auto !important;

      /* //弹出层里内容的高度 */
      .el-dialog__body {
        padding: 18px 20px !important;
        /* // max-height: 50vh !important; */
      }
    }

    ::v-deep .el-dialog.import-dialog {
      /* // height: auto;
      // max-height: 80vh; */
      /* //弹出层的高度防止鼠标放大缩小屏幕都会出现弹窗右侧本身的滚动条问题************************************** */
      height: 100% !important;
      overflow: hidden;

      /* //覆盖element 弹窗dialog关闭按钮的样式 */
      .el-dialog__header {
        padding: 0;

        .el-dialog__headerbtn {
          .el-dialog__close {
            color: transparent;
          }
        }
      }
    }
  }

  // 原始弹窗宽高
  .dialogClose {

    /* //弹出层的高度防止鼠标放大缩小屏幕都会出现弹窗右侧本身的滚动条问题************************************** */
    ::v-deep .el-dialog {
      /* //弹出层的高度防止鼠标放大缩小屏幕都会出现弹窗右侧本身的滚动条问题**************************************
      // height: 98%; */
      overflow: hidden;
      margin: 0 !important;

      /* //弹出层里内容的高度 */
      /* //弹出层的高度防止鼠标放大缩小屏幕都会出现弹窗右侧本身的滚动条问题************************************** */
      .el-dialog__body {
        padding: 21px 20px !important;
        height: 90vh !important;
        overflow-y: hidden;
      }
    }

    ::v-deep .el-dialog__wrapper {
      /* //防止鼠标放大缩小屏幕都会出现弹窗布局中屏幕的问题************************************** */
      display: flex;
      justify-content: center;
      align-items: center;

      .el-dialog.import-dialog {
        height: auto;
        /* // max-height: 80vh; */
        overflow-y: auto;

        /* //覆盖element 弹窗dialog关闭按钮的样式 */
        .el-dialog__header {
          padding: 0;

          .el-dialog__headerbtn {
            .el-dialog__close {
              color: transparent;
            }
          }
        }
      }
    }
  }

  .dialogClose,
  .dialogOpen {
    .content {
      .left {}

      .right {}
    }
  }

  .content {
    display: flex;

    .left {
      border-right: 1px solid #e2e2e2;
      flex: 8;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
    }

    .right {
      flex: 1.42;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
