<!-- 大表格  父组件-->
<template>
  <div class="container">
    <div class="dialog">
      <el-dialog
        title="导出成果"
        :visible.sync="dialogVisible"
        width="100%"
        :before-close="handleClose"
        custom-class="import-dialog"
      >
        <div>
          <table-item
            v-if="columnChange"
            :tableData="tableData"
            :col="col"
            @handleSelectionChange="handleSelectionChange"
            :isShow="isShow"
            :loading="loading"
            @parentSetIsShow="parentSetIsShow"
            @handleDetailChange="handleDetailChange"
            @handleDeleteChange="handleDeleteChange"
            @hiddenColumn="hiddenColumn"
            @showColumn="showColumn"
            @handleSubmitButtonChange="handleSubmitButtonChange"
            :isTable="isTable"
          ></table-item>
          <div
            v-if="isTable == false"
            style="display: flex; justify-content: flex-end"
          >
            <pagination
              :total="pagination.total"
              :page.sync="pagination.PageIndex"
              :limit.sync="pagination.PageRows"
              @pagination="handlePageChange"
            />
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
// 模拟数据
import {
  // tableData,
  col,
  newCol,
} from "./mock.js";
// 引入递归子组件
import tableItem from "../view/tableItem.vue";

export default {
  name: "AttractInvestment",
  components: {
    // Edit,
    // UpdateRecord,
    // TableDetail,
    tableItem,
    Pagination,
  },
  props: {
    parentObj: {
      type: Object,
    },
  },
  data() {
    return {
      // 表格加载中提示
      loading: false,
      dialogVisible: false,
      sortShow: false, // 排序展示隐藏
      filterName: "", // 表格筛选方案展示名称
      filterList: [], // 表格筛选方案
      treeOptionName: "", // 树菜单保存方案名称
      searchList: [], // 查询筛选项列表
      searchKey: "", // 查询Key值
      keyword: "", // 查询字段
      importLoading: false, // 导入loading状态
      dialogVisible: false, // 导入弹窗
      columnChange: true, // 表格重置卸载
      treeData: [], // 列表显示隐藏树菜单数据
      treeShow: false, // 树菜单展示隐藏
      defaultKeys: [], // 树菜单默认选中项
      defaultProps: {
        // 树菜单format
        children: "children",
        label: "DicName",
      },
      pagination: {
        PageIndex: 1,
        PageRows: 50,
        total: 0,
      },
      SortInfos: [
        {
          SortField: "ShopArea",
          SortType: "desc",
        },
      ],
      queryParam: [
        {
          Condition: "ProjectId",
          Keyword: "",
        },
        {
          Condition: "TaskId",
          Keyword: "",
        },
      ],
      // 是否显示编辑和查看
      isShow: 1,
      //树型结构表头数据
      col: col,
      // 用于渲染新建或编辑组件
      newCol: newCol,
      //表格数据
      tableData: [],
      isTable: false,
    };
  },
  created() {
    localStorage.removeItem("fileDataInternalAudit");
    localStorage.removeItem("fileDataExternalAudit");
    localStorage.removeItem("fileDataEquipmentAndMater");
    this.queryParam[0].Keyword = this.$route.query.id;
  },
  mounted() {
    //获取大表格整体数据
    this.GetTreeDataListByDicKey();
    // 列表展示方案
    // this.getOptionsData();
    // 列表数据
    // this.getDataList();
  },
  methods: {
    // 抽屉弹窗关闭
    closeDrawer() {
      // 刷新表格数据
      this.tableClickRefresh();
    },

    // 分页函数
    handlePageChange(data) {
      this.pagination.PageIndex = data.pageIndex;
      this.pagination.PageRows = data.pageSize;
      this.getDataList(); //这是重新获取修改日志列表
    },
    // 表格头点击隐藏列
    hiddenColumn(key) {
      this.columnChange = false;
      /**
       * tree数据源绑定col使用以下方法
        this.$set(e, "show", isCheck);
       */
      let idx = this.col.findIndex((item) => item.DicKey == key);
      this.col[idx].show = false;
      this.$nextTick(() => {
        this.columnChange = true;
      });
    },
    // 表格头点击显示列
    showColumn(key) {
      this.columnChange = false;
      /**
       * tree数据源绑定col使用以下方法
        this.$set(e, "show", isCheck);
       */
      let idx = this.col.findIndex((item) => item.DicKey == key);
      this.col[idx].show = true;
      this.$nextTick(() => {
        this.columnChange = true;
      });
    },
    // 新建商铺信息
    tableClickAdd() {
      let Object = {
        data: this.col,
        title: "新建",
        ProjectId: this.$route.query.id, //项目id
      };
      this.$refs.Edit.open(Object);
    },

    // 刷新表格
    tableClickRefresh() {
      // const loading = this.$loading({
      //     lock: true,
      //     text: '加载中...',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(0, 0, 0, 0.7)'
      //   });

      // 刷新表格
      this.loading = true;
      this.queryParam[0].Condition = "ProjectId";
      // 初始化页数
      this.pagination.PageIndex = 1;
      // 初始化表格数据
      this.tableData = [];
      // 获取表格数据
      this.getDataList();
      // 刷新列表展示方案
      // this.getOptionsData();
      // setTimeout(() => {
      //   loading.close();
      // }, 500);
    },
    init() {
      // tree数据源绑定col使用以下方法
      // this.defaultKeys = [];
      // this.col.forEach((item) => {
      //   if (item.children && item.children.length > 0) {
      //     this.defaultKeys.push(item.DicKey);
      //     this.getDefaultProp(item.children);
      //   }
      // });
      this.treeData = this.col.map((item) => {
        return {
          DicKey: item.DicKey,
          DicName: item.DicName,
        };
      });
      this.defaultKeys = this.col.map((item) => item.DicKey);
      // 查询条件
      this.searchList = [];
      this.getSearchKey(this.col);
    },
    // 移除方案
    // handleRemoveTag(e) {
    //   this.$http
    //     .post("/Shop_Manage/Shop_ColumnsScheme/DeleteData", [e.Id])
    //     .then((res) => {
    //       this.getOptionsData();
    //     });
    // },
    // 排序升降选择
    sortIconClick(item, sort) {
      this.$set(item, "sort", sort);
      if (!item.checked) this.$set(item, "checked", true);
    },
    // 排序重置
    sortReset() {
      this.searchList.forEach((item) => {
        this.$set(item, "checked", false);
        this.$set(item, "sort", "asc");
      });
      this.sortShow = false;
    },
    // 排序保存
    sortConfirm() {
      let newArr = this.searchList.filter((item) => item.checked);
      this.SortInfos = newArr;
      this.sortShow = false;
      this.getDataList();
    },
    // 表格列隐藏方案保存
    // optionConfirm() {
    //   if (!this.treeOptionName) return this.$message.error("请填写方案名称");
    //   let arr = this.col.filter((item) => item.show).map((item) => item.DicKey);
    //   this.$http
    //     .post("/Shop_Manage/Shop_ColumnsScheme/SaveData", {
    //       SchemeName: this.treeOptionName,
    //       SchemeContent: JSON.stringify(arr),
    //     })
    //     .then((res) => {
    //       if (res.Success) {
    //         this.getOptionsData();
    //         this.$message.success("操作成功");
    //         this.treeOptionName = "";
    //         this.treeShow = false;
    //       } else this.$message.error(res.Msg);
    //     });
    // },
    // 表格列隐藏方案选择
    handleCommand(e) {
      this.filterName = e.SchemeName;
      // this.columnChange = false;
      this.col.forEach((item) => {
        if (e.SchemeContent.includes(item.DicKey)) {
          this.$refs.tree.setChecked(item.DicKey, true, false);
        } else {
          this.$refs.tree.setChecked(item.DicKey, false, false);
        }
      });
      // this.$nextTick(() => {
      //   this.columnChange = true;
      // });
    },
    // 表格筛选项
    getSearchKey(arr) {
      arr.forEach((item) => {
        if (!item.children || item.children.length == 0) {
          if (item.DicName == "备注") return;
          this.searchList.push({
            SortField: item.DicKey,
            DicName: item.DicName,
            SortType: "asc",
            checked: false,
          });
        } else this.getSearchKey(item.children);
      });
    },
    // 树默认选中
    getDefaultProp(arr) {
      arr.forEach((item) => {
        if (item.children && item.children.length > 0) {
          this.defaultKeys.push(item.DicKey);
          this.getDefaultProp(item.children);
        }
      });
    },
    // 树选择
    nodeClick(e, isCheck) {
      // console.log(e.DicName, isCheck);
      this.columnChange = false;
      /**
       * tree数据源绑定col使用以下方法
        this.$set(e, "show", isCheck);
       */
      let idx = this.col.findIndex((item) => item.DicKey == e.DicKey);
      this.col[idx].show = isCheck;
      this.$nextTick(() => {
        this.columnChange = true;
      });
    },
    // 编辑
    handleEditChange() {
      this.$refs.Edit.openForm();
    },
    handleCellEnter() {},
    handleCellLeave() {},

    // 显示编辑页面
    handleSelectionChange(title, data, nodeObj) {
      var that = this;
      if (data.Status == 3) {
        this.$confirm("是否需要变更当前版本?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that.$http
              .post("/Shop_Manage/Shop_BaseInfo/UpdateDataByCondition", {
                Conditions: [
                  {
                    Condition: "Status",
                    Keyword: "1",
                  },
                ],
                Ids: [data.Id],
              })
              .then((res) => {
                if (res.Success) {
                  // setTimeout(() => {
                  //   loading.close();
                  // }, 500);
                  let obj = {
                    // 传递当前行id到子组件重新获取请求渲染组件
                    title: "编辑",
                    data: data.Id,
                    ProjectId: this.$route.query.id, //项目id
                  };
                  if (nodeObj) that.$refs.Edit.open(obj, nodeObj);
                  else that.$refs.Edit.open(obj);

                  // 刷新当前列表
                  that.getDataList();
                } else {
                  that.$message.error(res.Msg);
                }
              });
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '点击取消时调用的方法'
            // });
          });

        return;
      }

      let obj = {
        // 传递当前行id到子组件重新获取请求渲染组件
        title: "编辑",
        data: data.Id,
        ProjectId: this.$route.query.id, //项目id
      };
      if (nodeObj) that.$refs.Edit.open(obj, nodeObj);
      else this.$refs.Edit.open(obj);
    },
    // 点击查看事件
    handleDetailChange(data) {
      // 显示查看组件
      // this.isShow = 2;
      this.$refs.updateRecord.open(data);
    },
    // 删除
    handleDeleteChange(data) {
      var that = this;
      this.$confirm("此操作将当前条删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((result) => {
          // 刷新表格
          that.loading = true;
          const loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that.$http
            .post("/Shop_Manage/Shop_BaseInfo/DeleteData", [data.Id])
            .then((res) => {
              if (res.Success) {
                setTimeout(() => {
                  loading.close();
                  that.$message.success("已删除");
                }, 500);
                //  更新表格数据
                that.getDataList();
              } else {
                that.$message.error(res.Msg);
              }
            });
        })
        .catch((msg) => {});
    },

    // 当前行版本提交
    handleSubmitButtonChange(row) {
      var that = this;
      this.$confirm(
        "此操作将提交当前商铺版本,无法再次修改,  是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        // 刷新表格
        that.loading = true;
        const loading = this.$loading({
          lock: true,
          text: "版本提交中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        that.$http
          .post(
            "/Shop_Manage/Shop_BaseInfo/ShopInfo_VersionSubmission?shopId=" +
              row.Id
          )
          .then((res) => {
            if (res.Success) {
              setTimeout(() => {
                loading.close();
                that.$message.success("版本提交成功");
              }, 500);
              //  更新表格数据
              that.getDataList();
            } else {
              that.$message.error(res.Msg);
            }
          });
      });
    },
    // 返回招商组件
    back() {
      // 显示表格
      this.isShow = 1;
    },
    // tableitem子组件传递过来的方法  是否隐藏显示当前表格
    parentSetIsShow(show, data) {
      if (show == 4) {
        // 打开版本记录组件
        this.$refs.tableDetail.open(data.ProjectCode, data);
      }
      // 隐藏弹窗
      // this.isShow = data;
    },
    // 查看更记录组件的返回
    recordBack() {
      // 返回表格页面
      this.isShow = 1;
    },
    handleSuccess(res, file) {
      this.dialogVisible = false;
      this.importLoading = false;
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeUpload(file) {
      this.importLoading = true;
      const isXLS = file.name.indexOf(".xls");
      if (isXLS == -1) {
        this.importLoading = false;
        this.$message.error("请上传xls 或 xlsx 类型文件!");
      }
      return isXLS !== -1;
    },
    // 分页变更
    currentChange(e) {
      this.pagination.PageIndex = e;
      this.getDataList();
    },
    // 获取整体大表格数据
    GetTreeDataListByDicKey() {
      this.$http
        .post(
          "/Base_ProjectManage/Project_DictionaryMain/GetTreeDataListByDicKey?DicKey=ShopExtensionInfo"
        )
        .then((res) => {
          if (res.Success) {
            // if (this.col) {
            // 首页整体大表格渲染处理
            this.col = this.col = res.Data.map((item) => {
              return {
                ...item,
                show: true,
                disabled: true,
              };
            });
            if (this.newCol) {
              // 用于传递到新建或编辑组件渲染处理
              this.newCol = this.newCol.concat(
                res.Data.map((item) => {
                  return {
                    ...item,
                    show: true,
                    disabled: true,
                  };
                })
              );
            }

            this.newCol.forEach((item, index) => {
              if (item.DicKey === "Shop_BaseInfo") {
                item["disabled"] = false;
              } else {
                item["disabled"] = true;
              }
              if (index !== 0) {
                item.children.forEach((v) => {
                  if (v.children == null) {
                    if (v.DicName === "备注") {
                      v["isRequire"] = false;
                    } else {
                      v["isRequire"] = true;
                    }
                  } else {
                    v.children.forEach((m, l) => {
                      if (m.DicName === "备注") {
                        m["isRequire"] = false;
                      } else {
                        if (m.DicName === "附件数") {
                          m["isRequire"] = false;
                        } else {
                          m["isRequire"] = true;
                        }
                      }
                    });
                  }
                });
              }
            });

            // 存储大表格列表数据到vuex
            this.$store.commit("setTableListData", this.newCol);
            // }
            this.init();
          }
        });
    },
    // 列表展示方案
    // getOptionsData() {
    //   this.$http
    //     .post("/Shop_Manage/Shop_ColumnsScheme/GetDataList", {})
    //     .then((res) => {
    //       if (res.Success) {
    //         this.filterList = res.Data;
    //       }
    //     });
    // },
    // 列表接口
    getDataList() {
      var that = this;
      this.loading = true;
      this.tableData = [];
      this.$http
        .post("/Shop_Manage/Shop_BaseInfo/GetDataListByMoreCondition", {
          PageIndex: this.pagination.PageIndex,
          PageRows: this.pagination.PageRows,
          SortInfos: this.SortInfos,
          Search: this.queryParam,
        })
        .then((res) => {
          // 取消表格加载提示
          this.loading = false;
          if (res.Success) {
            if (res.Data.length == 0) {
              this.pagination.total = 0;
              // 显示暂无招商信息
              this.isTable = true;
              return;
            }
            this.pagination.total = res.Total;
            that.tableData = res.Data;

            // 处理设备材料审核审批状态与图纸报审审批状态
            that.tableData.forEach((item) => {
              // 如果当前行存在设备材料审核
              if (item.EquipmentAndMaterialReview) {
                // 插入设备材料审核的审批状态key
                item["StatusEAMR"] = item.EquipmentAndMaterialReview;
              }

              // 如果当前行存再内审外审
              if (item.InternalAudit) {
                // 内审审批状态处理
                item["StatusInternalAudit"] = item.InternalAudit;
              }
              if (item.ExternalAudit) {
                // 外审审批状态处理
                item["StatusExternalAudit"] = item.ExternalAudit;
              }
            });
            let arr = [];
            that.tableData.forEach((item) => {
              function a(obj) {
                let newObj = {};
                function digui(obj, newObj) {
                  for (let key in obj) {
                    if (
                      Object.prototype.toString.apply(obj[key]) ==
                      "[object Object]"
                    ) {
                      digui(obj[key], newObj);
                    } else if (
                      Object.prototype.toString.apply(obj[key]) ==
                        "[object Array]" &&
                      !obj[key][0].id
                    ) {
                      if (newObj[key]) {
                        newObj[key].push(...obj[key]);
                      } else {
                        newObj[key] = [];
                        newObj[key].push(...obj[key]);
                      }
                    } else if (
                      Object.prototype.toString.apply(obj[key]) ==
                        "[object Array]" &&
                      obj[key][0].id
                    ) {
                      obj[key].forEach((ite) => {
                        for (let k in ite) {
                          if (k != "id") {
                            if (newObj[k]) {
                              newObj[k].push(ite[k]);
                            } else {
                              newObj[k] = [];
                              newObj[k].push(ite[k]);
                            }
                          }
                        }
                      });
                    } else {
                      newObj[key] = obj[key];
                    }
                  }
                }
                digui(obj, newObj);
                console.log(newObj);
                return newObj;
              }
              arr.push(a(item));
            });

            that.tableData = arr;
            console.log(that.tableData, "获取导出大表格列表数据---");
          } else {
            this.pagination.total = 0;
          }
        });
    },

    // 弹窗
    open(taskOptionsId, params) {
      console.log("虎丘参数", params, this.col);

      this.queryParam[1].Keyword =
        taskOptionsId === "" ? params.TaskId : taskOptionsId;
      // 弹窗
      this.dialogVisible = true;
      // 列表数据
      this.getDataList();
    },
    //取消弹窗
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tag {
  margin: 5px;
  cursor: pointer;
}

.container {
  /*覆盖element弹窗的宽高*/
  .dialog {
    ::v-deep .el-dialog__wrapper {
      // 为了解决整个弹窗出现下拉进度条。导致底部会存在透明情况
      .el-dialog {
        margin: 0 !important;
      }

      .el-dialog.import-dialog {
        margin-top: 0px !important;
        height: 100% !important;

        //覆盖element 弹窗dialog关闭按钮的样式
        .el-dialog__header {
          .el-dialog__headerbtn {
            font-size: 35px;
          }
        }
      }
    }
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .tools {
      display: flex;
    }

    .marginRight {
      margin-right: 10px;
    }

    .filterBox {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .positionA {
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
    padding: 0;
    font-size: 12px;
    vertical-align: baseline;

    i {
      font-size: 14px;
    }
  }

  .mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 90;
    top: 0;
    left: 0;
  }

  .tree {
    position: absolute;
    top: 35px;
    right: 30px;
    z-index: 99;
    max-height: 450px;
    overflow-y: auto;
    // padding-right: 10px;
    background: #fff;
    box-shadow: 1px 2px 5px #aaa;
    border-radius: 5px;
    width: 190px;

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      /* 滚动条里面小方块 */
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #e0e0e0;
    }

    &::-webkit-scrollbar-track {
      /* 滚动条里面轨道 */
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      // border-radius: 10px;
      background: transparent;
    }
  }

  .sort {
    // right: 140px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 2;
    padding: 10px 0;
    cursor: pointer;
    padding-bottom: 30px;

    .content {
      max-height: 400px;
      width: 100%;
      overflow: scroll;

      .sortItem {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;

        .noWarp {
          display: inline-block;
          width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:hover {
          background: #fae7e7;
          color: #d1100b;

          .iconGroup {
            i {
              color: #fff;
              border-color: #fff;
            }
          }
        }

        .iconGroup {
          i {
            margin-left: 3px;
            display: inline-block;
            border: 1px solid #ccc;
            padding: 3px;
            border-radius: 3px;
          }

          .active {
            border-color: #d1100b !important;
            color: #d1100b !important;
          }
        }
      }

      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        /* 滚动条里面小方块 */
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #e0e0e0;
      }

      &::-webkit-scrollbar-track {
        /* 滚动条里面轨道 */
        // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        // border-radius: 10px;
        background: transparent;
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      right: 0;
      padding-bottom: 4px;
      padding-right: 4px;
    }
  }
}
</style>
