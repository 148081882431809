<template>
  <!--  这里是电气模块区域组件-->
  <div class="ElectricalMajor">
    <!--                    电气模块区域-->
    <div v-if="im.DicName === '电气信息'" style="margin-bottom: 10px">
      <div style="padding-left: 20px; margin-bottom: 10px">
        {{ im.DicName }}
      </div>
      <div class="flex">
        <div class="flex flex5" v-if="
          im.children &&
          (v.DicKey === 'PowerConsumption' || v.DicKey === 'EnergyUseIndex')
        " v-for="(v, i) in im.children" :key="i + new Date().getTime()">
          <div class="flex flex5" :class="{ 'border-top': updateRecordType !== '详情' }">
            <div class="flex5 design-left" :class="{ background: updateRecordType === '详情' }">
              {{ v.DicName }}
            </div>
            <div v-if="v.DicKey === 'PowerConsumption'" class="flex5 design-right"
              :class="{ background: updateRecordType === '详情' }">
              {{ entity.PowerConsumption }}
            </div>
            <div v-if="v.DicKey === 'EnergyUseIndex'" class="flex5 design-right"
              :class="{ background: updateRecordType === '详情' }">
              {{ entity.EnergyUseIndex }}
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="flex flex5" v-if="im.children && v.DicKey === 'ElectricalBox'" v-for="(v, i) in im.children"
          :key="i + new Date().getTime()">
          <div style="padding: 15px; width: 100%; text-align: center">
            {{ v.DicName }}
          </div>
        </div>
        <div class="flex" v-if="im.children && v.DicKey === 'ElectricalBox'" v-for="(v, vi) in im.children" :key="vi">
          <div v-for="(item, index) in entity.ElectricalBox" :key="index" style="width: 25%">
            <div>
              <div class="flex flex5" v-for="(m, l) in v.children" :key="l + new Date().getTime()">
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <!--         值-->
                <div class="flex5 design-right background">
                  {{ item[m.DicKey] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex5" v-if="im.children && v.DicKey === 'RemarkElectrical'" v-for="(v, vi) in im.children"
          :key="vi + new Date().getTime()">
          <div class="flex design-left background" style="flex: 1.18; justify-content: flex-end">
            {{ v.DicName }}
          </div>
          <div class="design-right background" style="flex: 8.82">{{ entity.RemarkElectrical }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
  name: "ElectricalMajor.vue",
  data() {
    // 这里存放数据
    return {};
  },
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    updateRecordType: {
      type: String,
    },
    parentObj: {
      type: Object,
      default: () => { },
    },
    im: {
      type: Object,
      default: () => { },
    },
    entity: {
      type: Object,
      default: () => { },
    },
  },
  // 方法集合
  methods: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  //过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() { },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() { },
  // 生命周期 - 挂载之前
  beforeMount() { },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() { },
  // 生命周期 - 更新之前
  beforeUpdate() { },
  // 生命周期 - 更新之后
  updated() { },
  // 生命周期 - 销毁之前
  beforeDestroy() { },
  // 生命周期 - 销毁完成
  destroyed() { },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  //进入的时候触发
  activated() { },
  //离开的时候触发
  deactivated() { },
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}

.flex5 {
  flex: 5;
}

.flex3 {
  flex: 3;
}

.border-top {
  border-top: 1px solid #ccc;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
}

.border-left {
  border-left: 1px solid #ccc;
}

.ElectricalMajor {
  .design-left.background {
    background-color: #f7eded;
    border: none !important;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .design-right.background {
    background-color: #ffe3e3;
    border: none !important;
    padding-left: 10px;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .design-left {
    //border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .design-right {
    //border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding-left: 10px;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
