<!-- 更新记录组件 -->
<template>
  <el-drawer title="查看" :visible.sync="visible" direction="rtl" size="60%" class="record">
    <!-- 返回 -->
    <!-- <div class="back">
      <i size="25" class="el-icon-back" @click="recordBack"></i>
    </div>
    <div class="" style="padding: 6px 0; display: flex">
      <div style="margin: 10px auto; font-weight: bold">查看</div>
    </div> -->
    <!-- 具体区域内容 -->
    <div class="recordContent">
      <!--      打印区域  待后续打开-->
      <div style="margin: 20px 0 0px 20px">
        <el-button @click="onPrint" size="mini" :loading="loading">打印预览
        </el-button>
      </div>
      <el-card :body-style="bodyStyle">
        <!-- accordion -->
        <el-collapse v-model="activeName" @change="handleChange">
          <el-collapse-item v-for="(item, index) in col" :key="item.DicName" :title="item.DicName" :name="index">
            <div class="rowFlex" style="">
              <div :span="12" style="" v-for="(im, i) in item.children" :key="i">
                <div v-if="!im.children">
                  <div style="">
                    <el-col :span="12">
                      <div style="" :class="{ flex: i % 2 == 1 }">
                        <div class="top">
                          <div class="top-item name">{{ im.DicName }}</div>
                          <div class="top-item key" v-if="im.DicKey === 'BuildingId'">
                            {{ entity["BuildingCode"] }}
                          </div>
                          <div class="top-item key" v-else-if="im.DicKey === 'IsOnGround'">
                            {{ entity[im.DicKey] == 1 ? "地上" : "地下" }}
                          </div>
                          <!-- <div class="top-item key" v-else-if="im.DicKey==='IsOpeningBusiness'" >{{ entity[im.DicKey]==1?'地上':'地下' }}</div> -->
                          <div class="top-item key" v-else>
                            {{
                              entity[im.DicKey] ? entity[im.DicKey] : "无数据"
                            }}
                          </div>
                        </div>
                      </div>
                    </el-col>
                  </div>
                </div>

                <div v-else>
                  <div v-if="item.DicName === '设计信息'">
                    <!--                    电气区域模块-->
                    <ElectricalMajor v-if="im.DicName === '电气信息'" ref="ElectricalMajor" :entity="entity"
                      :parentObj="{ ...this }" :im="im" :updateRecordType="'详情'"></ElectricalMajor>

                    <!--                    &lt;!&ndash;                    暖通区域模块&ndash;&gt;-->

                    <HVACMajor v-if="im.DicName === '暖通信息'" ref="HVACMajor" :entity="entity" :parentObj="{ ...this }"
                      :im="im" :updateRecordType="'详情'"></HVACMajor>

                    <!--                                        给排水区域-->
                    <WSADMajor v-if="
                      im.DicName === '给排水' || im.DicName === '燃气条件'
                    " ref="HVACMajor" :entity="entity" :parentObj="{ ...this }" :im="im" :updateRecordType="'详情'">
                    </WSADMajor>

                    <!--                    弱电区域-->
                    <IntelligentMajor v-if="im.DicName === '弱电信息'" ref="HVACMajor" :entity="entity"
                      :parentObj="{ ...this }" :im="im" :updateRecordType="'详情'"></IntelligentMajor>

                    <!--                                        结构区域-->
                    <StructureMajor v-if="im.DicName === '结构信息'" ref="HVACMajor" :entity="entity"
                      :parentObj="{ ...this }" :im="im" :updateRecordType="'详情'"></StructureMajor>
                  </div>

                  <!--                  其余模块-->
                  <div v-else>
                    <span class="bottom-span" style="padding-left: 20px">{{
                      im.DicName
                    }}</span>
                    <div class="bottom-box" style="">
                      <div class="bottom" v-for="(v, i) in im.children" :key="v.DicKey" style=""
                        :class="{ margin: i % 2 == 1 }">
                        <div v-if="v.children" style="width: 100%">
                          <div class="bottom-span" style="padding-left: 50px; padding-bottom: 20px">
                            {{ v.DicName }}
                          </div>
                          <div style="display: flex; flex-direction: column">
                            <div v-for="vi in v.children" :key="vi.DicKey" style="display: flex">
                              <div class="bottom-item name" style="width: 50%">
                                {{ vi.DicName }}
                              </div>
                              <!-- <div class="bottom-item key" >{{  entity[v.DicKey]  }}</div> -->
                              <!-- 图纸报审》内审审核状态 -->
                              <div v-if="vi.DicKey === 'StatusInternalAudit'" class="bottom-item key">
                                {{
                                  entity[vi.DicKey] == 0 ? "已通过" : "未通过"
                                }}
                              </div>
                              <!-- 图纸报审》外审审核状态 -->
                              <div v-else-if="vi.DicKey === 'StatusExternalAudit'" class="bottom-item key">
                                {{
                                  entity[vi.DicKey] == 0 ? "已通过" : "未通过"
                                }}
                              </div>
                              <!-- 楼栋单独处理 -->
                              <!-- <div  v-else-if="vi.DicKey === 'BuildingId'" class="bottom-item key">
                              111
                            </div> -->

                              <!-- 内审附件数量处理 -->
                              <div v-else class="bottom-item key">
                                <div v-if="vi.DicKey === 'CountInternalAudit'">
                                  <span>{{
                                    entity[vi.DicKey] ? entity[vi.DicKey] : 0
                                  }}</span>
                                  <div v-if="entity[vi.DicKey] !== 0" style="display: flex">
                                    <div v-for="(
                                        m, l
                                      ) in entity.InternalAuditUrlArr" :key="l" style="margin-right: 10px">
                                      <a class="ahover" :href="m" target="_blank">下载附件 {{ l + 1 }}</a>
                                    </div>
                                  </div>
                                </div>
                                <!-- 外审附件数量处理 -->
                                <div v-if="vi.DicKey === 'CountExternalAudit'">
                                  <span>{{
                                    entity[vi.DicKey] ? entity[vi.DicKey] : 0
                                  }}</span>
                                  <div v-if="entity[vi.DicKey] !== 0" style="display: flex">
                                    <div v-for="(
                                        m, l
                                      ) in entity.ExternalAuditUrlArr" :key="l" style="margin-right: 10px">
                                      <a class="ahover" :href="m" target="_blank">下载附件 {{ l + 1 }}</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-else style="display: flex; width: 100%">
                          <div class="bottom-item name">{{ v.DicName }}</div>
                          <div class="bottom-item key" style="">
                            <!-- 如果是设备材料审核 修改状态 -->
                            <div v-if="v.DicKey === 'StatusEAMR'">
                              <!-- {{ entity[v.DicKey]==1?'已通过':'未通过' }} -->
                              {{ entity[v.DicKey] == 0 ? "已通过" : "未通过" }}
                            </div>
                            <!-- <div  v-else-if="v.DicKey === 'IsOpeningBusiness'" class="bottom-item key">
                            {{ entity[v.DicKey] ==1?'已开业':'未开业' }}
                          </div> -->
                            <!-- 设备材料审核的附件数 -->
                            <div v-else-if="v.DicKey === 'CountEAMR'">
                              <span>{{
                                entity[v.DicKey] ? entity[v.DicKey] : 0
                              }}</span>
                              <div v-if="entity[v.DicKey] !== 0" style="display: flex">
                                <div v-for="(
                                    m, l
                                  ) in entity.EquipmentAndMaterFileArr" :key="l" style="margin-right: 10px">
                                  <a class="ahover" :href="m" target="_blank">下载附件 {{ l + 1 }}</a>
                                </div>
                              </div>
                            </div>

                            <div v-else>
                              {{
                                entity[v.DicKey] ? entity[v.DicKey] : "无数据"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </div>
    <!--    打印区域模块-->
    <UpdateRecordPrint ref="UpdateRecordPrint"></UpdateRecordPrint>
  </el-drawer>
</template>

<script>
//引入打印區域模塊
import UpdateRecordPrint from "@/views/Project/view/updateRecordPrint.vue";
//引入电气模块区域组件
import ElectricalMajor from "./components/ElectricalMajor.vue";
//引入暖通模块区域组件
import HVACMajor from "./components/HVACMajor.vue";
// 给排水区域组件
import WSADMajor from "./components/WSADMajor.vue";
// 弱电信息区域组件
import IntelligentMajor from "./components/IntelligentMajor.vue";
//结构信息区域组件
import StructureMajor from "./components/StructureMajor.vue";
// 模拟数据
import {
  mockElectricalMajorData,
  mockElectricalMajorListData,
  mockStructureMajorListData,
  mockIntelligentMajorListData,
  mockIntelligentMajorResultData,
  mockWSADMajorListData,
  mockWSADMajorResultData,
} from "./mock.js";

export default {
  //父组件通过 props 向下传递数据给子组件
  props: {
    //attractInvestment父组件传递过来的树型结构表头数据  有多少个表头就有多少个展开关闭模板
    parentObj: {
      type: Object,
      default: () => {
      },
    },
  },
  //import引入的组件需要注入此处能使用
  components: {
    ElectricalMajor,
    HVACMajor,
    WSADMajor,
    IntelligentMajor,
    StructureMajor,
    UpdateRecordPrint,
  },
  //此处存放数据
  data() {
    return {
      loading: false,
      // attractInvertment传递过来的col数据
      col: [],
      bodyStyle: {
        padding: "20px",
      },
      size: "",
      // 浏览器当前可视窗口高度
      windowHeight: 0,
      // 浏览器当前可视窗口宽度
      windowWidth: 0,
      visible: false,
      activeName: [0],
      entity: {},
      // 下载的附件
      downLoadFileListData: [],
    };
  },
  //计算属性
  computed: {},
  //监听data中数据变化
  watch: {},
  created() {
    this.activeName = [0];
  },
  methods: {
    // 打印预览按钮
    onPrint() {
      var that = this;
      this.loading = true;
      // 获取子组件的表格数据
      let tableData = this.parentObj.$refs.tableItem.tableData;
      // 过滤存在没有提交的商铺数据
      let isStatusIdArr = tableData.filter(
        (item) => item.Id === this.entity.Id
      );
      if (
        this.entity.Status == 0 ||
        this.entity.Status == 1 ||
        this.entity.Status == null ||
        this.entity.Status == 3
      ) {
        // 过滤未提交的商铺版本设置自动选中
        tableData.forEach((item) => {
          if (
            (item.Status == 0 ||
              item.Status == null ||
              item.Status == 1 ||
              item.Status == 3) &&
            item.Id === this.entity.Id
          ) {
            // 未提交的商铺版本
            this.parentObj.$refs.tableItem.$refs.table.toggleRowSelection(
              item,
              true
            );
          }
        });
        setTimeout(() => {
          this.loading = false;
        }, 500);

        this.$confirm("当前商铺版本未提交, 是否一键提交?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // 刷新表格
            that.loading = true;

            const loading = that.$loading({
              lock: true,
              text: "版本提交中...",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });

            that.$http
              .post(
                "/Shop_Manage/Shop_BaseInfo/ShopInfo_VersionSubmission?shopId=" +
                this.entity.Id
              )
              .then((res) => {
                if (res.Success) {
                  that.$message.success("版本提交成功");
                  // 取消自动选中
                  tableData.forEach((item) => {
                    that.parentObj.$refs.tableItem.$refs.table.toggleRowSelection(
                      item,
                      false
                    );
                  });
                  that.$nextTick(function () {
                    that.parentObj.$refs.tableItem.loading = true;
                    that.parentObj.$refs.tableItem.pagination.current = 1;
                    // that.parentObj.$refs.tableItem.queryParam[0].Keyword =
                    //   that.taskObj.ProjectId;
                    that.parentObj.$refs.tableItem.totalObj.ShopArea = 0;
                    that.parentObj.$refs.tableItem.totalObj.PowerConsumption = 0;
                    that.parentObj.$refs.tableItem.totalObj.KitchenExhaustFumes = 0;
                    // 刷新列表数据
                    that.parentObj.$refs.tableItem.getDataList();
                  });
                  setTimeout(() => {
                    // 刷新表格
                    that.loading = false;
                    loading.close();
                    //   打开预览打印组件
                    that.$refs.UpdateRecordPrint.open(that.entity, that.col);
                  }, 1000);
                } else {
                  loading.close();
                  that.$message.error(res.Msg);
                }
              });
          })
          .catch(() => {
            // 取消自动选中
            tableData.forEach((item) => {
              this.parentObj.$refs.tableItem.$refs.table.toggleRowSelection(
                item,
                false
              );
            });
            setTimeout(() => {
              that.loading = false;
              that.$message({
                type: "info",
                message: "已取消",
              });
            }, 500);
          });

        return;
      }

      setTimeout(() => {
        this.loading = false;
      }, 500);

      console.log("获取打印预览entity", this.entity, this.col);

      // return;
      //   打开预览打印组件
      this.$refs.UpdateRecordPrint.open(this.entity, this.col);
    },
    // 折叠展开
    handleChange(e) {
    },
    open(row, colData) {
      this.entity = row;
      this.visible = true;

      this.col = colData.filter(item => {
        return item.DicKey !== "EngineeringInfoJson" && item.DicKey !== "ContractInfoJson";
      });
      console.log(this.col, 'col')
      // 默认只展示基础信息折叠面板区域
      this.activeName = [0];
    },
    // 返回
    recordBack() {
      this.$emit("recordBack");
    },

    // 下载附件处理函数
    getDownLoadFile(file) {
      if (file) {
        let arr = file.split(",");
        this.downLoadFileListData = arr;
      }

      return this.downLoadFileListData;
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}

.flex5 {
  flex: 5;
}

.flex3 {
  flex: 3;
}

.border-top {
  border-top: 2px solid #e8f5ff;
}

.border-bottom {
  border-bottom: 2px solid #e8f5ff;
}

.ahover:hover {
  color: #1a73e8;
  border-bottom: 1px solid #1a73e8;
  padding-bottom: 5px;
}

/*覆盖element  el-drawer 头部的下边距样式*/
::v-deep .el-drawer__container.el-drawer__open {
  .el-drawer {
    .el-drawer__header {
      margin-bottom: 0px;
    }
  }
}

/*覆盖card样式*/
.el-card {
  border: none !important;
}

/*取消card的阴影*/
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}

::v-deep .el-descriptions .el-descriptions__header {
  padding: 10px;
  background-color: #f7d1d1;
}

/* justify-content: flex-end;*/
.flex {
  display: flex;
}

.record {
  .colMargin {
    margin: 3px 0;

    .flexBox {
      display: flex;

      .boxLeft {
        flex: 1;
        padding: 10px;
        background-color: #f2f2f2;

        .title {
          font-weight: bold;
          font-size: 13px;
        }

        .boxChildren {
          padding-left: 50px;

          .childrenRow {
            display: flex;
            margin: 5px 0;
            font-size: 13px;

            .rowLeft {
              flex: 1;
              padding: 5px;
              background-color: #fcf7f7;
            }
          }
        }
      }

      .boxRight {
        padding: 10px;
        flex: 1;
        text-align: right;
        background-color: #f7eded;
      }
    }
  }

  .back {
    cursor: pointer;
    width: 30px;
    font-size: 25px;
  }

  .recordContent {
    overflow: auto;

    .rowFlex {
      .top {
        display: flex;
        width: 100%;

        .top-item {}

        .top-item.name {
          flex: 4;
          background-color: #f7eded;
          padding: 6px 0 5px 10px;
          text-align: right;
          padding-right: 10px;
        }

        .top-item.key {
          background-color: #ffe3e3;
          padding: 6px 0 5px 10px;
          flex: 6;
        }
      }

      .top.margin {
        margin-left: 20px;
      }

      .bottom-span {}

      /*justify-content: space-between; */
      .bottom-box {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        flex-wrap: wrap;
      }

      .bottom.margin {
        //margin-left: 42px;
      }

      .bottom {
        display: flex;
        width: 50%;

        .bottom-item {
          flex: 5;
        }

        .bottom-item.name {
          background-color: #f7eded;
          padding: 6px 0px 6px 10px;
          flex: 4;
          text-align: right;
          padding-right: 10px;
        }

        .bottom-item.key {
          background-color: #ffe3e3;
          padding: 6px 0px 6px 10px;
          flex: 6;
        }
      }
    }
  }
}
</style>
