<template>
    <div style="width: 100%;padding: 10px 20px; box-sizing: border-box;">
        <div style="width: 100%;">
            <!-- <div>{{ taskObj }}</div> -->
            <div>
                <span style="font-weight: bold;font-size: 14px;">审批单位/部门：</span>
                <el-select v-model="search.ApprovalOrganName" clearable placeholder="请选择" @change="changeSignatoryUnit"
                    style="width: 200px" :loading="SignatoryUnitOptionsLoading" size="small">
                    <el-option v-for="v in SignatoryUnitOptions" :key="v.DicName" :label="v.DicName"
                        :value="v.DicName"></el-option>
                </el-select>
                <el-select v-model="search.ApprovalUserRole" clearable
                    :placeholder="ApprovalUserRoleList.length === 0 ? '暂无角色列表' : '请选择'"
                    :disabled="ApprovalUserRoleList.length === 0" style="width: 200px;margin-left: 10px;" size="small">
                    <el-option v-for="v in ApprovalUserRoleList" :key="v.DicName" :label="v.DicName"
                        :value="v.DicName"></el-option>
                </el-select>
                <el-checkbox v-model="search.isAuto" style="margin-left: 10px;">我的审批</el-checkbox>

                <el-button size="mini" style="margin-left: 10px;" @click="resetSearch">重置</el-button>
                <el-tooltip :disabled="!(!this.search.isAuto && !this.search.ApprovalOrganName)" content="请选择条件后查询"
                    placement="right" effect="light">
                    <el-button size="mini" type="primary" style="margin-left: 10px;" @click="searchData"
                        v-loading="isSearch">查询</el-button>
                </el-tooltip>

                <!-- <el-popover placement="right" trigger="click" content="请选择条件后查询"
                    v-if="!this.search.isAuto && !this.search.ApprovalOrganName">

                    <el-button slot="reference" size="mini" type="primary" style="margin-left: 10px;">查询</el-button>
                </el-popover> -->
                <el-button size="mini" type="primary" style="margin-left: 10px;float: right;"
                    @click="openPrint(ApprovalType === '1' ? data : data2, ApprovalType)">导出 /
                    打印</el-button>
            </div>
        </div>

        <el-tabs v-model="ApprovalType" @tab-click="changeTab">
            <el-tab-pane label="设备/材料审核台帐" name="1"></el-tab-pane>
            <el-tab-pane label="深化图纸审核台账" name="2"></el-tab-pane>
        </el-tabs>
        <el-table :data="ApprovalType === '1' ? data : data2" border :span-method="objectSpanMethod" v-loading="loading"
            empty-text="暂无数据，请选择条件进行查询" :height="height" :max-height="height">
            <el-table-column prop="sort" label="序号" align="center" width="50">
            </el-table-column>
            <el-table-column prop="ApprovalCode" :label="ApprovalType === '1' ? '设备/材料报审单编号' : '深化图纸报审单编号'" align="left"
                show-overflow-tooltip header-align="center"></el-table-column>
            <el-table-column prop="ApprovalName" :label="ApprovalType === '1' ? '设备/材料报审单名称' : '深化图纸报审单名称'" align="left"
                show-overflow-tooltip header-align="center"></el-table-column>
            <el-table-column prop="ApprovalBatch" label="报审批次" align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.ApprovalBatch">第 <span
                            style="color: red;font-weight: bold;font-size: 14px;">{{ scope.row.ApprovalBatch }}</span>
                        批次</span>
                </template>
            </el-table-column>
            <el-table-column prop="ApprovalDateTime" label="报审时间" align="center" width="150">
                <template slot-scope="scope">
                    {{ scope.row.CreateTime ? scope.row.CreateTime.substring(0, 10) : '' }}
                </template>
            </el-table-column>
            <el-table-column prop="ApprovalDateTime" label="审批时间" align="center" width="150">
                <template slot-scope="scope">
                    {{ scope.row.ApprovalDateTime ? scope.row.ApprovalDateTime.substring(0, 10) : '' }}
                </template>
            </el-table-column>
            <el-table-column label="审核结果" align="center">
                <el-table-column prop="ApprovalUserRole" label="角色" align="center" width="100"
                    v-if="!search.ApprovalUserRole && ApprovalUserRoleList.length > 0">
                </el-table-column>
                <el-table-column prop="ApprovalEvaluate" label="评价" align="center" width="100">
                    <template slot-scope="scope">
                        <span
                            :style="`color:${(scope.row.ApprovalEvaluate === 'B' || scope.row.ApprovalEvaluate === 'A') ? '#67C23A' : '#D1100B'};font-weight:bold;font-size:16px`">{{
                                scope.row.ApprovalEvaluate }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="ApprovalAnnotation" label="意见" align="left" show-overflow-tooltip
                    header-align="center"></el-table-column>

            </el-table-column>
            <el-table-column prop="ApprovalARemark" label="备注" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.ApprovalStatus == 0" style="color: red;"> 未审核或暂存 </span>
                    <span v-else>{{ scope.row.ApprovalARemark }}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-row :gutter="20" style="margin-top: 20px;">
            <el-col :span="6">
                <div class="echarts-title">通过比例</div>
                <EchartsIndex refId="RG_ApprovedCount" :option="RG_ApprovedCount"
                    styleStr="width: 100%;height: 180px;" />

            </el-col>
            <el-col :span="6">
                <div class="echarts-title">审批次数</div>
                <EchartsIndex refId="RG_ApprovalBatchCount" :option="RG_ApprovalBatchCount"
                    styleStr="width: 100%;height: 180px;" />

            </el-col>
            <el-col :span="6">
                <div class="echarts-title">评价分类</div>
                <EchartsIndex refId="RG_ApprovalEvaluateCount" :option="RG_ApprovalEvaluateCount"
                    styleStr="width: 100%;height: 180px;" />

            </el-col>
            <el-col :span="6">
                <div class="echarts-title">审批周期</div>
                <EchartsIndex refId="RG_ApprovalCycleCount" :option="RG_ApprovalCycleCount"
                    styleStr="width: 100%;height: 180px;" />

            </el-col>

        </el-row>
        <RG_Print ref="RG_Print"></RG_Print>
    </div>
</template>
<script>
import EchartsIndex from "@/components/Echarts/index";
import RG_Print from "./RG_Print";
import { mapState } from 'vuex'
function MyDebounce(fn, duration = 100) {
    let timer = null;
    return (...arg) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.call(this, ...arg);
        }, duration)
    }
}
let that = null
export default {
    data() {
        return {
            echartsData: {},
            RG_ApprovedCount: {
                legend: {
                    data: [],
                    bottom: 0,
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{b} : {c} ({d}%)",
                },
                label: {

                },

                yAxis: {
                    show: false,
                },
                xAxis: {
                    show: false,
                },
                series: [
                    {
                        type: "pie",
                        radius: "60%",
                        labelLayout: {
                            hideOverlap: true,
                        },
                        center: ["50%", "45%"],
                        selectedMode: "single",
                        labelLine: {
                            show: true,
                            length: 5,
                            length2: 5
                        },
                        data: [

                        ],


                    },
                ],
            },
            RG_ApprovalBatchCount: {
                legend: {

                    data: [],
                    bottom: 0,
                },

                tooltip: {
                    trigger: "item",
                    formatter: "{b} : {c} ({d}%)",
                },
                label: {

                },
                yAxis: {
                    show: false,
                },
                xAxis: {
                    show: false,
                },
                series: [
                    {
                        type: "pie",
                        radius: "60%",
                        center: ["50%", "45%"],
                        selectedMode: "single",
                        labelLayout: {
                            hideOverlap: true,
                        },
                        labelLine: {
                            show: true,
                            length: 5,
                            length2: 5
                        },
                        data: [

                        ],


                    },
                ],
            },
            RG_ApprovalEvaluateCount: {
                legend: {

                    data: [],
                    bottom: 0,
                },

                tooltip: {
                    trigger: "item",
                    formatter: "{b} : {c} ({d}%)",
                },
                label: {

                },
                yAxis: {
                    show: false,
                },
                xAxis: {
                    show: false,
                },
                series: [
                    {
                        type: "pie",
                        radius: "60%",
                        center: ["50%", "45%"],
                        selectedMode: "single",
                        labelLayout: {
                            hideOverlap: true,
                        },
                        labelLine: {
                            show: true,
                            length: 5,
                            length2: 5
                        },
                        data: [

                        ],


                    },
                ],
            },
            RG_ApprovalCycleCount: {
                legend: {
                    data: [],
                    bottom: 0,
                },

                tooltip: {
                    trigger: "item",
                    formatter: "{b} : {c} ({d}%)",
                },
                label: {
                    show: true,
                    formatter: "{b} : {c} ({d}%)",
                },
                yAxis: {
                    show: false,
                },
                xAxis: {
                    show: false,
                },
                series: [
                    {
                        type: "pie",
                        radius: "60%",
                        center: ["50%", "45%"],
                        selectedMode: "single",
                        labelLayout: {
                            hideOverlap: true,
                        },
                        labelLine: {
                            show: true,
                            length: 5,
                            length2: 5
                        },
                        data: [

                        ],


                    },
                ],
            },
            height: window.innerHeight - 576,
            loading: false,

            ApprovalType: '1',
            data: [],
            SignatoryUnitOptions: [],
            SignatoryUnitOptionsLoading: false,
            ApprovalUserRoleList: [],
            search: {
                ApprovalOrganName: "",
                ApprovalUserRole: "",
                isAuto: false,
            },
            isSearch: false,
            data2: []
        };
    },
    props: {
        taskObj: {
            type: Object,
            default: () => ({})
        }
    },
    components: { EchartsIndex, RG_Print },
    methods: {
        openPrint(data, ApprovalType) {
            if (data.length > 0) {
                this.$refs.RG_Print.openDrawer(data, ApprovalType, true, [], (!this.search.ApprovalUserRole && this.ApprovalUserRoleList.length > 0))
            } else {
                this.$message.warning('暂无数据，请查询数据后导出/打印')
            }

        },
        changeEachrtsData() {
            if (this.echartsData.RG_ApprovedCount && this.echartsData.RG_ApprovedCount.length > 0) {
                this.RG_ApprovedCount.legend.data = this.echartsData.RG_ApprovedCount || []
                this.RG_ApprovedCount.label = {
                    show: true,
                    formatter: "{b} : {c} ({d}%)",
                }
                this.RG_ApprovedCount.series[0].data = this.echartsData.RG_ApprovedCount || []
            }
            if (this.echartsData.RG_ApprovalBatchCount && this.echartsData.RG_ApprovalBatchCount.length > 0) {
                this.RG_ApprovalBatchCount.legend.data = this.echartsData.RG_ApprovalBatchCount || []
                this.RG_ApprovalBatchCount.label = {
                    show: true,
                    formatter: "{b} : {c} ({d}%)",
                }
                this.RG_ApprovalBatchCount.series[0].data = this.echartsData.RG_ApprovalBatchCount || []
            }
            if (this.echartsData.RG_ApprovalEvaluateCount && this.echartsData.RG_ApprovalEvaluateCount.length > 0) {
                this.RG_ApprovalEvaluateCount.legend.data = this.echartsData.RG_ApprovalEvaluateCount || []
                this.RG_ApprovalEvaluateCount.label = {
                    show: true,
                    formatter: "{b} : {c} ({d}%)",
                }
                this.RG_ApprovalEvaluateCount.series[0].data = this.echartsData.RG_ApprovalEvaluateCount || []
            }
            if (this.echartsData.RG_ApprovalCycleCount && this.echartsData.RG_ApprovalCycleCount.length > 0) {
                this.RG_ApprovalCycleCount.legend.data = this.echartsData.RG_ApprovalCycleCount || []
                this.RG_ApprovalCycleCount.label = {
                    show: true,
                    formatter: "{b} : {c} ({d}%)",
                }
                this.RG_ApprovalCycleCount.series[0].data = this.echartsData.RG_ApprovalCycleCount || []
            }
        },
        changeTab() {
            this.data = []
            this.data2 = []
            this.echartsData = {}
            this.RG_ApprovedCount.legend.data = []
            this.RG_ApprovedCount.series[0].data = []
            this.RG_ApprovalBatchCount.legend.data = []
            this.RG_ApprovalBatchCount.series[0].data = []
            this.RG_ApprovalEvaluateCount.legend.data = []
            this.RG_ApprovalEvaluateCount.series[0].data = []
            this.RG_ApprovalCycleCount.legend.data = []
            this.RG_ApprovalCycleCount.series[0].data = []
            this.mountedData()
        },
        //查询
        searchData: MyDebounce(function () {
            that.getDataList()
        }, 300),
        //重置
        resetSearch() {
            this.search = {
                ApprovalOrganName: "",
                ApprovalUserRole: "",
                isAuto: false,
            }
            this.changeSignatoryUnit('')

        },
        /**
   * 合并单元格
   * TableData:传递过来的表格数据
   * itemName：属性名
   * rowIndex：行索引值
   * */
        MergeCol(TableData, itemName, rowIndex) {
            var idName = TableData[rowIndex][itemName]; // 获取当前单元格的值
            // 合并单元格
            // id：属性名
            // rowIndex：行索引值

            if (rowIndex > 0) {
                // 判断是不是第一行
                // eslint-disable-next-line eqeqeq
                if (TableData[rowIndex][itemName] != TableData[rowIndex - 1][itemName]) {
                    // 先判断当前单元格的值是不是和上一行的值相等
                    var i = rowIndex;
                    var num = 0; // 定义一个变量i，用于记录行索引值并进行循环，num用于计数
                    while (i < TableData.length) {
                        // 当索引值小于table的数组长度时，循环执行
                        if (TableData[i][itemName] === idName) {
                            // 判断循环的单元格的值是不是和当前行的值相等
                            i++; // 如果相等，则索引值加1
                            num++; // 合并的num计数加1
                        } else {
                            i = TableData.length; // 如果不相等，将索引值设置为table的数组长度，跳出循环
                        }
                    }


                    return {
                        rowspan: num, // 最终将合并的行数返回
                        colspan: 1,
                    };
                } else {
                    return {
                        rowspan: 0, // 如果相等，则将rowspan设置为0
                        colspan: 1,
                    };
                }
            } else {
                // 如果是第一行，则直接返回
                let i = rowIndex;
                let num = 0;
                while (i < TableData.length) {
                    // 当索引值小于table的数组长度时，循环执行
                    if (TableData[i][itemName] === idName) {
                        i++;
                        num++;
                    } else {
                        i = TableData.length;
                    }
                }


                return {
                    rowspan: num,
                    colspan: 1,
                };
            }

        },
        //表格合并
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            let data = this.ApprovalType === '1' ? this.data : this.data2

            if (columnIndex == 2) {
                return this.MergeCol(data, 'ApprovalName', rowIndex)
            } else if (columnIndex == 1) {
                return this.MergeCol(data, 'ApprovalCode', rowIndex)
            } else if (columnIndex == 0) {
                return this.MergeCol(data, 'sort', rowIndex)
            }
        },
        debounce(fn, delay = 30000) {
            let timer = null; // 创建一个标记用来存放定时器的返回值
            return function () {
                // 再次执行的时候把前一个 setTimeout clear 清空掉
                clearTimeout(timer);
                // 然后又创建一个新的 setTimeout, 这样就能保证interval 间隔内如果时间持续触发，就不会执行 fn 函数
                // timer = setTimeout(() => {
                //     fn.apply(this, arguments);
                // }, delay);
                timer = setTimeout(fn, delay) // 简化写法
            }
        },
        mountedData() {
            if (!this.search.isAuto && !this.search.ApprovalOrganName) {
                return
            }
            this.getDataList()
        },
        getDataList() {
            console.log(this.isSearch)
            this.isSearch = false
            if (!this.search.isAuto && !this.search.ApprovalOrganName) {
                return
            }
            this.loading = true
            let arr = [{
                "Condition": "TaskId",
                "Keyword": this.taskObj.TaskId
            },
            {
                "Condition": "ApprovalOrganName",
                "Keyword": this.search.ApprovalOrganName
            },
            {
                "Condition": "ApprovalUserRole",
                "Keyword": this.search.ApprovalUserRole
            },
            {
                "Condition": "ApprovalType",
                "Keyword": this.ApprovalType === '1' ? 1 : 2
            },
            ]
            if (this.search.isAuto) {
                arr.push({
                    "Condition": "ApprovalPhone",
                    "Keyword": this.userInfo.Phone
                })
            }
            this.$http
                .post("/ReviewGO/Review_ApprovalInfo/GetReview_ApprovalInfoForTaskLedger", arr)
                .then(res => {
                    this.loading = false
                    if (res.Success) {
                        if (res.Data.length > 0) {
                            let num = 1
                            res.Data.forEach((item, index) => {
                                if (index === 0) {
                                    this.$set(item, 'sort', num)
                                } else {
                                    if (item.ApprovalCode === res.Data[index - 1].ApprovalCode) {
                                        this.$set(item, 'sort', num)
                                    } else {
                                        num++
                                        this.$set(item, 'sort', num)
                                    }
                                }

                            })
                            if (this.ApprovalType === '1') {
                                this.data = res.Data || []
                            } else if (this.ApprovalType === '2') {
                                this.data2 = res.Data || []
                            }
                        } else {
                            this.data2 = []
                            this.data = []
                        }


                    } else {
                        this.$message.error('数据获取失败' + res.Msg)
                    }
                })
            this.$http
                .post("/ReviewGO/Review_ApprovalInfo/GetReview_TaskAnalysisDiagrams", arr)
                .then(res => {
                    this.loading = false
                    if (res.Success) {
                        this.echartsData = res.Data || {}
                        this.changeEachrtsData()
                    } else {
                        this.$message.error('图标数据获取失败' + res.Msg)
                    }
                })
        },
        //部门改变
        changeSignatoryUnit(val) {
            this.$set(this.search, 'ApprovalUserRole', '')
            let obj = this.SignatoryUnitOptions.find(item => item.DicName === val)
            this.ApprovalUserRoleList = obj?.DictionaryDetailsList || []
        },
        // 获取部门字典
        async getSignatoryUnitOptions() {
            this.SignatoryUnitOptionsLoading = true
            await this.$http
                .post("/Base_ProjectManage/Project_DictionaryMain/GetSubsetListByDicKey?DicKey=RG_ExternalOrganization", {
                })
                .then(res => {
                    this.SignatoryUnitOptionsLoading = false
                    if (res.Success) {
                        this.SignatoryUnitOptions = res.Data || []
                    }
                })
        },
    },
    mounted() {
        that = this
        // this.height =
        //     console.log(this.height)
        // console.log(window.innerHeight)
        // console.log(window)
    },
    created() {
        this.getSignatoryUnitOptions()
        // this.getDataList()
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
        })
    },
    watch: {
    },
};
</script>
<style lang='scss' scoped>
::v-deep .logBook {
    margin-top: 5vh !important;

    .el-dialog__body {
        padding: 5px 10px !important;
    }
}

::v-deep .el-table th {
    background: #D1100B !important;
    color: #fff
}

.echarts-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}
</style>