var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex",staticStyle:{"border-left":"2px solid #000","border-top":"2px solid #000","border-right":"2px solid #000"}},[_c('div',{staticClass:"flex8 flexCol"},[_vm._m(0),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex5"},[_vm._m(1),_c('div',{staticClass:"flex jbetween flex5 height30 borderTop borderRight font16"},[_c('span',[_vm._v(_vm._s(_vm.parentObj.entity.PowerConsumption ? _vm.parentObj.entity.PowerConsumption : "")+" ")]),(_vm.parentObj.entity.PowerConsumption)?_c('span',{staticClass:"color paddingRight5 font14"},[_vm._v(" (kW)")]):_vm._e()])]),_c('div',{staticClass:"flex flex5"},[_vm._m(2),_c('div',{staticClass:"flex jbetween flex5 height30 borderTop borderRight font16"},[_c('span',[_vm._v(_vm._s(_vm.parentObj.entity.EnergyUseIndex ? _vm.parentObj.entity.EnergyUseIndex : "")+" ")]),(_vm.parentObj.entity.EnergyUseIndex)?_c('span',{staticClass:"color paddingRight5 font14"},[_vm._v(" (W/㎡)")]):_vm._e()])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex5"},[_vm._m(3),_c('div',{staticClass:"flex5"},[_c('InputComponent',{ref:"ElectricityMeter",attrs:{"isPrint":_vm.isPrint,"type":'select',"options":_vm.dianOtions},on:{"change":function($event){return _vm.inputSelectChange($event, 'ElectricityMeterSelectValue')}}})],1)]),_c('div',{staticClass:"flex flex5"},[_vm._m(4),_c('div',{staticClass:"flex5"},[_c('InputComponent',{ref:"ElectricityMeterPosition",attrs:{"isPrint":_vm.isPrint,"type":'select',"options":_vm.dianWeiOptions},on:{"change":function($event){return _vm.inputSelectChange(
                  $event,
                  'ElectricityMeterPositionSelectValue'
                )}}})],1)])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex5"},[_vm._m(5),(_vm.parentObj.entity.ElectricalBox)?_c('div',{staticClass:"flex jbetween flex5 height30 borderRight borderBottom font16"},[_c('span',[_vm._v(" "+_vm._s(_vm.parentObj.entity.ElectricalBox ? _vm.parentObj.entity.ElectricalBox.length : 0))]),_c('span',{staticClass:"color font14 paddingRight5"},[_vm._v(" "+_vm._s(_vm.parentObj.entity.ElectricalBox ? "个" : "个"))])]):_c('div',{staticClass:"flex jbetween flex5 height30 borderRight borderBottom font16"},[_c('span',[_vm._v("0")]),_c('span',{staticClass:"color font14 paddingRight5"},[_vm._v("个")])])]),_c('div',{staticClass:"flex flex5"},[_vm._m(6),_c('div',{staticClass:"flex5"},[_c('InputComponent',{ref:"EmergencySupply",attrs:{"isPrint":_vm.isPrint,"type":'select',"options":_vm.dianOtions},on:{"change":function($event){return _vm.inputSelectChange($event, 'EmergencySupplySelectValue')}}})],1)])])]),_c('div',{attrs:{"id":"shopInfoRemark flex flex2 "}},[_c('div',{staticClass:"text-center font16"},[_vm._v("备注")]),_c('div',[_c('InputComponent',{ref:"RemarkTotalPowerConsumptionInput",attrs:{"placeHolder":'请输入备注',"rows":3,"type":'textarea'},on:{"input":function($event){return _vm.inputChange($event, 'RemarkTotalPowerConsumption')}}})],1)])]),_c('div',{staticClass:"flex",staticStyle:{"border-left":"2px solid #000","border-bottom":"2px solid #000","border-right":"2px solid #000"}},[_c('div',{staticClass:"flex8"},_vm._l((_vm.parentObj.entity.ElectricalBox),function(item,index){return (
          _vm.parentObj.entity.ElectricalBox &&
          _vm.parentObj.entity.ElectricalBox.length > 0
        )?_c('div',{staticClass:"flex8"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 height30 borderRight borderBottom font16"},[_c('div',[_vm._v("配电箱编号 ( "+_vm._s(index + 1)+" )")])]),_c('div',{staticClass:"flex jbetween flex5 height30 borderRight borderBottom font16"},[_c('div',[_vm._v(" "+_vm._s(item.EB_Number ? item.EB_Number : "")+" ")]),_c('span',{staticClass:"color paddingRight5 font14"})])]),_c('div',{staticClass:"flex flex5"},[_vm._m(7,true),_c('div',{staticClass:"flex jbetween flex5 height30 borderRight font16"},[_c('InputComponent',{ref:'DistributionBoxCapacityInput' + index,refInFor:true,attrs:{"placeHolder":'请输入配电箱容量',"suffixText":'kW',"type":'input'},on:{"input":function($event){return _vm.inputChange($event, 'DistributionBoxCapacity' + index)}}})],1)])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex5"},[_vm._m(8,true),_c('div',{staticClass:"flex jbetween flex5 height30 borderRight borderBottom font16"},[_c('div',[_vm._v(" "+_vm._s(item.RatedCurrentOfIncomingSwitch ? item.RatedCurrentOfIncomingSwitch : "")+" ")]),_c('span',{staticClass:"color paddingRight5 font14"},[_vm._v("(A)")])])]),_c('div',{staticClass:"flex flex5"},[_vm._m(9,true),_c('div',{staticClass:"flex5 height30 borderTop borderRight borderBottom font16"},[_c('div',[_vm._v(_vm._s(item.IncomingCableSpecifications))])])])])]):_vm._e()}),0),(
        _vm.parentObj.entity.ElectricalBox &&
        _vm.parentObj.entity.ElectricalBox.length !== 0
      )?_c('div',[(_vm.parentObj.entity.ElectricalBox)?_c('div',[_c('InputComponent',{ref:"RemarkRatedCurrentOfIncomingSwitchInput",attrs:{"placeHolder":'请输入备注',"rows":_vm.parentObj.entity.ElectricalBox.length,"type":'textarea'},on:{"input":function($event){return _vm.inputChange($event, 'RemarkRatedCurrentOfIncomingSwitch')}}})],1):_c('div',[_c('InputComponent',{ref:"RemarkRatedCurrentOfIncomingSwitchInput",attrs:{"placeHolder":'请输入备注',"rows":1,"type":'textarea'},on:{"input":function($event){return _vm.inputChange($event, 'RemarkRatedCurrentOfIncomingSwitch')}}})],1)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex",staticStyle:{"height":"22px"}},[_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex5"}),_c('div',{staticClass:"flex5"})]),_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex5"}),_c('div',{staticClass:"flex5"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 height30 borderTop borderRight font16"},[_c('span',[_vm._v(" 交付总用电量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 height30 borderTop borderRight font16"},[_c('span',[_vm._v("用电指标")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 height30 borderTop font16"},[_c('span',[_vm._v("电表（提供/不提供）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 height30 borderTop font16"},[_c('span',[_vm._v("电表安装位置（户内/公共电井）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 height30 borderTop borderRight borderBottom font16"},[_c('span',[_vm._v("交付配电箱数量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 height30 borderTop borderBottom font16"},[_c('span',[_vm._v("是否提供应急电源（提供/不提供）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 height30 borderRight font16"},[_c('div',[_vm._v("配电箱容量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 height30 borderRight borderBottom font16"},[_c('div',[_vm._v("配电箱开关规格")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 height30 borderTop borderRight borderBottom font16"},[_c('div',[_vm._v("租户电缆进线规格")])])
}]

export { render, staticRenderFns }