<template>
  <div class="template">
    <div class="navHearder">
      <div class="navLeft">
        <!-- 返回按钮 -->
        <div class="back" @click="backMenu" style="flex: 8; cursor: pointer">
          <!-- <span> < </span> -->
          <i v-show="backShow == -1" class="el-icon-back" size="30" style="font-size: 23px"></i>
        </div>
        <div class="upload" style="">
          <el-upload class="avatar-uploader" :action="$rootUrl + '/Base_Manage/Upload/ProjectImgOssUploadFileByForm?ProjectId=' + this.entity.Id + '&Type=PC'
            " :headers="{
            Authorization: token,
          }" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="entity.ProjectImg" :src="entity.ProjectImg" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="longText">
          <div class="textTop">
            {{ entity.Name }}
            <!-- <i class="el-icon-star-off"></i> -->
          </div>
          <div class="textBottom">
            <el-radio-group v-model="activeName" size="small">
              <el-radio-button v-for="item in textList" :key="item.title" :label="item.title"><i
                  :class="item.icon"></i>{{ item.title }}
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="navRight">
        <div class="searchBar">
          <!-- <el-input
            ref="searchInput"
            class="searchInput"
            v-model="keyword"
            placeholder="搜索任务"
            clearable
            @keydown.enter.native="toSearch"
          ></el-input>
          <el-dropdown trigger="click" @command="sortCommand">
            <el-button size="mini" type="text" class="textSearch"
              >按创建日期 <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item disabled>排序</el-dropdown-item>
              <el-dropdown-item command="date">创建日期</el-dropdown-item>
              <el-dropdown-item command="date">截至日期</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </div>
    </div>
    <div class="content" :style="{ paddingLeft: activeName === '应用' ? '0' : '20px' }">
      <Board :pId="entity.Id" ref="board" v-if="activeName === '应用'" :parentOBJ="{ ...this }" />
      <File :pId="entity.Id" ref="File" v-if="activeName === '项目云盘'" :parentOBJ="{ ...this }" />
      <Team :pId="entity.Id" ref="Team" v-if="activeName === '成员管理'" :parentOBJ="{ ...this }" />
      <Info ref="infoComp" :pId="entity.Id" v-if="activeName === '基本信息'" @reload="reload" />
      <DataAnalysis ref="dataAnalysis" :projectId="entity.Id" v-if="activeName === '数据分析'" />
      <!--<DataAnalysis-->
      <!--    ref="dataAnalysis"-->
      <!--    v-if="activeName === '数据分析'"-->
      <!--/>-->

    </div>
  </div>
</template>

<script>
import Token from "@/utils/cache/TokenCache.js";
import { mapMutations } from "vuex";
import Board from "./Page/Board.vue"; // 应用
import File from "./Page/File.vue"; // 项目云盘
import Team from "./Page/Team.vue"; // 成员管理
import Info from "./Page/Info.vue"; // 基本信息
import DataAnalysis from "@/views/Project/DataAnalysis/DA_HomePage.vue"; // 数据分析
// import DataAnalysis from "./Page/DataAnalysis.vue"; // 数据分析
export default {
  components: {
    Board,
    File,
    Team,
    Info,
    DataAnalysis
  },
  data() {
    return {
      backShow: -1, // 后退按钮显示
      entity: {},
      editTitle: false, // 项目名称
      activeName: "应用",
      textList: [
        { title: "应用", icon: "el-icon-s-home" },
        { title: "基本信息", icon: "el-icon-s-order" },
        { title: "项目云盘", icon: "el-icon-folder" },
        { title: "成员管理", icon: "el-icon-data-line" },
        { title: "数据分析", icon: "el-icon-data-line" },
      ],
      keyword: "",
      loading: true,
      token: "",
      infoState: true,
    };
  },
  mounted() {
    this.token = "Bearer " + Token.getToken();
    if (this.$route.query.prop == "add") this.activeName = "基本信息";
    else this.activeName = "应用";
    this.getProjectInfo(this.$route.query.id);
  },
  // watch: {
  //   $route: {
  //     handler(n, o) {
  //       this.activeName = n.path;
  //       this.backShow = this.routerList.findIndex(
  //         (item) => item.path == n.path
  //       );
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  methods: {
    ...mapMutations(["setPatGOKey", "changeInfoState"]),
    openInfoForm() {
      this.$refs.infoComp.openInfoForm();
    },

    // 获取项目信息
    getProjectInfo(id) {
      this.$http
        .post("/Base_ProjectWork/Base_Project/GetTheData", { id })
        .then((res) => {
          console.log("获取项目信息1111", res);
          if (res.Success) {
            this.entity = res.Data;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 标题编辑
    handleEditTitle() {
      this.editTitle = false;
    },
    // 返回上一页
    backMenu() {
      console.log("当前路由", this.$route);
      // 更新当前小红点通知
      // this.getMessageCount();
      this.$router.go(-1);
    },
    // 查询
    toSearch() {
      if (this.activeName == "应用") {
        // 拍拍GO
        if (this.$refs.board.projectIndex == 1) {
          this.setPatGOKey({ key: this.keyword });
        }
      }
    },
    // 排序
    sortCommand(e) {
      console.log(e);
    },
    handleAvatarSuccess(res, file) {
      if (res.Code == 200) {
        this.entity.ProjectImg = res.Url;
        // 解决上传图片同意再其他组件内上传图片不生效的问题
        this.$nextTick(function () {
          if (this.activeName === "基本信息") {
            this.$refs.infoComp.openInfoForm(res.Url);
          }
          if (this.activeName === "应用") {
            this.$refs.board.openInfoForm(this.entity);
          }
          if (this.activeName === "项目云盘") {
            this.$refs.File.openInfoForm(this.entity);
          }
          if (this.activeName === "成员管理") {
            this.$refs.Team.openInfoForm(this.entity);
          }
        });
        // this.$http
        //   .post("/Base_ProjectWork/Base_Project/SaveData", this.entity)
        //   .then((res) => {
        //     this.loading = false;
        //     if (res.Success) {
        //       this.$message.success("操作成功");
        //     } else {
        //       this.entity.ProjectImg = "";
        //       this.$message.error(res.Msg);
        //     }
        //   });
      } else {
        this.$message.error(res.Msg);
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 或 PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 20MB!");
      }
      return isJPG && isLt2M;
    },
    // 页签
    moveTaps(title) {
      this.activeName = title;
    },
    // 重新获取数据
    reload(e) {
      console.log(e);
      this.getProjectInfo(this.entity.Id);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 65px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.avatar {
  width: 65px;
  height: 60px;
  display: block;
}

$boxShadow: 0 1px 5px 0 rgb(57 66 60 / 20%);

.active {
  border-bottom: 2px solid #1b9aee;
  color: #333 !important;
}

.template {
  width: 100%;
  background-color: #f7f7f7;
  height: 100%;

  .navHearder {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 70px;

    .navLeft {
      display: flex;
      align-items: center;
      height: 100%;
      box-sizing: border-box;
      padding-left: 20px;

      .icon {
        cursor: pointer;
        width: 28px;
        font-size: 24px;
      }

      .back {
        cursor: pointer;
        width: 30px;
        font-size: 30px;
      }

      .upload {
        width: 65px;
        height: 59px;
        // overflow: hidden;
        border-radius: 6px;
      }

      .longText {
        display: flex;
        flex-direction: column;
        height: 100%;

        .textTop {
          line-height: 40px;
          margin-left: 10px;
          overflow: hidden;
          height: 36px;

          i {
            cursor: pointer;
            margin-left: 10px;
            padding: 5px;
            border-radius: 5px;
            color: gray;
            transition: all 200ms linear 0s;

            &:hover {
              color: #333;
              background: #efefef;
            }
          }
        }

        .textBottom {
          flex: 1;
          display: flex;
          line-height: 28px;
          font-size: 14px;

          div {
            cursor: pointer;
            margin: 0 10px;
            color: gray;

            // transition: all 100ms linear 0s;
            i {
              margin-right: 5px;
            }
          }
        }
      }
    }

    .navRight {
      padding-right: 20px;

      .searchBar {
        height: 44px;
        display: flex;
        align-items: center;

        .searchInput {
          // display: none;
          margin-right: 10px;
          width: 300px;
        }
      }
    }
  }

  .content {
    padding-right: 20px;
    box-sizing: border-box;
    height: calc(100vh - 70px);
    // min-height: calc(100vh - 125px);
    // max-height: calc(100vh - 95px);
  }
}
</style>
