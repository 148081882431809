<!--基础信息组件-->
<template>
  <div class="Info">
    <InfoForm ref="infoForm" />
  </div>
</template>

<script>
import InfoForm from "./InfoComponent/InfoForm.vue";
import LouDonForm from "./InfoComponent/LouDonForm.vue";
export default {
  components: {
    InfoForm,
    LouDonForm,
  },
  props: {
    pId: { type: String, require: true },
    state: { type: Boolean, require: true, default: false },
  },
  data() {
    return {
      activeName: "first",
    };
  },
  mounted() {},
  methods: {
		
		openInfoForm(ProjectImg){
			this.$nextTick(function(){
				this.$refs.infoForm.entity.ProjectImg = ProjectImg
				this.$refs.infoForm.save()
				console.log('info子组件打印finform子组件的东西', this.$refs.infoForm,this.$refs.infoForm.entity, ProjectImg)
			})
		}
	},
};
</script>

<style lang="scss" scoped>
.Info {
  padding-top: 20px;
}
</style>