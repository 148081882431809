<template>
  <!--  电气部分-->
  <div>
    <!--    空调（提供/不提供）-->
    <div
      class="flex"
      style="
        border-left: 2px solid #000;
        border-top: 2px solid #000;
        border-right: 2px solid #000;
      "
    >
      <div class="flex8 flexCol">
        <!--              顶部备注区域布局站位-->
        <div class="flex" style="height: 22px">
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
        </div>
        <!--        内容区域 这里的height 30px 和下拉列表数据点额高度一致 否则回出现高度不一致情况-->
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span> 交付总用电量</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span
                >{{
                  parentObj.entity.PowerConsumption
                    ? parentObj.entity.PowerConsumption
                    : ""
                }}
              </span>
              <span
                v-if="parentObj.entity.PowerConsumption"
                class="color paddingRight5 font14"
              >
                (kW)</span
              >
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span>用电指标</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span
                >{{
                  parentObj.entity.EnergyUseIndex
                    ? parentObj.entity.EnergyUseIndex
                    : ""
                }}
              </span>
              <span
                v-if="parentObj.entity.EnergyUseIndex"
                class="color paddingRight5 font14"
              >
                (W/㎡)</span
              >
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <span>电表（提供/不提供）</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="ElectricityMeter"
                :isPrint="isPrint"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange($event, 'ElectricityMeterSelectValue')
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <span>电表安装位置（户内/公共电井）</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="ElectricityMeterPosition"
                :isPrint="isPrint"
                :type="'select'"
                :options="dianWeiOptions"
                @change="
                  inputSelectChange(
                    $event,
                    'ElectricityMeterPositionSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div
              class="flex5 height30 borderTop borderRight borderBottom font16"
            >
              <span>交付配电箱数量</span>
            </div>
            <div
              v-if="parentObj.entity.ElectricalBox"
              class="flex jbetween flex5 height30 borderRight borderBottom font16"
            >
              <span>
                {{
                  parentObj.entity.ElectricalBox
                    ? parentObj.entity.ElectricalBox.length
                    : 0
                }}</span
              >
              <span class="color font14 paddingRight5">
                {{ parentObj.entity.ElectricalBox ? "个" : "个" }}</span
              >
            </div>
            <div
              v-else
              class="flex jbetween flex5 height30 borderRight borderBottom font16"
            >
              <span>0</span>
              <span class="color font14 paddingRight5">个</span>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderBottom font16">
              <span>是否提供应急电源（提供/不提供）</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="EmergencySupply"
                :isPrint="isPrint"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange($event, 'EmergencySupplySelectValue')
                "
              ></InputComponent>
            </div>
          </div>
        </div>
      </div>
      <div id="shopInfoRemark flex flex2 ">
        <div class="text-center font16">备注</div>
        <!--       交付总用电量 备注-->
        <div>
          <InputComponent
            ref="RemarkTotalPowerConsumptionInput"
            :placeHolder="'请输入备注'"
            :rows="3"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkTotalPowerConsumption')"
          ></InputComponent>
        </div>
      </div>
    </div>

    <div
      class="flex"
      style="
        border-left: 2px solid #000;
        border-bottom: 2px solid #000;
        border-right: 2px solid #000;
      "
    >
      <div class="flex8">
        <div
          class="flex8"
          v-if="
            parentObj.entity.ElectricalBox &&
            parentObj.entity.ElectricalBox.length > 0
          "
          v-for="(item, index) in parentObj.entity.ElectricalBox"
        >
          <div class="flex">
            <div class="flex flex5">
              <div class="flex5 height30 borderRight borderBottom font16">
                <div>配电箱编号 ( {{ index + 1 }} )</div>
              </div>
              <div
                class="flex jbetween flex5 height30 borderRight borderBottom font16"
              >
                <div>
                  {{ item.EB_Number ? item.EB_Number : "" }}
                </div>
                <span class="color paddingRight5 font14"> </span>
              </div>
            </div>
            <div class="flex flex5">
              <div class="flex5 height30 borderRight font16">
                <div>配电箱容量</div>
              </div>
              <div class="flex jbetween flex5 height30 borderRight font16">
                <InputComponent
                  :ref="'DistributionBoxCapacityInput' + index"
                  :placeHolder="'请输入配电箱容量'"
                  :suffixText="'kW'"
                  :type="'input'"
                  @input="
                    inputChange($event, 'DistributionBoxCapacity' + index)
                  "
                ></InputComponent>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex flex5">
              <div class="flex5 height30 borderRight borderBottom font16">
                <div>配电箱开关规格</div>
              </div>
              <div
                class="flex jbetween flex5 height30 borderRight borderBottom font16"
              >
                <div>
                  {{
                    item.RatedCurrentOfIncomingSwitch
                      ? item.RatedCurrentOfIncomingSwitch
                      : ""
                  }}
                </div>
                <span class="color paddingRight5 font14">(A)</span>
              </div>
            </div>
            <div class="flex flex5">
              <div
                class="flex5 height30 borderTop borderRight borderBottom font16"
              >
                <div>租户电缆进线规格</div>
              </div>
              <div
                class="flex5 height30 borderTop borderRight borderBottom font16"
              >
                <div>{{ item.IncomingCableSpecifications }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          parentObj.entity.ElectricalBox &&
          parentObj.entity.ElectricalBox.length !== 0
        "
      >
        <div v-if="parentObj.entity.ElectricalBox">
          <InputComponent
            ref="RemarkRatedCurrentOfIncomingSwitchInput"
            :placeHolder="'请输入备注'"
            :rows="parentObj.entity.ElectricalBox.length"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkRatedCurrentOfIncomingSwitch')"
          ></InputComponent>
        </div>
        <div v-else>
          <InputComponent
            ref="RemarkRatedCurrentOfIncomingSwitchInput"
            :placeHolder="'请输入备注'"
            :rows="1"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkRatedCurrentOfIncomingSwitch')"
          ></InputComponent>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputComponent from "@/views/Project/view/components/inputComponents/inputComponent.vue";
// 模拟数据
import { dianbiaoOptions, dianbiaoWeizhiOptions } from "../../mock.js";
export default {
  props: {
    parentObj: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  components: { InputComponent },
  data() {
    return {
      isPrint: false, //是否打印
      // 输入框绑定的值
      selectValue: "提供",
      // 电表位置绑定
      dianWeiSelectValue: "户内",
      // 电表区域备注绑定
      RemarkElectrical: "",
      // 电表下拉列框绑定列别
      dianOtions: dianbiaoOptions,
      //   电表安装位置下拉数据
      dianWeiOptions: dianbiaoWeizhiOptions,
    };
  },
  methods: {
    input(row) {
      // 判断相关备注和下拉提供非提供逻辑  如果没有对应的数组  提供  否则 不提供
      this.$refs.ElectricityMeter.value = "";
      this.$refs.ElectricityMeterPosition.value = "";
      this.$refs.EmergencySupply.value = "";
      this.$refs.ElectricityMeter.value = row.ElectricityMeterSelectValue; //电表提供非提供
      this.$refs.ElectricityMeterPosition.value =
        row.ElectricityMeterPositionSelectValue; //电表安装位置提供非提供
      this.$refs.EmergencySupply.value = row.EmergencySupplySelectValue; //是否提供应急 电源提供非提供

      this.$refs.RemarkTotalPowerConsumptionInput.keyWords =
        row.RemarkTotalPowerConsumption; //交付总用电量备注
      if (this.$refs.RemarkRatedCurrentOfIncomingSwitchInput) {
        this.$refs.RemarkRatedCurrentOfIncomingSwitchInput.keyWords =
          row.RemarkRatedCurrentOfIncomingSwitch; //配电箱容量备注
      }
      // 4个配电箱容量回显
      if (row.DistributionBoxCapacity0)
        this.$refs.DistributionBoxCapacityInput0[0].keyWords =
          row.DistributionBoxCapacity0;
      if (row.DistributionBoxCapacity1)
        this.$refs.DistributionBoxCapacityInput1[0].keyWords =
          row.DistributionBoxCapacity1;
      if (row.DistributionBoxCapacity2)
        this.$refs.DistributionBoxCapacityInput2[0].keyWords =
          row.DistributionBoxCapacity2;
      if (row.DistributionBoxCapacity3)
        this.$refs.DistributionBoxCapacityInput3[0].keyWords =
          row.DistributionBoxCapacity3;
    },
    // 相关下拉参数处理回调
    inputSelectChange(event, type) {
      this.parentObj.openSetPrintSelectValue(event, type);
    },
    // 相关备注参数处理回调
    inputChange(event, type) {
      console.log("子组件---配单项输入", event, type);
      this.parentObj.openSetPrintInputValue(event, type);
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
/*覆盖card样式*/
.el-card {
  border: none !important;
}
/*取消card的阴影*/
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}

/*覆盖element card 卡片样式*/
::v-deep .recordContent {
  .el-card {
    .el-card__body {
      padding: 0px !important;
    }
  }
}
</style>
