<template>
  <!--  给排水部分-->
  <div style="border: 2px solid #000">
    <!--    给水管道-->
    <div class="flex">
      <div class="flex8 flexCol">
        <!--              顶部备注区域布局站位-->
        <div class="flex" style="height: 22px">
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
        </div>
        <!--        内容区域 这里的height 30px 和下拉列表数据点额高度一致 否则回出现高度不一致情况-->
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <div>给水管道</div>
            </div>
            <div class="flex5">
              <InputComponent
                ref="WaterSupplyPipeSize"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange($event, 'WaterSupplyPipeSizeSelectValue')
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <div>水表安装位置</div>
            </div>
            <div class="flex5">
              <InputComponent
                ref="WaterMeterPosition"
                :type="'select'"
                :options="dianWeiOptions"
                @change="
                  inputSelectChange($event, 'WaterMeterPositionSelectValue')
                "
              ></InputComponent>
            </div>
          </div>
        </div>
        <div class="flex" style="">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <div>给水管道入户数量</div>
            </div>
            <div
              v-if="parentObj.entity.WaterSupplyPipeSize"
              class="flex jbetween flex5 height30 borderRight font16"
            >
              <div>
                {{
                  parentObj.entity.WaterSupplyPipeSize
                    ? parentObj.entity.WaterSupplyPipeSize.length
                    : 0
                }}
              </div>
              <div class="color font14 paddingRight5">
                {{ parentObj.entity.WaterSupplyPipeSize ? "个" : "个" }}
              </div>
            </div>
            <div v-else class="flex jbetween flex5 height30 borderRight font16">
              <div>0</div>
              <div class="color font14 paddingRight5">个</div>
            </div>
          </div>
          <!--          布局站位-->
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight"></div>
            <div class="flex5 height30 borderRight"></div>
          </div>
        </div>

        <div class="flex">
          <div class="flex8 flexCol">
            <div class="flex8 flexCol">
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.WaterSupplyPipeSize &&
                    parentObj.entity.WaterSupplyPipeSize.length > 0 &&
                    (index == 0 || index == 1)
                  "
                  v-for="(item, index) in parentObj.entity.WaterSupplyPipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>给水管径({{ index + 1 }})</div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>{{ item }}</div>
                  </div>
                </div>
                <div
                    v-if=" parentObj.entity.WaterSupplyPipeSize &&
                    parentObj.entity.WaterSupplyPipeSize.length ==1"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.WaterSupplyPipeSize &&
                    parentObj.entity.WaterSupplyPipeSize.length > 0 &&
                    (index == 2 || index == 3)
                  "
                  v-for="(item, index) in parentObj.entity.WaterSupplyPipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>给水管径({{ index + 1 }})</div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>{{ item }}</div>
                  </div>
                </div>
                <div
                    v-if=" parentObj.entity.WaterSupplyPipeSize &&
                    parentObj.entity.WaterSupplyPipeSize.length ==3"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>
      <div>
        <div class="text-center font16">备注</div>
        <div v-if="parentObj.entity.WaterSupplyPipeSize">
          <InputComponent
            ref="RemarkWaterSupplyPipeSizeInput"
            :placeHolder="'请输入备注'"
            :rows="parentObj.entity.WaterSupplyPipeSize.length"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkWaterSupplyPipeSize')"
          ></InputComponent>
        </div>
        <div v-else style="padding-top: 3px">
          <InputComponent
            ref="RemarkWaterSupplyPipeSizeInput"
            :placeHolder="'请输入备注'"
            :rows="1"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkWaterSupplyPipeSize')"
          ></InputComponent>
        </div>
      </div>
    </div>

    <!--餐椅排水管道-->
    <div class="flex">
      <div class="flex8 flexCol">
        <!--        内容区域 这里的height 30px 和下拉列表数据点额高度一致 否则回出现高度不一致情况-->
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <div>餐饮排水</div>
            </div>
            <div class="flex5">
              <InputComponent
                ref="CateringDrainagePipeSize"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange(
                    $event,
                    'CateringDrainagePipeSizeSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
          <!--          布局站位-->
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight"></div>
            <div class="flex5 height30 borderTop borderRight"></div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <div>餐饮排水管道入户数量</div>
            </div>
            <div
              v-if="parentObj.entity.CateringDrainagePipeSize"
              class="flex jbetween flex5 height30 borderRight font16"
            >
              <div>
                {{
                  parentObj.entity.CateringDrainagePipeSize
                    ? parentObj.entity.CateringDrainagePipeSize.length
                    : 0
                }}
              </div>
              <div class="color font14 paddingRight5">
                {{ parentObj.entity.CateringDrainagePipeSize ? "个" : "个" }}
              </div>
            </div>
            <div v-else class="flex jbetween flex5 height30 borderRight font16">
              <div>0</div>
              <div class="color font14 paddingRight5">个</div>
            </div>
          </div>
          <!--          布局站位-->
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight"></div>
            <div class="flex5 height30 borderTop borderRight"></div>
          </div>
        </div>

        <div class="flex">
          <div class="flex8 flexCol">
            <div class="flex8 flexCol">
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.CateringDrainagePipeSize &&
                    parentObj.entity.CateringDrainagePipeSize.length > 0 &&
                    (index == 0 || index == 1)
                  "
                  v-for="(item, index) in parentObj.entity
                    .CateringDrainagePipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>排水管径({{ index + 1 }})</div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>{{ item }}</div>
                  </div>
                </div>
                <div
                    v-if=" parentObj.entity.CateringDrainagePipeSize &&
                    parentObj.entity.CateringDrainagePipeSize.length ==1"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.CateringDrainagePipeSize &&
                    parentObj.entity.CateringDrainagePipeSize.length > 0 &&
                    (index == 2 || index == 3)
                  "
                  v-for="(item, index) in parentObj.entity
                    .CateringDrainagePipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>排水管径({{ index + 1 }})</div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>{{ item }}</div>
                  </div>
                </div>
                <div
                    v-if=" parentObj.entity.CateringDrainagePipeSize &&
                    parentObj.entity.CateringDrainagePipeSize.length ==3"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>
      <div>
        <div v-if="parentObj.entity.CateringDrainagePipeSize">
          <InputComponent
            ref="RemarkCateringDrainagePipeSizeInput"
            :placeHolder="'请输入备注'"
            :rows="parentObj.entity.NonCateringDrainagePipeSize.length"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkCateringDrainagePipeSize')"
          ></InputComponent>
        </div>
        <div v-else>
          <InputComponent
            ref="RemarkCateringDrainagePipeSizeInput"
            :placeHolder="'请输入备注'"
            :rows="1"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkCateringDrainagePipeSize')"
          ></InputComponent>
        </div>
      </div>
    </div>

    <!--污废水管道-->
    <div class="flex">
      <div class="flex8 flexCol">
        <!--        内容区域 这里的height 30px 和下拉列表数据点额高度一致 否则回出现高度不一致情况-->
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <div>污/废水管道</div>
            </div>
            <div class="flex5">
              <InputComponent
                ref="NonCateringDrainagePipeSize"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange(
                    $event,
                    'NonCateringDrainagePipeSizeSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
          <!--          布局站位-->
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight"></div>
            <div class="flex5 height30 borderTop borderRight"></div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <div>污/废水管道入户数量</div>
            </div>
            <div
              v-if="parentObj.entity.NonCateringDrainagePipeSize"
              class="flex jbetween flex5 height30 borderRight font16"
            >
              <div>
                {{
                  parentObj.entity.NonCateringDrainagePipeSize
                    ? parentObj.entity.NonCateringDrainagePipeSize.length
                    : 0
                }}
              </div>
              <div class="color font14 paddingRight5">
                {{ parentObj.entity.NonCateringDrainagePipeSize ? "个" : "个" }}
              </div>
            </div>
            <div v-else class="flex jbetween flex5 height30 borderRight font16">
              <div>0</div>
              <div class="color font14 paddingRight5">个</div>
            </div>
          </div>
          <!--          布局站位-->
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight"></div>
            <div class="flex5 height30 borderTop borderRight"></div>
          </div>
        </div>

        <div class="flex">
          <div class="flex8 flexCol">
            <div class="flex8 flexCol">
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.NonCateringDrainagePipeSize &&
                    parentObj.entity.NonCateringDrainagePipeSize.length > 0 &&
                    (index == 0 || index == 1)
                  "
                  v-for="(item, index) in parentObj.entity
                    .NonCateringDrainagePipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>排水管径({{ index + 1 }})</div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>{{ item }}</div>
                  </div>
                </div>
                <div
                    v-if=" parentObj.entity.NonCateringDrainagePipeSize &&
                    parentObj.entity.NonCateringDrainagePipeSize.length ==1"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.NonCateringDrainagePipeSize &&
                    parentObj.entity.NonCateringDrainagePipeSize.length > 0 &&
                    (index == 2 || index == 3)
                  "
                  v-for="(item, index) in parentObj.entity
                    .NonCateringDrainagePipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>排水管径({{ index + 1 }})</div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>{{ item }}</div>
                  </div>
                </div>
                <div
                    v-if=" parentObj.entity.NonCateringDrainagePipeSize &&
                    parentObj.entity.NonCateringDrainagePipeSize.length ==3"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div>
        <div v-if="parentObj.entity.NonCateringDrainagePipeSize">
          <InputComponent
            ref="RemarkNonCateringDrainagePipeSizeInput"
            :placeHolder="'请输入备注'"
            :rows="parentObj.entity.NonCateringDrainagePipeSize.length"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkNonCateringDrainagePipeSize')"
          ></InputComponent>
        </div>
        <div v-else>
          <InputComponent
            ref="RemarkNonCateringDrainagePipeSizeInput"
            :placeHolder="'请输入备注'"
            :rows="1"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkNonCateringDrainagePipeSize')"
          ></InputComponent>
        </div>
      </div>
    </div>

    <!--专用隔油池-->
    <div class="flex">
      <div class="flex8 flexCol">
        <!--        内容区域 这里的height 30px 和下拉列表数据点额高度一致 否则回出现高度不一致情况-->
        <div class="flex" z>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderBottom font16">
              <div>专用隔油池</div>
            </div>
            <div class="flex5">
              <InputComponent
                ref="WaterOilSeparator"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange($event, 'WaterOilSeparatorSelectValue')
                "
              ></InputComponent>
            </div>
          </div>
          <!--          布局站位-->
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderBottom font16">
              <div>专用隔油池容量</div>
            </div>
            <div class="flex5">
              <InputComponent
                :placeHolder="'请输入专用隔油池容量'"
                ref="WaterOilSeparatorVolumeInput"
                :suffixText="'(m³)'"
                :type="'input'"
                @input="inputChange($event, 'WaterOilSeparatorVolume')"
              ></InputComponent>
            </div>
          </div>
        </div>
      </div>
      <div>
        <InputComponent
          ref="RemarkWaterOilSeparatorVolumeInput"
          :placeHolder="'请输入备注'"
          :rows="1"
          :type="'textarea'"
          @input="inputChange($event, 'RemarkWaterOilSeparatorVolume')"
        ></InputComponent>
      </div>
    </div>
  </div>
</template>
<script>
import InputComponent from "@/views/Project/view/components/inputComponents/inputComponent.vue";
// 模拟数据
import { dianbiaoOptions, dianbiaoWeizhiOptions } from "../../mock.js";
export default {
  props: {
    parentObj: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  components: { InputComponent },
  data() {
    return {
      // 输入框绑定的值
      selectValue: "提供",
      // 电表位置绑定
      dianWeiSelectValue: "",
      // 电表区域备注绑定
      RemarkElectrical: "",
      // 电表下拉列框绑定列别
      dianOtions: dianbiaoOptions,
      //   电表安装位置下拉数据
      dianWeiOptions: dianbiaoWeizhiOptions,
    };
  },
  methods: {
    input(row) {
      // 判断相关备注和下拉提供非提供逻辑  如果没有对应的数组  提供  否则 不提供
      this.$refs.WaterSupplyPipeSize.value = "";
      this.$refs.WaterMeterPosition.value = "";
      this.$refs.CateringDrainagePipeSize.value = "";
      this.$refs.NonCateringDrainagePipeSize.value = "";
      this.$refs.WaterOilSeparator.value = "";
      this.$refs.WaterSupplyPipeSize.value = row.WaterSupplyPipeSizeSelectValue; //给水管道提供非提供
      this.$refs.WaterMeterPosition.value = row.WaterMeterPositionSelectValue; //水表安装位置提供非提供
      this.$refs.CateringDrainagePipeSize.value =
        row.CateringDrainagePipeSizeSelectValue; //餐饮排水提供非提供
      this.$refs.NonCateringDrainagePipeSize.value =
        row.NonCateringDrainagePipeSizeSelectValue; //非餐饮污废水管道提非提供
      this.$refs.WaterOilSeparator.value = row.WaterOilSeparatorSelectValue; //专用隔油池提供非提供
      this.$refs.WaterOilSeparatorVolumeInput.keyWords =
        row.WaterOilSeparatorVolume; //专用隔油池容量输入内容
      this.$refs.RemarkNonCateringDrainagePipeSizeInput.keyWords =
        row.RemarkNonCateringDrainagePipeSize; //非餐饮排水水备注
      this.$refs.RemarkWaterOilSeparatorVolumeInput.keyWords =
        row.RemarkWaterOilSeparatorVolume; //专用隔油池容量备注
      this.$refs.RemarkCateringDrainagePipeSizeInput.keyWords =
        row.RemarkCateringDrainagePipeSize; //餐饮排水管道备注
      this.$refs.RemarkWaterSupplyPipeSizeInput.keyWords =
        row.RemarkWaterSupplyPipeSize; //给排水管道备注
    },
    // 相关下拉参数处理回调
    inputSelectChange(event, type) {
      this.parentObj.openSetPrintSelectValue(event, type);
    },
    // 相关备注参数处理回调
    inputChange(event, type) {
      this.parentObj.openSetPrintInputValue(event, type);
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
/*覆盖card样式*/
.el-card {
  border: none !important;
}
/*取消card的阴影*/
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}

/*覆盖element card 卡片样式*/
::v-deep .recordContent {
  .el-card {
    .el-card__body {
      padding: 0px !important;
    }
  }
}
</style>
