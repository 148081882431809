<template>
  <div class="box" v-loading="loading" style="overflow: hidden;">
    <div style="display: flex;margin-bottom: 5px;position: relative;">
      <div
        style="display: flex;align-items: center;justify-content: center;height: 30px;line-height: 30px;width: 40px;">
        <i size="30" class="el-icon-back" style="font-size: 23px;cursor: pointer;" @click="handleBack"></i>
      </div>
      <div style="font-size: 20px;font-weight: bold;line-height: 30px; margin-left: 20px;">数据分析-综合查询</div>
      <el-popover placement="left-start" width="800" trigger="click"
        v-if="ruleForm.BuildingInfoList.length > 0 || ruleForm.ShopFormatClass.length > 0 || ruleForm.AreaRangeValue.length > 0">
        <div style="overflow-y: auto;height: 300px;width: 100%">
          <div v-for="(item, index) in louList" :key="item.Id" style="line-height: 40px;">
            <div v-if="ruleForm.BuildingInfoList.includes(item.Id)">
              <span style="display: inline-block;">已选楼栋：</span>
              <el-tag style="color: #D1100B;margin-left: 3px;">{{ item.BuildingCode }}</el-tag>
              <span v-if="item.floorList.filter(item => item.isTrue).length > 0">
                <span style="display: inline-block;margin-left: 10px;">已选楼层：</span>
                <el-tag v-for="(val, index) in item.floorList.filter(item => item.isTrue)" :key="index"
                  style="color: #D1100B;margin-left: 3px;">{{ val.code }}</el-tag>
              </span>
            </div>
          </div>
          <div v-for="(item, index) in TypeList" :key="item.DicName" style="line-height: 40px;">
            <div v-if="ruleForm.ShopFormatClass.includes(item.DicName)">
              <span style="display: inline-block;">已选业态级别：</span>
              <el-tag style="color: #D1100B;margin-left: 5px;">{{ item.DicName }}</el-tag>
              <span v-if="item.YtList.filter(item => item.isTrue).length > 0">
                <span style="display: inline-block;margin-left: 10px;">已选业态：</span>
                <el-tag v-for="(val, index) in item.YtList.filter(item => item.isTrue)" :key="index"
                  style="color: #D1100B;margin-left: 3px;">{{ val.DicName }}</el-tag>
              </span>
            </div>
          </div>

          <div style="line-height: 40px;" v-if="ruleForm.AreaRangeValue.length > 0">
            <span style="display: inline-block;">已选面积区间：</span><span v-for="(item, index) in AreaRangeList"
              :key="item.DicValue">
              <span v-if="ruleForm.AreaRangeValue.includes(item.DicValue)">
                <el-tag style="color: #D1100B;margin-left: 5px;">{{ item.DicName }}</el-tag>
              </span>
            </span>
          </div>

        </div>
        <el-button slot="reference" size="mini" icon="el-icon-success"
          style="color: red;position: absolute;right: 3px;top: 0;z-index: 99;">已选条件</el-button>
      </el-popover>
    </div>
    <div style="margin: 50px auto;">
      <el-row>
        <el-col :span="14">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
            size="small">
            <el-form-item label="选择楼栋" prop="BuildingInfoList">
              <el-checkbox-group v-model="ruleForm.BuildingInfoList" @change="louChange">
                <el-checkbox-button v-for="item in louList" :label="item.Id" :key="item.Id">{{
                  item.BuildingCode
                }}
                  <el-popover placement="bottom-start" width="700" trigger="hover">
                    <div
                      style="font-size: 14px; line-height: 40px; margin-left: 10px;font-size: 17px;font-weight: bold;">
                      楼栋：{{ item.BuildingCode }}
                    </div>
                    <div style="display: flex;flex-wrap: wrap;align-items: center;">
                      <div
                        style="font-size: 14px; line-height: 40px; margin-left: 10px;font-size: 17px;font-weight: bold;">
                        选择楼层：
                      </div>
                      <el-button style="margin-left: 5px;margin-bottom: 5px;" @click="handleFloor(item, 1)"
                        v-if="item.floorList.filter(item => item.isTrue).length != item.floorList.length">全选
                      </el-button>
                      <el-button style="margin-left: 5px;margin-bottom: 5px;" @click="handleFloor(item, 2)"
                        v-if="item.floorList.filter(item => item.isTrue).length == item.floorList.length"
                        type="primary">取消全选
                      </el-button>
                      <div v-for=" (val, index) in item.floorList" :key="index">
                        <el-button style="margin-left: 5px;margin-bottom: 5px;" :type="val.isTrue ? 'primary' : ''"
                          @click="val.isTrue = !val.isTrue">{{ val.code }}
                        </el-button>
                      </div>
                    </div>
                    <el-button icon="el-icon-arrow-down" slot="reference" size="mini"
                      style="padding: 0; width: 20px;color: #000;" type="text" class="itembut"></el-button>
                  </el-popover>
                </el-checkbox-button>
              </el-checkbox-group>
              <div v-if="louList.length <= 0">
                <span style="font-size: 17px;font-weight: bold;color: #ee6666;">暂无楼栋数据</span>
              </div>
            </el-form-item>
            <el-form-item label="业态级别" prop="ShopFormatClass">
              <el-checkbox-group v-model="ruleForm.ShopFormatClass" @change="ShopFormatChange">
                <el-checkbox-button v-for="item in TypeList" :label="item.DicName" :key="item.DicName">{{
                  item.DicName
                }}
                  <el-popover placement="bottom-start" width="700" trigger="hover">
                    <div
                      style="font-size: 14px; line-height: 40px; margin-left: 10px;font-size: 17px;font-weight: bold;">
                      业态级别：{{ item.DicName }}
                    </div>
                    <div style="display: flex;flex-wrap: wrap;align-items: center;">
                      <div
                        style="font-size: 14px; line-height: 40px; margin-left: 10px;font-size: 17px;font-weight: bold;">
                        选择业态：
                      </div>
                      <el-button style="margin-left: 5px;margin-bottom: 5px;" @click="handleFloorShopFormat(item, 1)"
                        v-if="item.YtList.filter(item => item.isTrue).length != item.YtList.length">全选
                      </el-button>
                      <el-button style="margin-left: 5px;margin-bottom: 5px;" @click="handleFloorShopFormat(item, 2)"
                        v-if="item.YtList.filter(item => item.isTrue).length == item.YtList.length" type="primary">取消全选
                      </el-button>
                      <div v-for=" (val, index) in item.YtList" :key="index">
                        <el-button style="margin-left: 5px;margin-bottom: 5px;" :type="val.isTrue ? 'primary' : ''"
                          @click="val.isTrue = !val.isTrue">{{ val.DicName }}
                        </el-button>
                      </div>
                    </div>
                    <el-button icon="el-icon-arrow-down" slot="reference" size="mini"
                      style="padding: 0; width: 20px;color: #000;" type="text" class="itembut"></el-button>
                  </el-popover>
                </el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="截止日期" prop="time">
              <el-date-picker v-model="ruleForm.time" type="date" placeholder="截止日期" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="面积区间(㎡)" prop="ShopAreaInterval">
              <el-checkbox-group v-model="ruleForm.AreaRangeValue">
                <el-checkbox-button v-for="item in AreaRangeList" :key="item.DicValue" :label="item.DicValue">{{
                  item.DicName
                }}
                </el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="操作按钮" prop="action">
              <el-button type="primary" @click="handleResetFilter">重置条件</el-button>
              <el-button type="primary" @click="handleSearch">查询数据</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="10">
        </el-col>
      </el-row>
    </div>
    <!-- <div>
      <el-button @click="printTable">打印</el-button>
    </div> -->
    <!-- :span-method="objectSpanMethod" -->
    <!-- @cell-mouse-enter="handleCellMouseEnter"
    @cell-mouse-leave="handleCellMouseLeave" :row-class-name="tableRowClassName" -->
    <el-table :data="dataList" border style="width: 100%; margin-top: 20px" class="MG_ComprehensiveDataPagetable"
      id="tableDiv" :max-height="600" :span-method="objectSpanMethod">
      <el-table-column type="index" :fixed="true" label="序号" align="center" width="60px"
        :resizable="false"></el-table-column>
      <el-table-column :resizable="false" v-for="item in columnData" :key="item.ColumnKey" :label="item.ColumnName"
        :fixed="item.ColumnFixed" :width="calcColumnWidth(item)" :prop="item.ColumnKey" align="center">
        <template slot-scope="scope">
          <span
            v-if="item.ColumnKey == 'BuildingInfo' || item.ColumnKey == 'LayerInfo' || item.ColumnKey == 'FromatClass' || item.ColumnKey == 'FromatName' || item.ColumnKey == 'AreaRangeInfo'">
            {{ scope.row[item.ColumnKey] }}</span>
          <el-statistic v-else-if="scope.row.IsColSpan" group-separator="," :precision="2"
            :value="scope.row[item.ColumnKey]" :value-style="{ fontSize: '16px', fontWeight: 'bold' }"></el-statistic>
          <el-statistic v-else group-separator="," :precision="2" :value="scope.row[item.ColumnKey]"
            :value-style="{ fontSize: '14px' }"></el-statistic>

        </template>
      </el-table-column>
    </el-table>
    <div style="height: 50px;">

    </div>
  </div>
</template>

<script>
import Print from "print-js";

export default {
  name: "MG_ComprehensiveDataPage", // 综合数据查询模块
  components: {},
  props: {
    typeList: {
      type: Array,
      default: () => []
    },
    projectId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      dataListFlag: false,
      BuildingInfoIndex: "",
      LayerInfoIndex: "",
      FromatClassIndex: "",
      FromatNameIndex: "",
      currentColumnIndex: "",
      loading: false,
      floorList: [],
      ruleForm: {
        time: '',
        BuildingInfoList: [],
        AreaRangeValue: [],
        ShopFormatClass: [],
        ShopFormatNameList: [],
      },
      min: 0,
      max: 10000,
      step: 10, // 根据需要设置滑动的步长
      YtList: [],
      DataInfo: {},
      cities: [],
      cities2: [],
      rules: {},
      louList: [],
      TypeList: [],
      AreaRangeList: [],
      columnData: [],
      dataList: [],
      newtableData: [],
      tableData: []
    }
  },
  created() {
    this.getTypeList()
    this.getZS_ShopAreaRange()
    this.getLouData()
    this.handleSearch()
  },
  watch: {
    louList: {
      deep: true,
      handler(valArr) {
        valArr.forEach(item => {
          if (item.floorList) {
            let obj = item.floorList.find(val => {
              return val.isTrue
            })
            if (obj) {
              if (!this.ruleForm.BuildingInfoList.includes(item.Id)) {
                this.ruleForm.BuildingInfoList.push(item.Id)
              }

            }
          }
        })
      }
    },
    TypeList: {
      deep: true,
      handler(valArr) {
        valArr.forEach(item => {
          if (item.YtList) {
            let obj = item.YtList.find(val => {
              return val.isTrue
            })
            if (obj) {
              if (!this.ruleForm.ShopFormatClass.includes(item.DicName)) {
                this.ruleForm.ShopFormatClass.push(item.DicName)
              }
            }
          }
        })
      }
    },

  },
  methods: {
    // 转换千分位
    matter(row, column, cellValue) {
      const columns = ['BuildingInfo', 'LayerInfo', 'FromatClass', 'FromatName', 'AreaRangeInfo'];
      if (column && !columns.includes(column.property)) {
        cellValue += ''
        if (!cellValue.includes('.')) cellValue += '.'
        return cellValue.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
          return $1 + ','
        }).replace(/\.$/, '')
      } else {
        return cellValue
      }
    },
    //计算列宽度
    calcColumnWidth(column, name) {

      if (column.ColumnKey === 'BuildingInfo') {
        return '150px'
      } else if (column.ColumnKey === 'LayerInfo') {
        return '120px'
      } else if (column.ColumnKey === 'FromatClass') {
        return '100px'
      } else if (column.ColumnKey === 'FromatName') {
        return '150px'
      } else if (column.ColumnKey === 'AreaRangeInfo') {
        return '160px'
      } else {
        return column.ColumnName ? column.ColumnName.length * 20 + 'px' : 100 + 'px'
      }
      // this.$nextTick(() => {
      //   if (column == null) {
      //     return name.length * 35 + 'px'
      //   } else {
      //
      //   }
      // }
      // );
    },
    // 获取显示列信息
    GetColumnData(Condition, BuildingInfoList, ShopFormatInfoList, AreaRangeList) {
      this.$http.post("/Base_ProjectManage/Project_DataAnalysis/GetColumnData", {
        "ProjectId": this.projectId,
        // "Condition": Condition,
        "BuildingInfoList": BuildingInfoList,
        "ShopFormatInfoList": ShopFormatInfoList,
        "EndDateTime": this.ruleForm.time,
        "AreaRangeList": AreaRangeList,
      })
        .then(res => {
          if (res.Success) {
            this.columnData = res.Data || []
          }
        })
    },
    printTable() {
      setTimeout(function () {
        Print({
          printable: "tableDiv",
          type: "html",
          scanStyles: false,
          targetStyles: ["*"],
          header: '',
          style:
            "table {border-collapse: collapse; width: 100%;} th, td {border: 1px solid #dfe6ec; padding: 4px 0;}" // 可选-打印时去掉眉页眉尾
        });
      }, 500);

    },
    //是否显示楼层全选
    handleFloor(item, num) {
      if (num === 1) {
        item.floorList.forEach(val => {
          val.isTrue = true
        })
      } else {
        item.floorList.forEach(val => {
          val.isTrue = false
        })
      }

    },
    //是否显示业态全选
    handleFloorShopFormat(item, num) {
      if (num === 1) {
        item.YtList.forEach(val => {
          val.isTrue = true
        })
      } else {
        item.YtList.forEach(val => {
          val.isTrue = false
        })
      }
    },
    tableRowClassName({ row, rowIndex }) {
      let a = -1;
      // let b = -1;
      // let c = -1;
      // let d = -1;
      this.dataList.forEach((item) => {
        if (this.BuildingInfoIndex === row.BuildingInfoIndex) {
          a = rowIndex;
        }
        // if (this.LayerInfoIndex === row.LayerInfoIndex) {
        //   b = rowIndex;
        // }
        // if (this.FromatClassIndex === row.FromatClassIndex) {
        //   c = rowIndex;
        // }
        // if (this.FromatNameIndex === row.FromatNameIndex) {
        //   d = rowIndex;
        // }
      });
      if (rowIndex === a) {
        return 'hover-rowa';
      }
      // else if (rowIndex === b) {
      //   return 'hover-rowb';
      // } else if (rowIndex === c) {
      //   return 'hover-rowc';
      // } else if (rowIndex === d) {
      //   return 'hover-rowd';
      // } else {
      //   return 'hover-row'
      // }
    },
    //楼栋选择
    louChange(a) {
      if (a.length === 0) {
        this.louList.forEach(item => {
          item.floorList.forEach(val => {
            this.$set(val, 'isTrue', false)
          })
        })
      } else {
        this.louList.forEach(item => {
          if (!a.includes(item.Id)) {
            item.floorList.forEach(val => {
              this.$set(val, 'isTrue', false)
            })
          }
        })

      }
    },
    //业态选择
    ShopFormatChange(a) {
      if (a.length === 0) {
        this.TypeList.forEach(item => {
          item.YtList.forEach(val => {
            val.isTrue = false
          })
        })
      } else {
        this.TypeList.forEach(item => {
          if (!a.includes(item.DicName)) {
            item.YtList.forEach(val => {
              this.$set(val, 'isTrue', false)
            })
          }
        })
        // a.forEach(ele => {
        //   this.TypeList.forEach(item => {
        //     if (item.DicName != ele) {
        //       item.YtList.forEach(val => {
        //         val.isTrue = false
        //       })
        //     }
        //   })
        // })

      }

    },
    //鼠标移入后赋值
    handleCellMouseEnter(row, column, cell, event) {
      this.BuildingInfoIndex = row.BuildingInfoIndex
      // this.LayerInfoIndex = row.LayerInfoIndex
      // this.FromatClassIndex = row.FromatClassIndex
      // this.FromatNameIndex = row.FromatNameIndex
      // if (column.label == '楼栋名称') {
      //   this.BuildingInfoIndex = row.BuildingInfoIndex
      //   this.LayerInfoIndex = row.LayerInfoIndex
      //   this.FromatClassIndex = row.FromatClassIndex
      //   this.FromatNameIndex = row.FromatNameIndex
      // } else if (column.label == '楼层名称') {
      //   this.BuildingInfoIndex = row.BuildingInfoIndex
      //   this.LayerInfoIndex = row.LayerInfoIndex
      //   this.FromatClassIndex = row.FromatClassIndex
      //   this.FromatNameIndex = row.FromatNameIndex
      // } else if (column.label == '业态级别') {
      //   this.BuildingInfoIndex = row.BuildingInfoIndex
      //   this.LayerInfoIndex = row.LayerInfoIndex
      //   this.FromatClassIndex = row.FromatClassIndex
      //   this.FromatNameIndex = row.FromatNameIndex
      // } else if (column.label == '业态名称') {
      //   this.BuildingInfoIndex = row.BuildingInfoIndex
      //   this.LayerInfoIndex = row.LayerInfoIndex
      //   this.FromatClassIndex = row.FromatClassIndex
      //   this.FromatNameIndex = row.FromatNameIndex
      // } else {
      //   this.BuildingInfoIndex = row.BuildingInfoIndex
      //   this.LayerInfoIndex = row.LayerInfoIndex
      //   this.FromatClassIndex = row.FromatClassIndex
      //   this.FromatNameIndex = row.FromatNameIndex
      // }

    },
    //鼠标移走后置空
    handleCellMouseLeave() {
      this.BuildingInfoIndex = '';
      // this.LayerInfoIndex = '';
      // this.FromatClassIndex = '';
      // this.FromatNameIndex = '';
    },
    //合并表格
    objectSpanMethod(obj) {

      if (obj.row.IsColSpan) {
        if (obj.column.property === obj.row.ColumnNameList[0]) {
          return {
            align: 'center',
            rowspan: 1,
            colspan: obj.row.ColumnNameList.length
          }
        } else if (obj.row.ColumnNameList.filter((item, index) => index != 0).includes(obj.column.property)) {
          return {
            rowspan: 1,
            colspan: 0
          }
        } else {
          return {
            rowspan: 1,
            colspan: 1
          }
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }

      // let arr = this.columnData.map(val => {
      //   if (val.ColumnFixed) {
      //     return val.ColumnKey
      //   }
      // })
      // let list =[
      //   'BuildingInfo',
      //   'LayerInfo',
      //   'FromatClass',
      //   'FromatName',
      //   // 'AreaRangeInfo'
      // ]
      // console.log(arr, 'arr')
      // if (obj.row.IsColSpan) {
      //   return {
      //     rowspan: 1,
      //     colspan: obj.row.ColSpan
      //   }
      // }


      // if(obj.row.IsColSpan){
      //   return{
      //     rowspan: 1,
      //     colspan: obj.row.ColSpan
      //   }
      // }else{

      // }
      // console.log(obj, 'obj')
      // if (obj.column.property === "BuildingInfo") {
      //   return {
      //     rowspan: obj.row.BuildingInfoCount,
      //     colspan: 1
      //   }
      // }
      // if (obj.column.property === "LayerInfo") {
      //   // if (obj.row.islouTrue) {
      //   //   return {
      //   //     rowspan: obj.row.LayerInfoCount,
      //   //     colspan: 4
      //   //   }
      //   // }
      //   return {

      //     rowspan: obj.row.LayerInfoCount,
      //     colspan: 1
      //   }
      // }
      // if (obj.column.property === "FromatClass") {
      //   // if (obj.row.islouTrue) {
      //   //   return {
      //   //     rowspan: obj.row.FromatClassLength,
      //   //     colspan: 0
      //   //   }
      //   // }
      //   // if (obj.row.isLayerInfoTrue) {
      //   //   return {
      //   //     rowspan: obj.row.FromatClassLength,
      //   //     colspan: 3
      //   //   }
      //   // }
      //   return {
      //     rowspan: obj.row.FromatClassCount,
      //     colspan: 1
      //   }

      // }
      // if (obj.column.property === "FromatName") {
      //   // if (obj.row.islouTrue) {
      //   //   return {
      //   //     rowspan: obj.row.FromatNameCount,
      //   //     colspan: 0
      //   //   }
      //   // }
      //   // if (obj.row.isTrue) {
      //   //   return {
      //   //     rowspan: obj.row.FromatNameCount,
      //   //     colspan: 2
      //   //   }
      //   // }
      //   // if (obj.row.isLayerInfoTrue) {
      //   //   return {
      //   //     rowspan: obj.row.FromatNameCount,
      //   //     colspan: 0
      //   //   }
      //   // }
      //   return {
      //     rowspan: obj.row.FromatNameCount,
      //     colspan: 1
      //   }


      // }
      // if (obj.column.property === "AreaRangeInfo") {
      //   // if (obj.row.isTrue || obj.row.isLayerInfoTrue || obj.row.islouTrue) {
      //   //   return {
      //   //     rowspan: 1,
      //   //     colspan: 0
      //   //   }
      //   // }
      //   return {
      //     rowspan: obj.row.AreaRangeCount,
      //     colspan: 1
      //   }
      //   if (obj.row.isTrue) {
      //     return {
      //       rowspan: obj.row.FromatClassLength,
      //       colspan: 3
      //     }
      //   } else {
      //     return {
      //       rowspan: obj.row.FromatClassLength,
      //       colspan: 1
      //     }
      //   }
      // }


    },
    //面积字典
    getZS_ShopAreaRange() {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: 'ZS_ShopAreaRange',
        })
        .then(res => {
          this.AreaRangeList = res.Data.DictionaryDetailsList || [];
        })
    },
    //表格数据
    async getDataList(Condition, BuildingInfoList, ShopFormatInfoList, AreaRangeList) {
      await this.$http
        .post("/Base_ProjectManage/Project_DataAnalysis/GetComprehensiveReturnData", {
          "ProjectId": this.projectId,
          "Condition": Condition,
          "BuildingInfoList": BuildingInfoList,
          "ShopFormatInfoList": ShopFormatInfoList,
          "EndDateTime": this.ruleForm.time,
          "AreaRangeList": AreaRangeList,
        }).then((res) => {

          if (res.Success) {
            // this.DataInfo = res.Data || {}
            // this.columnData = res.Data.ColumnData || []

            this.dataList = [...this.dataList, ...res.Data]
            this.dataListFlag = true
            // let info = {}
            // console.log(this.dataList, 'dataList')
            // let BuildingInfoIndex = 1;
            // let LayerInfoIndex = 1;
            // let FromatClassIndex = 1;
            // let FromatNameIndex = 1;
            // this.dataList.forEach((item, index) => {
            //   // item.LayerInfoIndex = LayerInfoIndex;
            //   // item.FromatClassIndex = FromatClassIndex;
            //   // item.FromatNameIndex = FromatNameIndex;
            //   if (index == 0) {
            //     item.BuildingInfoIndex = BuildingInfoIndex;
            //   } else if (item.BuildingInfo === this.dataList[index - 1].BuildingInfo) {
            //     item.BuildingInfoIndex = BuildingInfoIndex;
            //   } else if (item.BuildingInfo !== this.dataList[index - 1].BuildingInfo) {
            //     BuildingInfoIndex++
            //     item.BuildingInfoIndex = BuildingInfoIndex;
            //   }
            //   if (index == 0) {
            //     item.LayerInfoIndex = LayerInfoIndex;
            //   } else if (item.LayerInfo === this.dataList[index - 1].LayerInfo) {
            //     item.LayerInfoIndex = LayerInfoIndex;
            //   } else if (item.LayerInfo !== this.dataList[index - 1].LayerInfo) {
            //     LayerInfoIndex++
            //     item.LayerInfoIndex = LayerInfoIndex;
            //   }

            //   if (index == 0) {
            //     item.FromatClassIndex = FromatClassIndex;
            //   } else if (item.FromatClass === this.dataList[index - 1].FromatClass) {
            //     item.FromatClassIndex = FromatClassIndex;
            //   } else if (item.FromatClass !== this.dataList[index - 1].FromatClass) {
            //     FromatClassIndex++
            //     item.FromatClassIndex = FromatClassIndex;
            //   }

            //   if (index == 0) {
            //     item.FromatNameIndex = FromatNameIndex;
            //   } else if (item.FromatName === this.dataList[index - 1].FromatName) {
            //     item.FromatNameIndex = FromatNameIndex;
            //   } else if (item.FromatName !== this.dataList[index - 1].FromatName) {
            //     FromatNameIndex++
            //     item.FromatNameIndex = FromatNameIndex;
            //   }

            //   if (info[item.BuildingInfo]) {
            //     item.BuildingInfoLength = 0
            //     info[item.BuildingInfo].BuildingInfoLength += 1
            //     item.BuildingInfoIndex = BuildingInfoIndex;
            //   } else {
            //     info[item.BuildingInfo] = item
            //     item.BuildingInfoLength = 1
            //   }
            //   if (info[item.LayerInfo]) {
            //     this.$set(item, 'LayerInfoLength', 0)
            //     info[item.LayerInfo].LayerInfoLength += 1
            //   } else {
            //     info[item.LayerInfo] = item
            //     this.$set(item, 'LayerInfoLength', 1)
            //   }
            //   if (info[item.FromatClass]) {
            //     this.$set(item, 'FromatClassLength', 0)
            //     info[item.FromatClass].FromatClassLength += 1
            //   } else {
            //     info[item.FromatClass] = item
            //     this.$set(item, 'FromatClassLength', 1)
            //   }
            //   if (info[item.FromatName]) {
            //     this.$set(item, 'FromatNameLenght', 0)
            //     info[item.FromatName].FromatNameLenght += 1
            //   } else {
            //     info[item.FromatName] = item
            //     this.$set(item, 'FromatNameLenght', 1)
            //   }
            // })
            // console.log(this.dataList, 'dataList')
          } else {
            this.$message.error(res.Msg);
            this.dataListFlag = false
          }
        }).catch(err => {
          this.dataListFlag = false
        })
    },
    // 查询
    async handleSearch() {
      //找到对应的楼层
      let arr = []
      this.ruleForm.BuildingInfoList.forEach(val => {
        let obj = this.louList.find(item => item.Id == val)
        if (obj) {
          arr.push(obj)
        }
      })
      //格式化楼层数据
      let BuildingInfoList = []
      arr.forEach(item => {
        let LayerList = item.floorList.filter(item => {
          return item.isTrue
        }).map(item => {
          return {
            LayerName: item.code,
            Layer: item.id,
          }
        })
        let obj = {
          BuildingId: item.Id,
          BuildingCode: item.BuildingCode,
          LayerList
        }
        BuildingInfoList.push(obj)
      })
      let arr2 = []
      this.ruleForm.ShopFormatClass.forEach(val => {
        let obj = this.TypeList.find(item => item.DicName == val)
        if (obj) {
          arr2.push(obj)
        }
      })
      let ShopFormatInfoList = []
      arr2.forEach(item => {
        let ShopFormatNameList = item.YtList.filter(item => {
          return item.isTrue
        }).map(item => {
          return item.DicName
        })
        let obj = {
          ShopFormatClass: item.DicName,
          ShopFormatNameList: ShopFormatNameList
        }
        ShopFormatInfoList.push(obj)
      })
      let arr3 = []
      this.ruleForm.AreaRangeValue.forEach(val => {
        let obj = this.AreaRangeList.find(item => item.DicValue == val)
        if (obj) {
          arr3.push(obj)
        }
      })
      let AreaRangeList = arr3.map(item => {
        return {
          AreaRangeName: item.DicName,
          AreaRangeValue: item.DicValue,
        }
      }) || []
      this.loading = true
      this.dataListFlag = true
      this.dataList = []
      BuildingInfoList = BuildingInfoList.sort((a, b) => {
        return a.BuildingCode - b.BuildingCode
      })
      this.GetColumnData('', BuildingInfoList, ShopFormatInfoList, AreaRangeList)
      let Keyword = ''
      if (BuildingInfoList.length > 0) {
        for (const item of BuildingInfoList) {
          let Condition = {
            Condition: "BuildingInfo",
            Keyword: item.BuildingId,
          }
          await this.getDataList(Condition, BuildingInfoList, ShopFormatInfoList, AreaRangeList)
          if (!this.dataListFlag) {
            break
          }
        }
        Keyword = 'BuildingInfo'
      } else if (ShopFormatInfoList.length > 0) {
        for (const item of ShopFormatInfoList) {
          let Condition = {
            Condition: "FromatInfo",
            Keyword: item.ShopFormatClass,
          }
          await this.getDataList(Condition, BuildingInfoList, ShopFormatInfoList, AreaRangeList)
          if (!this.dataListFlag) {
            break
          }

        }
        Keyword = 'FromatInfo'

      } else if (AreaRangeList.length > 0) {
        for (const item of AreaRangeList) {
          let Condition = {
            Condition: "AreaRangeInfo",
            Keyword: item.AreaRangeValue,
          }
          await this.getDataList(Condition, BuildingInfoList, ShopFormatInfoList, AreaRangeList)
          if (!this.dataListFlag) {
            break
          }
        }
        Keyword = 'AreaRangeInfo'
      } else {
        let Condition = {
          Condition: "UnConditional",
          Keyword: 'UnConditional',
        }
        await this.getDataList(Condition, BuildingInfoList, ShopFormatInfoList, AreaRangeList)
        if (!this.dataListFlag) {
          this.$message.error('合计数据获取失败')
        }
        this.loading = false
        return
      }

      let Condition = {
        Condition: "AllInfo",
        Keyword: Keyword,
      }
      if (this.dataList.length > 1) {
        await this.getDataList(Condition, BuildingInfoList, ShopFormatInfoList, AreaRangeList)
        if (!this.dataListFlag) {
          this.$message.error('合计数据获取失败')
        }
      }
      this.loading = false

      return

    },
    //重置
    handleResetFilter() {
      this.ruleForm.BuildingInfoList = []
      this.louList.forEach(item => {
        item.floorList.forEach(val => {
          val.isTrue = false
        })
      })
      this.TypeList.forEach(item => {
        item.YtList.forEach(val => {

          this.$set(val, 'isTrue', false)
        })
      })
      this.ruleForm.time = ''
      this.ruleForm.AreaRangeValue = []
      this.ruleForm.ShopFormatClass = []
      this.ruleForm.ShopFormatNameList = []
      this.handleSearch()
    },
    //业态添加
    async ShopFormatClassChange(item, index) {
      // console.log(a, b)
      // let obj = this.TypeList.find(item => item.DicName == a) || {}
      // this.ruleForm.ShopFormatNameList = []
      await this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: item.DicValue || '',
        })
        .then((res) => {
          if (res.Success) {
            res.Data.DictionaryDetailsList.forEach(val => {
              this.$set(val, 'isTrue', false)
            })
            // this.YtList = res.Data.DictionaryDetailsList || [];
            this.TypeList[index].YtList = res.Data.DictionaryDetailsList
          } else {
            this.YtList = [];
          }
        });
    },
    //业态级别
    async getTypeList() {
      await this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: "FormatClass",
        })
        .then((res) => {
          if (res.Success) {
            this.TypeList = res.Data.DictionaryDetailsList || [];
            this.TypeList.forEach((item, index) => {
              this.$set(item, 'YtList', [])
              this.ShopFormatClassChange(item, index)
            })
          } else {
            this.TypeList = [];
          }
        });
    },
    //楼栋数据
    getLouData() {
      this.$http.post("/Base_ProjectManage/Project_Building/GetDataList", {
        Search: {
          "Condition": "ProjectId",
          "Keyword": this.projectId,
        }
      }).then((res) => {
        if (res.Success) {
          this.louList = res.Data
          this.louList.forEach((item) => {
            this.$set(item, 'isTrue', true)
            this.$set(item, 'isTrueArr', [])
            let arr = []
            for (let i = 1; i <= item.UnderGround_Floor; i++) {
              let obj = {
                code: 'B' + i,
                id: '2-' + i,
                isTrue: false,
              }
              arr.push(obj)
            }
            for (let i = 1; i <= item.OnGround_Floor; i++) {
              let obj = {
                code: 'F' + i,
                id: '1-' + i,
                isTrue: false,
              }
              arr.push(obj)
            }

            this.$set(item, 'floorList', [{
              code: 'RF',
              id: '3-RF',
              isTrue: false,
            }, ...arr])
          });
        } else {
          this.louList = []
        }

      });
    },
    handleBack() {
      this.$emit('changePageblock', '');
    },
  }
}
</script>


<style scoped lang="scss">
.box {
  padding: 0px;
  box-sizing: border-box;

}

::v-deep .el-descriptions-item__cell {
  width: calc(100% / 8);
}

::v-deep .el-descriptions-item__label {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #ffffff;
  background-color: #C02D20;
}

::v-deep .el-descriptions-item__content {
  font-size: 18px;
  color: black;
  font-weight: bold !important;
  background-color: #dddddd;
}


::v-deep .el-form-item__content {
  margin-left: 120px !important;
  width: calc(100% - 120px);
}

::v-deep .is-checked .itembut {
  color: #fff !important
}

::v-deep .MG_ComprehensiveDataPagetable {

  .el-table__fixed,
  .el-table__fixed-right {
    pointer-events: none;
  }

  .el-table__fixed td,
  .el-table__fixed-right td,
  .el-table__fixed th,
  .el-table__fixed-right th {
    pointer-events: auto;
  }

  .w-table__fixed-right {
    // 右固定列

    bottom: 8px !important;

    left: 8px !important;

  }

  th {
    background-color: #D1100B !important;
    color: #fff !important;
    padding: 5px 0px !important;
  }

  td {
    background-color: #fff !important;
    color: #000 !important;
    height: 20px;
    text-align: right !important;
  }

  .el-table__row:hover td {
    background-color: #F5F7FA !important;
  }

  .el-table__body-wrapper {
    background-color: #fff
  }

  .el-statistic {
    .con {
      display: block;
      text-align: right;
    }
  }
}


::v-deep .hover-rowa td {
  background-color: #F5F7FA !important;
}

::v-deep .hover-rowb td {
  background-color: #F5F7FA !important;
}

::v-deep .hover-rowc td {
  background-color: #F5F7FA !important;
}

::v-deep .hover-rowd td {
  background-color: #F5F7FA !important;
}

::v-deep .rowa {
  background-color: #F5F7FA !important;
}

::v-deep .rowb {
  background-color: #F5F7FA !important;
}

::v-deep .rowc {
  background-color: #F5F7FA !important;
}

::v-deep .rowd {
  background-color: #F5F7FA !important;
}

::v-deep .el-table th.gutter {
  display: table-cell !important;
}
</style>
