const col = [
  {
    DicKey: "Shop_BaseInfo",
    disabled: false,
    DicName: "基础信息",
    fixed: "left",
    width: "1200px",
    isRequire: false,
    show: true,
    children: [
      {
        DicKey: "BuildingId",
        DicName: "楼栋",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false, //判断是否是数字框
        oninput: "",
        show: true,
      },
      {
        DicKey: "IsOnGround",
        DicName: "地上/地下",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "v",
        show: true,
      },
      {
        DicKey: "Storey",
        DicName: "楼层",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopArea",
        DicName: "商铺面积",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopFormatClass",
        DicName: "业态级别",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopFormatName",
        DicName: "业态名称",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopBrand",
        DicName: "商铺品牌信息",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopSeparation",
        DicName: "商铺分隔模式",
        width: "150px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopChangeReason",
        DicName: "商铺变更原因",
        width: "220px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "Remark",
        DicName: "备注",
        width: "200px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        disabled: false,
        show: true,
      },
    ],
  },
];

const newCol = [
  {
    DicKey: "Shop_BaseInfo",
    DicName: "基础信息",
    fixed: "left",
    width: "1100px",
    isRequire: false,
    show: true,
    children: [
      {
        DicKey: "BuildingId",
        DicName: "楼栋",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "IsOnGround",
        DicName: "地上/地下",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "Storey",
        DicName: "楼层",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ProjectCode",
        DicName: "工程编号",
        width: "110px",
        fixed: "left",
        disabled: true,
        isRequire: false,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "InvestmentCode",
        DicName: "招商编码",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "商铺编号",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopName",
        DicName: "商铺名称",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },

      {
        DicKey: "ShopArea",
        DicName: "商铺面积",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopFormatClass",
        DicName: "业态级别",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopFormatName",
        DicName: "业态名称",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopBrand",
        DicName: "商铺品牌信息",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopSeparation",
        DicName: "商铺分隔模式",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopChangeReason",
        DicName: "商铺变更原因",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "Remark",
        DicName: "备注",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        disabled: true,
        show: true,
      },
    ],
  },
];
// 图纸报审 下拉数据
const DrawingApprovalOptions = [
  {
    id: 1,
    label: "已通过",
  },
  {
    id: 2,
    label: "未通过",
  },
];

//   是否开业
const IsOpeningBusinessOptions = [
  {
    id: 1,
    label: "是",
  },
  {
    id: 2,
    label: "否",
  },
];

//   业态级别下拉数据
const options = [
  {
    value: "1",
    label: "主力店",
  },
  {
    value: "2",
    label: "一般商城",
  },
];

// 电表下拉数据
const dianbiaoOptions = [
  {
    value: "1",
    label: "提供",
  },
  {
    value: "2",
    label: "不提供",
  },
];
// 电表安装位置
const dianbiaoWeizhiOptions = [
  {
    value: "1",
    label: "户内",
  },
  {
    value: "2",
    label: "公共管件",
  },
];
// 空调管制下拉列表数据
const kongtiaoguanzhiOptions = [
  {
    value: "1",
    label: "两管",
  },
  {
    value: "2",
    label: "四管",
  },
];
// 厨房区域降板有误
const chujiangbanOptions = [
  {
    value: "1",
    label: "有",
  },
  {
    value: "2",
    label: "无",
  },
];

export {
  col,
  newCol,
  DrawingApprovalOptions,
  IsOpeningBusinessOptions,
  options,
  dianbiaoOptions,
  dianbiaoWeizhiOptions,
  kongtiaoguanzhiOptions,
  chujiangbanOptions,
};
