var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"WSADMajor",staticStyle:{"margin-top":"30px"}},[_c('span',{staticClass:"bottom-span",staticStyle:{"padding-left":"20px"}},[_vm._v(_vm._s(_vm.im.DicName + "信息"))]),_c('div',[_c('div',{},[_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._l((_vm.im.children),function(m,l){return (_vm.im.children.length !== 0 && _vm.im.DicKey === 'WSADMajor')?_c('div',{key:l + new Date().getTime()},[(m.DicKey !== 'RemarkWSAD')?_c('div',{staticStyle:{"padding":"15px","width":"97%","text-align":"center","border":"1px solid #ccc","margin":"20px 0 0 0"}},[_vm._v(" "+_vm._s(m.DicKey === "WaterSupplyPipeSize" ? "给水条件" : m.DicKey === "CateringDrainagePipeSize" ? "餐饮排水条件" : m.DicKey === "NonCateringDrainagePipeSize" ? "非餐饮排水条件" : "燃气条件")+" ")]):_vm._e(),(m.DicKey !== 'RemarkWSAD')?_c('div',{staticStyle:{"padding":"10px 50px"}},[_vm._v(" "+_vm._s(m.DicName)+" ")]):_vm._e(),_c('div',{staticClass:"flex",staticStyle:{"display":"flex"}},[_vm._l((_vm.entity.WaterSupplyPipeSize),function(item,index){return (
                m.DicKey === 'WaterSupplyPipeSize' &&
                _vm.entity.WaterSupplyPipeSize.length !== 0
              )?_c('div',{key:index,staticStyle:{"display":"flex","width":"25%"}},[_c('div',{staticClass:"flex5 WSADMajor-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 WSADMajor-right background"},[_vm._v(_vm._s(item))])]):_vm._e()}),_vm._l((_vm.entity.CateringDrainagePipeSize),function(item,index){return (
                m.DicKey === 'CateringDrainagePipeSize' &&
                _vm.entity.CateringDrainagePipeSize.length !== 0
              )?_c('div',{key:index,staticStyle:{"display":"flex","width":"25%"}},[_c('div',{staticClass:"flex5 WSADMajor-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 WSADMajor-right background"},[_vm._v(_vm._s(item))])]):_vm._e()}),_vm._l((_vm.entity.NonCateringDrainagePipeSize),function(item,index){return (
                m.DicKey === 'NonCateringDrainagePipeSize' &&
                _vm.entity.NonCateringDrainagePipeSize.length !== 0
              )?_c('div',{key:index,staticStyle:{"display":"flex","width":"25%"}},[_c('div',{staticClass:"flex5 WSADMajor-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 WSADMajor-right background"},[_vm._v(_vm._s(item))])]):_vm._e()})],2)]):_vm._e()}),(_vm.im.DicKey === 'NaturalGas')?_c('div',{staticStyle:{"padding":"15px","width":"97%","text-align":"center","border":"1px solid #ccc","margin":"20px 0 0 0"}},[_vm._v(" "+_vm._s(_vm.im.DicName)+" ")]):_vm._e(),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"20px"}},_vm._l((_vm.im.children),function(v,i){return (
              _vm.im.children &&
              (v.DicKey === 'NaturalGasVolume' ||
                v.DicKey === 'NaturalGasPressure')
            )?_c('div',{key:i,staticClass:"flex flex5"},[_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 WSADMajor-left background"},[_vm._v(" "+_vm._s(v.DicName)+" ")]),_c('div',{staticClass:"flex5 WSADMajor-right background"},[_vm._v(" "+_vm._s(_vm.entity[v.DicKey])+" ")])])]):_vm._e()}),0),_vm._l((_vm.im.children),function(v,i){return (
            v.DicKey === 'GasConnectingPipeSize' &&
            _vm.entity.GasConnectingPipeSize?_vm.entity.GasConnectingPipeSize.length:'' !== 0
          )?_c('div',{key:i,staticStyle:{"display":"flex"}},_vm._l((_vm.entity.GasConnectingPipeSize),function(item,index){return (_vm.entity.GasConnectingPipeSize.lengt !== 0)?_c('div',{key:index,staticClass:"flex",staticStyle:{"width":"25%"}},[_c('div',{staticClass:"flex5 WSADMajor-left background"},[_vm._v(" "+_vm._s(v.DicName)+" ")]),_c('div',{staticClass:"flex5 WSADMajor-right background"},[_vm._v(_vm._s(item))])]):_vm._e()}),0):_vm._e()}),(_vm.im.DicKey === 'NaturalGas')?_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"WSADMajor-left background",staticStyle:{"flex":"1.18"}},[_vm._v(" 备注 ")]),_c('div',{staticClass:"WSADMajor-right background",staticStyle:{"flex":"8.82"}},[_vm._v(" "+_vm._s(_vm.entity.RemarkWSAD)+" ")])])]):_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }