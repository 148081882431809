<template>
  <!-- 表格最外层递归子组件 -->
  <!-- 后续需要打开 -->
  <!-- <el-table-column
      v-if="isShowBox"
      type="selection"
      width="55"
      align="center"
      @selection-change="handleSelectionChange"
    >
    </el-table-column> -->
  <div class="tableItem" :style="{ width: windowWidth + 'px' }">
    <div style="margin-bottom: 20px">
      <el-button
          size="mini"
          type="primary"
          style="margin-right: 10px"
          @click="exportChange('招商信息')"
      >导出
      </el-button
      >
    </div>
    <el-table
        ref="report-table"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
        show-overflow-tooltip
        border
        :show-summary="isShowSum"
        :data="tableData"
        :max-height="(windowHeight || height) + 'px'"
        :header-cell-style="{
        background: '#eff2f6',
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '14px',
        'text-align': alignType,
      }"
        row-class-name="tableRow"
        cell-class-name="tableCell"
        @cell-click="cellClick"
    >
      <template>
        <el-table-column
            prop="bianma"
            label="商铺编码"
            fixed="left"
            width="770px"
        >
          <template slot="header" slot-scope="scope">
            <div class="parentHeader">
              <!--  @command="handleCommand" -->

              <el-dropdown
                  trigger="click"
                  class="btn"
                  v-if="col.filter((im) => !im.show).length > 0"
              >
                <el-button
                    icon="el-icon-plus"
                    circle
                    type="success"
                ></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      icon="el-icon-plus"
                      v-for="item in col.filter((im) => !im.show)"
                      :key="item.DicKey"
                      :command="item.DicKey"
                  >{{ item.DicName }}
                  </el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
          <el-table-column
              prop="ShopCode"
              label="商铺编号"
              fixed="left"
              align="center"
              width="200px"
          >
            <!-- <template slot-scope="scope">
              <div
                v-if="scope.row.ProjectCode"
                @click.stop="urlUpdateRecord(scope.row)"
              >
                <a href="javascript:void(0)">{{ scope.row.ProjectCode }}</a>
              </div>
            </template> -->
          </el-table-column>
          <el-table-column
              prop="ProjectCode"
              label="工程编号"
              fixed="left"
              align="center"
              width="130px"
          >
          </el-table-column>
          <el-table-column
              prop="Storey"
              label="所在楼层"
              align="center"
              fixed="left"
              width="140px"
          >
          </el-table-column>
          <el-table-column
              prop="ShopFormatName"
              label="业态名称"
              fixed="left"
              align="center"
              width="150px"
          >
          </el-table-column>
          <el-table-column
              prop="ShopArea"
              label="商铺面积"
              fixed="left"
              align="center"
              width="150px"
          >
          </el-table-column>
        </el-table-column>
        <div v-for="(item, index) in col" :key="index">
          <!--  @hiddenColumn="hiddenColumn" -->
          <column-item
              v-if="
              item.show &&
              item.DicKey != 'EngineeringInfoJson' &&
              item.DicKey != 'ContractInfoJson' &&
              item.DicKey != 'FireInfo'
            "
              :col="item"
              :isShow="isShow"
              :column="col"
          >
          </column-item>
        </div>
      </template>

      <!-- <el-table-column label="操作" align="center" width="220" fixed="right">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center">
            <el-button
              class="button"
              :class="{ active: scope.row.Status == 3 }"
              type="text"
              @click.stop="handleEdit(scope.row)"
              >{{scope.row.Status == 3?'解锁':'编辑'}}</el-button
            >
            <span style="padding: 0 5px; color: #999999">|</span>
            <el-button
              class="button"
              type="text"
              @click.stop="handleDetail(scope.row)"
              >详情</el-button
            >
            <span style="padding: 0 5px; color: #999999">|</span>
            <el-button
              class="button"
              type="text"
              @click.stop="handleDelete(scope.row)"
              >删除</el-button
            >
            <span
              v-if="scope.row.Status !== 3"
              style="padding: 0 5px; color: #999999"
              >|</span
            >
            <div v-if="scope.row.Status !== 3">
              <el-button
                style="margin-left: 5px"
                class="button"
                type="text"
                @click.stop="submitButton(scope.row)"
                >版本提交</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column> -->
      <template #empty>
        <div class="empty" style="height: 200px">
          <span class="empty-desc">暂无数据</span>
        </div>
      </template>
    </el-table>
  </div>
</template>
<script>
import ColumnItem from "./columnltem.vue";
// 表格导出
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default {
  name: "TableItem",
  components: {
    ColumnItem,
  },
  props: {
    //表格数据
    tableData: {
      type: Array,
      default: () => [],
    },

    //树型结构表头数据
    col: {
      type: Array,
      default: () => [],
    },
    // 是否显示表格区域快
    isShow: {
      type: Number,
      default: 1,
    },
    //是否在表格下方显示合计
    isShowSum: {
      type: Boolean,
      default: false,
    },
    // 是否显示暂无数据
    isTable: {
      type: Boolean,
      default: false,
    },
    // 表格加载中提示
    loading: {
      type: Boolean,
      default: false,
    },
    // 是否可以编辑
    editDisabled: {
      type: Boolean,
      default: false,
    },
    //判断单元格文字是居中还是左对齐显示,默认居中
    alignType: {
      type: String,
      default: "center",
    },
    //设置表格高度,固定表头
    // height: {
    //   type: String,
    //   default: null, //默认不固定表头
    // },
    //判断是否显示多选框
    isShowBox: {
      type: Boolean,
      default: true, //默认不展示
    },
  },
  data() {
    return {
      // 浏览器当前可视窗口高度
      windowHeight: 0,
      // 浏览器当前可视窗口宽度
      windowWidth: 0,
      allParent: {
        ProjectCode: "Shop_BaseInfo",
        InvestmentCode: "Shop_BaseInfo",
        ShopCode: "Shop_BaseInfo",
        ShopName: "Shop_BaseInfo",
        BuildingId: "Shop_BaseInfo",
        IsOnGround: "Shop_BaseInfo",
        Storey: "Shop_BaseInfo",
        ShopArea: "Shop_BaseInfo",
        ShopFormatClass: "Shop_BaseInfo",
        ShopFormatName: "Shop_BaseInfo",
        ShopBrand: "Shop_BaseInfo",
        ShopSeparation: "Shop_BaseInfo",
        ShopChangeReason: "Shop_BaseInfo",
        Remark: "Shop_BaseInfo",
      },
    };
  },
  created() {
    console.log("获取导出大表格数据", this.tableData);
    let obj = {};
    this.$http
        .post(
            "/Base_ProjectManage/Project_DictionaryMain/GetDictionaryMainParentLevelInfo?topDicKey=ShopExtensionInfo"
        )
        .then((res) => {
          console.log(res, "==================");
          if (res.Success) {
            res.Data.forEach((item) => {
              obj[item.DicKey] = item.DicKey_Top;
            });
            this.allParent = {...this.allParent, ...obj};
            console.log(this.allParent);
          }
        });
  },
  computed: {
    height() {
      return window.innerHeight - 240;
    },
  },
  mounted() {
    // 动态获取当前浏览器窗口的高度
    this.getWindowHeight(); // 在初始化时获取窗口高度
    window.addEventListener("resize", this.getWindowHeight); // 在窗口大小改变时重新获取窗口高度
  }, //生命周期 - 挂载完成
  activated() {
  }, //若页面有keep-alive缓存，此函数会触发
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowHeight); // 组件销毁时移除resize事件监听器
  },
  methods: {
    // 导出表格
    // 导出
    exportChange(excelName) {
      console.log("导出");
      try {
        const $e = this.$refs["report-table"].$el;
        let $table = $e.querySelector(".el-table__fixed");
        if (!$table) {
          $table = $e;
        }

        const wb = XLSX.utils.table_to_book($table, {raw: true});
        const wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        FileSaver.saveAs(
            new Blob([wbout], {type: "application/octet-stream"}),
            `${excelName}.xlsx`
        );
      } catch (e) {
        if (typeof console !== "undefined") console.error(e);
      }
    },
    handleCommand(e) {
      this.$emit("showColumn", e);
    },
    cellClick(row, column, cell, event) {
      console.log(column.property);
      console.log(this.allParent[column.property]);
      // this.$emit("handleSelectionChange", "编辑", row, {
      //   parentKey: this.allParent[column.property],
      //   key: column.property,
      // });
    },
    hiddenColumn(key) {
      this.$emit("hiddenColumn", key);
    },
    // showColumn(key) {
    //   this.$emit("showColumn", key);
    // },
    // 动态获取当前浏览器窗口的高度 宽度  -30  是减去其余头部的区域部分   剩余需要显示的，，后续可以调整减号后的数字调整高度大小
    getWindowHeight() {
      this.windowHeight = window.innerHeight - 300;
      this.windowWidth = window.innerWidth - 50;
    },
    // 表格编辑
    handleEdit(val) {
      console.log("编辑了", val);
      this.$emit("handleSelectionChange", "编辑", val);
    },
    // 表格查看
    handleDetail(val) {
      console.log("表格查案", val);
      this.$emit("handleDetailChange", val);
    },
    // 表格删除
    handleDelete(val) {
      console.log("删除", val);
      this.$emit("handleDeleteChange", val);
    },

    // 版本提交
    submitButton(val) {
      console.log("版本提交", val);
      this.$emit("handleSubmitButtonChange", val);
    },
    setIsShow(show, data) {
    },
    // 工程编号当前行点击事件
    urlUpdateRecord(item) {
      console.log("子组件点击", item);
      this.$emit("parentSetIsShow", 4, item);
    },
  },
};
</script>
<style lang="scss">
.button.active {
  color: #bcbcbc !important;
}

.button:hover {
  color: #fa7474 !important;
  font-weight: bold;
  font-size: 16px;
}

.tableRow {
  td:hover {
    background: #ccc !important;
  }
}

.el-table__fixed-right-patch {
  background: #eff2f6;
}

.tableItem {
  overflow: auto;
}

/* 处理表格表头和内容错位问题 */
.el-table th.gutter {
  display: table-cell !important;
}

.el-table th,
.el-table td {
  padding: 7px 0 !important;
}

/**
修改表格样式
改变边框颜色
 */
.el-table--border,
.el-table--group {
  border: 2px solid #ddd !important;
}

/**
改变表格内竖线颜色
*/
.el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ddd !important;
}

/**
改变表格内行线颜色
*/
.el-table td,
.el-table th,
.el-table th.is-leaf {
  border-bottom: 1px solid #ddd !important;
}

.el-table thead tr th {
  border-color: #8c939d;
}

/* g改变表头字体颜色 */
.el-table thead {
  color: black;
}
</style>
