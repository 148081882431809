<template>
  <!-- 设计信息 -->
  <div class="shopBaseInfo" style="padding-bottom: 50px; margin-top: 20px">
    <!-- <div style="margin-left: 20px; margin-bottom: 20px">
         <el-descriptions title="">
           <el-descriptions-item label="工程编号">
             <el-tag size="small">{{ rows.ProjectCode }}</el-tag>
           </el-descriptions-item>
           <el-descriptions-item label="业态">
             <el-tag size="small">{{ rows.ShopFormatClass }}</el-tag>
           </el-descriptions-item>
           <el-descriptions-item label="商铺面积">
             <el-tag size="small">{{ rows.ShopArea }}</el-tag>
           </el-descriptions-item>
         </el-descriptions>
       </div> -->
    <el-form ref="Form" size="mini" :model="Form" :rules="Rules" :hide-required-asterisk="false" label-position="left"
      label-width="100px" style="display: flex; flex-wrap: wrap" v-loading="loading">
      <!-- 电气 padding-left: 28px;-->
      <el-collapse v-model="activeNames1" @change="handleCollapseChange" style="width: 100%">
        <el-collapse-item :title="title1" name="电气信息">
          <!-- //@click.stop="" 防止事件冒泡 往上传递 -->
          <template slot="title">
            <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;" @click.stop="">

              <div>
                {{ title1 }}
              </div>
              <div style="pointer-events: auto;" @click="handleCollapseClick('title1', 'activeNames1', '电气信息')">
                <span style="margin-right: 5px;font-size: 12px;color:#6e7073;" v-if="title1">
                  展开
                </span>
                <span style="margin-right: 5px;font-size: 12px;color:#6e7073;" v-if="!title1">
                  收起
                </span>
              </div>
            </div>
          </template>
          <div class="dianqi" style="margin-bottom: 10px">
            <!--            电气顶部标题区域-->
            <div style="
                display: flex;
                justify-content: space-between;
                margin-left: 20px;
                padding-bottom: 5px;
                border-bottom: 1px solid #d3cbcb;
                margin-bottom: 20px;
              ">
              <div style="display: flex">
                <img style="width: auto; height: 23px; margin-right: 5px" src="../../../../assets/dianqi.png" alt="" />
                <span style="font-weight: bolder">电气信息</span>
              </div>
              <div style="padding-right: 20px">
                <el-button style="padding-right: 10px; outline: none" v-if="ElectricalMajorDisabled == false"
                  type="primary" plain size="mini" @click="editCancel('ElectricalMajorDisabled')">取消</el-button>
                <el-button v-else style="padding-right: 10px; outline: none" @click="ElectricalMajorDisabled = false"
                  type="primary" plain size="mini">编辑</el-button>
                <el-button v-if="ElectricalMajorDisabled == false" style="padding-right: 10px; outline: none"
                  @click="handlerSubmitSave('ElectricalMajor')" type="primary" plain size="mini">保存</el-button>
              </div>
            </div>
            <!--            用电量和用电指标-->
            <div class="itembox" style="padding-left: 30px">
              <el-row>
                <el-col :span="24">
                  <div style="display: flex">
                    <div style="display: flex">
                      <v-input :RegExpFlag="'/[^\\d.]/g'" :suffixText="'kW'" :disabled="ElectricalMajorDisabled"
                        v-model="Form.PowerConsumption" title="用电量" placeholder="请输入"
                        :titleStyle="{ fontWeight: 'bolder' }" style="margin-left: 57px; width: 210px" />
                    </div>
                    <div style="display: flex; margin-left: 15px">
                      <!--                      <span class="span" style="flex: 4">用电指标</span>-->
                      <v-input :RegExpFlag="'/[^\\d.]/g'" :suffixText="'W/㎡'" :disabled="true" v-model="EnergyUseIndex"
                        title="用电指标" :titleStyle="{ fontWeight: 'bolder' }" style="margin-left: 5vw; width: 210px" />
                      <span v-if="!rows.ShopArea" style="margin-left: 20px; color: #ff0000">请您确保商铺面积已经填写!</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <!--            第一行-->
            <div class="itembox" style="position: relative; margin-top: 5px" v-for="(i, index) in ElectricalBox"
              :key="i.id">
              <div style="display: flex; margin-bottom: 5px">
                <!-- <SelectOptions
                  :width="'97%'"
                  :optionsType="'电气第一行路'"
                  @handleSearchList="handleSearchList"
                  ref="kongtiaoxingshi"
                  :searchList="dianqiLuOptions"
                /> -->
                <div style="display: flex">
                  <el-button type="primary" @click="AddelectricData(i, 'reduce')" :style="{
                    visibility: index != 0 ? '' : 'hidden',
                    padding: '5px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '3px',
                  }" size="medium" icon="el-icon-remove-outline"></el-button>

                  <el-button type="primary" v-if="index == ElectricalBox.length - 1" @click="
                    AddelectricData({ id: new Date().getTime() }, 'add')
                    " :style="{
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      position: 'absolute',
                      left: '-10px',
                      top: '30px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '3px',
                    }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                  <!--                  <span class="span first"></span>-->
                  <div class="input" style="width: 240px">
                    <v-input :RegExpFlag="'/[^\\w_-]/g'" v-model="ElectricalBox[index].EB_Number" title="商铺隔离电箱编号"
                      :disabled="ElectricalMajorDisabled" placeholder="请输入" />
                    <!--                    <el-input-->
                    <!--                      style="flex: 6"-->
                    <!--                      class="input"-->
                    <!--                      v-model="Form.PowerConsumption"-->
                    <!--                      autocomplete="off"-->
                    <!--                     -->
                    <!--                    >-->
                    <!--                    </el-input>-->
                  </div>
                </div>
                <div style="display: flex; padding-left: 15px; width: 300px">
                  <span class="span second">租户隔离箱进线开关额定电流</span>
                  <div class="input">
                    <el-tooltip class="item" effect="dark" content="无保护作用" placement="top-start" :open-delay="450">
                      <SelectOptions :rightText="'A'" :optionsType="'RatedCurrentOfIncomingSwitch'"
                        :disabled="ElectricalMajorDisabled" @clear="
                          selectClearChange(
                            $event,
                            'RatedCurrentOfIncomingSwitch',
                            index,
                            'ElectricalBox'
                          )
                          " @handleSearchList="
                            handleSearchList(
                              ElectricalBox[index],
                              $event,
                              'RatedCurrentOfIncomingSwitch'
                            )
                            " ref="kongtiaoxingshia" :value="ElectricalBox[index].RatedCurrentOfIncomingSwitch
                              " :searchList="newSelectOptions.ZS_EM_RatedCurrent" style="width: 100px" />
                    </el-tooltip>
                  </div>
                </div>
                <div style="display: flex; padding-left: 15px; width: 300px">
                  <span class="span three">租户上端配电断路器额定电流</span>
                  <div class="input">
                    <el-tooltip class="item" effect="dark" content="选项参考" placement="top-start" :open-delay="450">
                      <SelectOptions :rightText="'A'" :optionsType="'RatedCurrentOfCircuitBreaker'"
                        :disabled="ElectricalMajorDisabled" @clear="
                          selectClearChange(
                            $event,
                            'RatedCurrentOfCircuitBreaker',
                            index,
                            'ElectricalBox'
                          )
                          " @handleSearchList="
                            handleSearchList(
                              ElectricalBox[index],
                              $event,
                              'RatedCurrentOfCircuitBreaker'
                            )
                            " ref="kongtiaoxingshi" :searchList="newSelectOptions.ZS_EM_RatedCurrent" :value="ElectricalBox[index].RatedCurrentOfCircuitBreaker
                              " style="width: 100px" />
                    </el-tooltip>
                  </div>
                </div>
                <div style="padding-left: 15px; display: flex; width: 340px">
                  <span class="span four">租户电缆进线规格</span>
                  <div class="input">
                    <SelectOptions :optionsType="'IncomingCableSpecifications'" :disabled="ElectricalMajorDisabled"
                      @clear="
                        selectClearChange(
                          $event,
                          'IncomingCableSpecifications',
                          index,
                          'ElectricalBox'
                        )
                        " @handleSearchList="
                          handleSearchList(
                            ElectricalBox[index],
                            $event,
                            'IncomingCableSpecifications'
                          )
                          " ref="kongtiaoxingshi" :searchList="newSelectOptions.ZS_CableInletSpecifications"
                      :value="ElectricalBox[index].IncomingCableSpecifications" style="width: 200px" />
                  </div>
                </div>
              </div>
            </div>

            <!-- <div>
              <v-input :inputStyle="{ flex: 9 }" title="备注" v-model="Form.RemarkElectrical"
                :disabled="ElectricalMajorDisabled" placeholder="备注" :maxlength="200" :showWordLimit="true" />
            </div> -->
            <div class="" style="margin-top: 30px">
              <v-input :inputStyle="{ flex: 9 }" title="备注" v-model="Form.RemarkElectrical"
                :disabled="ElectricalMajorDisabled" placeholder="请输入备注信息" :maxlength="200" :showWordLimit="true"
                style="width: 60%; margin-left: 100px" />
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>

      <!-- 暖通 -->
      <el-collapse v-model="activeNames2" @change="handleCollapseChange" style="width: 100%">
        <el-collapse-item :title="title2" name="暖通信息">
          <template slot="title">
            <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;" @click.stop="">

              <div>
                {{ title2 }}
              </div>
              <div style="pointer-events: auto;" @click="handleCollapseClick('title2', 'activeNames2', '暖通信息');">
                <span style="margin-right: 5px;font-size: 12px;color:#6e7073;" v-if="title2">
                  展开
                </span>
                <span style="margin-right: 5px;font-size: 12px;color:#6e7073;" v-if="!title2">
                  收起
                </span>
              </div>
            </div>
          </template>
          <!--          顶部按钮区域-->
          <div style="
              display: flex;
              justify-content: space-between;
              margin-left: 20px;
              padding-bottom: 8px;
              border-bottom: 1px solid #d3cbcb;
              margin-bottom: 20px;
            ">
            <div style="display: flex">
              <img style="width: auto; height: 22px; margin-right: 5px" src="../../../../assets/nuantong.png" alt="" />
              <span style="font-weight: bolder">暖通信息</span>
            </div>
            <div style="padding-right: 20px">
              <el-button v-if="HVACMajorDisabled == false" style="padding-right: 10px; outline: none"
                @click="editCancel('HVACMajorDisabled')" type="primary" plain size="mini">取 消</el-button>
              <el-button v-else style="padding-right: 10px; outline: none" @click="HVACMajorDisabled = false"
                type="primary" plain size="mini">编辑</el-button>
              <el-button v-if="HVACMajorDisabled == false" style="padding-right: 10px; outline: none"
                @click="handlerSubmitSave('HVACMajor')" type="primary" plain size="mini">保存</el-button>
            </div>
          </div>
          <!--          顶部标题区域-->

          <div class="dianqi" style="margin-bottom: 10px; display: flex">
            <!--            空调条件-->
            <div class="itembox" style="flex: 5">
              <span style="padding-left: 5px; font-weight: bolder">空调条件</span>
              <div style="display: flex; margin-bottom: 8px">
                <el-row>
                  <el-col :span="24">
                    <div style="display: flex">
                      <div style="display: flex">
                        <span class="span four">空调形式</span>
                        <div class="input" style="margin-left: 13px; width: 80px">
                          <SelectOptions :width="'120px'" :disabled="HVACMajorDisabled"
                            :optionsType="'AirConditioningForm'" @clear="
                              selectClearChange(
                                $event,
                                'AirConditioningForm',
                                -1,
                                'AirConditioningCondition'
                              )
                              " @handleSearchList="
                                handleSearchList(
                                  AirConditioningCondition,
                                  $event,
                                  'AirConditioningForm'
                                )
                                " :value="AirConditioningCondition['AirConditioningForm']
                                  " ref="kongtiaoxingshi" :searchList="newSelectOptions.ZS_AirConditioningForm
                                    " />
                        </div>
                      </div>
                      <div style="padding-left: 55px; display: flex">
                        <span class="span four">VRF条件预留</span>
                        <div class="input">
                          <el-switch v-model="AirConditioningCondition.VRFConditionReservation
                            " @change="switchChange" :disabled="HVACMajorDisabled">
                          </el-switch>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <!--              制冷负荷指标-->
              <div style="
                  display: flex;
                  margin-top: 20px;
                  margin-bottom: 8px;
                  flex-wrap: wrap;
                " v-for="(i, index) in AC_ColdWaterPipeDiameter" :key="index + '-' + index">
                <div style="padding-left: 5px" :style="{ visibility: index == 0 ? '' : 'hidden' }">
                  <v-input :inputStyle="{ width: '110px' }" :RegExpFlag="'/[^\\d.]/g'"
                    v-model="AirConditioningCondition.TotalCoolingCapacity" :disabled="HVACMajorDisabled" title="总制冷量"
                    :suffixText="'kW'" placeholder="请输入" />
                </div>
                <div :style="{ visibility: index == 0 ? '' : 'hidden' }" style="margin-left: 30px">
                  <v-input :inputStyle="{ width: '110px' }" :RegExpFlag="'/[^\\w_-]/g'" :disabled="true"
                    v-model="RefrigerationLoadIndex" :suffixText="'W/㎡'" title="制冷负荷指标" />
                  <span v-if="!rows.ShopArea" style="color: #ff0000">请您确保商铺面积已经填写!</span>
                </div>

                <div style="position: relative; display: flex">
                  <el-button type="primary" @click="setAC_ColdWaterPipeDiameter(i, 'reduce', index)" :style="{
                    visibility: index != 0 ? '' : 'hidden',
                    padding: '5px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // marginRight: '3px',
                    // marginLeft:'5px',
                  }" size="medium" icon="el-icon-remove-outline"></el-button>

                  <el-button type="primary" v-if="index == AC_ColdWaterPipeDiameter.length - 1"
                    @click="setAC_ColdWaterPipeDiameter('', 'add', index)" :style="{
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      position: 'absolute',
                      left: '-5px',
                      top: '20px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '3px',
                      marginLeft: '5px',
                    }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                  <span class="span four" style="padding-left: 5px">空调冷水管径</span>
                  <SelectOptions style="padding-left: 10px" :width="'150px'" :optionsType="'AC_ColdWaterPipeDiameter'"
                    :disabled="HVACMajorDisabled" @clear="
                      selectClearChange(
                        $event,
                        'AirConditioningCondition',
                        index,
                        'AC_ColdWaterPipeDiameter'
                      )
                      " @handleSearchList="
                        handleSearchList(AC_ColdWaterPipeDiameter, $event, index)
                        " ref="kongtiaoxingshi" :value="AC_ColdWaterPipeDiameter[index]"
                    :searchList="newSelectOptions.ZS_WaterPipeDiameter" />
                </div>
              </div>
              <!--供热负荷指标              -->
              <div style="display: flex; margin-top: 20px" v-for="(i, index) in AC_HotWaterPipeDiameter"
                :key="i.id || index + new Date().getTime + index">
                <div style="padding-left: 5px" :style="{ visibility: index == 0 ? '' : 'hidden' }">
                  <v-input :inputStyle="{ width: '110px' }" :RegExpFlag="'/[^\\d.]/g'" :disabled="HVACMajorDisabled"
                    v-model="AirConditioningCondition.TotalHeatingCapacity" title="总供热量" :suffixText="'kW'"
                    placeholder="请输入" />
                </div>
                <div :style="{ visibility: index == 0 ? '' : 'hidden' }" style="margin-left: 30px">
                  <v-input :inputStyle="{ width: '110px' }" :disabled="true" :RegExpFlag="'/[^\\w_-]/g'"
                    v-model="HeatingLoadIndex" :suffixText="'W/㎡'" title="供热负荷指标" />
                  <span v-if="!rows.ShopArea" style="color: #ff0000">请您确保商铺面积已经填写!</span>
                </div>

                <div style="position: relative; display: flex">
                  <el-button type="primary" @click="setAC_HotWaterPipeDiameter(i, 'reduce', index)" :style="{
                    visibility: index != 0 ? '' : 'hidden',
                    padding: '5px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // marginRight: '3px',
                  }" size="medium" icon="el-icon-remove-outline"></el-button>

                  <el-button type="primary" v-if="index == AC_HotWaterPipeDiameter.length - 1"
                    @click="setAC_HotWaterPipeDiameter('', 'add', index)" :style="{
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      position: 'absolute',
                      left: '-5px',
                      top: '20px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '3px',
                      marginLeft: '5px',
                    }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                  <span class="span four" style="padding-left: 5px">空调热水管径</span>

                  <SelectOptions style="margin-left: 10px" :width="'150px'" :disabled="HVACMajorDisabled"
                    :optionsType="'AC_HotWaterPipeDiameter'" @clear="
                      selectClearChange(
                        $event,
                        'AirConditioningCondition',
                        index,
                        'AC_HotWaterPipeDiameter'
                      )
                      " @handleSearchList="
                        handleSearchList(AC_HotWaterPipeDiameter, $event, index)
                        " ref="kongtiaoxingshi" :value="AC_HotWaterPipeDiameter[index]"
                    :searchList="newSelectOptions.ZS_WaterPipeDiameter" />
                </div>
              </div>
              <!--             送风管道尺寸-->
              <div style="
                  display: flex;
                  margin-top: 20px;
                  float: left;
                  margin-left: 15px;
                " v-for="(i, index) in FreshAirPipelineSize" :key="i.id">
                <div style="flex: 3" :style="{ visibility: index == 0 ? '' : 'hidden' }">
                  <v-input :inputStyle="{ width: '110px' }" :RegExpFlag="'/[^\\w_-]/g'" :disabled="HVACMajorDisabled"
                    v-model="AirConditioningCondition.AirChangeFlow" title="新风量" :suffixText="'(m³/h)'"
                    placeholder="请输入" />
                </div>
                <div style="display: flex">
                  <div style="position: relative; display: flex">
                    <el-button type="primary" @click="setFreshAirPipelineSize(i, 'reduce')" :style="{
                      visibility: index != 0 ? '' : 'hidden',
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // marginRight: '3px',
                    }" size="medium" icon="el-icon-remove-outline"></el-button>

                    <el-button type="primary" v-if="index == FreshAirPipelineSize.length - 1" @click="
                      setFreshAirPipelineSize(
                        { id: new Date().getTime() },
                        'add'
                      )
                      " :style="{
                        padding: '5px',
                        height: '20px',
                        display: 'flex',
                        position: 'absolute',
                        left: '15px',
                        top: '20px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '3px',
                        marginLeft: '5px',
                      }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                    <span class="" style="padding-left: 30px; margin-right: 5px">新风管道尺寸</span>
                    <!-- <SelectOptions
                      :width="'90px'"
                      :optionsType="'送风管道尺寸1'"
                      @handleSearchList="handleSearchList"
                      ref="kongtiaoxingshi"
                      :searchList="dianqiGuiOptions"
                    /> -->
                    <el-input style="width: 120px" v-model="FreshAirPipelineSize[index].FAPS_A" autocomplete="off"
                      placeholder="请输入" oninput="value=value.replace(/[^\d]/g,'')" :disabled="HVACMajorDisabled">
                      <i slot="suffix" style="font-style: normal">mm</i>
                    </el-input>
                    <span style="padding: 0 10px 0 10px"> X </span>
                    <!-- <SelectOptions
                      :width="'90px'"
                      :optionsType="'送风管道尺寸2'"
                      @handleSearchList="handleSearchList"
                      ref="kongtiaoxingshi"
                      :searchList="dianqiGuiOptions"
                    /> -->
                    <el-input style="width: 120px" v-model="FreshAirPipelineSize[index].FAPS_B" autocomplete="off"
                      placeholder="请输入" oninput="value=value.replace(/[^\d]/g,'')" :disabled="HVACMajorDisabled">
                      <i slot="suffix" style="font-style: normal">mm</i>
                    </el-input>
                  </div>
                </div>
              </div>
              <!--             送风管道尺寸-->
              <div style="display: flex; margin-top: 20px">
                <div style="flex: 3">
                  <!--                  -->
                  <!-- <SelectOptions
                    :width="'100px'"
                    :position="'end'"
                    :optionsType="'电气第一行路'"
                    @handleSearchList="handleSearchList"
                    ref="kongtiaoxingshi"
                    :searchList="dianqiLuOptions"
                  /> -->
                </div>
              </div>

              <!--              <el-form-item label="空调冷水管径(kW)">-->
              <!--                <div style="display: flex">-->
              <!--                  <SelectOptions-->
              <!--                    :optionsType="'冷左'"-->
              <!--                    @handleSearchList="handleSearchList"-->
              <!--                    ref="kongtiaoxingshi"-->
              <!--                    :searchList="xingshiOptions"-->
              <!--                  />-->
              <!--                  <SelectOptions-->
              <!--                     :optionsType="'冷右'"-->
              <!--                    @handleSearchList="handleSearchList"-->
              <!--                    ref="kongtiaoxingshi"-->
              <!--                    :searchList="xingshiOptions"-->
              <!--                  />-->
              <!--                </div>-->
              <!--              </el-form-item>-->
            </div>
            <!--厨房通风条件-->
            <div style="
                border-left: 2px solid #ceced3;
                flex: 5;
                margin-left: 8px;
                padding-left: 8px;
              ">
              <!--              厨房通风条件-->
              <div style="">
                <span class="textfont">厨房通风条件</span>
                <!--              厨房排油烟量区域-->
                <div style="display: flex" v-for="(i, index) in KitchenExhaustPipeSize" :key="i.id">
                  <el-form-item label="厨房排油烟量" :style="{ visibility: index == 0 ? '' : 'hidden' }">
                    <el-input v-model="VentilationCondition.KitchenOilFumeDischarge" autocomplete="off"
                      oninput="value=value.replace(/[^\d]/g,'')" :disabled="HVACMajorDisabled" placeholder="请输入">
                      <i slot="suffix" style="font-style: normal">(m³/h)</i>
                    </el-input>
                  </el-form-item>
                  <div style="position: relative; display: flex">
                    <el-button type="primary" @click="setKitchenfumes(i, 'reduce')" :style="{
                      visibility: index != 0 ? '' : 'hidden',
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '3px',
                    }" size="medium" icon="el-icon-remove-outline"></el-button>

                    <el-button type="primary" v-if="index == KitchenExhaustPipeSize.length - 1" @click="
                      setKitchenfumes({ id: new Date().getTime() }, 'add')
                      " :style="{
                        padding: '5px',
                        height: '20px',
                        display: 'flex',
                        position: 'absolute',
                        left: '-10px',
                        top: '45px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '3px',
                      }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                    <el-form-item label="排油烟管道尺寸">
                      <div style="display: flex">
                        <!-- <SelectOptions
                          :optionsType="'空调形式'"
                          @handleSearchList="handleSearchList"
                          ref="kongtiaoxingshi"
                          :searchList="xingshiOptions"
                        /> -->
                        <el-input v-model="KitchenExhaustPipeSize[index].KEPS_A" autocomplete="off"
                          oninput="value=value.replace(/[^\d]/g,'')" :disabled="HVACMajorDisabled">
                          <i slot="suffix" style="font-style: normal">mm</i>
                        </el-input>
                        <span style="padding: 0 5px">X</span>
                        <!-- <SelectOptions
                          :optionsType="'空调形式'"
                          @handleSearchList="handleSearchList"
                          ref="kongtiaoxingshi"
                          :searchList="xingshiOptions"
                        /> -->
                        <el-input v-model="KitchenExhaustPipeSize[index].KEPS_B" autocomplete="off"
                          oninput="value=value.replace(/[^\d]/g,'')" :disabled="HVACMajorDisabled">
                          <i slot="suffix" style="font-style: normal">mm</i>
                        </el-input>
                      </div>
                    </el-form-item>
                  </div>
                </div>
                <div style=""></div>
                <!-- 厨房平时排风事故排风量区域-->
                <div style="display: flex" v-for="(i, index) in KitchenNormalAccidentExhaustPipeSize" :key="i.id">
                  <el-form-item label="厨房平时排风(事故排风量)" :style="{ visibility: index == 0 ? '' : 'hidden' }">
                    <el-input v-model="VentilationCondition.KitchenNormalAccidentExhaustVolume
                      " autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" :disabled="HVACMajorDisabled"
                      placeholder="请输入">
                      <i slot="suffix" style="font-style: normal">(m³/h)</i>
                    </el-input>
                  </el-form-item>
                  <div style="position: relative; display: flex">
                    <el-button type="primary" @click="
                      setKitchenNormalAccidentExhaustPipeSize(i, 'reduce')
                      " :style="{
                        visibility: index != 0 ? '' : 'hidden',
                        padding: '5px',
                        height: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '3px',
                      }" size="medium" icon="el-icon-remove-outline"></el-button>

                    <el-button type="primary" v-if="
                      index == KitchenNormalAccidentExhaustPipeSize.length - 1
                    " @click="
                      setKitchenNormalAccidentExhaustPipeSize(
                        { id: new Date().getTime() },
                        'add'
                      )
                      " :style="{
                        padding: '5px',
                        height: '20px',
                        display: 'flex',
                        position: 'absolute',
                        left: '-10px',
                        top: '45px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '3px',
                      }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                    <el-form-item label="排风管道尺寸">
                      <div style="display: flex">
                        <!-- <SelectOptions
                          :optionsType="'排中'"
                          @handleSearchList="handleSearchList"
                          ref="kongtiaoxingshi"
                          :searchList="xingshiOptions"
                        /> -->
                        <el-input v-model="KitchenNormalAccidentExhaustPipeSize[index].KNAEPS_A
                          " autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')"
                          :disabled="HVACMajorDisabled">
                          <i slot="suffix" style="font-style: normal">mm</i>
                        </el-input>
                        <span style="padding: 0 5px">X</span>
                        <!-- <SelectOptions
                          :optionsType="'排右'"
                          @handleSearchList="handleSearchList"
                          ref="kongtiaoxingshi"
                          :searchList="xingshiOptions"
                        /> -->
                        <el-input v-model="KitchenNormalAccidentExhaustPipeSize[index].KNAEPS_B
                          " autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')"
                          :disabled="HVACMajorDisabled">
                          <i slot="suffix" style="font-style: normal">mm</i>
                        </el-input>
                      </div>
                    </el-form-item>
                  </div>
                </div>

                <!-- 厨房补风量区域-->
                <div style="display: flex" v-for="(i, index) in KitchenAirReplenishmentPipeSize" :key="i.id">
                  <el-form-item label="厨房补风量" :style="{ visibility: index == 0 ? '' : 'hidden' }">
                    <el-input v-model="VentilationCondition.KitchenAirReplenishmentVolume
                      " autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" :disabled="HVACMajorDisabled"
                      placeholder="请输入">
                      <i slot="suffix" style="font-style: normal">(m³/h)</i>
                    </el-input>
                  </el-form-item>
                  <div style="position: relative; display: flex">
                    <el-button type="primary" @click="setKitchenAirReplenishmentPipeSize(i, 'reduce')" :style="{
                      visibility: index != 0 ? '' : 'hidden',
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '3px',
                    }" size="medium" icon="el-icon-remove-outline"></el-button>

                    <el-button type="primary" v-if="index == KitchenAirReplenishmentPipeSize.length - 1" @click="
                      setKitchenAirReplenishmentPipeSize(
                        { id: new Date().getTime() },
                        'add'
                      )
                      " :style="{
                        padding: '5px',
                        height: '20px',
                        display: 'flex',
                        position: 'absolute',
                        left: '-10px',
                        top: '45px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '3px',
                      }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                    <el-form-item label="补风管道尺寸">
                      <div style="display: flex">
                        <!-- <SelectOptions
                          :optionsType="'排中'"
                          @handleSearchList="handleSearchList"
                          ref="kongtiaoxingshi"
                          :searchList="xingshiOptions"
                        /> -->
                        <el-input v-model="KitchenAirReplenishmentPipeSize[index].KARPS_A
                          " autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')"
                          :disabled="HVACMajorDisabled">
                          <i slot="suffix" style="font-style: normal">mm</i>
                        </el-input>
                        <span style="padding: 0 5px">X</span>
                        <!-- <SelectOptions
                          :optionsType="'排右'"
                          @handleSearchList="handleSearchList"
                          ref="kongtiaoxingshi"
                          :searchList="xingshiOptions"
                        /> -->
                        <el-input v-model="KitchenAirReplenishmentPipeSize[index].KARPS_B
                          " autocomplete="off" :disabled="HVACMajorDisabled"
                          oninput="value=value.replace(/[^\d]/g,'')">
                          <i slot="suffix" style="font-style: normal">mm</i>
                        </el-input>
                      </div>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <!--              个护通风条件-->
              <span class="textfont">个护通风条件</span>
              <div style="display: flex" v-for="(i, index) in IndividualProtectiveExhaustAirPipeSize" :key="i.id">
                <el-form-item label="个护排风量" :style="{ visibility: index == 0 ? '' : 'hidden' }">
                  <el-input v-model="VentilationCondition.IndividualProtectiveExhaustAirVolume
                    " placeholder="请输入" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')"
                    :disabled="HVACMajorDisabled">
                    <i slot="suffix" style="font-style: normal">(m³/h)</i>
                  </el-input>
                </el-form-item>
                <div style="position: relative; display: flex">
                  <el-button type="primary" @click="
                    setIndividualProtectiveExhaustAirPipeSize(i, 'reduce')
                    " :style="{
                      visibility: index != 0 ? '' : 'hidden',
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '3px',
                    }" size="medium" icon="el-icon-remove-outline"></el-button>

                  <el-button type="primary" v-if="
                    index == IndividualProtectiveExhaustAirPipeSize.length - 1
                  " @click="
                    setIndividualProtectiveExhaustAirPipeSize(
                      { id: new Date().getTime() },
                      'add'
                    )
                    " :style="{
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      position: 'absolute',
                      left: '-10px',
                      top: '45px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '3px',
                    }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                  <el-form-item label="排风管道尺寸">
                    <div style="display: flex">
                      <!-- <SelectOptions
                        :optionsType="'空调形式'"
                        @handleSearchList="handleSearchList"
                        ref="kongtiaoxingshi"
                        :searchList="xingshiOptions"
                      /> -->
                      <el-input v-model="IndividualProtectiveExhaustAirPipeSize[index].IPEAPS_A
                        " autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" :disabled="HVACMajorDisabled">
                        <i slot="suffix" style="font-style: normal">mm</i>
                      </el-input>
                      <span style="padding: 0 5px">X</span>
                      <el-input v-model="IndividualProtectiveExhaustAirPipeSize[index].IPEAPS_B
                        " autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" :disabled="HVACMajorDisabled">
                        <i slot="suffix" style="font-style: normal">mm</i>
                      </el-input>
                      <!-- <SelectOptions
                        :optionsType="'空调形式'"
                        @handleSearchList="handleSearchList"
                        ref="kongtiaoxingshi"
                        :searchList="xingshiOptions"
                      /> -->
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <!--          暖通备注-->
          <div class="" style="margin-top: 5px">
            <v-input :inputStyle="{ flex: 9 }" title="备注" v-model="Form.HVACRemark" :disabled="HVACMajorDisabled"
              placeholder="请输入备注信息" :maxlength="200" :showWordLimit="true" style="width: 60%; margin-left: 50px" />
          </div>
        </el-collapse-item>
      </el-collapse>

      <!-- 给排水 -->
      <el-collapse v-model="activeNames3" @change="handleCollapseChange" style="width: 100%">
        <el-collapse-item :title="title3" name="给排水信息">
          <template slot="title">
            <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;" @click.stop="">

              <div>
                {{ title3 }}
              </div>
              <div style="pointer-events: auto;" @click="handleCollapseClick('title3', 'activeNames3', '给排水信息');">
                <span style="margin-right: 5px;font-size: 12px;color:#6e7073;" v-if="title3">
                  展开
                </span>
                <span style="margin-right: 5px;font-size: 12px;color:#6e7073;" v-if="!title3">
                  收起
                </span>
              </div>
            </div>
          </template>
          <div class="dianqi" style="width: 100%">
            <!--          顶部头部区域-->
            <div style="
                display: flex;
                justify-content: space-between;
                margin-left: 20px;
                padding-bottom: 8px;
                border-bottom: 1px solid #d3cbcb;
                margin-bottom: 20px;
              ">
              <div style="display: flex">
                <img style="width: auto; height: 22px; margin-right: 5px" src="../../../../assets/geipaishui.png"
                  alt="" />
                <span style="font-weight: bolder">给排水信息</span>
              </div>
              <div style="padding-right: 20px">
                <el-button v-if="WSADMajorDisabled == false" style="padding-right: 10px; outline: none"
                  @click="editCancel('WSADMajorDisabled')" type="primary" plain size="mini">取 消</el-button>
                <el-button v-else style="padding-right: 10px; outline: none" @click="WSADMajorDisabled = false"
                  type="primary" plain size="mini">编辑</el-button>
                <el-button v-if="WSADMajorDisabled == false" style="padding-right: 10px; outline: none"
                  @click="handlerSubmitSave('WSADMajor')" type="primary" plain size="mini">保存</el-button>
              </div>
            </div>

            <!--            内容区域-->
            <div style="display: flex">
              <!--              最左侧区块给水条件-->
              <div style="
                  border: 1px solid #ccc;
                  padding: 10px;
                  flex: 3;
                  padding-left: 5px;
                ">
                <span class="textfont">给水条件</span>
                <!--                第一行-->
                <div style="
                    display: flex;
                    position: relative;
                    margin-bottom: 5px;
                    margin-top: 20px;
                  " v-for="(i, index) in WaterSupplyPipeSize" :key="i.id">
                  <el-button type="primary" @click="setWaterSupplyPipeSize(i, 'reduce', index)" :style="{
                    visibility: index != 0 ? '' : 'hidden',
                    padding: '5px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '3px',
                  }" size="medium" icon="el-icon-remove-outline"></el-button>
                  <el-button type="primary" v-if="index == WaterSupplyPipeSize.length - 1"
                    @click="setWaterSupplyPipeSize('', 'add', index)" :style="{
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      position: 'absolute',
                      left: '-10px',
                      top: '25px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '3px',
                    }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                  <div class="itembox" :style="{
                    display: 'flex',
                    marginBottom:
                      index == WaterSupplyPipeSize.length - 1 ? '20px' : '',
                  }">
                    <span>给水管径</span>
                  </div>
                  <div class="itembox" style="display: flex; width: 10vw; padding-left: 5px">
                    <div class="input">
                      <SelectOptions :disabled="WSADMajorDisabled" :optionsType="'WaterSupplyPipeSize'" @clear="
                        selectClearChange(
                          $event,
                          'WaterSupplyPipeSize',
                          index,
                          'WaterSupplyPipeSize'
                        )
                        " @handleSearchList="
                          handleSearchList(WaterSupplyPipeSize, $event, index)
                          " ref="kongtiaoxingshi" :value="WaterSupplyPipeSize[index]"
                        :searchList="newSelectOptions.ZS_DrainagePipeDiameter" style="width: 150px" />
                    </div>
                  </div>
                </div>
                <!--                第二行-->
                <!-- <div style="display: flex">
                  <div class="itembox" style="display: flex">
                    <span class="span first" style="color: #fff">佈局展位</span>
                    
                  </div>
                  <div class="itembox" style="display: flex; padding-left: 5px">
                    <div class="input">
                      <SelectOptions
                        :optionsType="'空调形式'"
                        @handleSearchList="handleSearchList"
                        ref="kongtiaoxingshi"
                        :searchList="xingshiOptions"
                      />
                    </div>
                  </div>
                </div> -->
              </div>

              <!--          餐饮排水条件    -->
              <div style="
                  border: 1px solid #ccc;
                  padding: 10px;
                  flex: 3;
                  padding-left: 5px;
                ">
                <span class="textfont">餐饮排水条件</span>
                <!--                第一行-->
                <div style="
                    display: flex;
                    position: relative;
                    margin-bottom: 5px;
                    margin-top: 20px;
                  " v-for="(i, index) in CateringDrainagePipeSize" :key="i.id">
                  <el-button type="primary" @click="setCateringDrainagePipeSize(i, 'reduce', index)" :style="{
                    visibility: index != 0 ? '' : 'hidden',
                    padding: '5px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '3px',
                  }" size="medium" icon="el-icon-remove-outline"></el-button>
                  <el-button type="primary" v-if="index == CateringDrainagePipeSize.length - 1"
                    @click="setCateringDrainagePipeSize('', 'add', index)" :style="{
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      position: 'absolute',
                      left: '-10px',
                      top: '25px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '3px',
                    }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                  <div class="itembox" :style="{
                    display: 'flex',
                    marginBottom:
                      index == CateringDrainagePipeSize.length - 1
                        ? '20px'
                        : '',
                  }">
                    <span>排水管径</span>
                    <!-- <div class="input">
                      <SelectOptions
                        :optionsType="'空调形式'"
                        @handleSearchList="handleSearchList"
                        ref="kongtiaoxingshi"
                        :searchList="xingshiOptions"
                      />
                    </div> -->
                  </div>
                  <div class="itembox" style="display: flex; padding-left: 5px">
                    <div class="input">
                      <SelectOptions :disabled="WSADMajorDisabled" :optionsType="'CateringDrainagePipeSize'" @clear="
                        selectClearChange(
                          $event,
                          'CateringDrainagePipeSize',
                          index,
                          'CateringDrainagePipeSize'
                        )
                        " @handleSearchList="
                          handleSearchList(
                            CateringDrainagePipeSize,
                            $event,
                            index
                          )
                          " :value="CateringDrainagePipeSize[index]" ref="kongtiaoxingshi"
                        :searchList="newSelectOptions.ZS_DrainagePipeDiameter" style="width: 150px" />
                    </div>
                  </div>
                </div>
                <!--                第二行-->
                <!-- <div style="display: flex">
                  <div class="itembox" style="display: flex">
                    <span class="span first" style="color: #fff">佈局展位</span>
                   
                  </div>
                  <div class="itembox" style="display: flex; padding-left: 5px">
                    <div class="input">
                      <SelectOptions
                        :optionsType="'空调形式'"
                        @handleSearchList="handleSearchList"
                        ref="kongtiaoxingshi"
                        :searchList="xingshiOptions"
                      />
                    </div>
                  </div>
                </div> -->
              </div>

              <!--              非餐饮排水条件-->
              <div style="
                  border: 1px solid #ccc;
                  padding: 10px;
                  flex: 3;
                  padding-left: 5px;
                ">
                <span class="textfont">非餐饮排水条件</span>
                <!--                第一行-->
                <div style="
                    display: flex;
                    position: relative;
                    margin-bottom: 5px;
                    margin-top: 20px;
                  " v-for="(i, index) in NonCateringDrainagePipeSize" :key="i.id">
                  <el-button type="primary" @click="setNonCateringDrainagePipeSize(i, 'reduce', index)" :style="{
                    visibility: index != 0 ? '' : 'hidden',
                    padding: '5px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '3px',
                  }" size="medium" icon="el-icon-remove-outline"></el-button>
                  <el-button type="primary" v-if="index == NonCateringDrainagePipeSize.length - 1"
                    @click="setNonCateringDrainagePipeSize('', 'add', index)" :style="{
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      position: 'absolute',
                      left: '-10px',
                      top: '25px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '3px',
                    }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                  <div class="itembox" :style="{
                    display: 'flex',
                    marginBottom:
                      index == NonCateringDrainagePipeSize.length - 1
                        ? '20px'
                        : '',
                  }">
                    <span>排水管径</span>
                    <!-- <div class="input">
                      <SelectOptions
                        :optionsType="'空调形式'"
                        @handleSearchList="handleSearchList"
                        ref="kongtiaoxingshi"
                        :searchList="xingshiOptions"
                      />
                    </div> -->
                  </div>
                  <div class="itembox" style="display: flex; padding-left: 5px">
                    <div class="input">
                      <SelectOptions :optionsType="'NonCateringDrainagePipeSize'" :disabled="WSADMajorDisabled" @clear="
                        selectClearChange(
                          $event,
                          'NonCateringDrainagePipeSize',
                          index,
                          'NonCateringDrainagePipeSize'
                        )
                        " @handleSearchList="
                          handleSearchList(
                            NonCateringDrainagePipeSize,
                            $event,
                            index
                          )
                          " :value="NonCateringDrainagePipeSize[index]" ref="kongtiaoxingshi"
                        :searchList="newSelectOptions.ZS_DrainagePipeDiameter" style="width: 150px" />
                    </div>
                  </div>
                </div>
                <!--                第二行-->
                <!-- <div style="display: flex">
                  <div class="itembox" style="display: flex">
                    <span class="span first" style="color: #fff">佈局展位</span>
                    
                  </div>
                  <div class="itembox" style="display: flex; padding-left: 5px">
                    <div class="input">
                      <SelectOptions
                        :optionsType="'空调形式'"
                        @handleSearchList="handleSearchList"
                        ref="kongtiaoxingshi"
                        :searchList="xingshiOptions"
                      />
                    </div>
                  </div>
                </div> -->
              </div>

              <!--             燃气条件-->
              <div style="
                  border: 1px solid #ccc;
                  padding: 10px;
                  flex: 3;
                  padding-left: 5px;
                ">
                <span class="textfont">燃气条件</span>

                <!--                第一行-->
                <div style="margin-bottom: 5px; margin-top: 10px">
                  <div style="display: flex">
                    <div class="itembox" style="display: flex">
                      <div class="input" style="padding-left: 25px">
                        <v-input :RegExpFlag="'/[^\\w_-]/g'" :suffix-text="'m³/h'" v-model="Form.NaturalGasVolume"
                          title="天然气量" :disabled="WSADMajorDisabled" placeholder="请输入" />
                        <v-input :RegExpFlag="'/[^\\w_-]/g'" :suffix-text="'kPa'" v-model="Form.NaturalGasPressure"
                          title="天然气压力" :disabled="WSADMajorDisabled" placeholder="请输入" style="margin-left: -12px" />
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; position: relative" v-for="(i, index) in GasConnectingPipeSize"
                    :key="i.id">
                    <el-button type="primary" @click="setGasConnectingPipeSize(i, 'reduce', index)" :style="{
                      visibility: index != 0 ? '' : 'hidden',
                      padding: '5px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '3px',
                    }" size="medium" icon="el-icon-remove-outline"></el-button>
                    <el-button type="primary" v-if="index == GasConnectingPipeSize.length - 1"
                      @click="setGasConnectingPipeSize('', 'add', index)" :style="{
                        padding: '5px',
                        height: '20px',
                        display: 'flex',
                        position: 'absolute',
                        left: '-9px',
                        top: '25px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '3px',
                      }" size="medium" icon="el-icon-circle-plus-outline"></el-button>
                    <div class="itembox" :style="{
                      marginLeft: '-6px',
                      width: '70px',
                      display: 'flex',
                      marginBottom:
                        index == GasConnectingPipeSize.length - 1
                          ? '20px'
                          : '',
                    }">
                      <span>燃气管径</span>
                      <!-- <div class="input">
                      <SelectOptions
                        :optionsType="'空调形式'"
                        @handleSearchList="handleSearchList"
                        ref="kongtiaoxingshi"
                        :searchList="xingshiOptions"
                      />
                    </div> -->
                    </div>
                    <div class="itembox" style="display: flex; margin-left: -16px">
                      <div class="input">
                        <SelectOptions :disabled="WSADMajorDisabled" :optionsType="'GasConnectingPipeSize'" @clear="
                          selectClearChange(
                            $event,
                            'GasConnectingPipeSize',
                            index,
                            'GasConnectingPipeSize'
                          )
                          " @handleSearchList="
                            handleSearchList(
                              GasConnectingPipeSize,
                              $event,
                              index
                            )
                            " ref="kongtiaoxingshi" :value="GasConnectingPipeSize[index]" :searchList="newSelectOptions.ZS_GasConnectingPipeSize
                              " style="width: 80%; margin-left: 5%" />
                      </div>
                    </div>
                  </div>
                </div>

                <!--                第二行-->
                <!-- <div style="display: flex">
                  <div class="itembox" style="display: flex">
                    <span class="span first" style="color: #fff">佈局展位</span>
                    
                  </div>
                  <div class="itembox" style="display: flex; padding-left: 5px">
                    <div class="input">
                      <SelectOptions
                        :optionsType="'空调形式'"
                        @handleSearchList="handleSearchList"
                        ref="kongtiaoxingshi"
                        :searchList="xingshiOptions"
                      />
                    </div>
                  </div>
                </div> -->
                <!-- <div class="input">
                      <SelectOptions
                        :optionsType="'空调形式'"
                        @handleSearchList="handleSearchList"
                        ref="kongtiaoxingshi"
                        :searchList="xingshiOptions"
                      />
                    </div> -->
              </div>
            </div>
          </div>
          <!--          给排水备注-->
          <div class="" style="margin-top: 5px">
            <v-input :inputStyle="{ flex: 9 }" title="备注" v-model="Form.RemarkWSAD" :disabled="WSADMajorDisabled"
              placeholder="请输入备注信息" :maxlength="200" :showWordLimit="true"
              style="width: 60%; margin-left: 50px; margin-top: 20px" />
          </div>
        </el-collapse-item>
      </el-collapse>

      <!-- 弱电 -->
      <el-collapse v-model="activeNames4" @change="handleCollapseChange" style="width: 100%">
        <el-collapse-item :title="title4" name="弱电信息">
          <template slot="title">
            <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;" @click.stop="">

              <div>
                {{ title4 }}
              </div>
              <div style="pointer-events: auto;" @click="handleCollapseClick('title4', 'activeNames4', '弱电信息');">
                <span style="margin-right: 5px;font-size: 12px;color:#6e7073;" v-if="title4">
                  展开
                </span>
                <span style="margin-right: 5px;font-size: 12px;color:#6e7073;" v-if="!title4">
                  收起
                </span>
              </div>
            </div>
          </template>
          <div class="dianqi" style="margin-bottom: 10px">
            <!--            智能化顶部标题区域-->
            <div style="
                display: flex;
                justify-content: space-between;
                margin-left: 20px;
                padding-bottom: 8px;
                border-bottom: 1px solid #ccc;
                margin-bottom: 20px;
              ">
              <div style="display: flex">
                <img style="width: auto; height: 22px; margin-right: 5px" src="../../../../assets/zhinenghua.png"
                  alt="" />
                <span style="font-weight: bolder">弱电信息</span>
              </div>
              <div style="padding-right: 20px">
                <el-button v-if="IntelligentMajorDisabled == false" @click="editCancel('IntelligentMajorDisabled')"
                  style="padding-right: 10px; outline: none" type="primary" plain size="mini">取 消</el-button>
                <el-button v-else @click="IntelligentMajorDisabled = false" style="padding-right: 10px; outline: none"
                  type="primary" plain size="mini">编辑</el-button>
                <el-button v-if="IntelligentMajorDisabled == false" @click="handlerSubmitSave('IntelligentMajor')"
                  style="padding-right: 10px; outline: none" type="primary" plain size="mini">保存</el-button>
              </div>
            </div>

            <!--            第二行-->
            <div class="itembox" :style="{
              marginTop: '5px',
              marginBottom:
                index == WeakCurrentBoxInfo.length - 1 ? '20px' : '0px',
            }" v-for="(i, index) in WeakCurrentBoxInfo" :key="i.id">
              <div style="display: flex; margin-bottom: 5px; position: relative">
                <el-button type="primary" @click="setWeakCurrentBoxInfo(i, 'reduce')" :style="{
                  visibility: index != 0 ? '' : 'hidden',
                  padding: '5px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '3px',
                }" size="medium" icon="el-icon-remove-outline"></el-button>

                <el-button type="primary" v-if="index == WeakCurrentBoxInfo.length - 1" @click="
                  setWeakCurrentBoxInfo({ id: new Date().getTime() }, 'add')
                  " :style="{
                    padding: '5px',
                    height: '20px',
                    display: 'flex',
                    position: 'absolute',
                    left: '-10px',
                    top: '45px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '3px',
                  }" size="medium" icon="el-icon-circle-plus-outline"></el-button>

                <div style="display: flex">
                  <!--                  <span class="span first">商铺弱电箱编号</span>-->
                  <div class="input" style="display: flex">
                    <div style="">
                      <!-- <span :style="{ visibility: index == 0 ? '' : 'hidden' }" >商铺弱电箱编号</span> -->
                      <span>商铺弱电箱编号</span>
                    </div>
                    <v-input :RegExpFlag="'/[^\\w_-]/g'" v-model="WeakCurrentBoxInfo[index].WCB_Number"
                      :disabled="IntelligentMajorDisabled" placeholder="请输入" style="width: 120px" />
                    <!-- <el-button type="primary" @click="setWeakCurrentBoxInfo(i + 1)" :style="{ visibility: index == 0 ? '' : 'hidden', padding: '5px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '3px' }"  size="mini" icon="el-icon-circle-plus-outline"></el-button> -->
                  </div>
                </div>
                <div style="display: flex; padding-left: 5px">
                  <!--                  <span class="span second">弱电接线盒</span>-->
                  <div class="input" style="display: flex">
                    <!-- <span :style="{ visibility: index == 0 ? '' : 'hidden' }" >弱电接线盒</span> -->
                    <span>弱电接线盒</span>
                    <v-input :RegExpFlag="'/[^\\w_-]/g'" v-model="WeakCurrentBoxInfo[index].WC_JunctionBox"
                      style="width: 270px" :disabled="IntelligentMajorDisabled" placeholder="请输入" />
                  </div>
                </div>
                <div style="display: flex; padding-left: 10px">
                  <span class="span three">网络点位及线缆</span>
                  <!-- <span class="span three" :style="{ visibility: index == 0 ? '' : 'hidden' }">网络点位及线缆</span> -->
                  <div class="input">
                    <SelectOptions :optionsType="'NetworkPointsAndCables'" @clear="
                      selectClearChange(
                        $event,
                        'NetworkPointsAndCables',
                        index,
                        'WeakCurrentBoxInfo'
                      )
                      " @handleSearchList="
                        handleSearchList(
                          WeakCurrentBoxInfo[index],
                          $event,
                          'NetworkPointsAndCables'
                        )
                        " :value="WeakCurrentBoxInfo[index].NetworkPointsAndCables" ref="kongtiaoxingshi"
                      :disabled="IntelligentMajorDisabled" :searchList="newSelectOptions.ZS_NetworkPointsAndCables"
                      style="width: 200px" />
                  </div>
                </div>
                <div style="padding-left: 10px; display: flex">
                  <span class="span four zhineng">有线电视点位及线缆</span>
                  <!-- <span class="span four zhineng" :style="{ visibility: index == 0 ? '' : 'hidden' }">有线电视点位及线缆</span> -->
                  <div class="input">
                    <SelectOptions :optionsType="'CableTVPointsAndCables'" @clear="
                      selectClearChange(
                        $event,
                        'CableTVPointsAndCables',
                        index,
                        'WeakCurrentBoxInfo'
                      )
                      " @handleSearchList="
                        handleSearchList(
                          WeakCurrentBoxInfo[index],
                          $event,
                          'CableTVPointsAndCables'
                        )
                        " :value="WeakCurrentBoxInfo[index].CableTVPointsAndCables" ref="kongtiaoxingshi"
                      :disabled="IntelligentMajorDisabled" :searchList="newSelectOptions.ZS_CableTVPointsAndCables"
                      style="width: 100px" />
                  </div>
                </div>
                <div style="padding-left: 10px; display: flex">
                  <span class="span four">POS点位及线缆</span>
                  <!-- <span class="span four" :style="{ visibility: index == 0 ? '' : 'hidden' }">POS点位及线缆</span> -->
                  <div class="input">
                    <SelectOptions :optionsType="'POSPointsAndCables'" :disabled="IntelligentMajorDisabled" @clear="
                      selectClearChange(
                        $event,
                        'POSPointsAndCables',
                        index,
                        'WeakCurrentBoxInfo'
                      )
                      " @handleSearchList="
                        handleSearchList(
                          WeakCurrentBoxInfo[index],
                          $event,
                          'POSPointsAndCables'
                        )
                        " :value="WeakCurrentBoxInfo[index].POSPointsAndCables" ref="kongtiaoxingshi"
                      :searchList="newSelectOptions.ZS_POSPointsAndCables" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <div class="" style="margin-top: 5px">
              <v-input :inputStyle="{ flex: 9 }" title="备注" :disabled="IntelligentMajorDisabled"
                v-model="Form.RemarkIntelligent" placeholder="请输入备注信息" :maxlength="200" :showWordLimit="true"
                style="width: 60%; margin-left: 75px" />
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>

      <!-- 结构 -->
      <el-collapse v-model="activeNames5" @change="handleCollapseChange" style="width: 100%">
        <el-collapse-item :title="title5" name="结构信息">
          <template slot="title">
            <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;" @click.stop="">

              <div>
                {{ title5 }}
              </div>
              <div style="pointer-events: auto;" @click="handleCollapseClick('title5', 'activeNames5', '结构信息');">
                <span style="margin-right: 5px;font-size: 12px;color:#6e7073;" v-if="title5">
                  展开
                </span>
                <span style="margin-right: 5px;font-size: 12px;color:#6e7073;" v-if="!title5">
                  收起
                </span>
              </div>
            </div>
          </template>
          <div class="dianqi" style="width: 100%; margin-top: 20px">
            <div style="
                display: flex;
                justify-content: space-between;
                margin-left: 20px;
                padding-bottom: 8px;
                border-bottom: 1px solid #ccc;
                margin-bottom: 20px;
              ">
              <div style="display: flex">
                <img style="width: auto; height: 22px; margin-right: 5px" src="../../../../assets/jiegou.png" alt="" />
                <span style="font-weight: bolder">结构信息</span>
              </div>
              <div style="padding-right: 20px">
                <el-button v-if="StructureMajorDisabled == false" @click="editCancel('StructureMajorDisabled')"
                  style="padding-right: 10px; outline: none" type="primary" plain size="mini">取 消</el-button>
                <el-button v-else @click="StructureMajorDisabled = false" style="padding-right: 10px; outline: none"
                  type="primary" plain size="mini">编辑</el-button>
                <el-button v-if="StructureMajorDisabled == false" @click="handlerSubmitSave('StructureMajor')"
                  style="padding-right: 10px; outline: none" type="primary" plain size="mini">保存</el-button>
              </div>
            </div>
            <!--            第一行-->
            <div class="itembox" style="margin-top: 5px">
              <div style="display: flex; margin-bottom: 5px">
                <el-button type="primary" :style="{
                  padding: '5px',
                  visibility: 'hidden',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '3px',
                }" size="mini" icon="el-icon-circle-plus-outline"></el-button>

                <div style="display: flex">
                  <!--                  <span class="span first">楼板板面活荷载</span>-->
                  <div class="input">
                    <div style="display: flex">
                      <v-input :RegExpFlag="'/[^\\d.]/g'" :suffixText="'kN/㎡'" :disabled="StructureMajorDisabled"
                        v-model="Form.LiveLoadOnTheBottomPlateSurface" title="楼板板面活荷载" placeholder="请输入"
                        style="width: 280px" />
                    </div>
                  </div>
                </div>
                <div style="display: flex; padding-left: 5px">
                  <!--                  <span class="span second">底板板面恒荷载（含吊顶和管线）</span>-->
                  <div class="input">
                    <div style="display: flex">
                      <v-input :RegExpFlag="'/[^\\d.]/g'" :suffixText="'kN/㎡'" :disabled="StructureMajorDisabled"
                        v-model="Form.ConstantLoadOnTheBottomPlateSurface" title="底板板面恒荷载" placeholder="请输入"
                        style="width: 280px; margin-left: 10px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="itembox" style="margin-top: 5px; display: flex">
              <el-button type="primary" :style="{
                padding: '5px',
                visibility: 'hidden',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '3px',
              }" size="mini" icon="el-icon-circle-plus-outline"></el-button>

              <div style="display: flex">
                <!--                  <span class="span three">厨房板面活荷载</span>-->
                <div class="input">
                  <div style="display: flex">
                    <v-input :RegExpFlag="'/[^\\d.]/g'" :suffixText="'kN/㎡'" :disabled="StructureMajorDisabled"
                      v-model="Form.KitchenPanelLiveLoad" title="厨房板面活荷载" placeholder="请输入" style="width: 280px" />
                  </div>
                </div>
              </div>
              <div style="padding-left: 5px; display: flex">
                <!--                  <span class="span four zhineng">厨房板面恒荷载</span>-->
                <div class="input">
                  <div style="display: flex">
                    <v-input :RegExpFlag="'/[^\\d.]/g'" :suffixText="'kN/㎡'" :disabled="StructureMajorDisabled"
                      v-model="Form.ConstantLoadOnKitchenPanelSurface" title="厨房板面恒荷载" placeholder="请输入"
                      style="width: 280px; margin-left: 10px" />
                  </div>
                </div>
              </div>
              <div style="padding-left: 5px; display: flex">
                <!--                  <span class="span four zhineng">厨房板面恒荷载</span>-->
                <div class="input">
                  <div style="display: flex">
                    <v-input :RegExpFlag="'/[^\\d.]/g'" :suffixText="'mm'" :disabled="StructureMajorDisabled"
                      v-model="Form.LoweringPlateHeight" title="降板高度" placeholder="请输入"
                      style="width: 280px; margin-left: 10px" />
                  </div>
                </div>
              </div>
            </div>
            <div class="" style="margin-top: 5px">
              <v-input :inputStyle="{ flex: 9 }" title="备注" v-model="Form.RemarkStructure"
                :disabled="StructureMajorDisabled" placeholder="请输入备注信息" :maxlength="200" :showWordLimit="true"
                style="width: 60%; margin-left: 75px" />
            </div>
            <!--            <div class="itemboxRemark">-->
            <!--              <el-form-item label="备注">-->
            <!--                <el-input-->
            <!--                  type="textarea"-->
            <!--                  v-model="Form.RemarkStructure"-->
            <!--                  autocomplete="off"-->
            <!--                  maxlength="200"-->
            <!--                  show-word-limit-->
            <!--                  :disabled="StructureMajorDisabled"-->
            <!--                >-->
            <!--                </el-input>-->
            <!--              </el-form-item>-->
            <!--            </div>-->
            <!--            <el-row :gutter="24" style="">-->
            <!--              <el-col :span="8">-->
            <!--                <div class="grid-content bg-purple-light">-->
            <!--                  <el-form-item label="降板高度">-->
            <!--                    <el-input-->
            <!--                      v-model="Form.LoweringPlateHeight"-->
            <!--                      autocomplete="off"-->
            <!--                      :disabled="StructureMajorDisabled"-->
            <!--                    >-->
            <!--                    </el-input>-->
            <!--                  </el-form-item>-->
            <!--                </div>-->
            <!--              </el-col>-->
            <!--              <el-col :span="16">-->
            <!--                <div class="grid-content bg-purple-light">-->
            <!--                  <el-form-item label="备注">-->
            <!--                    <el-input-->
            <!--                      type="textarea"-->
            <!--                      v-model="Form.RemarkStructure"-->
            <!--                      autocomplete="off"-->
            <!--                      :disabled="StructureMajorDisabled"-->
            <!--                    >-->
            <!--                    </el-input>-->
            <!--                  </el-form-item>-->
            <!--                </div>-->
            <!--              </el-col>-->
            <!--            </el-row>-->
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
// 引入工具素具
import {
  IsOnGroundOptions,
  dianqiLuOptions,
  dianqiLiuOptions,
  dianqiGuiOptions,
} from "../mock.js";
// 下拉搜索列表组件
import SelectOptions from "@/components/SelectOptions.vue";

export default {
  name: "WorkspaceJsonShopBaseInfo",
  components: {
    SelectOptions,
    // ComponentInput,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      msg: "",
      xingshiOptions: [
        {
          label: "1路",
          value: 1,
        },
        {
          label: "2路",
          value: 2,
        },
        {
          label: "3路",
          value: 3,
        },
      ],
      // 默认折叠展开面板
      activeNames1: ["电气信息"],
      activeNames2: ["暖通信息"],
      activeNames3: ["给排水信息"],
      activeNames4: ["弱电信息"],
      activeNames5: ["结构信息"],
      // 各个折叠区域的标题
      title1: "", //电气
      title2: "", //暖通
      title3: "", //给排水
      title4: "", //智能化
      title5: "", //结构
      // 传递过来的当前行的基础商铺信息
      rows: {},
      // 首页传递过来的项目，任务，应用id对象
      newTaskObj: {},
      // 电器禁用
      ElectricalMajorDisabled: true,
      // 暖通禁用
      HVACMajorDisabled: true,
      // 给排水禁用
      WSADMajorDisabled: true,
      // 智能化禁用
      IntelligentMajorDisabled: true,
      // 结构禁用
      StructureMajorDisabled: true,
      // 标题类型
      title: "",
      loading: false,
      show: false,
      // 表单
      Form: {},
      //暖通空调条件
      AirConditioningCondition: { AirConditioningForm: "", VRFConditionReservation: false, TotalCoolingCapacity: "", TotalHeatingCapacity: "", AirChangeFlow: "" },
      //暖通通风条件
      VentilationCondition: {
        KitchenOilFumeDischarge: "",
        KitchenNormalAccidentExhaustVolume: "",
        KitchenAirReplenishmentVolume: "",
        IndividualProtectiveExhaustAirVolume: "",

      },
      // 校验规则
      Rules: {},
      // 地上地下下拉数据
      IsOnGroundOptions,
      // 电气 1-4行的第一个下拉数据
      dianqiLuOptions,
      // 电气所有行的中间两列下拉数据
      dianqiLiuOptions,
      // 电气所有行的进线规格下拉数据
      dianqiGuiOptions,
      // 业态级别下拉数据
      // 楼栋信息
      buildingOptions: [],
      // 业态级别
      ShopFormatClassOptions: [],
      // 业态名称下拉选择
      ShopFormatNameOptions: [],
      // 原商铺id下拉列表数据
      ShopLastIdsOptions: [],
      // 电数据
      WeakCurrentBoxInfo: [{ id: new Date().getTime() + "1" }],
      //电气数据
      ElectricalBox: [{ id: new Date().getTime() + "2" }],
      //给水条件
      WaterSupplyPipeSize: [""],
      //餐饮排水条件
      CateringDrainagePipeSize: [""],
      //非餐饮排水条件
      NonCateringDrainagePipeSize: [""],
      //燃气条件
      GasConnectingPipeSize: [""],
      //暖通信息冷水
      AC_ColdWaterPipeDiameter: [""],
      //暖通信息热水
      AC_HotWaterPipeDiameter: [""],
      //暖通信息送风
      FreshAirPipelineSize: [{ id: new Date().getTime() + "9" }],
      //厨房排油烟量
      KitchenExhaustPipeSize: [{ id: new Date().getTime() + "10" }],
      //厨房平时排风
      KitchenNormalAccidentExhaustPipeSize: [
        { id: new Date().getTime() + "11" },
      ],
      //厨房补风量
      KitchenAirReplenishmentPipeSize: [{ id: new Date().getTime() + "12" }],
      //个护排风量
      IndividualProtectiveExhaustAirPipeSize: [
        { id: new Date().getTime() + "13" },
      ],
      //查询下拉框
      SelectOptionsInfo: [
        "ZS_EM_RatedCurrent",
        "ZS_CableInletSpecifications",
        "ZS_AirConditioningForm",
        "ZS_WaterPipeDiameter",
        "ZS_DrainagePipeDiameter",
        "ZS_GasConnectingPipeSize",
        "ZS_NetworkPointsAndCables",
        "ZS_CableTVPointsAndCables",
        "ZS_POSPointsAndCables",
      ],
      //下拉框选项
      newSelectOptions: {},
      ShopInfo: {}
    };
  },

  mounted() {
    this.$http
      .post(
        "/Base_ProjectManage/Project_DictionaryMain/GetDataDetailByDicKeyList",
        this.SelectOptionsInfo
      )
      .then((res) => {
        let obj = {};
        for (let key in res.Data) {
          obj[key] = JSON.parse(res.Data[key]);
          obj[key].forEach((item) => {
            item.label = item.DicName;
            item.value = item.DicValue;
          });
        }
        this.newSelectOptions = obj;
        this.$set(this.newSelectOptions, obj);
      })
      .catch((err) => {
        this.$message.error(err);
      });
  },
  computed: {
    EnergyUseIndex() {
      if (!this.rows.ShopArea) {
        return 0;
      } else {
        return this.Form.PowerConsumption
          ? (
            ((this.Form.PowerConsumption * 1000) / this.rows.ShopArea) *
            1
          ).toFixed(2)
          : "";
      }
    },

    HeatingLoadIndex() {
      if (!this.rows.ShopArea) {
        return 0;
      } else {
        return this.AirConditioningCondition.TotalHeatingCapacity
          ? (
            ((this.AirConditioningCondition.TotalHeatingCapacity * 1000) /
              this.rows.ShopArea) *
            1
          ).toFixed(2)
          : "";
      }
    },
    RefrigerationLoadIndex() {
      if (!this.rows.ShopArea) {
        return 0;
      } else {
        return this.AirConditioningCondition.TotalCoolingCapacity
          ? (
            ((this.AirConditioningCondition.TotalCoolingCapacity * 1000) /
              this.rows.ShopArea) *
            1
          ).toFixed(2)
          : "";
      }
    },
  },

  methods: {
    handleCollapseClick(title, names, name) {
      if (this[title]) {
        this[names] = [name]
      } else {
        this[names] = []
      }
      this.handleCollapseChange()
    },
    init() {
      // 重置
      this.Form = {};
      // 电气数组
      this.ElectricalBox = [{ id: new Date().getTime() + "2" }];

      this.AirConditioningCondition = { AirConditioningForm: "", VRFConditionReservation: false, TotalCoolingCapacity: "", TotalHeatingCapacity: "", AirChangeFlow: "" };
      this.AC_ColdWaterPipeDiameter = [""];
      this.AC_HotWaterPipeDiameter = [""];
      this.FreshAirPipelineSize = [{ id: new Date().getTime() + "9" }];
      this.VentilationCondition = {};
      this.KitchenExhaustPipeSize = [{ id: new Date().getTime() + "10" }];
      this.KitchenNormalAccidentExhaustPipeSize = [
        { id: new Date().getTime() + "11" },
      ];
      this.KitchenAirReplenishmentPipeSize = [
        { id: new Date().getTime() + "12" },
      ];
      this.IndividualProtectiveExhaustAirPipeSize = [
        { id: new Date().getTime() + "13" },
      ];
      this.WeakCurrentBoxInfo = [{ id: new Date().getTime() + "1" }];
      this.WaterSupplyPipeSize = [""];
      this.CateringDrainagePipeSize = [""];
      this.NonCateringDrainagePipeSize = [""];
      this.GasConnectingPipeSize = [""];
    },
    // 下拉框清空按钮子组件事件
    selectClearChange(data, type, index, modueType) {
      if (index == -1) return this[modueType][type] = "" //给空调形式用
      if (
        type === "AirConditioningCondition" ||
        modueType === "WaterSupplyPipeSize" ||
        modueType === "CateringDrainagePipeSize" ||
        modueType === "NonCateringDrainagePipeSize" ||
        modueType === "GasConnectingPipeSize"
      ) {
        this[modueType].splice(index, 1);
        if (this[modueType].length == 0) this[modueType][index] = "";

        return;
      }

      this[modueType][index][type] = "";
    },
    handleSearchList(item, options, optionsType) {
      item[optionsType] = options.value;
      // let obj = {
      //   Form: this.Form,
      //   WeakCurrentBoxInfo: this.WeakCurrentBoxInfo,
      //   ElectricalBox: this.ElectricalBox,
      //   WaterSupplyPipeSize: this.WaterSupplyPipeSize,
      //   CateringDrainagePipeSize: this.CateringDrainagePipeSize,
      //   NonCateringDrainagePipeSize: this.NonCateringDrainagePipeSize,
      //   GasConnectingPipeSize: this.GasConnectingPipeSize,
      //   AC_ColdWaterPipeDiameter: this.AC_ColdWaterPipeDiameter,
      //   AC_HotWaterPipeDiameter: this.AC_HotWaterPipeDiameter,
      //   FreshAirPipelineSize: this.FreshAirPipelineSize,
      //   KitchenExhaustPipeSize: this.KitchenExhaustPipeSize,
      //   KitchenNormalAccidentExhaustPipeSize:
      //     this.KitchenNormalAccidentExhaustPipeSize,
      //   KitchenAirReplenishmentPipeSize: this.KitchenAirReplenishmentPipeSize,
      //   IndividualProtectiveExhaustAirPipeSize:
      //     this.IndividualProtectiveExhaustAirPipeSize,
      // };
    },
    // 暖通VRF预留选择
    switchChange(val) {
      console.log("选择", val);
    },
    // 折叠面板事件
    handleCollapseChange() {
      // 判断点折叠区域  赋值对应区域title
      if (this.activeNames1.length == 0) {
        this.title1 = "电气信息";
      } else {
        this.title1 = "";
      }
      if (this.activeNames2.length == 0) {
        this.title2 = "暖通信息";
      } else {
        this.title2 = "";
      }
      if (this.activeNames3.length == 0) {
        this.title3 = "给排水信息";
      } else {
        this.title3 = "";
      }
      if (this.activeNames4.length == 0) {
        this.title4 = "弱电信息";
      } else {
        this.title4 = "";
      }
      if (this.activeNames5.length == 0) {
        this.title5 = "结构信息";
      } else {
        this.title5 = "";
      }
    },
    // 滚动到顶部
    handleScrollTop() {
      this.$nextTick(() => {
        // let scrollElem = this.$refs.scrollDiv;
        // scrollElem.scrollTo({ top: 0, behavior: 'smooth' });
      });
    },
    // 用户电缆限制输入框输入规则
    btKeyUp(type) {
      this.Form[type] = this.Form[type].replace(/[\u4e00-\u9fa5]/gi, "");
    },
    // 取消按钮
    async editCancel(type) {
      if (type === "ElectricalMajorDisabled") {
        // 电气
        this.ElectricalMajorDisabled = true;
      } else if (type === "HVACMajorDisabled") {
        // 暖通
        this.HVACMajorDisabled = true;
      } else if (type === "WSADMajorDisabled") {
        // 给排水
        this.WSADMajorDisabled = true;
      } else if (type === "IntelligentMajorDisabled") {
        // 智能化
        this.IntelligentMajorDisabled = true;
      } else {
        // 结构
        this.StructureMajorDisabled = true;
      }

      this.shopBaseInfoDisabled = true;
      let Id = this.parentObj.childrenRow.Id;
      // // 清空所有form表单数据重新获取渲染
      // this.Form = {};

      // /消除校验
      // setTimeout(function () {
      //   _this.$refs["Form"].clearValidate(); //消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
      // }, 30);
      // 重新获取编辑详情渲染
      await this.getShopInfoTheListData(Id, type);
      // setTimeout(function () {//消除某个表单的校验,这里的setTimeOut不能去掉,去掉之后会不生效在clearValidate()里以数组形式 填写表单字段的 prop属性值,
      // _this.$refs['Form'].clearValidate(['sn']);
      //     },30);
      // 重新刷新详情  避免取消条件保存输入的数据
    },
    // 获取编辑详情数据
    async getShopInfoTheListData(Id, type) {
      this.loading = true
      await this.$http
        .post("/Shop_Manage/Shop_BaseInfo/GetTheData", {
          Id: Id,
        })
        .then((res) => {
          this.loading = false;

          if (res.Success) {


            this.ShopInfo = res.Data;
            // 赋值表单数据
            if (type === "ElectricalMajorDisabled") {
              // 电气
              this.$set(this.Form, 'PowerConsumption', res.Data.PowerConsumption || '')
              this.$set(this.Form, 'RemarkElectrical', res.Data.RemarkElectrical || '')
              this.ElectricalBox = (res.Data.ElectricalBox && res.Data.ElectricalBox.length > 0) ? res.Data.ElectricalBox : [{ id: new Date().getTime() + "2" }]

            } else if (type === "HVACMajorDisabled") {
              // 暖通
              this.AirConditioningCondition =
                res.Data.AirConditioningCondition || { AirConditioningForm: "", VRFConditionReservation: false, TotalCoolingCapacity: "", TotalHeatingCapacity: "", AirChangeFlow: "" };


              this.AC_ColdWaterPipeDiameter =
                (res.Data.AirConditioningCondition.AC_ColdWaterPipeDiameter && res.Data.AirConditioningCondition.AC_ColdWaterPipeDiameter.length > 0)
                  ? res.Data.AirConditioningCondition.AC_ColdWaterPipeDiameter
                  : [""];
              this.AC_HotWaterPipeDiameter =
                (res.Data.AirConditioningCondition.AC_HotWaterPipeDiameter && res.Data.AirConditioningCondition.AC_HotWaterPipeDiameter.length > 0)
                  ? res.Data.AirConditioningCondition.AC_HotWaterPipeDiameter
                  : [""];

              this.FreshAirPipelineSize =
                (res.Data.AirConditioningCondition.FreshAirPipelineSize && res.Data.AirConditioningCondition.FreshAirPipelineSize.length > 0)
                  ? res.Data.AirConditioningCondition.FreshAirPipelineSize
                  : [{ id: new Date().getTime() + "9" }];



              this.VentilationCondition =
                res.Data.VentilationCondition || {
                  KitchenOilFumeDischarge: "",
                  KitchenNormalAccidentExhaustVolume: "",
                  KitchenAirReplenishmentVolume: "",
                  IndividualProtectiveExhaustAirVolume: '',
                }



              this.KitchenExhaustPipeSize =
                (res.Data.VentilationCondition.KitchenExhaustPipeSize && res.Data.VentilationCondition.KitchenExhaustPipeSize.length > 0)
                  ? res.Data.VentilationCondition.KitchenExhaustPipeSize
                  : [{ id: new Date().getTime() + "10" }];

              this.KitchenNormalAccidentExhaustPipeSize =
                (res.Data.VentilationCondition.KitchenNormalAccidentExhaustPipeSize && res.Data.VentilationCondition.KitchenNormalAccidentExhaustPipeSize.length > 0)
                  ? res.Data.VentilationCondition.KitchenNormalAccidentExhaustPipeSize
                  : [{ id: new Date().getTime() + "11" }];

              this.KitchenAirReplenishmentPipeSize =
                (res.Data.VentilationCondition.KitchenAirReplenishmentPipeSize && res.Data.VentilationCondition.KitchenAirReplenishmentPipeSize.length > 0)
                  ? res.Data.VentilationCondition.KitchenAirReplenishmentPipeSize
                  : [{ id: new Date().getTime() + "12" }];

              this.IndividualProtectiveExhaustAirPipeSize =
                (res.Data.VentilationCondition.IndividualProtectiveExhaustAirPipeSize && res.Data.VentilationCondition.IndividualProtectiveExhaustAirPipeSize.length > 0)
                  ? res.Data.VentilationCondition.IndividualProtectiveExhaustAirPipeSize
                  : [{ id: new Date().getTime() + "13" }];

              this.$set(this.Form, 'HVACRemark', res.Data.HVACRemark || '')
            } else if (type === "WSADMajorDisabled") {
              // 给排水


              // 给水条件
              this.WaterSupplyPipeSize =
                (res.Data.WaterSupplyPipeSize && res.Data.WaterSupplyPipeSize.length > 0)
                  ? res.Data.WaterSupplyPipeSize
                  : [""];
              //餐饮排水
              this.CateringDrainagePipeSize =
                (res.Data.CateringDrainagePipeSize && res.Data.CateringDrainagePipeSize.length > 0)
                  ? res.Data.CateringDrainagePipeSize
                  : [""];
              //非餐饮排水
              this.NonCateringDrainagePipeSize =
                (res.Data.NonCateringDrainagePipeSize && res.Data.NonCateringDrainagePipeSize.length > 0)
                  ? res.Data.NonCateringDrainagePipeSize
                  : [""];

              this.$set(this.Form, 'NaturalGasVolume', res.Data.NaturalGasVolume || '')
              this.$set(this.Form, 'NaturalGasPressure', res.Data.NaturalGasPressure || '')
              this.$set(this.Form, 'RemarkWSAD', res.Data.RemarkWSAD || '')
              this.GasConnectingPipeSize =
                (res.Data.GasConnectingPipeSize && res.Data.GasConnectingPipeSize.length > 0)
                  ? res.Data.GasConnectingPipeSize
                  : [""];

            } else if (type === "IntelligentMajorDisabled") {
              // 智能化
              this.WeakCurrentBoxInfo =
                (res.Data.WeakCurrentBoxInfo && res.Data.WeakCurrentBoxInfo.length > 0)
                  ? res.Data.WeakCurrentBoxInfo
                  : [{ id: new Date().getTime() + "1" }];
              this.$set(this.Form, 'RemarkIntelligent', res.Data.RemarkIntelligent || '')

            } else {
              // 结构
              this.$set(this.Form, 'LiveLoadOnTheBottomPlateSurface', res.Data.LiveLoadOnTheBottomPlateSurface || '')
              this.$set(this.Form, 'ConstantLoadOnTheBottomPlateSurface', res.Data.ConstantLoadOnTheBottomPlateSurface || '')
              this.$set(this.Form, 'KitchenPanelLiveLoad', res.Data.KitchenPanelLiveLoad || '')
              this.$set(this.Form, 'ConstantLoadOnKitchenPanelSurface', res.Data.ConstantLoadOnKitchenPanelSurface || '')
              this.$set(this.Form, 'LoweringPlateHeight', res.Data.LoweringPlateHeight || '')
              this.$set(this.Form, 'RemarkStructure', res.Data.RemarkStructure || '')


            }

          } else {
            this.loading = false;
          }
        });
    },
    // 业态级别下拉选择
    ShopFormatClassChange(e) {
      // 判断是否有选择业态级别  如果有选择了。单独清空校验规则  否则红字一直在
      // this.$refs.basisRuleForm.validateField("ShopFormatClass", (valid) => {
      //   //valid返回的是规则集中写的错误提示信息，如果满足条件，返回的就是空
      //   if (valid) {
      //     return this.$refs["basisRuleForm"].clearValidate("ShopFormatClass");
      //   }
      // });

      if (e) {
        console.log("有值", e, this.ShopFormatClassOptions);
        // 如果有选中业态级别  同时获取业态名称
        // this.ShopFormatNameDisabled = false;
      }
      // 业态名称下拉数据初始化
      // if (this.ruleForm1.ShopFormatName) {
      //   this.ruleForm1.ShopFormatName = "";
      // }
      let optionsData = this.ShopFormatClassOptions;
      if (this.ShopFormatClassOptions.length !== 0) {
        var newShopFormClassData = optionsData.filter(function (
          item,

        ) {
          return item.label === e;
        });
      }
      // 赋值业态级别下拉数据参数  以获取对应级别下的下拉数据
      this.parentObj.ShopFormatClassQueryParams.Keyword =
        newShopFormClassData.length == 0 ? "" : newShopFormClassData[0].value;
      // // 获取对应业态级别下的业态名称下拉数据
      // this.getShopNameListData();
      // // 业态名称后 初始化业态级别下拉数据的参数  避免下次还是原来选择的参数
      // this.ShopFormatClassQueryParams.Keyword="FormatClass"

      this.$emit("ShopFormatClassChange", e);
    },
    //给水条件
    setWaterSupplyPipeSize(e, type, i) {
      if (this.WSADMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.WaterSupplyPipeSize.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.WaterSupplyPipeSize.push(e);
      } else {
        this.WaterSupplyPipeSize.forEach((item, index) => {
          if (index == i) {
            this.WaterSupplyPipeSize.splice(index, 1);
          }
        });
      }
    },
    //餐饮排水条件
    setCateringDrainagePipeSize(e, type, i) {
      if (this.WSADMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.CateringDrainagePipeSize.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.CateringDrainagePipeSize.push(e);
      } else {
        this.CateringDrainagePipeSize.forEach((item, index) => {
          if (index == i) {
            this.CateringDrainagePipeSize.splice(index, 1);
          }
        });
      }
    },
    //非餐饮排水条件
    setNonCateringDrainagePipeSize(e, type, i) {
      if (this.WSADMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.NonCateringDrainagePipeSize.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.NonCateringDrainagePipeSize.push(e);
      } else {
        this.NonCateringDrainagePipeSize.forEach((item, index) => {
          if (index == i) {
            this.NonCateringDrainagePipeSize.splice(index, 1);
          }
        });
      }
    },
    //燃气条件
    setGasConnectingPipeSize(e, type, i) {
      if (this.WSADMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.GasConnectingPipeSize.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.GasConnectingPipeSize.push(e);
      } else {
        this.GasConnectingPipeSize.forEach((item, index) => {
          if (index == i) {
            this.GasConnectingPipeSize.splice(index, 1);
          }
        });
      }
    },
    //弱电信息添加
    setWeakCurrentBoxInfo(e, type) {
      if (this.IntelligentMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.WeakCurrentBoxInfo.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.WeakCurrentBoxInfo.push(e);
      } else {
        this.WeakCurrentBoxInfo.forEach((item, index) => {
          if (item.id == e.id) {
            this.WeakCurrentBoxInfo.splice(index, 1);
          }
        });
      }
    },
    //暖通冷风
    setAC_ColdWaterPipeDiameter(e, type, i) {
      if (this.HVACMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.AC_ColdWaterPipeDiameter.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.AC_ColdWaterPipeDiameter.push(e);
      } else {
        this.AC_ColdWaterPipeDiameter.forEach((item, index) => {
          if (index == i) {
            this.AC_ColdWaterPipeDiameter.splice(index, 1);
          }
        });
      }
    },
    //暖通冷风
    setAC_HotWaterPipeDiameter(e, type, i) {
      if (this.HVACMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.AC_HotWaterPipeDiameter.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.AC_HotWaterPipeDiameter.push(e);
      } else {
        this.AC_HotWaterPipeDiameter.forEach((item, index) => {
          if (index == i) {
            this.AC_HotWaterPipeDiameter.splice(index, 1);
          }
        });
      }
    },
    //送风管道
    setFreshAirPipelineSize(e, type) {
      if (this.HVACMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.FreshAirPipelineSize.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.FreshAirPipelineSize.push(e);
      } else {
        this.FreshAirPipelineSize.forEach((item, index) => {
          if (item.id == e.id) {
            this.FreshAirPipelineSize.splice(index, 1);
          }
        });
      }
    },

    //厨房排油烟
    setKitchenfumes(e, type) {
      if (this.HVACMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.KitchenExhaustPipeSize.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.KitchenExhaustPipeSize.push(e);
      } else {
        this.KitchenExhaustPipeSize.forEach((item, index) => {
          if (item.id == e.id) {
            this.KitchenExhaustPipeSize.splice(index, 1);
          }
        });
      }
    },
    //餐饮排水条件
    setKitchenNormalAccidentExhaustPipeSize(e, type) {
      if (this.HVACMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.KitchenNormalAccidentExhaustPipeSize.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.KitchenNormalAccidentExhaustPipeSize.push(e);
      } else {
        this.KitchenNormalAccidentExhaustPipeSize.forEach((item, index) => {
          if (item.id == e.id) {
            this.KitchenNormalAccidentExhaustPipeSize.splice(index, 1);
          }
        });
      }
    },

    //厨房补风
    setKitchenAirReplenishmentPipeSize(e, type) {
      if (this.HVACMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.KitchenAirReplenishmentPipeSize.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.KitchenAirReplenishmentPipeSize.push(e);
      } else {
        this.KitchenAirReplenishmentPipeSize.forEach((item, index) => {
          if (item.id == e.id) {
            this.KitchenAirReplenishmentPipeSize.splice(index, 1);
          }
        });
      }
    },

    //个护排风
    setIndividualProtectiveExhaustAirPipeSize(e, type) {
      if (this.HVACMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.IndividualProtectiveExhaustAirPipeSize.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加弱电");
        this.IndividualProtectiveExhaustAirPipeSize.push(e);
      } else {
        this.IndividualProtectiveExhaustAirPipeSize.forEach((item, index) => {
          if (item.id == e.id) {
            this.IndividualProtectiveExhaustAirPipeSize.splice(index, 1);
          }
        });
      }
    },

    //电气信息添加
    AddelectricData(e, type) {
      if (this.ElectricalMajorDisabled) {
        this.$message.info("请先点击编辑!");
        return;
      }
      if (type == "add") {
        if (this.ElectricalBox.length >= 4) {
          this.$message.info("最多只能存在四条!");
          return;
        }
        console.log("添加电气");
        this.ElectricalBox.push(e);
      } else {
        this.ElectricalBox.forEach((item, index) => {
          if (item.id == e.id) {
            this.ElectricalBox.splice(index, 1);
          }
        });
      }
    },
    // 保存
    handlerSubmitSave(e) {
      console.log("保存了", this.Form);
      if (e === "ElectricalMajor") {
        //电气
        // 根据是否当前行填写任意一个值 处理对应数组逻辑 只传递有任意一个值的当前行
        let newElectricalBoxArr = [];
        this.ElectricalBox.forEach((item) => {
          item["length"] = 0;
          if (item.EB_Number) item.length += 1;
          if (item.IncomingCableSpecifications) item.length += 1;
          if (item.RatedCurrentOfCircuitBreaker) item.length += 1;
          if (item.RatedCurrentOfIncomingSwitch) item.length += 1;
        });
        newElectricalBoxArr = this.ElectricalBox.filter(
          (item) => item.length > 0
        );
        console.log(this.ShopInfo)
        //电气
        let obj = {
          CreateTime: this.ShopInfo.ElectricalMajor_CreateTime
            ? this.ShopInfo.ElectricalMajor_CreateTime
            : undefined,
          CreatorId: this.ShopInfo.ElectricalMajor_CreatorId
            ? this.ShopInfo.ElectricalMajor_CreatorId
            : undefined,
          Id: this.ShopInfo.ElectricalMajor_Id
            ? this.ShopInfo.ElectricalMajor_Id
            : undefined,
          ModuleType: "ElectricalMajor",

          ModuleJson: JSON.stringify({
            // 用电量
            PowerConsumption: this.Form.PowerConsumption ? this.Form.PowerConsumption : "",
            // IncomingSwitch: this.Form.IncomingSwitch,
            // IncomingCable: this.Form.IncomingCable,
            //电箱数组
            ElectricalBox: newElectricalBoxArr,
            // 用电指标
            EnergyUseIndex: this.EnergyUseIndex,
            // 备注
            RemarkElectrical: this.Form.RemarkElectrical ? this.Form.RemarkElectrical : "",
          }),
          ShopId: this.ShopInfo.Id || this.Form.Id,
          Status: 1,
        };



        // 只有除基础信息以外的模块调用模块接口请求函数
        this.getShop_ExtensionInfoRequest(obj, e);
      } else if (e === "HVACMajor") {
        //暖通
        // 左侧
        // 冷水管径
        let newAC_ColdWaterPipeDiameter = this.AC_ColdWaterPipeDiameter.filter(
          (item) => item !== ""
        );
        // 热水管径
        let newAC_HotWaterPipeDiameter = this.AC_HotWaterPipeDiameter.filter(
          (item) => item !== ""
        );
        // 新风管道尺寸
        this.FreshAirPipelineSize.forEach((item) => {
          item["length"] = 0;
          if (item.FAPS_A) item.length += 1;
          if (item.FAPS_B) item.length += 1;
        });
        // 新风管道尺寸
        let newFreshAirPipelineSize = this.FreshAirPipelineSize.filter(
          (item) => item.FAPS_A && item.FAPS_B
        );

        // 排油烟管道尺寸
        let newKitchenExhaustPipeSize = [];
        // 厨房平时事故排风管道尺寸
        let newKitchenNormalAccidentExhaustPipeSize = [];
        // 厨房补风管道尺寸
        let newKitchenAirReplenishmentPipeSize = [];
        // 个护排风管道尺寸
        let newIndividualProtectiveExhaustAirPipeSize = [];

        // 右侧
        // 排油烟管道尺寸
        this.KitchenExhaustPipeSize.forEach((item) => {
          item["length"] = 0;
          if (item.KEPS_A) item.length += 1;
          if (item.KEPS_B) item.length += 1;
        });
        // 厨房平时排风管道尺寸
        this.KitchenNormalAccidentExhaustPipeSize.forEach((item) => {
          item["length"] = 0;
          if (item.KNAEPS_A) item.length += 1;
          if (item.KNAEPS_B) item.length += 1;
        });
        // 厨房补风管道尺寸
        this.KitchenAirReplenishmentPipeSize.forEach((item) => {
          item["length"] = 0;
          if (item.KARPS_A) item.length += 1;
          if (item.KARPS_B) item.length += 1;
        });
        // 个护排风管道尺寸
        this.IndividualProtectiveExhaustAirPipeSize.forEach((item) => {
          item["length"] = 0;
          if (item.IPEAPS_A) item.length += 1;
          if (item.IPEAPS_B) item.length += 1;
        });
        // 最终数组参数传递处理***********************8
        // 排油烟管道尺寸
        newKitchenExhaustPipeSize = this.KitchenExhaustPipeSize.filter(
          (item) => item.length > 1
        );
        // 厨房事故排风管道尺寸
        newKitchenNormalAccidentExhaustPipeSize =
          this.KitchenNormalAccidentExhaustPipeSize.filter(
            (item) => item.length > 1
          );
        // 厨房补风管道尺寸
        newKitchenAirReplenishmentPipeSize =
          this.KitchenAirReplenishmentPipeSize.filter(
            (item) => item.length > 1
          );
        // 个护排风管道尺寸
        newIndividualProtectiveExhaustAirPipeSize =
          this.IndividualProtectiveExhaustAirPipeSize.filter(
            (item) => item.length > 1
          );
        // 参数初始化
        if (!this.AirConditioningCondition.VRFConditionReservation) this.AirConditioningCondition['VRFConditionReservation'] = false
        if (!this.AirConditioningCondition.TotalCoolingCapacity) this.AirConditioningCondition['TotalCoolingCapacity'] = ""
        if (!this.AirConditioningCondition.TotalHeatingCapacity) this.AirConditioningCondition['TotalHeatingCapacity'] = ""
        if (!this.AirConditioningCondition.AirChangeFlow) this.AirConditioningCondition['AirChangeFlow'] = ""


        let obj = {
          CreateTime: this.ShopInfo.HVACMajor_CreateTime
            ? this.ShopInfo.HVACMajor_CreateTime
            : undefined,
          CreatorId: this.ShopInfo.HVACMajor_CreatorId
            ? this.ShopInfo.HVACMajor_CreatorId
            : undefined,
          Id: this.ShopInfo.HVACMajor_Id
            ? this.ShopInfo.HVACMajor_Id
            : undefined,
          ModuleType: "HVACMajor",
          ModuleJson: JSON.stringify({
            // AirChangeFlow: this.Form.AirChangeFlow,
            // AirconditionType: this.Form.AirconditionType,
            // LDZGCaliber: this.Form.LDZGCaliber,
            // CoolingLoadCapacity: this.CoolingLoadCapacity,
            // AirconditionCoolingIndex: this.Form.AirconditionCoolingIndex,
            // KitchenExhaustFumes: this.Form.KitchenExhaustFumes,
            // LampBlackNPS: this.Form.LampBlackNPS,
            // AirChanges: this.Form.AirChanges,
            // KitchenAirReplenishment: this.Form.KitchenAirReplenishment,
            // AirSupplyDuctSize: this.Form.AirSupplyDuctSize,
            // RemarkHVAC: this.Form.RemarkHVAC,
            AirConditioningCondition: {
              ...this.AirConditioningCondition,
              AC_ColdWaterPipeDiameter: newAC_ColdWaterPipeDiameter,
              AC_HotWaterPipeDiameter: newAC_HotWaterPipeDiameter,
              FreshAirPipelineSize: newFreshAirPipelineSize,
              HeatingLoadIndex: this.HeatingLoadIndex,
            },
            VentilationCondition: {
              ...this.VentilationCondition,
              KitchenExhaustPipeSize: newKitchenExhaustPipeSize,
              KitchenNormalAccidentExhaustPipeSize:
                newKitchenNormalAccidentExhaustPipeSize,
              KitchenAirReplenishmentPipeSize:
                newKitchenAirReplenishmentPipeSize,
              IndividualProtectiveExhaustAirPipeSize:
                newIndividualProtectiveExhaustAirPipeSize,
              RefrigerationLoadIndex: this.RefrigerationLoadIndex,
            },
            HVACRemark: this.Form.HVACRemark ? this.Form.HVACRemark : "",
          }),
          ShopId: this.ShopInfo.Id || this.Form.Id,
          Status: 1,
        };


        // 只有除基础信息以外的模块调用模块接口请求函数
        this.getShop_ExtensionInfoRequest(obj, e);
      } else if (e === "WSADMajor") {
        let newWaterSupplyPipeSize = this.WaterSupplyPipeSize.filter(
          (item) => item !== ""
        );
        let newCateringDrainagePipeSize = this.CateringDrainagePipeSize.filter(
          (item) => item !== ""
        );
        let newNonCateringDrainagePipeSize =
          this.NonCateringDrainagePipeSize.filter((item) => item !== "");
        let newGasConnectingPipeSize = this.GasConnectingPipeSize.filter(
          (item) => item !== ""
        );

        //给排水
        let obj = {
          CreateTime: this.ShopInfo.WSADMajor_CreateTime
            ? this.ShopInfo.WSADMajor_CreateTime
            : undefined,
          CreatorId: this.ShopInfo.WSADMajor_CreatorId
            ? this.ShopInfo.WSADMajor_CreatorId
            : undefined,
          Id: this.ShopInfo.WSADMajor_Id
            ? this.ShopInfo.WSADMajor_Id
            : undefined,
          ModuleType: "WSADMajor",
          ModuleJson: JSON.stringify({
            NaturalGasVolume: this.Form.NaturalGasVolume ? this.Form.NaturalGasVolume : "",
            NaturalGasPressure: this.Form.NaturalGasPressure ? this.Form.NaturalGasPressure : "",
            GasConnectingPipeSize: newGasConnectingPipeSize,
            WaterSupplyPipeSize: newWaterSupplyPipeSize,
            CateringDrainagePipeSize: newCateringDrainagePipeSize,
            NonCateringDrainagePipeSize: newNonCateringDrainagePipeSize,
            RemarkWSAD: this.Form.RemarkWSAD ? this.Form.RemarkWSAD : "",
          }),
          ShopId: this.ShopInfo.Id || this.Form.Id,
          Status: 1,
        };


        // 只有除基础信息以外的模块调用模块接口请求函数
        this.getShop_ExtensionInfoRequest(obj, e);
      } else if (e === "IntelligentMajor") {
        //弱电
        // 根据是否当前行填写任意一个值 处理对应数组逻辑 只传递有任意一个值的当前行
        let newWeakCurrentBoxInfo = [];
        this.WeakCurrentBoxInfo.forEach((item) => {
          item["length"] = 0;
          if (item.WCB_Number) item.length += 1;
          if (item.WC_JunctionBox) item.length += 1;
          if (item.NetworkPointsAndCables) item.length += 1;
          if (item.CableTVPointsAndCables) item.length += 1;
          if (item.POSPointsAndCables) item.length += 1;
        });
        newWeakCurrentBoxInfo = this.WeakCurrentBoxInfo.filter(
          (item) => item.length > 0
        );

        //智能化
        let obj = {
          CreateTime: this.ShopInfo.IntelligentMajor_CreateTime
            ? this.ShopInfo.IntelligentMajor_CreateTime
            : undefined,
          CreatorId: this.ShopInfo.IntelligentMajor_CreatorId
            ? this.ShopInfo.IntelligentMajor_CreatorId
            : undefined,
          Id: this.ShopInfo.IntelligentMajor_Id
            ? this.ShopInfo.IntelligentMajor_Id
            : undefined,
          ModuleType: "IntelligentMajor",
          ModuleJson: JSON.stringify({
            WeakCurrentBoxInfo: newWeakCurrentBoxInfo,
            RemarkIntelligent: this.Form.RemarkIntelligent ? this.Form.RemarkIntelligent : " ",
          }),
          ShopId: this.ShopInfo.Id || this.Form.Id,
          Status: 1,
        };
        // 只有除基础信息以外的模块调用模块接口请求函数
        this.getShop_ExtensionInfoRequest(obj, e);
      } else {
        // 结构
        let obj = {
          CreateTime: this.ShopInfo.StructureMajor_CreateTime
            ? this.ShopInfo.StructureMajor_CreateTime
            : undefined,
          CreatorId: this.ShopInfo.StructureMajor_CreatorId
            ? this.ShopInfo.StructureMajor_CreatorId
            : undefined,
          Id: this.ShopInfo.StructureMajor_Id
            ? this.ShopInfo.StructureMajor_Id
            : undefined,
          ModuleType: "StructureMajor",
          ModuleJson: JSON.stringify({
            // LoweringPlateHeight: this.Form.LoweringPlateHeight,
            LiveLoadOnTheBottomPlateSurface:
              this.Form.LiveLoadOnTheBottomPlateSurface ? this.Form.LiveLoadOnTheBottomPlateSurface : "",
            ConstantLoadOnTheBottomPlateSurface:
              this.Form.ConstantLoadOnTheBottomPlateSurface ? this.Form.ConstantLoadOnTheBottomPlateSurface : "",
            KitchenPanelLiveLoad: this.Form.KitchenPanelLiveLoad ? this.Form.KitchenPanelLiveLoad : "",
            ConstantLoadOnKitchenPanelSurface:
              this.Form.ConstantLoadOnKitchenPanelSurface ? this.Form.ConstantLoadOnKitchenPanelSurface : "",
            LoweringPlateHeight: this.Form.LoweringPlateHeight ? this.Form.LoweringPlateHeight : "",
            RemarkStructure: this.Form.RemarkStructure ? this.Form.RemarkStructure : "",
          }),
          ShopId: this.ShopInfo.Id || this.Form.Id,
          Status: 1,
        };
        // 只有除基础信息以外的模块调用模块接口请求函数
        this.getShop_ExtensionInfoRequest(obj, e);
      }
    },
    //提交保存日志
    SaveOperationLogs(data) {
      this.$http
        .post("/Shop_Manage/Shop_ExtensionInfo/SaveOperationLogs", { ...data })
        .then(res => {
          if (res.Success) {
            console.log("保存日志成功")
            this.parentObj.refreshLog()
          } else {
            console.log("保存日志失败")
          }
        })
    },

    // 扩展模块信息提交请求
    getShop_ExtensionInfoRequest(obj, type) {
      console.log(obj, 'obj')
      console.log(this.ShopInfo, 'ShopInfo')
      console.log(this.Form, 'FormShopInfo')

      var that = this;

      // var shopInfo = this.ShopInfo;

      const loading = this.$loading({
        lock: true,
        text: "保存中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });


      that.$http
        .post("/Shop_Manage/Shop_ExtensionInfo/SaveData", obj)
        .then(async (res) => {
          if (res.Success) {
            this.ShopInfo[obj.ModuleType + "_CreateTime"] = res.Data.CreateTime;
            this.ShopInfo[obj.ModuleType + "_CreatorId"] = res.Data.CreatorId;
            this.ShopInfo[obj.ModuleType + "_Id"] = res.Data.Id;
            this.SaveOperationLogs(res.Data)
            // 重新更新vux里的商铺信息
            // console.log(shopInfo)
            // this.$store.commit("getShopInfo", shopInfo);

            // this.$message.success("已保存");
            // 保存后按钮禁用
            if (type === "ElectricalMajor") {
              // 电气
              this.ElectricalMajorDisabled = true;
            } else if (type === "HVACMajor") {
              // 暖通
              this.HVACMajorDisabled = true;
            } else if (type === "WSADMajor") {
              // 给排水
              this.WSADMajorDisabled = true;
            } else if (type === "IntelligentMajor") {
              // 智能化
              this.IntelligentMajorDisabled = true;
            } else {
              // 结构
              this.StructureMajorDisabled = true;
            }
            // 刷新日志列表

            await this.editCancel(obj.ModuleType + 'Disabled')
            setTimeout(() => {
              loading.close();
              that.$message.success("已保存");
            }, 500);
            //  this.$emit("closeDialog", true);
          } else {

            if (type === "ElectricalMajor") {
              // 电气
              this.ElectricalMajorDisabled = true;
            } else if (type === "HVACMajor") {
              // 暖通
              this.HVACMajorDisabled = true;
            } else if (type === "WSADMajor") {
              // 给排水
              this.WSADMajorDisabled = true;
            } else if (type === "IntelligentMajor") {
              // 智能化
              this.IntelligentMajorDisabled = true;
            } else {
              // 结构
              this.StructureMajorDisabled = true;
            }
            // 刷新日志列表
            this.parentObj.refreshLog()
            await this.editCancel(obj.ModuleType + 'Disabled')
            loading.close();
            that.$message.error(res.Msg);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.shopBaseInfo {

  // 覆盖element input输入框的宽高
  ::v-deep .el-form-item {
    margin-bottom: 5px !important;

    .el-form-item__content {

      //margin: 0 !important;
      .el-input {

        // 可以隐藏上下加减号
        // input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
        //   // -webkit-appearance: none !important;
        // 	margin-left: 20px;
        // }
        .el-input__inner {
          height: 28px !important;
          line-height: 28px !important;
        }

        .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  //电气和暖通
  ::v-deep .inputBoxP[data-v-7d197f06] {
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    white-space: nowrap;
    margin: 0;
    //width: 150px;
    margin-right: 13px;
  }

  ::v-deep .dianqi {
    .itemboxRemark {
      padding-left: 30px;

      .el-form-item {
        display: flex;

        .el-form-item__label-wrap {
          margin-left: 0 !important;
        }

        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }

    .itembox {
      .el-form-item {
        //border: 1px solid red;
        display: flex;

        .el-form-item__label-wrap {
          margin-left: 0 !important;
        }

        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }

    .itembox {
      .el-input {
        .el-input__inner {
          height: 28px !important;
          line-height: 28px !important;
        }
      }

      .span.first {
        width: 60%;
        padding-right: 5px;
      }

      .span.second {
        width: 78%;
        padding-right: 5px;
      }

      .span.three {
        width: 78%;
        padding-right: 5px;
      }

      .span.four {
        width: 70%;
      }

      .span.four.zhineng {
        width: 77%;
      }

      .span {
        //flex: 3;
      }

      .input {
        //flex: 7;
      }
    }
  }

  //智能化话
  //::v-deep .dianqiHeader {
  //  .el-form-item {
  //    border: 1px solid red;
  //    display: flex;
  //    .el-form-item__label-wrap {
  //      border: 1px solid chartreuse !important;
  //      flex: 7;
  //      margin-left: 0 !important;
  //    }
  //    .el-form-item__content {
  //      flex: 3;
  //      border: 1px solid deeppink !important;
  //      margin-left: 0 !important;
  //    }
  //  }
  //}

  .edit:hover {
    color: #aaa7a7 !important;
    cursor: pointer;
  }

  .save {
    color: red;
  }

  .save:hover {
    color: rgb(253, 127, 127);
    cursor: pointer;
  }

  .el-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 20px;

    ::v-deep .el-button {
      padding: 8px 20px;
    }
  }

  .textfont {
    font-weight: bolder;
  }
}

.el-button--primary {
  background: #fff;
  border: none;
  color: red;
}

// 取消input的上下箭头
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}

::v-deep .el-collapse-item__header {
  pointer-events: none;
}

::v-deep .el-collapse-item__arrow {
  pointer-events: auto;
}
</style>
