<template>
  <div class="team">
    <el-row :gutter="20">
      <el-col :span="12" v-loading="dataLoading">
        <el-card class="left-box" body-style="height:calc(100vh - 190px);overflow-y:auto">
          <div slot="header" class="clearfix" style="height: 29px;">
            <span style="font-size: 18px; font-weight: bold;">项目成员</span>
            <el-button type="primary" size="mini" style="float: right;margin-right: 10px;"
              @click="openDialog">增加</el-button>
          </div>
          <div style="display: flex; flex-wrap: wrap;">

            <div v-for="(item, index) in data" :key="index" :span="3" class="teamBox">
              <el-card :body-style="{ padding: '10px 5px', textAlign: 'center', position: 'relative', width: '185px' }">
                <div class="badge" v-if="item.IsManage">创建人</div>
                <div class="badgeRight" v-if="item.UserId == userInfo.Id || IsManage" @click="delUser(item)">移除</div>
                <div style="display: flex;margin-top: 15px;padding-bottom: 15px;align-items: center;">
                  <div style="width: 50px;height: 50px;margin-right: 5px;margin-left: 5px;">
                    <el-image :src="item.Icon" class="image" fit="cover"></el-image>
                  </div>
                  <div style="width: calc(100% - 60px);">
                    <div style="line-height: 30px;font-size: 16px;" class="ellipsis1"
                      :title="item.NickName || item.UserName || '用户' + item.Phone">{{
                        item.NickName || item.UserName || "用户" + item.Phone
                      }}</div>
                    <div class="time ellipsis1" style="line-height: 30px;font-size: 14px;" :title="item.Phone">{{
                      item.Phone }}</div>
                  </div>

                </div>

                <!-- <div style="width: 25px;position: absolute;right: 5px;top: 15px;"
                  v-if="item.UserId == userInfo.Id || IsManage">
                  <el-button style="width: 25px;height: 70px;padding: 5px;" @click="delUser(item)"
                    type="primary">移<br>出<br>项<br>目</el-button>
                </div> -->

              </el-card>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12" v-loading="OutSourceLoading">

        <el-card class="left-box" body-style="height:calc(100vh - 190px);overflow-y:auto">
          <div slot="header" class="clearfix" style="height: 29px;">
            <span style="font-size: 18px; font-weight: bold;">外协成员</span>

          </div>
          <div style="display: flex; flex-wrap: wrap;">

            <div v-for="(item, index) in OutSourceDataList" :key="index" :span="3" class="teamBox">
              <el-card :body-style="{ padding: '10px 5px', textAlign: 'center', position: 'relative', width: '185px' }">

                <div style="display: flex;margin-top: 15px;padding-bottom: 15px;align-items: center;">
                  <div style="width: 50px;height: 50px;margin-right: 5px;margin-left: 5px;">
                    <el-image :src="item.Icon" class="image" fit="cover"></el-image>
                  </div>
                  <div style="width: calc(100% - 60px);">
                    <div style="line-height: 30px;font-size: 16px;" class="ellipsis1">{{
                      item.NickName || item.UserName || "用户" + item.Phone
                    }}</div>
                    <div class="time ellipsis1" style="line-height: 30px;font-size: 14px;">{{ item.Phone }}</div>
                  </div>

                </div>


              </el-card>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-select v-model="newCreateUser" placeholder="请指定创建人">
        <el-option v-for="item in localdata" :disabled="item.disable" :key="item.value" :label="item.text"
          :value="item.value">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogInputConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <PersonSelect ref="PersonSelect" @select="dialogContact" />
  </div>
</template>

<script>
import PersonSelect from "@/components/PersonSelect.vue";
import { mapState } from "vuex"
export default {
  props: {
    pId: { type: String, require: true },
    parentOBJ: { type: Object }
  },
  components: {
    PersonSelect,
  },
  data() {
    return {
      data: [],
      OutSourceDataList: [],
      localdata: [],
      imageUrl: "", // 新建团队图像
      userName: "", // 名称
      newCreateUser: "",
      dialogVisible: false,
      IsManage: true,
      OutSourceLoading: false,

      dataLoading: false,
    };
  },
  computed: {
    ...mapState(['userInfo'])
  },
  watch: {
    pId: {
      handler(n) {
        if (n) {
          this.getDataList();
          this.getOutSourceDataList()
        }

      },
      immediate: true,
    },
  },
  methods: {
    openInfoForm(entity) {
      this.$http
        .post("/Base_ProjectWork/Base_Project/SaveData", entity)
        .then((res) => {
          console.log('board组件获取保存结果', res, this.parentOBJ)
          this.loading = false;
          if (res.Success) {
            // 重新调用父组件里的详情
            let Id = this.parentOBJ.entity.Id
            this.parentOBJ.getProjectInfo(Id);
            this.disabled = true;
            this.$emit("infoFormClose", true);
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    dialogInputConfirm() {
      if (!this.newCreateUser) return this.$message.error('请选择新的项目创建人')
      this.$http.post('Base_ProjectWork/Base_ProjectPartake/DeleteProjectPartakeUserData', {
        "UserId": this.newCreateUser,
        "PartakeUserId": this.data.filter(item => item.IsManage)[0].UserId,
        "ProjectId": this.pId
      }).then(res => {
        if (res.Success) {
          this.$message.success('操作成功')
          this.dialogVisible = false
          setTimeout(() => {
            this.$router.replace('/Project/All')
          }, 1500);
        } else this.$message.error(res.Msg)
      })
    },
    delUser(item) {
      if (item.IsManage) {
        this.$confirm('当前成员为项目创建人,确认删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log('123123');
          this.localdata = this.data.map(item => {
            return {
              text: item.UserName,
              value: item.UserId,
              disable: item.IsManage
            }
          })
          this.newCreateUser = ''
          this.dialogVisible = true
        }).catch(() => { });
      } else {
        this.$confirm('此操作将移除当前成员,确认继续吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post('Base_ProjectWork/Base_ProjectPartake/DeleteProjectPartakeUserData', {
            "UserId": '',
            "PartakeUserId": item.UserId,
            "ProjectId": item.ProjectId
          }).then(res => {
            if (res.Success) {
              this.getDataList()
              this.$message.success('操作成功')
            } else {
              this.$message.error(res.Msg)
            }
          })
        }).catch(() => { });
      }
    },
    openDialog(text) {
      let str = typeof text == "string" ? text : "";
      this.$refs.PersonSelect.open(str);
    },
    dialogContact(arr) {
      let newArr = [];
      arr.forEach(async (item) => {
        let idx = this.data.findIndex((im) => {
          console.log(item, im);
          return im.UserId == item.MailUserId;
        });
        if (idx === -1) {
          newArr.push({
            ProjectId: this.pId,
            UserId: item.MailUserId,
          });
        }
      });
      if (newArr.length <= 0) this.$message.error("成员已存在");
      else this.saveUser(newArr);
    },
    saveUser(arr) {
      this.$http
        .post("Base_ProjectWork/Base_ProjectPartake/SaveDataByList", arr)
        .then((res) => {
          if (res.Success) {
            this.getDataList();
          } else this.$message.error(res.Msg);
        });
    },
    getDataList() {
      this.dataLoading = true;
      this.$http
        .post(
          "/Base_ProjectWork/Base_ProjectPartake/GetProjectPartakeUserList?ProjectId=" +
          this.pId
        )
        .then((res) => {
          this.dataLoading = false;
          if (res.Success) {
            this.data = res.Data;
            this.IsManage = this.data.some(item => item.UserId == this.userInfo.Id && item.IsManage)
          }
        });
    },
    getOutSourceDataList() {
      this.OutSourceLoading = true;
      this.$http
        .post(
          "/Base_ProjectManage/Project_ExternalUser/GetDataListByProjectId?ProjectId=" +
          this.pId
        )
        .then((res) => {
          this.OutSourceLoading = false;

          if (res.Success) {
            this.OutSourceDataList = res.Data;
            // this.IsManage = this.data.some(item => item.UserId == this.userInfo.Id && item.IsManage)
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 34px;
  color: #8c939d;
  width: 100%;
  height: 90px;
  line-height: 90px;
  text-align: center;
  cursor: pointer;
}

.avatar {
  width: 100%;
  height: 120px;
  display: block;
}

.paddingBox {
  padding: 16px;
  box-sizing: border-box;
}

.team {
  padding-top: 10px;

  // .content {
  //   padding: 10px;
  //   height: 60px;
  //   box-sizing: border-box;

  //   span {
  //     display: inline-block;
  //     width: 130px;
  //     overflow: hidden;
  //     white-space: nowrap;
  //     text-overflow: ellipsis;
  //   }

  //   .footer {
  //     line-height: 30px;

  //     .btn {
  //       float: left;
  //       padding: 0;
  //       margin-right: 8px;
  //     }
  //   }
  // }

  .teamBox {
    margin-right: 10px;
    margin-bottom: 10px;
    // width: 150px;
    // display: flex;

    .badge {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #D1100B;
      color: #fff;
      border-bottom-right-radius: 13px;
      font-size: 12px;
      padding: 3px;
      padding-right: 5px;
    }

    .badgeRight {
      position: absolute;
      cursor: pointer;
      bottom: 0;
      right: 0;
      background-color: #aba1a1;
      color: #fff;

      font-size: 12px;
      padding: 3px;
      padding-right: 5px;


    }

    .badgeRight:hover {
      background: #D1100B;
    }

    .del {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #D1100B;
      cursor: pointer;
    }

    // .el-badge {
    //   width: 100%;

    //   .el-badge__content {
    //     border-radius: 0 10px 0;
    //   }

    //   .el-badge__content.is-fixed {
    //     position: absolute;
    //     top: 11px !important;
    //     right: 39px !important;
    //   }

    //   .el-card {
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: center;
    //     border-radius: 7%;
    //   }
    // }
  }

  .time {
    font-size: 13px;
    color: #999;
  }

  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    height: 50px;
    width: 50px;
    // margin: 0 auto;
    display: block;
    border-radius: 50%;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both;
  }
}

::v-deep .left-box {
  .el-card__header {
    padding: 10px;
  }
}

.ellipsis1 {
  overflow: hidden; //超出隐藏
  word-wrap: break-word;
  word-break: normal;
  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

  -webkit-line-clamp: 1; //内容限制的行数
}
</style>
