<template>
  <div class="box" style="overflow: hidden;">
    <div style="display: flex;margin-bottom: 5px;">
      <div
        style="display: flex;align-items: center;justify-content: center;height: 30px;line-height: 30px;width: 40px;">
        <i size="30" class="el-icon-back" style="font-size: 23px;cursor: pointer;" @click="handleBack"></i>
      </div>
      <div style="font-size: 20px;font-weight: bold;line-height: 30px; margin-left: 20px;">数据分析-基础信息</div>
    </div>
    <el-row style="margin-bottom: 5px">
      <el-col :span="24" style="display: flex">
        <el-card style="width: calc(100% - 700px)"
          body-style="padding: 0 10px;display: flex;align-items: center;justify-content:space-between ;height: 90px;">

          <el-card style="
              height: 60px;
              width: 180px;
              margin-left: 10px;
              background-color: orange;
              color: #fff;
              border-radius: 6px;
              font-size: 20px;
              font-weight: bold;
            " body-style="padding: 0 10px;line-height:30px;text-align: center;">
            <i class="el-icon-office-building"></i>&nbsp;项目总面积<br /><span>{{ homeData.ProjectTotalArea }}㎡</span>
          </el-card>
          <el-card style="
              height: 60px;
              width: 180px;
              margin-left: 10px;
              background-color: green;
              color: #fff;
              border-radius: 6px;
              font-size: 20px;
              font-weight: bold;
            " body-style="padding: 0 10px;line-height:30px;text-align: center;">
            <i class="el-icon-house"></i>&nbsp;经营总面积<br /><span>{{ homeData.OperatingTotalArea }}㎡</span>
          </el-card>
          <el-card style="
              height: 60px;
              width: 180px;
              margin-left: 10px;
              background-color: mediumpurple;
              color: #fff;
              border-radius: 6px;
              font-size: 20px;
              font-weight: bold;
            " body-style="padding: 0 10px;line-height:30px;text-align: center;">
            <i class="el-icon-school"></i>&nbsp;总计商铺面积<br /><span>{{ homeData.ShopTotalArea }}㎡</span>
          </el-card>
          <el-card style="
              height: 60px;
              width: 170px;
              margin-left: 10px;
              background-color: #73c0de;
              color: #fff;
              border-radius: 6px;
              font-size: 20px;
              font-weight: bold;
            " body-style="padding: 0 10px;line-height:30px;text-align: center;">
            <i class="el-icon-data-board"></i>&nbsp;入住商铺数量<br /><span>{{
              homeData.ShopTotalNumber
            }}</span>
          </el-card>
          <el-card style="
              height: 60px;
              width: 180px;
              margin-left: 10px;
              background-color: #ee6666;
              color: #fff;
              border-radius: 6px;
              font-size: 20px;
              font-weight: bold;
            " body-style="padding: 0 10px;line-height:30px;text-align: center;">
            <i class="el-icon-odometer"></i>&nbsp;得铺率<br /><span>{{ homeData.ShopCoverageRate }}%</span>
          </el-card>
        </el-card>
        <el-card style="height: 90px; width: 700px; margin-left: 10px"
          body-style="padding: 0 10px;;text-align: center;background-color: #D1100B;">
          <el-table :data="tableData" size="mini" height="90">
            <el-table-column v-for="(item, index) in tableColumn" :key="index" :label="item.ShopFormatName"
              :prop="item.ShopFormatName" width="120">
            </el-table-column>

          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 5px" v-loading="louListLoading">
      <el-card body-style="padding: 0px 10px;min-height: 40px;">
        <el-row style="display: flex; align-items: center;flex-wrap: wrap; " v-if="isLou">
          <div style="font-size: 14px; line-height: 40px; font-size: 17px;font-weight: bold;">选择楼栋：</div>
          <div v-for="(item, index) in louList" :key="index" style="
              margin-left: 20px;
            
              line-height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 17px;
            ">
            <el-checkbox v-model="item.IsTrue" size="mini" style="line-height: 40px;"></el-checkbox><el-button
              type="primary" style="
               
                height: 20px;
              line-height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
                font-size: 17px;
                padding: 5px 10px;
              " @click="louClick(item)">{{ item.Value }}</el-button>
          </div>
          <div v-if="louList.length <= 0">
            <span style="font-size: 17px;line-height: 40px;font-weight: bold;color: #ee6666;">暂无楼栋数据</span>
          </div>
        </el-row>
        <el-row style="display: flex; align-items: center;font-size: 17px;
              line-height: 40px;
              flex-wrap: wrap;" v-else>
          <span @click="blockLou" style="font-weight: bold;cursor: pointer;">
            <i class="iconfont icon-fanhui" style="font-size: 18px;"></i><span style="margin-left: 10px;">当前楼栋:
            </span><span style="margin-left: 5px;">{{
              itemLou.Value
            }}</span>
          </span>


          <div style="font-size: 14px; line-height: 40px; margin-left: 10px;font-size: 17px;font-weight: bold;">
            选择楼层：
          </div>
          <div v-for="(item, index) in itemLou.floorList" :key="index" style="
              margin-left: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 17px;
              line-height: 40px;
            ">
            <el-checkbox v-model="item.IsTrue"></el-checkbox><el-button type="primary" style="
                width: 40px;
                height: 20px;
                line-height: 40px;
                display: flex;
                font-size: 17px;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
                padding: 0px;
              " @click="item.IsTrue = !item.IsTrue">{{ item.Value }}</el-button>
          </div>

        </el-row>
      </el-card>
    </el-row>

    <el-card body-style="padding:  10px;" v-if="initFlag && louList.length > 0" v-loading="louListLoading">
      <el-row :gutter="5">
        <el-col :span="24">


          <!-- 搜索数据 -->
          <el-row :gutter="10" style="height: 320px; overflow: hidden">
            <el-col :span="8" v-loading="MG_BaseInfoPageInfoLoading">
              <div class="InfoCard">
                <el-row style="line-height: 50px;height: 50px;border-bottom: 1px solid #EBEEF5">
                  <span style="font-size: 18px; font-weight: bold;margin-left: 10px;">当前得铺率</span>
                </el-row>
                <!-- 总计得铺率 -->
                <EchartsIndex :option="coverageRateOptions" styleStr="width: 100%;height: 260px;"
                  v-if="MG_BaseInfoPageInfo.BuildingTotalArea != undefined" />
                <el-empty description="暂无数据" style="width: 100%; height: 260px;" v-else></el-empty>
              </div>
            </el-col>

            <el-col :span="8" v-loading="MG_BaseInfoPageInfoLoading">
              <div class="InfoCard">
                <el-row style="line-height: 50px;height: 50px;border-bottom: 1px solid #EBEEF5">
                  <el-col :span="16">
                    <span v-if="MG_BaseInfoPageInfoNum == 2"
                      style="font-size: 18px; font-weight: bold;margin-left: 10px;">业态数量占比</span>
                    <span v-if="MG_BaseInfoPageInfoNum == 1"
                      style="font-size: 18px; font-weight: bold;margin-left: 10px;">业态面积占比</span>
                  </el-col>

                  <el-col :span="8">
                    <el-radio-group v-model="MG_BaseInfoPageInfoNum" size="small"
                      style="text-indent: 0;margin-left: 10px;" @change="changeType">
                      <el-radio-button :label="1"><i class="el-icon-s-home"></i>&nbsp;面积</el-radio-button>
                      <el-radio-button :label="2"><i class="el-icon-s-grid"></i>&nbsp;数量</el-radio-button>
                    </el-radio-group>
                  </el-col>
                </el-row>
                <div style="position: relative;">
                  <EchartsIndex :option="formatCountOptionsNum" styleStr="width:100%;height: 260px;"
                    v-if="MG_BaseInfoPageInfo.FAP_Shop && MG_BaseInfoPageInfo.FAP_Shop.length > 0"
                    @onclick="formatCountClick" />
                  <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
                  <div style="position: absolute;right: 0;top: 0;font-size: 12px;font-weight: bold;">
                    <div v-if="MG_BaseInfoPageInfoNum == 1">
                      <div>餐饮面积：<span style="color: #D1100B;">
                          {{ MG_BaseInfoPageInfo.CateringProportion ?
                            MG_BaseInfoPageInfo.CateringProportion.ShopAreaCatering + '㎡' : '' }}</span></div>
                      <div>餐饮面积占比：<span style="color: #D1100B;">{{
                        MG_BaseInfoPageInfo.CateringProportion ?
                          MG_BaseInfoPageInfo.CateringProportion.ShopAreaProportion + '%' : ''
                      }}</span></div>
                    </div>

                    <div v-if="MG_BaseInfoPageInfoNum == 2">
                      <div>餐饮数量：<span style="cleft: 0;olor: #D1100B;">{{ MG_BaseInfoPageInfo.CateringProportion ?
                        MG_BaseInfoPageInfo.CateringProportion.ShopCountCatering : '' }}</span></div>
                      <div>餐饮数量占比：<span style="color: #D1100B;">{{ MG_BaseInfoPageInfo.CateringProportion ?
                        MG_BaseInfoPageInfo.CateringProportion.ShopCountProportion + "%" : '' }}</span></div>
                    </div>
                  </div>
                  <div style="position: absolute;left: 10px;top: 0;font-size: 12px;font-weight: bold;"
                    v-if="MG_BaseInfoPageInfoNum == 1">
                    <div v-html="formatCountOptionsNumView">
                    </div>
                  </div>
                  <div style="position: absolute;left: 10px;top: 0;font-size: 12px;font-weight: bold;"
                    v-if="MG_BaseInfoPageInfoNum == 2">
                    <div v-html="formatCountOptionsNumView2">
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="8" v-loading="MG_BaseInfoPageTopShopBaseInfoLoading">
              <div class="InfoCard">
                <el-row style="line-height: 50px;height: 50px;border-bottom: 1px solid #EBEEF5">
                  <span style="font-size: 18px; font-weight: bold;margin-left: 10px;">TOP 10 门店商铺面积排名</span>
                </el-row>
                <EchartsIndex :option="TOP_Options" styleStr="width: 100%; height: 260px;"
                  v-if="MG_BaseInfoPageTopShopBaseInfo.length > 0" />
                <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
              </div>
            </el-col>

          </el-row>
          <el-row v-loading="MG_BaseInfo_ShopActivityLoading" :gutter="10" style="margin-top: 8px;">
            <el-col :span="24">
              <div class="InfoCard">
                <el-row style="line-height: 50px;border-bottom: 1px solid #EBEEF5">
                  <span style="font-size: 18px; font-weight: bold;margin-left: 10px;">商铺活跃度</span>
                </el-row>
                <!--商铺活跃度 -->
                <EchartsIndex :option="coverageRateTimeOptions" styleStr="width: 100%; height: 100%;height: 260px;"
                  v-if="MG_BaseInfo_ShopActivity.length > 0" />
                <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
              </div>
            </el-col>

          </el-row>
          <el-row :gutter="10" v-loading="MG_BaseInfo_ShopStatsReturnDataLoading" style="margin-top: 8px;">
            <!-- //1 -->
            <el-col :span="8" style="height: 100%">
              <div class="InfoCard">
                <div style="border-bottom: 1px solid #EBEEF5;padding-top:5px ;">
                  <el-row style="display: flex;align-items: center;height: 40px;">
                    <div style="width: calc(100% - 160px);">
                      <span v-if="ShopStatsReturn == 1"
                        style="font-size: 18px; font-weight: bold;margin-left: 10px;">不同面积商铺不同{{ isLou ?
                          '楼栋'
                          : '楼层'
                        }}数量统计</span>
                      <span v-if="ShopStatsReturn == 2"
                        style="font-size: 18px; font-weight: bold;margin-left: 10px;">不同面积商铺不同{{ isLou ?
                          '楼栋'
                          : '楼层'
                        }}占比</span>
                    </div>
                    <div style="width: 150px;margin-right: 10px;">
                      <el-radio-group v-model="ShopStatsReturn" size="small">
                        <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;数量</el-radio-button>
                        <el-radio-button :label="2"><i class="el-icon-s-help"></i>&nbsp;占比</el-radio-button>
                      </el-radio-group>
                    </div>
                  </el-row>

                </div>
                <div v-if="ShopStatsReturn == 1">
                  <EchartsIndex :option="acreageOption" :styleStr="'width: 100%;height: ' + bottomHeight + 'px'"
                    v-if="this.MG_BaseInfo_ShopStatsReturnData.Shop_AreaRange_Count && this.MG_BaseInfo_ShopStatsReturnData.Shop_AreaRange_Count.length > 0" />
                  <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
                </div>
                <div v-if="ShopStatsReturn == 2" style="position: relative;">
                  <div style="position: absolute;left: 10px;top: 0;font-size: 12px;font-weight: bold;">
                    <div v-html="tablepieOptionPieInfoView">

                    </div>
                  </div>
                  <EchartsIndex :option="tablepieOption" :styleStr="'width: 100%;height: ' + 450 + 'px'"
                    v-if="this.MG_BaseInfo_ShopStatsReturnData.Shop_AreaRange_Count && this.MG_BaseInfo_ShopStatsReturnData.Shop_AreaRange_Count.length > 0"
                    @onclick="tablepieOptionClick" />
                  <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
                </div>
              </div>
            </el-col>

            <!-- 2  Shop_Same_Format-->
            <el-col :span="8" style="height: 100%">
              <div class="InfoCard">
                <div style="border-bottom: 1px solid #EBEEF5;padding-top:5px ;">
                  <el-row style="height: 40px;display: flex;align-items: center;">
                    <div style="width: calc(100% - 160px);">
                      <span v-if="Shop_Same_FormatIsNum == 1"
                        style="font-size: 18px; font-weight: bold;margin-left: 10px;">同一业态不同{{ isLou
                          ? '楼栋' : '楼层'
                        }}数量统计</span>
                      <span v-if="Shop_Same_FormatIsNum == 2"
                        style="font-size: 18px; font-weight: bold;margin-left: 10px">同一业态不同{{ isLou
                          ? '楼栋' : '楼层'
                        }}占比</span>
                    </div>
                    <div style="width: 150px;margin-right: 10px;">
                      <el-radio-group v-model="Shop_Same_FormatIsNum" size="small">
                        <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;数量</el-radio-button>
                        <el-radio-button :label="2"><i class="el-icon-s-help"></i>&nbsp;占比</el-radio-button>
                      </el-radio-group>
                    </div>
                  </el-row>
                </div>
                <div v-if="Shop_Same_FormatIsNum == 1">
                  <EchartsIndex :option="Shop_Same_FormatBar" ref="Shop_Same_FormatBar"
                    :styleStr="'width: 100%;height: ' + bottomHeight + 'px'"
                    v-if="this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Format && this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Format.length > 0" />
                  <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
                </div>
                <div v-if="Shop_Same_FormatIsNum == 2" style="position: relative;">
                  <div style="position: absolute;left: 10px;top: 0;font-size: 12px;font-weight: bold;">
                    <div v-html="Shop_Same_FormatPieInfoView">

                    </div>

                  </div>
                  <EchartsIndex :option="Shop_Same_FormatPie" :styleStr="'width: 100%;height: ' + 450 + 'px'"
                    v-if="this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Format && this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Format.length > 0"
                    @onclick="Shop_Same_FormatPieClick" />

                  <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
                </div>
              </div>
            </el-col>
            <!-- 3  Shop_Same_Building-->
            <el-col :span="8" style="height: 100%">
              <div class="InfoCard">
                <div style="border-bottom: 1px solid #EBEEF5;padding-top:5px ;">
                  <el-row style="height: 40px;display: flex;align-items: center;">

                    <div style="width: calc(100% - 160px);">
                      <span v-if="Shop_Same_BuildingIsNum == 1"
                        style="font-size: 18px; font-weight: bold;margin-left: 10px"> 同一{{ isLou ? '楼栋'
                          : '楼层'
                        }}不同业态商铺数量统计</span>
                      <span v-if="Shop_Same_BuildingIsNum == 2"
                        style="font-size: 18px; font-weight: bold;margin-left: 10px"> 同一{{ isLou ? '楼栋'
                          : '楼层'
                        }}不同业态商铺占比</span>
                    </div>
                    <div style="width: 150px;margin-right: 10px;">
                      <el-radio-group v-model="Shop_Same_BuildingIsNum" size="small">
                        <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;数量</el-radio-button>
                        <el-radio-button :label="2"><i class="el-icon-s-help"></i>&nbsp;占比</el-radio-button>
                      </el-radio-group>
                    </div>
                  </el-row>

                </div>
                <div v-if="Shop_Same_BuildingIsNum == 1">
                  <EchartsIndex :option="Shop_Same_BuildingBar" :styleStr="'width: 100%;height: ' + bottomHeight + 'px'"
                    v-if="this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Building && this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Building.length > 0" />
                  <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
                </div>
                <div v-if="Shop_Same_BuildingIsNum == 2" style="position: relative;">
                  <div style="position: absolute;left: 10px;top: 0;font-size: 12px;font-weight: bold;">

                    <div v-html="Shop_Same_BuildingPieInfoView">

                    </div>
                  </div>
                  <EchartsIndex :option="Shop_Same_BuildingPie" :styleStr="'width: 100%;height: ' + 450 + 'px'"
                    v-if="this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Building && this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Building.length > 0"
                    @onclick="Shop_Same_BuildingPieClick" />
                  <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
                </div>
              </div>
            </el-col>


          </el-row>

        </el-col>

      </el-row>
    </el-card>
    <el-empty description="暂无数据" style="width: 100%; height: 600px;" v-else></el-empty>
  </div>
</template>

<script>
// 引入echarts图表组件
import EchartsIndex from "@/components/Echarts/index";

export default {
  name: "MG_BaseInfoPage", //基础信息模块
  components: {
    EchartsIndex,
  },
  props: {
    typeList: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: String,
      default: "",
    },
    homeData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {

      BuildingIdList: [],
      LayerList: [],
      louListLoading: false,
      MG_BaseInfoPageInfo: {},
      MG_BaseInfoPageInfoLoading: false,
      MG_BaseInfoPageInfoIs: '',
      MG_BaseInfoPageInfoNum: 1,

      MG_BaseInfoPageTopShopBaseInfoLoading: false,
      MG_BaseInfoPageTopShopBaseInfo: [],

      MG_BaseInfo_ShopActivityLoading: false,
      MG_BaseInfo_ShopActivity: [],

      MG_BaseInfo_ShopStatsReturnData: {},
      MG_BaseInfo_ShopStatsReturnDataLoading: false,

      IsChooseBuilding: false,
      //楼栋/楼层 主力店/一般
      Shop_Same_Building: "",
      //楼栋/楼层 主力店/一般
      Shop_Same_Format: "",
      //楼栋/楼层 数量占比
      ShopStatsReturn: 1,
      Shop_Same_BuildingIsNum: 1,
      Shop_Same_FormatIsNum: 1,
      initFlag: true, //初始化标识

      itemLou: {}, //楼栋
      isLou: true,
      louList: [

      ], //楼栋列表
      cList: [

      ],
      serchData: {
        type: [],
      },
      tableColumn: [],
      tableData: [

      ],
      serch: {
        building: ["1", "2"],
        storey: [],
      },

      coverageRateOptions: {
        tooltip: {
          show: true,
          formatter: (a) => {
            if (a.data.name !== "得铺率") {
              return `${a.data.name} : ${a.data.value}`;
            }
          },
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"], //控制圆环大小
            center: ["50%", "50%"], //控制圆环位置
            data: [],

          },
        ],
      },


      coverageRateTimeOptions: {
        grid: {
          left: 60,
          top: 40,
          right: 40,
          bottom: 80,
        },
        xAxis: {
          name: '日期信息',
          nameLocation: 'center',
          nameGap: 65,
          show: true,
          type: "category",
          // boundaryGap: false,
          axisLabel: {
            // 坐标轴刻度标签的相关设置。
            show: true,
            interval: 0,
            rotate: "-45",
          },
          data: [],
        },
        yAxis: {
          name: '商铺数量',
          nameLocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right'
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          show: true,
          type: "value",
        },
        label: {
          show: true,
          // position: "bottom",
          formatter: (a) => {
            return a.value ? a.value : ''
          }
        },
        tooltip: {
          //tip提示框
          trigger: "item",
          formatter: "{a} {b}  ：  {c}",
          textStyle: {
            fontSize: 14,
            fontWeight: "bold",
          },

        },
        legend: {
          show: true,
          top: 0,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        series: [],
      },

      TOP_Options: {
        grid: {
          left: 200,
          top: 30,
          bottom: 50,
        },
        tooltip: {},
        legend: {},
        xAxis: {
          name: '商铺面积',
          nameLocation: 'center',
          nameGap: 30,
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "value",
          alignTicks: true,
        },
        yAxis: {
          name: '商铺名称',
          nameLocation: 'end',
          nameGap: 0,
          nameTextStyle: {
            width: 200,
            align: 'right'
          },
          type: "category",
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          data: [],
        },
        series: [
          {
            data: [],
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            barWidth: '15',
            label: {
              show: true, // 显示标签
              color: "#fff", // 标签字体颜色
              position: "inside", // 标签位置
              // 可以通过 formatter 自定义标签格式
              formatter: "{c}㎡", // {c} 表示数据值
            },
            itemStyle: {
              color: (a) => {
                if (a.dataIndex == 0) {
                  return "#5470C6";
                } else if (a.dataIndex == 1) {
                  return "#91CC75";
                } else if (a.dataIndex == 2) {
                  return "#FAC858";
                } else if (a.dataIndex == 3) {
                  return "#EE6666";
                } else if (a.dataIndex == 4) {
                  return "#EA7CCC";
                } else if (a.dataIndex == 5) {
                  return "#3BA272";
                } else if (a.dataIndex == 6) {
                  return "#73C0DE";
                } else if (a.dataIndex == 7) {
                  return "#EE6666";
                } else if (a.dataIndex == 8) {
                  return "#FACB61";
                } else if (a.dataIndex == 9) {
                  return "#91CC75";
                } else if (a.dataIndex == 10) {
                  return "#5471C8";
                }
              },
              opacity: 1,
              borderRadius: [20, 20, 20, 20],
            },
          },
        ],
      },
      formatCountOptionsNum: {

        tooltip: {
          //tip提示框
          // trigger: "item",
          // formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        label: {

        },
        series: [
          {
            type: 'sunburst',
            center: ['50%', '50%'],
            // radius: [0, '80%'],
            data: [],

            label: {
              rotate: 'radial',

            },
            clockwise: true,
            itemStyle: {
              borderRadius: 8,
              borderWidth: 2
            },
            levels: [
              {
                // rotate: 0,
                radius: [0, '10%'],
                label: {
                  rotate: 0,
                  // formatter: (a) => {
                  //   return a.name +
                  //     '\n\n' + a.value.toFixed(2) + '㎡'
                  // },
                },
              },
              {

                radius: ['25%', '50%'],
                label: {
                  rotate: 0,
                },
                tooltip: {
                  //tip提示框
                  trigger: "item",
                  // formatter: "{a} <br/>{b} : {c} ",
                  formatter: (a) => {
                    console.log(a, 'aaaaaa')
                    // console.log(a.value / a.treePathInfo[0].value * 100)
                    let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                    return a.name + " : " + num + "%"
                  }

                },
              },
              {
                radius: ['55%', '80%'],
                label: {
                  rotate: 0,
                  position: 'outside'
                }
              },

            ]
          }
        ]
      },
      bottomHeight: 450,
      //2
      Shop_Same_FormatBar: {
        grid: {
          top: '18%',
          // // 网格的左侧位置
          // left: '10%',
          // // 网格的右侧位置
          // right: '10%',
          // // 网格的顶部位置
          // top: '10%',
          // // 网格的底部位置
          // bottom: '10%',
          // // 网格的 ContainLabel 选项，可以使得坐标轴的标签不会被标签渲染到的区域覆盖
          // containLabel: true
        },
        xAxis: {
          name: '商铺数量',
          nameLocation: 'center',
          nameGap: 30,
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "value",
        },
        label: {
          show: true,
          position: "inside",
          formatter: (a) => {
            if (a.value > 0) {
              return `${a.value}`;
            } else {
              return ''
            }
          },
        },
        yAxis: {
          name: '',
          nameLocation: 'end',
          nameGap: 0,
          nameTextStyle: {
            width: 200,
            align: 'right'
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          type: "category",
          data: []
        },
        tooltip: {
          show: true,
          // trigger: 'item',
          // formatter: "{a},{b},{c}{d}{e}",
        },
        legend: {
          autoPosition: true,
          // textStyle: {
          //   width: 200,
          //   height: 50,
          //   color: "#333",
          // },
          //图例系列
          show: true,
          // top: 0,
          title: "楼栋",
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        series: [],
      },
      Shop_Same_FormatPie: {
        tooltip: {
        },
        label: {
        },
        series: [
          {
            type: 'sunburst',
            center: ['50%', '50%'],
            // radius: [0, '80%'],
            data: [],

            label: {
              rotate: 'radial',
              formatter: (a) => {
                return a.name + '\n' + a.value
              }
            },
            clockwise: true,
            itemStyle: {
              borderRadius: 8,
              borderWidth: 2
            },
            levels: [
              {
                // rotate: 0,
                radius: [0, '10%'],

                label: {
                  rotate: 0,
                  formatter: (a) => {
                    return a.name + '\n\n' + a.value
                  }
                },
              },
              {

                radius: ['15%', '30%'],
                label: {
                  rotate: 0,
                },
                // tooltip: {
                //   //tip提示框
                //   trigger: "item",
                //   // formatter: "{a} <br/>{b} : {c} ",
                //   formatter: (a) => {
                //     console.log(a, 'aaaaaa')
                //     // console.log(a.value / a.treePathInfo[0].value * 100)
                //     let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                //     return a.name + " : " + num + "%"
                //   }

                // },
              },
              {
                radius: ['35%', '50%'],
                label: {
                  rotate: 0,
                  // position: 'outside'
                }
              },
              {
                radius: ['55%', '70%'],
                label: {
                  rotate: 0,
                  position: 'outside'
                }
              },

            ]
          }
        ]
      },
      //3
      Shop_Same_BuildingBar: {
        grid: {
          left: 100,
          top: '18%',
        },
        xAxis: {
          name: '商铺数量',
          nameLocation: 'middle',
          nameGap: 30,
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "value",
        },
        label: {
          show: true,
          position: "inside",
          formatter: (a) => {
            if (a.value > 0) {
              return `${a.value}`;
            } else {
              return ''
            }
          },
        },
        yAxis: {
          name: '业态名称',
          nameLocation: 'end',
          nameGap: 0,
          nameTextStyle: {
            width: 200,
            align: 'right'
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          type: "category",
          data: []
        },
        tooltip: {},
        legend: {
          //图例系列
          show: true,
          top: 0,
          title: "楼栋",
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        series: [],
      },
      Shop_Same_BuildingPie: {
        tooltip: {
        },
        label: {
        },
        series: [
          {
            type: 'sunburst',
            center: ['50%', '50%'],
            // radius: [0, '80%'],
            data: [],

            label: {
              rotate: 'radial',
              formatter: (a) => {
                return a.name + '\n' + a.value
              }
            },
            clockwise: true,
            itemStyle: {
              borderRadius: 8,
              borderWidth: 2
            },
            levels: [
              {
                radius: [0, '10%'],
                label: {
                  rotate: 0,
                  formatter: (a) => {
                    return a.name + '\n\n' + a.value
                  }
                },
              },
              {

                radius: ['15%', '30%'],
                label: {
                  rotate: 0,
                },
              },
              {
                radius: ['35%', '50%'],
                label: {
                  rotate: 0,
                  // position: 'outside'
                }
              },
              {
                radius: ['55%', '70%'],
                label: {
                  rotate: 0,
                  position: 'outside'
                }
              },

            ]
          }
        ]
      },

      acreageOption: {
        grid: {
          left: 80,
          top: 85,
        },
        xAxis: {
          name: '商铺数量',
          nameLocation: 'middle',
          nameGap: 30,
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "value",

        },
        yAxis: {

          name: '楼栋信息',
          nameLocation: 'end',
          nameGap: 0,
          nameTextStyle: {
            width: 200,
            align: 'right'
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "category",
          data: [],
        },
        label: {
          show: true,
          position: "inside",
          formatter: (a) => {
            if (a.value > 0) {
              return `${a.value}`;
            } else {
              return ''
            }
          },
        },
        tooltip: {},
        legend: {
          //图例系列
          show: true,
          top: 0,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        series: [],
      },

      tablepieOption: {
        grid: {
          left: 50,
          top: 80,
        },
        tooltip: {},

        label: {
          show: true,
          // position: "bottom",
          formatter: (a) => {
            return a.value ? a.name + '\n' + a.value : ''
          }
        },
        series: [
          {
            type: 'sunburst',
            center: ['50%', '50%'],
            // radius: [0, '80%'],
            data: [],

            label: {
              rotate: 'radial',

            },
            clockwise: true,
            itemStyle: {
              borderRadius: 8,
              borderWidth: 2
            },
            levels: [
              {
                radius: [0, '30%'],
                label: {
                  show: true,
                  rotate: 0,
                  formatter: (a) => {
                    return a.value ? a.name + '\n\n' + a.value : ''
                  }
                },
              },
              {

                radius: ['35%', '50%'],
                label: {
                  rotate: 0,
                },
              },
              {
                radius: ['55%', '70%'],
                label: {
                  rotate: 0,
                  position: 'outside',
                }
              },

            ]
          }
        ]

      },
      tablepieInfoArr: [],//1面积信息
      Shop_Same_FormatPieInfoArr: [],//2
      Shop_Same_BuildingPieInfoArr: [],//3
      formatCountInfoArr: [],//业态占比
    };
  },
  computed: {
    formatCountOptionsNumView2() {
      let arr = this.formatCountInfoArr
      if (arr.length == 1) {
        return `<span>${arr[0].name}</span>：<span style="color:#D1100B">${arr[0].value || 0}</span>`
      }
      if (arr.length == 2) {
        let num = (arr[1].value / arr[0].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}：<span style="color:#D1100B">${arr[1].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span>`
      }
      if (arr.length == 3) {
        let num = (arr[2].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[2].value / arr[1].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}：<span style="color:#D1100B">${arr[2].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span>`
      }
      if (arr.length == 4) {
        let num = (arr[3].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[3].value / arr[1].value * 100).toFixed(2)
        let num3 = (arr[3].value / arr[2].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}>${arr[3].name}：<span style="color:#D1100B">${arr[3].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span><br/>${arr[2].name}占比：<span style="color:#D1100B">${num3}%</span>`
      }
      return ''
    },
    formatCountOptionsNumView() {
      let arr = this.formatCountInfoArr
      if (arr.length == 1) {
        return `<span>${arr[0].name}</span>：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span>`
      }
      if (arr.length == 2) {
        let num = (arr[1].value / arr[0].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span></br>${arr[1].name}：<span style="color:#D1100B">${arr[1].value?.toFixed(2) + '㎡' || '0㎡'}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span>`
      }
      if (arr.length == 3) {
        let num = (arr[2].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[2].value / arr[1].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span></br>${arr[1].name}>${arr[2].name}：<span style="color:#D1100B">${arr[2].value?.toFixed(2) + '㎡' || '0㎡'}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span>`
      }
      if (arr.length == 4) {
        let num = (arr[3].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[3].value / arr[1].value * 100).toFixed(2)
        let num3 = (arr[3].value / arr[2].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span></br>${arr[1].name}>${arr[2].name}>${arr[3].name}：<span style="color:#D1100B">${arr[3].value?.toFixed(2) + '㎡' || '0㎡'}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span><br/>${arr[2].name}占比：<span style="color:#D1100B">${num3}%</span>`
      }
      return ''
    },
    // 3
    Shop_Same_BuildingPieInfoView() {
      let arr = this.Shop_Same_BuildingPieInfoArr
      if (arr.length == 1) {
        return `<span>${arr[0].name}</span>：<span style="color:#D1100B">${arr[0].value || 0}</span>`
      }
      if (arr.length == 2) {
        let num = (arr[1].value / arr[0].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}：<span style="color:#D1100B">${arr[1].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span>`
      }
      if (arr.length == 3) {
        let num = (arr[2].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[2].value / arr[1].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}：<span style="color:#D1100B">${arr[2].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span>`
      }
      if (arr.length == 4) {
        let num = (arr[3].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[3].value / arr[1].value * 100).toFixed(2)
        let num3 = (arr[3].value / arr[2].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}>${arr[3].name}：<span style="color:#D1100B">${arr[3].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span><br/>${arr[2].name}占比：<span style="color:#D1100B">${num3}%</span>`
      }
      return ''

    },
    //2
    Shop_Same_FormatPieInfoView() {
      let arr = this.Shop_Same_FormatPieInfoArr
      if (arr.length == 1) {
        return `<span>${arr[0].name}</span>：<span style="color:#D1100B">${arr[0].value || 0}</span>`
      }
      if (arr.length == 2) {
        let num = (arr[1].value / arr[0].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}：<span style="color:#D1100B">${arr[1].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span>`
      }
      if (arr.length == 3) {
        let num = (arr[2].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[2].value / arr[1].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}：<span style="color:#D1100B">${arr[2].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span>`
      }
      if (arr.length == 4) {
        let num = (arr[3].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[3].value / arr[1].value * 100).toFixed(2)
        let num3 = (arr[3].value / arr[2].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}>${arr[3].name}：<span style="color:#D1100B">${arr[3].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span><br/>${arr[2].name}占比：<span style="color:#D1100B">${num3}%</span>`
      }
      return ''

    },
    //1
    tablepieOptionPieInfoView() {
      let arr = this.tablepieInfoArr
      if (arr.length == 1) {
        return `<span>${arr[0].name}</span>：<span style="color:#D1100B">${arr[0].value || 0}</span>`
      }
      if (arr.length == 2) {
        let num = (arr[1].value / arr[0].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}：<span style="color:#D1100B">${arr[1].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span>`
      }
      if (arr.length == 3) {
        let num = (arr[2].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[2].value / arr[1].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}：<span style="color:#D1100B">${arr[2].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span>`
      }
      if (arr.length == 4) {
        let num = (arr[3].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[3].value / arr[1].value * 100).toFixed(2)
        let num3 = (arr[3].value / arr[2].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}>${arr[3].name}：<span style="color:#D1100B">${arr[3].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span><br/>${arr[2].name}占比：<span style="color:#D1100B">${num3}%</span>`
      }
      return ''

    }
  },
  methods: {
    formatCountClick(a) {
      if (this.MG_BaseInfoPageInfoNum === 1) {
        if (a.name === '商铺总面积') {
          this.formatCountInfoArr.pop()
        } else {
          this.formatCountInfoArr = [...a.treePathInfo]
        }
      } else if (this.MG_BaseInfoPageInfoNum === 2) {

        if (a.name === '商铺总数量') {
          this.formatCountInfoArr.pop()
        } else {
          this.formatCountInfoArr = [...a.treePathInfo]
        }
      }


    },
    Shop_Same_BuildingPieClick(a) {
      if (a.name === '商铺总数量') {
        this.Shop_Same_BuildingPieInfoArr.pop()
      } else {
        this.Shop_Same_BuildingPieInfoArr = [...a.treePathInfo]
      }

    },
    Shop_Same_FormatPieClick(a) {
      if (a.name === '商铺总数量') {
        this.Shop_Same_FormatPieInfoArr.pop()
      } else {
        this.Shop_Same_FormatPieInfoArr = [...a.treePathInfo]
      }
    },
    tablepieOptionClick(a) {
      if (a.name === '商铺总数量') {
        this.tablepieInfoArr.pop()
      } else {
        this.tablepieInfoArr = [...a.treePathInfo]
      }
    },
    ShopChange() {
      if (this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Format && this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Format.length > 0) {
        // 柱状图
        this.Shop_Same_FormatBar.legend.data = []
        this.Shop_Same_FormatBar.series = []
        this.Shop_Same_FormatBar.yAxis.data = []
        this.Shop_Same_FormatBar.yAxis.name = this.isLou ? '楼栋名称' : '楼层名称'
        this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Format[0].children[0].children.forEach(val => {
          this.Shop_Same_FormatBar.yAxis.data.push(val.name)
        })
        this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Format.forEach((item) => {
          item.children.forEach(item2 => {
            this.Shop_Same_FormatBar.legend.data.push(item2.name)
            this.Shop_Same_FormatBar.series.push({
              name: item2.name,
              type: "bar",
              stack: item.name,
              emphasis: {
                focus: "series",
              },
              data: item2.children ? item2.children : [],
            })
          })
        })

        if (this.Shop_Same_FormatBar.legend.data.length > 14) {
          let num = Math.ceil(this.Shop_Same_FormatBar.legend.data.length / 7)
          let topnum = 0
          for (let i = 0; i < num; i++) {
            topnum = 30 + topnum
          }
          this.Shop_Same_FormatBar.grid.top = topnum
        } else {
          this.Shop_Same_FormatBar.grid.top = 90
        }


        console.log(this.Shop_Same_FormatBar.grid.top, 'this.Shop_Same_FormatBar.grid.top')
        let narr = JSON.parse(JSON.stringify(this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Format))
        this.Shop_Same_FormatPie.tooltip = {
          //tip提示框
          trigger: "item",
          formatter: (a) => {

            if (a.treePathInfo.length == 1) {
              return `${a.name}：${a.value ? a.value.toFixed(2) : 0}`
            }
            if (a.treePathInfo.length == 2) {
              let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
              return `${a.name}：${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%`
            }
            if (a.treePathInfo.length == 3) {
              let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
              let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
              return `${a.name}：${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
            }
            if (a.treePathInfo.length == 4) {
              let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
              let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
              let num3 = (a.value / a.treePathInfo[2].value * 100).toFixed(2)
              return `${a.name}：${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%<br/>${a.treePathInfo[2].name}占比：${num3}%`
            }
          }
        }
        this.Shop_Same_FormatPie.series[0].data = [...narr];
        this.Shop_Same_FormatPie.series[0].name = '商铺总数量'
        let num = 0
        narr.forEach(item => {
          num = item.value + num
        })
        this.Shop_Same_FormatPieInfoArr = []
        this.Shop_Same_FormatPieInfoArr.push({
          name: '商铺总数量',
          value: num
        })
        console.log(this.Shop_Same_FormatPie, 'this.Shop_Same_Format')
      }
    },
    ShopChange2() {
      if (this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Building && this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Building.length > 0) {
        this.Shop_Same_BuildingBar.legend.data = []
        this.Shop_Same_BuildingBar.series = []
        this.Shop_Same_BuildingBar.yAxis.data = []
        this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Building[0].children.forEach(item => {
          item.children.forEach(item2 => {
            this.Shop_Same_BuildingBar.yAxis.data.push(item2.name)
          })
        })
        this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Building.forEach(item => {
          let arr = []
          this.Shop_Same_BuildingBar.legend.data.push(item.name)
          item.children.forEach(item2 => {
            item2.children.forEach(item3 => {
              arr.push(item3)
            })
          })
          console.log(arr, 'arr')
          this.Shop_Same_BuildingBar.series.push({
            name: item.name,
            type: "bar",
            stack: '1',
            emphasis: {
              focus: "series",
            },
            data: arr,
          })
        })
        if (this.Shop_Same_BuildingBar.legend.data.length > 30) {
          let num = Math.ceil(this.Shop_Same_BuildingBar.legend.data.length / 13)
          console.log(num, 'num')
          let topnum = 0
          for (let i = 0; i < num; i++) {
            topnum = 30 + topnum
          }
          this.Shop_Same_BuildingBar.grid.top = topnum + 20
        } else {
          this.Shop_Same_BuildingBar.grid.top = 90
        }
        console.log(this.Shop_Same_BuildingBar.grid.top)










        let narr = JSON.parse(JSON.stringify(this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Building))

        this.Shop_Same_BuildingPie.tooltip = {
          //tip提示框
          trigger: "item",
          formatter: (a) => {

            if (a.treePathInfo.length == 1) {
              return `${a.name}：${a.value ? a.value.toFixed(2) : 0}`
            }
            if (a.treePathInfo.length == 2) {
              let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
              return `${a.name}：${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%`
            }
            if (a.treePathInfo.length == 3) {
              let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
              let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
              return `${a.name}：${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
            }
            if (a.treePathInfo.length == 4) {
              let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
              let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
              let num3 = (a.value / a.treePathInfo[2].value * 100).toFixed(2)
              return `${a.name}：${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%<br/>${a.treePathInfo[2].name}占比：${num3}%`
            }
          }
        }
        this.Shop_Same_BuildingPie.series[0].data = [...narr];
        this.Shop_Same_BuildingPie.series[0].name = '商铺总数量'
        let num = 0
        narr.forEach(item => {
          num = item.value + num
        })
        this.Shop_Same_BuildingPieInfoArr = []
        this.Shop_Same_BuildingPieInfoArr.push({
          name: '商铺总数量',
          value: num
        })
        console.log(this.Shop_Same_BuildingPie, 'this.Shop_Same_FormatPie')
      }


    },
    // 比较
    findLargest(arr) {

      let largest = 0;
      arr.forEach(item => {
        if (item > largest) {
          largest = item;
        }
      })
      return largest;

    },
    // 最下面三个楼栋
    GetMG_BaseInfo_ShopStatsReturnData() {
      this.MG_BaseInfo_ShopStatsReturnDataLoading = true
      this.$http.post("/Base_ProjectManage/Project_DataAnalysis/GetMG_BaseInfo_ShopStatsReturnData", {
        ProjectId: this.projectId,
        "BuildingIdList": this.BuildingIdList,
        "LayerList": this.LayerList,
        "IsChooseBuilding": !this.isLou
      }).then(res => {
        this.MG_BaseInfo_ShopStatsReturnDataLoading = false
        if (res.Success) {
          this.MG_BaseInfo_ShopStatsReturnData = res.Data || {}
          let height = 0
          let ynum = this.MG_BaseInfo_ShopStatsReturnData.ShopFormatInfoList.length
          let nnum = this.MG_BaseInfo_ShopStatsReturnData.Shop_Same_Building.length
          if (ynum > nnum) {
            for (let i = 0; i < ynum; i++) {
              height = height + 30
            }
          } else {
            for (let i = 0; i < nnum; i++) {
              height = height + 30
            }
          }
          this.bottomHeight = height <= 450 ? 450 : height
          if (this.MG_BaseInfo_ShopStatsReturnData.Shop_AreaRange_Count && this.MG_BaseInfo_ShopStatsReturnData.Shop_AreaRange_Count.length > 0) {
            this.acreageOption.legend.data = []
            this.acreageOption.series = []
            this.acreageOption.yAxis.data = []
            this.acreageOption.yAxis.name = this.isLou ? '楼栋名称' : '楼层名称'
            this.MG_BaseInfo_ShopStatsReturnData.Shop_AreaRange_Count[0].children.forEach(item => {
              this.acreageOption.yAxis.data.push(item.name)
            })
            this.MG_BaseInfo_ShopStatsReturnData.Shop_AreaRange_Count.forEach(item => {
              this.acreageOption.legend.data.push(item.name)
              this.acreageOption.series.push({
                name: item.name,
                type: "bar",
                stack: "total",

                emphasis: {
                  focus: "series",
                },
                data: item.children,
              })
            })
            //旭日图
            let narr = JSON.parse(JSON.stringify(this.MG_BaseInfo_ShopStatsReturnData.Shop_AreaRange_Count))
            let num = 0
            narr.forEach(item => {
              num = item.value + num
            })
            this.tablepieInfoArr = []
            this.tablepieInfoArr.push({
              name: '商铺总数量',
              value: num
            })
            this.tablepieOption.tooltip = {
              //tip提示框
              trigger: "item",
              formatter: (a) => {

                if (a.treePathInfo.length == 1) {
                  return `${a.name}：${a.value ? a.value.toFixed(2) : 0}`
                }
                if (a.treePathInfo.length == 2) {
                  let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                  return `${a.name}：${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%`
                }
                if (a.treePathInfo.length == 3) {
                  let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                  let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
                  return `${a.name}：${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
                }
              }
            }
            this.tablepieOption.series[0].data = narr;
            this.tablepieOption.series[0].name = '商铺总数量'
          }

          this.ShopChange()
          this.ShopChange2()



        } else {
          this.$message.error('获取商铺统计数据失败');
        }
      }).catch(() => {
        this.MG_BaseInfo_ShopStatsReturnDataLoading = false
        this.MG_BaseInfo_ShopStatsReturnData = {}
        this.$message.error('获取商铺统计数据失败');
      })
    },
    // 商铺活跃度变化
    getMG_BaseInfo_ShopActivity() {
      this.MG_BaseInfo_ShopActivityLoading = true
      this.$http.post("/Base_ProjectManage/Project_DataAnalysis/GetMG_BaseInfo_ShopActivity", {
        ProjectId: this.projectId,
        "BuildingIdList": this.BuildingIdList,
        "LayerList": this.LayerList,
        "IsChooseBuilding": !this.isLou
      }).then(res => {
        this.MG_BaseInfo_ShopActivityLoading = false

        if (res.Success) {
          this.MG_BaseInfo_ShopActivity = res.Data || []
          if (this.MG_BaseInfo_ShopActivity && this.MG_BaseInfo_ShopActivity.length > 0) {
            this.coverageRateTimeOptions.xAxis.data = []
            this.coverageRateTimeOptions.series = []
            this.coverageRateTimeOptions.legend.data = []
            this.MG_BaseInfo_ShopActivity[0].value.forEach(item => {
              this.coverageRateTimeOptions.xAxis.data.push(item.name)
            })
            this.MG_BaseInfo_ShopActivity.forEach(item => {
              this.coverageRateTimeOptions.legend.data.push(item.name)
              this.coverageRateTimeOptions.series.push({
                name: item.name,
                type: 'bar',
                stack: "total",
                // barWidth: '50px',
                emphasis: {
                  focus: "series",
                },
                data: [...item.value]
              })

            })
          }

        } else {
          this.$message.error('获取商铺活跃度变化失败');
        }
      }).catch(() => {
        this.MG_BaseInfo_ShopActivityLoading = false
        this.MG_BaseInfo_ShopActivity = []
        this.$message.error('获取商铺活跃度变化失败');
      })
    },
    //业态占比切换
    changeType() {
      if (this.MG_BaseInfoPageInfo.FAP_Shop) {
        if (this.MG_BaseInfoPageInfoNum === 1) {
          let arr = []
          let colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
          this.MG_BaseInfoPageInfo.FAP_Shop.forEach(item => {
            let num = 0
            item.shopAreaList.forEach((val, index) => {
              num = val.value + num
              val.itemStyle = {
                color: colorList[index > colorList.length - 1 ? index - colorList.length - 1 : index]
              }
            })
            let obj = {
              name: item.formatCalss,
              value: item.shopArea ? item.shopArea : num ? Number(num.toFixed(2)) : 0,
              children: [...item.shopAreaList]
            }
            arr.push(obj)
          })
          console.log(arr)
          this.formatCountOptionsNum.series[0].data = arr;
          this.formatCountOptionsNum.series[0].name = '商铺总面积';
          this.formatCountOptionsNum.label = {
            show: true,
            rotate: 'radial',
            formatter: (a) => {
              return a.name +
                '\n' + a.value.toFixed(2) + '㎡'
            },
          }
          this.formatCountOptionsNum.tooltip = {
            //tip提示框
            trigger: "item",

            formatter: (a) => {

              if (a.treePathInfo.length == 1) {
                return `${a.name}：${a.value ? a.value.toFixed(2) : 0}㎡`
              }
              if (a.treePathInfo.length == 2) {
                let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                return `${a.name}：${a.value + '㎡'}<br/>${a.treePathInfo[0].name}占比：${num}%`
              }
              if (a.treePathInfo.length == 3) {
                let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
                return `${a.name}：${a.value + '㎡'}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
              }

            }

          }
          this.formatCountOptionsNum.series[0].levels[0].label = {
            rotate: 0,
            formatter: (a) => {
              return a.name +
                '\n\n' + a.value.toFixed(2) + '㎡'
            },
          }
          let num = 0
          this.MG_BaseInfoPageInfo.FAP_Shop.forEach(item => {
            num = num + item.shopArea
          })
          this.formatCountInfoArr = [{
            name: '商铺总面积',
            value: num
          }]
        } else if (this.MG_BaseInfoPageInfoNum === 2) {
          let arr = []
          let colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
          this.MG_BaseInfoPageInfo.FAP_Shop.forEach(item => {
            let num = 0
            item.shopCountList.forEach((val, index) => {
              val.itemStyle = {
                color: colorList[index > colorList.length - 1 ? index - colorList.length - 1 : index]
              }
              num = val.value + num
            })
            let obj = {
              name: item.formatCalss,
              value: item.shopCount ? item.shopCount : num ? Number(num.toFixed(2)) : 0,
              children: [...item.shopCountList]
            }
            arr.push(obj)
          })
          this.formatCountOptionsNum.series[0].name = '商铺总数量';
          this.formatCountOptionsNum.series[0].data = arr;
          this.formatCountOptionsNum.label = {
            show: true,
            rotate: 'radial',
            formatter: `{b}\n{c}`
          }
          this.formatCountOptionsNum.tooltip = {
            //tip提示框
            trigger: "item",

            formatter: (a) => {

              if (a.treePathInfo.length == 1) {
                return `${a.name}：${a.value ? a.value.toFixed(2) : 0}`
              }
              if (a.treePathInfo.length == 2) {
                let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                return `${a.name}:${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%`
              }
              if (a.treePathInfo.length == 3) {
                let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
                return `${a.name}：${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
              }

            }

          }
          this.formatCountOptionsNum.series[0].levels[0].label = {
            rotate: 0,
            formatter: (a) => {
              return a.name +
                '\n\n' + a.value
            },
          }
          let num = 0
          this.MG_BaseInfoPageInfo.FAP_Shop.forEach(item => {
            num = num + item.shopCount
          })
          this.formatCountInfoArr = [{
            name: '商铺总数量',
            value: num
          }]
        }

      }


    },
    //获取TOP10门店数据
    getMG_BaseInfoPageTopShopBaseInfo() {
      this.MG_BaseInfoPageTopShopBaseInfoLoading = true
      this.$http.post("/Base_ProjectManage/Project_DataAnalysis/GetMG_BaseInfoPageTopShopBaseInfo", {
        ProjectId: this.projectId,
        "BuildingIdList": this.BuildingIdList,
        "LayerList": this.LayerList
      }).then(res => {

        this.MG_BaseInfoPageTopShopBaseInfoLoading = false
        if (res.Success) {
          this.MG_BaseInfoPageTopShopBaseInfo = res.Data || []
          this.TOP_Options.yAxis.data = []
          this.TOP_Options.series[0].data = []
          this.MG_BaseInfoPageTopShopBaseInfo.forEach(item => {
            this.TOP_Options.yAxis.data.unshift(item.name)
            this.TOP_Options.series[0].data.unshift(item)
          })
        } else {
          this.$message.error('获取当前TOP10门店数据失败');
        }
      }).catch(() => {
        this.MG_BaseInfoPageTopShopBaseInfoLoading = false
        this.MG_BaseInfoPageTopShopBaseInfo = []
        this.$message.error('获取当前TOP10门店数据失败');
      })
    },
    //获取基本数据与业态
    getMG_BaseInfoPageInfo() {
      this.MG_BaseInfoPageInfoLoading = true
      this.$http.post("/Base_ProjectManage/Project_DataAnalysis/GetMG_BaseInfoPageInfo", {
        ProjectId: this.projectId,
        "BuildingIdList": this.BuildingIdList,
        "LayerList": this.LayerList
      }).then(res => {

        this.MG_BaseInfoPageInfoLoading = false
        if (res.Success) {
          this.MG_BaseInfoPageInfo = res.Data || {}
          this.coverageRateOptions.series[0].data = [
            {
              name: this.LayerList.length > 0 ? "当前楼层总面积" : "当前楼栋总面积",
              value: this.MG_BaseInfoPageInfo.BuildingTotalArea,
              itemStyle: {
                color: "orange",
              },
              label: {
                show: true,
                fontSize: "14",
                lineHeight: 20,
                formatter: `{b}\n{c}㎡`,
              },

            },
            {
              name: "当前经营总面积",
              value: this.MG_BaseInfoPageInfo.OperatingTotalArea,
              itemStyle: {
                color: "green",
              },
              label: {
                show: true,
                fontSize: "14",
                lineHeight: 20,
                formatter: `{b}\n{c}㎡`,
              },
            },
            {
              name: "当前商铺总面积",
              value: this.MG_BaseInfoPageInfo.ShopTotalArea,
              itemStyle: {
                color: "mediumpurple",
              },
              label: {
                show: true,
                fontSize: "14",
                lineHeight: 20,
                formatter: `{b}\n{c}㎡`,
              },
            },
            {
              name: "得铺率",
              value: 0,
              label: {
                show: true,
                fontSize: "15",
                fontWeight: "bold",
                formatter: `\n入驻商铺数量\n\n${this.MG_BaseInfoPageInfo.ShopTotalNumber}\n\n得铺率\n\n${this.MG_BaseInfoPageInfo.ShopCoverageRate}%`,
                position: "center",
                color: "#9b9999",
              },
            },
          ]
          this.changeType()
        } else {
          this.$message.error('得铺率与业态数据获取失败');
        }
      }).catch(() => {
        this.MG_BaseInfoPageInfoLoading = false
        this.MG_BaseInfoPageInfo = {}
        this.changeType()
        this.$message.error('得铺率与业态数据获取失败');
      })
    },
    //获取楼层
    async getMG_BaseInfo_BuildingsOrLayers(id) {

      await this.$http.post("/Base_ProjectManage/Project_DataAnalysis/GetMG_BaseInfo_BuildingsOrLayers", {
        "ProjectId": this.projectId,
        "BuildingId": id
      }).then(res => {
        if (res.Success) {
          this.$set(this.itemLou, 'floorList', res.Data || [])
        } else {
          this.$message.error('获取楼层数据失败')
        }
      })

    },
    //获取楼栋
    getLouData() {
      this.louListLoading = true
      this.$http.post("/Base_ProjectManage/Project_DataAnalysis/GetMG_BaseInfo_BuildingsOrLayers", {
        "ProjectId": this.projectId,
        // "BuildingId": []
      }).then((res) => {
        this.louListLoading = false
        if (res.Success) {
          this.louList = res.Data
        } else {
          this.louList = []
          this.$message.error('获取楼栋数据失败')
        }

      });
    },
    // 从楼层返回到楼栋
    blockLou() {
      this.isLou = true;
      this.BuildingIdList = [this.itemLou.Key]
      this.LayerList = []
      this.getMG_BaseInfoPageInfo()
      this.getMG_BaseInfoPageTopShopBaseInfo()
      this.getMG_BaseInfo_ShopActivity()
      this.GetMG_BaseInfo_ShopStatsReturnData()
      this.itemLou = {}
    },
    // 点击楼栋进入楼层
    async louClick(element) {
      this.isLou = false;
      this.itemLou = JSON.parse(JSON.stringify(element))
      await this.getMG_BaseInfo_BuildingsOrLayers(element.Key)
      this.louList.forEach(item => {
        if (item.Key === element.Key) {
          item.IsTrue = true;
        } else {
          item.IsTrue = false;
        }
      })

    },
    handleBack() {
      this.$emit("changePageblock", "MG_BaseInfoPage");
    },

  },
  created() {
    this.getLouData()
    this.MG_BaseInfoPageInfoIs = this.typeList[0].DicName
    this.Shop_Same_Format = this.typeList[0].DicName
    this.Shop_Same_Building = this.typeList[0].DicName
  },
  watch: {
    homeData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.FAT_Shop && val.FAT_Shop.length > 0) {
          this.tableColumn = [
            {
              ShopFormatName: "面积/数量",
            },
            ...val.FAT_Shop,
          ];
          let numObj = {
            "面积/数量": "数量",
          };
          let areaObj = {
            "面积/数量": "面积",
          };
          val.FAT_Shop.forEach((item) => {
            this.$set(numObj, item.ShopFormatName, item.ShopCount);
            this.$set(areaObj, item.ShopFormatName, item.ShopArea ? item.ShopArea + '㎡' : '0㎡');
          });
          this.tableData = [areaObj, numObj];
        }
      },
    },
    louList: {
      //选中楼栋时监听
      deep: true,
      handler(val) {
        if (!this.isLou) {
          return
        }
        console.log(val, '触发了louList')
        this.IsChooseBuilding = true
        this.BuildingIdList = []
        this.LayerList = []

        let arr = val.filter((item) => {
          return item.IsTrue;
        });

        if (arr.length === 1) {
          this.BuildingIdList = [arr[0].Key]
          this.getMG_BaseInfoPageInfo()
          this.getMG_BaseInfoPageTopShopBaseInfo()
          this.getMG_BaseInfo_ShopActivity()
          this.GetMG_BaseInfo_ShopStatsReturnData()
        } else if (arr.length > 1) {

          arr.forEach(item => {
            this.BuildingIdList.push(item.Key)
          })
          this.getMG_BaseInfoPageInfo()
          this.getMG_BaseInfoPageTopShopBaseInfo()
          this.getMG_BaseInfo_ShopActivity()
          this.GetMG_BaseInfo_ShopStatsReturnData()

        } else if (arr.length === 0) {
          this.getMG_BaseInfoPageInfo()
          this.getMG_BaseInfoPageTopShopBaseInfo()
          this.getMG_BaseInfo_ShopActivity()
          this.GetMG_BaseInfo_ShopStatsReturnData()
        }
      },

    },
    itemLou: {
      //选中楼层时监听
      deep: true,
      handler(val) {
        if (val.floorList) {

          this.LayerList = []
          let arr = val.floorList.filter((item) => {
            return item.IsTrue;
          });
          arr.forEach(item => {
            this.LayerList.push(item.Key)
          })
          this.BuildingIdList = [val.Key]
          this.getMG_BaseInfoPageInfo()
          this.getMG_BaseInfoPageTopShopBaseInfo()
          this.getMG_BaseInfo_ShopActivity()
          this.GetMG_BaseInfo_ShopStatsReturnData()


        }
      }
    },

  },
};
</script>

<style scoped lang="scss">
.box {
  padding: 0px;
  box-sizing: border-box;

}

.InfoCard {
  border-radius: 10px;
  padding: 0px 5px;
  box-sizing: border-box;
  border: 5px solid #c3c3c3;
}

.dataStripe_title {
  display: block;
  line-height: 40px;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

.dataStripe_i {
  font-size: 40px;
  color: #ffffff;
  line-height: 90px;
  margin-left: 20px;
}

// 数据条卡片内显示值
.dataStripe_value {
  display: block;
  line-height: 50px;
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
}

::v-deep .el-checkbox-button.is-focus .el-checkbox-button__inner {
  border-color: #dcdfe6;
}

::v-deep .el-form-item {
  margin-bottom: 0;
}

::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;

  // ::after {
  //   width: 7px;
  //   height: 10px;
  // }
}

::v-deep .el-checkbox__inner::after {
  width: 5px;
  left: 6px;
  height: 10px;
}

::v-deep .el-table th {
  background-color: #D1100B;
  color: #fff;
}

::v-deep .el-table__body-wrapper {
  background-color: #D1100B;
}

::-webkit-scrollbar {
  width: 0px;

  background: transparent;
}

::v-deep .el-table td {
  background-color: #D1100B;
  padding: 0 !important;
  color: #fff;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table {

  //可设置胶囊宽高
  ::-webkit-scrollbar {
    height: 12px;
    width: 10px;
  }

  // 设置胶囊色
  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #f2706b;
  }

  // ::-webkit-scrollbar-thumb:hover {

  // }
}

::v-deep .el-table__row:hover td {
  background-color: #f2706b !important;
}
</style>
