<template>
  <!-- 编辑页面基础信息 -->
  <div class="shopBaseInfo" style="margin-top: 20px">
    <!-- <h3 style="padding-left: 10px">{{ title }}</h3> -->
    <div style="
        display: flex;
        justify-content: flex-end
        margin-left: 20px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d3cbcb;
        margin-bottom: 20px;
      ">
      <!--      <div style="display: flex">-->
      <!--        <img-->
      <!--          style="width: auto; height: 22px; margin-right: 5px"-->
      <!--          src="../../../../assets/jichu.png"-->
      <!--          alt=""-->
      <!--        />-->
      <!--        <span>商铺基础信息</span>-->
      <!--      </div>-->
      <div style="padding-right: 20px">
        <el-button v-if="shopBaseInfoDisabled == false" @click="editCancel" size="mini" type="primary" plain
          style="cursor: pointer; padding-right: 10px">取 消
        </el-button>
        <el-button v-else style="padding-right: 10px" size="mini" class="edit" type="primary" plain
          @click="shopBaseInfoDisabled = false">编辑
        </el-button>
        <el-button v-if="shopBaseInfoDisabled == false" size="mini" type="primary" plain class="save"
          @click="handlerSubmitSave">保存
        </el-button>
      </div>
    </div>
    <el-form ref="Form" size="mini" :model="Form" :rules="Rules" :hide-required-asterisk="false" label-position="right"
      label-width="auto" style="flex-wrap: wrap">
      <div style="flex: 8; display: flex; ">
        <div style="flex: 1; flex-shrink: 0">
          <el-row :gutter="8">
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple">
                <el-form-item ref="ShopCodeForm" label="商铺编号" prop="ShopCode" :rules="$rules.handle.ShopCode">
                  <el-input v-model="Form.ShopCode" autocomplete="off" @input="ShopCodeInputBlur"
                    oninput="value=value.replace(/[^a-zA-Z0-9_\-]/g, '')" :disabled="shopBaseInfoDisabled"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <el-form-item label="商铺名称" prop="ShopName" :rules="$rules.handle.ShopName">
                  <el-input v-model="Form.ShopName" autocomplete="off" :disabled="shopBaseInfoDisabled">
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--             :rules="$rules.handle.ShopArea"-->
                <el-form-item label="商铺面积" prop="ShopArea">
                  <el-input v-model="Form.ShopArea" autocomplete="off" oninput="value=value.replace(/[^\d.]/g,'')"
                    :disabled="shopBaseInfoDisabled">
                    <span slot="suffix">m²</span>
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--            :rules="$rules.handle.ShopBrand"-->
                <el-form-item label="品牌信息" prop="ShopBrand">
                  <el-input v-model="Form.ShopBrand" autocomplete="off" :disabled="shopBaseInfoDisabled">
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
            <!--        布局站位-->
            <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <el-form-item label="" prop=""></el-form-item>
              </div>
            </el-col>

            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--            :rules="$rules.handle.ShopFormatClass"-->
                <el-form-item label="业态级别" prop="ShopFormatClass">
                  <!--
                    @clear="clear('ShopFormatClass')"
                    @click="ShopFormatClassClick" -->
                  <SelectOptions ref="SelectOptions" :optionsType="'业态级别'" :disabled="shopBaseInfoDisabled"
                    @handleSearchList="handleSearchList" :searchList="ShopFormatClassOptions" />
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--            :rules="$rules.handle.ShopFormatName"-->
                <el-form-item label="业态名称" prop="ShopFormatName">
                  <!--  :disabled="ShopFormatNameDisabled"
                @change="ShopFormatNameChange"
                @clear="clear('ShopFormatName')" -->
                  <SelectOptions @selectClick="ShopFormatNameSelectClick" :optionsType="'业态类型'" :moduleType="'基础信息'"
                    @handleSearchList="handleSearchList" ref="SelectOptionsShopFormatName"
                    :disabled="shopBaseInfoDisabled" :searchList="ShopFormatNameOptions" />
                </el-form-item>
              </div>
            </el-col>
            <!--        布局站位-->
            <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <el-form-item label="" prop=""></el-form-item>
              </div>
            </el-col>

            <!--        工程编号-->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--            :rules="$rules.handle.ProjectCode"-->
                <el-form-item label="工程编号" prop="ProjectCode">
                  <el-input v-model="Form.ProjectCode" autocomplete="off" :disabled="shopBaseInfoDisabled"></el-input>
                </el-form-item>
              </div>
            </el-col>

            <!--        楼栋-->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple">
                <!--             :rules="$rules.handle.BuildingId"-->
                <el-form-item label="楼栋编号" prop="BuildingId" :rules="[
                  {
                    required: true,
                    validator: validEmpty(
                      Form,
                      'BuildingId',
                      '请选择楼栋编号'
                    ),
                    trigger: 'change',
                  },
                ]">
                  <SelectOptions :optionsType="'楼栋'" @handleSearchList="handleSearchList" ref="SelectOptionsBuildingId"
                    :searchList="buildingOptions" :disabled="shopBaseInfoDisabled" :value="Form.BuildingCode" />
                </el-form-item>
              </div>
            </el-col>
            <!--        地上地下-->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--             :rules="$rules.handle.IsOnGround"-->
                <el-form-item label="地上/地下/屋面" prop="IsOnGround" :rules="$rules.handle.IsOnGround">
                  <!--
                    :disabled="IsOnGroundDisabled" @clear="clear('IsOnGround')" -->
                  <el-select v-model="Form.IsOnGround" placeholder="地上/地下/屋面" @change="IsOnGroundChange"
                    :disabled="shopBaseInfoDisabled">
                    <el-option v-for="v in IsOnGroundOptions" :key="v.value" :label="v.label" :value="v.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <!--        楼层-->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--            :rules="$rules.handle.Storey"-->
                <el-form-item label="楼层" prop="Storey" style="position: relative">
                  <el-input v-if="
                    this.Form.IsOnGround != 3 &&
                    this.Form.IsOnGround != '屋面'
                  " class="floorFirst" v-model="Form.Storey" autocomplete="off" type="number"
                    @input="StoreyInputChange" oninput="value=value.replace(/[\W]/g,'')"
                    :disabled="shopBaseInfoDisabled">
                  </el-input>
                  <el-input v-else disabled v-model="Form.Storey"></el-input>
                  <span v-if="Form.IsOnGround == 2 || Form.IsOnGround == '地下'"
                    style="position: absolute; left: 10px">-</span>
                  <!-- <span v-if="Form.IsOnGround == 3" style="position: absolute;right:5px;font-size: 1rem;">RF</span> -->
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <!--      备注-->
          <el-row :gutter="5" style="margin-bottom: 20px">
            <el-col :span="24">
              <div class="grid-content bg-purple-light" style="margin-top: 30px">
                <el-form-item label="备注">
                  <el-input type="textarea" v-model="Form.Remark" autocomplete="off" maxlength="200" :rows="7"
                    resize="none" show-word-limit :disabled="shopBaseInfoDisabled">
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="picUpload" style="
            width: 40%;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
          <Upload ref="Upload" :height="'450px'" :width="'450px'" :url="url" :isUploadType="true"
            :is_disabled="isFileDisabled" @uploadSuccess="uploadSuccessFileChange" @imageDelete="imageDelete"
            :fileData="fileListData" :disabledChange="shopBaseInfoDisabled" :uoloadTypeImage="uoloadTypeImage"></Upload>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
// 引入工具素具
import { IsOnGroundOptions } from "../mock.js";
// 上传组件
import Upload from "@/components/upload.vue";
// 下拉搜索列表组件
import SelectOptions from "@/components/SelectOptions.vue";

export default {
  name: "WorkspaceJsonShopBaseInfo",
  components: {
    Upload,
    SelectOptions,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      // uploadType 上传图片列表
      fileListData: [],
      // uploadType上传组件的图片
      uoloadTypeImage: "",
      // 是否禁止上传图片
      isFileDisabled: false,
      // 图片上传的地址
      url: "https://api.zytsaas.com/Base_Manage/Upload/ProjectImgOssUploadFileByForm?ProjectId='+this.entity.Id+'&Type=PC",
      // 传递过来得当前行数据
      rows: {},
      // 首页传递过来的项目，任务，应用id对象
      newTaskObj: {},
      // 商铺基础信息禁用
      shopBaseInfoDisabled: true,
      // 标题类型
      title: "",
      loading: false,
      show: false,
      // 表单
      Form: {},
      // 校验规则
      Rules: {},
      // 地上地下下拉数据
      IsOnGroundOptions,
      // 业态级别选中好的下拉数据 用于传参
      ShopFormatClassData: [],
      // 业态类型选中好的下拉数据 用于传参
      ShopFormatNameData: [],
      // 楼栋选中好的下拉数据 用于传参
      BuildingIdData: [],
      // 业态级别下拉数据
      // 楼栋信息
      buildingOptions: [],
      // 业态级别
      ShopFormatClassOptions: [],
      // 业态名称下拉选择
      ShopFormatNameOptions: [],
      // 原商铺id下拉列表数据
      ShopLastIdsOptions: [],
      //   上传成功后的图片路径
      newFileData: [],
    };
  },

  mounted() {
  },

  methods: {
    ShopFormatNameSelectClick() {
    },
    // 上传图片成功
    uploadSuccessFileChange(fileData, newFileData) {
      this.uoloadTypeImage = newFileData[0];
      this.newFileData = newFileData;
    },
    // 刪除圖片
    imageDelete() {
      this.uoloadTypeImage = "";
    },
    // 业态级别 业态类型  楼栋 下拉函数
    handleSearchList(options, optionsType, moduleType) {
      // 判断如果是业态级别条件下，，随时清空业态类型 避免重新选择还是原来的值
      if (optionsType === "业态级别") {
        this.ShopFormatClassData = options;
        this.$refs.SelectOptionsShopFormatName.selectVal = "";
        // this.Form.ShopFormatClass = options.label; //业态级别名称赋值
        this.$set(this.Form, "ShopFormatClass", options.label)
        this.$http
          .post(
            "/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey",
            { Condition: "DicKey", Keyword: options.value }
          )
          .then((res) => {
            this.ShopFormatNameOptions = res.Data.DictionaryDetailsList.map(
              (item) => {
                return {
                  value: item.Id,
                  label: item.DicName,
                };
              }
            );
          });
      }
      if (optionsType === "业态类型") {
        this.ShopFormatNameData = options;
        // this.Form.ShopFormatName = options.label; //业态类型名称赋值
        this.$set(this.Form, "ShopFormatName", options.label)
      }
      // 如果区块属于楼栋下拉  放开地上地下选择框
      if (optionsType === "楼栋") {
        this.BuildingIdData = options;
        this.IsOnGroundDisabled = false;
        // this.Form.BuildingId = options.value; //选择好的楼栋id赋值
        // this.Form.BuildingCode = options.label; //选择好的楼栋名称赋值
        this.$set(this.Form, "BuildingId", options.value)
        this.$set(this.Form, "BuildingCode", options.label)

      }
      if (options) {
        // 如果有选中业态级别  同时获取业态名称
        this.ShopFormatNameDisabled = false;
      }
      let optionsData = this.ShopFormatClassOptions;
      if (this.ShopFormatClassOptions.length !== 0) {
        var newShopFormClassData = optionsData.filter(function (
          item,
          index,
          arr
        ) {
          return item.label === options.label;
        });
      }
      // 赋值业态级别下拉数据参数  以获取对应业态类型下拉数据
      this.parentObj.ShopFormatClassQueryParams.Keyword =
        newShopFormClassData.length == 0 ? "" : newShopFormClassData[0].value;

      // this.$emit("ShopFormatClassChange", options.label);
    },
    selectOptionsChange(data) {
      this.parentObj.getShopNameListData();
      // 过滤业态级别 业态名称  楼栋 赋值
      // 业态级别赋值
      this.$refs.SelectOptions.selectVal = data.ShopFormatClass;
      //业态类型赋值
      this.$refs.SelectOptionsShopFormatName.selectVal = data.ShopFormatName;
      // 楼栋赋值
      let newBuildingIdArr = this.buildingOptions.filter(
        (item) => item.value === data.BuildingId
      );
      if (newBuildingIdArr.length > 0)
        this.$refs.SelectOptionsBuildingId.selectVal =
          newBuildingIdArr[0].label;

      // 图片回显  字段暂时测试  后续修改字段**********************************************************************************
      // this.uoloadTypeImage = data.EquipmentAndMaterFile;
    },
    // 默认先赋值业态级别下拉。避免第一次进去没有下拉数据****************************************
    openSelectOptions(type, data) {
      this.$forceUpdate();
      this.$nextTick(function () {
        // 默认先赋值业态级别下拉。避免第一次进去没有下拉数据****************************************
        if (type === "业态级别") {
          // 如果过来的是业态级别  赋值业态级别下拉数据
          this.$refs.SelectOptions.chldrenSearchList =
            this.ShopFormatClassOptions;
        } else if (type === "业态类型") {
          // 如果过来的是业态类型 赋值业态类型下拉数据
          this.$refs.SelectOptionsShopFormatName.chldrenSearchList =
            this.ShopFormatNameOptions;
        } else {
          // 如果过来的是楼栋 赋值楼栋下拉数据
          this.$refs.SelectOptionsBuildingId.chldrenSearchList =
            this.buildingOptions;
        }
      });
    },
    validEmpty(Form, name, tips) {
      return (rule, value, callback) => {
        const Form = this.Form[name];
        if (!Form) {
          // this.$message.warning('列表第' + (index + 1) + '行：' + tips)
          callback(tips || "必填");
        } else {
          callback();
        }
      };
    },
    // 清除表单校验
    clearValidate() {
      var _this = this;
      // /消除校验
      setTimeout(function () {
        _this.$refs["Form"].clearValidate(); //消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
      }, 10);
    },
    // 取消按钮
    editCancel() {
      var _this = this;
      this.shopBaseInfoDisabled = true;
      let Id = this.parentObj.childrenRow.Id;
      // 清空所有form表单数据重新获取渲染
      this.Form = {};

      // /消除校验
      setTimeout(function () {
        _this.$refs["Form"].clearValidate(); //消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
      }, 30);

      // 重新获取编辑详情渲染
      // this.getShopInfoTheListData(Id)
      this.$emit("editCancel");
      // setTimeout(function () {//消除某个表单的校验,这里的setTimeOut不能去掉,去掉之后会不生效在clearValidate()里以数组形式 填写表单字段的 prop属性值,
      // _this.$refs['Form'].clearValidate(['sn']);
      //     },30);
      // 重新刷新详情  避免取消条件保存输入的数据
    },
    // form表单初始化
    resetForm() {
      this.Form = {};
      // 重新打开弹窗初始化业态级别，业态类型和楼栋， 地上地下 下拉列表
      this.$refs.SelectOptions.selectVal = "";
      this.$refs.SelectOptionsShopFormatName.selectVal = "";
      this.$refs.SelectOptionsBuildingId.selectVal = "";
    },
    // 获取编辑详情数据
    // getShopInfoTheListData(Id) {
    //   this.$http
    //     .post("/Shop_Manage/Shop_BaseInfo/GetTheData", {
    //       Id: Id,
    //     })
    //     .then((res) => {
    //       this.loading = false;
    //       if (res.Success) {
    //         if (res.Data.IsOnGround == 1) {
    //           res.Data.IsOnGround = "地上";
    //         } else if (res.Data.IsOnGround == 2) {
    //           res.Data.IsOnGround = "地下";
    //         } else {
    //           res.Data.IsOnGround = "屋面";
    //         }

    //         let ShopFormatClassOptions = this.ShopFormatClassOptions;
    //         ShopFormatClassOptions.forEach((item) => {
    //           if (res.Data.ShopFormatClass === item.label) {
    //             this.parentObj.ShopFormatNameQueryParams.Keyword = item.value;
    //           }
    //         });

    //         this.Form = res.Data;
    //         // 存储vux  商铺信息
    //         this.$store.commit("getShopInfo", res.Data);
    //       } else {
    //         this.loading = false;
    //       }
    //     });
    // },

    // 地上地下选择事件
    IsOnGroundChange(e) {
      if (e == "3") {
        this.$set(this.Form, 'Storey', 'RF')
        // this.Form.Storey = "RF";
      } else {
        this.$set(this.Form, 'Storey', '')
      }
      // if (this.Form.IsOnGround == 1 || this.Form.IsOnGround === "地上") {
      //   this.Form.ProjectCode =
      //     this.Form.BuildingCode +
      //     " - " +
      //     (this.Form.Storey + "F") +
      //     " - " +
      //     this.Form.ShopCode;
      // } else {
      //   //楼下
      //   this.Form.ProjectCode =
      //     this.Form.BuildingCode +
      //     " - " +
      //     ("B" + this.Form.Storey) +
      //     " - " +
      //     this.Form.ShopCode;
      // }
    },

    // 所在楼层实时输入事件
    StoreyInputChange(e) {
    },
    // 商铺编号实时输入事件
    ShopCodeInputBlur(e) {
    },
    // 修改基础商铺信息
    handlerSubmitSave() {
      this.$refs["Form"].validate((valid) => {
        if (valid) {
          //  插入项目id和任务id
          this.Form["ProjectId"] = this.newTaskObj.ProjectId;
          this.Form["TaskId"] = this.newTaskObj.TaskId;
          const that = this;
          let obj = {
            BuildingCode: this.Form.BuildingCode,
            BuildingId: this.Form.BuildingId,
            CreateTime: this.Form.CreateTime,
            CreatorId: this.Form.CreatorId,
            Id: this.Form.Id,
            InvestmentCode: this.Form.InvestmentCode,
            IsOnGround: this.Form.IsOnGround === "地上" || this.Form.IsOnGround == 1 ? 1 : this.Form.IsOnGround === "地下" || this.Form.IsOnGround == 2 ? 2 : 3,
            ProjectCode: this.Form.ProjectCode,
            Remark: this.Form.Remark,
            ShopArea: this.Form.ShopArea,
            ShopBrand: this.Form.ShopBrand,
            ShopImage: this.uoloadTypeImage,
            // 变更原因
            ShopChangeReason: this.Form.ShopChangeReason,
            // 变更补充原因
            SupplementReason: this.Form.SupplementReason,
            ShopCode: this.Form.ShopCode,
            ShopFormatClass: this.Form.ShopFormatClass,
            ShopFormatName: this.Form.ShopFormatName,
            ShopName: this.Form.ShopName,
            ShopSeparation: this.Form.ShopSeparation,
            Status: this.Form.Status ? this.Form.Status : 1,
            Storey: this.Form.Storey,
            // Storey: this.Form.IsOnGround == 2 ? '-' + this.Form.Storey :this.Form.IsOnGround == 3? this.Form.Storey+'RF': this.Form.Storey,

            // 首页传递过来得项目id
            ProjectId: this.Form.ProjectId,
            // 首页传递过来得任务id
            TaskId: this.Form.TaskId,
            // 版本号
            ChangeVersion: this.$store.state.shopInfo.ChangeVersion,
          };

          const loading = this.$loading({
            lock: true,
            text: "保存中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });

          that.$http
            .post("/Shop_Manage/Shop_BaseInfo/SaveData", obj)
            .then((res) => {
              if (res.Success) {
                let storeShopInfo = that.$store.state.shopInfo;
                if (storeShopInfo.Id) {
                  Object.keys(storeShopInfo).map((key1) => {
                    Object.keys(res.Data).map((key2) => {
                      if (key1 === key2) {
                        storeShopInfo[key1] = res.Data[key2];
                      }
                    });
                  });
                  // 存储商铺基础信息到vuex
                  that.$store.commit("getShopInfo", storeShopInfo);
                } else {
                  // 存储商铺基础信息到vuex
                  that.$store.commit("getShopInfo", res.Data);
                }
                // 禁用商铺基础信息
                this.shopBaseInfoDisabled = true;
                setTimeout(() => {
                  loading.close();
                  that.$message.success("已保存");
                  that.$parent.$parent.$parent.GetShopSummaryInfo();
                  // console.log(that.$parent,that.$parent.$parent)
                }, 500);

                // 关闭弹出层
                //  this.$emit("closeDialog", true);
              } else {
                that.$message.error(res.Msg);
                loading.close();
                // 关闭edit弹出层
                this.parentObj.dialogVisible = false;
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shopBaseInfo {
  padding: 0px 0;

  /*覆盖element input输入框的宽高*/
  ::v-deep .el-form-item {
    margin-bottom: 5px !important;

    .el-form-item__content {

      /*覆盖element textarea输入框的行高度*/
      .el-input--mini {
        .el-textarea__inner {
          line-height: 1.5 !important;
        }
      }

      .el-input {

        /* 可以隐藏上下加减号
  input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
 -webkit-appearance: none !important;
   	margin-left: 20px;
   }*/
        .el-input__inner {
          height: 28px !important;
          line-height: 28px !important;
        }

        .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .edit:hover {
    color: #aaa7a7 !important;
    cursor: pointer;
  }

  .save {
    color: red;
  }

  .save:hover {
    color: rgb(253, 127, 127);
    cursor: pointer;
  }

  .el-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 20px;

    ::v-deep .el-button {
      padding: 8px 20px;
    }
  }
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}

.floorFirst {
  ::v-deep .el-input__inner {
    padding: 0 25px !important;
  }
}
</style>
