<template>
  <div class="box" style="overflow: hidden;">
    <div style="display: flex;margin-bottom: 5px;">
      <div
        style="display: flex;align-items: center;justify-content: center;height: 30px;line-height: 30px;width: 40px;">
        <i size="30" class="el-icon-back" style="font-size: 23px;cursor: pointer;" @click="handleBack"></i>
      </div>
      <div style="font-size: 20px;font-weight: bold;line-height: 30px; margin-left: 20px;">数据分析-工程合约信息</div>
    </div>
    <el-card body-style="padding: 0px ;">
      <el-table :data="tableData" size="mini" height="90" max-height="90">
        <el-table-column v-for="(item, index) in tableColumn" :key="index" :label="item.EngineeringStatus"
          :prop="item.EngineeringStatus">
        </el-table-column>
      </el-table>

    </el-card>
    <el-row :gutter="10" style="margin-bottom: 5px;margin-top: 5px;">
      <el-col :span="12" v-loading="loading">
        <el-card style="width: 100%;min-height: 300px;" body-style="padding: 10px;" class="InfoCard">
          <el-row style="font-weight: bold;font-size: 18px;height: 35px;line-height: 35px;">
            <el-col :span="24">
              不同类型业态从技术分析到进场施工的平均周期统计
            </el-col>
          </el-row>
          <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
          <EchartsIndex refId="MG_EngineeringContractPageleft1" :option="left1" styleStr="width: 100%;height: 260px"
            v-if="this.dataInfo.TA_ETSC_Format && this.dataInfo.TA_ETSC_Format.length > 0" />
          <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
        </el-card>
      </el-col>
      <el-col :span="12" v-loading="loading">
        <el-card style="width: 100%;min-height: 300px;" body-style="padding: 10px;" class="InfoCard">
          <el-row style="font-weight: bold;font-size: 18px;height: 35px;line-height: 35px;">
            <el-col :span="24">
              按不同的面积类型从技术分析到进场施工的平均周期统计
            </el-col>
          </el-row>
          <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
          <EchartsIndex refId="MG_EngineeringContractPageright1" :option="right1" styleStr="width: 100%;height: 260px"
            v-if="this.dataInfo.TA_ETSC_Area && this.dataInfo.TA_ETSC_Area.length > 0" />
          <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12" v-loading="loading">
        <el-card style="width: 100%;min-height: 300px;" body-style="padding: 10px;" class="InfoCard">
          <el-row style="font-weight: bold;font-size: 18px;height: 35px;line-height: 35px;">
            <el-col :span="24">
              不同类型业态从进场施工到消防验收完毕的平均周期统计
            </el-col>
          </el-row>
          <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
          <EchartsIndex refId="MG_EngineeringContractPageleft2" :option="left2" styleStr="width: 100%;height: 260px"
            v-if="this.dataInfo.ETSC_FA_Format && this.dataInfo.ETSC_FA_Format.length > 0" />
          <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
        </el-card>
      </el-col>
      <el-col :span="12" v-loading="loading">
        <el-card style="width: 100%;min-height: 300px;" body-style="padding: 10px;" class="InfoCard">
          <el-row style="font-weight: bold;font-size: 18px;height: 35px;line-height: 35px;">
            <el-col :span="24">
              按不同的面积类型从进场施工到消防验收完毕的平均周期统计
            </el-col>
          </el-row>
          <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
          <EchartsIndex refId="MG_EngineeringContractPageright2" :option="right2" styleStr="width: 100%;height: 260px"
            v-if="this.dataInfo.ETSC_FA_Area && this.dataInfo.ETSC_FA_Area.length > 0" />
          <el-empty description="暂无数据" style="width:100%;height: 260px;" v-else></el-empty>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
// 引入echarts图表组件
import EchartsIndex from '@/components/Echarts/index';

export default {
  name: "MG_EngineeringContractPage", // 工程及合约信息模块
  components: {
    EchartsIndex
  },
  props: {
    typeList: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: String,
      default: "",
    },
    homeData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      dataInfo: {},
      loading: false,
      left1: {
        tooltip: {
          trigger: 'item',
          formatter: '{a}：{c}天'
        },
        legend: {
          data: [],
          top: 0,
        },
        grid: {
          top: 80,
          bottom: 20,
          left: 10,
          right: 10,
          containLabel: true,
        },
        label: {
          show: true,
          position: 'top',
          formatter: (a) => {
            if (a.value > 0) {
              return a.value + '天'
            } else {
              return ''
            }
          }
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          name: '周期(天)',
          nameLocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: 'value',
          axisLabel: {
            formatter: '{value} 天',
          },
        },
        series: [],
      },
      left2: {
        tooltip: {
          trigger: 'item',
          formatter: '{a}：{c}天'
        },
        legend: {
          data: [],
          top: 0,
        },
        grid: {
          top: 80,
          bottom: 20,
          left: 10,
          right: 10,
          containLabel: true,
        },
        label: {
          show: true,
          position: 'top',
          formatter: (a) => {
            if (a.value > 0) {
              return a.value + '天'
            } else {
              return ''
            }
          }
        },

        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          name: '周期(天)',
          nameLocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: 'value',
          axisLabel: {
            formatter: '{value} 天',
          },
        },
        series: [

        ],

        // axisPointer: {
        //   type: 'cross' // 坐标轴指示器，坐标轴线上两个点的连线 
      },
      right1: {
        tooltip: {
          trigger: 'item',
          formatter: '{a}：{c}天'
        },
        label: {
          show: true,
          position: 'top',
          formatter: (a) => {
            if (a.value > 0) {
              return a.value + '天'
            } else {
              return ''
            }
          }
        },
        legend: {
          data: [],
          top: 0,
        },
        grid: {
          top: 80,
          bottom: 20,
          left: 10,
          right: 10,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          name: '周期(天)',
          nameLocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: 'value',
          axisLabel: {
            formatter: '{value} 天',
          },
        },
        series: [

        ],

      },
      right2: {
        tooltip: {
          trigger: 'item',
          formatter: '{a}：{c}天'
        },
        label: {
          show: true,
          position: 'top',
          formatter: (a) => {
            if (a.value > 0) {
              return a.value + '天'
            } else {
              return ''
            }
          }
        },
        legend: {
          data: [],
          top: 0,
        },
        grid: {
          top: 80,
          bottom: 20,
          left: 10,
          right: 10,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          name: '周期(天)',
          nameLocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: 'value',
          axisLabel: {
            formatter: '{value} 天',
          },
        },
        series: [

        ],
      },
    }
  },

  created() {
    this.getDataList()
  },
  watch: {
    homeData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.ECMT_Shop && val.ECMT_Shop.length > 0) {
          this.tableColumn = [
            {
              EngineeringStatus: "数量/占比",
            },
            ...val.ECMT_Shop,
          ];
          let numObj = {
            "数量/占比": "数量",
          };
          let areaObj = {
            "数量/占比": "占比",
          };
          val.ECMT_Shop.forEach((item) => {
            this.$set(numObj, item.EngineeringStatus, item.StatusCount);
            this.$set(areaObj, item.EngineeringStatus, item.StatusProportion ? item.StatusProportion.toFixed(2) + '%' : '0%');
          });
          this.tableData = [numObj, areaObj];
        }
      },
    },
  },
  methods: {
    getDataList() {
      this.loading = true
      this.$http.post("/Base_ProjectManage/Project_DataAnalysis/Get_EngineeringStatusReturnData?projectId=" + this.projectId).then(res => {
        this.loading = false
        if (res.Success) {
          this.dataInfo = res.Data || {}
          if (this.dataInfo.TA_ETSC_Format && this.dataInfo.TA_ETSC_Format.length > 0) {
            this.left1.series = []
            this.left1.legend.data = []
            this.dataInfo.TA_ETSC_Format.forEach(item => {
              item.children.forEach(item2 => {
                this.left1.legend.data.push({
                  name: item2.name,

                })
                this.left1.series.push(
                  {
                    name: item2.name,
                    type: 'bar',
                    emphasis: {
                      focus: "series",
                    },
                    showBackground: true,
                    barGap: '100%',
                    data: [item2.value ? item2.value.toFixed(2) : 0]
                  },
                )
              })

            })
            if (this.left1.legend.data.length > 16) {
              let num = Math.ceil(this.left1.legend.data.length / 8)
              let topnum = 0
              for (let i = 0; i < num; i++) {
                topnum = 30 + topnum
              }
              this.left1.grid.top = topnum + 20
            } else {
              this.left1.grid.top = 60
            }
          }
          if (this.dataInfo.ETSC_FA_Format && this.dataInfo.ETSC_FA_Format.length > 0) {
            this.left2.legend.data = []
            this.left2.series = []
            this.dataInfo.ETSC_FA_Format.forEach(item => {
              item.children.forEach(item2 => {
                this.left2.legend.data.push({
                  name: item2.name,

                })
                this.left2.series.push(
                  {
                    name: item2.name,
                    type: 'bar',
                    emphasis: {
                      focus: "series",
                    },
                    showBackground: true,
                    barGap: '100%',
                    data: [item2.value ? item2.value.toFixed(2) : 0]
                  },
                )
              })
              if (this.left2.legend.data.length > 16) {
                let num = Math.ceil(this.left2.legend.data.length / 8)
                let topnum = 0
                for (let i = 0; i < num; i++) {
                  topnum = 30 + topnum
                }
                this.left2.grid.top = topnum + 20
              } else {
                this.left2.grid.top = 60
              }

            })
          }
          if (this.dataInfo.TA_ETSC_Area && this.dataInfo.TA_ETSC_Area.length > 0) {
            this.right1.legend.data = []
            this.right1.series = []
            this.dataInfo.TA_ETSC_Area.forEach(item => {

              this.right1.legend.data.push({
                name: item.name,
              })
              this.right1.series.push(
                {
                  name: item.name,
                  type: 'bar',
                  emphasis: {
                    focus: "series",
                  },
                  showBackground: true,
                  barGap: '100%',
                  data: [item.value]
                },
              )
            })

          }
          if (this.dataInfo.ETSC_FA_Area && this.dataInfo.ETSC_FA_Area.length > 0) {
            this.right2.legend.data = []
            this.right2.series = []
            this.dataInfo.ETSC_FA_Area.forEach(item => {

              this.right2.legend.data.push({
                name: item.name,
              })
              this.right2.series.push(
                {
                  name: item.name,
                  type: 'bar',
                  emphasis: {
                    focus: "series",
                  },
                  showBackground: true,
                  barGap: '100%',
                  data: [item.value]
                },
              )
            })
          }
        } else {
          this.$message.error('获取工程合约信息失败')
        }
      }).catch(() => {
        this.dataInfo = {}
        this.loading = false
        this.$message.error('获取工程合约信息失败')
      })
    },
    handleBack() {
      this.$emit('changePageblock', '');
    },
  }
}
</script>


<style scoped lang="scss">
.box {
  padding: 0px;
  box-sizing: border-box;
}

::v-deep .el-table th {
  background-color: #D1100B;
  color: #fff;

}

.InfoCard {
  margin-top: 5px;
  border-radius: 10px;
  padding: 0px 5px;
  box-sizing: border-box;
  border: 5px solid #c3c3c3;
}

::v-deep .el-table th {
  background-color: #D1100B;
  color: #fff;
}

::v-deep .el-table__body-wrapper {
  background-color: #D1100B;
}

::-webkit-scrollbar {
  width: 0px;

  background: transparent;
}

::v-deep .el-table td {
  background-color: #D1100B;
  padding: 0 !important;
  color: #fff;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table {

  //可设置胶囊宽高
  ::-webkit-scrollbar {
    height: 12px;
    width: 10px;
  }

  // 设置胶囊色
  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #f2706b;
  }

  // ::-webkit-scrollbar-thumb:hover {

  // }
}

::v-deep .el-table__row:hover td {
  background-color: #f2706b !important;
}
</style>
