<!-- 大表格  父组件-->
<template>
  <div class="container">
    <!-- 表格内容 min-height: 500px-->
    <div v-if="isShow == 1" style="position: relative">
      <div class="mask" @click="(treeShow = false), (sortShow = false)" v-show="treeShow || sortShow"></div>

      <div class="toolbar">
        <!-- 左侧搜索区域 -->
        <div :style="{ width: windowWidth + 'px' }" style="display: flex; justify-content: space-between">
          <div class="tools" style="">
            <el-button size="mini" type="primary" style="margin-right: 10px" @click="backTask">返回</el-button>
            <el-select style="" class="marginRight" v-model="taskData" placeholder="请选择筛任务" size="mini"
              @change="taskOptionsChange" filterable default-first-option>
              <el-option v-for="item in taskOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button size="mini" @click="deleteTableClick" type="primary" :disabled="isDelete">删除</el-button>
            <el-button size="mini" @click="batchVersonClick" type="primary" :disabled="isBatch">批量提交版本</el-button>
          </div>
          <div class="tools" style="margin-left: 10px">
            <!-- <el-input style="" class="marginRight" v-model="searchKeyword" placeholder="请输入商铺编号或名称"
              size="mini"></el-input> -->
            <el-select v-model="BuildingIdList" placeholder="请选择楼栋" size="mini" class="marginRight" clearable multiple
              collapse-tags filterable default-first-option style="max-width: 160px;">
              <el-option v-for="item in BuildingList" :key="item.Key" :label="item.Value" :value="item.Key">
              </el-option>
            </el-select>
            <el-input style="" class="marginRight" v-model="searchKeyword" placeholder="请输入商铺编号或名称"
              size="mini"></el-input>
            <el-button size="mini" @click="inputSearchChange" type="primary">筛选</el-button>
            <el-button size="mini" @click="tableClickRefresh" style="margin-right: 8px">刷新</el-button>


            <el-select style="" v-model="SortField" placeholder="选择排序方式" size="mini" @change="sortSelectChange"
              class="marginRight">
              <el-option v-for="item in sortOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

            <el-tooltip class="item" effect="dark" content="降序" placement="top">
              <el-button size="mini" type="primary" @click="sortChange" icon="el-icon-sort-down" style="padding: 5px;"
                :disabled="isSortAsc">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="升序" placement="top">
              <el-button size="mini" type="primary" @click="sortChange" icon="el-icon-sort-up" style="padding: 5px;"
                :disabled="!isSortAsc">

              </el-button>
            </el-tooltip>

            <!-- <el-dialog title="导入数据" :visible.sync="dialogVisible" width="30%">
            <div style="display: flex; justify-content: space-around">
              <el-button size="mini">下载模板</el-button>
              <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
              >
                <el-button size="mini" :loading="importLoading">导入数据</el-button>
              </el-upload>
            </div>
          </el-dialog> -->
            <el-button style="margin-right: 10px" size="mini" type="primary" @click="exportChangeExcel">导出成果</el-button>
          </div>
        </div>
        <div style="text-align: right"></div>
        <div v-if="tableData.length !== 0">
          <el-button size="mini" class="marginRight" round @click="sortShow = true">排序 <i
              class="el-icon-sort"></i></el-button>
          <!-- <el-dropdown trigger="click" @command="handleCommand">
            <el-button size="mini" size="mini" round class="marginRight">
              {{ filterName ? filterName : "列方案" }}
              <i class="el-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in filterList"
                :key="index"
                :command="item"
                >{{ item.SchemeName }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown> -->
          <!-- <el-button size="mini" size="mini" round @click="treeShow = true" class="setting"
            >设置列
            <i class="el-icon-setting"></i>
          </el-button> -->
        </div>
      </div>
      <!-- 排序 -->
      <transition name="el-zoom-in-top">
        <div class="tree sort" v-show="sortShow">
          <div class="content">
            <div class="sortItem" v-for="(item, index) in searchList" :key="index">
              <el-checkbox v-model="item.checked">
                <span class="noWarp">{{ item.DicName }}</span>
              </el-checkbox>
              <div class="iconGroup">
                <i @click="sortIconClick(item, 'desc')" :class="item.sort == 'desc'
                  ? 'el-icon-sort-down active'
                  : 'el-icon-sort-down'
                  "></i><i @click="sortIconClick(item, 'asc')" :class="item.sort == 'asc'
                    ? 'el-icon-sort-up active'
                    : 'el-icon-sort-up'
                    "></i>
              </div>
            </div>
          </div>
          <div class="footer">
            <el-button size="mini" @click="sortReset">重 置</el-button>
            <el-button size="mini" type="primary" @click="sortConfirm">确 认</el-button>
          </div>
        </div>
      </transition>
      <!-- 树菜单 -->
      <!-- <transition name="el-zoom-in-top">
        <div class="tree" v-show="treeShow">
          <el-tree
            ref="tree"
            :data="treeData"
            show-checkbox
            node-key="DicKey"
            :default-expanded-keys="defaultKeys"
            :default-checked-keys="defaultKeys"
            :props="defaultProps"
            @check-change="nodeClick"
          >
          </el-tree>
          <el-input
            size="mini"
            v-model="treeOptionName"
            style="width: 100px; margin-left: 5px"
            placeholder="方案名称"
          ></el-input>
          <el-button size="mini" size="mini" type="primary" @click="optionConfirm"
            >保存方案</el-button
          >
          <div class="filterBox">
            <el-tag
              v-for="(item, index) in filterList"
              :key="index"
              type="mini"
              closable
              @close="handleRemoveTag(item)"
              @click="handleCommand(item)"
            >
              {{ item.SchemeName }}
            </el-tag>
          </div>
        </div>
      </transition> -->

      <!-- 表格 -->
      <div>
        <table-item ref="tableItem" :taskObj="taskObj" v-if="columnChange" :col="col"
          @handleSelectionChange="handleSelectionChange" :isShow="isShow" @parentSetIsShow="parentSetIsShow"
          @handleDetailChange="handleDetailChange" @hiddenColumn="hiddenColumn" @showColumn="showColumn"
          @tableSelectionChange="tableSelectionChange" @handleSubmitButtonChange="handleSubmitButtonChange"
          @submitButtonBatchVersion="submitButtonBatchVersion"></table-item>
        <!-- 底部分页-->
        <!-- <div v-if="isTable==false" style="display:flex;justify-content: flex-end;">
          <pagination :total="pagination.total" :page.sync="pagination.PageIndex" :limit.sync="pagination.PageRows"
            @pagination="handlePageChange" />
        </div> -->
      </div>
    </div>
    <!-- 更新记录组件 -->
    <UpdateRecord ref="updateRecord" @recordBack="recordBack" :parentObj="{ ...this }"></UpdateRecord>

    <!-- 查看组件-->
    <TableDetail ref="tableDetail" @tableDetailBack="recordBack"></TableDetail>

    <TableDialog ref="TableDialog" :parentObj="{ ...this }" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
// 模拟数据
import { tableData, col, newCol, sortOptions } from "./mock.js";
// 引入递归子组件
import tableItem from "./tableItem";

// 引入导出成果大表格组件
import TableDialog from "./TableDialog.vue";

// 引入更新记录组件
import UpdateRecord from "../view/updateRecord.vue";

// 引入查看记录组件
import TableDetail from "../newView/tableDetail.vue";

export default {
  name: "AttractInvestment",
  components: {
    // Edit,
    UpdateRecord,
    TableDetail,
    tableItem,
    Pagination,
    TableDialog,
  },
  props: {
    parentObj: {
      type: Object,
    },
    taskObj: {
      type: Object,
    },
    ClassType: {
      type: Number,
      require: true,
    },
  },
  // 监听任务类型   已启动。进行中。已完成
  watch: {
    ClassType: {
      handler(n, o) {
        // if (n>=0) {
        //   for (let i = 0; i < 3; i++) {
        //     this.getDataList(i);
        //   }
        // }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      // 计算顶部搜索按钮区域的整体视窗宽度
      windowWidth: 0,
      // 是否批量提交版本
      isBatch: true,
      // 是否启用删除按钮
      isDelete: true,
      // 搜索得关键词
      searchKeyword: "",
      //楼栋搜索
      BuildingIdList: [],
      BuildingList: [],
      // 任务下拉默认绑定数据
      taskData: "",
      // 任务下拉默认数据
      taskOptions: [],
      taskOptionsId: "",
      // 按照面积，按照业态排序
      sortOptions,
      //项目id
      ProjectId: "",
      isInfiniteScroll: true,
      // 表格加载中提示
      loading: false,
      sortShow: false, // 排序展示隐藏
      filterName: "", // 表格筛选方案展示名称
      filterList: [], // 表格筛选方案
      treeOptionName: "", // 树菜单保存方案名称
      searchList: [], // 查询筛选项列表
      searchKey: "", // 查询Key值
      keyword: "", // 查询字段
      importLoading: false, // 导入loading状态
      dialogVisible: false, // 导入弹窗
      columnChange: true, // 表格重置卸载
      treeData: [], // 列表显示隐藏树菜单数据
      treeShow: false, // 树菜单展示隐藏
      defaultKeys: [], // 树菜单默认选中项
      defaultProps: {
        // 树菜单format
        children: "children",
        label: "DicName",
      },
      pagination: {
        PageIndex: 1,
        PageRows: 25,
        total: 0,
      },
      SortInfos: [],
      queryParam: [
        {
          Condition: "ProjectId",
          Keyword: "",
        },
        {
          Condition: "",
          Keyword: "",
        },
        {
          Condition: "",
          Keyword: "",
        },
      ],
      taskQueryParams: {
        ClassType: 0,
        Conditions: [
          {
            Condition: "ProjectId",
            Keyword: this.taskObj.ProjectId,
          },
          {
            Condition: "AppyId",
            Keyword: this.taskObj.AppyId,
          },
          // { Condition: "Status", Keyword: 0 },暂时删除后续有需要追加
        ],
      },
      // 是否显示编辑和查看
      isShow: 1,
      //树型结构表头数据
      col: col,
      // 用于渲染新建或编辑组件
      newCol: newCol,
      //表格数据
      tableData: [],
      // tableItem 子组件传递过来的删除数组
      handleSelectListData: [],
      //排序方式
      SortField: "ShopArea",
      sortAglin: "ShopArea",
      isSortAsc: true
    };
  },
  created() {
    localStorage.removeItem("fileDataInternalAudit");
    localStorage.removeItem("fileDataExternalAudit");
    localStorage.removeItem("fileDataEquipmentAndMater");
    this.queryParam[0].Keyword = this.$route.query.id;
    this.ProjectId = this.$route.query.id;
    // console.log("获取应用Id", this.taskObj);
    // 赋值默认的当前项目下的当前任务下的招商表格数据
    this.taskData = this.taskObj.TaskId;
  },
  mounted() {
    // 动态获取当前浏览器窗口的高度
    this.getWindowHeight(); // 在初始化时获取窗口高度
    window.addEventListener("resize", this.getWindowHeight); // 在窗口大小改变时重新获取窗口高度

    //获取大表格整体数据
    this.GetTreeDataListByDicKey();
    // 列表展示方案
    // this.getOptionsData();
    // 获取所有当前项目下的下拉列表数据
    this.getTaskListData();
    console.log(this.taskObj)
    this.getBuildingList()

  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowHeight); // 组件销毁时移除resize事件监听器
  },
  methods: {
    //获取楼栋列表
    getBuildingList() {
      this.$http.post('/Base_ProjectManage/Project_DataAnalysis/GetMG_BaseInfo_BuildingsOrLayers', {
        ProjectId: this.taskObj.ProjectId,
      }).then(res => {
        if (res.Success) {
          this.BuildingList = res.Data
        } else {
          this.$message.error('获取楼栋列表失败');
          this.BuildingList = []
        }
      })
    },
    // 动态获取当前浏览器窗口的高度 宽度  -30  是减去其余头部的区域部分   剩余需要显示的，，后续可以调整减号后的数字调整高度大小
    getWindowHeight() {
      this.windowWidth = window.innerWidth - 300;
    },
    backTask() {
      this.$emit("task", {
        status: true,
        ...this.taskObj
      });
    },
    scrollLoad(code) {
      this.ApplyCode = code
      this.$nextTick(function () {
        this.$refs.tableItem.loadMore();
        // 传递项目id 应用id 应用名称到tableItem组件
        this.$refs.tableItem.taskObj = this.taskObj;
      });
    },

    // 根据用户编号和名称进行搜索
    inputSearchChange(e) {
      this.$nextTick(function () {
        this.$refs.tableItem.pagination.current = 1;
        this.$refs.tableItem.queryParam[0].Keyword = this.taskObj.ProjectId;
        this.$refs.tableItem.queryParam[2].Keyword = this.searchKeyword;
        if (this.BuildingIdList.length > 0) {
          this.$refs.tableItem.queryParam[3].Keyword = this.BuildingIdList.join(",");
        } else {
          this.$refs.tableItem.queryParam[3].Keyword = ''
        }

        // 重新获取echarts图标相关数据和底部综合数据
        this.$refs.tableItem.ecahrtsOptions2.series[0].data = [];
        this.$refs.tableItem.ecahrtsOptions1.series[0].data = [];
        this.$refs.tableItem.ecahrtsOptions.series[0].data = [];
        // 刷新列表数据
        this.$refs.tableItem.getDataList();
        this.$refs.tableItem.GetShopSummaryInfo();
      });
    },

    // 排序方式
    sortSelectChange(e) {
      // this.$refs.tableItem.SortInfos = [
      //   {
      //     SortField: "CreateTime",
      //     SortType: "desc",
      //   },
      // ];
      this.$nextTick(function () {
        this.$refs.tableItem.pagination.current = 1;
        // if (e === 'Storey') {
        //   this.$refs.tableItem.SortInfos[0].SortField = 'Storey';
        //   this.$refs.tableItem.SortInfos[0].SortType = 'desc';
        //   // this.$refs.tableItem.SortInfos.push({ SortField: e })
        // } else {
        this.$refs.tableItem.SortInfos[0].SortField = e;
        // }
        this.$refs.tableItem.SortInfos[0].SortType = this.isSortAsc ? "desc" : "asc"
        // 重新获取表格列表谁
        this.$refs.tableItem.getDataList();
      });
    },
    sortChange() {
      this.$nextTick(function () {
        this.isSortAsc = !this.isSortAsc
        this.$refs.tableItem.pagination.current = 1;
        // if (e === 'Storey') {
        //   this.$refs.tableItem.SortInfos[0].SortField = 'Storey';
        //   this.$refs.tableItem.SortInfos[0].SortType = 'desc';
        //   // this.$refs.tableItem.SortInfos.push({ SortField: e })
        // } else {

        // }
        this.$refs.tableItem.SortInfos[0].SortType = this.isSortAsc ? "desc" : "asc"
        // 重新获取表格列表谁
        this.$refs.tableItem.getDataList();
      });
    },
    // 任务下拉选择改变事件
    taskOptionsChange(e) {
      this.taskOptionsId = e;
      // 根据当前选择的任务下拉选项赋值echarts图标的参数
      this.taskObj.TaskId = e;
      this.$nextTick(function () {
        this.$refs.tableItem.pagination.current = 1;
        this.$refs.tableItem.queryParam[1].Keyword = e;
        //清空楼栋数据
        this.BuildingIdList = [];
        this.$refs.tableItem.queryParam[3].Keyword = '';
        // 重新获取echarts图标相关数据和底部综合数据
        this.$refs.tableItem.ecahrtsOptions2.series[0].data = [];
        this.$refs.tableItem.ecahrtsOptions1.series[0].data = [];
        this.$refs.tableItem.ecahrtsOptions.series[0].data = [];
        // 刷新列表数据
        this.$refs.tableItem.getDataList();
        this.$refs.tableItem.GetShopSummaryInfo();
      });
    },

    // 获取任务下拉列表数据
    getTaskListData() {
      this.$http
        .post("/Base_TaskWork/Base_Task/GetDataListByMoreCondition", {
          Search: this.taskQueryParams,
        })
        .then((res) => {
          if (res.Success) {
            let arr = res.Data.map((item) => {
              return {
                // 任务id
                value: item.Id,
                label: item.Name,
              };
            });
            this.taskOptions = arr;
            //  更新表格数据
            // 清空选项
          } else {
            this.$message.error(res.Msg);
          }
        });
    },

    // 批量提交版本
    batchVersonClick() {
      // 批量提交时判断是否存在批量提交的招商列表数据
      if (this.$refs.tableDetail.tableData == 0) {
        this.$message.error("暂无批量提交的商铺版本, 请先添加商铺");
        return;
      }

      // 判断是否有选择
      if (this.handleSelectListData.length == 0) {
        this.$message.error("请至少选择一项");
        return;
      }
      // 过滤存在需要变更的条件  过滤出已提交的
      let isStatus = this.handleSelectListData.filter(
        (item) => item.Status == 2
      );

      if (isStatus.length > 0) {
        this.$message.error(
          "存在需要变更的商铺信息, 请先变更该商铺后再批量提交"
        );
        return;
      }

      //  过滤id数组 批量提交版本
      var arrId = this.handleSelectListData.map((item) => {
        return item.Id;
      });

      var that = this;
      this.handleSubmitButtonChange(arrId, "批量");
    },

    // 表格项删除
    deleteTableClick() {
      if (this.handleSelectListData.length == 0) {
        this.$message.error("请至少选择一项");
        return;
      }
      // 启用删除按钮
      this.isDelete = false;
      // 启用批量提交按钮
      //  过滤id数组
      var arr = this.handleSelectListData.map((item) => {
        return item.Id;
      });
      var that = this;
      this.$confirm("此操作将当前条删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((result) => {
          // 刷新表格
          that.loading = true;
          const loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that.$http
            .post("/Shop_Manage/Shop_BaseInfo/DeleteData", arr)
            .then((res) => {
              if (res.Success) {
                setTimeout(() => {
                  loading.close();
                  that.$message.success("已删除");
                }, 500);
                //  更新表格数据
                // 清空选项
                this.handleSelectListData = [];
                this.pagination.current = 1;
                // 刷新表格
                this.tableClickRefresh();
              } else {
                // 刷新表格
                that.loading = true;
                loading.close();
                that.$message.error(res.Msg);
              }
            });
        })
        .catch((msg) => { });
    },
    // 再次点击升或降
    // sortSameValue(value) {
    //   // if (this.sortAglin === value) {
    //   //   if (this.$refs.tableItem.SortInfos[0].SortType == "desc") {
    //   //     this.$refs.tableItem.SortInfos[0].SortType = "asc"

    //   //   } else {


    //   //   }


    //   // }
    //   this.sortAglin = value;

    //   this.$refs.tableItem.SortInfos[0].SortType = this.isSortAsc ? "desc" : "asc"
    //   // 重新获取表格列表谁
    //   this.$refs.tableItem.getDataList();
    // },
    tableSelectionChange(e) {
      if (e.length == 0) {
        // 启用删除按钮
        this.isDelete = true;
        this.isBatch = true;
      } else {
        this.isDelete = false;
        this.isBatch = false;
      }
      this.handleSelectListData = e;
    },

    // 抽屉弹窗关闭
    closeDrawer() {
      // 刷新表格数据
      this.tableClickRefresh();
    },

    // 分页函数
    handlePageChange(data) {
      this.pagination.PageIndex = data.pageIndex;
      this.pagination.PageRows = data.pageSize;
      this.getDataList(); //这是重新获取修改日志列表
    },
    // 表格头点击隐藏列
    hiddenColumn(key) {
      this.columnChange = false;
      /**
         * tree数据源绑定col使用以下方法
          this.$set(e, "show", isCheck);
         */
      let idx = this.col.findIndex((item) => item.DicKey == key);
      this.col[idx].show = false;
      this.$nextTick(() => {
        this.columnChange = true;
      });
    },
    // 表格头点击显示列
    showColumn(key) {
      this.columnChange = false;
      /**
         * tree数据源绑定col使用以下方法
          this.$set(e, "show", isCheck);
         */
      let idx = this.col.findIndex((item) => item.DicKey == key);
      this.col[idx].show = true;
      this.$nextTick(() => {
        this.columnChange = true;
      });
    },
    // 新建商铺信息
    tableClickAdd() {
      let Object = {
        data: this.col,
        title: "新建",
        ProjectId: this.$route.query.id, //项目id
      };
      this.$refs.Edit.open(Object);
    },

    // 刷新表格
    tableClickRefresh() {
      // 刷新表格
      this.loading = true;
      this.queryParam[0].Condition = "ProjectId";
      // 初始化页数
      this.pagination.PageIndex = 1;
      // 初始化表格数据
      this.tableData = [];
      // 获取表格数据
      this.$refs.tableItem.tableData = [];
      this.$refs.tableItem.pagination.current = 1;
      // 重新获取echarts图标相关数据和底部综合数据
      this.$refs.tableItem.ecahrtsOptions2.series[0].data = [];
      this.$refs.tableItem.ecahrtsOptions1.series[0].data = [];
      this.$refs.tableItem.ecahrtsOptions.series[0].data = [];
      // 刷新列表数据
      this.$refs.tableItem.GetShopSummaryInfo();
      this.$refs.tableItem.getDataList();
      // 传递项目id 应用id 应用名称到tableItem组件
      this.$refs.tableItem.taskObj = this.taskObj;
      // 初始化搜索条件
      this.$refs.tableItem.queryParam[2].Keyword = "";
      this.searchKeyword = "";
      // // 刷新列表展示方案
      // this.getOptionsData();
      // // setTimeout(() => {
      // //   loading.close();
      // // }, 500);
    },

    // 导出程工
    exportChangeExcel() {
      this.loading = true;
      var that = this;
      // 获取子组件的表格数据
      let tableData = this.$refs.tableItem.tableData;
      // 过滤存在没有提交的商铺数据
      let isStatusIdArr = tableData.filter(
        (item) => item.Status == null || item.Status == 1 || item.Status == 0
      );

      // 判断是否有要导出的商铺信息
      if (tableData.length == 0)
        return this.$message.error("暂无导出的商铺信息, 请先添加商铺");
      // 判断过滤的数组是否大于0  说明有未提交的版本进行校验
      if (isStatusIdArr.length > 0) {
        // 过滤未提交的商铺版本设置自动选中
        tableData.forEach((item) => {
          if (item.Status == 0 || item.Status == null || item.Status == 1) {
            // 未提交的商铺版本
            this.$refs.tableItem.$refs.table.toggleRowSelection(item, true);
          }
        });
        // 过滤所有选中的id数组
        let bathIdArr = this.handleSelectListData.map((item) => {
          return item.Id;
        });

        this.$confirm("当前页您有未提交的商铺版本, 是否一键提交?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that.$refs.tableItem.loading = true;
            // 刷新表格
            that.loading = true;
            const loading = that.$loading({
              lock: true,
              text: "版本提交中...",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });

            that.$http
              .post(
                "/Shop_Manage/Shop_BaseInfo/ShopInfo_VersionBatchSubmission",
                bathIdArr
              )
              .then((res) => {
                if (res.Success) {
                  that.$message.success("一键提交成功");
                  setTimeout(() => {
                    // 刷新表格
                    that.loading = false;
                    that.$refs.tableItem.loading = false;
                    // 刷新列表数据
                    that.$refs.tableItem.getDataList();
                    this.$refs.tableItem.GetShopSummaryInfo();
                    loading.close();
                    // 可以进入大表格导出页面
                    that.$nextTick(function () {
                      that.$refs.TableDialog.open(
                        that.taskOptionsId,
                        that.taskObj
                      );
                    });
                  }, 1000);
                  // 取消自动选中
                  tableData.forEach((item) => {
                    this.$refs.tableItem.$refs.table.toggleRowSelection(
                      item,
                      false
                    );
                  });
                } else {
                  // 刷新表格
                  that.loading = false;
                  loading.close();
                  that.$message.error(res.Msg);
                }
              });
          })
          .catch(() => {
            // 取消自动选中
            tableData.forEach((item) => {
              this.$refs.tableItem.$refs.table.toggleRowSelection(item, false);
            });
            setTimeout(() => {
              that.$message({
                type: "info",
                message: "已取消",
              });
            }, 500);
          });
      } else {
        // 可以进入大表格导出页面
        that.$nextTick(function () {
          that.$refs.TableDialog.open(that.taskOptionsId, that.taskObj);
        });
      }
    },

    init() {
      // tree数据源绑定col使用以下方法
      // this.defaultKeys = [];
      // this.col.forEach((item) => {
      //   if (item.children && item.children.length > 0) {
      //     this.defaultKeys.push(item.DicKey);
      //     this.getDefaultProp(item.children);
      //   }
      // });
      this.treeData = this.col.map((item) => {
        return {
          DicKey: item.DicKey,
          DicName: item.DicName,
        };
      });
      this.defaultKeys = this.col.map((item) => item.DicKey);
      // 查询条件
      this.searchList = [];
      this.getSearchKey(this.col);
    },
    // 移除方案
    // handleRemoveTag(e) {
    //   this.$http
    //     .post("/Shop_Manage/Shop_ColumnsScheme/DeleteData", [e.Id])
    //     .then((res) => {
    //       this.getOptionsData();
    //     });
    // },
    // 排序升降选择
    sortIconClick(item, sort) {
      this.$set(item, "sort", sort);

      if (!item.checked) this.$set(item, "checked", true);
    },
    // 排序重置
    sortReset() {
      this.searchList.forEach((item) => {
        this.$set(item, "checked", false);
        this.$set(item, "sort", "asc");
      });
      this.sortShow = false;
    },
    // 排序保存
    sortConfirm() {
      let newArr = this.searchList.filter((item) => item.checked);
      this.SortInfos = newArr;
      this.sortShow = false;
      this.getDataList();
    },
    // 表格列隐藏方案保存
    // optionConfirm() {
    //   if (!this.treeOptionName) return this.$message.error("请填写方案名称");
    //   let arr = this.col.filter((item) => item.show).map((item) => item.DicKey);
    //   this.$http
    //     .post("/Shop_Manage/Shop_ColumnsScheme/SaveData", {
    //       SchemeName: this.treeOptionName,
    //       SchemeContent: JSON.stringify(arr),
    //     })
    //     .then((res) => {
    //       if (res.Success) {
    //         this.getOptionsData();
    //         this.$message.success("操作成功");
    //         this.treeOptionName = "";
    //         this.treeShow = false;
    //       } else this.$message.error(res.Msg);
    //     });
    // },
    // 表格列隐藏方案选择
    handleCommand(e) {
      this.filterName = e.SchemeName;
      // this.columnChange = false;
      this.col.forEach((item) => {
        if (e.SchemeContent.includes(item.DicKey)) {
          this.$refs.tree.setChecked(item.DicKey, true, false);
        } else {
          this.$refs.tree.setChecked(item.DicKey, false, false);
        }
      });
      // this.$nextTick(() => {
      //   this.columnChange = true;
      // });
    },
    // 表格筛选项
    getSearchKey(arr) {
      arr.forEach((item) => {
        if (!item.children || item.children.length == 0) {
          if (item.DicName == "备注") return;
          this.searchList.push({
            SortField: item.DicKey,
            DicName: item.DicName,
            SortType: "asc",
            checked: false,
          });
        } else this.getSearchKey(item.children);
      });
    },
    // 树默认选中
    getDefaultProp(arr) {
      arr.forEach((item) => {
        if (item.children && item.children.length > 0) {
          this.defaultKeys.push(item.DicKey);
          this.getDefaultProp(item.children);
        }
      });
    },
    // 树选择
    nodeClick(e, isCheck) {
      this.columnChange = false;
      /**
         * tree数据源绑定col使用以下方法
          this.$set(e, "show", isCheck);
         */
      let idx = this.col.findIndex((item) => item.DicKey == e.DicKey);
      this.col[idx].show = isCheck;
      this.$nextTick(() => {
        this.columnChange = true;
      });
    },
    // 编辑
    handleEditChange() {
      this.$refs.Edit.openForm();
    },
    handleCellEnter() { },
    handleCellLeave() { },

    // 字符串截取
    getStringFormat(str) {
      if (str) {
        let arr = str.split("");
        let version = Number(arr[1]);
        let newVersion = version + 1;
        return newVersion;
      }
    },

    // 解锁编辑页面
    handleSelectionChange(data, ShopChangeReason, SupplementReason) {
      // let newVersion = "V" + this.getStringFormat(data.ChangeVersion) + ".0";
      var that = this;
      that.$http
        .post("/Shop_Manage/Shop_BaseInfo/ShopInfo_VersionUnlock", {
          ShopId: data.Id,
          ShopChangeReason: ShopChangeReason,
          SupplementReason: SupplementReason,
        })
        .then((res) => {
          if (res.Success) {
            that.$message.success("变更成功,已解锁");
            that.$nextTick(function () {
              // that.$refs.tableItem.pagination.current = 1;
              that.$refs.tableItem.queryParam[0].Keyword =
                that.taskObj.ProjectId;
              that.$refs.tableItem.totalObj.ShopArea = 0;
              that.$refs.tableItem.totalObj.PowerConsumption = 0;
              that.$refs.tableItem.totalObj.KitchenExhaustFumes = 0;
              // 关闭变更原因弹窗
              that.$refs.tableItem.dialogVisible = false;
              that.$refs.tableItem.VersionLoading = false;
              // 刷新列表数据
              that.$refs.tableItem.loadMore();

            });
          } else {
            that.$message.error(res.Msg);
          }
        });
    },
    // 点击查看事件
    handleDetailChange(data) {
      // 单独处理内审，外审，设备材料审核 附件
      let InternalAuditUrlArr = []; //内审
      let ExternalAuditUrlArr = []; //外审
      let EquipmentAndMaterFileArr = []; //设备材料审核

      if (data.InternalAuditUrl) {
        InternalAuditUrlArr = data.InternalAuditUrl.split(",");
      }
      if (data.InternalAuditUrl) {
        ExternalAuditUrlArr = data.ExternalAuditUrl.split(",");
      }
      if (data.EquipmentAndMaterFile) {
        EquipmentAndMaterFileArr = data.EquipmentAndMaterFile.split(",");
      }
      // 插入data中
      this.$set(data, "InternalAuditUrlArr", InternalAuditUrlArr);
      this.$set(data, "ExternalAuditUrlArr", ExternalAuditUrlArr);
      this.$set(data, "EquipmentAndMaterFileArr", EquipmentAndMaterFileArr);
      // 显示查看组件
      // this.isShow = 2;

      this.$refs.updateRecord.open(data, this.newCol);
    },
    // 删除
    handleDeleteChange(data) {
      var that = this;
      this.$confirm("此操作将当前条删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((result) => {
          // 刷新表格
          that.loading = true;
          const loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that.$http
            .post("/Shop_Manage/Shop_BaseInfo/DeleteData", [data.Id])
            .then((res) => {
              if (res.Success) {
                setTimeout(() => {
                  loading.close();
                  that.$message.success("已删除");
                }, 500);
                that.$nextTick(function () {
                  that.$refs.tableItem.pagination.current = 1;
                  that.$refs.tableItem.queryParam[0].Keyword =
                    this.taskObj.ProjectId;
                  that.$refs.tableItem.totalObj.ShopArea = 0;
                  that.$refs.tableItem.totalObj.PowerConsumption = 0;
                  that.$refs.tableItem.totalObj.KitchenExhaustFumes = 0;
                  // 刷新列表数据
                  that.$refs.tableItem.loadMore();
                });
              } else {
                loading.close();
                that.$message.error(res.Msg);
              }
            });
        })
        .catch((msg) => { });
    },

    // 撤销版本
    submitButtonBatchVersion(row) {
      var that = this;
      this.$confirm("此操作将撤销商铺全部信息至上一个版本, 是否继续?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 刷新表格
          that.loading = true;
          const loading = that.$loading({
            lock: true,
            text: "版本撤销中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });

          that.$http
            .post(
              "/Shop_Manage/Shop_BaseInfo/ShopInfo_VersionReturn?shopId=" +
              row.Id
            )
            .then((res) => {
              if (res.Success) {
                setTimeout(() => {
                  loading.close();
                  that.$message({
                    type: "success",
                    message: "已撤销",
                  });
                }, 500);
                // 刷新列表数据
                that.$refs.tableItem.loadMore();
              } else {
                // 刷新表格
                that.loading = true;
                loading.close();
                that.$message.error(res.Msg);
              }
            });
        })
        .catch((action) => {
          that.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 版本提交和批量版本提交
    submitVersionRequest(url, msg, params, type) {
      var that = this;
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 刷新表格
          that.loading = true;
          const loading = that.$loading({
            lock: true,
            text: "版本提交中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that.$http.post(url, params.length ? params : {}).then((res) => {
            if (res.Success) {
              setTimeout(() => {
                loading.close();
                that.$message.success("版本提交成功");
              }, 500);
              that.$nextTick(function () {
                // that.$refs.tableItem.pagination.current = 1;
                that.$refs.tableItem.queryParam[0].Keyword =
                  this.taskObj.ProjectId;
                that.$refs.tableItem.totalObj.ShopArea = 0;
                that.$refs.tableItem.totalObj.PowerConsumption = 0;
                that.$refs.tableItem.totalObj.KitchenExhaustFumes = 0;
                // 刷新列表数据
                that.$refs.tableItem.loadMore();
              });
            } else {
              // 刷新表格
              that.loading = false;
              loading.close();
              that.$message.error(res.Msg);
            }
          });
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "info",
              message: "已取消",
            });
          }, 500);
        });
    },

    // 当前行版本提交
    handleSubmitButtonChange(row, type) {
      let msg = "";
      let params = null;
      let url = "";
      if (type === "批量") {
        msg = "此操作将批量提交版本, 是否继续?";
        params = row;
        url = "/Shop_Manage/Shop_BaseInfo/ShopInfo_VersionBatchSubmission";
      } else {
        msg = "此操作将提交当前版本, 是否继续?";
        params = row.Id;
        url =
          "/Shop_Manage/Shop_BaseInfo/ShopInfo_VersionSubmission?shopId=" +
          params;
      }

      this.submitVersionRequest(url, msg, params, type);
    },
    // 返回招商组件
    back() {
      // 显示表格
      this.isShow = 1;
    },
    // tableitem子组件传递过来的方法  是否隐藏显示当前表格
    parentSetIsShow(show, data) {
      if (show === "TableDetail") {
        // 打开版本记录组件
        this.$refs.tableDetail.open(data.ProjectCode, data, this.newCol);
      }
      // 隐藏弹窗
      // this.isShow = data;
    },
    // 查看更记录组件的返回
    recordBack() {
      // 返回表格页面
      this.isShow = 1;
    },
    handleSuccess(res, file) {
      this.dialogVisible = false;
      this.importLoading = false;
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeUpload(file) {
      this.importLoading = true;
      const isXLS = file.name.indexOf(".xls");
      if (isXLS == -1) {
        this.importLoading = false;
        this.$message.error("请上传xls 或 xlsx 类型文件!");
      }
      return isXLS !== -1;
    },
    // 分页变更
    currentChange(e) {
      this.pagination.PageIndex = e;
      this.getDataList();
    },
    // 获取整体大表格数据
    GetTreeDataListByDicKey() {
      this.$http
        .post(
          "/Base_ProjectManage/Project_DictionaryMain/GetTreeDataListByDicKey?DicKey=ShopExtensionInfo"
        )
        .then((res) => {
          if (res.Success) {
            if (this.col) {
              // 首页整体大表格渲染处理
              this.col = this.col.concat(
                res.Data.map((item) => {
                  return {
                    ...item,
                    show: true,
                    disabled: true,
                  };
                })
              );
              if (this.newCol) {
                // 用于传递到新建或编辑组件渲染处理
                this.newCol = this.newCol.concat(
                  res.Data.map((item) => {
                    return {
                      ...item,
                      show: true,
                      disabled: true,
                    };
                  })
                );
              }

              this.newCol.forEach((item, index) => {
                if (item.DicKey === "Shop_BaseInfo") {
                  item["disabled"] = false;
                } else {
                  item["disabled"] = true;
                }
                if (index !== 0) {
                  item.children.forEach((v) => {
                    if (v.children == null) {
                      if (v.DicName === "备注") {
                        v["isRequire"] = false;
                      } else {
                        v["isRequire"] = true;
                      }
                    } else {
                      v.children.forEach((m, l) => {
                        if (m.DicName === "备注") {
                          m["isRequire"] = false;
                        } else {
                          if (m.DicName === "附件数") {
                            m["isRequire"] = false;
                          } else {
                            m["isRequire"] = true;
                          }
                        }
                      });
                    }
                  });
                }
              });

              // 存储大表格列表数据到vuex
              this.$store.commit("setTableListData", this.newCol);
            }
            this.init();
          }
        });
    },
    // 列表展示方案
    // getOptionsData() {
    //   this.$http
    //     .post("/Shop_Manage/Shop_ColumnsScheme/GetDataList", {})
    //     .then((res) => {
    //       if (res.Success) {
    //         this.filterList = res.Data;
    //       }
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
.el-tag {
  margin: 5px;
  cursor: pointer;
}

.container {
  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .tools {
      display: flex;
      align-items: center;
    }

    .marginRight {
      margin-right: 10px;
    }

    .filterBox {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .positionA {
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
    padding: 0;
    font-size: 12px;
    vertical-align: baseline;

    i {
      font-size: 14px;
    }
  }

  .mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 90;
    top: 0;
    left: 0;
  }

  .tree {
    position: absolute;
    top: 35px;
    right: 30px;
    z-index: 99;
    max-height: 450px;
    overflow-y: auto;
    // padding-right: 10px;
    background: #fff;
    box-shadow: 1px 2px 5px #aaa;
    border-radius: 5px;
    width: 190px;

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      /* 滚动条里面小方块 */
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #e0e0e0;
    }

    &::-webkit-scrollbar-track {
      /* 滚动条里面轨道 */
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      // border-radius: 10px;
      background: transparent;
    }
  }

  .sort {
    // right: 140px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 2;
    padding: 10px 0;
    cursor: pointer;
    padding-bottom: 30px;

    .content {
      max-height: 400px;
      width: 100%;
      overflow: scroll;

      .sortItem {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;

        .noWarp {
          display: inline-block;
          width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:hover {
          background: #fae7e7;
          color: #d1100b;

          .iconGroup {
            i {
              color: #fff;
              border-color: #fff;
            }
          }
        }

        .iconGroup {
          i {
            margin-left: 3px;
            display: inline-block;
            border: 1px solid #ccc;
            padding: 3px;
            border-radius: 3px;
          }

          .active {
            border-color: #d1100b !important;
            color: #d1100b !important;
          }
        }
      }

      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        /* 滚动条里面小方块 */
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #e0e0e0;
      }

      &::-webkit-scrollbar-track {
        /* 滚动条里面轨道 */
        // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        // border-radius: 10px;
        background: transparent;
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      right: 0;
      padding-bottom: 4px;
      padding-right: 4px;
    }
  }
}
</style>
