var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"StructureMajor",staticStyle:{"margin-top":"30px"}},[_c('span',{staticClass:"bottom-span",staticStyle:{"padding-left":"20px"}},[_vm._v(_vm._s(_vm.im.DicName))]),_c('div',{staticStyle:{"margin-top":"20px"}},[(_vm.im.children.length > 0)?_c('div',{staticStyle:{"display":"flex"}},_vm._l((_vm.im.children),function(item,index){return (
          item.DicKey === 'LiveLoadOnTheBottomPlateSurface' ||
          item.DicKey === 'ConstantLoadOnTheBottomPlateSurface'
        )?_c('div',{key:index,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 structure-left background"},[_vm._v(_vm._s(item.DicName))]),(item.DicKey === 'LiveLoadOnTheBottomPlateSurface')?_c('div',{staticClass:"flex5 structure-right background"},[_vm._v(" "+_vm._s(_vm.entity.LiveLoadOnTheBottomPlateSurface)+" ")]):_vm._e(),(item.DicKey === 'ConstantLoadOnTheBottomPlateSurface')?_c('div',{staticClass:"flex5 structure-right background"},[_vm._v(" "+_vm._s(_vm.entity.ConstantLoadOnTheBottomPlateSurface)+" ")]):_vm._e()]):_vm._e()}),0):_vm._e(),(_vm.im.children.length > 0)?_c('div',{staticStyle:{"display":"flex"}},_vm._l((_vm.im.children),function(item,index){return (
          item.DicKey === 'KitchenPanelLiveLoad' ||
          item.DicKey === 'ConstantLoadOnKitchenPanelSurface'
        )?_c('div',{key:index,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 structure-left background"},[_vm._v(_vm._s(item.DicName))]),(item.DicKey === 'KitchenPanelLiveLoad')?_c('div',{staticClass:"flex5 structure-right background"},[_vm._v(" "+_vm._s(_vm.entity.KitchenPanelLiveLoad)+" ")]):_vm._e(),(item.DicKey === 'ConstantLoadOnKitchenPanelSurface')?_c('div',{staticClass:"flex5 structure-right background"},[_vm._v(" "+_vm._s(_vm.entity.ConstantLoadOnKitchenPanelSurface)+" ")]):_vm._e()]):_vm._e()}),0):_vm._e(),(_vm.im.children.length > 0)?_c('div',{staticStyle:{"display":"flex"}},[_vm._l((_vm.im.children),function(item,index){return (item.DicKey === 'LoweringPlateHeight')?_c('div',{key:index,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 structure-left background"},[_vm._v(_vm._s(item.DicName))]),_c('div',{staticClass:"flex5 structure-right background"},[_vm._v(_vm._s(_vm.entity.LoweringPlateHeight))])]):_vm._e()}),_vm._l((_vm.im.children),function(item,index){return (item.DicKey === 'RemarkStructure')?_c('div',{key:index,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 structure-left background"}),_c('div',{staticClass:"flex5 structure-right background"})]):_vm._e()})],2):_vm._e(),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"structure-left background",staticStyle:{"flex":"0.9"}},[_vm._v("备注")]),_c('div',{staticClass:"structure-right background",staticStyle:{"flex":"9.1"}},[_vm._v(" "+_vm._s(_vm.entity.RemarkStructure)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }