<!-- 查看组件 -->
<template>
  <div id="">
    <el-drawer :title="'工程编号:' + title" :visible.sync="visible" direction="rtl" size="80%" class="record">
      <!-- padding:20px 20px 0; -->
      <div v-if="changeVersionListData.length !== 0" style="
          padding: 20px 20px 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          position: absolute;
          width: 100%;
          text-align: right;
        ">
        <img v-if="isRow == true" @click="handelColRowChange(false)"
          style="width: 28px; margin-right: 20px; cursor: pointer" src="../../../assets/hengxiang-active.png" alt="" />
        <img v-if="isRow == false" @click="handelColRowChange(false)"
          style="width: 28px; margin-right: 20px; cursor: pointer" src="../../../assets/hengxiang.png" alt="" />
        <img v-show="isCol == true" @click="handelColRowChange(true)"
          style="width: 28px; margin-right: 20px; cursor: pointer" src="../../../assets/shuxiang-active.png" alt="" />
        <img v-show="isCol == false" @click="handelColRowChange(true)"
          style="width: 28px; margin-right: 20px; cursor: pointer" src="../../../assets/shuxiang.png" alt="" />

        <img @click="handelColse" style="width: 37px; margin-right: 20px; cursor: pointer"
          src="../../../assets/delete.png" alt="" />
      </div>
      <!-- 返回 -->
      <!-- <div class="back">
	 	    <i size="25" class="el-icon-back" @click="tableDetailBack"></i>
	 	  </div>
	 	  <div class="" style="padding: 6px 0; display: flex">
	 	    <div style="margin: 0 auto; font-weight: bold">更新记录详情</div>
	 	  </div> -->
      <!-- 横向布局 -->
      <div id="" v-show="isRowCol == false">
        <el-card style="margin-top: 80px; padding: 0 20px">
          <el-collapse accordion v-model="activeNames" @change="handleChange">
            <el-collapse-item name="1" v-for="(item, index) in changeVersionListData" :key="index" :name="index">
              <span class="collapse-title" slot="title">{{
                "版本号:" + item.ChangeVersion
              }}</span>
              <el-descriptions title="用户信息" v-for="(v, i) in item.newColInfo" :key="i">
                <el-descriptions-item label="楼栋">{{
                  v.BuildingCode
                    ? v.BuildingCode
                    : v.ProjectCode
                      ? v.ProjectCode.split("-")[0]
                      : ""
                }}
                </el-descriptions-item>
                <el-descriptions-item label="地上/地下">{{
                  v.IsOnGround == 1 ? "地上" : "地下"
                }}
                </el-descriptions-item>
                <el-descriptions-item label="楼层">{{
                  v.Storey
                }}
                </el-descriptions-item>
                <el-descriptions-item label="工程编号">
                  <el-tag size="small">{{ v.ProjectCode }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="招商编码">{{
                  v.InvestmentCode
                }}
                </el-descriptions-item>
                <el-descriptions-item label="商铺编号">{{
                  v.ShopCode
                }}
                </el-descriptions-item>
                <el-descriptions-item label="商铺名称">{{
                  v.ShopName
                }}
                </el-descriptions-item>
                <el-descriptions-item label="商铺面积">{{
                  v.ShopArea
                }}
                </el-descriptions-item>
                <el-descriptions-item label="业态级别">{{
                  v.ShopFormatClass
                }}
                </el-descriptions-item>
                <el-descriptions-item label="业态名称">{{
                  v.ShopFormatName
                }}
                </el-descriptions-item>
                <el-descriptions-item label="商铺品牌信息">
                  <el-tag size="small">{{
                    v.ShopBrand
                  }}
                  </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="商铺分隔模式">{{
                  v.ShopSeparation
                }}
                </el-descriptions-item>
                <el-descriptions-item label="商铺变更原因">{{
                  v.ShopChangeReason
                }}
                </el-descriptions-item>
                <el-descriptions-item label="商铺补充变更原因">{{
                  v.SupplementReason
                }}
                </el-descriptions-item>
                <el-descriptions-item label="备注">{{
                  v.Remark
                }}
                </el-descriptions-item>
              </el-descriptions>
              <div>
                <table-item-copy :isShow="isShow" :loading="loading" :col="otherInfoTableHeader"
                  :tableData="item.tableListData"></table-item-copy>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-card>

        <!-- 空状态 -->
        <el-card v-if="changeVersionListData.length == 0">
          <el-empty description="暂无提交版本"></el-empty>
        </el-card>
      </div>

      <!-- 纵向布局 -->
      <div v-show="isRowCol == true" style="margin-top: 80px; padding-left: 20px">
        <ColTable ref="ColTable" :parentObj="{ ...this }" :colData="col" />
      </div>

      <!-- 图片预览组件 -->
      <!-- <img-view :show="imageViewerVisible" :url-list="imageViewerList" @close="imageViewerVisible = false"> -->
      <!-- </img-view> -->
    </el-drawer>
  </div>
</template>

<script>
// 引入图片预览组件
import ImgView from "@/components/imgView.vue";
// 引入递归子组件
import TableItemCopy from "./tableItemCopy";

// 引入纵向布局组件
import ColTable from "./colTable.vue";

export default {
  //父组件通过 props 向下传递数据给子组件
  props: {
    //树型结构表头数据
    // col: {
    // 	type: Array,
    // 	default: () => [],
    // },
  },
  //import引入的组件需要注入此处能使用
  components: {
    // 图片预览组件
    ImgView,
    TableItemCopy,
    ColTable,
  },
  //此处存放数据
  data() {
    return {
      // attractInvertment传递过来的col数据
      col: [],
      // 横向布局还是纵向布局
      isCol: false,
      isRow: true,
      isRowCol: false,
      newColInfo: [],
      // 基础信息表头数据
      basisInfoTableHeader: [],
      // 其余表格内的表头数据
      otherInfoTableHeader: [],
      // 表格加载中提示
      loading: false,
      title: "",
      visible: false,
      activeNames: "",
      // 预览的图片数据
      imageViewerList: [],
      // 是否展示图片预览组件
      imageViewerVisible: false,
      // 浏览器当前可视窗口高度
      windowHeight: 0,
      // 浏览器当前可视窗口宽度
      windowWidth: 0,
      // 是否显示表格
      isShow: 1,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      queryParam: {
        BuildingId: "",
        IsOnGround: "",
        Storey: "",
        ShopCode: "",
        ShopLastIds: "",
        Conditions: [
          {
            Condition: "",
            Keyword: "",
          },
        ],
      },
      sortInfor: {
        SortField: "Id",
        SortType: "desc",
      },
      // 版本号数据列表
      changeVersionListData: [],
      // 首页传递过来的当前行数据
      rowEntity: {},
    };
  },
  //计算属性
  computed: {},
  //监听data中数据变化
  watch: {},
  created() {
  }, //生命周期 - 创建完成
  mounted() {
    //页面滚动了的距离
    let height =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    // console.log('页面滚动的距离',height )
    // 动态获取当前浏览器窗口的高度
    // this.getWindowHeight(); // 在初始化时获取窗口高度
    // window.addEventListener("resize", this.getWindowHeight); // 在窗口大小改变时重新获取窗口高度
  }, //生命周期 - 挂载完成
  activated() {
  }, //若页面有keep-alive缓存，此函数会触发
  // beforeDestroy() {
  //   window.removeEventListener("resize", this.getWindowHeight); // 组件销毁时移除resize事件监听器
  // },
  //方法集合
  methods: {
    // 横向还是竖向布局
    handelColRowChange(type) {
      this.isRowCol = type;
      if (type == true) {
        // 竖向布局
        this.isRow = false;
        this.isCol = true;
      } else {
        // 横向布局
        this.isRow = true;
        this.isCol = false;
      }
    },
    // 获取版本号列表数据
    getVersionListData() {
      var that = this;
      // 版本号列表初始化
      this.$http
        .post("/Shop_Manage/Shop_BaseInfo_History/GetDataListByMoreCondition", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          ...this.sortInfor,
          Search: this.queryParam,
        })
        .then((res) => {
          if (res.Success) {
            this.pagination.total = res.Total;
            if (res.Data.length !== 0) {
              // 处理循环数据
              let data = res.Data;
              let newArr = [];
              var arr = [];
              let newColInfo = {};

              console.log("获取便跟个原因原来数据", this.newColInfo, res.Data);
              this.$set(this.newColInfo, "value", "");
              res.Data.forEach((item, index) => {
                var newArrObj = {};
                var newObj = {};
                this.newColInfo.forEach((v) => {
                  Object.keys(item).map((key) => {
                    if (v.DicKey === "BuildingId") {
                      newObj["BuildingCode"] = item["BuildingCode"];
                    } else {
                      if (v.DicKey === key) {
                        newObj[key] = item[key];
                      }
                    }
                    newObj["ProjectCode"] = item["ProjectCode"];
                    newObj["ShopCode"] = item["ShopCode"];
                    newObj["InvestmentCode"] = item["InvestmentCode"];
                  });
                });

                Object.keys(item).map((key) => {
                  Object.keys(newObj).map((keyi) => {
                    if (key === keyi) {
                      newArrObj[key] = item[key];
                    }
                  });
                });
                arr.push(newArrObj);
                //循环处理数据，放到最外层
                // console.log(data[index], 'tableData,ns')
                function a(obj) {
                  let newObj = {};

                  function digui(obj, newObj) {
                    for (let key in obj) {
                      if (
                        Object.prototype.toString.apply(obj[key]) ==
                        "[object Object]"
                      ) {
                        digui(obj[key], newObj);
                      } else if (
                        Object.prototype.toString.apply(obj[key]) ==
                        "[object Array]" &&
                        !obj[key][0]?.id
                      ) {
                        if (newObj[key]) {
                          newObj[key].push(...obj[key]);
                        } else {
                          newObj[key] = [];
                          newObj[key].push(...obj[key]);
                        }
                      } else if (
                        Object.prototype.toString.apply(obj[key]) ==
                        "[object Array]" &&
                        obj[key][0]?.id
                      ) {
                        obj[key].forEach((ite) => {
                          for (let k in ite) {
                            if (k != "id") {
                              if (newObj[k]) {
                                newObj[k].push(ite[k]);
                              } else {
                                newObj[k] = [];
                                newObj[k].push(ite[k]);
                              }
                            }
                          }
                        });
                      } else {
                        newObj[key] = obj[key];
                      }
                    }
                  }

                  digui(obj, newObj);
                  return newObj;
                }

                data[index] = a(data[index]);
                let newDataOjb = {
                  // 商铺id
                  ShopLastIds: item.ShopLastIds,
                  // 版本号
                  ChangeVersion: item.ChangeVersion,
                  // 每一个版版本下的表头数据
                  newColInfo: [arr[index]],
                  // 当前版本下的表格内容数据
                  tableListData: [data[index]],
                };
                newArr.push(newDataOjb);

                newArr.forEach((item) => {
                  item.tableBody = item.tableListData[0];
                });
                that.changeVersionListData = newArr;
              });
              console.log(
                "获取版本号列表tableDetail1==========================",
                that.changeVersionListData
              );
            } else {
              that.changeVersionListData = [];
              return;
            }
          } else {
            // setTimeout(function () {
            that.changeVersionListData = [];
            // }, 1000)
          }
        });
    },

    // 图片预览
    imgViewChange(img) {
      // 将展示的图片到数组
      this.imageViewerList[0] = img;
      // 显示预览组件
      this.imageViewerVisible = true;
    },
    handleDetailChangeCopy(e) {
    },

    // 折叠面板折叠点击事件
    handleChange(event, item) {
    },

    // 返回
    tableDetailBack() {
      this.$emit("tableDetailBack");
    },

    // 数组去重
    deleteRepeat(arr) {
      for (var i = 0; i < arr.length - 1; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].DicKey == arr[j].DicKey) {
            arr.splice(j, 1);
            //因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
            j--;
          }
        }
      }
      return arr;
    },

    // 分割表头数据，，基础信息表头 和 其余表头
    open(title, data, newCol) {
      console.log("获取attractInvestment传递过来的nwCOl", title, data, newCol);
      this.col = newCol;
      this.visible = true;
      // 默认不打开折叠
      this.activeNames = "";
      // 清空横向和纵向布局
      this.isCol = false;
      // 默认显示横向布局
      this.isRowCol = false;
      this.isRow = true;
      this.title = title ? title : "";
      // 赋值父组件传递过来的当前行数据
      this.rowEntity = data;

      let newColData = this.col;
      let arr = [];
      let newArr = [];
      newColData.forEach((item) => {
        // console.log('循环', item)
        if (item.DicKey === "ProjectCode") {
          // console.log('chldren', item.children)
          arr = item.children;
        }
        if (item.DicKey === "Shop_BaseInfo") {
          // console.log('chldren', item.children)
          newArr = item.children;
        }
      });
      let obj = {
        DicKey: "ProjectCode",
        DicName: "工程编码",
        fixed: "left",
        isNumber: true,
        isRequire: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
        width: "110px",
      };
      arr.unshift(obj);

      let ARR = this.deleteRepeat(arr);
      // console.log('是否重复了', arr, ARR, newArr)
      let newARR = [...ARR, ...newArr];

      let newCOL = this.col;
      let newCOLARR = [];
      newCOL.forEach((item, index) => {
        if (index !== 1) {
          newCOLARR.push(item);
        }
      });
      let otherNewCol = this.deleteRepeat(newCOLARR);

      let columnTable = [];
      let otherTableData0 = [];
      let otherTableData1 = [];
      let otherTableData2 = [];
      var otherTableData3 = [];
      otherNewCol.forEach((item, index) => {
        if (item.children.length !== 0) {
          if (index == 3) {
            otherTableData3 = item;
            // console.log('打印3', item, index)
          } else if (index == 1) {
            otherTableData1 = item.children;
            // console.log('打印444item.chlidren', item.children, index)
          } else if (index == 2) {
            otherTableData2 = item.children;
          }
          // columnTable=columnTable.concat(item.children)
        }
      });

      // console.log('最新',  this.col,  this.col[0].children, otherNewCol)
      let otherColData = this.col[0];
      otherColData.children = newARR;
      // console.log('打印合并的基础信息',otherColData )

      let otherTableTotalData = [...otherTableData1, ...otherTableData2];
      otherTableTotalData.unshift(otherColData);
      otherTableTotalData.push(otherTableData3);

      let newOtherTableTotalData = this.deleteRepeat(otherTableTotalData);
      newOtherTableTotalData[0].children = this.deleteRepeat(
        newOtherTableTotalData[0].children
      );

      newOtherTableTotalData.forEach((item) => {
        item.changeVersionListData = this.changeVersionListData;
      });
      this.$nextTick(function () {
        this.$refs.ColTable.colData = newOtherTableTotalData;
        console.log(newOtherTableTotalData, "横头");
        this.$refs.ColTable.checkList = [];
        // tab切换恢复初始化
        this.$refs.ColTable.isActive = 0;
        // console.log('打印coltable里的coldata=========================', this.$refs.ColTable.colData)
      });
      //提出基础信息表头数据
      let newBasisInfoTableHeader = this.col.filter((item) => {
        if (item.DicKey === "Shop_BaseInfo") {
          return item;
        }
      });
      // 其余表头数据
      let newOthersTableHeader = this.col.filter((item) => {
        if (item.DicKey !== "Shop_BaseInfo") {
          //提出基础信息
          return item;
        }
      });

      // 调整顺序  目前还是有问题
      this.otherInfoTableHeader = newOthersTableHeader;

      // 楼栋id
      this.queryParam.BuildingId = data.BuildingId;
      // 地上地下
      this.queryParam.IsOnGround = data.IsOnGround;
      // 楼层
      this.queryParam.Storey = data.Storey;
      // 商铺编号
      this.queryParam.ShopCode = data.ShopCode;
      // 商铺id
      this.queryParam.ShopLastIds = data.Id;
      if (newBasisInfoTableHeader.length !== 0) {
        this.newColInfo = newBasisInfoTableHeader[0].children;
      }
      // 获取版本号列表数据
      this.getVersionListData();
      // console.log('获取表头', this.newColInfo, this.col)
    },

    // 自定义关闭弹窗
    handelColse() {
      this.visible = false;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .collapse-title {
  //配合上面得el-collapse可以将右侧得图标移动到最左边
  flex: 1 0 90%;
  order: 1;

  .el-collapse-item__header {
    flex: 1 0 auto;
    order: -1;
  }
}

// 修改覆盖el-drawer头部结构布局
::v-deep .el-drawer {
  .el-drawer__header {
    position: absolute;
    background-color: #fff;
    width: 100%;

    .el-drawer__close-btn {
      // font-size: 40px;
      color: transparent;
    }

    span {
      font-size: 21px !important;
    }
  }

  .el-drawer__body {
    overflow: hidden !important;

    .el-card {
      .el-card__body {
        padding: 0 !important;
      }
    }
  }
}

/*覆盖element  el-drawer 头部的下边距样式*/
::v-deep .el-drawer__container.el-drawer__open {
  .el-drawer {
    .el-drawer__header {
      margin-bottom: 0px;
    }
  }
}

/*覆盖card样式*/
.el-card {
  border: none !important;
}

/*取消card的阴影*/
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}

/*覆盖折叠面板的样式*/
.el-collapse {
  .el-collapse-item {
    ::v-deep .el-collapse-item__header {
      font-size: 15px;
      font-weight: 700;
    }
  }
}

/*覆盖描述列表的样式*/
.el-descriptions {
  ::v-deep .el-descriptions__header {
    .el-descriptions__title {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.record {
  .back {
    cursor: pointer;
    width: 30px;
    font-size: 25px;
  }

  .recordImg {
    cursor: pointer;

    img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
      border-radius: 10px;
    }
  }
}
</style>
