<template>
  <!--  这里是暖通模块区域组件-->
  <div class="HVACMajor" style="margin-top: 30px">
    <!--    空调条件-->
    <div>
      <div style="padding-left: 20px; margin-bottom: 10px">
        {{ im.DicName }}
      </div>
      <!--      空调形式  VRF条件预留-->
      <div class="">
        <div
          class=""
          v-if="im.children && v.DicKey === 'AirConditioningCondition'"
          v-for="(v, i) in im.children"
        >
          <div
            style="
              padding: 15px;
              width: 97%;
              text-align: center;
              border: 1px solid #ccc;
              margin: 10px 0;
            "
          >
            {{ v.DicName }}
          </div>
          <div class="flex">
            <div
              class="flex flex5"
              v-if="
                m.DicKey === 'AirConditioningForm' ||
                m.DicKey === 'VRFConditionReservation'
              "
              v-for="(m, mi) in v.children"
              :key="mi"
            >
              <div class="flex5 design-left background">
                {{ m.DicName }}
              </div>
              <div
                v-if="m.DicKey === 'AirConditioningForm'"
                class="flex5 design-right background"
              >
                {{
                  entity.AirConditioningCondition
                    ? entity.AirConditioningCondition.AirConditioningForm
                    : ""
                }}
              </div>
              <div
                v-if="m.DicKey === 'VRFConditionReservation'"
                class="flex5 design-right background"
              >
                {{
                  entity.AirConditioningCondition?(entity.AirConditioningCondition.VRFConditionReservation
                    ? "已预留"
                    : "未预留"):''
                }}
              </div>
            </div>
          </div>
          <div class="flex" style="margin-top: 10px">
            <!--          总制冷量-->
            <div class="flex flex5">
              <div
                class="flex flex5"
                v-if="m.DicKey === 'TotalCoolingCapacity'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{ entity.AirConditioningCondition?entity.AirConditioningCondition.TotalCoolingCapacity:'' }}
                </div>
              </div>
            </div>
            <!--          空调冷指标-->
            <div class="flex flex5">
              <div
                class="flex flex5"
                v-if="m.DicKey === 'RefrigerationLoadIndex'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{
                    entity.VentilationCondition?(entity.VentilationCondition.RefrigerationLoadIndex
                      ? $tools.momenyFormat(
                          entity.VentilationCondition.RefrigerationLoadIndex
                        )
                      : ""):''
                  }}
                </div>
              </div>
            </div>
          </div>

          <!--空调冷水管径-->
          <div class="flex">
            <div
              style="width: 25%"
              v-for="(item, index) in entity.AirConditioningCondition?entity.AirConditioningCondition
                .AC_ColdWaterPipeDiameter:''"
              :key="index"
            >
              <div
                class="flex"
                v-if="m.DicKey === 'AC_ColdWaterPipeDiameter'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">{{ item }}</div>
              </div>
            </div>
          </div>

          <div class="flex" style="margin-top: 10px">
            <!--          总供热量-->
            <div class="flex flex5">
              <div
                class="flex flex5"
                v-if="m.DicKey === 'TotalHeatingCapacity'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{ entity.AirConditioningCondition?entity.AirConditioningCondition.TotalHeatingCapacity:'' }}
                </div>
              </div>
            </div>
            <!--          供热负荷指标-->
            <div class="flex flex5">
              <div
                class="flex flex5"
                v-if="m.DicKey === 'HeatingLoadIndex'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{
                    entity.AirConditioningCondition?(entity.AirConditioningCondition.HeatingLoadIndex
                      ? $tools.momenyFormat(
                          entity.AirConditioningCondition.HeatingLoadIndex
                        )
                      : ""):''
                  }}
                </div>
              </div>
            </div>
          </div>

          <!--空调热水管径-->
          <div class="flex">
            <div
              style="width: 25%"
              v-for="(item, index) in entity.AirConditioningCondition?entity.AirConditioningCondition
                .AC_HotWaterPipeDiameter:''"
              :key="index"
            >
              <div
                class="flex"
                v-if="m.DicKey === 'AC_HotWaterPipeDiameter'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">{{ item }}</div>
              </div>
            </div>
          </div>

          <div class="flex" style="margin-top: 10px">
            <!--         新风量-->
            <div class="flex flex5">
              <div
                class="flex flex5"
                v-if="m.DicKey === 'AirChangeFlow'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{ entity.AirConditioningCondition?entity.AirConditioningCondition.AirChangeFlow:'' }}
                </div>
              </div>
            </div>
            <!--    新风量的布局站位-->
            <div class="flex flex5">
              <div class="flex flex5">
                <div class="flex5 design-left background"></div>
                <div class="flex5 design-right background"></div>
              </div>
            </div>
          </div>

          <!--送风管道尺寸-->
          <div class="flex">
            <div
              style="width: 25%"
              v-for="(item, index) in entity.AirConditioningCondition?entity.AirConditioningCondition
                .FreshAirPipelineSize:''"
              :key="index"
            >
              <div
                class="flex"
                v-if="m.DicKey === 'FreshAirPipelineSize'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{ item.FAPS_A?item.FAPS_A:'' + " X " + item.FAPS_B?item.FAPS_B:'' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    通风条件-->

    <div>
      <div class="">
        <div
          class=""
          v-if="im.children && v.DicKey === 'VentilationCondition'"
          v-for="(v, i) in im.children"
        >
          <div
            style="
              padding: 15px;
              width: 100%;
              text-align: center;
              border: 1px solid #ccc;
              margin: 10px 0;
            "
          >
            {{ "厨房" + v.DicName }}
          </div>
          <!--          厨房排油烟量-->
          <div class="flex" style="margin-top: 10px">
            <div class="flex flex5">
              <div
                class="flex flex5"
                v-if="m.DicKey === 'KitchenOilFumeDischarge'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{ entity.VentilationCondition?entity.VentilationCondition.KitchenOilFumeDischarge:"" }}
                </div>
              </div>
            </div>
            <!--    排油烟量的布局站位-->
            <div class="flex flex5">
              <div class="flex flex5">
                <div class="flex5 design-left background"></div>
                <div class="flex5 design-right background"></div>
              </div>
            </div>
          </div>

          <!--排油烟管道尺寸-->
          <div class="flex">
            <div
              style="width: 25%"
              v-for="(item, index) in entity.VentilationCondition?entity.VentilationCondition
                .KitchenExhaustPipeSize:''"
              :key="index"
            >
              <div
                class="flex"
                v-if="m.DicKey === 'KitchenExhaustPipeSize'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{ item.KEPS_A?item.KEPS_A:'' + " X " + item.KEPS_B?item.KEPS_B:'' }}
                </div>
              </div>
            </div>
          </div>

          <!--          事故排风量-->
          <div class="flex" style="margin-top: 10px">
            <div class="flex flex5">
              <div
                class="flex flex5"
                v-if="m.DicKey === 'KitchenNormalAccidentExhaustVolume'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{
                    entity.VentilationCondition?entity.VentilationCondition
                      .KitchenNormalAccidentExhaustVolume:''
                  }}
                </div>
              </div>
            </div>
            <!--    事故排风量的布局站位-->
            <div class="flex flex5">
              <div class="flex flex5">
                <div class="flex5 design-left background"></div>
                <div class="flex5 design-right background"></div>
              </div>
            </div>
          </div>

          <!--事故排风管道尺寸-->
          <div class="flex">
            <div
              style="width: 25%"
              v-for="(item, index) in entity.VentilationCondition?entity.VentilationCondition
                .KitchenNormalAccidentExhaustPipeSize:''"
              :key="index"
            >
              <div
                class="flex"
                v-if="m.DicKey === 'KitchenNormalAccidentExhaustPipeSize'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{ item.KNAEPS_A?item.KNAEPS_A:'' + " X " + item.KNAEPS_B?item.KNAEPS_B:'' }}
                </div>
              </div>
            </div>
          </div>

          <!--       厨房补风量-->
          <div class="flex" style="margin-top: 10px">
            <div class="flex flex5">
              <div
                class="flex flex5"
                v-if="m.DicKey === 'KitchenAirReplenishmentVolume'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{
                    entity.VentilationCondition?entity.VentilationCondition.KitchenAirReplenishmentVolume:''
                  }}
                </div>
              </div>
            </div>
            <!--    厨房补风量的布局站位-->
            <div class="flex flex5">
              <div class="flex flex5">
                <div class="flex5 design-left background"></div>
                <div class="flex5 design-right background"></div>
              </div>
            </div>
          </div>

          <!--厨房管道尺寸-->
          <div class="flex">
            <div
              style="width: 25%"
              v-for="(item, index) in entity.VentilationCondition?entity.VentilationCondition
                .KitchenAirReplenishmentPipeSize:''"
              :key="index"
            >
              <div
                class="flex"
                v-if="m.DicKey === 'KitchenAirReplenishmentPipeSize'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{ item.KARPS_A?item.KARPS_A:'' + " X " + item.KARPS_B?item.KARPS_B:'' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    个护通风条件-->
    <div>
      <div class="">
        <div
          class=""
          v-if="im.children && v.DicKey === 'VentilationCondition'"
          v-for="(v, i) in im.children"
        >
          <div
            style="
              padding: 15px;
              width: 100%;
              text-align: center;
              border: 1px solid #ccc;
              margin: 10px 0;
            "
          >
            个护通风条件
          </div>

          <!--       个护排风量-->
          <div class="flex" style="margin-top: 10px">
            <div class="flex flex5">
              <div
                class="flex flex5"
                v-if="m.DicKey === 'IndividualProtectiveExhaustAirVolume'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{
                    entity.VentilationCondition?entity.VentilationCondition
                      .IndividualProtectiveExhaustAirVolume:''
                  }}
                </div>
              </div>
            </div>
            <!--    个护排风量量的布局站位-->
            <div class="flex flex5">
              <div class="flex flex5">
                <div class="flex5 design-left background"></div>
                <div class="flex5 design-right background"></div>
              </div>
            </div>
          </div>

          <!--个护排风管道尺寸-->
          <div class="flex">
            <div
              style="width: 25%"
              v-for="(item, index) in entity.VentilationCondition?entity.VentilationCondition
                .IndividualProtectiveExhaustAirPipeSize:''"
              :key="index"
            >
              <div
                class="flex"
                v-if="m.DicKey === 'IndividualProtectiveExhaustAirPipeSize'"
                v-for="(m, mi) in v.children"
                :key="mi"
              >
                <div class="flex5 design-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 design-right background">
                  {{ item.IPEAPS_A?item.IPEAPS_A:'' + " X " + item.IPEAPS_B?item.IPEAPS_B:'' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    备注-->
    <div
      class="flex flex5"
      v-if="im.children && v.DicKey === 'HVACRemark'"
      v-for="(v, vi) in im.children"
    >
      <div
        class="flex design-left background"
        style="flex: 1.18; justify-content: flex-end"
      >
        备注
      </div>
      <div class="design-right background" style="flex: 8.82">
        {{ entity.HVACRemark }}
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
  name: "HVACMajor.vue",
  data() {
    // 这里存放数据
    return {};
  },
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    parentObj: {
      type: Object,
      default: () => {},
    },
    im: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  // 方法集合
  methods: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  //过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {},
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  //进入的时候触发
  activated() {},
  //离开的时候触发
  deactivated() {},
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
.HVACMajor {
  .design-left.background {
    background-color: #f7eded;
    border: none !important;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .design-right.background {
    background-color: #ffe3e3;
    border: none !important;
    padding-left: 10px;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
