<template>
	<div class="container" style="">
		<el-row>
			<!-- 左侧menu   此未布局占位-->
			<el-col :span="3">
				<div v-if="checkList.length > 1" class="menu" style="border:1px solid transparent;text-align: center;">
					<span class="hoverSpan" style="font-size: 13px;padding-bottom: 2px;cursor: pointer;"
						@click="contrastVersionChange">开始对比</span>
				</div>
				<div v-else class="menu" style="border:1px solid transparent">
					<span style=""></span>
				</div>
			</el-col>
			<!-- 右侧版本号 -->
			<el-col :span="21" class="" @scroll="onScroll" style="display: flex;">
				<div style="">
					<div
						style="padding: 5px; border:1px solid #d9e5f3;width: 170px;text-align: right;padding-right: 20px;font-size: 13px;">
						版本号
					</div>
				</div>
				<div v-for="(item, index) in parentObj.changeVersionListData" :key="index"
					style="border-top:1px solid #d9e5f3;border-right:1px solid #d9e5f3;width: 196px;display: flex;justify-content: center;align-items: center;">
					<el-checkbox-group v-model="checkList" @change="changeVersion">
						<el-checkbox :label="item.ChangeVersion"></el-checkbox>
					</el-checkbox-group>
					<!-- <div
						style="padding: 5px; border:1px solid #d9e5f3;width: 170px;text-align: right;padding-right: 20px;font-size: 13px;">
						{{item.ChangeVersion}}
					</div> -->
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="1">
			<el-col :span="3">
				<div class="menu" style="">
					<!--导航选择事件-->
					<el-menu :default-active="activeStep" @select="jump" style="">
						<el-menu-item v-for="(item, index) in colData" :index="`${index}`" :key="index">
							<span slot="title">{{ item.DicName }}</span>
						</el-menu-item>
					</el-menu>
				</div>
			</el-col>
			<!--绑定scroll事件需要监听-->
			<el-col :span="21" class="scroll_cls" @scroll="onScroll">
				<div v-for="(item, index) in colData" :key="index" style="">
					<div class="title scroll-item" :id="item.name"
						style=" padding: 10px;background-color: #f0f3f8;font-size: 13px;">
						{{ item.DicName }}
					</div>
					<div :id="i" v-for="(m, i) in item.children" :key="i" ref="divContent" style="">
						<div>
							<div style="display: flex;">
								<div
									:style="{color: tableNoData.includes(m.DicKey) ?'red':''}"
									style="border-top:1px solid #d9e5f3;border-right:1px solid #d9e5f3;width: 180px;padding-left: 20px;font-size: 13px;padding: 8px;text-align: right;">
									<span>{{ m.DicName }}</span>
								</div>
								<template v-if="!tableNoData.includes(m.DicKey)">
									<div  style="border:1px solid #d9e5f3;width: 180px;padding-left: 20px;font-size: 13px;padding: 8px;"
										v-for="(k, n) in parentObj.changeVersionListData" :key="n">
										<span v-html="getData(k.tableBody[m.DicKey])"></span>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>

		<!-- 版本对比-->
		<ContractVersion ref="ContractVersion" :parentObj="{ ...this }" :drawerVisible="drawerVisibleVersion"
			@handleClose="handleClose" />
	</div>
</template>
<script>
import ContractVersion from './contractVersion.vue'
export default {
	//父组件通过 props 向下传递数据给子组件
	props: {
		parentObj: {
			type: Object
		},
		// //树型结构表头数据
		// colData: {
		// 	type: Array,
		// 	default: () => [],
		// },
	},
	components: {
		ContractVersion
	},
	data() {
		return {
			// 是否显示版本对比弹窗
			drawerVisibleVersion: false,
			// 是否显示开始对比按钮
			checkList: [],
			activeName: "Shop_BaseInfo",
			colData: [],
			tabPosition: 'left',
			activeStep: "0",
			// 左侧tab激活标签
			tabName: 'Shop_BaseInfo',
			tabs: [{
				name: '基础信息',
				refName: 'Shop_BaseInfo',
			},
			{
				name: '电气',
				refName: 'ElectricalMajor',
			},
			{
				name: '暖通',
				refName: 'HVACMajor',
			},
			{
				name: '给排水',
				refName: 'WSADMajor',
			},
			{
				name: '智能化',
				refName: 'IntelligentMajor',
			},
			{
				name: '基本信息',
				refName: 'EngineeringBaseInfo',
			},
			{
				name: '图纸报审',
				refName: 'DrawingApproval',
			},
			{
				name: '设备材料审核',
				refName: 'EquipmentAndMaterialReview',
			},
			{
				name: '合约信息',
				refName: 'ContractInfoJson',
			},
			],
			//表头无数据列表
			tableNoData: ["ElectricalBox","FreshAirPipelineSize","KitchenExhaustPipeSize","KitchenNormalAccidentExhaustPipeSize","KitchenAirReplenishmentPipeSize","IndividualProtectiveExhaustAirPipeSize"]
		}
	},
	mounted() {
		this.$nextTick(() => {
			console.log(1)
			window.addEventListener('scroll', this.onScroll, true)
			this.colData.forEach(item => {
				item.children = this.flattenTree(item.children);
			})
		})
	},

	created() {
		// this.getHight()
		// window.addEventListener('resize', this.getHight)
	},
	destroyed() {
		// window.removeEventListener('resize', this.getHight)
	},
	methods: {
		//处理表头
		flattenTree(tree, result = []) {
			tree.forEach(item => {
				result.push(item);
				if (item.children && item.children.length > 0) {
					this.flattenTree(item.children, result);
				}
			})
			return result;
		},
		// 对象数组去重处理
		removeDuplicateObj(newArr1) {
			let obj = {};
			newArr1 = newArr1.reduce((newArr, next) => {
				obj[next.DicKey]
					? ""
					: (obj[next.DicKey] = true && newArr.push(next));
				return newArr;
			}, []);
			return newArr1;
		},
		onScroll(e) {
			let scrollItems = document.querySelectorAll(".scroll-item");
			// console.log(scrollItems)
			// console.log(e)
			for (let i = scrollItems.length - 1; i >= 0; i--) {
				// 判断滚动条滚动距离是否大于当前滚动项可滚动距离
				let judge =
					e.target.scrollTop >=
					scrollItems[i].offsetTop - scrollItems[0].offsetTop;
				if (judge) {
					// console.log(i)
					this.activeStep = i.toString();
					break;
				}
			}
		},
		getData(data) {
			if (data === true) {
				return '是'
			} else if (data === false) {
				return '否'
			}
			if (Array.isArray(data)) {
				return `${data.join('<br/>')}`
			} else {
				return data
			}
		},
		jump(index) {
			console.log(index)
			let target = document.querySelector(".scroll_cls");
			let scrollItems = document.querySelectorAll(".scroll-item");
			// 判断滚动条是否滚动到底部
			if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
				console.log(index)
				console.log(typeof index)
				this.activeStep = index;
			}
			let total = scrollItems[index].offsetTop - scrollItems[0].offsetTop; // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)
			console.log(total)
			let distance = document.querySelector(".scroll_cls").scrollTop; // 滚动条距离滚动区域顶部的距离
			console.log(distance)
			// let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)
			// 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次
			// 计算每一小段的距离
			let step = total / 50;
			if (total > distance) {
				smoothDown(document.querySelector(".scroll_cls"));
			} else {
				let newTotal = distance - total;
				step = newTotal / 50;
				smoothUp(document.querySelector(".scroll_cls"));
			}

			// 参数element为滚动区域
			function smoothDown(element) {
				if (distance < total) {
					distance += step;
					element.scrollTop = distance;
					setTimeout(smoothDown.bind(this, element), 10);
				} else {
					element.scrollTop = total;
				}
			}

			// 参数element为滚动区域
			function smoothUp(element) {
				if (distance > total) {
					distance -= step;
					element.scrollTop = distance;
					setTimeout(smoothUp.bind(this, element), 10);
				} else {
					element.scrollTop = total;
				}
			}

			document.querySelectorAll('.scroll-item').forEach((item, index1) => {
				if (index === index1) {
					item.scrollIntoView({
						block: 'start',
						behavior: 'smooth'
					})
				}
			})
		},
		// 版本选择对比
		changeVersion(event) {
			console.log('选择', this.checkList, this.parentObj.changeVersionListData)
			let newChangeVersionListData = []
			let changeVersionListData = this.parentObj.changeVersionListData
			changeVersionListData.forEach(item => {
				this.checkList.forEach((v, i) => {
					if (item.ChangeVersion === v) {
						newChangeVersionListData.push(item)
					}
				})
			})
			// 对象数组去重处理
			this.removeDuplicateObj(newChangeVersionListData)
			console.log('最后选择的是newChangeVersionListData', newChangeVersionListData)

			// 赋值对比子组件数据
			this.$nextTick(function () {
				this.$refs.ContractVersion.colData = this.colData
				this.$refs.ContractVersion.changeVersionListData = newChangeVersionListData
			})
		},
		// 版本开始对比
		contrastVersionChange() {
			console.log('版本对比')
			// 显示版本对比弹窗
			this.drawerVisibleVersion = true
		},
		// 关闭弹窗
		handleClose(fun) {
			// 关闭弹窗
			this.drawerVisibleVersion = false
		}


	},
}
</script>
<style lang="scss" scoped>
.container {
	.hoverSpan:hover {
		color: #D1100B !important;
		border-bottom: 1px solid #D1100B !important;
	}

	.menu {
		.el-menu-item.is-active {
			color: #D1100B !important;
			background-color: rgb(254, 67, 101, 0.4) !important;
		}

		.el-menu {
			background-color: #eef3f9;

			.el-menu-item {
				// background-color: #D1100B;
			}
		}
	}

}



.scroll_cls {
	height: 560px;
	overflow: auto;
}

.auto-adjust-edit {
	flex-basis: 100%;
	display: flex;
	overflow: hidden;
	height: 500px;
	height: 100%;

	// 侧边栏
	.operation-btn {
		width: 9.5%;
		height: 95%;
		margin-right: 0.5%;
		padding-top: 1%;
		margin-top: 4px;
		background: white;
		border: 1px solid rgb(190, 188, 188);
		border-radius: 6px;
		box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);

		.btn-item {
			height: 40px;
			line-height: 40px;
			padding-left: 20px;
			cursor: pointer;
		}
	}

	// 表单内容
	.scroll-content {
		height: 100%;
		width: 90%;
		overflow: auto;

		.scroll-item {
			background: white;
			border-radius: 8px;
			margin-bottom: 6px;
			border: 1px solid rgb(216, 214, 214);

			// 标题
			.part-title {
				height: 40px;
				line-height: 40px;
				font-weight: 600;
				padding-left: 10px;
			}

			// 表单
			::v-deep .el-form {
				border: 1px solid rgb(190, 189, 189);
				width: 98%;
				margin: 10px auto 30px;
				border-radius: 6px;

				::v-deep.el-form-item {
					margin-bottom: 12px;
					margin-top: 10px;
				}
			}
		}
	}
}
</style>