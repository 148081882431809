<template>
  <!--  结构部分-->
  <div>
    <!--   厨房区域降板（有/无）-->
    <div class="flex" style="border: 2px solid #000">
      <div class="flex8 flexCol">
        <!--              顶部备注区域布局站位-->
        <div class="flex" style="height: 22px">
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
        </div>
        <!--        内容区域 这里的height 30px 和下拉列表数据点额高度一致 否则回出现高度不一致情况-->
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              楼板板面活荷载
            </div>
            <div class="flex jbetween flex5 height30 borderTop borderRight font16">
              <span>{{ parentObj.entity.LiveLoadOnTheBottomPlateSurface ? parentObj.entity.LiveLoadOnTheBottomPlateSurface :''}}</span>
              <span class="color font14 paddingRight5">{{ parentObj.entity.LiveLoadOnTheBottomPlateSurface ?'(kN/㎡)' :''}}</span>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              楼板板面恒荷载
            </div>
            <div class="flex jbetween flex5 height30 borderTop borderRight font16">
              <span>{{ parentObj.entity.ConstantLoadOnTheBottomPlateSurface ? parentObj.entity.ConstantLoadOnTheBottomPlateSurface :""}}</span>
              <span class="color font14 paddingRight5">{{ parentObj.entity.ConstantLoadOnTheBottomPlateSurface ? '(kN/㎡)' :""}}</span>
            </div>
          </div>
        </div>
        <div class="flex" >
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span class="">厨房区域楼板板面活荷载</span>
            </div>
            <div class="flex jbetween flex5 height30 borderTop borderRight font16">
              <span class="">
                {{ parentObj.entity.KitchenPanelLiveLoad  ? parentObj.entity.KitchenPanelLiveLoad  :""}}</span
              >
              <span class="color font14 paddingRight5">
                {{ parentObj.entity.KitchenPanelLiveLoad  ? "(kN/㎡)" :""}}</span
              >
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              厨房区域楼板板面恒荷载
            </div>
            <div class="flex jbetween flex5 height30  borderTop borderRight font16">
             <span> {{ parentObj.entity.ConstantLoadOnKitchenPanelSurface ? parentObj.entity.ConstantLoadOnKitchenPanelSurface  :""}}</span>
              <span class="font14 color paddingRight5"> {{ parentObj.entity.ConstantLoadOnKitchenPanelSurface ?'(kN/㎡)' :""}}</span>
            </div>
          </div>
        </div>

        <div class="flex" >
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderBottom font16">
              厨房区域降板（有/无）
            </div>
            <div class="flex5">
              <InputComponent
                ref="LoweringPlate"
                :type="'select'"
                :options="jiangbanOptions"
                @change="
                  inputSelectChange($event, 'LoweringPlateHeightSelectValue')
                "
              ></InputComponent>
            </div>
          </div>
          <!--          布局站位-->
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight borderBottom font16">
              厨房区域降板厚度
            </div>
            <div class="flex jbetween flex5 height30 borderTop borderRight borderBottom font16">
              <span>{{ parentObj.entity.LoweringPlateHeight ?parentObj.entity.LoweringPlateHeight:""}}</span>
              <span class="color font14 paddingRight5">{{ parentObj.entity.LoweringPlateHeight ?"(mm)" :""}}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="text-center font16">备注</div>
        <div style="padding-top:1px;">
 <InputComponent
            ref="RemarkStructureInput"
            :placeHolder="'请输入备注'"
            :rows="3"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkStructureArea')"
          ></InputComponent>
        </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputComponent from "@/views/Project/view/components/inputComponents/inputComponent.vue";
// 模拟数据
import { chujiangbanOptions } from "../../mock.js";
export default {
  props: {
    parentObj: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  components: { InputComponent },
  data() {
    return {
      isPrint: false, //是否打印
      // 输入框绑定的值
      selectValue: "提供",
      // 降板有无绑定
      jiangBanSelectValue: "有",
      // 电表区域备注绑定
      RemarkElectrical: "",
      // 空调管制绑定
      jiangbanOptions: chujiangbanOptions,
    };
  },
  methods: {
    input(row) {
      console.log("input函数返回", row);
      // 判断相关备注和下拉提供非提供逻辑  如果没有对应的数组  提供  否则 不提供
      this.$nextTick(function () {
        this.$refs.LoweringPlate.value = row.LoweringPlateHeightSelectValue;
      });

      this.$refs.RemarkStructureInput.keyWords = row.RemarkStructureArea //结构备注回显
        ? row.RemarkStructureArea
        : "";
    },
    // 相关下拉参数处理回调
    inputSelectChange(event, type) {
      this.parentObj.openSetPrintSelectValue(event, type);
    },
    // 相关备注参数处理回调
    inputChange(event, type) {
      this.parentObj.openSetPrintInputValue(event, type);
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
/*覆盖card样式*/
.el-card {
  border: none !important;
}
/*取消card的阴影*/
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}

/*覆盖element card 卡片样式*/
::v-deep .recordContent {
  .el-card {
    .el-card__body {
      padding: 0px !important;
    }
  }
}
</style>
