<!-- 上传组件 -->
<template>
  <!-- <div style="position: relative;"> -->
  <div :style="{ width: width, height: height, position: 'relative' }">
    <!--    上传单张图片-->
    <!--    根据需求显示不同的上传布局 isUploadType 判断-->
    <div
      v-if="isUploadType"
      class="isUploadType"
      :style="{ width: widthHeight, height: widthHeight }"
    >
      <div
        v-if="uoloadTypeImage"
        class="imghover"
        :style="{ width: widthHeight, height: widthHeight }"
      >
        <!--        //有图片时显示上传图片-->
        <div class="mask" :style="{ width: widthHeight, height: widthHeight }">
          <div class="uploader-operate">
            <em
              style="
                position: relative;
                font-size: 25px;
                margin-right: 20px;
                color: transparent;
              "
              class="el-icon-zoom-in"
              @click.prevent="imagePreview"
            ></em>
            <em
              v-if="uoloadTypeImage && !disabledChange"
              class="el-icon-delete"
              @click.prevent="imageDelete"
            ></em>
          </div>
        </div>
        <img
          :style="{ width: widthHeight, height: widthHeight }"
          v-if="uoloadTypeImage"
          :src="uoloadTypeImage"
          alt="icon"
        />
        <!--        //无图片时显示默认图标-->
        <!--        <em v-else class="el-icon-plus avatar-uploader-icon"></em>-->
        <!--        //修改和新增转态显示-->
        <!--        <div v-if="tipShow" slot="tip" class="el-upload__tip">-->
        <!--          尺寸应为 82 * 82, 且不超过 500 k 的 jpg / png 文件-->
        <!--        </div>-->
        <!--        //删除 v-if="fileList.length >0"-->
        <!--        @click.stop="form.icon = ''"-->
      </div>
      <el-upload
        v-else
        :style="{ width: widthHeight, height: widthHeight }"
        class="avatar-uploader"
        :before-upload="before_Upload"
        :action="$rootUrl + '/Shop_Manage/Shop_BaseInfo/UploadFileByForm'"
        :headers="{
          Authorization: 'Bearer ' + TokenCache.getToken(),
        }"
        :disabled="disabledChange"
        :on-success="uploadSuccess"
        :show-file-list="false"
      >
        <div>
          <img style="width: 30px" src="../assets/xinjian.png" />
        </div>
        <!--        //无图片时显示默认图标-->
        <!--        <i-->
        <!--          class="el-icon-plus avatar-uploader-icon"-->
        <!--          style="border: 3px solid green"-->
        <!--        ></i>-->
      </el-upload>
    </div>
    <!-- 上传附件 -->
    <el-upload
      v-else
      v-loading="loading"
      :action="$rootUrl + '/Shop_Manage/Shop_ExtensionInfo/UploadFileByForm'"
      :headers="{
        Authorization: 'Bearer ' + TokenCache.getToken(),
      }"
      :multiple="true"
      :file-list="fileList"
      :before-upload="before_Upload"
      :on-success="uploadSuccess"
      :on-exceed="masterFileMax"
      :on-remove="handleRemove"
      :limit="limit"
      :disabled="is_disabled"
      :auto-upload="autoUpload"
      :accept="accept"
      :drag="drag"
      :name="name"
      :data="data"
      :with-credentials="cookieOK"
      :list-type="listType"
      ref="upload"
      class="upCss"
    >
      <el-button size="mini" type="primary" :disabled="is_disabled"
        >点击上传</el-button
      >
      <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
    </el-upload>

    <!-- 图片预览组件 -->
    <img-view
      :show="imageViewerVisible"
      :url-list="imageViewerList"
      @close="imageViewerVisible = false"
    >
    </img-view>
  </div>
</template>
<script>
import TokenCache from "@/utils/cache/TokenCache";
// 引入图片预览组件
import ImgView from "@/components/imgView.vue";
// 下载文件方法（之前写过，有兴趣的小伙伴可以搜：Base64转File）
//   import { dataURLtoFile } from "@/utils";
export default {
  components: {
    ImgView,
  },
  props: {
    // 哪一种上传布局
    isUploadType: { type: Boolean, default: false },
    // 上传文件的地址 必填
    url: { type: String, required: true },
    // 这里是为了当我们没有点击提交时 父组件通过绑定的数组进行删除上传的文件
    value: { type: Array },
    // 这里是为了显示文件
    fileData: { type: Array },
    // 上传文件的格式
    fileType: { type: Array },
    //   最大允许上传个数
    limit: { type: Number, default: 3 },
    // 是否禁止上传
    is_disabled: {
      //是否禁止，true是禁止，false不禁止
      type: Boolean,
      default: true,
    },
    //图片宽高
    widthHeight: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
    // 图片的高度
    height: {
      type: String,
      default: "80%",
    },
    disabledChange: {
      type: Boolean,
      default: false,
    },
    data: {
      //上传时额外带的参数
      type: Object,
      default: () => {
        return {};
      },
    },
    name: {
      //上传的文件字段名
      type: String,
      default: "file",
    },
    cookieOK: {
      //支持发送 cookie 凭证信息
      type: Boolean,
      default: false,
    },
    drag: {
      //是否启用拖拽上传
      type: Boolean,
      default: false,
    },
    listType: {
      // 文件列表的类型 - text/picture/picture-card
      type: String,
      // default: "picture-card",
      default: "text",
    },
    // 传递过来的图片路径
    uoloadTypeImage: {
      type: String,
      default: "",
    },
    /**
     * 自动上传参数
     * */
    autoUpload: {
      // 是否需要选取完自动上传功能
      type: Boolean,
      default: true,
    },
    accept: {
      //接受文件类型-图片上传类型-不同的格式之间以逗号隔开
      type: String,
      // default:'.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      default: "",
    },
  },
  model: {
    prop: "value",
    event: "update",
  },
  data() {
    return {
      // 预览的图片数据
      imageViewerList: [],
      // 是否展示图片预览组件
      imageViewerVisible: false,
      // 获取token工具
      TokenCache,
      myUrl: "",
      fileList: [],
      // 当前上传的文件列表
      arrayModel: [],
      loading: false,
      mFileList: this.fileData,
    };
  },

  created() {
    // 处理传过来的url 这里我传图片请求头要带token,如果不需要可删除
    //   this.myUrl = `${this.url}?token=${token}`;
    //   this.myUrl = `${this.url}`;
    // 这里是为了如果传过文件 那么显示出来有文件
    // console.log("最终upload组件获取到的filedata", this.fileData);
    // if (this.fileData !== null) {
    //   this.fileList = this.fileData;
    // }
  },
  watch: {
    // 监听传递过来的图片数据  不要卸载created里面  否则会出现无数据的情况
    fileData(o) {
      if (o) this.fileList = this.fileData;
    },
  },
  mounted() {},
  methods: {
    // 图片预览
    imagePreview() {
      // 单独提取图片处理
      // let image = this.fileList[0].response.thumbUrl;
      // let arr = [];
      // arr[0] = {
      //   jietu: image,
      // };
      // 将展示的图片到数组
      this.imageViewerList[0] = this.uoloadTypeImage;
      // 显示预览组件
      this.imageViewerVisible = true;
    },
    // 图片删除
    imageDelete() {
      // 清空filelist列表数据
      // if(this.isUploadType == false){
      //   this.fileList=[]
      // }
      // this.fileList = [];

      this.$emit("imageDelete");
    },
    changeHandle(file, fileList) {
      if (file.response && file.response.code == 500) {
        this.$emit("upload-error", file);
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning("只能上传1张图片！");
    },
    // 图片上传个数校验
    // 图片上传个数校验
    masterFileMax(files, fileList) {
      this.$emit("masterFileMax");
      this.$message.warning(`请最多上传3个图片`);
    },
    // 上传图片预览
    // 图片预览
    imgViewChange(row) {
      // 将展示的图片到数组
      this.imageViewerList[0] = row.jietu;
      // 显示预览组件
      this.imageViewerVisible = true;
    },
    // 删除文件是触发的钩子
    handleRemove(file) {
      console.log("子组件删除附件", file, this.fileList);
      // 看当前有多少个文件
      for (let i = 0; i < this.fileList.length; i++) {
        // 要删除的文件 == 已经上传过的文件
        if (file.uid == this.fileList[i].uid) {
          // 从文件列表中删除
          this.fileList.splice(i, 1);
          this.arrayModel.splice(i, 1);

          let fileData = this.fileList.map((item) => {
            return {
              name: item.name,
              url: item.response ? item.response.url : item.url,
            };
          });

          let newFileData = [];
          fileData.forEach((item) => {
            newFileData.push(item.url);
          });

          this.$emit("handleRemove", fileData, newFileData);

          // 后续打开
          // 发起请求 删除文件
          // this.$http({
          //   url: this.$http.adornUrl(`/sys/file/deleteRelated`),
          //   method: "post",
          //   params: fileName: this.arrayModel[i]
          // }).then(res=>{
          //   // 更新绑定的v-model
          //   this.arrayModel.splice(i, 1);
          //   this.$emit("update", this.arrayModel);
          // });
        }
      }
    },

    // 下载文件 或放大
    handlePictureCardPreview(file) {
      // 将展示的图片到数组
      this.imageViewerList[0] = file.url;
      // 显示预览组件
      this.imageViewerVisible = true;

      // 暂时不打开  以下为下载逻辑  后续需要打开
      // this.loading = true;
      // if (!file.base) {
      //   this.$message({
      //     message: "刚刚上传的文件还需要下载？",
      //     type: "warning"
      //   });
      //   return
      // }
      // // 获取文件Base64格式
      // this.$http({
      //   url: this.$http.adornUrl(`/sys/file/getFile`),
      //   method: "get",
      //   params: this.$http.adornParams({
      //     fileName: file.base
      //   })
      // }).then(({ data }) => {
      //   // 下载文件
      //   dataURLtoFile(data.bean, file.name);
      // });
    },

    //上传前的操作，一般来判断文件的格式和大小
    before_Upload(file) {
      // let name = file.name.split('.')
      // console.log('上传之前的文件格式', file, name, name[0].length)
      // 判断上传的图片格式前缀长度不能为一个   只能>=1
      // if(name[0].length==1) {
      //   // this.$refs.upload.clearFiles(); //去掉文件列表去掉文件列表去掉文件列表去掉文件列表*******************************************
      //   this.$message.error('上传的文件前缀格式过短，请修改后上传')
      // }
      //    待校验格式
      // 上传图片之前格式校验  暂时不删除  后续有需要打开即可
      // const isJpgOrPng = file.type === "image/png" || file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJpgOrPng) {
      //   this.$message.error("请上传正确格式文件!");
      // } else if (!isLt2M) {
      //   this.$message.error("文件大小不能超过 2MB!");
      // }
      // return isJpgOrPng && isLt2M;

      // 设置默认上传文件格式（不知道的搜 MIME类型 ）  暂时不删除
      // let myType = [
      //   "application/msword",
      //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      //   "application/pdf"
      // ];
      // // 和传进来的文件格式合并一下
      // if (this.fileType) {
      //   for (let i = 0; i < this.fileType.length; i++) {
      //     myType.push(this.fileType[i]);
      //   }
      // }
      // const isIMAGE = myType.includes(file.type);
      // const isLt = file.size / 1024 / 1024 < 30;
      // if (!isIMAGE) {
      //   this.$message.error("文件上传格式错误！");
      //   return false;
      // }
      // if (!isLt) {
      //   this.$message.error("文件大于30MB！");
      //   return false;
      // }
    },
    // isUploadType上傳成功狗子函數
    uploadTypeSuccess(response, file, fileList) {
      console.log("uploadTypeSuccess上傳成功", response, file, fileList);
      this.$emit("uploadTypeSuccess", response.thumbUrl);
    },

    // 文件上传成功的钩子
    uploadSuccess(response, file, fileList) {
      console.log(
        "文件上传成功狗子函数====================",
        response,
        file,
        fileList
      );
      // ************************************************判断上传的图片格式前缀长度不能为一个   只能>=1************************************************
      // if(!response.thumbUrl){
      //   // 除去上传失败的文件  upload为绑定的upload ref值  参考网址https://www.cnblogs.com/Scooby/p/17264667.html
      //   const errFileIndex = this.$refs.upload.uploadFiles.findIndex(item => item.name === file.name);
      //   console.log('获取不成功后的参数', errFileIndex, fileList)
      //   // if(errFileIndex != -1){
      //   //   this.$refs.upload.uploadFiles.splice(errFileIndex,1);
      //   // }
      //
      //   return
      // }

      // // 更新一下 文件列表
      this.fileList = fileList;

      // if (response.code !== 0) return;
      // 这里 arrayModel 存储的是后端返回的编译后的文件名
      this.arrayModel.push(response.Url);

      console.log("打印this.arrayModel", this.arrayModel);

      // console.log('子组件上传附件', response, file, fileList, this.arrayModel)
      let fileData = fileList.map((item) => {
        console.log("upload组件文件上传", fileList, item, response);
        return {
          name: item.name,
          url: item.response ? item.response.url : item.url,
        };
      });

      let newFileData = [];
      fileData.forEach((item) => {
        newFileData.push(item.url);
      });
      // console.log('上传后的upload子组件里的',fileData , newFileData)
      // console.log('需要传递的', newFileData)
      // 上传成功则往v-model数据上添加
      this.$emit("uploadSuccess", fileData, newFileData);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.isUploadType {
  .avatar-uploader {
    .el-upload--text {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .imghover:hover .mask {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .imghover:hover .el-icon-zoom-in {
    color: #fff !important;
  }
  .imghover:hover .el-icon-delete {
    color: #fff !important;
  }
  .mask {
    cursor: pointer;
    position: absolute;
    height: 150px;
    width: 150px;
    .uploader-operate {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      .el-icon-zoom-in {
        position: relative;
        color: transparent;
        font-size: 25px;
        margin-right: 20px;
      }
      .el-icon-delete {
        position: relative;
        color: transparent;
        font-size: 25px;
      }
    }
  }
  .avatar-uploader {
    .el-upload {
      height: 100% !important;
    }
  }
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upCss {
  display: flex;
  flex-direction: column;
  ::v-deep .el-upload--picture-card {
    background-color: transparent;
    border: none;
    line-height: 44px;
    height: 0px;
  }
  /*覆盖element上传组件的listtype样式*/
  ::v-deep .el-upload.el-upload--text {
    display: flex;
  }
  ::v-deep .el-upload-list.el-upload-list--text {
    margin-left: 10px;
    display: flex;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    .el-upload-list__item.is-success {
      height: 20px;
      width: 100%;
    }
  }
  ::v-deep .el-upload-dragger {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .el-upload-list__item {
    width: 100px;
    height: 100px;
  }
}

.el-upload-list__item {
  ::v-deep .el-upload-list__item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.upDate {
  width: 100px;
  height: 100px;
  text-align: center;

  p {
    margin: 2px;
  }
}

::v-deep .el-upload {
  width: 100%;
}

::v-deep .el-upload .el-upload-dragger {
  width: 100%;
}
</style>
