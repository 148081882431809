<template>
  <el-dialog :title="title" :visible.sync="dialogVisible">
    <div class="mailTable">
      <el-table
          :data="tableData"
          @selection-change="handleSelectionChange"
          ref="multipleTable"
          :header-row-style="{ 'height':'50px','font-size':'14px' }"
          :row-style="{ 'height':'60px' }"
          height="60vh"
          style="width: 100%;overflow: auto"
      >
        <el-table-column v-if="type == 'checkBox'" align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column v-else type="radio" width="55">
          <template slot-scope="scope">
            <el-radio v-model="radioSelect" :label="scope.$index">{{ "" }}
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="Icon" label="头像" align="center" width="100px" style="margin-right: 50px">
          <template slot-scope="scope">
            <el-avatar :src="scope.row.Icon" :size="40" style="vertical-align: middle;"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="姓名">
          <template slot-scope="scope">
            {{ scope.row.NickName || scope.row.Name }}
          </template>
        </el-table-column>
        <el-table-column prop="Phone" label="手机号"></el-table-column>
        <el-table-column label="账户状态" prop="IsLogout" align="center" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.IsLogout" type="danger" style="font-weight: bold">已注销</el-tag>
            <el-tag v-else type="success">使用中</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
      <el-button size="mini" type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: {default: "选择联系人", require: false, type: String},
    type: {default: "checkBox", require: false, type: String},
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      selectRows: [],
      radioSelect: null,
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.getDataList();
      this.$nextTick(() => {
        this.$refs.multipleTable.clearSelection();
        this.radioSelect = null;
      });
    },
    handleSelectionChange(val) {
      this.selectRows = val;
    },
    confirm() {
      if (this.type == "checkBox") {
        if (this.selectRows.length <= 0)
          return this.$message.warning("请选择用户");
        this.$emit("select", [...this.selectRows]);
        this.dialogVisible = false;
      } else {
        if (this.radioSelect === null)
          return this.$message.warning("请选择用户");
        this.$emit("select", {...this.tableData[this.radioSelect]});
        this.dialogVisible = false;
      }
    },
    getDataList() {
      this.$http
          .post("/Base_Mail/Base_MailList/GetDataList", {
            SortField: "Initial",
            SortType: "asc",
          })
          .then((res) => {
            if (res.Success) {
              this.tableData = res.Data;
              console.log(this.tableData)
              this.tableData = this.tableData.filter(x => !x.IsLogout)
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.mailTable {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;

  .el-table::before {
    z-index: inherit;
  }


  &::-webkit-scrollbar {
    height: 2px;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    /* 滚动条里面小方块 */
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e0e0e0;
  }

  &::-webkit-scrollbar-track {
    /* 滚动条里面轨道 */
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    // border-radius: 10px;
    background: transparent;
  }
}
</style>