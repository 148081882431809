<template>
  <!-- 招商信息 -->
  <div class="ContractInfoJson" style="padding-bottom: 50px; margin-top: 20px">
    <!--    <div style="margin-left: 20px; margin-bottom: 20px">-->
    <!--      <el-descriptions title="">-->
    <!--        <el-descriptions-item label="工程编号">-->
    <!--          <el-tag size="small">{{ rows.ProjectCode }}</el-tag>-->
    <!--        </el-descriptions-item>-->
    <!--        <el-descriptions-item label="业态">-->
    <!--          <el-tag size="small">{{ rows.ShopFormatClass }}</el-tag>-->
    <!--        </el-descriptions-item>-->
    <!--        <el-descriptions-item label="商铺面积">-->
    <!--          <el-tag size="small">{{ rows.ShopArea }}</el-tag>-->
    <!--        </el-descriptions-item>-->
    <!--      </el-descriptions>-->
    <!--    </div>-->
    <el-form ref="Form" size="mini" :model="Form" :rules="Rules" :hide-required-asterisk="false" label-position="right"
      label-width="auto" style="">
      <!-- 合约信息 -->
      <div style="margin-bottom: 10px" v-loading="loadingInfo">
        <div style="
            display: flex;
            justify-content: space-between;
            margin-left: 20px;
            padding-bottom: 5px;
            border-bottom: 1px solid #d3cbcb;
            margin-bottom: 20px;
          ">
          <div style="display: flex">
            <img style="width: auto; height: 22px; margin-right: 5px" src="../../../../assets/heyue.png" alt="" />
            <span>合约信息</span>
          </div>
          <div style="padding-right: 20px">
            <span v-if="ContractInfoJsonDisabled == false" @click="editCancel('ContractInfoJsonDisabled')"
              style="cursor: pointer; padding-right: 10px">取 消</span>
            <span v-else style="padding-right: 10px; color: red" class="edit"
              @click="ContractInfoJsonDisabled = false">编辑</span>
            <span v-if="ContractInfoJsonDisabled == false" class="save" @click="handleSubmitSave">保存</span>
          </div>
        </div>
        <el-row :gutter="24" style="">
          <el-col :span="24" style="margin-bottom: 30px;height: 100px;">
            <div class="grid-content bg-purple">
              <el-form-item label="合约状态">
                <!-- <el-button type="text" disabled v-if="ContractInfoJsonDisabled">
                  {{ Form.Shop_ContractStatus || '其它' }}
                </el-button> -->
                <div style="display: flex;">
                  <el-steps :active="StatusOptionsActive" style="width: 70%;" align-center>
                    <el-step title="其它" icon="el-icon-d-arrow-right" description="默认状态"></el-step>
                    <el-step v-for="(item, index) in StatusOptions" :key="item.DicValue" :title="item.DicName"
                      :description="item.ChangeTime ? item.ChangeTime.substring(0, 10) : ''">
                      <b slot="icon">{{ index + 1 }}</b>
                    </el-step>
                  </el-steps>
                  <div style="width: 20%;margin-left: 5%;">
                    <div style="margin-bottom: 10px;">
                      <div style="font-size: 18px;font-weight: bold;line-height: 20px;display: flex;">
                        <div style="width: 80px;text-align: right;">
                          状态：
                        </div>
                        <span style="margin-left: 10px;;width: 220px;" v-if="StatusOptionsActive != 0">
                          {{
                            StatusOptions[StatusOptionsActive - 1] ?
                              StatusOptions[StatusOptionsActive - 1].DicName : ""
                          }}
                        </span>
                        <span style="margin-left: 10px;;width: 220px;" v-else>
                          其它
                        </span>
                      </div>
                      <div style="margin-top: 10px;font-size: 18px;font-weight: bold;line-height: 20px;display: flex;"
                        v-if="StatusOptionsActive != 0">
                        <div style="width: 80px;text-align: right;">
                          <span style="color: red;">*</span>日期：
                        </div>
                        <el-date-picker v-if="StatusOptions[StatusOptionsActive - 1]"
                          :disabled="ContractInfoJsonDisabled"
                          v-model="StatusOptions[StatusOptionsActive - 1].ChangeTime" type="date" placeholder="选择日期"
                          format="yyyy-MM-dd" value-format="yyyy-MM-dd" size="mini"
                          style="margin-left: 10px;width: 220px;">
                        </el-date-picker>
                      </div>
                    </div>
                    <div style="padding-left: 10%;">
                      <el-button @click="StatusOptionsActive--" v-if="StatusOptionsActive > 0"
                        :disabled="ContractInfoJsonDisabled">上一步
                      </el-button>
                      <el-button @click="nextStep()" v-if="StatusOptions.length !== (StatusOptionsActive)"
                        :disabled="ContractInfoJsonDisabled">下一步
                      </el-button>
                    </div>
                  </div>
                </div>
                <!-- <el-select v-model="Form.ContractStatus" clearable placeholder="请选择合约状态"
                  :disabled="ContractInfoJsonDisabled">
                  <el-option v-for="v in ContractOptions" :key="v.value" :label="v.label" :value="v.label">
                  </el-option>
                </el-select> -->
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <el-form-item label="合约起始时间">
                <el-date-picker v-model="Form.ContractStartTime" style="width: 100%; cursor: pointer" type="date"
                  placeholder="选择日期" value-format="yyyy-MM-dd" :disabled="ContractInfoJsonDisabled">
                </el-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <el-form-item label="合约结束时间">
                <el-date-picker v-model="Form.ContractEndTime" style="width: 100%; cursor: pointer" type="date"
                  placeholder="选择日期" value-format="yyyy-MM-dd" :disabled="ContractInfoJsonDisabled">
                </el-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <el-form-item label="合约价格">
                <el-input v-model="Form.ContractPrice" autocomplete="off" :disabled="ContractInfoJsonDisabled"
                  type="number">
                </el-input>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="24">
            <div class="grid-content bg-purple-light">
              <el-form-item label="备注">
                <el-input type="textarea" v-model="Form.RemarkContract" autocomplete="off"
                  :disabled="ContractInfoJsonDisabled">
                </el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
// 引入工具素具
import { IsOnGroundOptions, ContractOptions } from "../mock.js";

export default {
  name: "WorkspaceJsonShopBaseInfo",

  props: {
    parentObj: { type: Object },

    ContractStatusOptions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      loadingInfo: false,
      StatusOptionsActive: 0,
      StatusOptions: [],
      // 工程状态下拉
      // EngineeringStatusOptions: [],
      //当前合约状态
      StatusData: [],
      // 传递过来的当前行的基础商铺信息
      rows: {},
      // 首页传递过来的项目，任务，应用id对象
      newTaskObj: {},
      // 合约信息禁用
      ContractInfoJsonDisabled: true,
      // 合约状态
      ContractOptions,
      // 标题类型
      title: "",
      loading: false,
      show: false,
      // 表单
      Form: {},
      // 校验规则
      Rules: {},
      // 地上地下下拉数据
      IsOnGroundOptions,
      // 业态级别下拉数据
      // 楼栋信息
      buildingOptions: [],
      // 业态级别
      ShopFormatClassOptions: [],
      // 业态名称下拉选择
      ShopFormatNameOptions: [],
      // 原商铺id下拉列表数据
      ShopLastIdsOptions: [],
    };
  },

  mounted() {
  },

  methods: {
    nextStep() {
      if (this.StatusOptionsActive == 0) {
        this.StatusOptionsActive++
        return
      }
      if (this.StatusOptions[this.StatusOptionsActive - 1].ChangeTime) {
        this.StatusOptionsActive++
      } else {
        this.$message.warning('请选择日期')
      }

    },
    async getSubEngineeringStatus(arr) {
      await this.$http.post('/Shop_Manage/Shop_StatusRecord/SaveDataByCondition', {
        ModuleType: 'ContractStatus',
        ShopId: this.parentObj.childrenRow.Id,
        StatusRecords: arr
      }).then(res => {
        if (res.Success) {
          this.$set(this.Form, 'ContractStatus', arr.length > 0 ? arr[arr.length - 1].Status : '其他')
        } else {
          this.$message.error(res.Msg);
        }
      })
    },
    getStatusOptions() {
      this.$http.post('/Shop_Manage/Shop_StatusRecord/GetDataListByMoreCondition', {
        "Search": [
          {
            "Condition": "ShopId",
            "Keyword": this.parentObj.childrenRow.Id
          },
          {
            "Condition": "ModuleType",
            "Keyword": 'ContractStatus'
          },
        ]
      }).then(res => {
        if (res.Success) {
          this.StatusData = res.Data || []
          if (this.StatusData.length > 0) {
            this.StatusOptions.forEach(item => {
              let obj = this.StatusData.find(val => {
                return val.Status === item.DicName
              })
              if (obj) {
                this.$set(item, 'ChangeTime', obj.ChangeTime)

              }
            })
            this.StatusOptionsActive = this.StatusData.length
          } else {
            this.StatusOptionsActive = 0
          }
        }
      })
    },
    // 取消按钮
    editCancel(type) {
      if (type === "ContractInfoJsonDisabled") {
        // 招商信息
        this.ContractInfoJsonDisabled = true;
      }
      var _this = this;
      this.shopBaseInfoDisabled = true;
      let Id = this.parentObj.childrenRow.Id;
      console.log("获取editDetail父组件数据", this.parentObj.childrenRow);
      // 清空所有form表单数据重新获取渲染
      this.Form = {};

      // /消除校验
      setTimeout(function () {
        _this.$refs["Form"].clearValidate(); //消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
      }, 30);
      // 重新获取编辑详情渲染
      this.getShopInfoTheListData(Id);
      this.StatusOptions = JSON.parse(JSON.stringify([...this.ContractStatusOptions]))
      this.getStatusOptions()
      // setTimeout(function () {//消除某个表单的校验,这里的setTimeOut不能去掉,去掉之后会不生效在clearValidate()里以数组形式 填写表单字段的 prop属性值,
      // _this.$refs['Form'].clearValidate(['sn']);
      //     },30);
      // 重新刷新详情  避免取消条件保存输入的数据
    },
    // 获取编辑详情数据
    getShopInfoTheListData(Id) {
      this.$http
        .post("/Shop_Manage/Shop_BaseInfo/GetTheData", {
          Id: Id,
        })
        .then((res) => {
          this.loading = false;
          console.log("重新获取详情结果", res.Data);
          if (res.Success) {
            if (res.Data.IsOnGround == 1) {
              res.Data.IsOnGround = "地上";
            } else if (res.Data.IsOnGround == 2) {
              res.Data.IsOnGround = "地下";
            } else {
              res.Data.IsOnGround = "屋面";
            }
            this.Form = res.Data;
            // 存储vux  商铺信息
            this.$store.commit("getShopInfo", res.Data);
          } else {
            this.loading = false;
          }
        });
    },
    // 业态级别下拉选择
    ShopFormatClassChange(e) {
      // 判断是否有选择业态级别  如果有选择了。单独清空校验规则  否则红字一直在
      // this.$refs.basisRuleForm.validateField("ShopFormatClass", (valid) => {
      //   //valid返回的是规则集中写的错误提示信息，如果满足条件，返回的就是空
      //   if (valid) {
      //     return this.$refs["basisRuleForm"].clearValidate("ShopFormatClass");
      //   }
      // });

      if (e) {
        console.log("有值", e, this.ShopFormatClassOptions);
        // 如果有选中业态级别  同时获取业态名称
        // this.ShopFormatNameDisabled = false;
      }
      // 业态名称下拉数据初始化
      // if (this.ruleForm1.ShopFormatName) {
      //   this.ruleForm1.ShopFormatName = "";
      // }
      let optionsData = this.ShopFormatClassOptions;
      if (this.ShopFormatClassOptions.length !== 0) {
        var newShopFormClassData = optionsData.filter(function (
          item,
          index,
          arr
        ) {
          return item.label === e;
        });
      }
      console.log("获取处理后的", newShopFormClassData);
      // 赋值业态级别下拉数据参数  以获取对应级别下的下拉数据
      this.parentObj.ShopFormatClassQueryParams.Keyword =
        newShopFormClassData.length == 0 ? "" : newShopFormClassData[0].value;
      console.log(
        "获取下拉业态级别的参数1111111",
        this.parentObj.ShopFormatClassQueryParamsz
      );
      // // 获取对应业态级别下的业态名称下拉数据
      // this.getShopNameListData();
      // // 业态名称后 初始化业态级别下拉数据的参数  避免下次还是原来选择的参数
      // this.ShopFormatClassQueryParams.Keyword="FormatClass"

      this.$emit("ShopFormatClassChange", e);
    },
    SaveOperationLogs(data) {
      this.$http
        .post("/Shop_Manage/Shop_ExtensionInfo/SaveOperationLogs", { ...data })
        .then(res => {
          if (res.Success) {
            console.log("保存日志成功")
            this.parentObj.refreshLog()
          } else {
            console.log("保存日志失败")
          }
        })
    },
    // 保存
    async handleSubmitSave() {
      if (this.StatusOptionsActive != 0) {
        if (!this.StatusOptions[this.StatusOptionsActive - 1].ChangeTime) {
          this.$message.warning('签约状态请选择日期')
          return
        }
      }
      this.loadingInfo = true
      let arr = this.StatusOptions.slice(0, this.StatusOptionsActive).map(item => {
        return {
          "Status": item.DicName,
          "ChangeTime": item.ChangeTime
        }
      })
      let obj = {
        CreateTime: this.$store.state.shopInfo.ContractInfoJson_CreateTime
          ? this.$store.state.shopInfo.ContractInfoJson_CreateTime
          : undefined,
        CreatorId: this.$store.state.shopInfo.ContractInfoJson_CreatorId
          ? this.$store.state.shopInfo.ContractInfoJson_CreatorId
          : undefined,
        Id: this.$store.state.shopInfo.ContractInfoJson_Id
          ? this.$store.state.shopInfo.ContractInfoJson_Id
          : undefined,
        ModuleType: "ContractInfoJson",
        ModuleJson: JSON.stringify({
          ContractStartTime: this.Form.ContractStartTime,
          ContractEndTime: this.Form.ContractEndTime,
          ContractPrice: this.Form.ContractPrice,
          ContractStatus: arr.length > 0 ? arr[arr.length - 1].Status : '其他',
          RemarkContract: this.Form.RemarkContract,
        }),
        ShopId: this.$store.state.shopInfo.Id || this.Form.Id,
        Status: 1,
      };
      var shopInfo = this.$store.state.shopInfo;
      var that = this;
      try {
        await this.getSubEngineeringStatus(arr)
        console.log("参数", obj);

        // 保存后禁用按钮
        this.ContractInfoJsonDisabled = true;

        // const loading = this.$loading({
        //   lock: true,
        //   text: "保存中...",
        //   spinner: "el-icon-loading",
        //   background: "rgba(0, 0, 0, 0.7)",
        // });
        that.$http
          .post("/Shop_Manage/Shop_ExtensionInfo/SaveData", obj)
          .then((res) => {
            this.loadingInfo = false
            if (res.Success) {
              shopInfo[obj.ModuleType + "_CreateTime"] = res.Data.CreateTime;
              shopInfo[obj.ModuleType + "_CreatorId"] = res.Data.CreatorId;
              shopInfo[obj.ModuleType + "_Id"] = res.Data.Id;
              this.SaveOperationLogs(res.Data)
              // console.log('获取最终合并的参数对象==================================', shopInfo)
              // // 重新更新vux里的商铺信息
              this.$store.commit("getShopInfo", shopInfo);
              // setTimeout(() => {
              //   loading.close();
              //   that.$message.success("已保存");
              // }, 500);
              that.$message.success("已保存");
              this.editCancel('ContractInfoJsonDisabled')
              // 关闭弹出层
              // this.$emit("closeDialog", true);
            } else {
              that.$message.error(res.Msg);
            }
          });
      } catch (error) {
        this.loadingInfo = false
      }

    },
  },
  watch: {
    // 监听工程状态选项变化
    ContractStatusOptions: {
      deep: true,
      handler(val) {
        console.log(val)
        if (val.length > 0) {
          this.StatusOptions = JSON.parse(JSON.stringify([...val]))
          this.getStatusOptions()
        }
        console.log("工程状态选项变化", val)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ContractInfoJson {

  // 覆盖element input输入框的宽高
  ::v-deep .el-form-item {
    margin-bottom: 5px !important;

    .el-form-item__content {
      .el-input {

        // 可以隐藏上下加减号
        // input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
        //   // -webkit-appearance: none !important;
        // 	margin-left: 20px;
        // }
        .el-input__inner {
          height: 28px !important;
          line-height: 28px !important;
        }

        .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .edit:hover {
    color: #aaa7a7 !important;
    cursor: pointer;
  }

  .save {
    color: red;
  }

  .save:hover {
    color: rgb(253, 127, 127);
    cursor: pointer;
  }

  .el-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 20px;

    ::v-deep .el-button {
      padding: 8px 20px;
    }
  }
}

::v-deep .el-steps {
  font-weight: bold;
}

::v-deep .el-step__head.is-finish {
  color: #D1100B;
  border-color: #D1100B;
}

::v-deep .el-steps .is-finish .el-step__line {
  background: #D1100B;
}

::v-deep .el-steps .is-process {
  color: #D1100B;
  border-color: #D1100B;
}
</style>
