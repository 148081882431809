<template>
    <div>
        <el-dialog :title="title" :visible.sync="moveDialgVisible" width="500px" :close-on-press-escape="false"
            :close-on-click-modal="false">

            <el-select v-model="value" placeholder="请选择配电室" size="small" @change="changeValue" filterable
                default-first-option>
                <el-option v-for="item in tableData" :key="item.Id" :label="item.TDRName + '【' + item.TDRCode + '】'"
                    :value="item.Id" :disabled="item.Id == rowId">
                </el-option>
            </el-select>

            <el-select v-model="ServiceScopeValue" placeholder="请选择服务范围" size="small" filterable default-first-option
                v-if="title === '复制数据'" style="margin-top: 10px;">
                <el-option v-for="item in ServiceScopeList" :key="item.value"
                    :label="item.parentLabel + '/' + item.label" :value="item.value">
                </el-option>
            </el-select>
            <!-- <el-row style="text-align: right;margin-bottom: 10px;">
                <el-input size="mini" v-model="searchValue" placeholder="搜索配电室名称/编号"
                    style="display: inline-block; width: 200px;margin-right: 10px;" clearable></el-input>
                <el-button size="mini" type="primary" style="margin-right: 10px" @click="search">搜索</el-button>
            </el-row> -->
            <!-- <el-table :data="tableData" border stripe :height="tableHeight" @selection-change="selectionChange"
                @current-change="tableCurrentChange" @row-click="tableRowClick" ref="tablemove">
                <el-table-column type="selection" width="40" align="center">
                </el-table-column>
                <el-table-column type="index" label="编号" width="50" align="center">
                </el-table-column>
                <el-table-column prop="TDRCode" label="配电室编号" align="center" width="120" show-overflow-tooltip
                    :resizable="false" :filters="filtersTable('TDRCode')" :filter-method="filtersTableInfo">
                </el-table-column>
                <el-table-column prop="TDRName" label="配电室名称" align="center" width="120" show-overflow-tooltip
                    :resizable="false" :filters="filtersTable('TDRName')" :filter-method="filtersTableInfo">
                </el-table-column>
                <el-table-column prop="BuildingArea" label="建筑面积(㎡)" header-align="center" align="right" width="115"
                    show-overflow-tooltip :resizable="false" :filters="filtersTable('BuildingArea')"
                    :filter-method="filtersTableInfo">
                    <template slot-scope="scope">
                        {{ magnification(scope.row.BuildingArea) }}

                    </template>
</el-table-column>
<el-table-column prop="ServiceScope" label="服务范围" align="center" width="100" show-overflow-tooltip :resizable="false">
    <template slot-scope="scope">
                        <div v-if="scope.row.ServiceScope">
                            <div v-for="item in JSON.parse(scope.row.ServiceScope)" :key="item.value">
                                <el-tag type="info" size="mini"> {{ item.parentLabel + '/' + item.label }}</el-tag>
                            </div>
                        </div>
                    </template>
</el-table-column>
<el-table-column prop="ManagementModel" label="经营模式" align="center" width="90" show-overflow-tooltip :resizable="false"
    :filters="filtersTable('ManagementModel')" :filter-method="filtersTableInfo">
</el-table-column>
<el-table-column prop="PowerDemandLoad" label="需求功率(kW)" align="right" header-align="center" width="200">
    <template slot-scope="scope">
                        {{ magnification(scope.row.PowerDemandLoad) }}
                    </template>
</el-table-column>
<el-table-column prop="PowerDemandCapacity" label="需求装机(kVA)" align="right" header-align="center" width="200">
    <template slot-scope="scope">
                        {{ magnification(scope.row.PowerDemandCapacity) }}
                    </template>
</el-table-column>
<el-table-column label="申请装机" align="center">
    <el-table-column prop="TransformerData" label="变压器容量/数量" width="270" align="center">
        <template slot-scope="scope">
                            <div v-if="scope.row.TransformerData">
                                <div v-for="(item, index) in JSON.parse(scope.row.TransformerData)" :key="index">
                                    <el-tag type="info" size="mini">
                                        容量： {{ item.TransformerCapacity }}(kVA/台)
                                    </el-tag>
                                    &nbsp;
                                    <el-tag type="info" size="mini"> 数量： {{ item.TransformerCount }}(台)</el-tag>
                                </div>
                            </div>

                        </template>
    </el-table-column>
    <el-table-column prop="TransformerAllCapacity" label="装机负荷(kVA)" width="120" align="right" header-align="center">
        <template slot-scope="scope">
                            {{ magnification(scope.row.TransformerAllCapacity) }}
                        </template>
    </el-table-column>
</el-table-column>
<el-table-column label="面积指标" align="center">
    <el-table-column prop="DemandLoadDensity" label="需求功率密度(W/㎡)" width="160" align="right" header-align="center">
        <template slot-scope="scope">
                            {{ magnification(scope.row.DemandLoadDensity) }}
                        </template>
    </el-table-column>
    <el-table-column prop="TXLoadDensity" label="装机负荷密度(VA/m)" width="160" align="right" header-align="center">
        <template slot-scope="scope">
                            {{ magnification(scope.row.TXLoadDensity) }}
                        </template>
    </el-table-column>
</el-table-column>
<el-table-column prop="TransformerLoadRate" label="变压器负载率" align="center" width="90">
    <template slot-scope="scope">
                        {{ (scope.row.TransformerLoadRate).toFixed(2) + '%' }}
                    </template>
</el-table-column>

<el-table-column label="版本管理" align="center" prop="ChangeVersion" width="60">
</el-table-column>
</el-table> -->
            <!-- <div style="margin-top: 10px;text-align: right;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="pagination.currentPage" :page-sizes="[20, 50, 100, 200, 300, 400]"
                    :page-size="pagination.pageSize" layout="total, sizes, prev, pager, next, jumper"
                    :total="pagination.total">
                </el-pagination>
            </div> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="moveDialgVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="moveYes" size="small">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
export default {
    data() {
        return {
            title: '移动数据',
            tableData: [],
            moveDialgVisible: false,
            tableHeight: 400,
            searchValue: "",
            value: "",
            pagination: {
                pageSize: 20,
                currentPage: 1,
                total: 0,
            },
            TaskId: "",
            TableSelectionList: [],
            moveList: [],
            valueObj: {},
            rowId: "",
            ServiceScopeList: [],
            ServiceScopeValue: '',
        };
    },
    props: {},
    components: {},
    methods: {
        changeValue(Id) {
            let obj = this.tableData.find(item => item.Id === Id)
            this.valueObj = obj
            console.log(obj)
            this.ServiceScopeValue = ''
            this.ServiceScopeList = JSON.parse(obj.ServiceScope)
        },
        moveYes() {
            if (this.value) {

                if (this.title === '复制数据') {
                    let obj = null
                    if (this.ServiceScopeValue) {
                        obj = this.ServiceScopeList.find(item => {
                            return item.value === this.ServiceScopeValue
                        })
                    }
                    this.$emit('copyChange', this.valueObj, this.moveList, obj)
                } else if (this.title === '移动数据') {
                    this.$emit('moveChange', this.valueObj, this.moveList)
                }
                this.moveDialgVisible = false
            } else {
                this.$message.warning('请选择配电室后确定')
            }

        },
        tableRowClick(row) {
            this.$refs.tablemove.toggleRowSelection(row)
        },
        selectionChange(list) {
            console.log(list)
            this.TableSelectionList = list
        },
        tableCurrentChange(val) {
            console.log(val)
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pagination.pageSize = val;
            this.getDataList();

        },
        handleCurrentChange(val) {
            this.pagination.currentPage = val;
            this.getDataList();
            console.log(`当前页: ${val}`);
        },
        getDataList() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            this.$http.post('/ElectricalGO/Electrical_SchemeStageSum/GetDataListByMoreCondition', {
                // "PageIndex": this.pagination.currentPage,
                // "PageRows": this.pagination.pageSize,
                "SortField": "Id",
                "SortType": "desc",
                "Search": [
                    {
                        "Condition": "TaskId",
                        "Keyword": this.TaskId,
                    },
                    {
                        "Condition": "NameOrCode",
                        "Keyword": this.searchValue
                    }
                ]
            }).then(res => {
                loading.close();
                if (res.Success) {
                    this.tableData = res.Data
                    this.pagination.total = res.Total
                } else {
                    this.$message.error(res.Msg)
                }
            })
        },
        search() {
            this.pagination.currentPage = 1;
            this.getDataList();
        },
        filtersTableInfo(value, row, column) {
            const property = column['property'];
            return row[property] === value;
        },
        openMoveDialog(taskId, List = [], Id, title) {
            this.title = title
            this.rowId = Id
            this.TaskId = taskId
            this.moveList = List
            this.moveDialgVisible = true
            // this.pagination.currentPage = 1
            // this.pagination.pageSize = 20
            // this.searchValue = ""
            this.getDataList()
            this.value = ""
            this.valueObj = {}
            // this.tableHeight = (window.innerHeight * 0.9 - 160 - 100)
            // this.TableSelectionList = []
            // this.$nextTick(() => {
            //     this.$refs.tablemove.clearSelection()
            // })

        },
        magnification(number) {
            let parts = number.toString().split('.');
            let intPart = parts[0];
            let decPart = parts.length > 1 ? '.' + parts[1] : '';
            let formatted = '';
            while (intPart.length > 3) {
                formatted = ',' + intPart.slice(-3) + formatted;
                intPart = intPart.slice(0, -3);
            }

            return intPart + formatted + decPart;
        },
    },
    mounted() { },
    created() { },
    computed: {
        filtersTable() {
            return function (text) {
                let arr = []
                arr = this.tableData.filter(item => Boolean(item[text])).map(item => {
                    return {
                        text: item[text],
                        value: item[text]
                    }
                })

                let uniqueArr = arr.filter((item, index) => arr.findIndex(i => i.value === item.value) === index);
                return uniqueArr

            }
        },
    },
    watch: {},
};
</script>
<style lang='less' scoped></style>