<template>
  <!--  燃气部分-->
  <div>
    <!--    燃气（提供/不提供）-->
    <div class="flex" style="border: 2px solid #000">
      <div class="flex8 flexCol">
        <!--              顶部备注区域布局站位-->
        <div class="flex" style="height: 22px">
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
        </div>
        <!--        内容区域 这里的height 30px 和下拉列表数据点额高度一致 否则回出现高度不一致情况-->
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">燃气</div>
            <div class="flex5 height30">
              <InputComponent
                ref="selectInputGasConnectingPipeSize"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange($event, 'GasConnectingPipeSizeSelectValue')
                "
              ></InputComponent>
            </div>
          </div>
          <!--          布局站位-->
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight"></div>
            <div class="flex5 height30 borderTop borderRight"></div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div
              class="flex5 height30 borderTop borderRight borderBottom font16"
            >
              燃气入户压力
            </div>
            <div
              class="flex jbetween flex5 height30 borderRight borderBottom font16"
            >
              <span>{{
                parentObj.entity.NaturalGasPressure
                  ? parentObj.entity.NaturalGasPressure
                  : ""
              }}</span>
              <span class="color font14 paddingRight5">{{
                parentObj.entity.NaturalGasPressure ? "(kPa)" : ""
              }}</span>
            </div>
          </div>
          <!--          布局站位-->
          <div class="flex flex5">
            <div
              class="flex5 height30 borderTop borderRight borderBottom font16"
            >
              燃气入户容量
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight borderBottom font16"
            >
              <span>
                {{
                  parentObj.entity.NaturalGasVolume
                    ? parentObj.entity.NaturalGasVolume
                    : ""
                }}</span
              >
              <span class="color font14 paddingRight5">
                {{ parentObj.entity.NaturalGasVolume ? "(m³/h)" : "" }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div id="shopInfoRemark flex flex2 border">
        <div class="text-center font16">备注</div>
        <div style="padding-top: 1px">
          <InputComponent
            ref="InputComponent"
            :placeHolder="'请输入备注'"
            :rows="2"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkNaturalGas')"
          ></InputComponent>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputComponent from "@/views/Project/view/components/inputComponents/inputComponent.vue";
// 模拟数据
import {
  dianbiaoOptions,
  dianbiaoWeizhiOptions,
  kongtiaoguanzhiOptions,
} from "../../mock.js";
export default {
  props: {
    parentObj: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  components: { InputComponent },
  data() {
    return {
      // 燃气提供不提供
      GasConnectingPipeSizeSelectValue: "",
      // 电表下拉列框绑定列别
      dianOtions: dianbiaoOptions,
    };
  },
  methods: {
    input(row) {
      // 判断相关备注和下拉提供非提供逻辑  如果没有对应的数组  提供  否则 不提供
      this.$nextTick(function () {
        this.$refs.selectInputGasConnectingPipeSize.value = "";
        this.$refs.selectInputGasConnectingPipeSize.value =
          row.GasConnectingPipeSizeSelectValue;
      });

      this.$refs.InputComponent.keyWords = row.RemarkNaturalGas
        ? row.RemarkNaturalGas
        : "";
    },
    // 相关下拉参数处理回调
    inputSelectChange(event, type) {
      this.parentObj.openSetPrintSelectValue(event, type);
    },
    // 相关备注参数处理回调
    inputChange(event, type) {
      this.parentObj.openSetPrintInputValue(event, type);
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
/*覆盖card样式*/
.el-card {
  border: none !important;
}
/*取消card的阴影*/
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}

/*覆盖element card 卡片样式*/
::v-deep .recordContent {
  .el-card {
    .el-card__body {
      padding: 0px !important;
    }
  }
}
</style>
