<template>
  <!-- //方案阶段-主页列表 -->
  <div>
    <div class="content-list">
      <div class="content-table">
        <el-row type="flex" justify="space-between">
          <div style="display: flex;">
            <el-button size="mini" type="primary" style="margin-right: 10px" @click="backTask">返回</el-button>
            <el-select style="" class="marginRight" v-model="taskDataSelect" placeholder="请选择筛任务" size="mini"
              @change="taskOptionsChange" filterable default-first-option>
              <el-option v-for="item in taskOptions" :key="item.Id" :label="item.Name + '【' + item.TaskTypeName + '】'"
                :value="item.Id">
              </el-option>
            </el-select>
            <el-button size="mini" type="primary" style="margin-left: 10px" @click="addSchemeStageMain">新建</el-button>
          </div>
          <div>
            <div style="display: inline-block;">
              <el-input size="mini" v-model="searchValue" placeholder="搜索配电室名称/编号"
                style="display: inline-block; width: 200px;margin-right: 10px;" clearable></el-input>
              <el-button size="mini" type="primary" style="margin-right: 10px" @click="search">搜索</el-button>
            </div>
            <el-button size="mini" @click="getDataList">刷新</el-button>
            <el-button size="mini" type="primary">导出计算书</el-button>
          </div>
        </el-row>
        <div style="margin-top: 15px;box-sizing: border-box;">
          <el-table :data="tableData" border stripe :height="tableHeight" @row-click="editSchemeStageMain">
            <el-table-column type="index" label="编号" width="50" align="center">
            </el-table-column>
            <el-table-column prop="TDRCode" label="配电室编号" align="center" width="120" show-overflow-tooltip
              :resizable="false" :filters="filtersTable('TDRCode')" :filter-method="filtersTableInfo">
            </el-table-column>
            <el-table-column prop="TDRName" label="配电室名称" align="center" width="120" show-overflow-tooltip
              :resizable="false" :filters="filtersTable('TDRName')" :filter-method="filtersTableInfo">
            </el-table-column>
            <el-table-column prop="BuildingArea" label="建筑面积(㎡)" header-align="center" align="right" width="115"
              show-overflow-tooltip :resizable="false" :filters="filtersTable('BuildingArea')"
              :filter-method="filtersTableInfo">
              <template slot-scope="scope">
                {{ magnification(scope.row.BuildingArea) }}

              </template>
            </el-table-column>
            <el-table-column prop="ServiceScope" label="服务范围" align="center" width="150" :resizable="false">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" placement="top" v-if="scope.row.ServiceScope" :open-delay="300">

                  <div slot="content">
                    <span v-for="item in JSON.parse(scope.row.ServiceScope)" :key="item.value">
                      {{ item.parentLabel + '/' + item.label }}<br />
                    </span>
                  </div>
                  <div v-if="scope.row.ServiceScope">
                    <el-tag type="info" size="mini"> {{ JSON.parse(scope.row.ServiceScope)[0].parentLabel + '/' +
                      JSON.parse(scope.row.ServiceScope)[0].label }}</el-tag>
                    <el-tag type="info" size="mini" style="margin-left: 10px;"
                      v-if="JSON.parse(scope.row.ServiceScope).length > 1"> {{
                        `+ ${JSON.parse(scope.row.ServiceScope).length - 1}`
                      }}</el-tag>

                  </div>
                </el-tooltip>

              </template>
            </el-table-column>
            <el-table-column prop="ManagementModel" label="经营模式" align="center" width="90" show-overflow-tooltip
              :resizable="false" :filters="filtersTable('ManagementModel')" :filter-method="filtersTableInfo">
            </el-table-column>
            <el-table-column prop="PowerDemandLoad" label="需求功率(kW)" align="right" header-align="center">
              <template slot-scope="scope">
                {{ magnification(scope.row.PowerDemandLoad) }}
              </template>
            </el-table-column>
            <el-table-column prop="PowerDemandCapacity" label="需求装机(kVA)" align="right" header-align="center">
              <template slot-scope="scope">
                {{ magnification(scope.row.PowerDemandCapacity) }}
              </template>
            </el-table-column>
            <el-table-column label="申请装机" align="center">
              <el-table-column prop="TransformerData" label="变压器容量/数量" width="300" align="center">
                <template slot-scope="scope">

                  <el-tooltip class="item" effect="dark" placement="top" v-if="scope.row.TransformerData"
                    :open-delay="300">
                    <div slot="content">
                      <div v-for="(item, index) in JSON.parse(scope.row.TransformerData)" :key="index">
                        <span type="info" size="mini">
                          容量： {{ item.TransformerCapacity }}(kVA/台)
                        </span>
                        &nbsp;
                        <span type="info" size="mini"> 数量： {{ item.TransformerCount }}(台)</span>
                      </div>
                    </div>
                    <div v-if="scope.row.TransformerData">
                      <el-tag type="info" size="mini">
                        容量： {{ JSON.parse(scope.row.TransformerData)[0].TransformerCapacity }}(kVA/台)
                      </el-tag>

                      <el-tag type="info" size="mini" style="margin-left: 10px;"> 数量： {{
                        JSON.parse(scope.row.TransformerData)[0].TransformerCount }}(台)</el-tag>
                      <el-tag type="info" size="mini" style="margin-left: 10px;"
                        v-if="JSON.parse(scope.row.TransformerData).length > 1"> {{
                          `+ ${JSON.parse(scope.row.TransformerData).length - 1}`
                        }}</el-tag>
                    </div>
                  </el-tooltip>




                </template>
              </el-table-column>
              <!-- <el-table-column prop="TransformerData" label="变压器容量" width="120" align="center">
              </el-table-column> -->
              <el-table-column prop="TransformerAllCapacity" label="装机负荷(kVA)" width="120" align="right"
                header-align="center">
                <template slot-scope="scope">
                  {{ magnification(scope.row.TransformerAllCapacity) }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="面积指标" align="center">
              <el-table-column prop="DemandLoadDensity" label="需求功率密度(W/㎡)" width="160" align="right"
                header-align="center">
                <template slot-scope="scope">
                  {{ magnification(scope.row.DemandLoadDensity) }}
                </template>
              </el-table-column>
              <el-table-column prop="TXLoadDensity" label="装机负荷密度(VA/㎡)" width="160" align="right"
                header-align="center">
                <template slot-scope="scope">
                  {{ magnification(scope.row.TXLoadDensity) }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="TransformerLoadRate" label="变压器负载率" align="center" width="90">
              <template slot-scope="scope">
                {{ (scope.row.TransformerLoadRate).toFixed(2) + '%' }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="操作" align="center" width="50">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="editSchemeStageMain(scope.row)">编辑</el-button>
              </template>
            </el-table-column> -->
            <el-table-column label="版本管理" align="center" prop="ChangeVersion" width="60">
            </el-table-column>
          </el-table>
          <div class="bottomTotal" style="">
            <div style="display: flex; justify-content: space-between;width: 40%;">
              <div class="grid-content bg-purple" style="">总计建筑面积(㎡)</div>
              <div class="grid-content bg-purple-light" style="">
                {{ totalObj.BuildingArea ? magnification(totalObj.BuildingArea) : 0 }}
              </div>
              <div class="grid-content bg-purple" style="">总计装机容量(kVA)</div>
              <div class="grid-content bg-purple-light" style="border-right: 2px solid #a8a7a7">
                {{
                  totalObj.TransformerAllCapacity
                    ? magnification(totalObj.TransformerAllCapacity)
                    : 0
                }}
              </div>

            </div>
          </div>
          <div style="margin-top: 10px;text-align: right;">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pagination.currentPage" :page-sizes="[20, 50, 100, 200, 300, 400]"
              :page-size="pagination.pageSize" layout="total, sizes, prev, pager, next, jumper"
              :total="pagination.total">
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- <div class="content-echarts">
        <EchartsIndex ref="Echarts1" :option="Options" styleStr="width: 200px; height: 200px">
        </EchartsIndex>

        <EchartsIndex ref="Echarts2" :option="Options" styleStr="width: 200px; height: 200px">
        </EchartsIndex>
      </div> -->
    </div>
    <div v-if="SchemeStageMainEditShow">
      <SchemeStageMainEdit ref="SchemeStageMainEdit" @save="search" @close="SchemeStageMainEditClose">
      </SchemeStageMainEdit>
    </div>
  </div>
</template>
<script>
//import EchartsIndex from "@/components/Echarts/index";
import SchemeStageMainEdit from "./SchemeStage_MainEdit.vue";
export default {
  data() {
    return {
      taskDataSelect: "",
      taskOptions: [],
      taskQueryParams: {
        ClassType: 0,
        Conditions: [
          {
            Condition: "ProjectId",
            Keyword: this.taskObj.ProjectId,
          },
          {
            Condition: "AppyId",
            Keyword: this.taskObj.AppyId,
          },
          // { Condition: "Status", Keyword: 0 },暂时删除后续有需要追加
        ],
      },
      searchValue: "",
      tableHeight: 0,
      BuildingAreaTotal: 0,
      PowerDemandLoadTotal: 0,
      pagination: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      Options: {
        title: {
          text: "业态分析",
          subtext: "",
          left: "center",
          top: 0,
        },
        tooltip: {
          trigger: 'item',
          position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = { top: 60 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },
          formatter: '{b}：{c}<br/>占比：{d}%'
        },
        legend: {
          orient: 'horizontal',
          // left: 'left'
          bottom: 10,
        },
        series: [
          {
            name: '业态类型分析',
            type: 'pie',
            radius: '40%',
            labelLine: {
              length: 2,
              length2: 2,
            },
            label: {
              show: true,
              formatter: (a) => {
                return a.name +
                  '\n' + a.value
              },
            },
            data: [
              { value: 1048, name: '商业' },
              { value: 735, name: '媒体' },
              { value: 580, name: '酒店' },
            ],
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: 'rgba(0, 0, 0, 0.5)'
            //   }
            // }
          }
        ]
      },
      louList: [],
      SchemeStageMainEditShow: false,
    };
  },
  props: {
    taskObj: { type: Object, require: true },
    ClassType: { type: Number, require: true },
  },
  components: {
    //EchartsIndex,
    SchemeStageMainEdit
  },
  methods: {
    getTaskListData() {
      this.$http
        .post("/Base_TaskWork/Base_Task/GetDataListByMoreCondition", {
          Search: this.taskQueryParams,
        })
        .then((res) => {
          if (res.Success) {
            this.taskOptions = res.Data || [];
            this.taskDataSelect = this.taskObj.TaskId
            //  更新表格数据
            // 清空选项
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    async taskOptionsChange(id) {
      let item = this.taskOptions.find(item => item.Id == id)
      await this.$emit("task", { status: false, ...item });
      console.log(this.taskObj, '11111')
      this.getDataList()
    },
    magnification(number) {
      let parts = number.toString().split('.');
      let intPart = parts[0];
      let decPart = parts.length > 1 ? '.' + parts[1] : '';
      let formatted = '';
      while (intPart.length > 3) {
        formatted = ',' + intPart.slice(-3) + formatted;
        intPart = intPart.slice(0, -3);
      }

      return intPart + formatted + decPart;
    },
    SchemeStageMainEditClose() {
      this.SchemeStageMainEditShow = false
      // setTimeout(() => {

      // }, 400)
    },
    editSchemeStageMain(row) {
      console.log(row)
      this.SchemeStageMainEditShow = true
      this.$nextTick(() => {
        this.$refs.SchemeStageMainEdit.openForm({
          ...this.taskObj
        }, row, '编辑')
      })

    },
    filtersTableInfo(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    //楼栋数据
    getLouData() {
      this.$http.post("/Base_ProjectManage/Project_Building/GetDataList", {
        Search: {
          "Condition": "ProjectId",
          "Keyword": this.taskObj.ProjectId,
        }
      }).then((res) => {
        if (res.Success) {
          this.louList = res.Data
          this.louList.forEach((item) => {
            this.$set(item, 'isTrue', true)
            this.$set(item, 'isTrueArr', [])
            let arr = []
            for (let i = 1; i <= item.UnderGround_Floor; i++) {
              let obj = {
                code: 'B' + i,
                id: '2-' + i,
                isTrue: false,
              }
              arr.push(obj)
            }
            for (let i = 1; i <= item.OnGround_Floor; i++) {
              let obj = {
                code: 'F' + i,
                id: '1-' + i,
                isTrue: false,
              }
              arr.push(obj)
            }

            this.$set(item, 'floorList', [{
              code: 'RF',
              id: '3-RF',
              isTrue: false,
            }, ...arr])
          });
        } else {
          this.louList = []
        }

      });
    },
    addSchemeStageMain() {
      this.SchemeStageMainEditShow = true
      this.$nextTick(() => {
        this.$refs.SchemeStageMainEdit.openForm({
          ...this.taskObj
        }, null, '新建');
      })

    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getDataList();

    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getDataList();
      console.log(`当前页: ${val}`);
    },
    getDataList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      this.$http.post('/ElectricalGO/Electrical_SchemeStageSum/GetDataListByMoreCondition', {
        "PageIndex": this.pagination.currentPage,
        "PageRows": this.pagination.pageSize,
        "SortField": "Id",
        "SortType": "desc",
        "Search": [
          {
            "Condition": "TaskId",
            "Keyword": this.taskObj.TaskId,
          },
          {
            "Condition": "NameOrCode",
            "Keyword": this.searchValue
          }
        ]
      }).then(res => {
        loading.close();
        if (res.Success) {
          this.tableData = res.Data
          this.pagination.total = res.Total
        } else {
          this.$message.error(res.Msg)
        }
      })
    },
    search() {
      this.pagination.currentPage = 1;
      this.getDataList();
    },
    backTask() {
      this.$emit("task", {
        status: true,
        ...this.taskObj,
      });
    },
  },
  mounted() { },
  created() {
    console.log(this.taskObj, 'taskObj')
    this.tableHeight = window.innerHeight - 260;
    this.getDataList()
    this.getTaskListData()
  },
  computed: {
    totalObj() {
      let obj = {
        TransformerAllCapacity: 0,
        BuildingArea: 0,
      }
      this.tableData.forEach(item => {
        obj.TransformerAllCapacity += item.TransformerAllCapacity
        obj.BuildingArea += item.BuildingArea
      })
      return obj
    },
    filtersTable() {
      return function (text) {
        let arr = []
        arr = this.tableData.filter(item => Boolean(item[text])).map(item => {
          return {
            text: item[text],
            value: item[text]
          }
        })

        let uniqueArr = arr.filter((item, index) => arr.findIndex(i => i.value === item.value) === index);
        return uniqueArr

      }
    },
  },
  watch: {},
};
</script>
<style lang='scss' scoped>
::v-deep .content-list {
  display: flex;
  width: calc(100vw - 90px);
  height: calc(100vh - 145px);

  .content-table {
    width: calc(100%);
    // width: calc(100% - 210px);
    box-sizing: border-box;
    padding-right: 10px;

    // .el-table th {
    //   background-color: #D1100B !important;
    //   color: #fff !important;
    // }

    .el-table td {
      padding: 7px 0 !important;
    }
  }

  .el-table th.el-table__cell>.cell.highlight {
    color: #000 !important;
  }

  .content-echarts {
    width: 210px;
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .el-statistic .con span {
    line-height: 30px;
    font-size: 14px;

  }
}

.bottomTotal {
  .grid-content.bg-purple {
    padding: 6px 0;
    flex: 1;
    background-color: #d7d7d7;
    border: 2px solid #a8a7a7;
    border-right: 0;
    text-align: center;
    font-size: 13px;
  }

  .grid-content.bg-purple-light {
    background-color: #f2f2f2;
    border: 2px solid #a8a7a7;
    border-right: 0;
    flex: 1;
    line-height: 30px;
    text-align: center;
  }
}
</style>