<!-- 版本对比组件 -->
<template>
	<div class="contractVersion">
		<el-drawer title="版本对比" size="80%" class="record" :visible.sync="drawerVisible" :direction="direction"
			:before-close="handleClose" append-to-body modal-append-to-body>
			<el-row>
				<!-- 左侧menu   此未布局占位-->
				<el-col :span="3">
					<div class="menu" style="border:1px solid transparent">
					</div>
				</el-col>
				<!-- 右侧版本号 -->
				<el-col :span="21" class="" @scroll="onScroll" style="display: flex;">
					<div style="">
						<div
							style="padding: 5px; border:1px solid #d9e5f3;width: 170px;text-align: right;padding-right: 20px;font-size: 13px;">
							版本号
						</div>
					</div>
					<div v-for="(item, index) in changeVersionListData" :key="index"
						style="width: 196px;display: flex;justify-content: center;align-items: center;">
						<div
							style="text-align: center; padding: 5px;border-top:1px solid #d9e5f3;border-right:1px solid #d9e5f3;width: 170px;text-align: right;padding-right: 20px;font-size: 13px;">
							{{ item.ChangeVersion }}
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="1" class="anchor-point"
				style="flex-basis: 100%;display: flex;overflow: hidden;margin-left:0;margin-right:0">
				<!-- 按钮 -->
				<el-col :span="3" class="operation-btn" style=" height: 100%;">
					<el-menu :default-active="activeStep" @select="jump" style="">
						<el-menu-item v-for="(item, index) in colData" :index="`${index}`" :key="index"
							@click="jump(index)">
							<span slot="title">{{ item.DicName }}</span>
						</el-menu-item>
					</el-menu>
				</el-col>
				<!-- 滚动区域 -->
				<el-col :span="21" class="scroll-content" @scroll="onScroll"
					style="  height: 561px; width: 90%; overflow: auto;">
					<div v-for="(item, index) in colData" :key="index" style="">
						<div class="title scroll-item" :id="item.name"
							style=" padding: 10px;background-color: #f0f3f8;font-size: 13px;">
							{{ item.DicName }}
						</div>
						<div :id="i" v-for="(m, i) in item.children" :key="i" ref="divContent" style="">
							<div style="display: flex;">
								<div :style="{ color: tableNoData.includes(m.DicKey) ? 'red' : '' }"
									style="border-top:1px solid #d9e5f3;border-right:1px solid #d9e5f3;width: 180px;padding-left: 20px;font-size: 13px;padding: 8px;text-align: right;">
									<span>{{ m.DicName }}</span>
								</div>
								<template v-if="!tableNoData.includes(m.DicKey)">
									<!-- <div style="border-top:1px solid #d9e5f3;border-right:1px solid #d9e5f3;width: 180px;padding-left: 20px;font-size: 13px;padding: 8px;" -->
									<div  style="border:1px solid #d9e5f3;width: 180px;padding-left: 20px;font-size: 13px;padding: 8px;"
										v-for="(k, n) in changeVersionListData" :key="n">
										<span v-html="getData(k.tableBody[m.DicKey])"></span>
									</div>
								</template>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</el-drawer>
	</div>
</template>

<script>
export default {
	name: "contractVersion",
	components: {
		// Edit,
		// UpdateRecord,
		// TableDetail,
	},
	props: {
		parentObj: {
			type: Object
		},
		// 是否显示版本对比弹窗
		drawerVisible: {
			type: Boolean,
			default: false
		},
		direction: {
			type: String,
			default: 'rtl'
		}
	},
	data() {
		return {
			activeName: '1',
			isActive: '1',
			scrolls: {},
			NavDistance: 0,
			navStyle: 0,
			scrollHeight: 0,
			// 版本列表
			changeVersionListData: [],
			// 左侧menu列表数据
			menuListData: [],
			activeName: "Shop_BaseInfo",
			colData: [],
			tabPosition: 'left',
			activeStep: "0",
			// 左侧tab激活标签
			tabName: 'Shop_BaseInfo',
			tabs: [{
				name: '基础信息',
				refName: 'Shop_BaseInfo',
			},
			{
				name: '电气',
				refName: 'ElectricalMajor',
			},
			{
				name: '暖通',
				refName: 'HVACMajor',
			},
			{
				name: '给排水',
				refName: 'WSADMajor',
			},
			{
				name: '智能化',
				refName: 'IntelligentMajor',
			},
			{
				name: '基本信息',
				refName: 'EngineeringBaseInfo',
			},
			{
				name: '图纸报审',
				refName: 'DrawingApproval',
			},
			{
				name: '设备材料审核',
				refName: 'EquipmentAndMaterialReview',
			},
			{
				name: '合约信息',
				refName: 'ContractInfoJson',
			},
			],
			//表头无数据列表
			tableNoData: ["ElectricalBox", "FreshAirPipelineSize", "KitchenExhaustPipeSize", "KitchenNormalAccidentExhaustPipeSize", "KitchenAirReplenishmentPipeSize", "IndividualProtectiveExhaustAirPipeSize"]
		};
	},
	created() { },
	mounted: function () {
		this.$nextTick(function () {
			window.addEventListener('scroll', this.onScroll)
		})
	},

	methods: {
		// 滚动触发按钮高亮
		onScroll(e) {
			console.log('获取滚动条', e)
			let scrollItems = document.querySelectorAll('.scroll-item')
			for (let i = scrollItems.length - 1; i >= 0; i--) {
				// 判断滚动条滚动距离是否大于当前滚动项可滚动距离
				let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop
				if (judge) {
					this.activeStep = i
					break
				}
			}
		},
		jump(index) {
			let target = document.querySelector('.scroll-content')
			let scrollItems = document.querySelectorAll('.scroll-item')
			// 判断滚动条是否滚动到底部
			if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
				this.activeStep = index
			}
			let total = scrollItems[index].offsetTop - scrollItems[0].offsetTop // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)
			let distance = document.querySelector('.scroll-content').scrollTop // 滚动条距离滚动区域顶部的距离
			// let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)
			// 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次
			// 计算每一小段的距离
			let step = total / 50
			if (total > distance) {
				smoothDown(document.querySelector('.scroll-content'))
			} else {
				let newTotal = distance - total
				step = newTotal / 50
				smoothUp(document.querySelector('.scroll-content'))
			}

			// 参数element为滚动区域
			function smoothDown(element) {
				if (distance < total) {
					distance += step
					element.scrollTop = distance
					setTimeout(smoothDown.bind(this, element), 10)
				} else {
					element.scrollTop = total
				}
			}

			// 参数element为滚动区域
			function smoothUp(element) {
				if (distance > total) {
					distance -= step
					element.scrollTop = distance
					setTimeout(smoothUp.bind(this, element), 10)
				} else {
					element.scrollTop = total
				}
			}

			// document.querySelectorAll('.scroll-item').forEach((item, index1) => {
			//   if (index === index1) {
			//     item.scrollIntoView({
			//       block: 'start',
			//       behavior: 'smooth'
			//     })
			//   }
			// })
		},
		getData(data) {
			if (data === true) {
				return '是'
			} else if (data === false) {
				return '否'
			}
			if (Array.isArray(data)) {
				return `${data.join('<br/>')}`
			} else {
				return data
			}
		},
		// 抽屉弹窗关闭
		closeDrawer() {
			console.log('父组件关闭弹窗')
			// 刷新表格数据
			this.tableClickRefresh()
		},

		// 分页函数
		handlePageChange(data) {
			console.log('分页函数', data)
			this.pagination.PageIndex = data.pageIndex
			this.pagination.PageRows = data.pageSize
			this.getDataList() //这是重新获取修改日志列表
		},
		// 表格头点击隐藏列
		hiddenColumn(key) {
			console.log(key, "======================");
			this.columnChange = false;
			/**
			 * tree数据源绑定col使用以下方法
			  this.$set(e, "show", isCheck);
			 */
			let idx = this.col.findIndex((item) => item.DicKey == key);
			this.col[idx].show = false;
			this.$nextTick(() => {
				this.columnChange = true;
			});
		},
		// 表格头点击显示列
		showColumn(key) {
			console.log(key, "======================");
			this.columnChange = false;
			/**
			 * tree数据源绑定col使用以下方法
			  this.$set(e, "show", isCheck);
			 */
			let idx = this.col.findIndex((item) => item.DicKey == key);
			this.col[idx].show = true;
			this.$nextTick(() => {
				this.columnChange = true;
			});
		},
		// 新建商铺信息
		tableClickAdd() {
			let Object = {
				data: this.col,
				title: "新建",
				ProjectId: this.$route.query.id //项目id
			};
			this.$refs.Edit.open(Object);
		},

		// 刷新表格
		tableClickRefresh() {
			console.log('刷新表格了', this.$route.query.id, this.queryParam)
			// const loading = this.$loading({
			//     lock: true,
			//     text: '加载中...',
			//     spinner: 'el-icon-loading',
			//     background: 'rgba(0, 0, 0, 0.7)'
			//   });

			// 刷新表格
			this.loading = true;
			this.queryParam[0].Condition = "ProjectId";
			// 初始化页数
			this.pagination.PageIndex = 1;
			// 初始化表格数据
			this.tableData = [];
			// 获取表格数据
			this.getDataList();
			// 刷新列表展示方案
			this.getOptionsData();
			// setTimeout(() => {
			//   loading.close();
			// }, 500);
		},
		init() { },
		// 返回招商组件
		back() {
			// 显示表格
			this.isShow = 1;
		},
		// 弹窗
		open() {
			// 弹窗
			// 列表数据
		},
		//取消弹窗
		handleClose() {
			this.$emit('handleClose')
		},
	},
};
</script>

<style lang="scss" scoped>
.el-menu-item.is-active {
	color: #D1100B !important;
	background-color: rgb(254, 67, 101, 0.4) !important;
}

.el-menu {
	background-color: #eef3f9;

	.el-menu-item {
		// background-color: #D1100B;
	}
}

.anchor-point {

	.scroll-content {}

	.operation-btn {}
}
</style>