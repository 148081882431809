<template>
  <!-- 工程信息 -->
  <div class="EngineeringBaseInfo" style=" margin-top: 20px">

    <el-form ref="Form" size="mini" :model="Form" :rules="Rules" :hide-required-asterisk="false" label-position="right"
      label-width="auto">
      <!-- 基本信息 -->
      <div style="margin-bottom: 10px" v-loading="loadingInfo">
        <div style="

            display: flex;
            justify-content: space-between;
            margin-left: 20px;
            padding-bottom: 5px;
            border-bottom: 1px solid #d3cbcb;
            margin-bottom: 20px;
          ">
          <div style="display: flex">
            <img style="width: auto; height: 23px; margin-right: 5px" src="../../../../assets/jibenxinxi.png" alt="" />
            <span style="font-size: 14px;font-weight: bold;">基本信息</span>
          </div>
          <div style="padding-right: 20px">
            <span v-if="EngineeringBaseInfoDisabled == false" @click="editCancel('EngineeringBaseInfoDisabled')"
              style="cursor: pointer; padding-right: 10px">取 消</span>
            <span v-else style="padding-right: 10px; color: red" class="edit"
              @click="EngineeringBaseInfoDisabled = false">编辑</span>
            <span v-if="EngineeringBaseInfoDisabled == false" class="save"
              @click="handlerSubmitSave('EngineeringBaseInfo')">保存</span>
          </div>
        </div>
        <el-row :gutter="24" style="">
          <el-col :span="24" style="height: 100px;margin-bottom: 10px;">
            <el-form-item label="工程状态">
              <!-- <el-button type="text" disabled v-if="EngineeringBaseInfoDisabled">
                {{ Form.Shop_EngineeringStatus || '其它' }}
              </el-button> -->
              <!-- v-if="!EngineeringBaseInfoDisabled" -->
              <div style="display: flex;">
                <el-steps :active="StatusOptionsActive" style="width: 70%;" align-center>
                  <el-step title="其它" icon="el-icon-d-arrow-right" description="默认状态"></el-step>
                  <el-step v-for="(item, index) in StatusOptions" :key="item.DicValue" :title="item.DicName"
                    :description="item.ChangeTime ? item.ChangeTime.substring(0, 10) : ''">
                    <b slot="icon">{{ index + 1 }}</b>
                  </el-step>
                </el-steps>
                <div style="width: 20%;margin-left: 5%;">
                  <div style="margin-bottom: 10px;">
                    <div style="font-size: 18px;font-weight: bold;line-height: 20px;display: flex;">
                      <div style="width: 80px;text-align: right;">
                        状态：
                      </div>
                      <span style="margin-left: 10px;;width: 220px;" v-if="StatusOptionsActive != 0">
                        {{
                          StatusOptions[StatusOptionsActive - 1] ?
                            StatusOptions[StatusOptionsActive - 1].DicName : ""
                        }}
                      </span>
                      <span style="margin-left: 10px;;width: 220px;" v-else>
                        其它
                      </span>
                    </div>
                    <div style="margin-top: 10px;font-size: 18px;font-weight: bold;line-height: 20px;display: flex;"
                      v-if="StatusOptionsActive != 0">
                      <div style="width: 80px;text-align: right;">
                        <span style="color: red;">*</span>日期：
                      </div>
                      <el-date-picker v-if="StatusOptions[StatusOptionsActive - 1]"
                        :disabled="EngineeringBaseInfoDisabled"
                        v-model="StatusOptions[StatusOptionsActive - 1].ChangeTime" type="date" placeholder="选择日期"
                        format="yyyy-MM-dd" value-format="yyyy-MM-dd" size="mini"
                        style="margin-left: 10px;width: 220px;" :picker-options="pickerOptions">
                      </el-date-picker>
                    </div>
                  </div>
                  <div style="padding-left: 10%;">
                    <el-button @click="StatusOptionsActive--" v-if="StatusOptionsActive > 0"
                      :disabled="EngineeringBaseInfoDisabled">上一步
                    </el-button>
                    <el-button @click="nextStep()" v-if="StatusOptions.length !== (StatusOptionsActive)"
                      :disabled="EngineeringBaseInfoDisabled">下一步
                    </el-button>
                  </div>
                </div>


              </div>

              <!-- IsOpeningBusiness -->
              <!-- <el-select v-model="Form.EngineeringStatus" clearable placeholder="请选择工程状态" style="width: 100%"
                :disabled="EngineeringBaseInfoDisabled">
                <el-option v-for="v in EngineeringStatusOptions" :key="v.value" :label="v.label" :value="v.label">
                </el-option>
              </el-select> -->
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="是否开业">
              <!-- IsOpeningBusiness -->
              <el-select v-model="Form.IsOpeningBusiness" clearable placeholder="请选择是否开业" style="width: 100%"
                :disabled="EngineeringBaseInfoDisabled">
                <el-option v-for="v in IsOpeningBusinessOptions" :key="v.label" :label="v.label" :value="v.label">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="进场日期">
              <el-date-picker v-model="Form.EntryTime" style="width: 100%; cursor: pointer" type="date"
                placeholder="选择日期" value-format="yyyy-MM-dd" :disabled="EngineeringBaseInfoDisabled">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="施工进度">
              <el-input v-model="Form.ConstructionProgress" autocomplete="off" :disabled="EngineeringBaseInfoDisabled">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input type="textarea" v-model="Form.RemarkEngineeringInfo" autocomplete="off"
                :disabled="EngineeringBaseInfoDisabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- 图纸报审 -->
      <div style="margin-bottom: 10px; width: 100%;margin-top: 20px;">
        <div style="
            display: flex;
            justify-content: space-between;
            margin-left: 20px;
            padding-bottom: 8px;
            border-bottom: 1px solid #d3cbcb;
            margin-bottom: 10px;
          ">
          <div style="display: flex;align-items: center;line-height: 26px;">
            <img style="width: auto; height: 24px; margin-right: 5px" src="../../../../assets/tuzhibaoshen.png"
              alt="" />
            <span style="font-weight: bold;font-size: 14px;">商铺报审</span>
            <span>
              <span style="margin-left: 30px;font-size: 14px;font-weight: bold;">当前报审单位：</span>
              <span>{{ SingleConditionList.length > 0 ? SingleConditionList[0].ReportingUnit : '暂无' }}</span>
            </span>
            <span>
              <span style="margin-left: 30px;font-size: 14px;font-weight: bold;">报审手机号：</span>
              <span>{{ SingleConditionList.length > 0 ? SingleConditionList[0].ContactPhone : '暂无' }}</span>
            </span>
            <span style="margin-left: 30px;"><el-button size="mini" type="primary"
                @click="AuditFlag = true">查看报审单位</el-button>
              <el-button size="mini" type="primary" @click="AuditOpen('', 3)"
                v-if="SingleConditionList.length > 0">转发报审链接</el-button></span>
          </div>


        </div>
        <div style="width: 100%;">
          <el-row :gutter="20">
            <el-col :span="11">
              <div style="font-weight: bold;">设备/材料报审
              </div>
              <div style="max-height: 340px;overflow: auto;">
                <div v-for="(item) in auditMaterialsInfoList" :key="item.id"
                  style="border: 1px solid #D3CBCB;margin-top: 10px;border-radius: 10px;cursor: pointer;padding: 10px 0px;"
                  class="auditMaterialsInfoList" @click="editAuditOpen(item, 1)">
                  <el-row style="display: flex;align-items: center;font-size: 14px;">
                    <div style="width: 84px;text-align: right;">
                      <el-tag style="font-weight: bold;letter-spacing: 1px;margin-left: 10px;text-align: center;"
                        effect="plain">{{ item.ApprovalType == 1 ? '设备材料' : item.ApprovalType == 2 ? '深化图纸' : '' }}
                      </el-tag>
                    </div>
                    <div style="width: calc(100% - 264px);margin-left: 10px;">
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold;">报审单名称：</span>
                          <span :title="item.ApprovalName">{{ item.ApprovalName }}</span>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold;">报审单编号：</span>
                          <span :title="item.ApprovalCode">{{ item.ApprovalCode }}</span>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold;">报审单批次：</span> 第 <span
                            style="font-weight: bold;color: #D1100B;">{{ item.ApprovalBatch }}</span> 批次
                        </el-col>
                      </el-row>

                    </div>
                    <div style="width: 160px;margin-left: 10px">
                      <div style="text-align: center;">
                        <el-tag style="height: 25px;line-height: 25px;padding: 0 10px;" type="primary"
                          v-if="item.Status == 0">未提审
                        </el-tag>
                        <el-tag type="success" style="height: 25px;line-height: 25px;padding: 0 10px;"
                          v-if="item.Status == 1">已提审
                        </el-tag>
                      </div>
                      <div style="margin-top: 10px;text-align: center;">
                        <span style="text-align: center;color: #C0C4CC;font-size: 13px;margin-top: 5px">
                          {{ item.CreateTime ? item.CreateTime.substring(0, 19) : '' }}
                        </span>
                      </div>

                    </div>

                  </el-row>

                </div>
                <el-empty description="暂无数据" v-if="auditMaterialsInfoList.length == 0"></el-empty>
              </div>
            </el-col>
            <el-col :span="11" :offset="1">
              <div style="font-weight: bold;">深化图纸报审</div>

              <div style="height: 340px;overflow: auto;">
                <div v-for="(item) in auditImgInfoList" :key="item.id"
                  style="border: 1px solid #D3CBCB;margin-top: 10px;border-radius: 10px;cursor: pointer;padding: 10px 0px;"
                  class="auditMaterialsInfoList" @click="editAuditOpen(item, 2)">
                  <el-row style="display: flex;align-items: center;font-size: 14px;">
                    <div style="width: 84px;text-align: right;">
                      <el-tag style="font-weight: bold;letter-spacing: 1px;margin-left: 10px;text-align: center;"
                        effect="plain">{{ item.ApprovalType == 1 ? '设备材料' : item.ApprovalType == 2 ? '深化图纸' : '' }}
                      </el-tag>
                    </div>
                    <div style="width: calc(100% - 264px);margin-left: 10px;">
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold;">报审单名称：</span>
                          <span :title="item.ApprovalName">{{ item.ApprovalName }}</span>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold;">报审单编号：</span>
                          <span :title="item.ApprovalCode">{{ item.ApprovalCode }}</span>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold;">报审单批次：</span> 第 <span
                            style="font-weight: bold;color: #D1100B;">{{ item.ApprovalBatch }}</span> 批次
                        </el-col>
                      </el-row>

                    </div>
                    <div style="width: 160px;margin-left: 10px">
                      <div style="text-align: center;">
                        <el-tag style="height: 25px;line-height: 25px;padding: 0 10px;" type="primary"
                          v-if="item.Status == 0">未提审
                        </el-tag>
                        <el-tag type="success" style="height: 25px;line-height: 25px;padding: 0 10px;"
                          v-if="item.Status == 1">已提审
                        </el-tag>
                      </div>
                      <div style="margin-top: 10px;text-align: center;">
                        <span style="text-align: center;color: #C0C4CC;font-size: 13px;margin-top: 5px">
                          {{ item.CreateTime ? item.CreateTime.substring(0, 19) : '' }}
                        </span>
                      </div>

                    </div>

                  </el-row>

                </div>
                <el-empty description="暂无数据" v-if="auditImgInfoList.length == 0"></el-empty>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

    </el-form>
    <el-dialog :visible.sync="AuditFlag" width="60%" append-to-body :show-close="false" :close="AuditClose"
      custom-class="Audit-Dialog">
      <div slot="title">
        <div style="line-height: 33px;display: flex;justify-content: space-between;">
          <div style="width: 100%;text-align: center;font-weight: bold;font-size: 18px;">
            报审单位
          </div>
          <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;" src="@/assets/delete.png" alt=""
            @click="AuditClose" />
        </div>
      </div>
      <el-card class="Audit-el-card">
        <div slot="header" style="line-height: 30px;font-weight: bold;padding: 0;margin: 0;" class="">
          添加报审单位
        </div>
        <div>
          <el-form :model="AuditForm" ref="AuditForm" label-width="120px" class="demo-AuditForm">
            <el-row :gutter="20">
              <el-col :span="10">
                <el-form-item label="提报单位：">
                  <el-input v-model="AuditForm.ReportingUnit"></el-input>
                </el-form-item>

              </el-col>
              <el-col :span="10">
                <el-form-item label="报审手机号：">
                  <el-input v-model="AuditForm.ContactPhone" type="number"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-button type="primary" @click="AuditFormSubmitSave(AuditForm)">
                  添加
                </el-button>

              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-card>
      <el-card style="height: 50vh;overflow-y: auto;overflow-x: hidden;width: 100%;margin-top: 10px;"
        class="SingleConditionList Audit-el-card" body-style="padding: 0 20px;">
        <div slot="header" style="line-height: 30px;font-weight: bold;padding: 0;margin: 0;" class="">
          报审单位列表
        </div>
        <div>
          <el-divider></el-divider>
          <div v-for="(item) in SingleConditionList" :key="item.Id">
            <el-row :gutter="20" style="line-height: 40px;">
              <el-col :span="20">
                <el-row :gutter="20">
                  <el-col :span="10">
                    <div style="font-size: 16px;line-height: 40px;">
                      <span
                        style="display: inline-block;width: 110px;text-align: right;font-weight: bold;">报审单位：</span><span>{{
                          item.ReportingUnit }}</span>
                    </div>
                  </el-col>
                  <el-col :span="10">
                    <div style="font-size: 16px;line-height: 40px;">
                      <span style="display: inline-block;width: 110px;text-align: right;font-weight: bold;">
                        报审手机号：</span><span>{{
                          item.ContactPhone }}</span>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div style="font-size: 16px;line-height: 40px;">
                      <span style="display: inline-block;width: 110px;text-align: right;font-weight: bold;">已报审次数：
                      </span><span>{{
                        item.ApprovalInfoCount }}</span>
                    </div>

                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="4" style="text-align: center;">
                <el-button type="primary" @click="AuditFormDelete(item)" icon="el-icon-delete" size="small"
                  :disabled="item.ApprovalInfoCount > 0">
                  删除
                </el-button>
              </el-col>
            </el-row>
            <el-divider></el-divider>
          </div>
        </div>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="AuditClose">关闭</el-button>

      </div>

    </el-dialog>

    <!-- //查看报审 -->
    <el-dialog :visible.sync="ViewAuditFlag" width="70%" append-to-body :show-close="false" :close="ViewAuditFlagClose"
      custom-class="ViewAudit">
      <div slot="title">
        <el-row style="font-size: 18px;font-weight: bold;color:#606266;display: flex;">
          <div style="width: calc(100% - 33px);text-align: center;" class="ellipsis1">
            {{
              ViewAuditForm.ApprovalName
            }}
          </div>
          <div style="text-align: right;width: 33px;">
            <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;" src="@/assets/delete.png" alt=""
              @click="ViewAuditFlagClose" />
          </div>
        </el-row>
        <el-row style="font-size: 14px;font-weight: bold;color:#909399;text-align: left;width: calc(100% - 33px);"
          class="ellipsis">
          {{
            ViewAuditForm.ApprovalCode
          }}

        </el-row>
      </div>
      <el-row :gutter="10" v-loading="ViewAuditLoadings">
        <el-col :span="9">
          <el-card>
            <div style="overflow-y: auto;height: 65vh;" class="viewList">
              <el-descriptions class="margin-top" :column="1" size="medium" border>
                <el-descriptions-item :label="addAuditType == 1 ? '设备/材料名称' : '深化图纸名称'">
                  {{ ViewAuditForm.ApprovalName }}</el-descriptions-item>
                <el-descriptions-item :label="addAuditType == 1 ? '设备/材料报审单编号' : '深化图纸报审单编号'">
                  {{ ViewAuditForm.ApprovalCode }}</el-descriptions-item>
                <el-descriptions-item label="报审批次">第<span style="color: #D1100B;">{{ ViewAuditForm.ApprovalBatch
                    }}</span>批次
                </el-descriptions-item>
                <el-descriptions-item label="报审单位">{{ ViewAuditForm.ReportingUnit }}</el-descriptions-item>
                <el-descriptions-item label="报审人">{{ ViewAuditForm.ReportingName }}</el-descriptions-item>
                <el-descriptions-item label="报审日期"> {{
                  ViewAuditForm.ReportingDate ? ViewAuditForm.ReportingDate.substring(0, 19) : ''
                }}</el-descriptions-item>
                <el-descriptions-item label="关联附件">
                  <div v-for="item in ViewUpFileData" :key="item.Id"
                    style="color: #D1100B;cursor: pointer;font-size: 14px;font-weight: bold;"
                    @click="downloadFile(item)">
                    {{ item.FileName }}
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </el-card>
        </el-col>
        <el-col :span="15">
          <el-card>
            <div class="content" style="overflow-y: auto;height: 65vh;">
              <div>
                <div v-for="item in ViewSignatoryUnitOptions" :key="item.Id">
                  <el-form label-width="120px" style="width: 80%;font-weight: bold;" class="ViewAuditForm" disabled>
                    <el-form-item :label="item.ApprovalOrganName + '：'">
                      <el-select v-model="item.ApprovalOpinions" clearable placeholder="未选择批复意见" style="width: 60%">
                        <el-option v-for="v in ApprovalOpinions" :key="v.DicName" :label="v.DicName"
                          :value="v.DicValue">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="批注意见：">
                      <el-select v-model="item.ApprovalAnnotation" clearable placeholder="未选择批注意见" style="width: 60%">
                        <el-option v-for="v in AnnotationOpinions" :key="v.DicName" :label="v.DicName"
                          :value="v.DicValue">
                        </el-option>
                      </el-select>
                      <el-input type="textarea" style="margin-top: 5px;" v-model="item.ApprovalARemark">
                      </el-input>
                    </el-form-item>
                    <el-form-item label="关联附件：">
                      <div v-for="c in item.AuditUpFileData" :key="c.Id"
                        style="color: #D1100B;cursor: pointer;font-size: 14px;font-weight: bold;"
                        @click="downloadFile(c)" class="ellipsis">
                        {{ c.FileName }}
                      </div>
                    </el-form-item>
                    <el-form-item label="负责人签署：">
                      <div style="display: flex;align-items: center;">
                        <el-image style="width: 200px; height: 100px" :src="item.ApproverSignature">
                          <div slot="error"
                            style="width: 100%;height: 100%;text-align: center;line-height: 100px;background-color: #F5F7FA;">
                            暂无数据
                          </div>
                        </el-image>
                        <div style="margin-left: 10px;">
                          <el-date-picker v-model="item.ApprovalDateTime" type="date" value-format="yyyy-MM-dd "
                            format="yyyy-MM-dd " placeholder="未选择日期">
                          </el-date-picker>
                        </div>
                        <div style="margin-left: 20px;">
                          <el-tag v-if="item.ApprovalStatus == 1" type="success">
                            已审核
                          </el-tag>
                          <el-tag v-if="item.ApprovalStatus == 0" type="danger">
                            未审核或暂存
                          </el-tag>
                        </div>
                      </div>
                    </el-form-item>

                  </el-form>
                  <el-divider></el-divider>
                </div>
              </div>
              <!-- <div v-if="addAuditType == 2">
                <div v-for="item in ViewSignatoryUnitOptions" :key="item.value">
                  <el-form label-width="120px" style="width: 80%;font-weight: bold;" class="ViewAuditForm" disabled>

                    <el-form-item :label="item.ApprovalOrganName + '：'">
                      <div style="display: flex;">
                        <el-select v-model="item.ApprovalOpinions" clearable placeholder="意见归类"
                          style="width: 30%;display: inline-block;" size="small">
                          <el-option v-for="v in OpinionClassification" :key="v.DicName" :label="v.DicName"
                            :value="v.DicValue">
                          </el-option>
                        </el-select>
                        <el-input type="textarea" style="margin-top: 5px;display: inline-block;" placeholder="批注意见备注"
                          :rows="1" v-model="item.ApprovalARemark">
                        </el-input>
                        <div style="margin-left: 20px;">
                          <el-tag v-if="item.ApprovalOpinions" type="success">
                            已签署
                          </el-tag>
                          <el-tag v-if="!item.ApprovalOpinions" type="danger">
                            未签署
                          </el-tag>
                        </div>
                      </div>
                    </el-form-item>


                  </el-form>
                  <el-divider></el-divider>
                </div>
              </div> -->

            </div>

          </el-card>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ViewAuditFlagClose">取 消</el-button>

      </span>
    </el-dialog>
    <!-- 提审 -->
    <el-dialog :visible.sync="qrcodeVisible" width="50%" append-to-body :show-close="false">
      <div slot="title">
        <el-row style="height: 33px;text-align: center;font-weight: bold;font-size: 18px;">
          请将链接转发至报审单位人员进行报审
          <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;float: right;"
            src="@/assets/delete.png" alt="" @click="qrcodeVisible = false" />
        </el-row>
      </div>
      <div style="display: flex;justify-content: space-around;align-items: center;height: 200px;">
        <div>
          <span>点击链接复制：</span><br>
          <span style="color: #D1100B;cursor: pointer;" @click="copyUrl(qrcode)"> {{ qrcode }}</span>
        </div>
        <!-- <div>
          <vue-qr :text="qrcode" :logoScale="0.2" :size="200"></vue-qr>
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrcodeVisible = false">关闭</el-button>

      </span>
    </el-dialog>
  </div>
</template>

<script>
// 引入工具素具
import {
  IsOnGroundOptions,
  // EngineeringStatusOptions,
  IsOpeningBusinessOptions,
} from "../mock.js";
import vueQr from "vue-qr";
// 上传组件
// import UploadValue from "@/components/UploadValue/UploadValue.vue";
import moment from 'moment'
export default {
  name: "WorkspaceJsonShopBaseInfo",
  components: {

    vueQr
  },
  props: {
    EngineeringStatusOptions: {
      type: Array,
      default: () => []
    },

    parentObj: { type: Object },
  },
  data() {
    return {
      AuditFlag: false,
      moment,
      loadingInfo: false,
      StatusOptionsActive: 0,
      StatusOptions: [],
      // 工程状态下拉
      // EngineeringStatusOptions: [],
      //当前工程状态
      StatusData: [],
      qrcode: '',//提审跳转H5页面二维码
      qrcodeVisible: false,
      auditPhone: "",
      // 新建报审
      addAuditFlag: false,
      //图审ROWId 上传时ID
      rowId: '',
      addAuditType: 0,
      addAuditFlagForm: {
        ShopId: "",
        ApprovalType: "",
        ApprovalTitle: "",
        ApprovalSubtitle: "",
        ReportingUnit: "",
        SignatoryUnit: '',

      },
      addAuditFlagRules: {
        ApprovalTitle: [
          { required: true, message: "请输入主标题", trigger: "blur" },

        ],
        ApprovalSubtitle: [
          { required: true, message: "请输入副标题", trigger: "blur" },

        ],
        ReportingUnit: [
          { required: true, message: "请输入提报单位", trigger: "blur" },

        ],
        SignatoryUnit: [
          { required: true, message: "请选择签署机构", trigger: "change" },
        ],
        addAuditUpFileData: [
          { required: true, message: "请上传附件", trigger: "blur" },
        ],

      },
      addAuditLoadings: false,
      addAuditUpFileData: [],
      SignatoryUnitList: [
        {
          ApprovalId: '',
          ApprovalOrganType: 1,
          ApprovalOrganName: '',
          ApprovalUserType: '',
          ApprovalPhone: '',
        }
      ],
      // 签署机构下拉列表数据
      SignatoryUnitOptions: [
      ],
      addIsDisabled: false,
      //批复意见-材料报审-内
      ApprovalOpinions: [],
      //批注意见-材料报审-内
      AnnotationOpinions: [],
      // 意见归类-图纸审核-内
      OpinionClassification: [],

      // 设备/材料报审列表
      auditMaterialsInfoList: [

      ],
      //深化图纸报审
      auditImgInfoList: [
      ],
      AuditTitle: '',
      //查看报审
      ViewAuditFlag: false,
      ViewAuditLoadings: false,
      ViewAuditForm: {

      },
      ViewUpFileData: [],
      ViewSignatoryUnitOptions: [],


      // 基础信息
      // 传递过来的当前行的基础商铺信息
      rows: {},
      // 首页传递过来的项目，任务，应用id对象
      newTaskObj: {},
      // 基本信息禁用
      EngineeringBaseInfoDisabled: true,
      // 图纸报审禁用
      DrawingApprovalDisabled: true,
      // 设备审核禁用
      EquipmentAndMaterialReviewDisabled: true,

      //是否开业
      IsOpeningBusinessOptions,
      // 标题类型
      title: "",
      loading: false,
      show: false,
      // 表单
      Form: {
        // 内审状态
        StatusInternalAudit: 1,
        // 外审状态
        StatusExternalAudit: 1,
        // 设备材料审核状态
        StatusEAMR: 1,
      },
      // 校验规则
      Rules: {},
      // 地上地下下拉数据
      IsOnGroundOptions,
      // 业态级别下拉数据
      // 楼栋信息
      buildingOptions: [],
      // 业态级别
      ShopFormatClassOptions: [],
      // 业态名称下拉选择
      ShopFormatNameOptions: [],
      // 原商铺id下拉列表数据
      ShopLastIdsOptions: [],
      // 图片上传的地址
      url: "https://api.zytsaas.com/Base_Manage/Upload/ProjectImgOssUploadFileByForm",
      // 内审上传显示的文件
      fileDataInternalAudit: [],
      // 外审上传显示文件
      fileDataExternalAudit: [],
      // 设备材料审核显示文件
      fileDataEquipmentAndMater: [],
      //报审单位列表
      SingleConditionList: [],
      AuditForm: {
        ContactPhone: "",
        ReportingUnit: ""
      },
      IsAuditForm: false,
    };
  },


  computed: {
    pickerOptions() {
      return {
        disabledDate: (date) => {
          if (this.StatusOptions[this.StatusOptionsActive - 2]) {
            return moment(date) < moment(this.StatusOptions[this.StatusOptionsActive - 2].ChangeTime)
          } else {
            return false
          }

        },

      }
    },
    SignatoryUnitOptionsLength() {

      if (this.SignatoryUnitList.length === 1) {
        return true
      } else {
        return false
      }

    }
  },
  async created() {
  },
  mounted() {
    console.log(this.Form, '1111111')
  },
  methods: {
    downloadFile(item) {
      window.open(item.FileWebURL)
    },
    copyUrl(text) {
      var input = document.createElement("input"); // 创建input对象
      input.value = text; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    AuditClose() {
      this.AuditFlag = false
    },
    // 图审删除附件
    deleteFile(a, b) {
      console.log(a, b)
    },
    //图审签署保存
    SubSignatoryUnitList() {
      // let arr = this.SignatoryUnitList.map(item => {
      //   return {
      //     ApprovalId: this.rowId,
      //     ApprovalOrganType: item.ApprovalOrganName,
      //     ApprovalOrganName: item.ApprovalOrganName,
      //     ApprovalUserType: item.ApprovalUserType,
      //     ApprovalPhone: item.ApprovalPhone,
      //   }
      // })
      let arr = [...this.SignatoryUnitList]
      arr.forEach(item => {
        item.ApprovalId = this.rowId
        item.ApprovalOrganType = item.ApprovalOrganName
      })
      this.$http.post('/Shop_Manage/Shop_ApprovalSignInfo/SaveDataByList', arr).then(res => {
        this.addAuditFlag = false;
        this.addAuditLoadings = false
        if (res.Success) {
          this.$message.success("新建报审信息成功");
          if (this.addAuditType == 1) {
            this.getDataListByMoreCondition(1)
          } else if (this.addAuditType == 2) {
            this.getDataListByMoreCondition(2)
          }
        } else {
          this.$message.error(res.Msg);
        }
      })
    },
    //图审编辑详情

    async getApprovalInfo(item) {
      //主要信息详情
      this.$http.post('/Shop_Manage/Shop_ApprovalInfo/GetTheData', {
        "id": item.Id
      }).then(res => {
        if (res.Success) {
          this.addAuditFlagForm = { ...res.Data }
        }
      })
      //附件详情
      this.$http.post('/Shop_Manage/Shop_ApprovalInfo_FAL/GetDataListBySingleCondition', {
        "Search": {
          "Condition": "ApprovalId",
          "Keyword": item.Id
        }
      }).then(res => {
        if (res.Success) {
          res.Data.length
          if (res.Data.length > 0 && res.Data) {
            res.Data.forEach(item => {
              item.name = item.FileName
              item.size = item.FileSize
              item.url = item.FileWebURL
              item.uid = item.Id
              item.status = "success"
            })
            this.addAuditUpFileData = [...res.Data]
          } else {
            this.addAuditUpFileData = []
          }

        }
      })
      //签署机构详情
      this.$http.post('/Shop_Manage/Shop_ApprovalSignInfo/GetDataListBySingleCondition', {
        "Search": {
          "Condition": "ApprovalId",
          "Keyword": item.Id
        }
      }).then(res => {
        if (res.Success) {
          if (res.Success) {
            res.Data.forEach(item => {
              item.vid = new Date().getTime()
            })
            if (res.Data.length > 0) {
              this.SignatoryUnitList = [...res.Data]
            } else {
              this.SignatoryUnitList = [{
                ApprovalId: '',
                ApprovalOrganType: '',
                ApprovalOrganName: '',
                ApprovalUserType: 1,
                ApprovalPhone: '',
              }]
            }

          }
        }
      })
    },
    ///图审删除
    AuditDelete(item, num) {
      this.$confirm('此操作将删除该信息,确认删除吗', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/Shop_Manage/Shop_ApprovalInfo/DeleteData', [item.Id]).then(res => {
          if (res.Success) {
            this.$message.success('删除成功')
            if (num == 1) {
              this.getDataListByMoreCondition(1)
            } else if (num == 2) {
              this.getDataListByMoreCondition(2)
            }
          } else {
            this.$message.error(res.Msg)
          }
        })
      })



    },
    // 图审字典
    getApprovalOpinions() {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: 'ZS_ApprovalOpinions_CL_N',
        }).then(res => {
          if (res.Success) {
            this.ApprovalOpinions = res.Data.DictionaryDetailsList || []
          }
        })
    },
    // 图审字典
    getAnnotationOpinions() {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: 'ZS_AnnotationOpinions_CL_N',
        }).then(res => {
          if (res.Success) {
            this.AnnotationOpinions = res.Data.DictionaryDetailsList || []
          }
        })
    },
    // 图审字典
    getOpinionClassification() {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: 'ZS_OpinionClassification_TS_N',
        }).then(res => {
          if (res.Success) {
            this.OpinionClassification = res.Data.DictionaryDetailsList || []
          }
        })
    },
    //图审字典
    getSignatoryUnitOptions() {
      let Keyword = ''
      if (this.addAuditType == 1) {
        Keyword = 'ZS_ExternalOrganization_CL_N'
      } else if (this.addAuditType == 2) {
        Keyword = 'ZS_ExternalOrganization_TS_N'
      }
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: Keyword,
        })
        .then(res => {
          if (res.Success) {
            this.SignatoryUnitOptions = res.Data.DictionaryDetailsList || []
          }
        })
    },
    // 图审列表
    async getDataListByMoreCondition(ApprovalType) {
      await this.$http.post('/Shop_Manage/Shop_ApprovalInfo/GetDataListByMoreCondition', {
        "SortField": "CreateTime",
        "SortType": "desc",
        "Search": [
          {
            "Condition": "ShopId",
            "Keyword": this.parentObj.childrenRow.Id,
          },
          {
            "Condition": "ApprovalType",
            "Keyword": ApprovalType,
          }
        ]
      }).then(res => {
        if (res.Success) {
          if (ApprovalType == 1) {
            this.auditMaterialsInfoList = res.Data || []
          } else if (ApprovalType == 2) {
            this.auditImgInfoList = res.Data || []
          }


        }
      })
    },
    //工程状态
    async getSubEngineeringStatus(arr) {


      await this.$http.post('/Shop_Manage/Shop_StatusRecord/SaveDataByCondition', {
        ModuleType: 'EngineeringStatus',
        ShopId: this.parentObj.childrenRow.Id,
        StatusRecords: arr
      }).then(res => {
        if (res.Success) {
          this.$set(this.Form, 'EngineeringStatus', arr.length > 0 ? arr[arr.length - 1].Status : '其他')
          // this.$message.success('工程状态变更成功');

        } else {
          this.$message.error(res.Msg);
        }
      })
    },
    //工程下一步
    nextStep() {
      if (this.StatusOptionsActive == 0) {
        this.StatusOptionsActive++
        return
      }
      if (this.StatusOptions[this.StatusOptionsActive - 1].ChangeTime) {
        this.StatusOptionsActive++
      } else {
        this.$message.warning('请选择日期')
      }

    },
    //获取工程状态
    getStatusOptions() {
      this.$http.post('/Shop_Manage/Shop_StatusRecord/GetDataListByMoreCondition', {
        "Search": [
          {
            "Condition": "ShopId",
            "Keyword": this.parentObj.childrenRow.Id
          },
          {
            "Condition": "ModuleType",
            "Keyword": 'EngineeringStatus'
          },
        ]
      }).then(res => {
        if (res.Success) {
          this.StatusData = res.Data || []
          if (this.StatusData.length > 0) {
            this.StatusOptions.forEach(item => {
              let obj = this.StatusData.find(val => {
                return val.Status === item.DicName
              })
              if (obj) {
                this.$set(item, 'ChangeTime', obj.ChangeTime)

              }
            })
            this.StatusOptionsActive = this.StatusData.length
          } else {
            this.StatusOptionsActive = 0
          }
        }
      })
    },
    //图审新建添加
    addSignatory(index) {
      this.SignatoryUnitList.splice(index + 1, 0, {
        ApprovalId: '',
        ApprovalOrganType: '',
        ApprovalOrganName: '',
        ApprovalUserType: 1,
        ApprovalPhone: '',
      })

    },
    delSignatory(index, item) {
      console.log(item, index)

      if (item.Id) {
        this.$confirm('此操作将删除该信息,确认删除吗', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post('/Shop_Manage/Shop_ApprovalSignInfo/DeleteData', [item.Id]).then(res => {
            if (res.Success) {
              this.$message.success('删除成功')
              this.SignatoryUnitList.splice(index, 1)
            } else {
              this.$message.error(res.Msg)
            }
          })
        })
      } else {
        this.SignatoryUnitList.splice(index, 1)
      }

    },
    AuditOpen(item, num) {
      if (num === 3) {
        this.qrcodeVisible = true
        // this.qrcode = window.location.origin + '/ExternalUserLogin'
        this.qrcode = window.location.origin + '/Shop_SubmitApprovalLogin?shopId=' + this.parentObj.childrenRow.Id
        return
      }

      // console.log(item)
      // this.qrcodeVisible = true
    },
    ViewAuditFlagClose() {
      this.ViewAuditFlag = false
    },
    editAuditOpen(item, num) {
      // if (item.Status === 0) {
      //   this.addAuditOpen(num, item)
      //   return
      // }
      console.log(item, 1)
      this.addAuditType = num
      this.getSignatoryUnitOptions()
      if (num == 1) {
        this.getApprovalOpinions()
        this.getAnnotationOpinions()
      } else if (num == 2) {
        this.getOpinionClassification()
      }

      this.ViewAuditFlag = true
      this.getViewAuditDataInfo(item)
      // this.ViewAuditForm = { ...item }
    },
    getViewAuditDataInfo(item) {
      this.ViewAuditLoadings = true
      let a = () => {
        this.ViewAuditForm = {}
        return this.$http.post('/Shop_Manage/Shop_ApprovalInfo/GetTheData', {
          id: item.Id
        }).then(res => {
          if (res.Success) {
            this.ViewAuditForm = res.Data || {}
          } else {
            this.$message.error(res.Msg)
          }
        })
      }
      let b = () => {
        this.ViewUpFileData = []
        return this.$http.post('/Shop_Manage/Shop_ApprovalInfo_FAL/GetDataListByMoreCondition', {
          "Search": [
            {
              "Condition": "ApprovalId",
              "Keyword": item.Id
            }
          ]
        }).then(res => {
          if (res.Success) {
            if (res.Data && res.Data.length > 0) {
              res.Data.forEach(item => {
                item.name = item.FileName
                item.size = item.FileSize
                item.url = item.FileWebURL
                item.uid = item.Id
                item.status = "success"
              })
              this.ViewUpFileData = res.Data
            } else {
              this.ViewUpFileData = []
            }

          } else {
            this.$message.error(res.Msg)
          }
        })
      }
      let c = () => {
        this.ViewSignatoryUnitOptions = []
        return this.$http.post('/Shop_Manage/Shop_ApprovalSignInfo/GetDataListByMoreCondition', {
          Search: [
            {
              "Condition": "ApprovalId",
              "Keyword": item.Id
            },
          ]
        }).then(async res => {
          if (res.Success) {
            this.ViewSignatoryUnitOptions = res.Data || []
            for (const val of this.ViewSignatoryUnitOptions) {
              this.$set(val, 'AuditUpFileData', [])
              await this.getitemUpList(val)
            }
          } else {
            this.$message.error(res.Msg)
          }
        })
      }
      Promise.all([
        a(), b(), c()
      ]).then(() => {
        this.ViewAuditLoadings = false
      }).catch(() => {
        this.ViewAuditLoadings = false
      })



    },
    async getitemUpList(item) {
      await this.$http.post('/Shop_Manage/Shop_ApprovalSignInfo_FAL/GetDataListBySingleCondition', {
        "Search":
        {
          "Condition": "ApprovalSignId",
          "Keyword": item.Id
        }

      }).then(res => {
        if (res.Success) {
          res.Data.forEach(val => {
            val.name = val.FileName
            val.size = val.FileSize
            val.url = val.FileWebURL
            val.uid = val.Id
            val.status = "success"
          })
          this.$set(item, 'AuditUpFileData', res.Data)
        } else {
          this.$phoneMessage({
            message: '附件列表获取失败',
          })
        }
      })

    },
    addAuditClose() {
      this.addAuditFlag = false;
    },
    addAuditOpen(num, item, isTrue) {
      this.addAuditLoadings = false
      if (item) {
        this.AuditTitle = '编辑'
      } else {
        this.AuditTitle = '新建'
      }

      if (isTrue) {
        this.AuditTitle = '查看'
        this.addIsDisabled = true
      } else {
        this.addIsDisabled = false
      }
      console.log(2)
      this.addAuditType = num
      this.addAuditFlag = true;
      this.addAuditUpFileData = []
      this.rowId = ''
      this.addAuditFlagForm = {
        ShopId: "",
        ApprovalType: "",
        ApprovalTitle: "",
        ApprovalSubtitle: "",
        ReportingUnit: "",
        SignatoryUnit: '',

      }
      this.addAuditLoadings = false
      this.SignatoryUnitList = [{
        ApprovalId: '',
        ApprovalOrganType: '',
        ApprovalOrganName: '',
        ApprovalUserType: 1,
        ApprovalPhone: '',
      }]
      this.getSignatoryUnitOptions()
      this.$nextTick(() => {
        this.$refs.addAuditFlagForm.resetFields();
        if (item) {
          this.getApprovalInfo(item)
        }
      });
    },

    //附件上传成功后避免组件验证失效
    propValidateField() {
      this.SubSignatoryUnitList()
      setTimeout(() => {
        this.$refs["addAuditFlagForm"].validateField("addAuditUpFileData");
      }, 100);
    },
    // 新增保存
    async addAuditSubmit() {



      // console.log(flag)
      // console.log(this.addAuditUpFileData[0], '2')
      console.log(this.addAuditFlagForm)

      this.$refs.addAuditFlagForm.validate((valid) => {
        if (valid) {
          let flag = this.SignatoryUnitList.every(item => {
            return item.ApprovalOrganName && (item.ApprovalUserType === 0 || item.ApprovalUserType === 1) && (/^1[3-9]\d{9}$/.test(item.ApprovalPhone))
          })
          if (!flag) {
            return this.$message.error('请填写正确的签署机构信息')
          }

          this.addAuditLoadings = true

          this.$http
            .post("/Shop_Manage/Shop_ApprovalInfo/SaveData", {
              ...this.addAuditFlagForm,
              ApprovalType: this.addAuditType,
              ShopId: this.parentObj.childrenRow.Id,
            })

            .then(async (res) => {

              if (res.Success) {
                let data = res.Data
                this.rowId = data.Id
                console.log(this.rowId, 'rowId')
                await setTimeout(async () => {
                  let flag = await this.$refs.UploadValue.upload()
                  if (!flag) {
                    this.SubSignatoryUnitList()
                  }
                })
              } else {
                this.$message.error("新建报审信息失败");
                this.addAuditLoadings = false
              }
            });
        } else {
          return false;
        }
      });
    },
    addAuditUpSuccess(FileList) {
      this.addAuditUpFileData = [...FileList]
      console.log(this.addAuditUpFileData, '11')
    },
    addAuditUpRemove(FileList, newFileListData) {
      this.addAuditUpFileData = [...FileList]
      console.log(FileList, newFileListData)
      console.log(this.addAuditUpFileData, '1122')
    },
    // 取消按钮
    editCancel(type) {
      if (type === "EngineeringBaseInfoDisabled") {
        // 基本信息
        this.EngineeringBaseInfoDisabled = true;
      } else if (type === "DrawingApprovalDisabled") {
        // 图纸报审
        this.DrawingApprovalDisabled = true;
      } else if (type === "EquipmentAndMaterialReviewDisabled") {
        // 设备材料审核
        this.EquipmentAndMaterialReviewDisabled = true;
      }
      var _this = this;
      this.shopBaseInfoDisabled = true;
      let Id = this.parentObj.childrenRow.Id;
      console.log("获取editDetail父组件数据", this.parentObj.childrenRow);
      // 清空所有form表单数据重新获取渲染
      this.Form = {};

      // /消除校验
      setTimeout(function () {
        _this.$refs["Form"].clearValidate(); //消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
      }, 30);
      // 重新获取编辑详情渲染
      this.getShopInfoTheListData(Id);
      this.StatusOptions = JSON.parse(JSON.stringify([...this.EngineeringStatusOptions]))
      this.getStatusOptions()
      // setTimeout(function () {//消除某个表单的校验,这里的setTimeOut不能去掉,去掉之后会不生效在clearValidate()里以数组形式 填写表单字段的 prop属性值,
      // _this.$refs['Form'].clearValidate(['sn']);
      //     },30);
      // 重新刷新详情  避免取消条件保存输入的数据
    },
    // 获取编辑详情数据
    getShopInfoTheListData(Id) {
      this.$http
        .post("/Shop_Manage/Shop_BaseInfo/GetTheData", {
          Id: Id,
        })
        .then((res) => {
          this.loading = false;
          console.log("重新获取详情结果", res.Data);
          if (res.Success) {
            if (res.Data.IsOnGround == 1) {
              res.Data.IsOnGround = "地上";
            } else if (res.Data.IsOnGround == 2) {
              res.Data.IsOnGround = "地下";
            } else {
              res.Data.IsOnGround = "屋面";
            }
            this.Form = res.Data;
            // 存储vux  商铺信息
            this.$store.commit("getShopInfo", res.Data);
            this.parentObj.refreshLog()
          } else {
            this.loading = false;
          }
        });
    },
    // 上传是否成功  内审附件
    uploadSuccessInternalAudit(fileList, newFileListData) {
      console.log("内审附件上传成功-===", fileList, newFileListData);
      // fileList  赋值 用于传递    newFileListData用于处理传参
      this.fileDataInternalAudit = fileList;
      // 内审附件url
      if (newFileListData.length > 1) {
        this.$set(this.Form, "InternalAuditUrl", newFileListData.join(","));
      } else {
        this.$set(this.Form, "InternalAuditUrl", newFileListData[0]);
      }
      // // 内审附件数量
      this.$set(this.Form, "CountInternalAudit", newFileListData.length);
      console.log(
        "子组件upload传递过来的附件",
        fileList,
        newFileListData,
        this.fileDataInternalAudit,
        this.Form
      );
    },
    // 上传是否成功  外审附件
    uploadSuccessExternalAudit(fileList, newFileListData) {
      this.fileDataExternalAudit = fileList;
      // 外审附件url
      if (newFileListData.length > 1) {
        this.$set(this.Form, "ExternalAuditUrl", newFileListData.join(","));
      } else {
        this.$set(this.Form, "ExternalAuditUrl", newFileListData[0]);
      }

      // 外审附件数量
      this.$set(this.Form, "CountExternalAudit", newFileListData.length);
    },
    // 设备材料审核上传附件
    uploadSuccessEquipmentAndMater(fileList, newFileListData) {
      console.log("设备材料审核上传附件成功===", fileList, newFileListData);
      this.fileDataEquipmentAndMater = fileList;

      // 设备材料附件url
      if (newFileListData.length > 1) {
        this.$set(
          this.Form,
          "EquipmentAndMaterFile",
          newFileListData.join(",")
        );
      } else {
        this.$set(this.Form, "EquipmentAndMaterFile", newFileListData[0]);
      }
      // 设备材料审核附件个数赋值
      this.$set(this.Form, "CountEAMR", newFileListData.length);
    },
    // 内审附件删除
    handleRemoveInternalAudit(fileList, newFileListData) {
      this.fileDataInternalAudit = fileList;
      // 内审附件url
      if (newFileListData.length > 1) {
        this.$set(this.Form, "InternalAuditUrl", newFileListData.join(","));
      } else {
        this.$set(this.Form, "InternalAuditUrl", newFileListData[0]);
      }
      // 内审附件数量
      this.$set(this.Form, "CountInternalAudit", newFileListData.length);
    },
    // 外审附件删除
    handleRemoveExternalAudit(fileList, newFileListData) {
      this.fileDataExternalAudit = fileList;
      // 外审附件url
      if (newFileListData.length > 1) {
        this.$set(this.Form, "ExternalAuditUrl", newFileListData.join(","));
      } else {
        this.$set(this.Form, "ExternalAuditUrl", newFileListData[0]);
      }
      // 外审附件数量
      this.$set(this.Form, "CountExternalAudit", newFileListData.length);
    },

    // 设备材料审核附件删除
    handleRemoveEquipmentAndMater(fileList, newFileListData) {
      console.log("设备材料审核删除成功==", fileList, newFileListData);
      this.fileDataEquipmentAndMater = fileList;
      // let arr = [];
      // this.fileDataEquipmentAndMater.forEach((item) => {
      //   arr.push(item.url);
      // });
      // 设备材料附件url
      if (newFileListData.length > 1) {
        this.$set(
          this.Form,
          "EquipmentAndMaterFile",
          newFileListData.join(",")
        );
      } else {
        this.$set(this.Form, "EquipmentAndMaterFile", newFileListData[0]);
      }

      // 设备材料审核附件个数赋值
      this.$set(this.Form, "CountEAMR", newFileListData.length);
    },

    // 业态级别下拉选择
    ShopFormatClassChange(e) {
      // 判断是否有选择业态级别  如果有选择了。单独清空校验规则  否则红字一直在
      // this.$refs.basisRuleForm.validateField("ShopFormatClass", (valid) => {
      //   //valid返回的是规则集中写的错误提示信息，如果满足条件，返回的就是空
      //   if (valid) {
      //     return this.$refs["basisRuleForm"].clearValidate("ShopFormatClass");
      //   }
      // });

      if (e) {
        console.log("有值", e, this.ShopFormatClassOptions);
        // 如果有选中业态级别  同时获取业态名称
        // this.ShopFormatNameDisabled = false;
      }
      // 业态名称下拉数据初始化
      // if (this.ruleForm1.ShopFormatName) {
      //   this.ruleForm1.ShopFormatName = "";
      // }
      let optionsData = this.ShopFormatClassOptions;
      if (this.ShopFormatClassOptions.length !== 0) {
        var newShopFormClassData = optionsData.filter(function (
          item,

        ) {
          return item.label === e;
        });
      }
      console.log("获取处理后的", newShopFormClassData);
      // 赋值业态级别下拉数据参数  以获取对应级别下的下拉数据
      this.parentObj.ShopFormatClassQueryParams.Keyword =
        newShopFormClassData.length == 0 ? "" : newShopFormClassData[0].value;
      console.log(
        "获取下拉业态级别的参数1111111",
        this.parentObj.ShopFormatClassQueryParamsz
      );
      // // 获取对应业态级别下的业态名称下拉数据
      // this.getShopNameListData();
      // // 业态名称后 初始化业态级别下拉数据的参数  避免下次还是原来选择的参数
      // this.ShopFormatClassQueryParams.Keyword="FormatClass"

      this.$emit("ShopFormatClassChange", e);
    },

    // 保存
    async handlerSubmitSave(e) {
      if (e === "EngineeringBaseInfo") {
        if (this.StatusOptionsActive != 0) {
          if (!this.StatusOptions[this.StatusOptionsActive - 1].ChangeTime) {
            this.$message.warning('工程状态请选择日期')
            return
          }
        }

        this.loadingInfo = true
        //基本信息
        //获取工程状态

        let arr = this.StatusOptions.slice(0, this.StatusOptionsActive).map(item => {
          return {
            "Status": item.DicName,
            "ChangeTime": item.ChangeTime
          }
        })

        let obj = {
          CreateTime: this.$store.state.shopInfo.EngineeringBaseInfo_CreateTime
            ? this.$store.state.shopInfo.EngineeringBaseInfo_CreateTime
            : undefined,
          CreatorId: this.$store.state.shopInfo.EngineeringBaseInfo_CreatorId
            ? this.$store.state.shopInfo.EngineeringBaseInfo_CreatorId
            : undefined,
          Id: this.$store.state.shopInfo.EngineeringBaseInfo_Id
            ? this.$store.state.shopInfo.EngineeringBaseInfo_Id
            : undefined,
          ModuleType: "EngineeringBaseInfo",
          ModuleJson: JSON.stringify({
            EntryTime: this.Form.EntryTime,
            ConstructionProgress: this.Form.ConstructionProgress,
            IsOpeningBusiness: this.Form.IsOpeningBusiness,
            RemarkEngineeringInfo: this.Form.RemarkEngineeringInfo,
            EngineeringStatus: arr.length > 0 ? arr[arr.length - 1].Status : '其他',
          }),
          ShopId: this.$store.state.shopInfo.Id || this.Form.Id,
          Status: 1,
        };
        // 只有除基础信息以外的模块调用模块接口请求函数

        try {
          await this.getSubEngineeringStatus(arr)
          this.getShop_ExtensionInfoRequest(obj, e);
        } catch (error) {
          this.loadingInfo = false
        }
      } else if (e === "DrawingApproval") {
        //图纸报审
        let obj = {
          CreateTime: this.$store.state.shopInfo.DrawingApproval_CreateTime
            ? this.$store.state.shopInfo.DrawingApproval_CreateTime
            : undefined,
          CreatorId: this.$store.state.shopInfo.DrawingApproval_CreatorId
            ? this.$store.state.shopInfo.DrawingApproval_CreatorId
            : undefined,
          Id: this.$store.state.shopInfo.DrawingApproval_Id
            ? this.$store.state.shopInfo.DrawingApproval_Id
            : undefined,
          ModuleType: "DrawingApproval",
          ModuleJson: JSON.stringify({
            // 内审
            InternalAudit: this.Form.StatusInternalAudit,
            StatusInternalAudit: this.Form.StatusInternalAudit,
            // 外审
            ExternalAudit: this.Form.StatusExternalAudit,
            StatusExternalAudit: this.Form.StatusExternalAudit,
            //内审url
            InternalAuditUrl: this.Form.InternalAuditUrl
              ? this.Form.InternalAuditUrl
              : "",
            // 内审附件个数
            CountInternalAudit: this.Form.CountInternalAudit
              ? this.Form.CountInternalAudit
              : 0,
            // 外审url
            ExternalAuditUrl: this.Form.ExternalAuditUrl
              ? this.Form.ExternalAuditUrl
              : "",
            //外审附件个数
            CountExternalAudit: this.Form.CountExternalAudit
              ? this.Form.CountExternalAudit
              : 0,
          }),
          ShopId: this.$store.state.shopInfo.Id || this.Form.Id,
          Status: 1,
        };
        console.log(
          "保存了DrawingApproval",
          e,
          obj,
          this.Form,
          this.$store.state.shopInfo
        );
        return
        // // 只有除基础信息以外的模块调用模块接口请求函数
        // this.getShop_ExtensionInfoRequest(obj, e);
      } else if (e === "EquipmentAndMaterialReview") {

        //设备材料审核
        let obj = {
          CreateTime: this.$store.state.shopInfo
            .EquipmentAndMaterialReview_CreateTime
            ? this.$store.state.shopInfo.EquipmentAndMaterialReview_CreateTime
            : undefined,
          CreatorId: this.$store.state.shopInfo
            .EquipmentAndMaterialReview_CreatorId
            ? this.$store.state.shopInfo.EquipmentAndMaterialReview_CreatorId
            : undefined,
          Id: this.$store.state.shopInfo.EquipmentAndMaterialReview_Id
            ? this.$store.state.shopInfo.EquipmentAndMaterialReview_Id
            : undefined,
          ModuleType: "EquipmentAndMaterialReview",
          ModuleJson: JSON.stringify({
            // 设备材料审核福建个数
            CountEAMR: this.Form.CountEAMR,
            // 设备材料审核状态
            StatusEAMR: this.Form.StatusEAMR,
            //url
            EquipmentAndMaterFile: this.Form.EquipmentAndMaterFile
              ? this.Form.EquipmentAndMaterFile
              : "",
          }),
          ShopId: this.$store.state.shopInfo.Id || this.Form.Id,
          Status: 1,
        };
        console.log(
          "保存了设备材料审核",
          e,
          obj,
          this.Form,
          this.$store.state.shopInfo
        );
        return
        // // 只有除基础信息以外的模块调用模块接口请求函数
        // this.getShop_ExtensionInfoRequest(obj, e);
      }
    },

    SaveOperationLogs(data) {
      this.$http
        .post("/Shop_Manage/Shop_ExtensionInfo/SaveOperationLogs", { ...data })
        .then(res => {
          if (res.Success) {
            console.log("保存日志成功")
            this.parentObj.refreshLog()
          } else {
            console.log("保存日志失败")
          }
        })
    },
    // 扩展模块信息提交请求
    getShop_ExtensionInfoRequest(obj, type) {
      var that = this;
      console.log(
        "传递过来的整体参数传递过来的整体参数是传递过来的整体参数是是",
        obj,
        this.$store.state.shopInfo
      );
      var shopInfo = this.$store.state.shopInfo;
      // 保存后按钮禁用
      if (type === "EngineeringBaseInfo") {
        // 基础信息
        this.EngineeringBaseInfoDisabled = true;
      } else if (type === "DrawingApproval") {
        // 图纸报审
        this.DrawingApprovalDisabled = true;
      } else {
        // 设备材料审核
        this.EquipmentAndMaterialReviewDisabled = true;
      }
      // const loading = this.$loading({
      //   lock: true,
      //   text: "保存中...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });

      that.$http
        .post("/Shop_Manage/Shop_ExtensionInfo/SaveData", obj)
        .then(async (res) => {
          this.loadingInfo = false
          if (res.Success) {

            shopInfo[obj.ModuleType + "_CreateTime"] = res.Data.CreateTime;
            shopInfo[obj.ModuleType + "_CreatorId"] = res.Data.CreatorId;
            shopInfo[obj.ModuleType + "_Id"] = res.Data.Id;
            this.SaveOperationLogs(res.Data)
            console.log(
              "获取最终合并的参数对象==================================",
              shopInfo
            );
            // 重新更新vux里的商铺信息
            this.$store.commit("getShopInfo", shopInfo);
            // this.parentObj.tableClickRefresh()
            // setTimeout(() => {
            //   loading.close();

            // }, 500);
            that.$message.success("已保存");
            if (type === "EngineeringBaseInfo") {
              this.editCancel('EngineeringBaseInfoDisabled')
            }
            // 关闭弹出层
            // this.$emit("closeDialog", true);
          } else {
            that.$message.error(res.Msg);
          }
        });
    },
    getDataListBySingleCondition() {
      this.$http.post('/Shop_Manage/Shop_ApprovalUnit/GetApprovalUnitAndApprovalCount', {

        "SortField": "CreateTime",
        "SortType": "desc",
        "Search": {
          "Condition": "ShopId",
          "Keyword": this.parentObj.childrenRow.Id
        }
      }).then(res => {
        if (res.Success) {
          if (res.Data) {
            res.Data.forEach(item => {
              this.$set(item, 'disabled', true)
            })
            this.SingleConditionList = res.Data || []
          }



        } else {
          this.$message.error(res.Msg)
        }
      })
    },
    AuditFormSubmitSave(item) {
      if (!item.ReportingUnit) {
        this.$message.warning('请输入报审单位')
        return
      }
      if (!item.ContactPhone) {
        this.$message.warning('请输入报审手机号')
        return
      }
      if (!(/^1[3-9]\d{9}$/.test(item.ContactPhone))) {
        this.$message.warning('请输入正确的报审手机号')
        return
      }
      this.$http.post('/Shop_Manage/Shop_ApprovalUnit/SaveData', {
        ShopId: this.parentObj.childrenRow.Id,
        ...item
      }).then(res => {
        if (res.Success) {
          this.$message.success('保存成功')
          this.AuditForm.ContactPhone = ''
          this.AuditForm.ReportingUnit = ''
          this.getDataListBySingleCondition()
        } else {
          this.$message.error(res.Msg)
        }
      })
    },
    AuditFormDelete(item) {
      this.$confirm('确定删除该条信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/Shop_Manage/Shop_ApprovalUnit/DeleteData', [item.Id]).then(res => {
          if (res.Success) {
            this.$message.success('删除成功')
            this.getDataListBySingleCondition()
          } else {
            this.$message.error(res.Msg)
          }
        })
      })
    },
  },
  watch: {
    EngineeringStatusOptions: {
      deep: true,
      handler(val) {
        if (val.length > 0) {
          this.StatusOptions = JSON.parse(JSON.stringify([...val]))
          this.getStatusOptions()
        }

        this.getDataListByMoreCondition(1)
        this.getDataListByMoreCondition(2)
        //获取报审单位
        this.getDataListBySingleCondition()
        console.log("工程状态选项变化", val)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.EngineeringBaseInfo {

  // 覆盖element input输入框的宽高
  ::v-deep .el-form-item {
    margin-bottom: 5px !important;

    .el-form-item__content {
      .el-input {

        // 可以隐藏上下加减号
        // input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
        //   // -webkit-appearance: none !important;
        // 	margin-left: 20px;
        // }
        .el-input__inner {
          height: 28px !important;
          line-height: 28px !important;
        }

        .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .edit:hover {
    color: #aaa7a7 !important;
    cursor: pointer;
  }

  .save {
    color: red;
  }

  .save:hover {
    color: rgb(253, 127, 127);
    cursor: pointer;
  }

  .el-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 20px;

    ::v-deep .el-button {
      padding: 8px 20px;
    }
  }
}

::v-deep .addAuditForm {
  .el-form-item__label {
    font-size: 18px;
  }
}

::v-deep .ViewAuditForm {
  .el-form-item__label {
    font-size: 18px;
  }
}

.auditMaterialsInfoList:hover {
  cursor: pointer;
  background: #f3eaea;
}

::v-deep.ViewAuditForm {
  .el-upload {
    display: none;
  }
}

::v-deep .el-steps {
  font-weight: bold;
}

::v-deep .el-step__head.is-finish {
  color: #D1100B;
  border-color: #D1100B;
}

::v-deep .el-steps .is-finish .el-step__line {
  background: #D1100B;
}

::v-deep .el-steps .is-process {
  color: #D1100B;
  border-color: #D1100B;
}

.ellipsis {
  overflow: hidden; //超出隐藏
  word-wrap: break-word;
  word-break: normal;
  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

  -webkit-line-clamp: 2; //内容限制的行数
}

.ellipsis1 {
  overflow: hidden; //超出隐藏
  word-wrap: break-word;
  word-break: normal;
  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

  -webkit-line-clamp: 1; //内容限制的行数
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 针对Firefox的样式 */
input[type="number"] {
  -moz-appearance: textfield;
}

::v-deep .demo-AuditForm {
  .el-form-item__label {
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }
}

::v-deep .SingleConditionList {
  .el-divider--horizontal {
    margin: 10px 0;
  }
}

::v-deep .Audit-el-card {
  .el-card__header {
    padding: 5px 20px;
    font-size: 18px;
    font-weight: bold;
  }


}

::v-deep .demo-AuditForm {
  .el-form-item {
    margin: 0 !important;
  }
}

::v-deep .Audit-Dialog {
  margin-top: 5vh !important;

  .el-dialog__body {
    padding: 5px 10px !important;
  }
}

::v-deep .ViewAudit {
  margin-top: 5vh !important;

  .el-dialog__body {
    padding: 0px 20px;
  }
}

::v-deep .viewList {
  .el-descriptions-item__label {
    width: 40%;
    text-align: right;
    font-size: 16px !important;
    font-weight: bold !important;
  }
}
</style>
