<!-- 招商到处表格的最里层子组件 -->
<template>
  <!--  :fixed="col.fixed"v-if="col.show"
     -->
  <el-table-column
      :prop="col.DicKey"
      :label="col.DicName"
      :align="alignType"
      :width="col.width"
  >
    <!-- tree数据源绑定col使用以下方法 -->
    <!-- v-if="col.show || col.children.filter((item) => item.show).length > 0" -->
    <template v-for="(item, index) of col.children">
      <column-item
          v-if="item.children && item.DicKey !='FireInfo'"
          :key="index"
          :col="item"
      ></column-item>
      <el-table-column
          v-if="!item.children && item.show"
          :key="index"
          :label="item.DicName"
          :prop="item.DicKey"
          :align="alignType"
          :min-width="jsonParse(item.ConfigInfo) || item.width"
      >
        <template slot-scope="scope">
          <div v-if="item.DicKey === 'IsOnGround'">
            <span>{{ scope.row.IsOnGround == 1 ? "地上" : "地下" }}</span>
          </div>

          <div v-else-if="item.DicKey === 'BuildingId'">
            <span>{{ scope.row.BuildingCode }}</span>
          </div>


          <div v-else-if="item.DicKey === 'StatusEAMR'">
            <span>{{ scope.row.StatusEAMR == 0 ? "已通过" : "未通过" }}</span>
          </div>
          <div v-else-if="item.DicKey === 'StatusExternalAudit'">
              <span>{{
                  scope.row.StatusExternalAudit == 0 ? "已通过" : "未通过"
                }}</span>
          </div>

          <div v-else-if="item.DicKey === 'StatusInternalAudit'">
              <span>{{
                  scope.row.StatusInternalAudit == 0 ? "已通过" : "未通过"
                }}</span>
          </div>
          <div v-else>
            <span v-html="getData(scope.row[item.DicKey])"></span>
          </div>
        </template>
      </el-table-column>
    </template>

    <!-- 图片预览组件 -->
    <img-view
        :show="imageViewerVisible"
        :url-list="imageViewerList"
        @close="imageViewerVisible = false"
    >
    </img-view>
  </el-table-column>
</template>

<script>
// 引入图片预览组件
import ImgView from "@/components/imgView.vue";

export default {
  name: "ColumnItem",
  props: {
    col: {
      type: Object,
    },
    //判断单元格文字是居中还是左对齐显示
    alignType: {
      type: String,
      default: "center", //默认居中
    },
    // 是否显示表格区域快
    isShow: {
      type: Number,
      default: 1,
    },
    // column: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // },
  },
  components: {
    ImgView,
  },
  created() {
  },
  data() {
    return {
      // 预览的图片数据
      imageViewerList: [],
      // 是否展示图片预览组件
      imageViewerVisible: false,
    };
  },
  methods: {
    jsonParse(json) {
      if (json) {
        return JSON.parse(json).titleWidth;
      } else {
        return null;
      }
    },
    hiddenColumn(key, col) {
      this.$emit("hiddenColumn", key);
    },
    // 图片预览
    imgViewChange(row) {
      // 将展示的图片到数组
      this.imageViewerList[0] = row.jietu;
      // 显示预览组件
      this.imageViewerVisible = true;
    },
    getData(data) {
      if (data === true) {
        return '是'
      } else if (data === false) {
        return '否'
      }
      if (Array.isArray(data)) {
        return `${data.join('<br/>')}`
      } else {
        return data
      }
    }
  },
};
</script>
<style lang="scss">
.parentHeader {
  // display: flex;
  // align-items: center;
  // width: 100%;
  // justify-content: center;
  line-height: 40px;

  .pre {
    margin-top: 1px;
  }

  .btn {
    float: right;
    margin-left: 10px;

    i {
      font-weight: bold;
    }
  }
}
</style>
