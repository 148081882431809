<template style="padding: 0">
  <!-- :style="{ width: windowWidth + 'px' }" -->
  <div style="overflow: inherit" class="tableItem"
    :style="{ width: windowWidth + 'px', height: windowHeightAll + 'px' }">
    <!-- <div style="display:flex;justify-content: flex-end;"><span class="right" style="padding:0 5px;cursor: pointer;" @click="backLogChange">➔</span></div> -->
    <div style="display: flex; justify-content: space-between">
      <!-- 左侧表格 flex: 8;width:80%;-->
      <div style="" :style="{ width: windowWidthTable + 'px', height: windowHeight + 'px' }">
        <div class="grid-content bg-purple box" :style="{ height: windowHeight + 'px' }">
          <!-- row-class-name="tableRow" cell-class-name="tableCell"-->
          <!--自定义加载提示loading-->
          <div class="loading_gif" v-if="loading"
            :style="{ width: windowWidthTable + 'px', height: windowHeight - 35 + 'px' }">
            <img style="width: 40px; height: 40px" src="../../../assets/shuaxin-loading.gif" alt="" />
          </div>
          <el-table ref="table" :data="tableData" :height="windowHeight" class="tablelist" show-overflow-tooltip
            :cell-class-name="tableCell" :header-cell-class-name="headerCell" @cell-click="cellClick"
            @selection-change="tableSelectionChange" @select-all="tableSelectAllChange"
            :row-class-name="tableRowClassName">
            <el-table-column type="selection" width="40" align="center" fixed="left"></el-table-column>
            <!-- 商铺编号 -->
            <el-table-column prop="ShopCode" label="" align="left" width="110" fixed="left" show-overflow-tooltip>
              <template slot="header" slot-scope="scope">
                <div style="display: flex; align-items: center">
                  商铺编号
                  <!-- <div style="margin-left: 5px">
                    <span
                      :class="{ active: sortIndex === 'top' }"
                      class="shopCodeTop"
                      style="margin-bottom: 3px"
                      @click.stop="sortClickChange('top', scope.row)"
                    ></span>
                    <span
                      :class="{ active: sortIndex === 'bottom' }"
                      class="shopCodeBottom"
                      @click.stop="sortClickChange('bottom', scope.row)"
                    ></span>
                  </div> -->
                  <img @click.stop="handlerAddChange(scope.row)" style="width: 17%; cursor: pointer; margin-left: 10px"
                    src="../../../assets/add.png" alt="" />
                </div>
              </template>

            </el-table-column>
            <el-table-column prop="ShopName" label="" align="left" width="100" fixed="left" show-overflow-tooltip>
              <template slot="header" slot-scope="scope">
                <div style="text-align: center">
                  <span style="font-weight: bold">商铺名称</span>
                </div>
              </template>

            </el-table-column>
            <!--工程编号 -->
            <el-table-column prop="ProjectCode" label="工程编号" align="center" width="80" show-overflow-tooltip
              fixed="left"></el-table-column>

            <!--            所在楼层-->
            <el-table-column prop="BuildingCode" label="楼栋" align="center" width="80" show-overflow-tooltip>

            </el-table-column>
            <el-table-column prop="Storey" label="楼层" align="center" width="50">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="resetStorey(scope.row)" placement="top"
                  :open-delay="300">
                  <div style="font-size: 13px"> {{
                    resetStorey(scope.row)
                  }}
                  </div>
                </el-tooltip>

              </template>
            </el-table-column>


            <!--            基础信息模块  fixed="left"-->
            <el-table-column label="基础信息" width="330" align="center">
              <div>
                <el-table-column prop="ellipsis1" label="..." align="center" width="35">
                  <template slot="header" slot-scope="scope">
                    <div style="cursor: pointer" @click.stop="tableListMoreChange(scope.row, 'ellipsis1')">
                      <el-tooltip class="item" effect="dark" content="点击下面行内...查看更多" placement="top-start"
                        :open-delay="450">
                        <div @click.stop="tableListMoreChange(scope.row, 'ellipsis1')">...</div>
                      </el-tooltip>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div style="cursor: pointer" @click.stop="tableListMoreChange(scope.row, 'ellipsis1')">
                      <el-tooltip class="item" effect="dark" content="点击查看更多" placement="top-start" :open-delay="450">
                        <div @click.stop="tableListMoreChange(scope.row, 'ellipsis1')">...</div>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="ShopFormatName" label="业态" header-align="center" align="right" width="80"
                  show-overflow-tooltip>
                  <template slot="header" slot-scope="scope">
                    <div style="text-align: center">
                      <span style="font-weight: normal">业态</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="ShopArea" label="" align="right" width="100">
                  <template slot="header" slot-scope="scope">
                    <div style="text-align: center">
                      <span style="font-weight: normal">商铺面积</span>
                    </div>
                  </template>
                  <template slot-scope="scope">

                    <div style="text-align: right;font-size: 13px" class="ellipsis1"
                      :title="scope.row.ShopArea ? scope.row.ShopArea + 'm²' : ''">
                      <span style="font-weight: normal">{{ scope.row.ShopArea }}</span>
                      <span v-if="scope.row.ShopArea"
                        style="font-weight: normal; color: #c6c6c6;font-size: 12px">m²</span>
                    </div>


                  </template>
                </el-table-column>
              </div>
            </el-table-column>

            <!--            设计信息模块-->
            <el-table-column label="设计信息" align="center">
              <el-table-column prop="AirConditioningCondition.TotalCoolingCapacity" label="" align="left">
                <template slot="header" slot-scope="scope">
                  <div style="text-align: center">
                    <span style="font-weight: normal">暖通专业</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div style="text-align: right" class="ellipsis1"
                    :title="scope.row.AirConditioningCondition?.TotalCoolingCapacity ? scope.row.AirConditioningCondition?.TotalCoolingCapacity + 'kW' : ''">
                    <span style="font-weight: normal;padding-right: 2px;font-size: 13px;">{{
                      scope.row.AirConditioningCondition?.TotalCoolingCapacity
                    }}</span>
                    <span v-if="scope.row.AirConditioningCondition?.TotalCoolingCapacity"
                      style="font-weight: normal; color: #c6c6c6;font-size: 12px">kW</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="PowerConsumption" label="" align="left">
                <template slot="header" slot-scope="scope">
                  <div style="text-align: center">
                    <span style="font-weight: normal">电气专业</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div style="text-align: right;" class="ellipsis1"
                    :title="scope.row.PowerConsumption ? scope.row.PowerConsumption + 'kW' : ''">
                    <span style="font-weight: normal;padding-right: 2px;font-size: 13px">{{
                      scope.row.PowerConsumption
                    }}</span>
                    <span v-if="scope.row.PowerConsumption"
                      style="font-weight: normal; color: #c6c6c6;font-size: 12px">kW</span>
                  </div>
                </template>
              </el-table-column>
              <!--              三个点区域块-->
              <el-table-column prop="ellipsis2" label="..." align="center" width="35">
                <template slot="header" slot-scope="scope">
                  <div style="cursor: pointer" @click.stop="tableListMoreChange(scope.row, 'ellipsis2')">
                    <el-tooltip class="item" effect="dark" content="点击下面行内...查看更多" placement="top-start"
                      :open-delay="450">
                      <div @click.stop="tableListMoreChange(scope.row, 'ellipsis2')">...</div>
                    </el-tooltip>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click.stop="tableListMoreChange(scope.row, 'ellipsis2')">
                    <el-tooltip class="item" effect="dark" content="点击查看更多" placement="top-start" :open-delay="450">
                      <div @click.stop="tableListMoreChange(scope.row, 'ellipsis2')">...</div>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>

            <!--            工程信息模块-->
            <el-table-column label="工程信息" align="center">
              <el-table-column prop="EngineeringStatus" label="" width="120" align="center">
                <!--                工程状态区块-->
                <template slot="header" slot-scope="scope">
                  <span style="font-weight: normal">工程状态</span>
                </template>

                <template slot-scope="scope">

                  <el-tooltip class="item" effect="dark"
                    :content="(scope.row.Shop_EngineeringStatus ? scope.row.Shop_EngineeringStatus : '') + (scope.row.Shop_EngineeringStatus_ChangeTime ? ' ' + scope.row.Shop_EngineeringStatus_ChangeTime.substring(0, 10) : '')"
                    placement="right" :open-delay="450">
                    <el-button type="text" @click.stop="openStatusDialog(scope.row, 1)" style="color:#409EFF">
                      {{ scope.row.Shop_EngineeringStatus }} <i class="el-icon-edit-outline"></i>
                    </el-button>
                  </el-tooltip>
                  <!-- <el-select size="mini" style="margin: 5px 0 5px 0;font-size: 13px"
                    v-model="scope.row.EngineeringStatus" placeholder="请选择" :disabled="scope.row.Status == 3"
                    @change="EngineeringStatusChange(scope.row)">
                    <el-option style="font-size: 13px" v-for="item in EngineeringStatusOptions" :key="item.value"
                      :label="item.label" :value="item.label">
                    </el-option>
                  </el-select> -->
                </template>
              </el-table-column>
              <!--              备注-->
              <el-table-column prop="RemarkEngineeringInfo" label="" align="right">
                <template slot="header" slot-scope="scope">
                  <div style="text-align: center">
                    <span style="font-weight: normal">备注</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div style="cursor: pointer; text-align: left;font-size: 12px"
                    @click.stop="tableListMoreChange(scope.row)">
                    <el-tooltip style="font-size: 12px" effect="dark" :content="scope.row.RemarkEngineeringInfo"
                      placement="top-start" :open-delay="450">
                      <div v-if="scope.row.RemarkEngineeringInfo">
                        {{
                          scope.row.RemarkEngineeringInfo.length > 5 ? scope.row.RemarkEngineeringInfo.slice(0, 5) +
                            "..." : scope.row.RemarkEngineeringInfo
                        }}
                      </div>
                      <div v-else></div>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <!--            招商信息模块-->
            <el-table-column label="招商信息" align="center">
              <el-table-column prop="ContractStatus" label="" width="120" align="center">
                <template slot="header" slot-scope="scope">
                  <span style="font-weight: normal">合约状态</span>
                </template>
                <template slot-scope="scope">

                  <el-tooltip class="item" effect="dark"
                    :content="(scope.row.Shop_ContractStatus ? scope.row.Shop_ContractStatus : '') + (scope.row.Shop_ContractStatus_ChangeTime ? ' ' + scope.row.Shop_ContractStatus_ChangeTime.substring(0, 10) : '')"
                    placement="right" :open-delay="450">
                    <el-button type="text" @click.stop="openStatusDialog(scope.row, 2)" style="color:#409EFF">
                      {{ scope.row.Shop_ContractStatus }} <i class="el-icon-edit-outline"></i>
                    </el-button>
                  </el-tooltip>

                  <!-- <el-select size="mini" style="margin: 5px 0 5px 0;font-size: 13px" v-model="scope.row.ContractStatus"
                    placeholder="请选择" :disabled="scope.row.Status == 3" @change="ContractStatusChange(scope.row)">
                    <el-option style="font-size: 13px" v-for="item in ContractOptions" :key="item.value"
                      :label="item.label" :value="item.label">
                    </el-option>
                  </el-select> -->
                </template>
              </el-table-column>
              <el-table-column prop="ContractStartTime" label="" width="100" align="center">
                <template slot="header" slot-scope="scope">
                  <span style="font-weight: normal;">签约时间</span>
                </template>
                <template slot-scope="scope">
                  <div style="text-align: center;font-size: 13px">
                    <span style="font-weight: normal">{{ scope.row.ContractStartTime }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <!-- 操作列 -->
            <el-table-column prop="ContractStartTime" label="操作" width="220" align="center">
              <template slot-scope="scope">
                <div style="font-size: 13px;font-weight: bold">
                  <span :class="[scope.row.ChangeVersion === 'V1.0' ? 'disabled' : 'hover',]"
                    style="font-size:13px;color: #409EFF;margin-right: 10px"
                    @click.stop="tableDetailClick(scope.row)">{{
                      scope.row.ChangeVersion ?
                        scope.row.ChangeVersion.slice(0, 4) : ""
                    }}</span>
                  <span class="hover" style="font-size:13px;color: #1E1E1E; margin-right: 10px"
                    @click.stop="handleDetail(scope.row)">详情</span>
                  <span class="hover" v-if="scope.row.Status == 2"
                    style="font-size:13px;color: #E6A23C; margin-right: 10px"
                    @click.stop="submitButtonUpdate(scope.row)">变更</span>
                  <span v-else class="hover" @click.stop="submitButton(scope.row)"
                    style="font-size:13px;color: #7FCC59; margin-right: 10px">提交</span>
                  <span :class="[scope.row.ChangeVersion === 'V1.0' ? 'disabled' : 'hover',]"
                    style="font-size:13px;color: #F56C6C;margin-right: 10px"
                    @click.stop="submitButtonBatchVersion(scope.row)">撤销版本</span>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- 底部汇总统计 -->

          <div class="bottomTotal" style="">
            <div style="display: flex; justify-content: space-between">
              <div class="grid-content bg-purple" style="">商铺总面积(㎡)</div>
              <div class="grid-content bg-purple-light" style="">
                {{ totalObj.ShopArea ? totalObj.ShopArea.toLocaleString() : 0 }}
              </div>
              <div class="grid-content bg-purple" style="">商铺总数量(个)</div>
              <div class="grid-content bg-purple-light" style="">
                {{
                  totalObj.ShopCount ? totalObj.ShopCount.toLocaleString() : 0
                }}
              </div>
              <div class="grid-content bg-purple" style="">总电量(kW)</div>
              <div class="grid-content bg-purple-light" style="">
                {{
                  totalObj.ShopPowerConsumption
                    ? totalObj.ShopPowerConsumption.toLocaleString()
                    : 0
                }}
              </div>
              <div class="grid-content bg-purple" style="">
                餐饮商铺数量(个)
              </div>
              <div class="grid-content bg-purple-light" style="">
                {{
                  totalObj.ShopCateringTypeCount
                    ? totalObj.ShopCateringTypeCount.toLocaleString()
                    : 0
                }}
              </div>
              <div class="grid-content bg-purple" style="">总排烟量(m³/h)</div>
              <div class="grid-content bg-purple-light" style="border-right: 2px solid #a8a7a7">
                {{
                  totalObj.ShopKitchenExhaustFumes
                    ? totalObj.ShopKitchenExhaustFumes.toLocaleString()
                    : 0
                }}
              </div>
            </div>
          </div>
        </div>
        <!-- 底部分页 -->
        <div style="display: flex; justify-content: flex-end">
          <pagination :total="pagination.total" :page.sync="pagination.current" :limit.sync="pagination.pageSize"
            @pagination="handlePageChange" />
        </div>
      </div>

      <!-- 右侧echarts图表width: 20%; flex: 2;	:style="{ height: windowHeight + addWindowHeight + 'px' }" -->
      <div class="grid-content bg-purple-light" style="overflow: auto;" :style="{ height: windowHeight + 'px' }">
        <div style="height: 100%; width: 100%">
          <!--          餐饮比例-->
          <div class="echarts-box" style="width: 100%;margin-bottom: 20px">
            <EchartsIndex :option="ecahrtsOptions" :width="200" :height="200" />
          </div>

          <!--          签约比例-->
          <div class="echarts-box" style="width: 100%;margin-bottom: 20px">
            <EchartsIndex :option="ecahrtsOptions1" :width="200" :height="200" />
          </div>

          <!--          工程启动比例-->
          <div class="echarts-box" style="width: 100%">
            <EchartsIndex :option="ecahrtsOptions2" :width="200" :height="200" />
          </div>
        </div>
      </div>
    </div>

    <!-- 编辑 -->
    <EditDetail ref="EditDetail" :parentObj="{ ...this }" @handleCloseResetTableChange="handleCloseResetTableChange" />
    <!-- 新建 -->
    <AddDetail ref="AddDetail" :parentObj="{ ...this }" @handleCloseResetTableChange="handleCloseResetTableChange" />

    <!-- 变更原因弹窗 -->
    <el-dialog title="变更原因" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div>
        <div style="margin-bottom: 20px">
          <el-select class="marginRight" v-model="ShopChangeReason" placeholder="选择具体原因" size="mini"
            @change="reasonSelectChange">
            <el-option v-for="item in shopReasonOptions" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
          </el-select>
        </div>
        <el-input rows="10" type="textarea" v-model="SupplementReason" placeholder="请输入变更原因" size="mini"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitButtonUnlockChange(false)" v-loading="VersionLoading"
          size="small">非版本变更</el-button>
        <el-button type="primary" @click="submitButtonUnlockChange(true)" v-loading="VersionLoading"
          size="small">版本变更</el-button>
      </span>
    </el-dialog>

    <StepsListDialog ref="StepsListDialog" title="工程状态变更" @submit="handleEngineeringStatusSubmit"></StepsListDialog>
  </div>
</template>
<script>
// 引入echarts图表组件
import EchartsIndex from "@/components/Echarts/index.vue";
// 引入工具数据
import { ContractOptions } from "./mock";
// 引入编辑详情组件
import EditDetail from "./EditDetail.vue";
// 引入新建详情组件
import AddDetail from "./AddDetail.vue";

import StepsListDialog from "@/components/StepsListDialog/StepsListDialog.vue";

export default {
  name: "TableItem",
  components: {
    EditDetail,
    AddDetail,
    EchartsIndex,
    StepsListDialog
  },
  props: {
    //树型结构表头数据
    col: {
      type: Array,
      default: () => [],
    },
    // 是否显示表格区域快
    isShow: {
      type: Number,
      default: 1,
    },
    //是否在表格下方显示合计
    isShowSum: {
      type: Boolean,
      default: false,
    },
    // 是否可以编辑
    editDisabled: {
      type: Boolean,
      default: false,
    },
    //判断单元格文字是居中还是左对齐显示,默认居中
    alignType: {
      type: String,
      default: "center",
    },
    //设置表格高度,固定表头
    // height: {
    //   type: String,
    //   default: null, //默认不固定表头
    // },
    //判断是否显示多选框
    isShowBox: {
      type: Boolean,
      default: true, //默认不展示
    },
    // 项目id
    ProjectId: {
      type: String,
    },
    // 传递过来的项目。应用。任务 id对象
    taskObj: {
      type: Object,
    },
  },
  data() {
    return {
      VersionLoading: false,
      //工程状态弹窗信息
      EngineeringRowData: {},
      // 工程状态变更弹窗
      EngineeringStatusVisible: false,
      EngineeringStatusOptionsActive: 0,
      EngineeringInfo: {
        time: "",
        status: "",
      },
      // 选择的当前行数据
      rowData: {},
      // 输入变更原因
      SupplementReason: "",
      // 下拉变更原因
      ShopChangeReason: "",
      // 下拉原因数据
      shopReasonOptions: [

      ],
      // 变更原因弹窗
      dialogVisible: false,
      taskTableItemObj: {},
      // 底部汇总
      totalObj: {
        // 商铺总面积
        ShopArea: 0,
        // 总电量
        ShopPowerConsumption: 0,
        // 餐饮商铺总个数
        ShopCateringTypeCount: 0,
        // 总排烟量
        ShopKitchenExhaustFumes: 0,
        // 商铺总个数
        ShopCount: 0,
      },
      // 商铺编号排序三角号默认
      sortIndex: "top",
      //任务id
      TaskId: "",
      // 日志显示隐藏
      isLog: false,
      // 排序
      isSort: false,
      // 高度微调
      addWindowHeight: 40,
      // 工程状态下拉数据
      EngineeringStatusOptions: [],
      // 合约状态下拉数据
      ContractOptions,
      // 工程状态绑定的数据
      // 是否暂时无数据的时候显示表格
      isTable: false,
      isInfiniteScroll: true,
      loading: false,
      tableData: [],
      // 浏览器当前可视窗口高度
      windowHeight: 0,
      // 浏览器当前可视窗口宽度
      windowWidth: 0,
      // 底部统计的整体视窗口宽度
      windowWidthTotal: 0,
      // 左侧表格的整体视窗口宽度
      windowWidthTable: 0,
      windowHeightAll: 0,
      allParent: {
        ProjectCode: "Shop_BaseInfo",
        InvestmentCode: "Shop_BaseInfo",
        ShopCode: "Shop_BaseInfo",
        ShopName: "Shop_BaseInfo",
        BuildingId: "Shop_BaseInfo",
        IsOnGround: "Shop_BaseInfo",
        Storey: "Shop_BaseInfo",
        ShopArea: "Shop_BaseInfo",
        ShopFormatClass: "Shop_BaseInfo",
        ShopFormatName: "Shop_BaseInfo",
        ShopBrand: "Shop_BaseInfo",
        ShopSeparation: "Shop_BaseInfo",
        ShopChangeReason: "Shop_BaseInfo",
        Remark: "Shop_BaseInfo",
      },

      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        pageSizeOptions: [10, 30, 50],
      },
      SortInfos: [
        {
          SortField: "ShopArea",
          SortType: "desc",
        },
      ],
      queryParam: [
        {
          Condition: "ProjectId",
          Keyword: this.taskObj.ProjectId,
        },
        {
          Condition: "TaskId",
          Keyword: this.taskObj.TaskId,
        },
        {
          Condition: "CodeOrName",
          Keyword: "",
        },
        {
          Condition: "BuildingIdList",
          Keyword: "",
        },
      ],
      // 餐饮面积比echarts图表数据
      ecahrtsOptions: {
        title: {
          text: "餐饮比例",
          subtext: "",
          left: "center",
          top: 0,
        },
        tooltip: {
          show: true,
          confine: true, //是否将 tooltip 框限制在图表的区域内。
          trigger: "item",
          textStyle: {
            align: "center",
            fontSize: 12,
          },
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          type: 'scroll',
          itemWidth: 8,
          itemHeight: 8,
          bottom: 5, //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          right: 0,
          data: [], //图例数据
          x: "right", //居中显示再下方
          y: "center", //居中显示再下方
          width: 200, //单行图例的宽度
        },
        series: [
          {
            name: "餐饮比例",
            type: "pie",
            radius: "50%",
            center: ["35%", "50%"], //饼状图位置
            // /百分比
            label: {
              normal: {
                show: true,
                fontSize: "11", //调整字体大小
                position: "inner", // 数值显示在内部
                formatter: "{d}%", //自定义显示格式(b:name, c:value, d:百分比)// 格式化数值百分比输出
              },
            },
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },

      //  已签约商铺占比
      ecahrtsOptions1: {
        title: {
          text: "签约比例",
          subtext: "",
          left: "center",
          top: 0,
        },
        tooltip: {
          show: true,
          confine: true, //是否将 tooltip 框限制在图表的区域内。
          trigger: "item",
          textStyle: {
            align: "center",
            fontSize: 12,
          },
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          type: 'scroll',
          itemWidth: 8,
          itemHeight: 8,
          bottom: 5, //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          right: 0,
          data: [], //图例数据
          x: "right", //居中显示再下方
          y: "center", //居中显示再下方
          width: 200, //单行图例的宽度
        },
        series: [
          {
            name: "签约比例",
            type: "pie",
            radius: "50%",
            center: ["35%", "50%"], //饼状图位置
            // /百分比
            label: {
              normal: {
                show: true,
                fontSize: "11", //调整字体大小
                position: "inner", // 数值显示在内部
                // formatter: "{b}: {d}%", //自定义显示格式(b:name, c:value, d:百分比)// 格式化数值百分比输出
                formatter: "{d}%", //自定义显示格式(b:name, c:value, d:百分比)// 格式化数值百分比输出
              },
            },
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },

      //  工程已启动商铺占比
      ecahrtsOptions2: {
        title: {
          text: "工程状态比例",
          subtext: "",
          left: "center",
          top: 0,
        },
        tooltip: {
          show: true,
          confine: true, //是否将 tooltip 框限制在图表的区域内。
          trigger: "item",
          textStyle: {
            align: "center",
            fontSize: 12,
          },
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          type: 'scroll',
          itemWidth: 8,
          itemHeight: 8,
          bottom: 5, //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          right: 0,
          data: [], //图例数据
          x: "right", //居中显示再下方
          y: "center", //居中显示再下方
          width: 200, //单行图例的宽度
        },
        series: [
          {
            name: "工程状态比例",
            type: "pie",
            radius: "50%",
            center: ["35%", "50%"], //饼状图位置
            // /百分比
            label: {
              normal: {
                show: true,
                fontSize: "11", //调整字体大小
                position: "inner", // 数值显示在内部
                // formatter: "{b}: {d}%", //自定义显示格式(b:name, c:value, d:百分比)// 格式化数值百分比输出
                formatter: "{d}%", //自定义显示格式(b:name, c:value, d:百分比)// 格式化数值百分比输出
              },
            },
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },

    };
  },
  created() {
    // console.log("获取col", this.col);

    let obj = {};
    this.$http
      .post("/Base_ProjectManage/Project_DictionaryMain/GetDictionaryMainParentLevelInfo?topDicKey=ShopExtensionInfo")
      .then((res) => {
        if (res.Success) {
          res.Data.forEach((item) => {
            obj[item.DicKey] = item.DicKey_Top;
          });
          this.allParent = {
            ...this.allParent,
            ...obj,
          };
        }
      });
  },
  computed: {
    height() {
      return window.innerHeight - 240;
    },
  },
  mounted() {
    // 动态获取当前浏览器窗口的高度
    this.getWindowHeight(); // 在初始化时获取窗口高度
    window.addEventListener("resize", this.getWindowHeight); // 在窗口大小改变时重新获取窗口高度

    this.taskTableItemObj = this.taskObj;
  }, //生命周期 - 挂载完成
  activated() {
  }, //若页面有keep-alive缓存，此函数会触发
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowHeight); // 组件销毁时移除resize事件监听器
  },
  methods: {
    resetStorey(row) {
      if (row?.IsOnGround == '1') {
        return row.Storey ? 'F' + row.Storey : ''
      } else if (row?.IsOnGround == '3') {
        return row.Storey ? row.Storey : ''
      } else if (row?.IsOnGround == '2') {
        return row.Storey ? 'B' + row.Storey : ''
      }
    },
    //行内更改状态请求
    handleEngineeringStatusSubmit(obj) {
      this.$http.post('/Shop_Manage/Shop_StatusRecord/SaveDataByCondition', { ...obj }).then(res => {
        if (res.Success) {
          this.$message.success('更改成功');
          this.getDataList()
        } else {
          this.$message.error(res.Msg);
        }
      })
    },
    //打开工程/合约状态变更弹窗
    openStatusDialog(row, num) {
      if (num === 1) {
        this.$refs['StepsListDialog'].openDialog(row, 'EngineeringStatus', this.EngineeringStatusOptions, '工程状态变更');
      } else {
        this.ContractOptions
        this.$refs['StepsListDialog'].openDialog(row, 'ContractStatus', this.ContractOptions, '合约状态变更');
      }
      // this.EngineeringStatusVisible = true;


      this.EngineeringRowData = row;
    },
    //
    submitEngineeringStatus() {

    },
    //隔行变色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 != 0) {
        return "warning-row";
      }
      return "";
    },
    // 表格加載 單獨設置某一表格樣式
    tableCell({ row, column, rowIndex, columnIndex }) {
      // console.log("打印表格", row, column, rowIndex, columnIndex);
      if (
        columnIndex == 6 ||
        columnIndex == 9 ||
        columnIndex == 12 ||
        columnIndex == 14 ||
        columnIndex == 16
      ) {
        //此判断为前两列
        return "sixBorder";
      }
      // if (columnIndex == 9) {
      //   //此判断为前两列
      //   return "nineBorder";
      // }
    },
    // 表格加載 單獨設置某一表格樣式
    headerCell({ row, column, rowIndex, columnIndex }) {

      if (rowIndex == 0) {
        if (
          columnIndex == 6 || columnIndex == 7 || columnIndex == 8 ||
          columnIndex == 9 || columnIndex == 10 ||
          columnIndex == 12 ||
          columnIndex == 14 ||
          columnIndex == 16

        ) {
          //此判断为前两列
          return "sixBorder";
        }
      } else if (rowIndex == 1) {
        if (
          columnIndex == 0 ||
          columnIndex == 3 ||
          columnIndex == 6 ||
          columnIndex == 8
        ) {
          //此判断为前两列
          return "sixBorder";
        }
      }
      // if (columnIndex == 9) {
      //   //此判断为前两列
      //   return "nineBorder";
      // }
    },

    tableRow({ row, index }) {
      console.log("打印表格行方法", row, index);
    },
    //自适应宽高
    myChartContainer(myChart) {
      // myChart.style.width = (window.innerWidth-100)+'px';
      // myChart.style.height = (window.innerHeight-250)+'px';
    },

    // 分页函数
    handlePageChange(data) {
      this.pagination.current = data.pageIndex;
      this.pagination.pageSize = data.pageSize;
      this.getDataList();
    },
    loadMore() {
      // 获取所有商铺列表数据
      this.getDataList();
      // 获取所有商铺底部综合统计数据
      this.GetShopSummaryInfo();
    },
    // 下拉原因选择
    reasonSelectChange(event) {
      // console.log('下拉选择', event)
    },
    // 获取底部统计
    GetShopSummaryInfo() {
      // 初始化
      // 商铺总面积
      this.totalObj.ShopArea = 0;
      // 总电量
      this.totalObj.ShopPowerConsumption = 0;
      // 餐饮商铺总个数
      this.totalObj.ShopCateringTypeCount = 0;
      // 总排烟量
      this.totalObj.ShopKitchenExhaustFumes = 0;
      // 商铺总个数
      this.totalObj.ShopCount = 0;
      this.$http
        .post("/Shop_Manage/Shop_BaseInfo/GetShopSummaryInfo", [
          {
            Condition: "ProjectId",
            Keyword: this.taskObj.ProjectId,
          },
          {
            Condition: "TaskId",
            Keyword: this.taskObj.TaskId,
          },
        ])
        .then((res) => {
          if (res.Success) {
            // 商铺总面积
            this.totalObj.ShopArea = res.Data.ShopArea;
            // 总电量
            this.totalObj.ShopPowerConsumption = res.Data.ShopPowerConsumption;
            // 餐饮商铺总个数
            this.totalObj.ShopCateringTypeCount =
              res.Data.ShopCateringTypeCount;
            // 总排烟量
            this.totalObj.ShopKitchenExhaustFumes =
              res.Data.ShopKitchenExhaustFumes;
            // 商铺总个数
            this.totalObj.ShopCount = res.Data.ShopCount;

            // echart图标渲染
            this.ecahrtsOptions.legend.data =
              res.Data.ShopPieChartList[0] ? res.Data.ShopPieChartList[0].PieData : [];
            this.ecahrtsOptions.series[0].data =
              res.Data.ShopPieChartList[0] ? res.Data.ShopPieChartList[0].PieData : [];

            this.ecahrtsOptions2.series[0].data =
              res.Data.ShopPieChartList[1] ? res.Data.ShopPieChartList[1].PieData : [];
            this.ecahrtsOptions2.legend.data =
              res.Data.ShopPieChartList[1] ? res.Data.ShopPieChartList[1].PieData : [];

            this.ecahrtsOptions1.legend.data =
              res.Data.ShopPieChartList[2] ? res.Data.ShopPieChartList[2].PieData : [];
            this.ecahrtsOptions1.series[0].data =
              res.Data.ShopPieChartList[2] ? res.Data.ShopPieChartList[2].PieData : [];
          }
        });
    },
    // 获取招商数据列表接口获取招商数据列表接口获取招商数据列表接口获取招商数据列表接口获取招商数据列表接口获取招商数据列表接口获取招商数据列表接口
    getDataList() {
      var that = this;
      this.loading = true;
      this.$http
        .post("/Shop_Manage/Shop_BaseInfo/GetDataListByMoreCondition", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortInfos: this.SortInfos,
          Search: this.queryParam,
        })
        .then((res) => {
          // 取消表格加载提示
          this.loading = false;
          if (res.Success) {
            // 获取招商列表工程状态下拉列表数据
            this.getEngineeringStatusOptions();
            if (res.Data.length == 0) {
              this.pagination.total = 0;
              // 显示暂无招商信息
              this.isTable = true;
              this.tableData = [];
              return;
            }

            this.pagination.total = res.Total;
            // 赋值任务id
            this.TaskId = res.Data[0].TaskId;
            this.tableData = res.Data; // 分页请求的数据，追加到任务列表中

            setTimeout(function () {
              that.loading = false;
            });
          } else {
            this.pagination.total = 0;
            this.loading = false;
          }
        });
    },

    // 获取招商列表工程状态下拉列表数据
    getEngineeringStatusOptions() {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: "EngineeringStatus",
        })
        .then((res) => {
          if (res.Success) {
            let EngineeringStatusOptions = res.Data.DictionaryDetailsList
            // .map(
            //   (item) => {
            //     return {
            //       value: item.Id,
            //       label: item.DicName,
            //     };
            //   }
            // );
            this.EngineeringStatusOptions = EngineeringStatusOptions;

          }
        });
    },

    // adddetail子组件传递过来的关闭弹窗需要刷新表格数据的方法
    handleCloseResetTableChange() {
      // 页数初始化
      // this.pagination.current = 1;
      // this.getDataList();
      // // 重新获取表格数据
      this.loadMore();
    },
    // 日志显示隐藏
    backLogChange() {
    },
    // 新建商铺
    handlerAddChange(row) {
      let obj = {
        title: "新建",
        ProjectId: this.taskObj.ProjectId,
        TaskId: this.taskObj.TaskId,
        // rowTitle:rowTitle,
        // parentKey: this.allParent[column.property],
        // key: column.property,
      };
      this.$refs.AddDetail.open(obj, this.taskObj);
    },

    // 表头排序
    sortClickChange(index, row) {
      this.sortIndex = index;
      if (this.sortIndex === "top") {
        this.tableData.sort(function (a, b) {
          return a.ShopCode - b.ShopCode;
        });
      } else {
        this.tableData.sort(function (a, b) {
          return b.ShopCode - a.ShopCode;
        });
      }
    },

    // 工程状态改变事件
    EngineeringStatusChange(row) {
      // 存储vux  商铺信息
      this.$store.commit("getShopInfo", row);
      var shopInfo = this.$store.state.shopInfo;
      // EngineeringStatus
      let obj = {
        CreateTime: row.EngineeringBaseInfo_CreateTime
          ? row.EngineeringBaseInfo_CreateTime
          : undefined,
        CreatorId: row.EngineeringBaseInfo_CreatorId
          ? row.EngineeringBaseInfo_CreatorId
          : undefined,
        Id: row.EngineeringBaseInfo_Id ? row.EngineeringBaseInfo_Id : undefined,
        ModuleType: "EngineeringBaseInfo",
        ModuleJson: JSON.stringify({
          EntryTime: row.EntryTime ? row.EntryTime : "",
          ConstructionProgress: row.ConstructionProgress
            ? row.ConstructionProgress
            : "",
          IsOpeningBusiness: row.IsOpeningBusiness ? row.IsOpeningBusiness : "",
          RemarkEngineeringInfo: row.RemarkEngineeringInfo
            ? row.RemarkEngineeringInfo
            : "",
          EngineeringStatus: row.EngineeringStatus,
        }),
        ShopId: row.Id,
        Status: 1,
        // 任务id
        TaskId: this.taskObj.TaskId,
      };
      console.log("工程状态改变了", row, row);
      // 更新工程状态
      this.getShop_ExtensionInfoRequest(obj);
    },
    // 合约状态改变事件
    ContractStatusChange(row) {
      // 存储vux  商铺信息
      this.$store.commit("getShopInfo", row);
      var shopInfo = this.$store.state.shopInfo;
      let obj = {
        CreateTime: row.ContractInfoJson_CreateTime
          ? row.ContractInfoJson_CreateTime
          : undefined,
        CreatorId: row.ContractInfoJson_CreatorId
          ? row.ContractInfoJson_CreatorId
          : undefined,
        Id: row.ContractInfoJson_Id ? row.ContractInfoJson_Id : undefined,
        ModuleType: "ContractInfoJson",
        ModuleJson: JSON.stringify({
          ContractStartTime: row.ContractStartTime ? row.ContractStartTime : "",
          ContractEndTime: row.ContractEndTime ? row.ContractEndTime : "",
          ContractPrice: row.ContractPrice ? row.ContractPrice : 0,
          ContractStatus: row.ContractStatus ? row.ContractStatus : "未签约",
          RemarkContract: row.RemarkContract ? row.RemarkContract : "",
        }),
        ShopId: row.Id,
        Status: 1,
      };
      // 更新合约状态
      this.getShop_ExtensionInfoRequest(obj);
    },
    SaveOperationLogs(data) {
      this.$http
        .post("/Shop_Manage/Shop_ExtensionInfo/SaveOperationLogs", { ...data })
        .then(res => {
          if (res.Success) {
            console.log("保存日志成功")
            // this.parentObj.refreshLog()
          } else {
            console.log("保存日志失败")
          }
        })
    },
    // 扩展模块信息提交请求
    getShop_ExtensionInfoRequest(obj) {
      var that = this;
      var shopInfo = this.$store.state.shopInfo;

      const loading = this.$loading({
        lock: true,
        text: "修改中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$http
        .post("/Shop_Manage/Shop_ExtensionInfo/SaveData", obj)
        .then((res) => {
          if (res.Success) {
            shopInfo[obj.ModuleType + "_CreateTime"] = res.Data.CreateTime;
            shopInfo[obj.ModuleType + "_CreatorId"] = res.Data.CreatorId;
            shopInfo[obj.ModuleType + "_Id"] = res.Data.Id;
            this.SaveOperationLogs(res.Data)
            // 重新更新vux里的商铺信息
            this.$store.commit("getShopInfo", shopInfo);
            setTimeout(() => {
              loading.close();
              this.$message.success("已修改");
            }, 500);
          } else {
            loading.close();
            this.$message.error(res.Msg);
          }
        });
    },

    // 表格多选事件
    tableSelectionChange(e) {
      this.$emit("tableSelectionChange", e);
    },
    // 表格全选事件
    tableSelectAllChange(e) {
    },
    // 表格更多按钮点击事件
    tableListMoreChange(row, column) {
      if (row == undefined) {
        // 说明点击的是表头里的三个点
        this.$message.error("请点击表格的内容区域");
        return;
      }
      //变更下可以点击所以注释
      // // 判断是否需要变更条件
      if (row.Status == 2) {
        // 说明当前已提交版本 处于解锁状态
        this.$message.error("请先变更");
        return;
      }

      let rowTitle = "";
      if (column == undefined) {
        // 点击的是工程信息区块的备注区块
        rowTitle = "工程信息";
      } else if (column === "ellipsis1") {
        //点击的是基础信息区块
        rowTitle = "基础信息";
      } else {
        //点击的是设计信息区块
        rowTitle = "设计信息";
      }
      let obj = {
        title: "",
        row: row,
        rowTitle: rowTitle,
        parentKey: column,
        key: column,
      };
      // 编辑弹窗
      this.$refs.EditDetail.open(obj, this.taskObj);
    },

    handleCommand(e) {
      this.$emit("showColumn", e);
    },
    // 表格单元格点击事件
    cellClick(row, column, cell, event) {
      console.log(column);
      console.log(row, 'row');
      let rowTitle = ""; //点击的是哪个区块的名称
      let property = this.allParent[column.property];
      if (row.Status == 2) {
        // 说明当前已提交版本 处于解锁状态
        this.$message.error("请先变更");
        return;
      }

      if (property === "Shop_BaseInfo" || column.property === "ellipsis1") {
        //点击的是基础信息区块  包含商铺编号，工程编号等  ellipsis1 和ellipsis2 点击的是基础信息和设计信息里的三个点
        rowTitle = "基础信息";
      } else if (
        column.property == "AirConditioningCondition.TotalCoolingCapacity" ||
        property === "DesignInfoJson" ||
        column.property === "ellipsis2"
      ) {
        //点击的是设计信息区块
        rowTitle = "设计信息";
      } else if (
        column.property === "EngineeringStatus" ||
        property === "EngineeringInfoJson" ||
        column.property === "EngineeringStatus"
      ) {
        //点击的是工程信息区块
        rowTitle = "工程信息";
      } else if (property === "ContractInfoJson") {
        //点击的是合约信息区块
        rowTitle = "招商信息";
      }

      let obj = {
        title: "",
        row: row,
        rowTitle: rowTitle,
        parentKey: this.allParent[column.property],
        key: column.property,
      };
      // 编辑弹窗
      this.$refs.EditDetail.open(obj, this.taskObj);

      // 暂时不删除
      // this.$emit("cellClick", "编辑", row, {
      //   parentKey: this.allParent[column.property],
      //   key: column.property,
      // });
    },
    hiddenColumn(key) {
      this.$emit("hiddenColumn", key);
    },
    // showColumn(key) {
    //   this.$emit("showColumn", key);
    // },
    // 动态获取当前浏览器窗口的高度 宽度  -30  是减去其余头部的区域部分   剩余需要显示的，，后续可以调整减号后的数字调整高度大小
    getWindowHeight() {
      this.windowHeight = window.innerHeight - 240;
      this.windowHeightAll = window.innerHeight - 190;
      this.windowWidth = window.innerWidth - 100;
      this.windowWidthTotal = window.innerWidth - 513;
      this.windowWidthTable = this.windowWidth - 200;
      // 根据当前可视窗口的高度，赋值editDetail组件弹窗的高度
      this.$refs.EditDetail.windowHeight = this.windowHeight + 50;
    },
    // 表格详情
    handleEdit(val) {
      // 显示编辑详情弹窗
      this.$refs.EditDetail.open("详情", val);
    },
    // 表格查看
    handleDetail(val) {
      this.$emit("handleDetailChange", val);
    },

    // 版本提交
    submitButton(val) {
      this.$emit("handleSubmitButtonChange", val, "单行");
    },

    // 撤销版本
    submitButtonBatchVersion(row) {
      // 判断是否是初始版本
      if (row.ChangeVersion === "V1.0") return;
      this.$emit("submitButtonBatchVersion", row);
    },

    // 变更按钮
    submitButtonUpdate(row) {
      // 先获取变更下拉列表数据
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: "ShopChangeReason",
        })
        .then((res) => {
          if (res.Success) {
            let shopReasonOptions = res.Data.DictionaryDetailsList.map(
              (item) => {
                return {
                  value: item.Id,
                  label: item.DicName,
                };
              }
            );
            this.shopReasonOptions = shopReasonOptions;
          } else {
            this.$message.error(res.Msg);
          }
        });
      // 清空原因输入框内容
      this.ShopChangeReason = "";
      this.SupplementReason = "";
      this.rowData = row;
      // 显示变更原因弹窗
      this.dialogVisible = true;
    },
    // 字符串截取
    getStringFormat(str) {
      if (str) {
        let arr = str.split("");
        let version = Number(arr[1]);
        let newVersion = version + 1;
        return newVersion;
      }
    },
    // 解锁 变更
    // submitButtonUnlockChangeNoVersion() {
    //   if (this.ShopChangeReason === "" || this.selectShopChangeReason === "") {
    //     this.$message.error("请输入变更原因");
    //     return;
    //   }
    //   this.VersionLoading = true
    //   this.$confirm(
    //     `确定不修改版本变更吗？`,
    //     "提示",
    //     {
    //       confirmButtonText: "确定",
    //       cancelButtonText: "取消",
    //       type: "warning",
    //     }
    //   ).then(val => {
    //     let obj = {
    //       Conditions: [
    //         {
    //           Condition: "ShopChangeReason",
    //           Keyword: this.ShopChangeReason,
    //         },
    //       ],
    //       Ids: [this.rowData.Id],
    //     };
    //     this.$http
    //       .post("/Shop_Manage/Shop_BaseInfo/UpdateDataByCondition", obj)
    //       .then((res) => {
    //         if (res.Success) {
    //           this.dialogVisible = false;
    //           this.VersionLoading = false
    //           this.$message.success("变更成功");
    //           // this.$emit(
    //           //   "handleSelectionChange",
    //           //   this.rowData,
    //           //   this.ShopChangeReason,
    //           //   this.SupplementReason
    //           // );
    //         } else {
    //           this.VersionLoading = false
    //           this.$message.error(res.Msg);
    //         }
    //       });
    //   }).catch(() => {
    //     this.VersionLoading = false
    //   })

    // },
    //变更原因+提交版本
    submitButtonUnlockChange(IsChangeVersion) {

      if (this.ShopChangeReason === "" || this.selectShopChangeReason === "") {
        this.$message.error("请输入变更原因");
        return;
      }
      let str = ''
      if (IsChangeVersion) {
        let newVersion = "V" + this.getStringFormat(this.rowData.ChangeVersion) + ".0";
        str = `确定变更${this.rowData.ChangeVersion} 版本到 ${newVersion} 版本吗`
      } else {
        str = ` 确定不修改版本变更吗？`

      }
      this.VersionLoading = true

      this.$confirm(
        str,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$http
            .post("/Shop_Manage/Shop_BaseInfo/ShopInfo_VersionUnlock", {
              IsChangeVersion: IsChangeVersion,
              ShopId: this.rowData.Id,
              ShopChangeReason: this.ShopChangeReason,
              SupplementReason: this.SupplementReason,
            })
            .then((res) => {
              if (res.Success) {
                this.VersionLoading = false
                this.dialogVisible = false;
                this.$message.success("变更成功,已解锁");
                this.loadMore()
                // this.$emit(
                //   "handleSelectionChange",
                //   this.rowData,
                //   this.ShopChangeReason,
                //   this.SupplementReason
                // );
              } else {
                this.VersionLoading = false
                this.$message.error(res.Msg);
              }
            });
        }).catch(() => {
          this.VersionLoading = false
        })

    },
    setIsShow(show, data) {
    },
    // 版本当前行点击事件
    tableDetailClick(item) {
      this.$emit("parentSetIsShow", "TableDetail", item);
    },
    // 关闭弹窗
    handleClose(done) {
      done();
    },
  },
};
</script>
<style lang="scss">
.disabled {
  color: #c3c3c3 !important;
}

/* // 去掉eltable的hover效果 */
::v-deep .el-table tbody tr:hover>td {
  background-color: transparent !important;
}
</style>
<style lang="scss">
.sixBorder {
  //background-color: #065b5b !important;
  border-left: 2px solid #d3d3d3 !important;
}

.el-table .warning-row {
  background: #f0f0f0;
}

/*
//::v-deep.el-table--border {
//  td {
//    border-right: 2px solid red !important;
//  }
//}
/* 覆盖element 指定表格的边框样式
//tr th.el-table_2_column_22,
//tr th.el-table_2_column_23,
//tr th.el-table_2_column_28,
//tr th.el-table_2_column_32,
//tr th.el-table_2_column_35 {
//  border-right: 5px solid rgba(0, 0, 0, 0.7) !important;
//}
//table tbody tr td.el-table_1_column_4_column_8 {
//  //border-right: 5px solid rgba(0, 0, 0, 0.7) !important;
//  background-color: red !important;
//}
*/
.shopCodeTop.active {
  border-bottom-color: red;
}

.shopCodeBottom.active {
  border-top-color: red;
}

.shopCodeTop {
  cursor: pointer;
  display: block;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: #b0b0b0;
}

.shopCodeBottom {
  cursor: pointer;
  display: block;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: #b0b0b0;
}

/* 首先去除默认的hover效果v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(0, 0, 0, 0) !important;
} */

// 表格滚动条的样式     没有滚动条***************************
//::-webkit-scrollbar{
// width: -1px ;
//}
//都有滚动条
::-webkit-scrollbar {
  width: -1px;
}

//覆盖单个区域的滚动条样式***************************
//.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar{
// background-color: red;
//}

.button.active {
  color: #bcbcbc !important;
}

.button:hover {
  color: #fa7474 !important;
  font-weight: bold;
  font-size: 16px;
}

/* background: #ccc !important;*/
.tableRow {
  td:hover {}
}

::v-deep .el-table {
  //border: 3px solid red !important;
}

V-deep .el-table .el-table__header-wrapper {
  //border: 3px solid red;
}

/*覆盖element单元格的hover样式*/
.el-table tbody tr:hover>td {
  background-color: #f0f0f0 !important; //修改成自己想要的颜色即可
  cursor: pointer;
}

.el-table__fixed-right-patch {
  background: #eff2f6;
}

.el-table tbody tr td div div div div.flex {
  color: #fff;
  display: flex;
}

.el-table tbody tr td div div div div.flex div.detail {
  background-color: #fff;
  padding: 0 5px;
  border-radius: 5px;
  margin-left: 10px;
}

/*覆盖element表格hover事件 。。逐渐乡里层 background-color: #fff;*/
.el-table tbody tr:hover td div div div div {
  cursor: pointer;
  padding: 0 5px;
  border-radius: 5px;
  margin-left: 10px;
}

.el-table tbody tr:hover td div div div span {
  color: #333;
  cursor: pointer;
}

/*隐藏整个表格区域的横向滚动条  否则会出现横向滚动条*/
.tableItem::-webkit-scrollbar {
  display: none;
}

.tableItem {
  overflow: auto;

  .hover:hover {
    color: #333 !important;
  }

  .bottomTotal {
    .grid-content.bg-purple {
      padding: 6px 0;
      flex: 1;
      background-color: #d7d7d7;
      border: 2px solid #a8a7a7;
      border-right: 0;
      text-align: center;
      font-size: 13px;
    }

    .grid-content.bg-purple-light {
      background-color: #f2f2f2;
      border: 2px solid #a8a7a7;
      border-right: 0;
      flex: 1;
      line-height: 30px;
      text-align: center;
    }
  }

  .grid-content.bg-purple {
    display: flex;
    flex-direction: column;
  }

  /*设置表格的高度  内容过长自动滚动*/
  .tablelist {

    //overflow: auto;
    .Shop_BaseInfo {
      display: flex;
      justify-content: space-between;

      .detail {
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
    }
  }
}

/* 处理表格表头和内容错位问题 */
.el-table th.gutter {
  display: table-cell !important;
}

.el-table th,
.el-table td {
  padding: 7px 0 !important;
}

/**
修改表格样式
改变边框颜色
 */
.el-table--border,
.el-table--group {
  border: 2px solid #ddd !important;
}

/**
改变表格内竖线颜色
*/
.el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
  border-right: 1px solid #ddd !important;
}

// .el-input__inner{
//   height: 20px;
// }
/**
改变表格内行线颜色
*/
.el-table td,
.el-table th,
.el-table th.is-leaf {
  border-bottom: 1px solid #ddd !important;
}

.el-table thead tr th {
  border-color: #8c939d;
}

/* g改变表头字体颜色 */
.el-table thead {
  color: black;
}

/* 加载图片区域 */
.loading_gif {
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ellipsis1 {
  overflow: hidden; //超出隐藏
  word-wrap: break-word;
  word-break: normal;
  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

  -webkit-line-clamp: 1; //内容限制的行数
}
</style>
