// const col = [
//   {
//     DicKey: "Shop_BaseInfo",
//     disabled: false,
//     DicName: "基础信息",
//     fixed: "left",
//     width: "1200px",
//     isRequire: false,
//     show: true,
//     children: [
//       {
//         DicKey: "BuildingId",
//         DicName: "楼栋",
//         width: "110px",
//         fixed: "left",
//         isRequire: false,
//         isNumber: false, //判断是否是数字框
//         oninput: "",
//         show: true,
//       },
//       {
//         DicKey: "IsOnGround",
//         DicName: "地上/地下",
//         width: "110px",
//         fixed: "left",
//         isRequire: false,
//         isNumber: false,
//         oninput: "v",
//         show: true,
//       },
//       {
//         DicKey: "Storey",
//         DicName: "楼层",
//         width: "110px",
//         fixed: "left",
//         isRequire: false,
//         isNumber: true,
//         oninput: "value=value.replace(/[^d]/g,'')",
//         show: true,
//       },
//       {
//         DicKey: "ShopArea",
//         DicName: "商铺面积",
//         width: "110px",
//         fixed: "left",
//         isRequire: false,
//         isNumber: true,
//         oninput: "value=value.replace(/[^d]/g,'')",
//         show: true,
//       },
//       {
//         DicKey: "ShopFormatClass",
//         DicName: "业态级别",
//         width: "110px",
//         fixed: "left",
//         isRequire: false,
//         isNumber: false,
//         oninput: "",
//         show: true,
//       },
//       {
//         DicKey: "ShopFormatName",
//         DicName: "业态名称",
//         width: "110px",
//         fixed: "left",
//         isRequire: false,
//         isNumber: false,
//         oninput: "",
//         show: true,
//       },
//       {
//         DicKey: "ShopBrand",
//         DicName: "商铺品牌信息",
//         width: "110px",
//         fixed: "left",
//         isRequire: false,
//         isNumber: false,
//         oninput: "",
//         show: true,
//       },
//       {
//         DicKey: "ShopSeparation",
//         DicName: "商铺分隔模式",
//         width: "150px",
//         fixed: "left",
//         isRequire: false,
//         isNumber: false,
//         oninput: "",
//         show: true,
//       },
//       {
//         DicKey: "ShopChangeReason",
//         DicName: "商铺变更原因",
//         width: "220px",
//         fixed: "left",
//         isRequire: false,
//         isNumber: false,
//         oninput: "",
//         show: true,
//       },
//       {
//         DicKey: "SupplementReason",
//         DicName: "商铺补充变更原因",
//         width: "110px",
//         fixed: "left",
//         isRequire: false,
//         isNumber: false,
//         oninput: "",
//         show: true,
//       },
//       {
//         DicKey: "Remark",
//         DicName: "备注",
//         width: "200px",
//         fixed: "left",
//         isRequire: false,
//         isNumber: false,
//         oninput: "",
//         disabled: false,
//         show: true,
//       },
//     ],
//   },
//   {
//     DicKey: "ProjectCode",
//     disabled: false,
//     DicName: "编号信息",
//     fixed: "left",
//     width: "1200px",
//     isRequire: false,
//     show: true,
//     children: [
//       {
//         DicKey: "InvestmentCode",
//         DicName: "招商编码",
//         width: "110px",
//         fixed: "left",
//         isRequire: true,
//         isNumber: true,
//         oninput: "value=value.replace(/[^d]/g,'')",
//         show: true,
//       },
//       {
//         DicKey: "ShopCode",
//         DicName: "商铺编号",
//         width: "110px",
//         fixed: "left",
//         isRequire: true,
//         isNumber: true,
//         oninput: "value=value.replace(/[^d]/g,'')",
//         show: true,
//       },
//     ],
//   },
// ];
const col = [
  {
    DicKey: "Shop_BaseInfo",
    disabled: false,
    DicName: "电气信息",
    fixed: "left",
    width: "1200px",
    isRequire: false,
    show: true,
    children: [
      {
        DicKey: "BuildingId",
        DicName: "",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false, //判断是否是数字框
        oninput: "",
        show: true,
        children: [
          {
            DicKey: "InvestmentCode",
            DicName: "用电量(kW)",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
        ],
      },
      {
        DicKey: "IsOnGround",
        DicName: "",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "v",
        show: true,
        children: [
          {
            DicKey: "InvestmentCode",
            DicName: "用电量指标（W /㎡）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
        ],
      },
      {
        DicKey: "Storey",
        DicName: "隔离电箱信息",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
        children: [
          {
            DicKey: "InvestmentCode",
            DicName: "商铺隔离电箱编号",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "租户隔离箱进线开关（无保护作用）额定电流（A）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "租户上端配电断路器（选型参考）额定电流（A）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "租户进线电缆规格",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
        ],
      },
      // {
      //   DicKey: "ShopArea",
      //   DicName: "厨房板面活荷载",
      //   width: "110px",
      //   fixed: "left",
      //   isRequire: false,
      //   isNumber: true,
      //   oninput: "value=value.replace(/[^d]/g,'')",
      //   show: true,
      //   children: [
      //     {
      //       DicKey: "InvestmentCode",
      //       DicName: "KN/m2",
      //       width: "110px",
      //       fixed: "left",
      //       isRequire: true,
      //       isNumber: true,
      //       oninput: "value=value.replace(/[^d]/g,'')",
      //       show: true,
      //     },
      //   ]
      // },
      // {
      //   DicKey: "ShopFormatClass",
      //   DicName: "厨房板面恒荷载",
      //   width: "110px",
      //   fixed: "left",
      //   isRequire: false,
      //   isNumber: false,
      //   oninput: "",
      //   show: true,
      //   children: [
      //     {
      //       DicKey: "InvestmentCode",
      //       DicName: "KN/m2",
      //       width: "110px",
      //       fixed: "left",
      //       isRequire: true,
      //       isNumber: true,
      //       oninput: "value=value.replace(/[^d]/g,'')",
      //       show: true,
      //     },
      //   ]
      // },
      // {
      //   DicKey: "ShopFormatName",
      //   DicName: "业态名称",
      //   width: "110px",
      //   fixed: "left",
      //   isRequire: false,
      //   isNumber: false,
      //   oninput: "",
      //   show: true,
      // },
      // {
      //   DicKey: "ShopBrand",
      //   DicName: "商铺品牌信息",
      //   width: "110px",
      //   fixed: "left",
      //   isRequire: false,
      //   isNumber: false,
      //   oninput: "",
      //   show: true,
      // },
      // {
      //   DicKey: "ShopSeparation",
      //   DicName: "商铺分隔模式",
      //   width: "150px",
      //   fixed: "left",
      //   isRequire: false,
      //   isNumber: false,
      //   oninput: "",
      //   show: true,
      // },
      // {
      //   DicKey: "ShopChangeReason",
      //   DicName: "商铺变更原因",
      //   width: "220px",
      //   fixed: "left",
      //   isRequire: false,
      //   isNumber: false,
      //   oninput: "",
      //   show: true,
      // },
      // {
      //   DicKey: "SupplementReason",
      //   DicName: "商铺补充变更原因",
      //   width: "110px",
      //   fixed: "left",
      //   isRequire: false,
      //   isNumber: false,
      //   oninput: "",
      //   show: true,
      // },
      // {
      //   DicKey: "Remark",
      //   DicName: "备注",
      //   width: "200px",
      //   fixed: "left",
      //   isRequire: false,
      //   isNumber: false,
      //   oninput: "",
      //   disabled: false,
      //   show: true,
      // },
    ],
  },
  {
    DicKey: "ProjectCode",
    disabled: false,
    DicName: "暖通信息",
    fixed: "left",
    width: "1200px",
    isRequire: false,
    show: true,
    children: [
      {
        DicKey: "InvestmentCode",
        DicName: "空调条件",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
        children: [
          {
            DicKey: "InvestmentCode",
            DicName: "总制冷量（kW）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "制冷负荷指标（W /㎡）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "总供热量（kW）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "供热负荷指标（W /㎡）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "空调冷水管径",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "空调热水管径",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "新风量（m³/h）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "新风管尺寸（mm）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
        ],
      },
      {
        DicKey: "ShopCode",
        DicName: "通风条件",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
        children: [
          {
            DicKey: "InvestmentCode",
            DicName: "厨房排油烟量（m³/h）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "厨房排油烟管尺寸（mm）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "厨房平时/事故排风量（m³/h）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "厨房平时/事故排风管尺寸（mm）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "厨房补风量（m³/h）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "厨房补风管尺寸（mm）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "个护排风量（m³/h）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
          {
            DicKey: "InvestmentCode",
            DicName: "个护排风管尺寸（mm）",
            width: "110px",
            fixed: "left",
            isRequire: true,
            isNumber: true,
            oninput: "value=value.replace(/[^d]/g,'')",
            show: true,
          },
        ],
      },
    ],
  },
  {
    DicKey: "ProjectCode",
    disabled: false,
    DicName: "给排水条件",
    fixed: "left",
    width: "1200px",
    isRequire: false,
    show: true,
    children: [
      {
        DicKey: "InvestmentCode",
        DicName: "给水点数（个）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "给水管径",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "非餐饮排水点数（个）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "非餐饮排水管径",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "餐饮排水点数（个）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "餐饮排水管径",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
    ],
  },
  // {
  //   DicKey: "ProjectCode",
  //   disabled: false,
  //   DicName: "强电条件",
  //   fixed: "left",
  //   width: "1200px",
  //   isRequire: false,
  //   show: true,
  //   children: [
  //     {
  //       DicKey: "InvestmentCode",
  //       DicName: "租户隔离电箱编号",
  //       width: "110px",
  //       fixed: "left",
  //       isRequire: true,
  //       isNumber: true,
  //       oninput: "value=value.replace(/[^d]/g,'')",
  //       show: true,

  //     },
  //     {
  //       DicKey: "ShopCode",
  //       DicName: "用电负荷指标",
  //       width: "110px",
  //       fixed: "left",
  //       isRequire: true,
  //       isNumber: true,
  //       oninput: "value=value.replace(/[^d]/g,'')",
  //       show: true,

  //     },
  //     {
  //       DicKey: "ShopCode",
  //       DicName: "租户计算电量",
  //       width: "110px",
  //       fixed: "left",
  //       isRequire: true,
  //       isNumber: true,
  //       oninput: "value=value.replace(/[^d]/g,'')",
  //       show: true,

  //     },
  //     {
  //       DicKey: "ShopCode",
  //       DicName: "租户隔离箱进线开关(无保护作用)额定电流",
  //       width: "110px",
  //       fixed: "left",
  //       isRequire: true,
  //       isNumber: true,
  //       oninput: "value=value.replace(/[^d]/g,'')",
  //       show: true,

  //     },
  //     {
  //       DicKey: "ShopCode",
  //       DicName: "租户上端配电断路器(选型参考)额定电",
  //       width: "110px",
  //       fixed: "left",
  //       isRequire: true,
  //       isNumber: true,
  //       oninput: "value=value.replace(/[^d]/g,'')",
  //       show: true,

  //     },
  //     {
  //       DicKey: "ShopCode",
  //       DicName: "租户进线电缆规格",
  //       width: "110px",
  //       fixed: "left",
  //       isRequire: true,
  //       isNumber: true,
  //       oninput: "value=value.replace(/[^d]/g,'')",
  //       show: true,

  //     },
  //   ],
  // },
  {
    DicKey: "ProjectCode",
    disabled: false,
    DicName: "弱电条件",
    fixed: "left",
    width: "1200px",
    isRequire: false,
    show: true,
    children: [
      {
        DicKey: "InvestmentCode",
        DicName: "弱电箱编号",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "弱电接线盒",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "网络点位及线缆",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "有线电视点位及线缆",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "POS点位及线缆",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
    ],
  },
  {
    DicKey: "ProjectCode",
    disabled: false,
    DicName: "燃气条件",
    fixed: "left",
    width: "1200px",
    isRequire: false,
    show: true,
    children: [
      {
        DicKey: "InvestmentCode",
        DicName: "天然气量（m³/h）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "天然气压力(kPa)",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "燃气接管管径",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
    ],
  },
  {
    DicKey: "ProjectCode",
    disabled: false,
    DicName: "结构信息",
    fixed: "left",
    width: "1200px",
    isRequire: false,
    show: true,
    children: [
      {
        DicKey: "InvestmentCode",
        DicName: "底板板面活荷载（kN /㎡",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "底板板面恒荷载（含吊顶和管线）（kN/㎡）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "厨房板面活荷载（kN /㎡）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "厨房板面恒荷载（kN /㎡）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "降板高度(mm)",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
    ],
  },
  {
    DicKey: "ProjectCode",
    disabled: false,
    DicName: "消防信息",
    fixed: "left",
    width: "1200px",
    isRequire: false,
    show: true,
    children: [
      {
        DicKey: "InvestmentCode",
        DicName: "户内消火栓（提供/不提供",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "户内消火栓数量（个）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "喷淋系统（提供/不提供）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "烟感报警系统（提供/不提供）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "消防排烟入户管道（提供/不提供）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "消防排烟总风量（m³/h）",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
    ],
  },
];
const newCol = [
  {
    DicKey: "Shop_BaseInfo",
    DicName: "基础信息",
    fixed: "left",
    width: "1100px",
    isRequire: false,
    show: true,
    children: [
      {
        DicKey: "BuildingId",
        DicName: "楼栋",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "IsOnGround",
        DicName: "地上/地下",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "Storey",
        DicName: "楼层",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopName",
        DicName: "商铺名称",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },

      {
        DicKey: "ShopArea",
        DicName: "商铺面积",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopFormatClass",
        DicName: "业态级别",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopFormatName",
        DicName: "业态名称",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopBrand",
        DicName: "商铺品牌信息",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopSeparation",
        DicName: "商铺分隔模式",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "ShopChangeReason",
        DicName: "商铺变更原因",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "SupplementReason",
        DicName: "商铺补充变更原因",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        show: true,
      },
      {
        DicKey: "Remark",
        DicName: "备注",
        width: "110px",
        fixed: "left",
        isRequire: false,
        isNumber: false,
        oninput: "",
        disabled: true,
        show: true,
      },
    ],
  },
  {
    DicKey: "ProjectCode",
    disabled: false,
    DicName: "编号信息",
    fixed: "left",
    width: "1200px",
    isRequire: false,
    show: true,
    children: [
      {
        DicKey: "InvestmentCode",
        DicName: "招商编码",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
      {
        DicKey: "ShopCode",
        DicName: "商铺编号",
        width: "110px",
        fixed: "left",
        isRequire: true,
        isNumber: true,
        oninput: "value=value.replace(/[^d]/g,'')",
        show: true,
      },
    ],
  },
];
// 图纸报审 下拉数据
const DrawingApprovalOptions = [
  {
    id: 1,
    label: "已通过",
  },
  {
    id: 2,
    label: "未通过",
  },
];

//   是否开业
const IsOpeningBusinessOptions = [
  {
    id: 1,
    label: "是",
  },
  {
    id: 2,
    label: "否",
  },
];

//   业态级别下拉数据
const ShopFormatClassOptions = [
  {
    value: "1",
    label: "主力店",
  },
  {
    value: "2",
    label: "一般商城",
  },
];

const EngineeringStatusOptions = [
  {
    value: "0",
    label: "前期接洽",
  },
  {
    value: "1",
    label: "可研分析",
  },
  {
    value: "2",
    label: "等待签约",
  },
  {
    value: "3",
    label: "已签约",
  },
];
//   工程状态下拉数据
const ContractOptions = [
  {
    value: "1",
    DicName: "未签约",
    DicValue: "1",
    label: "未签约",
  },
  {
    value: "0",
    DicName: "已签约",
    DicValue: "0",
    label: "已签约",
  },
];

const IsOnGroundOptions = [
  {
    value: 1,
    label: "地上",
  },
  {
    value: 2,
    label: "地下",
  },
  {
    value: 3,
    label: "屋面",
  },
];
const sortOptions = [
  {
    value: "ShopArea",
    label: "按面积排序",
  },
  {
    value: "ShopFormatClass",
    label: "按业态排序",
  },
  {
    value: "ShopCode",
    label: "按商铺编号排序",
  },
  {
    value: "Storey",
    label: "按楼层排序",
  },
];

const dianqiLuOptions = [
  //电气区域所有行的第一列下拉数据  ---几路几路
  {
    value: "1",
    label: "1路",
  },
  {
    value: "2",
    label: "2路",
  },
  {
    value: "3",
    label: "3路",
  },
  {
    value: "4",
    label: "4路",
  },
  {
    value: "5",
    label: "5路",
  },
];

const dianqiLiuOptions = [
  //电气区域所有行的中间两列列下拉数据
  {
    value: "16",
    label: "16",
  },
  {
    value: "20",
    label: "20",
  },
  {
    value: "25",
    label: "25",
  },
  {
    value: "32",
    label: "32",
  },
  {
    value: "40",
    label: "40",
  },
  {
    value: "50",
    label: "50",
  },
  {
    value: "63",
    label: "63",
  },
  {
    value: "80",
    label: "80",
  },
  {
    value: "100",
    label: "100",
  },
  {
    value: "125",
    label: "125",
  },
  {
    value: "160",
    label: "160",
  },
  {
    value: "200",
    label: "200",
  },
  {
    value: "225",
    label: "225",
  },
  {
    value: "250",
    label: "250",
  },
  {
    value: "315",
    label: "315",
  },
  {
    value: "350",
    label: "350",
  },
  {
    value: "400",
    label: "400",
  },
  {
    value: "500",
    label: "500",
  },
  {
    value: "630",
    label: "630",
  },
];

const dianqiGuiOptions = [
  //电气区域所有行的仅限规格下拉数据
  {
    value: "4x6",
    label: "4x6 + 1x6",
  },
  {
    value: "4x95",
    label: "4x95 + 1x90",
  },
  {
    value: "4x120",
    label: "4x120 + 1x70",
  },
  {
    value: "4x150",
    label: "4x150 + 1x95",
  },
  {
    value: "4x185",
    label: "4x185 + 1x95",
  },
  {
    value: "4x240",
    label: "4x240 + 1x120",
  },
  {
    value: "2 (4x120",
    label: "2 (4x120 + 1x70) ",
  },
  {
    value: "2(4x150",
    label: "2(4x150 + 1x95) 1",
  },
];

export {
  col,
  newCol,
  DrawingApprovalOptions,
  IsOpeningBusinessOptions,
  ShopFormatClassOptions,
  EngineeringStatusOptions,
  ContractOptions,
  IsOnGroundOptions,
  sortOptions,
  dianqiLuOptions,
  dianqiLiuOptions,
  dianqiGuiOptions,
};
