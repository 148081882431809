<template>
  <div class="infoBox">
    <el-row :gutter="20" v-loading="loading">
      <el-col :span="5">
        <el-image v-if="disabled" fit="contain" class="projectImg" :src="entity.ProjectImg">
          <div slot="error">
            <div class="image-slot">暂无图片</div>
          </div>
        </el-image>
        <el-upload v-else class="avatar-uploader" :action="$rootUrl +
          '/Base_Manage/Upload/ProjectImgOssUploadFileByForm?ProjectId=' +
          this.entity.Id +
          '&Type=PC'
          " :headers="{
            Authorization: token,
          }" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <el-image v-if="entity.ProjectImg" fit="contain" class="projectImg" :src="entity.ProjectImg"></el-image>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-col>
      <el-col :span="16">
        <el-form :model="entity" :rules="rules" ref="entity" label-width="100px">
          <el-form-item label="项目名称" prop="Name">
            <div class="divContent" v-if="disabled">
              {{ entity.Name }}
            </div>
            <el-input v-else v-model="entity.Name"></el-input>
          </el-form-item>
          <el-form-item label="项目描述" prop="Describe">
            <div class="divContent" v-if="disabled">
              {{ entity.Describe || "这个家伙很懒，什么也没有留下~" }}
            </div>
            <el-input v-else type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="这个家伙很懒，什么也没有留下~"
              v-model="entity.Describe" style="font-family: Arial">
            </el-input>
          </el-form-item>
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="国家" prop="Country">
                <div class="divContent" v-if="disabled">
                  {{ entity.Country }}
                </div>
                <el-select v-else v-model="entity.Country" filterable placeholder="请选择">
                  <el-option value="中国">中国</el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="所在城市" prop="Province">
                <!-- <div class="divContent" v-if="disabled">
                  {{ entity.Province }}{{ entity.City }}
                </div> -->
                <div>
                  <div class="flex">
                    <el-select class="flex:1" v-model="entity.Province" placeholder="请选择省" @change="changeProvince"
                      filterable :disabled="entity.Country != '中国'">
                      <el-option v-for="item in ProvinceLsit" :key="item.Id" :label="item.Name"
                        :value="item.Name"></el-option>
                    </el-select>
                    <el-select class="flex:1" v-model="entity.City" placeholder="请选择市" filterable
                      :disabled="entity.Country != '中国'">
                      <el-option v-for="item in CityLsit" :key="item.Id" :label="item.Name"
                        :value="item.Name"></el-option>
                    </el-select>
                  </div>

                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="项目类型" prop="ProjectType">
                <div class="divContent" v-if="disabled">
                  {{ entity.ProjectType }}
                </div>
                <el-select v-else v-model="entity.ProjectType" filterable>
                  <el-option v-for="item in ProjectTypeList" :key="item.Id" :label="item.DicName"
                    :value="item.DicName"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="建筑类型" prop="BuildingType">
                <div class="divContent" v-if="disabled">
                  {{ entity.BuildingType }}
                </div>
                <el-select v-else v-model="entity.BuildingType" filterable>
                  <el-option v-for="item in BuildingTypeList" :key="item.Id" :label="item.DicName"
                    :value="item.DicName"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目业态名称" prop="Formats">
                <div class="divContent" v-if="disabled">
                  {{ entity.Formats }}
                </div>
                <el-input v-else v-model="entity.Formats"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-form-item label="气候带" prop="ClimaticZone">
            <div class="divContent" v-if="disabled">
              {{ entity.ClimaticZone }}
            </div>
            <el-select v-else v-model="entity.ClimaticZone">
              <el-option
                v-for="item in ClimaticZoneList"
                :key="item.Id"
                :label="item.DicName"
                :value="item.DicName"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="建筑总面积" prop="TotalArea">
                <div class="divContent" v-if="disabled">
                  <span>{{ entity.TotalArea }}</span>
                  <span class="color">m²</span>
                </div>
                <el-input v-else v-model="entity.TotalArea" type="number">
                  <span slot="suffix">m²</span>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="地上建筑面积" prop="OnGroundArea">
                <div class="divContent" v-if="disabled">
                  <span>{{ entity.OnGroundArea }}</span>
                  <span class="color">m²</span>
                </div>
                <el-input v-else v-model="entity.OnGroundArea" type="number">
                  <span slot="suffix">m²</span>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="地下建筑面积" prop="UnderGroundArea">
                <div class="divContent" v-if="disabled">
                  <span>{{ entity.UnderGroundArea }}</span>
                  <span class="color">m²</span>
                </div>
                <el-input v-else v-model="entity.UnderGroundArea" type="number">
                  <span slot="suffix">m²</span>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="开业时间" prop="OpeningTime">
                <div class="divContent" v-if="disabled">
                  <span>{{ entity.OpeningTime ? entity.OpeningTime.substring(0, 10) : '' }}</span>
                </div>
                <el-date-picker v-else v-model="entity.OpeningTime" value-format="yyyy-MM-dd" type="date"
                  placeholder="选择日期" style="width: 185px">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
      <el-col :span="3" style="text-align: right">
        <!-- <div>
          <el-button size="mini" type="primary" v-if="disabled" @click="edit">维护资料</el-button>
        </div> -->
        <div>
          <el-button size="mini" type="primary" @click="save" :disabled="changeNum <= 1">保 存</el-button>
        </div>
        <!-- <div v-if="!disabled" style="margin-top: 10px">
          <el-button size="mini" @click="cancelEdit">取 消</el-button>
        </div> -->
      </el-col>
    </el-row>
    <el-row>
      <lou-don />
    </el-row>
  </div>
</template>


<script>
import Token from "@/utils/cache/TokenCache.js";
import LouDon from "./LouDonForm.vue";
import { col } from '../../newView/mock';
export default {
  components: {
    LouDon,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      loading: false,
      entity: {},
      rules: {},
      visible: false,
      token: "",
      ProvinceLsit: [],
      CityLsit: [],
      disabled: false,
      ProjectTypeList: [],
      BuildingTypeList: [],
      ClimaticZoneList: [],
      changeNum: 0
    };
  },
  async mounted() {
    this.token = "Bearer " + Token.getToken();
    await this.getProvince();
    this.getProjectInfo();
    this.getSelectList();
  },
  methods: {
    edit() {
      this.entity = JSON.parse(JSON.stringify(this.entity));
      // this.disabled = false;
    },
    cancelEdit() {
      this.getProjectInfo();
      // this.disabled = true;
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      if (res.Code == 200) {
        this.entity.Project_Photo_Url = res.Url;
        this.entity.ProjectImg = res.Url;
      } else {
        this.$message.error(res.Msg);
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 或 PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 20MB!");
      }
      return isJPG && isLt2M;
    },
    // 下拉
    getSelectList() {
      let arr = ["ProjectType", "BuildingType", "ClimaticZone"];
      arr.forEach((item) => {
        this.$http
          .post(
            "Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey",
            {
              Condition: "DicKey",
              Keyword: item,
            }
          )
          .then((res) => {
            if (res.Success)
              this[item + "List"] = res.Data.DictionaryDetailsList;
          });
      });
    },
    // 更换省份
    changeProvince(e) {
      this.$set(this.entity, "City", "");
      console.log(e);
      let id = this.ProvinceLsit.find((item) => item.Name == e).Id;
      this.getCityLsit(id);
    },
    // 获取省份信息
    async getProvince() {
      await this.$http
        .post("/Base_BasicData/Base_District/GetDistrictList")
        .then((res) => {
          this.ProvinceLsit = res.Data;
        });
    },
    // 获取市/区信息
    async getCityLsit(pid) {
      await this.$http
        .post("/Base_BasicData/Base_District/GetDistrictList?ParentId=" + pid)
        .then((res) => {
          this.CityLsit = res.Data;
        });
    },
    // 项目基本信息
    getProjectInfo() {
      this.loading = true;
      this.$http
        .post("/Base_ProjectWork/Base_Project/GetTheData", {
          id: this.$route.query.id,
        })
        .then(async (res) => {
          if (res.Success) {
            this.entity = res.Data;
            if (this.entity.Province) {
              let id = this.ProvinceLsit.find(
                (item) => item.Name == this.entity.Province
              ).Id;
              await this.getCityLsit(id);

            }
            this.loading = false;
            console.log("获取项目基础信息getProjectInfo", res, this.entity);
          } else {
            this.loading = false;
            this.$message.error(res.Msg);
          }
        });
    },
    // 保存
    save() {
      console.log("获取保存的基础项目信息", this.entity);
      this.loading = true;
      this.$http
        .post("/Base_ProjectWork/Base_Project/UpdateData", this.entity)
        .then((res) => {
          console.log("获取保存结果", res);
          this.loading = false;
          if (res.Success) {
            // this.getProjectInfo();
            // this.disabled = true;
            this.$parent.$parent.entity.ProjectImg = this.entity.ProjectImg;
            this.$emit("infoFormClose", true);
            this.$message.success("保存成功");
            this.changeNum = 1;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
  },
  watch: {
    entity: {
      deep: true,
      handler() {
        this.changeNum++
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// 取消input的上下箭头
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}

.el-form-item {
  margin-bottom: 10px;
}

::v-deep .el-textarea__inner {
  font-family: Arial;
}

.flex {
  display: flex;
}

.flex1 {
  flex: 1;
}

.infoBox {
  width: 1320px;

  .projectImg {
    width: 280px;
    height: 280px;

    .image-slot {
      text-align: center;
      line-height: 280px;
    }
  }

  .divContent {
    background: #fff;
    padding: 0 10px;
    border-radius: 3px;
    width: 100%;
    min-height: 40px;
    box-sizing: border-box;
    box-shadow: 1px 1px 5px #ccc;
    display: flex;
    justify-content: space-between;
    line-height: 2;
    align-items: center;

    .color {
      color: #c0c4cc;
    }
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  background-color: white;
  width: 280px;
  height: 280px;
  line-height: 280px;
  text-align: center;
}

.avatar {
  width: 280px;
  height: 280px;
  display: block;
}
</style>
