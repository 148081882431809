<template>
  <div>
    <el-dialog :title="title" :visible.sync="StatusVisible" width="50%" :show-close="false">
      <div slot="title" style="text-align-last: left;">
        <el-row style="height: 33px;text-align: center;font-weight: bold;font-size: 20px;">
          {{ title }}
          <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;float: right;"
            src="@/assets/delete.png" alt="" @click="StatusVisible = false" />
        </el-row>
      </div>
      <div style="width: 80%;margin-left: 10%;">
        <el-steps :active="StatusOptionsActive" align-center>
          <el-step title="其它" icon="el-icon-d-arrow-right" description="默认状态"></el-step>
          <el-step v-for="(item, index) in StatusOptions" :key="item.DicValue" :title="item.DicName"
            :description="item.ChangeTime ? item.ChangeTime.substring(0, 10) : ''">
            <b slot="icon">{{ index + 1 }}</b>
          </el-step>
        </el-steps>
      </div>
      <div style="margin-left: calc((100% - 300px) /2);height:80px;">
        <div style="margin-top: 60px;font-size: 18px;font-weight: bold;line-height: 30px;display: flex;">
          <div style="width: 80px;text-align: right;">
            状态：
          </div>
          <span style="margin-left: 10px;;width: 220px;" v-if="StatusOptionsActive != 0">
            {{
              StatusOptions[StatusOptionsActive - 1] ?
                StatusOptions[StatusOptionsActive - 1].DicName : ""
            }}
          </span>
          <span style="margin-left: 10px;;width: 220px;" v-else>
            其它
          </span>
        </div>
        <div style="margin-top: 20px;font-size: 18px;font-weight: bold;line-height: 30px;display: flex;"
          v-if="StatusOptionsActive != 0">
          <div style="width: 80px;text-align: right;">
            <span style="color: red;">*</span>日期：
          </div>
          <el-date-picker v-if="StatusOptions[StatusOptionsActive - 1]"
            v-model="StatusOptions[StatusOptionsActive - 1].ChangeTime" type="date" placeholder="选择日期"
            format="yyyy-MM-dd" value-format="yyyy-MM-dd" size="mini" style="margin-left: 10px;width: 220px;"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <el-button @click="StatusVisible = false">取 消</el-button>
        <el-button @click="StatusOptionsActive--" v-if="StatusOptionsActive > 0">上一步</el-button>
        <el-button @click="nextStep()" v-if="StatusOptions.length !== (StatusOptionsActive)">下一步</el-button>
        <el-button type="primary" @click="submitEngineeringStatus">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  data() {
    return {
      StatusVisible: false,
      StatusOptionsActive: 0,
      StatusOptions: [],
      StatusData: [],
      time: '',
      ModuleType: '',
      ShopId: '',
      row: {},
      title: "",
    };
  },
  props: {

    data: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  methods: {
    openDialog(row, ModuleType, data, title) {
      this.title = title
      this.row = row
      this.ModuleType = ModuleType
      this.StatusOptions = JSON.parse(JSON.stringify([...data]))
      this.getDataList()
      this.StatusVisible = true

    },
    getDataList() {
      this.$http.post('/Shop_Manage/Shop_StatusRecord/GetDataListByMoreCondition', {
        "Search": [
          {
            "Condition": "ShopId",
            "Keyword": this.row.Id
          },
          {
            "Condition": "ModuleType",
            "Keyword": this.ModuleType
          },
        ]
      }).then(res => {
        if (res.Success) {
          this.StatusData = res.Data || []
          if (this.StatusData.length > 0) {
            this.StatusOptions.forEach(item => {
              let obj = this.StatusData.find(val => {
                return val.Status === item.DicName
              })
              if (obj) {
                this.$set(item, 'ChangeTime', obj.ChangeTime)
              }
            })
            this.StatusOptionsActive = this.StatusData.length
          } else {
            this.StatusOptionsActive = 0

          }
        }
      })
    },
    submitEngineeringStatus() {
      if (this.StatusOptionsActive != 0) {
        if (!this.StatusOptions[this.StatusOptionsActive - 1].ChangeTime) {
          this.$message.warning('请选择日期')
          return
        }
      }
      let arr = this.StatusOptions.slice(0, this.StatusOptionsActive).map(item => {
        return {
          "Status": item.DicName,
          "ChangeTime": item.ChangeTime
        }
      })
      this.$emit('submit', {
        ModuleType: this.ModuleType,
        ShopId: this.row.Id,
        StatusRecords: arr
      })
      this.StatusVisible = false
    },
    nextStep() {
      if (this.StatusOptionsActive == 0) {
        this.StatusOptionsActive++
        return
      }
      if (this.StatusOptions[this.StatusOptionsActive - 1].ChangeTime) {
        this.StatusOptionsActive++
      } else {
        this.$message.warning('请选择日期')
      }

    },
  },
  mounted() {
  },
  created() {
  },
  computed: {
    pickerOptions() {
      return {
        disabledDate: (date) => {
          if (this.StatusOptions[this.StatusOptionsActive - 2]) {
            return moment(date) < moment(this.StatusOptions[this.StatusOptionsActive - 2].ChangeTime)
          } else {
            return false
          }

        },

      }
    },
  },

};
</script>
<style lang='scss' scoped>
::v-deep .el-steps {
  font-weight: bold;
}

::v-deep .el-step__head.is-finish {
  color: #D1100B;
  border-color: #D1100B;
}

::v-deep .el-steps .is-finish .el-step__line {
  background: #D1100B;
}

::v-deep .el-steps .is-process {
  color: #D1100B;
  border-color: #D1100B;
}
</style>
