<template>
  <!--  弱电部分-->
  <div>
    <!--    空调（提供/不提供）-->
    <div class="flex" style="border: 2px solid #000">
      <div class="flex8 flexCol">
        <!--              顶部备注区域布局站位-->
        <div class="flex" style="height: 22px">
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
        </div>
        <!--        内容区域 这里的height 30px 和下拉列表数据点额高度一致 否则回出现高度不一致情况-->
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 flexAcneter borderTop font16">
              <span> 电话/网络</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="NetworkPointsAndCables"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange($event, 'NetworkPointsAndCablesSelectValue')
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span> 进线规格</span>
            </div>
            <div
              v-if="parentObj.entity.WeakCurrentBoxInfo"
              class="flex5 height30 borderTop borderRight"
            >
              <div
                v-if="parentObj.entity.WeakCurrentBoxInfo.length > 0"
                class="flex5 height30 borderTop borderRight"
              >
                {{
                  parentObj.entity.WeakCurrentBoxInfo[0].CableTVPointsAndCables
                }}
              </div>
            </div>
            <div v-else class="flex5 height30 borderTop borderRight"></div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <span> 有线电视</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="CableTVPointsAndCables"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange($event, 'CableTVPointsAndCablesSelectValue')
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span> 进线规格</span>
            </div>
            <div
              v-if="parentObj.entity.WeakCurrentBoxInfo"
              class="flex5 height30 borderTop borderRight font16"
            >
              <div
                v-if="parentObj.entity.WeakCurrentBoxInfo.length > 0"
                class="flex5 height30 borderTop borderRight font16"
              >
                {{
                  parentObj.entity.WeakCurrentBoxInfo[0].NetworkPointsAndCables
                }}
              </div>
            </div>
            <div
              v-else
              class="flex5 height30 borderTop borderRight font16"
            ></div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderBottom font16">
              <span>Pos系统</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="POSPointsAndCables"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange($event, 'POSPointsAndCablesSelectValue')
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight borderBottom">
              <span></span>
            </div>
            <div
              class="flex5 height30 borderTop borderRight borderBottom"
            ></div>
          </div>
        </div>
      </div>
      <div id="shopInfoRemark flex flex2 ">
        <div class="text-center font16">备注</div>
        <div>
          <div>
            <InputComponent
              ref="RemarkNetworkPointsAndCablesInput"
              :placeHolder="'请输入备注'"
              :rows="1"
              :type="'textarea'"
              @input="inputChange($event, 'RemarkNetworkPointsAndCables')"
            ></InputComponent>
          </div>

          <div>
            <InputComponent
              ref="RemarkCableTVPointsAndCablestInput"
              :placeHolder="'请输入备注'"
              :rows="1"
              :type="'textarea'"
              @input="inputChange($event, 'RemarkCableTVPointsAndCablest')"
            ></InputComponent>
          </div>
          <div>
            <InputComponent
              ref="RemarkPOSPointsAndCablesInput"
              :placeHolder="'请输入备注'"
              :rows="1"
              :type="'textarea'"
              @input="inputChange($event, 'RemarkPOSPointsAndCables')"
            ></InputComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputComponent from "@/views/Project/view/components/inputComponents/inputComponent.vue";
// 模拟数据
import {
  dianbiaoOptions,
  dianbiaoWeizhiOptions,
  kongtiaoguanzhiOptions,
} from "../../mock.js";
export default {
  props: {
    parentObj: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  components: { InputComponent },
  data() {
    return {
      // 输入框绑定的值
      selectValue: "提供",
      // 电表区域备注绑定
      RemarkElectrical: "",
      // 电表下拉列框绑定列别
      dianOtions: dianbiaoOptions,
    };
  },
  methods: {
    input(row) {
      // 判断相关备注和下拉提供非提供逻辑  如果没有对应的数组  提供  否则 不提供
      this.$refs.NetworkPointsAndCables.value = "";
      this.$refs.CableTVPointsAndCables.value = "";
      this.$refs.POSPointsAndCables.value = "";
      this.$refs.NetworkPointsAndCables.value =
        row.NetworkPointsAndCablesSelectValue; //弱电网络提供非提供
      this.$refs.CableTVPointsAndCables.value =
        row.CableTVPointsAndCablesSelectValue; //弱电有线提供非提供
      this.$refs.POSPointsAndCables.value = row.POSPointsAndCablesSelectValue; //弱电POS提供非提供
      this.$refs.RemarkNetworkPointsAndCablesInput.keyWords =
        row.RemarkNetworkPointsAndCables; //弱电网络备注
      this.$refs.RemarkCableTVPointsAndCablestInput.keyWords =
        row.RemarkCableTVPointsAndCablest; //弱电网络备注
      this.$refs.RemarkPOSPointsAndCablesInput.keyWords =
        row.RemarkPOSPointsAndCables; //弱电网络备注
    },
    // 相关下拉参数处理回调
    inputSelectChange(event, type) {
      this.parentObj.openSetPrintSelectValue(event, type);
    },
    // 相关备注参数处理回调
    inputChange(event, type) {
      this.parentObj.openSetPrintInputValue(event, type);
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
/*覆盖card样式*/
.el-card {
  border: none !important;
}
/*取消card的阴影*/
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}

/*覆盖element card 卡片样式*/
::v-deep .recordContent {
  .el-card {
    .el-card__body {
      padding: 0px !important;
    }
  }
}
</style>
