<template>
  <!--  巡检记录弹窗 组件-->
  <el-drawer title="巡检记录" :visible.sync="visible" direction="rtl" :size="600">
    <template slot="title">
      <div class="ellipsis1" style="font-size: 18px;font-weight: bold;" :title="row.Name ? row.Name + ' 巡检记录' : '巡检记录'">
        {{ row.Name ?
          row.Name + ' 巡检记录' : '巡检记录' }}</div>
    </template>
    <!-- 巡检记录 -->
    <div style="padding: 20px;">


      <el-tabs type="card" v-model="activeName" v-loading="loading && TASKloading">
        <el-tab-pane label="我的记录" name="1">
          <div v-for="(aItem, aIndex) in RecordingList" :key="aIndex" style="padding: 5px;">
            <!-- <div class="taskName">{{ aItem.Name }}</div> -->
            <el-card class="one" v-for="(item, index) in aItem.TakeNotesList" :key="index">
              <div class="head" style="">

                <img class="man" :src="item.UserIcon" alt="" />
                <div class="textbox">
                  <div>
                    {{ item.CreatorRealName || item.Phone }}
                  </div>
                  <div>
                    <div style="text-align: right;">
                      <el-tag type="success" size="small" v-if="item.IsEnjoy">已共享</el-tag>
                      <el-tag type="info" size="small" v-else>未共享</el-tag>
                    </div>
                    <div>
                      {{ item.CreateTime.split(" ")[0] }}
                    </div>
                  </div>


                </div>
              </div>
              <div class="contents">
                <div class="cone">
                  <p v-for="(val, vix) in item.PropData" :key="vix">
                    {{ val.ControlName }}:
                    {{ val.DefaultValue }}
                  </p>
                </div>
                <p style="margin-top: 10px">描述： {{ item.Content }}</p>
              </div>
              <div class="tu">
                <!--           controlslist="nodownload"   //内部视频，取消下载功能  暂时不删除后续需要打开-->
                <div v-for="(videoImg, index) in item.MediumList" :key="index"
                  style="width: 35%;height:100px; margin-bottom:35px ;">
                  <video style="height:120px;width: 82%" v-if="videoImg.Mtype === 'video'" controls :src="videoImg.Murl"
                    width="82%" height="120px" autoplay="0"></video>
                  <el-image v-else :key="videoImg.url" :src="videoImg.Murl" :preview-src-list="imgList"
                    fit="contain"></el-image>

                </div>
              </div>
            </el-card>
            <el-empty description="暂无记录" v-if="aItem.TakeNotesList.length == 0"></el-empty>
          </div>
          <el-empty description="暂无记录" v-if="RecordingList.length == 0"></el-empty>
        </el-tab-pane>
        <el-tab-pane label="任务记录" name="2">
          <div v-for="(aItem, aIndex) in RecordingListTASK" :key="aIndex" style="padding: 5px;">
            <!-- <div class="taskName">{{ aItem.Name }}</div> -->
            <el-card class="one" v-for="(item, index) in aItem.TakeNotesList" :key="index">
              <div class="head" style="">
                <img class="man" :src="item.UserIcon" alt="" />
                <div class="textbox">
                  <div>
                    {{ item.CreatorRealName || item.Phone }}
                  </div>
                  <div>
                    {{ item.CreateTime.split(" ")[0] }}
                  </div>
                </div>
              </div>
              <div class="contents">
                <div class="cone">
                  <p v-for="(val, vix) in item.PropData" :key="vix">
                    {{ val.ControlName }}:
                    {{ val.DefaultValue }}
                  </p>
                </div>
                <p style="margin-top: 10px">描述： {{ item.Content }}</p>
              </div>
              <div class="tu">
                <!--           controlslist="nodownload"   //内部视频，取消下载功能  暂时不删除后续需要打开-->
                <div v-for="(videoImg, index) in item.MediumList" :key="index"
                  style="width: 35%;height:100px; margin-bottom:35px ;">
                  <video style="height:120px;width: 82%" v-if="videoImg.Mtype === 'video'" controls :src="videoImg.Murl"
                    width="82%" height="120px" autoplay="0"></video>
                  <el-image v-else :key="videoImg.url" :src="videoImg.Murl" :preview-src-list="imgListTASK"
                    fit="contain"></el-image>

                </div>
              </div>
            </el-card>
            <el-empty description="暂无记录" v-if="aItem.TakeNotesList.length == 0"></el-empty>
          </div>
          <el-empty description="暂无记录" v-if="RecordingListTASK.length == 0"></el-empty>
        </el-tab-pane>
      </el-tabs>

    </div>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      activeName: '1',
      visible: false,
      loading: false,
      TASKloading: false,
      RecordingList: [],
      imgList: [],
      RecordingListTASK: [],
      imgListTASK: [],
      row: {}
    };
  },
  methods: {
    openForm(obj) {
      console.log(obj)
      this.row = { ...obj }
      this.visible = true;
      this.activeName = '1'
      this.RecordingListTASK = []
      this.imgListTASK = []
      this.RecordingList = []
      this.imgList = []
      this.loading = true;
      this.TASKloading = true
      try {
        this.$http
          .post("/Base_ProjectWork/Base_Project/GetTakeJsonData", {
            // PageIndex: 1,
            // PageRows: 1,
            SortField: "CreateTime",
            SortType: "desc",
            Search: {
              ProjectId: obj.ProjectId,
              TaskId: obj.Id,
            },
          })
          .then((res) => {
            this.loading = false;

            if (res.Success) {
              console.log('获取巡检记录列表数据', res.Data)
              this.RecordingList = res.Data;
              this.imgList = res.ListDetail.map((item) => item.Murl);
            } else {
              this.RecordingList = [];
              this.imgList = [];
            }
          });

        this.$http
          .post("/Base_ProjectWork/Base_Project/GetProjectTakeJsonData", {
            // PageIndex: 1,
            // PageRows: 1,
            SortField: "CreateTime",
            SortType: "desc",
            Search: {
              ProjectId: obj.ProjectId,
              TaskId: obj.Id,
            },
          })
          .then((res) => {
            this.TASKloading = false
            if (res.Success) {
              // console.log('获取巡检记录列表数据', res.Data)
              this.RecordingListTASK = res.Data;
              this.imgListTASK = res.ListDetail.map((item) => item.Murl);
            } else {
              this.RecordingListTASK = [];
              this.imgListTASK = [];
            }
          });

      } catch (error) {
        this.loading = false;
        this.TASKloading = false
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.taskName {
  color: #336dba;
  font-weight: bold;
  font-size: 20px;
  padding-left: 12px;
  line-height: 2;
  // border-bottom: 1px solid #ccc;
  // margin-bottom: 10px;
}

.one {
  padding: 12px;
  padding-top: 0;
  margin-bottom: 12px;

  .head {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .man {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .textbox {
      width: calc(100% - 45px);
      // margin-top: -52px;
      // margin-left: 13%;
      // background-color: red;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .contents {
    width: 100%;
    padding-left: 44px;
    box-sizing: border-box;

    .cone {
      display: flex;
      flex-wrap: wrap;

      p {
        width: 50%;
        margin: 5px 0;
      }
    }
  }

  .tu {
    width: 100%;
    padding-left: 44px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

    .el-image {
      width: 120px;
      height: 120px;
      margin-right: 10px;
      border: 1px solid #ccc;
      padding: 5px;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
}

::v-deep .el-drawer__header {
  margin-bottom: 0;
}

::v-deep .el-tabs__content {
  height: calc(100vh - 130px);
  overflow-y: auto;
}

::v-deep .el-tabs__header {
  margin-bottom: 0;
}

.ellipsis1 {
  overflow: hidden; //超出隐藏
  word-wrap: break-word;
  word-break: normal;
  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

  -webkit-line-clamp: 1; //内容限制的行数
}
</style>
