<template>
  <div class="box" style="overflow: hidden;">
    <div style="display: flex;">
      <div
        style="display: flex;align-items: center;justify-content: center;height: 35px;line-height: 35px;width: 40px;">
        <i size="30" class="el-icon-back" style="font-size: 23px;cursor: pointer;" @click="handleBack"></i>
      </div>
      <div style="font-size: 20px;font-weight: bold;line-height: 35px; margin-left: 20px;">数据分析-商铺变更原因信息</div>
    </div>
    <!-- body-style="display: flex;align-items: center;height: 100px;padding: 0 10px;padding: 0px 10px;;text-align: center;background-color:#D1100B;height: 90px;" -->
    <el-card body-style="padding:0;">
      <el-table :data="tableData" size="mini" height="90" max-height="90" style="width: 100%;">
        <el-table-column v-for="(item, index) in tableColumn" :key="index" :label="item.ShopChangeReason"
          :prop="item.ShopChangeReason">
        </el-table-column>
      </el-table>
      <!-- <div
        style="display: flex;align-items: center;justify-content: center;height: 60px;line-height: 30px;width: 40px;">
        <i size="30" class="el-icon-back" style="font-size: 23px;cursor: pointer;" @click="handleBack"></i>
      </div> -->


    </el-card>
    <el-card style="width: 100%;" v-loading="MG_ShopChangesReturnDataLoading" class="InfoCard"
      body-style="padding:0px 10px">
      <el-row style="font-size: 18px;font-weight: bold;border-bottom:1px solid #E6E6E6;line-height: 30px; ">
        不同业态商铺变更原因信息统计
      </el-row>
      <EchartsIndex :option="Shop_SCRD_Count" style="width: 100%;height: 295px;"
        v-if="this.MG_ShopChangesReturnData.Shop_SCRD_Count && this.MG_ShopChangesReturnData.Shop_SCRD_Count.length > 0" />
      <el-empty description="暂无数据" style="width: 100%; height: 295px;" v-else></el-empty>
    </el-card>
    <el-card style="width: 100%;" v-loading="MG_ShopChangesReturnDataLoading" class="InfoCard"
      body-style="padding:0px 10px">
      <el-row style="font-size: 18px;font-weight: bold;border-bottom:1px solid #E6E6E6;line-height: 30px; ">
        商铺变更原因随时间变化统计
      </el-row>
      <EchartsIndex :option="Shop_SCRD_Line" style="width: 100%;height: 295px;"
        v-if="this.MG_ShopChangesReturnData.Shop_SCRD_Line && this.MG_ShopChangesReturnData.Shop_SCRD_Line.length > 0" />
      <el-empty description="暂无数据" style="width: 100%; height: 295px;" v-else></el-empty>
    </el-card>
    <!-- <el-row :gutter="10" style="margin-top: 5px;">
      <el-col :span="8" v-for="(item, index) in listArr" :key="index" style="margin-bottom: 10px;">
        <el-card style="width: 100%;">
          <EchartsIndex :option="item" style="width: 100%;height: 300px;" />
        </el-card>
      </el-col>
    </el-row> -->
    <div>

    </div>
  </div>
</template>

<script>
// 引入echarts图表组件
import EchartsIndex from '@/components/Echarts/index';

export default {
  name: "MG_ShopChangesDataPage", // 商铺变更原因数据
  components: {
    EchartsIndex
  },
  props: {
    typeList: {
      type: Array,
      default: () => []
    },
    projectId: {
      type: String,
      default: ''
    },
    homeData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      MG_ShopChangesReturnData: {},
      MG_ShopChangesReturnDataLoading: false,
      Shop_SCRD_Count: {
        grid: {
          left: 140,
          top: 50,
          bottom: 40
        },
        yAxis: {
          name: '变更原因',
          nameLocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          type: "category",
          data: []
        },
        label: {
          show: true,
          position: "inside",
          formatter: (a) => {
            if (a.value > 0) {
              return `${a.value}次`;
            } else {
              return ''
            }
          },
        },
        xAxis: {
          minInterval: 1,
          name: '变更次数',
          nameLocation: 'center',
          nameGap: 25,
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "value",

        },
        tooltip: {},
        legend: {
          //图例系列
          show: true,
          top: 5,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        series: [],
      },
      Shop_SCRD_Line: {
        grid: {
          top: 50, // 设置图表距离容器顶部的距离为10%\
          left: 140,
          bottom: 80
        },
        xAxis: {
          name: '日期信息',
          nameLocation: 'center',
          nameGap: 65,
          show: true,
          type: "category",
          // boundaryGap: false,
          axisLabel: {
            // 坐标轴刻度标签的相关设置。
            show: true,
            interval: 0,
            rotate: "-45",
          },
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} 次",
          },
          minInterval: 1,
          name: '变更次数',
          nameLocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          type: "value",
        },
        tooltip: {
          trigger: "item",

          formatter: (a) => {
            return a.name.substring(0, 10) + "<br/>" + a.seriesName + " : " + a.value + '次';
          },
        },
        legend: {
          //图例系列
          show: true,
          top: 5,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        series: [],
      },
      tableData: [
      ],
      tableColumn: [
      ],

    }
  },
  created() {
    this.getMG_ShopChangesReturnData()
  },
  watch: {
    homeData: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val)
        if (val.SCRT_Shop && val.SCRT_Shop.length > 0) {
          this.tableColumn = [
            {
              ShopChangeReason: "数量/占比",
            },
            ...val.SCRT_Shop,
          ];
          let numObj = {
            "数量/占比": "数量",
          };
          let areaObj = {
            "数量/占比": "占比",
          };
          val.SCRT_Shop.forEach((item) => {
            this.$set(numObj, item.ShopChangeReason, item.ShopChangeCount ? item.ShopChangeCount + '次' : '0次');
            this.$set(areaObj, item.ShopChangeReason, item.ShopChangeProportion ? item.ShopChangeProportion.toFixed(2) + '%' : '0%');
          });
          this.tableData = [numObj, areaObj];
        }
      }
    }
  },
  methods: {
    getMG_ShopChangesReturnData() {
      this.MG_ShopChangesReturnDataLoading = true
      this.$http
        .post("/Base_ProjectManage/Project_DataAnalysis/GetMG_ShopChangesReturnData?projectId=" + this.projectId,).then(res => {
          this.MG_ShopChangesReturnDataLoading = false
          if (res.Success) {
            this.MG_ShopChangesReturnData = res.Data || {}
            if (this.MG_ShopChangesReturnData.Shop_SCRD_Count && this.MG_ShopChangesReturnData.Shop_SCRD_Count.length > 0) {
              this.Shop_SCRD_Count.legend.data = []
              this.Shop_SCRD_Count.series = []
              this.Shop_SCRD_Count.yAxis.data = []
              this.MG_ShopChangesReturnData.Shop_SCRD_Count[0].value.forEach(item => {
                this.Shop_SCRD_Count.yAxis.data.push(item.name)
              })
              this.MG_ShopChangesReturnData.Shop_SCRD_Count.forEach(item => {

                this.Shop_SCRD_Count.legend.data.push(item.name)
                this.Shop_SCRD_Count.series.push({
                  name: item.name,
                  type: "bar",
                  emphasis: {
                    focus: "series",
                  },
                  barGap: '100%',
                  stack: "total",
                  data: item.value,
                })
              })
              if (this.Shop_SCRD_Count.legend.data.length > 48) {
                let num = Math.ceil(this.Shop_SCRD_Count.legend.data.length / 23)
                let topnum = 0
                for (let i = 0; i < num; i++) {
                  topnum = 30 + topnum
                }
                this.Shop_SCRD_Count.grid.top = topnum + 20
              } else {
                this.Shop_SCRD_Count.grid.top = 70
              }
            }
            if (this.MG_ShopChangesReturnData.Shop_SCRD_Line && this.MG_ShopChangesReturnData.Shop_SCRD_Line.length > 0) {
              this.Shop_SCRD_Line.xAxis.data = []
              this.Shop_SCRD_Line.series = []
              this.Shop_SCRD_Line.legend.data = []
              this.MG_ShopChangesReturnData.Shop_SCRD_Line[0].value.forEach(item => {
                this.Shop_SCRD_Line.xAxis.data.push(item.name ? item.name.substring(0, 10) : '')
              })
              this.MG_ShopChangesReturnData.Shop_SCRD_Line.forEach(item => {
                this.Shop_SCRD_Line.legend.data.push(item.name)
                this.Shop_SCRD_Line.series.push({
                  name: item.name,
                  type: "line",
                  smooth: true,
                  data: item.value,
                  areaStyle: {},
                })
              })
            }


          } else {
            this.MG_ShopChangesReturnData = {}
            this.$message.error("获取商铺变更原因数据失败")
          }
        }).catch(() => {
          this.MG_ShopChangesReturnDataLoading = false
          this.MG_ShopChangesReturnData = {}
          this.$message.error("获取商铺变更原因数据失败")
        })

    },

    handleBack() {
      this.$emit('changePageblock', '');
    },
  },

}
</script>


<style scoped lang="scss">
.box {
  padding: 0px;
  box-sizing: border-box;
}

::v-deep .el-table th {
  background-color: #D1100B;
  color: #fff;

}

.InfoCard {
  margin-top: 5px;
  border-radius: 10px;
  padding: 0px 5px;
  box-sizing: border-box;
  border: 5px solid #c3c3c3;
}

::v-deep .el-table th {
  background-color: #D1100B;
  color: #fff;
}

::v-deep .el-table__body-wrapper {
  background-color: #D1100B;
}

::-webkit-scrollbar {
  width: 0px;

  background: transparent;
}

::v-deep .el-table td {
  background-color: #D1100B;
  padding: 0 !important;
  color: #fff;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table {

  //可设置胶囊宽高
  ::-webkit-scrollbar {
    height: 12px;
    width: 10px;
  }

  // 设置胶囊色
  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #f2706b;
  }

  // ::-webkit-scrollbar-thumb:hover {

  // }
}

::v-deep .el-table__row:hover td {
  background-color: #f2706b !important;
}
</style>
