var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"board"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.LeftMenuShow),expression:"LeftMenuShow"}],staticClass:"taskMenu",style:({ width: _vm.width + 'px' })},[_vm._l((_vm.TaskMenu),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.width),expression:"width"}],key:item.name,class:_vm.menuKey == index ? 'menuItem menuActive' : 'menuItem',on:{"click":function($event){return _vm.menuTaskChange(item, index)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),_c('div',{staticClass:"shrink",on:{"click":_vm.shrink}},[_c('i',{class:_vm.width ? 'el-icon-s-fold' : 'el-icon-s-unfold'})])],2),_c('div',{staticStyle:{"flex":"1","margin-left":"10px"}},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.curValue),callback:function ($$v) {_vm.curValue=$$v},expression:"curValue"}},_vm._l((_vm.List),function(item,index){return _c('el-tab-pane',{key:item.Id,attrs:{"label":item.ApplyName,"name":item.ApplyCode}})}),1),(_vm.List.length > 0)?_c('div',[(!_vm.TaskInfoShow)?_c('TaskList',{ref:"TaskList",attrs:{"taskObj":{
        ProjectId: _vm.pId || _vm.$route.query.id,
        AppyId: _vm.List[_vm.ListIndex].Id,
        AppyName: _vm.List[_vm.ListIndex].ApplyName,
        ApplyCode: _vm.List[_vm.ListIndex].ApplyCode,
        ClassType: _vm.menuKey
      },"ClassType":_vm.menuKey},on:{"task":_vm.changeTask}}):_vm._e(),_c('PatGoForm',{ref:"PatGoForm"}),(_vm.TaskInfoShow && _vm.curValue == '10002')?_c('AttractInvestment',{ref:"AttractInvestment",attrs:{"taskObj":{
        ProjectId: _vm.pId || _vm.$route.query.id,
        AppyId: _vm.List[_vm.ListIndex].Id,
        AppyName: _vm.List[_vm.ListIndex].ApplyName,
        ApplyCode: _vm.List[_vm.ListIndex].ApplyCode,
        TaskId: _vm.TaskId
        ,
      }},on:{"task":_vm.changeTask}}):_vm._e(),(_vm.TaskInfoShow && _vm.curValue == '10004')?_c('RGList',{ref:"RGList",attrs:{"taskObj":{
        ProjectId: _vm.pId || _vm.$route.query.id,
        AppyId: _vm.List[_vm.ListIndex].Id,
        AppyName: _vm.List[_vm.ListIndex].ApplyName,
        ApplyCode: _vm.List[_vm.ListIndex].ApplyCode,
        TaskId: _vm.TaskId

      }},on:{"task":_vm.changeTask}}):_vm._e(),(_vm.TaskInfoShow && _vm.curValue == '10005')?_c('SchemeStageMainList',{ref:"SchemeStageMainList",attrs:{"taskObj":{
        ProjectId: _vm.pId || _vm.$route.query.id,
        AppyId: _vm.List[_vm.ListIndex].Id,
        AppyName: _vm.List[_vm.ListIndex].ApplyName,
        ApplyCode: _vm.List[_vm.ListIndex].ApplyCode,
        TaskId: _vm.TaskId,
        TaskTypeKey: _vm.TaskTypeKey
      }},on:{"task":_vm.changeTask}}):_vm._e()],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }