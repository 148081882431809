<template>
    <div>
        <div style="margin-bottom: 10px; width: 100%;">
            <div style="
            padding-bottom: 10px;
            border-bottom: 1px solid #d3cbcb;
            margin-bottom: 10px;
          ">
                <div style="display: flex;align-items: center;line-height: 26px;">
                    <el-button size="mini" type="primary" style="margin-right: 10px" @click="backTask">返回</el-button>
                    <div>
                        <el-select style="" class="marginRight" v-model="taskDataSelect" placeholder="请选择筛任务"
                            size="mini" @change="taskOptionsChange" filterable default-first-option>
                            <el-option v-for="item in taskOptions" :key="item.Id" :label="item.Name" :value="item.Id">
                            </el-option>
                        </el-select>
                    </div>
                    <el-button size="mini" style="margin-left: 10px;" @click="refresh">刷新</el-button>
                    <div style="display: flex;align-items: center;">
                        <span style="margin-left: 30px;font-size: 14px;font-weight: bold;">当前报审单位：</span>
                        <span>{{ SingleConditionList.length > 0 ? SingleConditionList[0].ReportingUnit : '暂无' }}</span>
                    </div>
                    <div>
                        <span style="margin-left: 30px;font-size: 14px;font-weight: bold;">报审手机号：</span>
                        <span>{{ SingleConditionList.length > 0 ? SingleConditionList[0].ContactPhone : '暂无' }}</span>
                    </div>
                    <div style="margin-left: 30px;"><el-button size="mini" type="primary"
                            @click="AuditFlag = true">查看报审单位</el-button>
                        <el-button size="mini" type="primary" @click="AuditOpen('', 3)"
                            v-if="SingleConditionList.length > 0">转发报审链接</el-button>
                    </div>

                </div>
            </div>

            <el-tabs v-model="tabName" type="card">
                <el-tab-pane label="全部" name="全部"></el-tab-pane>
                <el-tab-pane label="我的待审" name="我的待审"></el-tab-pane>
                <el-tab-pane label="我的已审" name="我的已审"></el-tab-pane>
                <el-tab-pane label="任务台账" name="任务台账"></el-tab-pane>
            </el-tabs>
            <div style="width: 100%;" v-if="tabName != '任务台账'">
                <el-row :gutter="20">
                    <el-col :span="11" v-loading="auditMaterialsInfoListLoading">
                        <div style="font-weight: bold;line-height: 30px;">
                            <span>设备/材料报审</span>

                        </div>
                        <div style="height:calc(100vh - 300px);overflow: auto;padding: 0 10px;">
                            <div v-for="(item) in auditMaterialsInfoList" :key="item.id"
                                style="border: 1px solid #D3CBCB;margin-top: 10px;border-radius: 10px;cursor: pointer;padding: 10px 0px;position: relative;"
                                class="auditMaterialsInfoList" @click="editAuditOpen(item, 1)">
                                <el-row style="display: flex;align-items: center;font-size: 14px;">
                                    <div style="width: 84px;text-align: right;">
                                        <el-tag
                                            style="font-weight: bold;letter-spacing: 1px;margin-left: 10px;text-align: center;"
                                            effect="plain">{{ item.ApprovalType == 1 ? '设备材料' : item.ApprovalType ==
                                                2 ?
                                                '深化图纸' : '' }}
                                        </el-tag>
                                    </div>
                                    <div style="width: calc(100% - 264px);margin-left: 10px;">
                                        <el-row>
                                            <el-col style="line-height: 1.5" class="ellipsis1">
                                                <span style="font-weight: bold;">报审单名称：</span>
                                                <span :title="item.ApprovalName">{{ item.ApprovalName }}</span>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col style="line-height: 1.5" class="ellipsis1">
                                                <span style="font-weight: bold;">报审单编号：</span>
                                                <span :title="item.ApprovalCode">{{ item.ApprovalCode }}</span>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col style="line-height: 1.5" class="ellipsis1">
                                                <span style="font-weight: bold;">报审单批次：</span> 第 <span
                                                    style="font-weight: bold;color: #D1100B;">{{ item.ApprovalBatch
                                                    }}</span> 批次
                                            </el-col>
                                        </el-row>

                                    </div>
                                    <div style="width: 160px;margin-left: 10px">
                                        <div style="text-align: center;">
                                            <el-tag style="height: 25px;line-height: 25px;padding: 0 10px;"
                                                type="primary" v-if="item.Status == 0">未提审
                                            </el-tag>
                                            <el-tag type="success"
                                                style="height: 25px;line-height: 25px;padding: 0 10px;"
                                                v-if="item.Status == 1">{{ item.ApprovalBatch == 1 ? '已提审' : '再提审' }}
                                            </el-tag>
                                            <el-tag style="height: 25px;line-height: 25px;padding: 0 10px;" type="info"
                                                v-if="item.Status == 2">审核中
                                            </el-tag>
                                            <el-tag style="height: 25px;line-height: 25px;padding: 0 10px;"
                                                type="danger" v-if="item.Status == 3">已驳回
                                            </el-tag>
                                            <el-tag style="height: 25px;line-height: 25px;padding: 0 10px;"
                                                type="success" v-if="item.Status == 4">已通过
                                            </el-tag>
                                        </div>
                                        <div style="margin-top: 10px;text-align: center;">
                                            <span
                                                style="text-align: center;color: #C0C4CC;font-size: 13px;margin-top: 5px">
                                                {{ item.CreateTime ? item.CreateTime.substring(0, 19) : '' }}
                                            </span>
                                        </div>

                                    </div>

                                </el-row>
                                <div style="position: absolute;right: -3px;top: -4px;z-index: 99;"
                                    v-if="item.IsNew == 0">
                                    <el-image style="width: 50px; height: 50px" :src="newImgUrl" fit="cover"></el-image>
                                </div>

                            </div>
                            <el-empty description="暂无数据" v-if="auditMaterialsInfoList.length == 0"></el-empty>
                        </div>
                    </el-col>
                    <el-col :span="11" :offset="1" v-loading="auditImgInfoListLoading">
                        <div style="font-weight: bold;line-height: 30px;">
                            <span>深化图纸报审</span>
                        </div>

                        <div style="height:calc(100vh - 300px);overflow: auto;padding: 0 10px;">
                            <div v-for="(item) in auditImgInfoList" :key="item.id"
                                style="border: 1px solid #D3CBCB;margin-top: 10px;border-radius: 10px;cursor: pointer;padding: 10px 0px;position: relative;"
                                class="auditMaterialsInfoList" @click="editAuditOpen(item, 2)">
                                <el-row style="display: flex;align-items: center;font-size: 14px;">
                                    <div style="width: 84px;text-align: right;">
                                        <el-tag
                                            style="font-weight: bold;letter-spacing: 1px;margin-left: 10px;text-align: center;"
                                            effect="plain">{{ item.ApprovalType == 1 ? '设备材料' : item.ApprovalType ==
                                                2 ?
                                                '深化图纸' : '' }}
                                        </el-tag>
                                    </div>
                                    <div style="width: calc(100% - 264px);margin-left: 10px;">
                                        <el-row>
                                            <el-col style="line-height: 1.5" class="ellipsis1">
                                                <span style="font-weight: bold;">报审单名称：</span>
                                                <span :title="item.ApprovalName">{{ item.ApprovalName }}</span>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col style="line-height: 1.5" class="ellipsis1">
                                                <span style="font-weight: bold;">报审单编号：</span>
                                                <span :title="item.ApprovalCode">{{ item.ApprovalCode }}</span>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col style="line-height: 1.5" class="ellipsis1">
                                                <span style="font-weight: bold;">报审单批次：</span> 第 <span
                                                    style="font-weight: bold;color: #D1100B;">{{ item.ApprovalBatch
                                                    }}</span> 批次
                                            </el-col>
                                        </el-row>

                                    </div>
                                    <div style="width: 160px;margin-left: 10px">
                                        <div style="text-align: center;">
                                            <el-tag style="height: 25px;line-height: 25px;padding: 0 10px;"
                                                type="primary" v-if="item.Status == 0">未提审
                                            </el-tag>
                                            <el-tag type="success"
                                                style="height: 25px;line-height: 25px;padding: 0 10px;"
                                                v-if="item.Status == 1"> {{ item.ApprovalBatch == 1 ? '已提审' : '再提审' }}
                                            </el-tag>
                                            <el-tag style="height: 25px;line-height: 25px;padding: 0 10px;" type="info"
                                                v-if="item.Status == 2">审核中
                                            </el-tag>
                                            <el-tag style="height: 25px;line-height: 25px;padding: 0 10px;"
                                                type="danger" v-if="item.Status == 3">已驳回
                                            </el-tag>
                                            <el-tag style="height: 25px;line-height: 25px;padding: 0 10px;"
                                                type="success" v-if="item.Status == 4">已通过
                                            </el-tag>
                                        </div>
                                        <div style="margin-top: 10px;text-align: center;">
                                            <span
                                                style="text-align: center;color: #C0C4CC;font-size: 13px;margin-top: 5px">
                                                {{ item.CreateTime ? item.CreateTime.substring(0, 19) : '' }}
                                            </span>
                                        </div>

                                    </div>

                                </el-row>
                                <div style="position: absolute;right: -3px;top: -4px;z-index: 99;"
                                    v-if="item.IsNew == 0">
                                    <el-image style="width: 50px; height: 50px" :src="newImgUrl" fit="cover"></el-image>
                                </div>

                            </div>
                            <el-empty description="暂无数据" v-if="auditImgInfoList.length == 0"></el-empty>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <logbook ref="logbook" v-if="tabName == '任务台账'" :taskObj="taskObj"></logbook>
        </div>

        <!-- //报审单位 -->
        <el-dialog :visible.sync="AuditFlag" width="60%" append-to-body :show-close="false" :close="AuditClose"
            custom-class="Audit-Dialog">
            <div slot="title">
                <div style="line-height: 33px;display: flex;justify-content: space-between;">
                    <div style="width: 100%;text-align: center;font-weight: bold;font-size: 18px;">
                        报审单位
                    </div>
                    <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;" src="@/assets/delete.png"
                        alt="" @click="AuditClose" />
                </div>
            </div>
            <el-card class="Audit-el-card">
                <div slot="header" style="line-height: 30px;font-weight: bold;padding: 0;margin: 0;" class="">
                    添加报审单位
                </div>
                <div>
                    <el-form :model="AuditForm" ref="AuditForm" label-width="120px" class="demo-AuditForm">
                        <el-row :gutter="20">
                            <el-col :span="10">
                                <el-form-item label="提报单位：">
                                    <el-input v-model="AuditForm.ReportingUnit"></el-input>
                                </el-form-item>

                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="报审手机号：">
                                    <el-input v-model="AuditForm.ContactPhone" type="number"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="4">
                                <el-button type="primary" @click="AuditFormSubmitSave(AuditForm)">
                                    添加
                                </el-button>

                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
            <el-card style="height: 50vh;overflow-y: auto;overflow-x: hidden;width: 100%;margin-top: 10px;"
                class="SingleConditionList Audit-el-card" body-style="padding: 0 20px;">
                <div slot="header" style="line-height: 30px;font-weight: bold;padding: 0;margin: 0;" class="">
                    报审单位列表
                </div>
                <div>
                    <el-divider></el-divider>
                    <div v-for="(item) in SingleConditionList" :key="item.Id">
                        <el-row :gutter="20" style="line-height: 40px;">
                            <el-col :span="20">
                                <el-row :gutter="20">
                                    <el-col :span="10">
                                        <div style="font-size: 16px;line-height: 40px;">
                                            <span
                                                style="display: inline-block;width: 110px;text-align: right;font-weight: bold;">报审单位：</span><span>{{
                                                    item.ReportingUnit }}</span>
                                        </div>
                                    </el-col>
                                    <el-col :span="10">
                                        <div style="font-size: 16px;line-height: 40px;">
                                            <span
                                                style="display: inline-block;width: 110px;text-align: right;font-weight: bold;">
                                                报审手机号：</span><span>{{
                                                    item.ContactPhone }}</span>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div style="font-size: 16px;line-height: 40px;">
                                            <span
                                                style="display: inline-block;width: 110px;text-align: right;font-weight: bold;">已报审次数：
                                            </span><span>{{
                                                item.ApprovalInfoCount }}</span>
                                        </div>

                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="4" style="text-align: center;">
                                <el-button type="primary" @click="AuditFormDelete(item)" icon="el-icon-delete"
                                    size="small" :disabled="item.ApprovalInfoCount > 0">
                                    删除
                                </el-button>
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>
                    </div>
                </div>
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button @click="AuditClose">关闭</el-button>

            </div>

        </el-dialog>
        <!-- 报审单位链接 -->
        <el-dialog :visible.sync="qrcodeVisible" width="50%" append-to-body :show-close="false">
            <div slot="title">
                <el-row style="height: 33px;text-align: center;font-weight: bold;font-size: 18px;">
                    请将链接转发至报审单位人员进行报审
                    <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;float: right;"
                        src="@/assets/delete.png" alt="" @click="qrcodeVisible = false" />
                </el-row>
            </div>
            <div style="display: flex;justify-content: space-around;align-items: center;height: 200px;">
                <div>
                    <span>点击链接复制：</span><br>
                    <span style="color: #D1100B;cursor: pointer;" @click="copyUrl(qrcode)"> {{ qrcode }}</span>
                </div>
                <div>
                    <vue-qr :text="qrcode" :logoScale="0.2" :size="200"></vue-qr>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="qrcodeVisible = false">关闭</el-button>

            </span>
        </el-dialog>
        <!-- //查看报审 -->

        <el-dialog :visible.sync="ListAuditFlag" width="80%" append-to-body :show-close="false"
            :close="ListAuditFlagClose" :custom-class="fullscreen ? '' : 'ViewAudit'" :fullscreen="fullscreen">
            <div slot="title">
                <el-row style="font-size: 18px;font-weight: bold;color:#606266;display: flex;">
                    <div style="width: calc(100% - 33px);text-align: center;line-height: 33px;" class="ellipsis1">
                        {{
                            ListAuditForm.ApprovalName
                        }}
                    </div>
                    <div style="text-align: right;display: flex;">


                        <i :class="!fullscreen ? 'el-icon-full-screen' : 'el-icon-copy-document'"
                            style="float: right;font-size: 33px;width: 33px;height: 33px;margin-right: 10px;cursor: pointer;"
                            @click="fullscreen = !fullscreen"></i>
                        <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;float: right;"
                            src="@/assets/delete.png" alt="" @click="ListAuditFlagClose" />
                    </div>
                </el-row>
                <el-row
                    style="font-size: 14px;font-weight: bold;color:#909399;text-align: left;width: calc(100% - 33px);"
                    class="ellipsis">
                    {{
                        ListAuditForm.ApprovalCode
                    }}

                </el-row>
            </div>

            <div style="height:calc(90vh - 160px);overflow-y: auto;overflow-x: hidden;" class="ListActive"
                v-loading="DataLoading">
                <el-collapse v-model="activeNames" @change="activeNamesChange" accordion v-if="!isRGAudit">
                    <el-collapse-item v-for="(item, index) in ListAuditData" :key="item.Id" :name="item.Id">
                        <template slot="title">
                            <span style="font-weight: bold;font-size: 16px;">
                                报审单批次：第{{ item.ApprovalBatch }}批次
                            </span>
                        </template>
                        <el-row :gutter="10" v-loading="item.loading">
                            <el-col :span="9">
                                <el-card v-if="item.viewData.ViewAuditForm">
                                    <div style="overflow-y: auto;height: 55vh;" class="viewList">
                                        <el-descriptions class="margin-top" :column="1" size="medium" border>
                                            <el-descriptions-item :label="addAuditType == 1 ? '设备/材料名称' : '深化图纸名称'">
                                                {{ item.viewData.ViewAuditForm.ApprovalName }}
                                            </el-descriptions-item>
                                            <el-descriptions-item
                                                :label="addAuditType == 1 ? '设备/材料报审单编号' : '深化图纸报审单编号'">
                                                {{ item.viewData.ViewAuditForm.ApprovalCode }}
                                            </el-descriptions-item>
                                            <el-descriptions-item label="报审批次">第<span style="color: #D1100B;">{{
                                                item.viewData.ViewAuditForm.ApprovalBatch
                                                    }}</span>批次
                                            </el-descriptions-item>
                                            <el-descriptions-item label="专业">
                                                {{
                                                    item.viewData.ViewAuditForm.ApprovalMajor
                                                }}
                                            </el-descriptions-item>
                                            <el-descriptions-item label="报审单位">{{
                                                item.viewData.ViewAuditForm.ReportingUnit
                                                }}</el-descriptions-item>
                                            <el-descriptions-item label="报审人">{{
                                                item.viewData.ViewAuditForm.ReportingName
                                                }}</el-descriptions-item>
                                            <el-descriptions-item label="报审日期"> {{
                                                item.viewData.ViewAuditForm.ReportingDate ?
                                                    item.viewData.ViewAuditForm.ReportingDate.substring(0,
                                                        19) :
                                                    ''
                                            }}
                                            </el-descriptions-item>
                                            <el-descriptions-item label="关联附件">
                                                <div v-if="item.viewData.ViewUpFileData">
                                                    <div v-for="val in item.viewData.ViewUpFileData" :key="val.Id"
                                                        style="color: #D1100B;cursor: pointer;font-size: 14px;font-weight: bold;"
                                                        @click="downloadFile(val)">
                                                        {{ val.FileName }}
                                                    </div>
                                                </div>

                                            </el-descriptions-item>
                                        </el-descriptions>
                                    </div>
                                </el-card>
                                <el-empty description="加载中" v-else></el-empty>
                            </el-col>
                            <el-col :span="15">
                                <el-card v-if="item.viewData.ViewSignatoryUnitOptions">


                                    <div class="content" style="overflow-y: auto;height: 55vh;">
                                        <div>
                                            <div v-for="(val, ind) in item.viewData.ViewSignatoryUnitOptions"
                                                :key="val.Id">
                                                <el-form label-width="150px" style="width: 80%;font-weight: bold;"
                                                    class="ViewAuditForm" disabled>
                                                    <el-form-item :label="val.ApprovalOrganName + '：'">
                                                        <el-select v-model="val.ApprovalOpinions" clearable
                                                            placeholder="未选择批复意见" style="width: 60%">
                                                            <el-option v-for="v in ApprovalOpinions" :key="v.DicName"
                                                                :label="v.DicName" :value="v.DicName">
                                                            </el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                    <el-form-item label="批注意见：">
                                                        <el-select v-model="val.ApprovalAnnotation" clearable
                                                            placeholder="未选择批注意见" style="width: 60%">
                                                            <el-option v-for="v in AnnotationOpinions" :key="v.DicName"
                                                                :label="v.DicName" :value="v.DicName">
                                                            </el-option>
                                                        </el-select>
                                                        <el-input type="textarea" style="margin-top: 5px;"
                                                            v-model="val.ApprovalARemark">
                                                        </el-input>
                                                    </el-form-item>
                                                    <el-form-item label="关联附件：">
                                                        <div v-for="c in val.AuditUpFileData" :key="c.Id"
                                                            style="color: #D1100B;cursor: pointer;font-size: 14px;font-weight: bold;"
                                                            @click="downloadFile(c)">
                                                            {{ c.FileName }}
                                                        </div>
                                                    </el-form-item>
                                                    <el-form-item label="角色：">
                                                        <span style="font-size: 16px"> {{ val.ApprovalUserRole || '暂无'
                                                            }}</span>
                                                    </el-form-item>
                                                    <el-form-item label="负责人手机号：">
                                                        {{ val.ApprovalPhone }}
                                                    </el-form-item>
                                                    <el-form-item label="负责人签署：">
                                                        <div style="display: flex;align-items: center;">
                                                            <el-image style="width: 200px; height: 100px;border:1px solid #D9D9D9;"
                                                                :src="val.ApproverSignature">
                                                                <div slot="error"
                                                                    style="width: 100%;height: 100%;text-align: center;line-height: 100px;background-color: #F5F7FA;">
                                                                    暂无数据
                                                                </div>
                                                            </el-image>
                                                            <div style="margin-left: 10px;">
                                                                <el-date-picker v-model="val.ApprovalDateTime"
                                                                    type="date" value-format="yyyy-MM-dd "
                                                                    format="yyyy-MM-dd " placeholder="未选择日期">
                                                                </el-date-picker>
                                                            </div>
                                                            <div style="margin-left: 20px;">
                                                                <el-tag v-if="val.ApprovalStatus == 1" type="success">
                                                                    已审核
                                                                </el-tag>
                                                                <el-tag v-if="val.ApprovalStatus == 0" type="danger">
                                                                    未审核或暂存
                                                                </el-tag>

                                                            </div>
                                                        </div>
                                                    </el-form-item>


                                                </el-form>

                                                <el-row style="text-align: right;"
                                                    v-if="val.ApprovalPhone === userInfo.Phone && item.Status == 1 && val.ApprovalStatus == 0 && index === ListAuditData.length - 1">
                                                    <!-- 最新批次+当前用户与审批用户手机号相同 + 未审核或暂存 才显示 -->
                                                    <el-button type="primary" size="small" style="margin-right: 20px;"
                                                        @click="AuditSubmit(item, val)">
                                                        审核
                                                    </el-button>
                                                </el-row>
                                                <el-divider
                                                    v-if="ind != item.viewData.ViewSignatoryUnitOptions.length - 1"></el-divider>
                                            </div>
                                        </div>

                                    </div>

                                </el-card>
                                <el-empty description="加载中" v-else></el-empty>
                            </el-col>

                        </el-row>

                    </el-collapse-item>

                </el-collapse>
                <div v-if="isRGAudit">
                    <el-row :gutter="10">
                        <el-col :span="9">
                            <el-card>
                                <div style="height: calc(73vh - 53px);overflow-y: auto;overflow-x: hidden;">

                                    <div class="viewList">
                                        <el-descriptions class="margin-top" :column="1" size="medium" border>
                                            <el-descriptions-item :label="addAuditType == 1 ? '设备/材料名称' : '深化图纸名称'">
                                                {{ RGAuditData?.viewData?.ViewAuditForm?.ApprovalName }}
                                            </el-descriptions-item>
                                            <el-descriptions-item
                                                :label="addAuditType == 1 ? '设备/材料报审单编号' : '深化图纸报审单编号'">
                                                {{ RGAuditData?.viewData?.ViewAuditForm?.ApprovalCode }}
                                            </el-descriptions-item>
                                            <el-descriptions-item label="报审批次">第<span style="color: #D1100B;">{{
                                                RGAuditData?.viewData?.ViewAuditForm?.ApprovalBatch
                                                    }}</span>批次
                                            </el-descriptions-item>
                                            <el-descriptions-item label="专业">
                                                {{
                                                    RGAuditData?.viewData?.ViewAuditForm?.ApprovalMajor
                                                }}
                                            </el-descriptions-item>
                                            <el-descriptions-item label="报审单位">{{
                                                RGAuditData?.viewData?.ViewAuditForm?.ReportingUnit
                                                }}</el-descriptions-item>
                                            <el-descriptions-item label="报审人">{{
                                                RGAuditData?.viewData?.ViewAuditForm?.ReportingName
                                                }}</el-descriptions-item>
                                            <el-descriptions-item label="报审日期"> {{
                                                RGAuditData?.viewData?.ViewAuditForm?.ReportingDate ?
                                                    RGAuditData?.viewData?.ViewAuditForm?.ReportingDate.substring(0,
                                                        19) :
                                                    ''
                                            }}
                                            </el-descriptions-item>
                                            <el-descriptions-item label="关联附件">
                                                <div v-if="RGAuditData?.viewData?.ViewUpFileData">
                                                    <div v-for="val in RGAuditData?.viewData?.ViewUpFileData"
                                                        :key="val.Id"
                                                        style="color: #D1100B;cursor: pointer;font-size: 14px;font-weight: bold;"
                                                        @click="downloadFile(val)">
                                                        {{ val.FileName }}
                                                    </div>
                                                </div>

                                            </el-descriptions-item>
                                        </el-descriptions>
                                    </div>
                                    <div style="width: 100%;text-align: center;margin-top: 20px;">
                                        <vue-qr :text="urlQrCode" :size="180" :margin="0"></vue-qr>
                                        <div
                                            style="text-align: center;width: 100%;line-height: 30px;font-size: 18px;font-weight: bold;">
                                            扫 码 审 核</div>
                                    </div>

                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="15">
                            <el-card v-loading.fullscreen.lock="RGLoading">
                                <div style="height: calc(73vh - 53px);overflow-y: auto;overflow-x: hidden;">
                                    <el-form label-width="150px" style="width: 80%;font-weight: bold;"
                                        class="ViewAuditForm">
                                        <el-form-item label="角色：">
                                            <span style="font-size: 16px;">{{ RGAuditInfo.ApprovalUserRole || '暂无'
                                                }}</span>
                                        </el-form-item>
                                        <el-form-item :label="RGAuditInfo.ApprovalOrganName + '：'" required>
                                            <el-select v-model="RGAuditInfo.ApprovalOpinions" clearable
                                                placeholder="未选择批复意见" style="width: 60%"
                                                @change="changeApprovalOpinions($event, RGAuditInfo)">
                                                <el-option v-for="v in ApprovalOpinions" :key="v.DicName"
                                                    :label="v.DicName" :value="v.DicName">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="批注意见：" required>
                                            <el-select v-model="RGAuditInfo.ApprovalAnnotation" clearable
                                                placeholder="未选择批注意见" style="width: 60%"
                                                @change="changeApprovalAnnotation($event, RGAuditInfo)">
                                                <el-option v-for="v in AnnotationOpinions" :key="v.DicName"
                                                    :label="v.DicName" :value="v.DicName">
                                                </el-option>
                                            </el-select>
                                            <el-input type="textarea" style="margin-top: 5px;font-size: 13px;"
                                                v-model="RGAuditInfo.ApprovalARemark" placeholder="批注意见备注">
                                            </el-input>
                                            <div style="margin-top: 10px;">
                                                <el-date-picker v-model="RGAuditInfo.ApprovalDateTime" type="date"
                                                    value-format="yyyy-MM-dd " format="yyyy-MM-dd " placeholder="未选择日期">
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>

                                        <el-form-item label="附件：">

                                            <UploadValue v-model="RGAuditInfo.AuditUpFileData"
                                                @success="propValidateField($event, RGAuditInfo)" :id="RGAuditInfo.Id"
                                                ref="UploadValue" @error="errorValidateField($event, RGAuditInfo)"
                                                upUrl="/ReviewGO/Review_ApprovalSignInfo_FAL/OssUploadFileByForm"
                                                delUrl="/ReviewGO/Review_ApprovalSignInfo_FAL/DeleteData">
                                            </UploadValue>



                                        </el-form-item>
                                        <el-form-item label="负责人签署：" required>
                                            <div style="display: flex;align-items: center;">
                                                <el-image style="width: 200px; height: 100px;border:1px solid #D9D9D9;"
                                                    :src="RGAuditInfo.ApproverSignature">
                                                    <div slot="error"
                                                        style="width: 100%;height: 100%;text-align: center;line-height: 100px;background-color: #F5F7FA;">
                                                        暂无签名
                                                    </div>
                                                </el-image>
                                                <el-button type="primary" size="mini" style="margin-left: 10px;"
                                                    @click="selectSignature">使用预制签名</el-button>

                                            </div>
                                        </el-form-item>
                                        <!-- {{ RGAuditInfo.ApprovalPhone + '/审批手机号' }}
                                        {{ userInfo.Phone + '/当前用户手机号' }} -->

                                    </el-form>

                                </div>
                            </el-card>
                        </el-col>
                    </el-row>


                </div>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isRGAudit = false" v-if="isRGAudit">返回</el-button>
                <el-button type="primary" @click="saveData(RGAuditInfo)" v-if="isRGAudit">提交</el-button>
                <el-button @click="ListAuditFlagClose">关 闭</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import vueQr from "vue-qr";
import moment from 'moment'
import { mapState } from 'vuex'
import newImgUrl from '@/assets/NewRightSup_500.png';
import UploadValue from '@/views/Project_RG_User/UploadValue.vue'
import logbook from './RG_logbook.vue'
export default {
    data() {
        return {
            fullscreen: false,
            tabName: '全部',
            newImgUrl,
            auditImgInfoListLoading: false,
            auditMaterialsInfoListLoading: false,
            moment,
            addAuditType: 0,
            isShow: false,
            SingleConditionList: [],
            AuditFlag: false,
            auditMaterialsInfoList: [],
            auditImgInfoList: [],
            AuditForm: {
                ContactPhone: "",
                ReportingUnit: ""
            },

            qrcode: '',//提审跳转H5页面二维码
            qrcodeVisible: false,
            //查看报审
            ViewAuditFlag: false,
            ViewAuditLoadings: false,
            ViewAuditForm: {

            },
            ViewUpFileData: [],
            ViewSignatoryUnitOptions: [],
            // 签署机构下拉列表数据
            SignatoryUnitOptions: [
            ],
            addIsDisabled: false,
            //批复意见-材料报审-内
            ApprovalOpinions: [],
            //批注意见-材料报审-内
            AnnotationOpinions: [],
            // 意见归类-图纸审核-内
            OpinionClassification: [],
            dataItem: {},
            // /列表弹窗
            ListAuditFlag: false,
            ListAuditForm: {},
            ListAuditData: [],
            DataLoading: false,
            activeNames: '',
            TaskData: {},
            taskDataSelect: "",
            taskOptions: [],
            taskQueryParams: {
                ClassType: 0,
                Conditions: [
                    {
                        Condition: "ProjectId",
                        Keyword: this.taskObj.ProjectId,
                    },
                    {
                        Condition: "AppyId",
                        Keyword: this.taskObj.AppyId,
                    },
                    // { Condition: "Status", Keyword: 0 },暂时删除后续有需要追加
                ],
            },
            //当前用户审核
            isRGAudit: false,
            RGAuditData: {},
            RGAuditInfo: {
            },
            //当前外协用户信息 
            EUInfo: {},
            RGLoading: false,
            urlQrCode: ''
        };
    },
    props: {
        taskObj: { type: Object, require: true },
        ClassType: { type: Number, require: true },
    },
    components: {
        vueQr, UploadValue, logbook
    },
    methods: {
        changeApprovalOpinions(eve, val) {

            let obj = this.ApprovalOpinions.find(item => {
                return item.DicName === eve
            })
            this.$set(val, 'ApprovalOpinionsKey', obj?.DicValue || '')
            console.log(eve, val)
        },
        changeApprovalAnnotation(eve, val) {

            let obj = this.AnnotationOpinions.find(item => {
                return item.DicName === eve
            })
            this.$set(val, 'ApprovalAnnotationKey', obj?.DicValue || '')
            console.log(eve, val)
        },
        selectSignature() {
            if (this.EUInfo.NameSignature) {
                this.RGAuditInfo.ApproverSignature = this.EUInfo.NameSignature
            } else {
                this.$message({
                    message: '暂无预制签名,请扫码后审核或扫码后配置预制签名',
                    type: 'warning'
                })
            }


        },
        UpDataIsNew(item) {
            this.$http.post('/ReviewGO/Review_ApprovalSignInfo/ProcessEnteringState', {
                ApprovalId: item.Id,
                ApprovalPhone: this.userInfo.Phone,
            }).then(res => {
                if (res.Success) {
                    console.log('成功更新NEW状态')
                }
            })
        },
        saveData(item, index, num) {

            let str = ''
            str = '请确保信息已填写完毕，确定提交吗?'
            this.$confirm(str, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',

            }).then(() => {
                let arr = []
                if (item.ApprovalOpinions) {
                    arr.push({
                        "Condition": "ApprovalOpinions",
                        "Keyword": item.ApprovalOpinions
                    })
                } else {
                    return this.$message.error('请选择批复意见')
                }

                if (item.ApprovalAnnotation) {
                    arr.push({
                        "Condition": "ApprovalAnnotation",
                        "Keyword": item.ApprovalAnnotation
                    })
                } else {
                    return this.$message.error('请选择批注意见')

                }
                if (item.ApprovalOpinionsKey) {
                    arr.push({
                        "Condition": "ApprovalOpinionsKey",
                        "Keyword": item.ApprovalOpinionsKey
                    })
                }
                if (item.ApprovalAnnotationKey) {
                    arr.push({
                        "Condition": "ApprovalAnnotationKey",
                        "Keyword": item.ApprovalAnnotationKey
                    })
                }
                if (item.ApprovalDateTime) {
                    arr.push({
                        "Condition": "ApprovalDateTime",
                        "Keyword": item.ApprovalDateTime
                    })
                } else {
                    return this.$message.error('请选择日期')

                }

                if (item.ApprovalARemark) {
                    arr.push({
                        "Condition": "ApprovalARemark",
                        "Keyword": item.ApprovalARemark
                    })
                }
                if (item.ApproverSignature) {
                    arr.push({
                        "Condition": "ApproverSignature",
                        "Keyword": item.ApproverSignature
                    })
                } else {
                    return this.$message.error('请确认是否签名后再提交')

                }
                if (item.AuditUpFileData.length == 0) {
                    this.$confirm('附件暂无上传，确定提交吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',

                    }).then(() => {
                        arr.push({
                            "Condition": "ApprovalStatus",
                            "Keyword": '1'
                        })
                        arr.push({
                            "Condition": "IsNew",
                            "Keyword": 1
                        })
                        arr.push({
                            "Condition": "ApprovalId",
                            "Keyword": item.ApprovalId
                        })
                        this.num = num
                        this.RGLoading = true
                        this.$http.post('/ReviewGO/Review_ApprovalSignInfo/ApprovalSignOperation', {
                            "Conditions": arr,
                            "Ids": [
                                item.Id
                            ]
                        }).then(async res => {
                            this.RGLoading = false
                            if (res.Success) {
                                this.getListAuditData({ ...this.ListAuditForm })
                                this.isRGAudit = false
                                this.$message.success('提交成功')

                                this.$set(item, 'disabled', true)
                            } else {
                                this.$set(item, 'disabled', false)
                                this.$message.error(res.Msg)
                            }
                        })
                    })
                    return
                }

                arr.push({
                    "Condition": "ApprovalStatus",
                    "Keyword": '1'
                })
                arr.push({
                    "Condition": "IsNew",
                    "Keyword": 1
                })
                arr.push({
                    "Condition": "ApprovalId",
                    "Keyword": item.ApprovalId
                })
                this.RGLoading = true
                this.$http.post('/ReviewGO/Review_ApprovalSignInfo/ApprovalSignOperation', {
                    "Conditions": arr,
                    "Ids": [
                        item.Id
                    ]
                }).then(async res => {
                    if (res.Success) {
                        if (item.AuditUpFileData.length > 0) {
                            let flag = item.AuditUpFileData.every(val => {
                                return val.status === 'success'
                            })
                            if (flag) {
                                this.RGLoading = false
                                this.$set(item, 'disabled', true)
                                this.getListAuditData({ ...this.ListAuditForm })
                                this.isRGAudit = false
                                return this.$message.success('提交成功')


                            } else {
                                await setTimeout(async () => {
                                    await this.$refs.UploadValue.upload()
                                })
                            }
                        }


                    } else {
                        this.$message.error(res.Msg)
                    }
                })
            })

        },
        propValidateField(data, item,) {
            this.RGLoading = false

            this.getListAuditData({ ...this.ListAuditForm })
            this.isRGAudit = false
            this.$message.success('提交成功')
            this.$set(item, 'disabled', true)
        },
        errorValidateField(data, item) {
            this.RGLoading = false
            console.log(data, 'errorValidateField')
            let str = ''
            data.forEach(val => {
                str = val.name + ',' + str
            })


            this.$message.warning(str + '文件上传失败')
            this.$set(item, 'disabled', false)

        },
        getEUInfo(item) {

            this.$http.post('/Base_ProjectManage/Project_ExternalUser/EUSubmitLoginByPhone', {
                Phone: this.userInfo.Phone,
                ApprovalId: item.Id
            }).then(res => {
                if (res.Success) {

                    if (res.Data.EUInfo) {
                        this.EUInfo = res.Data.EUInfo
                        window.localStorage.setItem('RGInfo', JSON.stringify(res.Data.EUInfo))


                    }
                }
            })
        },
        AuditSubmit(item, val) {
            console.log(item, val, '11111111111111')
            this.isRGAudit = true
            this.RGAuditInfo = { ...val }

            this.RGAuditData = { ...item }
            this.getEUInfo(item)
            this.urlQrCode = window.location.origin + `/TransferUrl?Id=${item.Id}&phone=${val.ApprovalPhone} `
            console.log(item, val)




        },
        refresh() {
            if (this.tabName === '我的待审') {
                this.getDataListByMoreCondition(1, 0)
                this.getDataListByMoreCondition(2, 0)
            } else if (this.tabName === '我的已审') {
                this.getDataListByMoreCondition(1, 1)
                this.getDataListByMoreCondition(2, 1)
            } else if (this.tabName === '全部') {
                this.getDataListByMoreCondition(1)
                this.getDataListByMoreCondition(2)
            } else if (this.tabName === '任务台账') {
                this.$nextTick(() => {
                    this.$refs.logbook.changeTab()
                })
            }
            //获取报审单位
            this.getDataListBySingleCondition()
        },
        getTaskListData() {
            this.$http
                .post("/Base_TaskWork/Base_Task/GetDataListByMoreCondition", {
                    Search: this.taskQueryParams,
                })
                .then((res) => {
                    if (res.Success) {
                        // let arr = res.Data.map((item) => {
                        //     return {
                        //         // 任务id
                        //         value: item.Id,
                        //         label: item.Name,
                        //     };
                        // });
                        this.taskOptions = res.Data || [];
                        this.taskDataSelect = this.taskObj.TaskId
                        //  更新表格数据
                        // 清空选项
                    } else {
                        this.$message.error(res.Msg);
                    }
                });
        },
        async taskOptionsChange(id) {

            let item = this.taskOptions.find(item => item.Id == id)

            await this.$emit("task", { status: false, ...item });

            this.refresh()
        },
        downloadFile(item) {
            window.open(item.FileWebURL)
        },
        ViewAuditFlagClose() {
            this.ViewAuditFlag = false
        },
        copyUrl(text) {
            var input = document.createElement("input"); // 创建input对象
            input.value = text; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message.success("复制成功！");
        },
        //获取报审单位列表
        getDataListBySingleCondition() {
            this.$http.post('/ReviewGO/Review_ApprovalUnit/GetApprovalUnitAndApprovalCount', {

                "SortField": "CreateTime",
                "SortType": "desc",
                "Search": {
                    "Condition": "TaskId",
                    "Keyword": this.taskObj.TaskId
                }
            }).then(res => {
                if (res.Success) {
                    if (res.Data) {
                        res.Data.forEach(item => {
                            this.$set(item, 'disabled', true)
                        })
                        this.SingleConditionList = res.Data || []
                    }



                } else {
                    this.$message.error(res.Msg)
                }
            })
        },
        // 关闭报审
        AuditClose() {
            this.AuditFlag = false
            // this.refresh()
            this.getDataListBySingleCondition()
        },
        // 报审单位添加保存
        AuditFormSubmitSave(item) {
            if (!item.ReportingUnit) {
                this.$message.warning('请输入报审单位')
                return
            }
            if (!item.ContactPhone) {
                this.$message.warning('请输入报审手机号')
                return
            }
            if (!(/^1[3-9]\d{9}$/.test(item.ContactPhone))) {
                this.$message.warning('请输入正确的报审手机号')
                return
            }
            this.$http.post('/ReviewGO/Review_ApprovalUnit/SaveData', {
                TaskId: this.taskObj.TaskId,
                ...item
            }).then(res => {
                if (res.Success) {
                    this.$message.success('保存成功')
                    this.AuditForm.ContactPhone = ''
                    this.AuditForm.ReportingUnit = ''
                    this.getDataListBySingleCondition()
                } else {
                    this.$message.error(res.Msg)
                }
            })
        },
        // 报审单位删除
        AuditFormDelete(item) {
            this.$confirm('确定删除该条信息吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post('/ReviewGO/Review_ApprovalUnit/DeleteData', [item.Id]).then(res => {
                    if (res.Success) {
                        this.$message.success('删除成功')
                        this.getDataListBySingleCondition()
                    } else {
                        this.$message.error(res.Msg)
                    }
                })
            })
        },
        //返回
        backTask() {
            this.$emit("task", {
                status: true,
                ...this.taskObj
            });
        },
        openForm(item,) {
            this.dataItem = item
            this.isShow = true;
        },
        AuditOpen(item, num) {
            if (num === 3) {
                this.qrcodeVisible = true
                // this.qrcode = window.location.origin + '/ExternalUserLogin'
                this.qrcode = window.location.origin + '/RG_SubmitApprovalLogin?TaskId=' + this.taskObj.TaskId
                return
            }

            // console.log(item)
            // this.qrcodeVisible = true
        },
        //全部列表
        async getDataListByMoreCondition(ApprovalType, ApprovalStatus) {

            if (ApprovalType == 1) {
                this.auditMaterialsInfoListLoading = true
            } else {
                this.auditImgInfoListLoading = true

            }
            await this.$http.post('/ReviewGO/Review_ApprovalInfo/GetDataListByMoreConditionForLatestRecord', {
                "SortField": "CreateTime",
                "SortType": "desc",
                "Search": [
                    {
                        "Condition": "TaskId",
                        "Keyword": this.taskObj.TaskId,
                    },
                    {
                        "Condition": "ApprovalType",
                        "Keyword": ApprovalType,
                    },
                    {
                        "Condition": "ApprovalPhone",
                        "Keyword": this.userInfo.Phone
                    },
                    {
                        "Condition": "ApprovalStatus",
                        "Keyword": ApprovalStatus,
                    },
                ]
            }).then(res => {
                if (ApprovalType == 1) {
                    this.auditMaterialsInfoListLoading = false
                } else {
                    this.auditImgInfoListLoading = false

                }
                if (res.Success) {
                    if (ApprovalType == 1) {
                        this.auditMaterialsInfoList = res.Data || []
                    } else if (ApprovalType == 2) {
                        this.auditImgInfoList = res.Data || []
                    }


                }
            }).catch(() => {
                if (ApprovalType == 1) {
                    this.auditMaterialsInfoListLoading = false
                } else {
                    this.auditImgInfoListLoading = false

                }
            })
        },
        // 我的 我的待审
        getDataListByPhoneForUnreviewed(ApprovalType) {
            if (ApprovalType == 1) {
                this.auditMaterialsInfoListLoading = true
            } else {
                this.auditImgInfoListLoading = true

            }
            this.$http.post('/ReviewGO/Review_ApprovalInfo/GetDataListByPhoneForUnreviewed', {
                // "PageIndex": 0,
                // "PageRows": 0,
                SortField: "CreateTime",
                SortType: "desc",
                "Search": [
                    {
                        "Condition": "ApprovalType",
                        "Keyword": ApprovalType,
                    },
                    {
                        "Condition": "TaskId",
                        "Keyword": this.taskObj.TaskId
                    },
                    {
                        "Condition": "ApprovalPhone",
                        "Keyword": this.userInfo.Phone
                    }
                ]
            }).then(res => {
                if (ApprovalType == 1) {
                    this.auditMaterialsInfoListLoading = false
                } else {
                    this.auditImgInfoListLoading = false

                }
                if (res.Success) {
                    if (ApprovalType == 1) {
                        this.auditMaterialsInfoList = res.Data || []
                    } else if (ApprovalType == 2) {
                        this.auditImgInfoList = res.Data || []
                    }
                } else {
                    this.$message.error(res.Msg)
                }
            })
        },
        //我的我的已审
        getDataListByPhoneForReviewed(ApprovalType) {

            if (ApprovalType == 1) {
                this.auditMaterialsInfoListLoading = true
            } else {
                this.auditImgInfoListLoading = true

            }
            this.$http.post('/ReviewGO/Review_ApprovalInfo/GetDataListByPhoneForReviewed', {
                // "PageIndex": 0,
                // "PageRows": 0,
                SortField: "CreateTime",
                SortType: "desc",
                "Search": [
                    // { ...obj },
                    {
                        "Condition": "TaskId",
                        "Keyword": this.taskObj.TaskId
                    },
                    {
                        "Condition": "ApprovalType",
                        "Keyword": ApprovalType,
                    },
                    {
                        "Condition": "ApprovalPhone",
                        "Keyword": this.userInfo.Phone
                    }
                ]
            }).then(res => {
                if (ApprovalType == 1) {
                    this.auditMaterialsInfoListLoading = false
                } else {
                    this.auditImgInfoListLoading = false

                }
                if (res.Success) {
                    if (ApprovalType == 1) {
                        this.auditMaterialsInfoList = res.Data || []
                    } else if (ApprovalType == 2) {
                        this.auditImgInfoList = res.Data || []
                    }
                } else {
                    this.$message.error(res.Msg)
                }
            })
        },
        ListAuditFlagClose() {
            this.ListAuditFlag = false

        },
        activeNamesChange(a) {
            let objIndex = this.ListAuditData.findIndex(i => i.Id == a)
            if (objIndex != -1) {
                if (!this.ListAuditData[objIndex].viewData.ViewAuditForm) {
                    this.getViewAuditDataInfo(this.ListAuditData[objIndex])
                }
            }
        },
        getListAuditData(row) {
            this.DataLoading = true
            this.$http.post('/ReviewGO/Review_ApprovalInfo/GetDataListByMoreCondition', {
                "Search": [
                    {
                        "Condition": "TaskId",
                        "Keyword": row.TaskId
                    },
                    {
                        "Condition": "ApprovalCode",
                        "Keyword": row.ApprovalCode
                    },
                    {
                        "Condition": "ReviewedStatus",
                        "Keyword": 'ReviewedStatus'
                    },
                ]
            }).then(res => {
                this.DataLoading = false
                if (res.Success) {
                    console.log(res.Data, 'res.Data')
                    this.ListAuditForm = { ...row }
                    if (res.Data && res.Data.length > 0) {
                        res.Data.forEach(item => {
                            this.$set(item, 'viewData', {})
                            this.$set(item, 'loading', false)
                        })
                        this.ListAuditData = res.Data
                        this.activeNames = this.ListAuditData[this.ListAuditData.length - 1].Id
                        this.activeNamesChange(this.ListAuditData[this.ListAuditData.length - 1].Id)
                    }

                }
            })
        },
        editAuditOpen(item, num) {
            console.log(item, 1)
            this.addAuditType = num
            this.activeNames = ''
            this.getApprovalOpinions(num)
            this.getAnnotationOpinions(num)
            this.ListAuditFlag = true
            this.isRGAudit = false
            this.getListAuditData(item)
            //如果点击的是未读状态，则将状态改为已读
            if (item.IsNew == 0) {
                this.UpDataIsNew(item)
            }
        },
        // 图审字典 	批复意见-内 1 材料 2 图纸
        getApprovalOpinions(type) {
            this.$http
                .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
                    Condition: "DicKey",
                    Keyword: type == 1 ? 'RG_ApprovalOpinions_CL' : 'RG_ApprovalOpinions_TS',
                }).then(res => {
                    if (res.Success) {
                        this.ApprovalOpinions = res.Data.DictionaryDetailsList || []
                    }
                })
        },
        // 图审字典 批注意见-内 1 材料 2 图纸
        getAnnotationOpinions(type) {
            this.$http
                .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
                    Condition: "DicKey",
                    Keyword: type == 1 ? 'RG_AnnotationOpinions_CL' : 'RG_AnnotationOpinions_TS',
                }).then(res => {
                    if (res.Success) {
                        this.AnnotationOpinions = res.Data.DictionaryDetailsList || []
                    }
                })
        },
        // 图审字典
        // getOpinionClassification() {
        //     this.$http
        //         .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
        //             Condition: "DicKey",
        //             Keyword: 'ZS_OpinionClassification_TS_N',
        //         }).then(res => {
        //             if (res.Success) {
        //                 this.OpinionClassification = res.Data.DictionaryDetailsList || []
        //             }
        //         })
        // },
        //图审字典
        getSignatoryUnitOptions() {
            let Keyword = ''
            if (this.addAuditType == 1) {
                Keyword = 'RG_ExternalOrganization_CL'
            } else if (this.addAuditType == 2) {
                Keyword = 'RG_ExternalOrganization_TS'
            }
            this.$http
                .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
                    Condition: "DicKey",
                    Keyword: Keyword,
                })
                .then(res => {
                    if (res.Success) {
                        this.SignatoryUnitOptions = res.Data.DictionaryDetailsList || []
                    }
                })
        },
        getViewAuditDataInfo(item) {
            item.loading = true
            let a = () => {
                // this.ViewAuditForm = {}
                this.$set(item.viewData, 'ViewAuditForm', {})
                return this.$http.post('/ReviewGO/Review_ApprovalInfo/GetTheData', {
                    id: item.Id
                }).then(res => {
                    if (res.Success) {
                        // this.ViewAuditForm = res.Data || {}
                        this.$set(item.viewData, 'ViewAuditForm', res.Data || {})


                    } else {
                        this.$message.error(res.Msg)
                    }
                })
            }
            let b = () => {
                this.$set(item.viewData, 'ViewUpFileData', [])
                return this.$http.post('/ReviewGO/Review_ApprovalInfo_FAL/GetDataListByMoreCondition', {
                    "Search": [
                        {
                            "Condition": "ApprovalId",
                            "Keyword": item.Id
                        }
                    ]
                }).then(res => {
                    if (res.Success) {
                        if (res.Data && res.Data.length > 0) {
                            res.Data.forEach(item => {
                                item.name = item.FileName
                                item.size = item.FileSize
                                item.url = item.FileWebURL
                                item.uid = item.Id
                                item.status = "success"
                            })
                            // this.ViewUpFileData = res.Data
                            this.$set(item.viewData, 'ViewUpFileData', res.Data || [])
                        } else {
                            this.$set(item.viewData, 'ViewUpFileData', [])
                        }

                    } else {
                        this.$message.error(res.Msg)
                    }
                })
            }
            let c = () => {
                // this.ViewSignatoryUnitOptions = []
                this.$set(item.viewData, 'ViewSignatoryUnitOptions', [])
                return this.$http.post('/ReviewGO/Review_ApprovalSignInfo/GetDataListByMoreCondition', {
                    Search: [
                        {
                            "Condition": "ApprovalId",
                            "Keyword": item.Id
                        },
                    ]
                }).then(async res => {
                    if (res.Success) {
                        // this.ViewSignatoryUnitOptions = res.Data || []
                        this.$set(item.viewData, 'ViewSignatoryUnitOptions', res.Data || [])
                        for (const val of item.viewData.ViewSignatoryUnitOptions) {
                            this.$set(val, 'AuditUpFileData', [])
                            await this.getitemUpList(val)
                        }


                    } else {
                        this.$message.error(res.Msg)
                    }
                })
            }
            Promise.all([
                a(), b(), c()
            ]).then(() => {
                console.log(item, 'item')
                item.loading = false
            }).catch(() => {
                item.loading = false
            })


        },
        async getitemUpList(item) {
            await this.$http.post('/ReviewGO/Review_ApprovalSignInfo_FAL/GetDataListBySingleCondition', {
                "Search":
                {
                    "Condition": "ApprovalSignId",
                    "Keyword": item.Id
                }

            }).then(res => {
                if (res.Success) {
                    res.Data.forEach(val => {
                        val.name = val.FileName
                        val.size = val.FileSize
                        val.url = val.FileWebURL
                        val.uid = val.Id
                        val.status = "success"
                    })
                    this.$set(item, 'AuditUpFileData', res.Data)
                } else {
                    this.$phoneMessage({
                        message: '附件列表获取失败',
                    })
                }
            })

        },
        getTaskData() {

            this.$http.post('/Base_TaskWork/Base_Task/GetBaseTaskForReviewGo', {
                "id": this.taskObj.TaskId
            }).then(res => {
                if (res.Success) {
                    this.TaskData = res.Data
                } else {
                    this.$message.error(res.Msg)
                }
            })
        },
    },
    mounted() { },
    created() {

        console.log(this.taskObj, 'taskObj')
        this.refresh()
        // this.getTaskData()
        this.getTaskListData()

    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
        })
    },
    watch: {
        ListAuditFlag(val) {
            if (val === false) {
                this.refresh()

            }
        },
        tabName(val) {

            if (val === '我的待审') {
                this.getDataListByMoreCondition(1, 0)
                this.getDataListByMoreCondition(2, 0)
            } else if (val === '我的已审') {
                this.getDataListByMoreCondition(1, 1)
                this.getDataListByMoreCondition(2, 1)
            } else if (val === '全部') {
                this.getDataListByMoreCondition(1)
                this.getDataListByMoreCondition(2)
            } else if (val === '任务台账') {
                this.$nextTick(() => {
                    this.$refs.logbook.changeTab()
                })
            }
        }
    },
};
</script>
<style lang='scss' scoped>
::v-deep .addAuditForm {
    .el-form-item__label {
        font-size: 18px;
    }
}

::v-deep .ViewAuditForm {
    .el-form-item__label {
        font-size: 18px;
    }
}

.auditMaterialsInfoList:hover {
    cursor: pointer;
    background: #f3eaea;
}

::v-deep.ViewAuditForm {
    // .el-upload {
    //     display: none;
    // }
}

::v-deep .el-steps {
    font-weight: bold;
}

::v-deep .el-step__head.is-finish {
    color: #D1100B;
    border-color: #D1100B;
}

::v-deep .el-steps .is-finish .el-step__line {
    background: #D1100B;
}

::v-deep .el-steps .is-process {
    color: #D1100B;
    border-color: #D1100B;
}

.ellipsis {
    overflow: hidden; //超出隐藏
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis; //文本超出时显示省略号

    display: -webkit-box;

    -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

    -webkit-line-clamp: 2; //内容限制的行数
}

.ellipsis1 {
    overflow: hidden; //超出隐藏
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis; //文本超出时显示省略号

    display: -webkit-box;

    -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

    -webkit-line-clamp: 1; //内容限制的行数
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* 针对Firefox的样式 */
input[type="number"] {
    -moz-appearance: textfield;
}

::v-deep .demo-AuditForm {
    .el-form-item__label {
        font-size: 18px;
        font-weight: bold;
        color: #000;
    }
}

::v-deep .SingleConditionList {
    .el-divider--horizontal {
        margin: 10px 0;
    }
}

::v-deep .Audit-el-card {
    .el-card__header {
        padding: 5px 20px;
        font-size: 18px;
        font-weight: bold;
    }


}

::v-deep .demo-AuditForm {
    .el-form-item {
        margin: 0 !important;
    }
}

::v-deep .Audit-Dialog {
    margin-top: 5vh !important;

    .el-dialog__body {
        padding: 5px 10px !important;
    }
}

::v-deep .ViewAudit {
    margin-top: 5vh !important;


    .el-dialog__body {
        padding: 0px 20px;
    }
}

::v-deep .viewList {
    .el-descriptions-item__label {
        width: 40%;
        text-align: right;
        font-size: 16px !important;
        font-weight: bold !important;
    }

}

::v-deep .ListActive {
    .el-collapse-item__content {
        padding-bottom: 0;
    }
}
</style>