<template>
    <!-- tab切换组件 -->
  <div>
    <div class="stockTitle">
      <div class="stockMenu" v-for="(item, index) in titleData" :key="index">
        <h3 @click="setTabCup(index)" class="tabcup" :class="{'active':num==index}">{{ item }}</h3>
      </div>
    </div>
    <div v-for="(item, index) in Components" :key="index">
      <component :is="index === num ? item : null"></component>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    titleData: {
      type: Array,
      default: () => [],
    },
    num:{
        type: Number || String,
        default:0
    },
  },
  data() {
    return {
      Components: [],
    };
  },
  created() {},
  mounted() {},
  // Vue方法定义
  methods: {
    init(params) {
      this.Components = params;
    },
    setTabCup(index) {
        console.log('index', index)
        this.$emit('setTabCup',index)
    },
  },
};
</script>

<style scoped>
/* @import url(''); 引入css类 */
.stockTitle {
  display: flex;
  padding:0 15px;
}

h3 {
  margin-right: 10px;
  font-size: 13px;
  font-size: 600;
}

.stockMenu {
  position: relative;
}

.active {
  background-color: #facaca;
  color:#F54040 !important;
}
.tabcup:hover{
    background-color: #facaca;
    color:#F54040 !important;
}
.tabcup{
    padding:5px 7px;
    border-radius: 4px;
    cursor: pointer;
    color: #727272;

}
</style>
