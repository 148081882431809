<template>
  <!--  暖通部分-->
  <div style="border: 2px solid #000">
    <!--    空调（提供/不提供）-->
    <div class="flex">
      <div class="flex8 flexCol">
        <!--              顶部备注区域布局站位-->
        <div class="flex" style="height: 22px">
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
        </div>
        <!--        内容区域 这里的height 30px 和下拉列表数据点额高度一致 否则回出现高度不一致情况-->
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">空调</div>
            <div class="flex5">
              <InputComponent
                ref="AirConditioningCondition"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange(
                    $event,
                    'AirConditioningConditionSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              空调形式
            </div>
            <div class="flex5 height30 borderTop borderRight font16">
              {{
                parentObj.entity.AirConditioningCondition
                  ? parentObj.entity.AirConditioningCondition
                      .AirConditioningForm
                    ? parentObj.entity.AirConditioningCondition
                        .AirConditioningForm
                    : ""
                  : ""
              }}
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">采暖供热</div>
            <div class="flex5">
              <InputComponent
                ref="AC_ColdWaterPipeDiameter"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange(
                    $event,
                    'AC_ColdWaterPipeDiameterSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">采暖供热形式</div>
            <div class="flex5">
              <InputComponent
                :placeHolder="'请输入采暖供热形式'"
                ref="AirConditioningHeatingFormInput"
                :suffixText="''"
                :type="'input'"
                @input="inputChange($event, 'AirConditioningHeatingForm')"
              ></InputComponent>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">独立VRF安装条件</div>
            <div class="flex5 height30">
              <InputComponent
                ref="VRFConditionReservationSelect"
                :type="'select'"
                :options="kongguanOtions"
                @change="
                  inputSelectChange(
                    $event,
                    'VRFConditionReservationSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">空调及供热管制</div>
            <div class="flex5">
              <InputComponent
                ref="AirConditioningHeatingControl"
                :type="'select'"
                :options="kongguanOtions"
                @change="
                  inputSelectChange(
                    $event,
                    'AirConditioningHeatingControlSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              空调冷负荷
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>
                {{
                  parentObj.entity.AirConditioningCondition
                    ? parentObj.entity.AirConditioningCondition
                        .TotalCoolingCapacity
                      ? parentObj.entity.AirConditioningCondition
                          .TotalCoolingCapacity
                      : ""
                    : ""
                }}</span
              >
              <div v-if="parentObj.entity.AirConditioningCondition">
                <span
                  v-if="
                    parentObj.entity.AirConditioningCondition
                      .TotalCoolingCapacity
                  "
                  class="paddingRight5 color font14"
                  >(kW)</span
                >
              </div>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              空调冷负荷指标
            </div>
            <div class="flex jbetween flex5 height30 borderRight font16">
              <span>
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .RefrigerationLoadIndex
                      ? parentObj.entity.VentilationCondition
                          .RefrigerationLoadIndex
                      : ""
                    : ""
                }}</span
              >
              <span class="color paddingRight5 font14">{{
                parentObj.entity.VentilationCondition
                  ? parentObj.entity.VentilationCondition.RefrigerationLoadIndex
                    ? "(W/㎡)"
                    : ""
                  : ""
              }}</span>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              空调热负荷
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>{{
                parentObj.entity.AirConditioningCondition
                  ? parentObj.entity.AirConditioningCondition
                      .TotalHeatingCapacity
                    ? parentObj.entity.AirConditioningCondition
                        .TotalHeatingCapacity
                    : ""
                  : ""
              }}</span>
              <span class="color paddingRight5 font14">{{
                parentObj.entity.AirConditioningCondition
                  ? parentObj.entity.AirConditioningCondition
                      .TotalHeatingCapacity
                    ? "(kW)"
                    : ""
                  : ""
              }}</span>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              空调热负荷指标
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>{{
                parentObj.entity.AirConditioningCondition
                  ? parentObj.entity.AirConditioningCondition.HeatingLoadIndex
                    ? parentObj.entity.AirConditioningCondition.HeatingLoadIndex
                    : ""
                  : ""
              }}</span>
              <span class="color paddingRight5 font14">{{
                parentObj.entity.AirConditioningCondition
                  ? parentObj.entity.AirConditioningCondition.HeatingLoadIndex
                    ? "(W/㎡)"
                    : ""
                  : ""
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="shopInfoRemark flex flex2 border">
        <div class="text-center font16">备注</div>
        <div>
          <InputComponent
            ref="RemarkHVACInput"
            :placeHolder="'请输入备注'"
            :rows="6"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkHVAC')"
          ></InputComponent>
        </div>
      </div>
    </div>

    <!--    空调冷热水管道数量 -->
    <div class="flex">
      <div class="flex8 flexCol">
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span>空调冷水管道数量</span>
            </div>
            <div
              v-if="parentObj.entity.AirConditioningCondition"
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>
                {{
                  parentObj.entity.AirConditioningCondition
                    ? parentObj.entity.AirConditioningCondition
                        .AC_ColdWaterPipeDiameter
                      ? parentObj.entity.AirConditioningCondition
                          .AC_ColdWaterPipeDiameter.length
                      : 0
                    : 0
                }}
              </span>
              <span class="color font14 paddingRight5">
                {{
                  parentObj.entity.AirConditioningCondition
                    ? parentObj.entity.AirConditioningCondition
                        .AC_ColdWaterPipeDiameter
                      ? "个"
                      : "个"
                    : "个"
                }}
              </span>
            </div>
            <div
              v-else
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>0 </span>
              <span class="color font14 paddingRight5">个</span>
            </div>
          </div>
          <!--          布局站位-->
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span></span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span class="color font14 paddingRight5"></span>
            </div>
          </div>
        </div>
        <!-- 冷水管径-->
        <!--        v-if="parentObj.entity.AirConditioningCondition"-->
        <div class="flex" v-if="parentObj.entity.AirConditioningCondition">
          <div class="flex8 flexCol">
            <div class="flex8 flexCol">
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.AirConditioningCondition &&
                    parentObj.entity.AirConditioningCondition
                      .AC_ColdWaterPipeDiameter &&
                    parentObj.entity.AirConditioningCondition
                      .AC_ColdWaterPipeDiameter.length > 0 &&
                    (index == 0 || index == 1)
                  "
                  v-for="(item, index) in parentObj.entity
                    .AirConditioningCondition.AC_ColdWaterPipeDiameter"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span>冷水管径({{ index + 1 }})</span>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span>{{ item }}</span>
                  </div>
                </div>
<!--               布局站位-->
                <div
                    v-if=" parentObj.entity.AirConditioningCondition &&
                    parentObj.entity.AirConditioningCondition.AC_ColdWaterPipeDiameter.length ==1"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.AirConditioningCondition &&
                    parentObj.entity.AirConditioningCondition
                      .AC_ColdWaterPipeDiameter &&
                    parentObj.entity.AirConditioningCondition
                      .AC_ColdWaterPipeDiameter.length > 0 &&
                    (index == 2 || index == 3)
                  "
                  v-for="(item, index) in parentObj.entity
                    .AirConditioningCondition.AC_ColdWaterPipeDiameter"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span>冷水管径({{ index + 1 }})</span>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span>{{ item }}</span>
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.AirConditioningCondition &&
                    parentObj.entity.AirConditioningCondition.AC_ColdWaterPipeDiameter.length ==3"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div class="flex" v-else>
          <div class="flex8 flexCol">
            <div class="flex8 flexCol"></div>
          </div>
          <div></div>
        </div>
        <!--        空调热书管道数量-->
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span>空调热水管道数量</span>
            </div>
            <div
              v-if="parentObj.entity.AirConditioningCondition"
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>
                {{
                  parentObj.entity.AirConditioningCondition
                    ? parentObj.entity.AirConditioningCondition
                        .AC_HotWaterPipeDiameter
                      ? parentObj.entity.AirConditioningCondition
                          .AC_HotWaterPipeDiameter.length
                      : 0
                    : 0
                }}
              </span>
              <span class="color font14 paddingRight5">
                {{
                  parentObj.entity.AirConditioningCondition
                    ? parentObj.entity.AirConditioningCondition
                        .AC_HotWaterPipeDiameter
                      ? "个"
                      : "个"
                    : "个"
                }}
              </span>
            </div>
            <div
              v-else
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>0 </span>
              <span class="color font14 paddingRight5">个</span>
            </div>
          </div>
          <!--          布局站位-->
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span></span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span class="color font14 paddingRight5"></span>
            </div>
          </div>
        </div>

        <div class="flex" v-if="parentObj.entity.AirConditioningCondition">
          <div class="flex8 flexCol">
            <div class="flex8 flexCol">
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.AirConditioningCondition &&
                    parentObj.entity.AirConditioningCondition
                      .AC_HotWaterPipeDiameter.length > 0 &&
                    (index == 0 || index == 1)
                  "
                  v-for="(item, index) in parentObj.entity
                    .AirConditioningCondition.AC_HotWaterPipeDiameter"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>热水管径({{ index + 1 }})</div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>{{ item }}</div>
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.AirConditioningCondition &&
                    parentObj.entity.AirConditioningCondition.AC_HotWaterPipeDiameter.length ==1"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.AirConditioningCondition &&
                    parentObj.entity.AirConditioningCondition
                      .AC_HotWaterPipeDiameter.length > 0 &&
                    (index == 2 || index == 3)
                  "
                  v-for="(item, index) in parentObj.entity
                    .AirConditioningCondition.AC_HotWaterPipeDiameter"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>热水管径({{ index + 1 }})</div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div>{{ item }}</div>
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.AirConditioningCondition &&
                    parentObj.entity.AirConditioningCondition.AC_HotWaterPipeDiameter.length ==3"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>
      <div v-if="parentObj.entity.AirConditioningCondition">
        <div
          v-if="
            parentObj.entity.AirConditioningCondition.AC_HotWaterPipeDiameter
          "
        >
          <InputComponent
            ref="RemarkAC_ColdHOTWaterPipeDiameterInput"
            :placeHolder="'请输入备注'"
            :rows="
              parentObj.entity.AirConditioningCondition.AC_HotWaterPipeDiameter
                .length
            "
            :type="'textarea'"
            @input="inputChange($event, 'RemarkAC_ColdHOTWaterPipeDiameter')"
          ></InputComponent>
        </div>
      </div>
      <div v-else>
        <InputComponent
          ref="RemarkAC_ColdHOTWaterPipeDiameterInput"
          :placeHolder="'请输入备注'"
          :rows="1"
          :type="'textarea'"
          @input="inputChange($event, 'RemarkAC_ColdHOTWaterPipeDiameter')"
        ></InputComponent>
      </div>
    </div>

    <!--    新风管道-->
    <div class="flex">
      <div class="flex8 flexCol">
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <span>新风</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="FreshAirPipelineSize"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange($event, 'FreshAirPipelineSizeSelectValue')
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span>新风送风量</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>
                {{
                  parentObj.entity.AirConditioningCondition
                    ? parentObj.entity.AirConditioningCondition.AirChangeFlow
                      ? parentObj.entity.AirConditioningCondition.AirChangeFlow
                      : ""
                    : ""
                }}
              </span>
              <span class="font14 color paddingRight5">
                {{
                  parentObj.entity.AirConditioningCondition
                    ? parentObj.entity.AirConditioningCondition.AirChangeFlow
                      ? "(m³/h)"
                      : ""
                    : ""
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span> 新风管道入户数量</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderRight font16"
              v-if="parentObj.entity.AirConditioningCondition"
            >
              <span>
                {{
                  parentObj.entity.AirConditioningCondition
                    ? parentObj.entity.AirConditioningCondition
                        .FreshAirPipelineSize
                      ? parentObj.entity.AirConditioningCondition
                          .FreshAirPipelineSize.length
                      : 0
                    : 0
                }}
              </span>
              <span class="color font14 paddingRight5">
                {{
                  parentObj.entity.AirConditioningCondition
                    ? parentObj.entity.AirConditioningCondition
                        .FreshAirPipelineSize
                      ? "个"
                      : "个"
                    : "个"
                }}
              </span>
            </div>
            <div class="flex jbetween flex5 height30 borderRight font16" v-else>
              <span>0 </span>
              <span class="color font14 paddingRight5">个</span>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight"></div>
            <div class="flex5 height30 borderTop borderRight"></div>
          </div>
        </div>
        <div class="flex" v-if="parentObj.entity.AirConditioningCondition">
          <div class="flex8 flexCol">
            <div class="flex8 flexCol">
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.AirConditioningCondition
                      .FreshAirPipelineSize.length > 0 &&
                    (index == 0 || index == 1)
                  "
                  v-for="(item, index) in parentObj.entity
                    .AirConditioningCondition.FreshAirPipelineSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span> 新风管道尺寸({{ index + 1 }})</span>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span v-if="item.FAPS_A && item.FAPS_B">
                      {{ item.FAPS_A + " X " + item.FAPS_B }}</span
                    >
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.AirConditioningCondition &&
                    parentObj.entity.AirConditioningCondition.FreshAirPipelineSize.length ==1"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.AirConditioningCondition
                      .FreshAirPipelineSize.length > 0 &&
                    (index == 2 || index == 3)
                  "
                  v-for="(item, index) in parentObj.entity
                    .AirConditioningCondition.FreshAirPipelineSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span> 新风管道尺寸({{ index + 1 }})</span>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span v-if="item.FAPS_A && item.FAPS_B">
                      {{ item.FAPS_A + " X " + item.FAPS_B }}</span
                    >
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.AirConditioningCondition &&
                    parentObj.entity.AirConditioningCondition.FreshAirPipelineSize.length ==3"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div v-if="parentObj.entity.AirConditioningCondition">
        <div
          v-if="parentObj.entity.AirConditioningCondition.FreshAirPipelineSize"
        >
          <InputComponent
            ref="RemarkFreshAirPipelineSizeInput"
            :placeHolder="'请输入备注'"
            :rows="
              parentObj.entity.AirConditioningCondition.FreshAirPipelineSize
                .length
            "
            :type="'textarea'"
            @input="inputChange($event, 'RemarkFreshAirPipelineSize')"
          ></InputComponent>
        </div>
      </div>
      <div v-else>
        <InputComponent
          ref="RemarkFreshAirPipelineSizeInput"
          :placeHolder="'请输入备注'"
          :rows="1"
          :type="'textarea'"
          @input="inputChange($event, 'RemarkFreshAirPipelineSize')"
        ></InputComponent>
      </div>
    </div>

    <!--  排油烟管道（提供/不提供）-->
    <div class="flex">
      <div class="flex8 flexCol">
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <span> 排油烟管道</span>
            </div>
            <div class="flex5 height30">
              <InputComponent
                ref="KitchenExhaustPipeSize"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange($event, 'KitchenExhaustPipeSizeSelectValue')
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span>排油烟总风量</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenOilFumeDischarge
                      ? parentObj.entity.VentilationCondition
                          .KitchenOilFumeDischarge
                      : ""
                    : ""
                }}
              </span>
              <span class="font14 color paddingRight5">
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenOilFumeDischarge
                      ? "(m³/h)"
                      : ""
                    : ""
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span> 排油烟管道入户数量</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderRight font16"
              v-if="parentObj.entity.VentilationCondition"
            >
              <span>
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenExhaustPipeSize
                      ? parentObj.entity.VentilationCondition
                          .KitchenExhaustPipeSize.length
                      : 0
                    : 0
                }}
              </span>
              <span class="color font14 paddingRight5">
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenExhaustPipeSize
                      ? "个"
                      : "个"
                    : "个"
                }}
              </span>
            </div>
            <div class="flex jbetween flex5 height30 borderRight font16" v-else>
              <span>0 </span>
              <span class="color font14 paddingRight5">个</span>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight"></div>
            <div class="flex5 height30 borderTop borderRight"></div>
          </div>
        </div>
        <div class="flex" v-if="parentObj.entity.VentilationCondition">
          <div class="flex8 flexCol">
            <div class="flex8 flexCol">
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.VentilationCondition.KitchenExhaustPipeSize
                      .length > 0 &&
                    (index == 0 || index == 1)
                  "
                  v-for="(item, index) in parentObj.entity.VentilationCondition
                    .KitchenExhaustPipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span>排油烟管道尺寸({{ index + 1 }})</span>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span v-if="item.KEPS_A && item.KEPS_B">{{
                      item.KEPS_A + " X " + item.KEPS_B
                    }}</span>
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition.KitchenExhaustPipeSize.length ==1"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.VentilationCondition.KitchenExhaustPipeSize
                      .length > 0 &&
                    (index == 2 || index == 3)
                  "
                  v-for="(item, index) in parentObj.entity.VentilationCondition
                    .KitchenExhaustPipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span>排油烟管道尺寸({{ index + 1 }})</span>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span v-if="item.KEPS_A && item.KEPS_B">{{
                      item.KEPS_A + " X " + item.KEPS_B
                    }}</span>
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition.KitchenExhaustPipeSize.length ==3"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div v-if="parentObj.entity.VentilationCondition">
        <div
          v-if="parentObj.entity.VentilationCondition.KitchenExhaustPipeSize"
        >
          <InputComponent
            ref="RemarkKitchenExhaustPipeSizeInput"
            :placeHolder="'请输入备注'"
            :rows="
              parentObj.entity.VentilationCondition.KitchenExhaustPipeSize
                .length
            "
            :type="'textarea'"
            @input="inputChange($event, 'RemarkKitchenExhaustPipeSize')"
          ></InputComponent>
        </div>
      </div>
      <div v-else>
        <InputComponent
          ref="RemarkKitchenExhaustPipeSizeInput"
          :placeHolder="'请输入备注'"
          :rows="1"
          :type="'textarea'"
          @input="inputChange($event, 'RemarkKitchenExhaustPipeSize')"
        ></InputComponent>
      </div>
    </div>

    <!--  排油烟补风管道（提供/不提供）-->
    <div class="flex">
      <div class="flex8 flexCol">
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <span>排油烟补风管道</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="KitchenAirReplenishmentPipeSize"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange(
                    $event,
                    'KitchenAirReplenishmentPipeSizeSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span> 排油烟补风总风量</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenAirReplenishmentVolume
                      ? parentObj.entity.VentilationCondition
                          .KitchenAirReplenishmentVolume
                      : ""
                    : ""
                }}
              </span>
              <span class="font14 color paddingRight5">
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenAirReplenishmentVolume
                      ? "(m³/h)"
                      : ""
                    : ""
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span> 排油烟补风管道入户数量</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderRight font16"
              v-if="parentObj.entity.VentilationCondition"
            >
              <span>
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenAirReplenishmentPipeSize
                      ? parentObj.entity.VentilationCondition
                          .KitchenAirReplenishmentPipeSize.length
                      : 0
                    : 0
                }}
              </span>
              <span class="color font14 paddingRight5">
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenAirReplenishmentPipeSize
                      ? "个"
                      : "个"
                    : "个"
                }}
              </span>
            </div>
            <div class="flex jbetween flex5 height30 borderRight font16" v-else>
              <span>0 </span>
              <span class="color font14 paddingRight5">个</span>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight"></div>
            <div class="flex5 height30 borderTop borderRight"></div>
          </div>
        </div>
        <div class="flex" v-if="parentObj.entity.VentilationCondition">
          <div class="flex8 flexCol">
            <div class="flex8 flexCol">
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.VentilationCondition
                      .KitchenAirReplenishmentPipeSize.length > 0 &&
                    (index == 0 || index == 1)
                  "
                  v-for="(item, index) in parentObj.entity.VentilationCondition
                    .KitchenAirReplenishmentPipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span>补风管道尺寸({{ index + 1 }})</span>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span v-if="item.KARPS_A && item.KARPS_B">{{
                      item.KARPS_A + " X " + item.KARPS_B
                    }}</span>
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition.KitchenAirReplenishmentPipeSize.length ==1"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="flex" style="">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.VentilationCondition
                      .KitchenAirReplenishmentPipeSize.length > 0 &&
                    (index == 2 || index == 3)
                  "
                  v-for="(item, index) in parentObj.entity.VentilationCondition
                    .KitchenAirReplenishmentPipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span>补风管道尺寸({{ index + 1 }})</span>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span v-if="item.KARPS_A && item.KARPS_B">{{
                      item.KARPS_A + " X " + item.KARPS_B
                    }}</span>
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition.KitchenAirReplenishmentPipeSize.length ==3"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div v-if="parentObj.entity.VentilationCondition">
        <div
          v-if="
            parentObj.entity.VentilationCondition
              .KitchenAirReplenishmentPipeSize
          "
        >
          <InputComponent
            ref="RemarkKitchenAirReplenishmentPipeSizeInput"
            :placeHolder="'请输入备注'"
            :rows="
              parentObj.entity.VentilationCondition
                .KitchenAirReplenishmentPipeSize.length
            "
            :type="'textarea'"
            @input="
              inputChange($event, 'RemarkKitchenAirReplenishmentPipeSize')
            "
          ></InputComponent>
        </div>
      </div>
      <div v-else>
        <InputComponent
          ref="RemarkKitchenAirReplenishmentPipeSizeInput"
          :placeHolder="'请输入备注'"
          :rows="1"
          :type="'textarea'"
          @input="inputChange($event, 'RemarkKitchenAirReplenishmentPipeSize')"
        ></InputComponent>
      </div>
    </div>

    <!-- 厨房排风兼事故排风管道（提供/不提供）-->
    <div class="flex">
      <div class="flex8 flexCol">
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <span>厨房排风兼事故排风管道</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="KitchenNormalAccidentExhaustPipeSize"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange(
                    $event,
                    'KitchenNormalAccidentExhaustPipeSizeSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span>厨房排风兼事故排风总风量</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenNormalAccidentExhaustVolume
                      ? parentObj.entity.VentilationCondition
                          .KitchenNormalAccidentExhaustVolume
                      : ""
                    : ""
                }}
              </span>
              <span class="color font14 paddingRight5">
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenNormalAccidentExhaustVolume
                      ? "(m³/h)"
                      : ""
                    : ""
                }}</span
              >
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span>厨房排风兼事故排风管道入户数量</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderRight font16"
              v-if="parentObj.entity.VentilationCondition"
            >
              <span>
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenNormalAccidentExhaustPipeSize
                      ? parentObj.entity.VentilationCondition
                          .KitchenNormalAccidentExhaustPipeSize.length
                      : 0
                    : 0
                }}
              </span>
              <span class="color font14 paddingRight5">
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenNormalAccidentExhaustPipeSize
                      ? "个"
                      : "个"
                    : "个"
                }}
              </span>
            </div>
            <div class="flex jbetween flex5 height30 borderRight font16" v-else>
              <span>0</span>
              <span class="color font14 paddingRight">个</span>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight"></div>
            <div class="flex5 height30 borderTop borderRight"></div>
          </div>
        </div>
        <div class="flex" v-if="parentObj.entity.VentilationCondition">
          <div class="flex8 flexCol">
            <div class="flex8 flexCol">
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition
                      .KitchenNormalAccidentExhaustPipeSize.length > 0 &&
                    (index == 0 || index == 1)
                  "
                  v-for="(item, index) in parentObj.entity.VentilationCondition
                    .KitchenNormalAccidentExhaustPipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span>排风管道尺寸({{ index + 1 }})</span>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span v-if="item.KNAEPS_A && item.KNAEPS_B">{{
                      item.KNAEPS_A + " X " + item.KNAEPS_B
                    }}</span>
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition.KitchenNormalAccidentExhaustPipeSize.length ==1"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="flex" style="">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition
                      .KitchenNormalAccidentExhaustPipeSize.length > 0 &&
                    (index == 2 || index == 3)
                  "
                  v-for="(item, index) in parentObj.entity.VentilationCondition
                    .KitchenNormalAccidentExhaustPipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span>排风管道尺寸({{ index + 1 }})</span>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span v-if="item.KNAEPS_A && item.KNAEPS_B">
                      {{ item.KNAEPS_A + " X " + item.KNAEPS_B }}</span
                    >
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition.KitchenNormalAccidentExhaustPipeSize.length ==3"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div v-if="parentObj.entity.VentilationCondition">
        <div
          v-if="
            parentObj.entity.VentilationCondition
              .KitchenNormalAccidentExhaustPipeSize
          "
        >
          <InputComponent
            ref="RemarkKitchenNormalAccidentExhaustPipeSizeInput"
            :placeHolder="'请输入备注'"
            :rows="
              parentObj.entity.VentilationCondition
                .KitchenNormalAccidentExhaustPipeSize.length
            "
            :type="'textarea'"
            @input="
              inputChange($event, 'RemarkKitchenNormalAccidentExhaustPipeSize')
            "
          ></InputComponent>
        </div>
      </div>
      <div v-else>
        <InputComponent
          ref="RemarkKitchenNormalAccidentExhaustPipeSizeInput"
          :placeHolder="'请输入备注'"
          :rows="1"
          :type="'textarea'"
          @input="
            inputChange($event, 'RemarkKitchenNormalAccidentExhaustPipeSize')
          "
        ></InputComponent>
      </div>
    </div>

    <!-- 个护排风管道（提供/不提供）-->
    <div class="flex">
      <div class="flex8 flexCol">
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop font16">
              <span>个护排风管道</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="IndividualProtectiveExhaustAirPipeSize"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange(
                    $event,
                    'IndividualProtectiveExhaustAirPipeSizeSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span>个护排风总风量</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight font16"
            >
              <span>
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenNormalAccidentExhaustVolume
                      ? parentObj.entity.VentilationCondition
                          .KitchenNormalAccidentExhaustVolume
                      : ""
                    : ""
                }}
              </span>
              <span class="color font14 paddingRight5">
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .KitchenNormalAccidentExhaustVolume
                      ? "(m³/h)"
                      : ""
                    : ""
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <span>个护排风管道入户数量</span>
            </div>
            <div
              class="flex jbetween flex5 height30 borderRight font16"
              v-if="parentObj.entity.VentilationCondition"
            >
              <span>
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .IndividualProtectiveExhaustAirPipeSize
                      ? parentObj.entity.VentilationCondition
                          .IndividualProtectiveExhaustAirPipeSize.length
                      : 0
                    : 0
                }}
              </span>
              <span class="color font14 paddingRight5">
                {{
                  parentObj.entity.VentilationCondition
                    ? parentObj.entity.VentilationCondition
                        .IndividualProtectiveExhaustAirPipeSize
                      ? "个"
                      : ""
                    : ""
                }}
              </span>
            </div>
            <div class="flex jbetween flex5 height30 borderRight font16" v-else>
              <span>0 </span>
              <span class="color font14 paddingRight5"> 个</span>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight"></div>
            <div class="flex5 height30 borderTop borderRight"></div>
          </div>
        </div>
        <div class="flex" v-if="parentObj.entity.VentilationCondition">
          <div class="flex8 flexCol">
            <div class="flex8 flexCol">
              <div class="flex">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition
                      .IndividualProtectiveExhaustAirPipeSize.length > 0 &&
                    (index == 0 || index == 1)
                  "
                  v-for="(item, index) in parentObj.entity.VentilationCondition
                    .IndividualProtectiveExhaustAirPipeSize"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span>排风管道尺寸({{ index + 1 }})</span>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <span v-if="item.IPEAPS_A && item.IPEAPS_B">{{
                      item.IPEAPS_A + " X " + item.IPEAPS_B
                    }}</span>
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition.IndividualProtectiveExhaustAirPipeSize.length ==1"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="flex" style="">
                <div
                  class="flex flex5"
                  v-if="
                    parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition
                      .IndividualProtectiveExhaustAirPipeSize.length > 0 &&
                    (index == 2 || index == 3)
                  "
                  v-for="(item, index) in parentObj.entity.VentilationCondition
                    .IndividualProtectiveExhaustAirPipeSize"
                >
                  <div
                    class="flex5 height30 borderTop borderRight borderBottom font16"
                  >
                    <span>排风管道尺寸({{ index + 1 }})</span>
                  </div>
                  <div
                    class="flex5 height30 borderTop borderRight borderBottom font16"
                  >
                    <span v-if="item.IPEAPS_A && item.IPEAPS_B">
                      {{ item.IPEAPS_A + " X " + item.IPEAPS_B }}
                    </span>
                  </div>
                </div>
                <!--               布局站位-->
                <div
                    v-if=" parentObj.entity.VentilationCondition &&
                    parentObj.entity.VentilationCondition.IndividualProtectiveExhaustAirPipeSize.length ==3"
                    class="flex flex5"
                >
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                  <div class="flex5 height30 borderTop borderRight font16">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div v-if="parentObj.entity.VentilationCondition">
        <div
          v-if="
            parentObj.entity.VentilationCondition
              .KitchenNormalAccidentExhaustPipeSize
          "
        >
          <InputComponent
            ref="RemarkIndividualProtectiveExhaustAirPipeSizeInput"
            :placeHolder="'请输入备注'"
            :rows="
              parentObj.entity.VentilationCondition
                .KitchenNormalAccidentExhaustPipeSize.length
            "
            :type="'textarea'"
            @input="
              inputChange(
                $event,
                'RemarkIndividualProtectiveExhaustAirPipeSize'
              )
            "
          ></InputComponent>
        </div>
      </div>
      <div v-else>
        <InputComponent
          ref="RemarkIndividualProtectiveExhaustAirPipeSizeInput"
          :placeHolder="'请输入备注'"
          :rows="1"
          :type="'textarea'"
          @input="
            inputChange($event, 'RemarkIndividualProtectiveExhaustAirPipeSize')
          "
        ></InputComponent>
      </div>
    </div>
  </div>
</template>
<script>
import InputComponent from "@/views/Project/view/components/inputComponents/inputComponent.vue";
// 模拟数据
import {
  dianbiaoOptions,
  dianbiaoWeizhiOptions,
  kongtiaoguanzhiOptions,
} from "../../mock.js";
export default {
  props: {
    parentObj: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  components: { InputComponent },
  data() {
    return {
      // 输入框绑定的值
      selectValue: "提供",
      // 电表位置绑定
      dianWeiSelectValue: "",
      // 电表区域备注绑定
      RemarkElectrical: "",
      // 空调管制绑定
      kongtiaoguanzhiSelectValue: "两管",
      // 电表下拉列框绑定列别
      dianOtions: dianbiaoOptions,
      //   空调供热管制下拉列表数据
      kongguanOtions: kongtiaoguanzhiOptions,
    };
  },
  methods: {
    input(row) {
      this.$nextTick(function () {
        // 判断相关备注和下拉提供非提供逻辑  如果没有对应的数组  提供  否则 不提供
        // this.$refs.AirConditioningCondition.value = "";
        // this.$refs.AC_ColdWaterPipeDiameter.value = "";
        // this.$refs.AirConditioningHeatingControl.value = "";
        // this.$refs.VRFConditionReservation.value = "";
        // this.$refs.FreshAirPipelineSize.value = "";
        // this.$refs.KitchenExhaustPipeSize.value = "";
        // this.$refs.KitchenAirReplenishmentPipeSize.value = "";
        // this.$refs.KitchenNormalAccidentExhaustPipeSize.value = "";
        // this.$refs.IndividualProtectiveExhaustAirPipeSize.value = "";
        this.$refs.AirConditioningCondition.value =
          row.AirConditioningConditionSelectValue; //空调（提供/不提供）
        this.$refs.AC_ColdWaterPipeDiameter.value =
          row.AC_ColdWaterPipeDiameterSelectValue; //采暖供热（提供/不提供）
        this.$refs.AirConditioningHeatingControl.value =
          row.AirConditioningHeatingControlSelectValue; //空调及供热管制（两管/四管）
        this.$refs.VRFConditionReservationSelect.value =
          row.VRFConditionReservationSelectValue; //独立VRV安装条件（提供/不提供）
        this.$refs.FreshAirPipelineSize.value =
          row.FreshAirPipelineSizeSelectValue; // //新风管道（提供/不提供）

        this.$refs.KitchenExhaustPipeSize.value =
          row.KitchenExhaustPipeSizeSelectValue; //排油烟管道（提供/不提供）
        this.$refs.KitchenAirReplenishmentPipeSize.value =
          row.KitchenAirReplenishmentPipeSizeSelectValue; //排油烟补风管道（提供/不提供）
        this.$refs.KitchenNormalAccidentExhaustPipeSize.value =
          row.KitchenNormalAccidentExhaustPipeSizeSelectValue; //厨房排风兼事故排风管道（提供/不提供）
        this.$refs.IndividualProtectiveExhaustAirPipeSize.value =
          row.IndividualProtectiveExhaustAirPipeSizeSelectValue; //个护排风管道（提供/不提供）
        this.$refs.AirConditioningHeatingFormInput.keyWords =
          row.AirConditioningHeatingForm; //采暖供热形式输入内容
        this.$refs.RemarkHVACInput.keyWords = row.RemarkHVAC; //空调管道备注
        this.$refs.RemarkAC_ColdHOTWaterPipeDiameterInput.keyWords =
          row.RemarkAC_ColdHOTWaterPipeDiameter; //空调冷热水管道备注
        this.$refs.RemarkFreshAirPipelineSizeInput.keyWords =
          row.RemarkFreshAirPipelineSize; //新风管道备注
        this.$refs.RemarkKitchenExhaustPipeSizeInput.keyWords =
          row.RemarkKitchenExhaustPipeSize; //排油烟管道备注
        this.$refs.RemarkKitchenAirReplenishmentPipeSizeInput.keyWords =
          row.RemarkKitchenAirReplenishmentPipeSize; //排油烟补风管道备注
        this.$refs.RemarkKitchenNormalAccidentExhaustPipeSizeInput.keyWords =
          row.RemarkKitchenNormalAccidentExhaustPipeSize; //厨房排风兼事故排风管道备注
        this.$refs.RemarkIndividualProtectiveExhaustAirPipeSizeInput.keyWords =
          row.RemarkIndividualProtectiveExhaustAirPipeSize; //个户排风管道备注
      });
    },
    // 相关下拉参数处理回调
    inputSelectChange(event, type) {
      console.log("下拉选择", event, type);
      this.parentObj.openSetPrintSelectValue(event, type);
    },
    // 相关备注参数处理回调
    inputChange(event, type) {
      this.parentObj.openSetPrintInputValue(event, type);
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
/*覆盖card样式*/
.el-card {
  border: none !important;
}
/*取消card的阴影*/
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}

/*覆盖element card 卡片样式*/
::v-deep .recordContent {
  .el-card {
    .el-card__body {
      padding: 0px !important;
    }
  }
}
</style>
