<template>
  <!--  这里是给排水模块区域组件-->
  <div class="WSADMajor" style="margin-top: 30px">
    <span class="bottom-span" style="padding-left: 20px">{{
      im.DicName + "信息"
    }}</span>

    <!--    个护通风条件-->
    <div>
      <!--      给水条件-->
      <div class="">
        <div class="" style="margin-top: 10px">
          <div
            v-if="im.children.length !== 0 && im.DicKey === 'WSADMajor'"
            v-for="(m, l) in im.children"
            :key="l + new Date().getTime()"
          >
            <div
              v-if="m.DicKey !== 'RemarkWSAD'"
              style="
                padding: 15px;
                width: 97%;
                text-align: center;
                border: 1px solid #ccc;
                margin: 20px 0 0 0;
              "
            >
              {{
                m.DicKey === "WaterSupplyPipeSize"
                  ? "给水条件"
                  : m.DicKey === "CateringDrainagePipeSize"
                  ? "餐饮排水条件"
                  : m.DicKey === "NonCateringDrainagePipeSize"
                  ? "非餐饮排水条件"
                  : "燃气条件"
              }}
            </div>
            <div v-if="m.DicKey !== 'RemarkWSAD'" style="padding: 10px 50px">
              {{ m.DicName }}
            </div>
            <!--                给水管径-->
            <div class="flex" style="display: flex">
              <div
                style="display: flex; width: 25%"
                v-if="
                  m.DicKey === 'WaterSupplyPipeSize' &&
                  entity.WaterSupplyPipeSize.length !== 0
                "
                v-for="(item, index) in entity.WaterSupplyPipeSize"
                :key="index"
              >
                <div class="flex5 WSADMajor-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 WSADMajor-right background">{{ item }}</div>
              </div>
              <!--              餐饮排水条件-->

              <div
                style="display: flex; width: 25%"
                v-if="
                  m.DicKey === 'CateringDrainagePipeSize' &&
                  entity.CateringDrainagePipeSize.length !== 0
                "
                v-for="(item, index) in entity.CateringDrainagePipeSize"
                :key="index"
              >
                <div class="flex5 WSADMajor-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 WSADMajor-right background">{{ item }}</div>
              </div>
              <!--                非餐饮排水管径-->
              <div
                style="display: flex; width: 25%"
                v-if="
                  m.DicKey === 'NonCateringDrainagePipeSize' &&
                  entity.NonCateringDrainagePipeSize.length !== 0
                "
                v-for="(item, index) in entity.NonCateringDrainagePipeSize"
                :key="index"
              >
                <div class="flex5 WSADMajor-left background">
                  {{ m.DicName }}
                </div>
                <div class="flex5 WSADMajor-right background">{{ item }}</div>
              </div>
              <!--              备注-->
              <!--              <div style="width: 100%" v-if="m.DicKey === 'RemarkWSAD'">-->
              <!--                <div class="WSADMajor-right background">-->
              <!--                  {{ entity.RemarkWSAD }}-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
          <!--          燃气条件-->
          <div
            v-if="im.DicKey === 'NaturalGas'"
            style="
              padding: 15px;
              width: 97%;
              text-align: center;
              border: 1px solid #ccc;
              margin: 20px 0 0 0;
            "
          >
            {{ im.DicName }}
          </div>
          <!--          天然气量 天然气压力-->
          <div class="flex" style="margin-top: 20px">
            <div
              class="flex flex5"
              v-if="
                im.children &&
                (v.DicKey === 'NaturalGasVolume' ||
                  v.DicKey === 'NaturalGasPressure')
              "
              v-for="(v, i) in im.children"
              :key="i"
            >
              <div class="flex flex5">
                <div class="flex5 WSADMajor-left background">
                  {{ v.DicName }}
                </div>
                <div class="flex5 WSADMajor-right background">
                  {{ entity[v.DicKey] }}
                </div>
              </div>
            </div>
          </div>
          <!--  天然气管径-->
          <div
            style="display: flex"
            v-if="
              v.DicKey === 'GasConnectingPipeSize' &&
              entity.GasConnectingPipeSize?entity.GasConnectingPipeSize.length:'' !== 0
            "
            v-for="(v, i) in im.children"
            :key="i"
          >
            <div
              class="flex"
              style="width: 25%"
              v-if="entity.GasConnectingPipeSize.lengt !== 0"
              v-for="(item, index) in entity.GasConnectingPipeSize"
              :key="index"
            >
              <div class="flex5 WSADMajor-left background">
                {{ v.DicName }}
              </div>
              <div class="flex5 WSADMajor-right background">{{ item }}</div>
            </div>
          </div>

          <!--          备注-->
          <div v-if="im.DicKey === 'NaturalGas'">
            <div class="flex">
              <div class="WSADMajor-left background" style="flex: 1.18">
                备注
              </div>
              <div class="WSADMajor-right background" style="flex: 8.82">
                {{ entity.RemarkWSAD }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
  name: "WSADMajor.vue",
  data() {
    // 这里存放数据
    return {};
  },
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    updateRecordType: {
      type: String,
    },
    parentObj: {
      type: Object,
      default: () => {},
    },
    im: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  // 方法集合
  methods: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  //过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {},
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  //进入的时候触发
  activated() {},
  //离开的时候触发
  deactivated() {},
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
.border-top {
  border-top: 1px solid #ccc;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}
.border-left {
  border-left: 1px solid #ccc;
}
.WSADMajor {
  .WSADMajor-left.background {
    background-color: #f7eded;
    border: none !important;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .WSADMajor-right.background {
    background-color: #ffe3e3;
    border: none !important;
    padding-left: 10px;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
