<template>
  <div class="box" style="overflow: hidden;">
    <div style="display: flex;margin-bottom: 5px;">
      <div
        style="display: flex;align-items: center;justify-content: center;height: 30px;line-height: 30px;width: 40px;">
        <i size="30" class="el-icon-back" style="font-size: 23px;cursor: pointer;" @click="handleBack"></i>
      </div>
      <div style="font-size: 20px;font-weight: bold;line-height: 30px; margin-left: 20px;">数据分析-餐饮信息</div>
    </div>

    <el-row style="width: 100%" :gutter="5">
      <el-col :span="4">
        <el-card style=" height: 60px; background-color: #068d74; color: #fff; border-radius: 6px;  width: 100%;
            " body-style="padding: 0 10px;line-height:30px;text-align: center;font-size: 18px;font-weight: bold;">
          <i class="iconfont icon-leijiyongdianliang" style="font-size: 18px"></i>&nbsp;餐饮用电负荷平均指标<br /><span>{{
            homeData.AvgLoadQuota_Electricity ?
              homeData.AvgLoadQuota_Electricity.toFixed(2) : 0 }}W/㎡</span>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card style=" height: 60px;background-color: #f84d4d;color: #fff;border-radius: 6px; width: 100%; "
          body-style="padding: 0 10px;line-height:30px;text-align: center;font-size: 18px;font-weight: bold;">
          <i class="iconfont icon-refuhe" style="font-size: 18px"></i>&nbsp;餐饮热负荷平均指标<br /><span>{{
            homeData.AvgLoadQuota_Heat ? homeData.AvgLoadQuota_Heat.toFixed(2) : 0
          }}W/㎡</span>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card style=" height: 60px; background-color: #7395d3;  color: #fff;  border-radius: 6px;  width: 100%;"
          body-style="padding: 0 10px;line-height:30px;text-align: center;font-size: 18px;font-weight: bold;">
          <i class="iconfont icon-lengfuhe" style="font-size: 18px"></i>&nbsp;餐饮冷负荷平均指标<br /><span>{{
            homeData.AvgLoadQuota_Cooling
              ? homeData.AvgLoadQuota_Cooling.toFixed(2)
              : 0
          }}W/㎡</span>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card style=" height: 60px;  background-color: #6665b8;   color: #fff;  border-radius: 6px;  width: 100%; "
          body-style="padding: 0 10px;line-height:30px;text-align: center;font-size: 18px;font-weight: bold;">
          <i class="iconfont icon-zhifutubiaoku_yangan-xian" style="font-size: 18px"></i>&nbsp;餐饮总排油烟量平均指标<br /><span>{{
            homeData.AvgQuota_OilFumeDischarge
              ? homeData.AvgQuota_OilFumeDischarge.toFixed(2)
              : 0
          }}/换气次数</span>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card style=" height: 60px; background-color: #fac858; color: #fff; border-radius: 6px;  width: 100%;
            " body-style="padding: 0 10px;line-height:30px;text-align: center;font-size: 18px;font-weight: bold;">
          <i class="el-icon-odometer"></i>&nbsp;餐饮商铺数量/{{
            homeData.ShopCountCatering
          }}<br /><span>占比指数/{{ homeData.ShopCountProportion }}%</span>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card style=" height: 60px;  background-color: #91cc75;  color: #fff; border-radius: 6px; width: 100%; "
          body-style="padding: 0 10px;line-height:30px;text-align: center;font-size: 18px;font-weight: bold;">
          <i class="el-icon-stopwatch"></i>&nbsp;餐饮商铺面积/{{
            homeData.ShopAreaCatering
          }}㎡<br /><span>占比指数/{{ homeData.ShopAreaProportion }}%</span>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin-top: 5px">
      <el-col :span="12" v-loading="MG_CateringReturnDataLoading">
        <el-card style=" width: 100%;" class="InfoCard">
          <el-row style="height: 35px;line-height: 35px;">
            <el-col :span="16">
              <span v-if="TopRadio == 1" style="font-size: 18px; font-weight: bold">餐饮用电负荷总量统计</span>
              <span v-if="TopRadio == 2" style="font-size: 18px; font-weight: bold">餐饮用电负荷总量占比</span>
            </el-col>
            <el-col :span="8" style="text-align: right;">
              <el-radio-group v-model="TopRadio" size="small">
                <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;数量</el-radio-button>
                <el-radio-button :label="2"><i class="el-icon-s-help"></i>&nbsp;占比</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
          <el-row v-if="TopRadio == 1">
            <EchartsIndex refId="optionTopData" :option="optionTopDataBar" styleStr="width: 100%;height: 295px;"
              v-if="this.MG_CateringReturnData.LoadQuota_Electricity && this.MG_CateringReturnData.LoadQuota_Electricity.length > 0" />
            <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
          </el-row>

          <el-row v-if="TopRadio == 2">
            <EchartsIndex refId="optionTopData2" :option="optionTopDataPie" styleStr="width: 100%;height: 290px;"
              v-if="this.MG_CateringReturnData.LoadQuota_Electricity && this.MG_CateringReturnData.LoadQuota_Electricity.length > 0" />
            <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="12" v-loading="MG_CateringReturnDataLoading">
        <el-card style=" width: 100%" class="InfoCard">
          <el-row style="height: 35px;line-height: 35px;">
            <el-col :span="16">
              <span v-if="TopRadio2 == 1" style="font-size: 18px; font-weight: bold">餐饮排油烟总量统计</span>
              <span v-if="TopRadio2 == 2" style="font-size: 18px; font-weight: bold">餐饮排油烟总量占比</span>
            </el-col>
            <el-col :span="8" style="text-align: right;">
              <el-radio-group v-model="TopRadio2" size="small">
                <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;数量</el-radio-button>
                <el-radio-button :label="2"><i class="el-icon-s-help"></i>&nbsp;占比</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
          <el-row v-if="TopRadio2 == 1">
            <EchartsIndex refId="right1" :option="optionTopDataBar2" styleStr="width: 100%;height: 295px;"
              v-if="this.MG_CateringReturnData.OilFumeDischarge && this.MG_CateringReturnData.OilFumeDischarge.length > 0" />
            <el-empty description="暂无数据" style="width: 100%; height: 295px;" v-else></el-empty>
          </el-row>
          <el-row v-if="TopRadio2 == 2">
            <EchartsIndex refId="right2" :option="optionTopDataPie2" styleStr="width: 100%;height: 295px;"
              v-if="this.MG_CateringReturnData.OilFumeDischarge && this.MG_CateringReturnData.OilFumeDischarge.length > 0" />
            <el-empty description="暂无数据" style="width: 100%; height: 295px;" v-else></el-empty>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin-top: 5px">
      <el-col :span="12" v-loading="MG_CateringReturnDataLoading">
        <el-card style="width: 100%" class="InfoCard">
          <el-row style="line-height: 35px;height: 35px;">
            <el-col :span="16">
              <span style="font-weight: bold;font-size: 18px;" v-if="BottomRadio == 1">
                不同业态餐饮的用电负荷变化曲线
              </span>
              <span style="font-weight: bold;font-size: 18px;" v-if="BottomRadio == 2">
                不同业态餐饮的排油烟量变化曲线
              </span>

            </el-col>
            <el-col :span="8" style="text-align: right;">
              <el-radio-group v-model="BottomRadio" size="small" @change="changeBottomRadio">
                <el-radio-button :label="1">用电负荷</el-radio-button>
                <el-radio-button :label="2">排油烟</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
          <EchartsIndex refId="optionBottomData" :option="optionBottomData" styleStr="width: 100%;height: 295px;"
            v-if="this.MG_CateringReturnData.LoadQuota_Electricity_Line && this.MG_CateringReturnData.LoadQuota_Electricity_Line.length > 0" />
          <el-empty description="暂无数据" style="width: 100%; height: 295px;" v-else></el-empty>
        </el-card>
      </el-col>
      <el-col :span="12" v-loading="MG_CateringReturnDataLoading">
        <el-card style="width: 100%;" class="InfoCard">
          <el-row style="font-weight: bold;font-size: 18px;height: 35px;line-height: 35px;">
            <el-col :span="16">
              <span v-if="BottomRadio2 == 1">隔油池总面积</span>
              <span v-if="BottomRadio2 == 2">隔油池平均指标</span>
            </el-col>
            <el-col :span="8" style="text-align: right;">
              <el-radio-group v-model="BottomRadio2" size="small" @change="changeBottomRadio2">
                <el-radio-button :label="1">总面积</el-radio-button>
                <el-radio-button :label="2">平均指标</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
          <EchartsIndex refId="optionBottomData2" :option="optionBottomData2" styleStr="width: 100%;height: 295px;"
            v-if="this.MG_CateringReturnData.WaterOilSeparator && this.MG_CateringReturnData.WaterOilSeparator.length > 0" />
          <el-empty description="暂无数据" style="width: 100%; height: 295px;" v-else></el-empty>
        </el-card>
      </el-col>
    </el-row>


  </div>
</template>

<script>
// 引入echarts图表组件
import EchartsIndex from "@/components/Echarts/index";

export default {
  name: "MG_CateringDataPage", // 餐饮数据模块
  components: {
    EchartsIndex,
  },
  props: {
    typeList: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: String,
      default: "",
    },
    homeData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      TopRadio: 1,
      TopRadio2: 1,
      BottomRadio: 1,
      BottomRadio2: 1,
      MG_CateringReturnData: {

      },
      MG_CateringReturnDataLoading: false,

      optionTopDataBar: {
        grid: {
          top: 50, // 设置图表距离容器顶部的距离为10%\
          left: 120,
          bottom: 30
          // 其他grid属性...
        },
        label: {
          show: true,
          position: "top",
          formatter: `{c}kW`
        },
        legend: {
          //图例系列
          show: true,
          top: 5,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        tooltip: {
          trigger: "item",
          formatter: `{a} : {c}kW`
        },
        xAxis: {

          type: "category",
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} kW",
          },
          name: "电负荷总量（kW）",
          nameLocation: "end",
          nameTextStyle: {
            width: 200,
            align: "right",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "value",
        },
        series: [],
      },
      optionTopDataPie: {
        tooltip: {
          formatter: "{b} : {c}kW ({d}%)",
        },

        legend: {
          show: true,
          top: 5,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        labelLine: {
          show: false,
          length: 1,
          length2: 1,
        },
        label: {
          show: true,
          formatter: "{b} : {c}kW ({d}%)",
        },
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "60%"],
            selectedMode: "single",
            data: [],
          },
        ],
      },
      optionTopDataBar2: {
        grid: {
          top: 50, // 设置图表距离容器顶部的距离为10%\
          left: 100,
          bottom: 30
          // 其他grid属性...
        },
        label: {
          show: true,
          position: "top",
          formatter: `{c}m³/h`
        },
        legend: {
          //图例系列
          show: true,
          top: 5,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        tooltip: {
          trigger: "item",
          formatter: `{a} : {c}m³/h`
        },
        xAxis: {
          type: "category",

          data: [],
        },
        yAxis: {
          name: "排油烟总量（m³/h）",
          axisLabel: {
            formatter: "{value} m³/h",
          },
          nameLocation: "end",
          nameTextStyle: {
            width: 200,
            align: "right",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "value",
        },
        series: [],
      },
      optionTopDataPie2: {
        tooltip: {
          formatter: "{b} : {c}m³/h ({d}%)",
        },

        legend: {
          show: true,
          top: 5,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        labelLine: {
          show: false,
          length: 1,
          length2: 1,
        },
        label: {
          show: true,
          formatter: "{b} : {c}m³/h ({d}%)",
        },
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "60%"],
            selectedMode: "single",
            data: [],
          },
        ],
      },
      optionBottomData: {
        grid: {
          top: 60,
          left: 120,
          bottom: 80
        },
        xAxis: {
          name: "日期信息",
          nameLocation: "center",
          nameGap: 65,
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "category",
          data: [],
          axisLabel: {
            // 坐标轴刻度标签的相关设置。
            show: true,
            interval: 0,
            rotate: "-50",
          },
        },

        yAxis: {
          name: "",
          axisLabel: {

          },
          nameLocation: "end",
          nameTextStyle: {
            width: 200,
            align: "right",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "value",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          //图例系列
          show: true,
          top: 5,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        series: [
        ],
      },
      optionBottomData2: {
        grid: {
          top: 60,
          left: 100,
          bottom: 80
        },
        xAxis: {
          name: "日期信息",
          nameLocation: "center",
          nameGap: 65,
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "category",
          data: [],
          axisLabel: {
            // 坐标轴刻度标签的相关设置。
            show: true,
            interval: 0,
            rotate: "-50",
          },
        },

        yAxis: {
          name: "",
          axisLabel: {
            formatter: "{value} ㎡",
          },
          nameLocation: "end",
          nameTextStyle: {
            width: 200,
            align: "right",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          type: "value",
        },
        tooltip: {

        },
        legend: {
          //图例系列
          show: true,
          top: 5,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        series: [
          // {
          //   name: item.name,
          //   data: [...item.value],
          //   type: "line",
          //   smooth: true,
          //   areaStyle: {

          //   },
          // }
        ],
      },

    };
  },
  mounted() {

  },
  created() {
    this.getMG_CateringReturnData();
  },
  methods: {
    changeBottomRadio() {
      if (this.BottomRadio === 1) {
        this.optionBottomData.legend.data = []
        this.optionBottomData.series = []
        this.optionBottomData.xAxis.data = []
        this.optionBottomData.yAxis.name = '用电负荷总量（kW）'
        this.optionBottomData.yAxis.axisLabel.formatter = "{value} kW"
        if (this.MG_CateringReturnData.LoadQuota_Electricity_Line && this.MG_CateringReturnData.LoadQuota_Electricity_Line.length > 0) {
          this.optionBottomData.legend.data = []
          this.optionBottomData.series = []
          this.optionBottomData.xAxis.data = []
          this.optionBottomData.tooltip = {
            formatter: (a) => {
              return a.name.substring(0, 10) + '<br/>' + a.seriesName + ':' + a.value + 'kW'
            }
          }
          this.MG_CateringReturnData.LoadQuota_Electricity_Line[0].value.forEach(item => {
            this.optionBottomData.xAxis.data.push(item.name.substring(0, 10))
          })
          this.MG_CateringReturnData.LoadQuota_Electricity_Line.forEach(item => {
            this.optionBottomData.legend.data.push(item.name);
            this.optionBottomData.series.push({
              name: item.name,
              data: [...item.value],
              type: "line",
              smooth: true,
              areaStyle: {

              },
              // barWidth: 20,
            });

          })

        }
      } else if (this.BottomRadio === 2) {
        this.optionBottomData.legend.data = []
        this.optionBottomData.series = []
        this.optionBottomData.xAxis.data = []
        this.optionBottomData.yAxis.name = '排油烟总量（m³/h）'
        this.optionBottomData.yAxis.axisLabel.formatter = "{value} m³/h"
        if (this.MG_CateringReturnData.OilFumeDischarge_Line && this.MG_CateringReturnData.OilFumeDischarge_Line.length > 0) {

          this.optionBottomData.tooltip = {
            formatter: (a) => {
              return a.name.substring(0, 10) + '<br/>' + a.seriesName + ':' + a.value + 'm³/h'
            }
          }


          this.MG_CateringReturnData.OilFumeDischarge_Line[0].value.forEach(item => {
            this.optionBottomData.xAxis.data.push(item.name.substring(0, 10))
          })
          this.MG_CateringReturnData.OilFumeDischarge_Line.forEach(item => {
            this.optionBottomData.legend.data.push(item.name);
            this.optionBottomData.series.push({
              name: item.name,
              data: [...item.value],
              type: "line",
              smooth: true,
              areaStyle: {

              },
              // barWidth: 20,
            });

          })

        }
      }

    },
    changeBottomRadio2() {
      if (this.MG_CateringReturnData.WaterOilSeparator && this.MG_CateringReturnData.WaterOilSeparator.length > 0) {
        if (this.BottomRadio2 === 1) {
          this.optionBottomData2.series = []
          this.optionBottomData2.xAxis.data = []
          this.optionBottomData2.yAxis.name = "总面积（m³）"
          this.optionBottomData2.yAxis.axisLabel.formatter = "{value} m³"
          this.optionBottomData2.yAxis.nameTextStyle = {
            width: 200,
            align: 'right',
          }
          let arr = this.MG_CateringReturnData.WaterOilSeparator.map(item => {
            return {
              name: item.name ? item.name.substring(0, 10) : '',
              value: item.volume
            }
          })
          arr.forEach(item => {
            this.optionBottomData2.xAxis.data.push(item.name)
          })
          this.optionBottomData2.tooltip = {
            trigger: "item",
            formatter: (a) => {
              console.log(a)
              return a.seriesName + '<br/>' + a.name + '：' + a.value + 'm³'
            }
          }
          this.optionBottomData2.series.push(
            {
              name: '隔油池总面积',
              data: [...arr],
              type: "line",
              smooth: true,
              areaStyle: {
              },
            }
          )
          console.log(this.optionBottomData2)
        } else if (this.BottomRadio2 === 2) {
          this.optionBottomData2.series = []
          this.optionBottomData2.xAxis.data = []
          this.optionBottomData2.yAxis.name = "平均指标（m³/㎡）"
          this.optionBottomData2.yAxis.axisLabel.formatter = "{value} m³/㎡"
          this.optionBottomData2.yAxis.nameTextStyle = {
            width: 200,
            align: 'right',
          }
          let arr = this.MG_CateringReturnData.WaterOilSeparator.map(item => {
            return {
              name: item.name ? item.name.substring(0, 10) : '',
              value: item.volume_avg ? item.volume_avg.toFixed(2) : ''
            }
          })
          arr.forEach(item => {
            this.optionBottomData2.xAxis.data.push(item.name)
          })
          this.optionBottomData2.tooltip = {
            trigger: "item",
            formatter: (a) => {
              console.log(a)
              return a.seriesName + '<br/>' + a.name + '：' + a.value + 'm³/㎡'
            }
          }
          this.optionBottomData2.series.push(
            {
              name: '隔油池平均指标',
              data: [...arr],
              type: "line",
              smooth: true,
              areaStyle: {

              },
            }

          )
        }
      }

    },
    getMG_CateringReturnData() {
      this.MG_CateringReturnDataLoading = true
      this.$http
        .post("/Base_ProjectManage/Project_DataAnalysis/GetMG_CateringReturnData?projectId=" + this.projectId).then(res => {
          this.MG_CateringReturnDataLoading = false
          if (res.Success) {
            this.MG_CateringReturnData = res.Data || {}

            if (this.MG_CateringReturnData.LoadQuota_Electricity && this.MG_CateringReturnData.LoadQuota_Electricity.length > 0) {
              this.optionTopDataBar.legend.data = []
              this.optionTopDataBar.series = []
              this.optionTopDataPie.legend.data = []
              this.optionTopDataPie.series[0].data = []
              this.MG_CateringReturnData.LoadQuota_Electricity.forEach(item => {
                this.optionTopDataBar.legend.data.push(item.name);
                this.optionTopDataBar.series.push({
                  name: item.name,
                  data: [item.value],
                  type: "bar",
                  emphasis: {
                    focus: "series",
                  },
                  showBackground: true,
                  barGap: '100%',
                  // barWidth: 20,
                });
                this.optionTopDataPie.legend.data.push(item.name);
                this.optionTopDataPie.series[0].data.push({
                  name: item.name,
                  value: item.value,
                })
              })

            }

            if (this.MG_CateringReturnData.OilFumeDischarge && this.MG_CateringReturnData.OilFumeDischarge.length > 0) {
              this.optionTopDataBar2.legend.data = []
              this.optionTopDataBar2.series = []
              this.optionTopDataPie2.legend.data = []
              this.optionTopDataPie2.series[0].data = []
              this.MG_CateringReturnData.OilFumeDischarge.forEach(item => {
                this.optionTopDataBar2.legend.data.push(item.name);
                this.optionTopDataBar2.series.push({
                  name: item.name,
                  data: [item.value],
                  type: "bar",
                  emphasis: {
                    focus: "series",
                  },
                  showBackground: true,
                  // barWidth: '10%',
                  barGap: '100%',

                });
                this.optionTopDataPie2.legend.data.push(item.name);
                this.optionTopDataPie2.series[0].data.push({
                  name: item.name,
                  value: item.value,
                })
              })

            }
            this.changeBottomRadio()
            this.changeBottomRadio2()

          } else {
            this.MG_CateringReturnData = {}
            this.$message.error('获取餐饮数据失败');
          }
        }).catch(() => {
          this.MG_CateringReturnDataLoading = false
          this.MG_CateringReturnData = {}
          this.$message.error('获取餐饮数据失败');

        })

    },

    handleBack() {
      this.$emit("changePageblock", true);
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  padding: 0px;
  box-sizing: border-box;
}

.dataStripe_title {
  display: block;
  line-height: 45px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

// 数据条卡片内显示值
.dataStripe_value {
  display: block;
  line-height: 45px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

.dataStripe_i {
  font-size: 40px;
  color: #ffffff;
  line-height: 90px;
  // margin-left: 20px;
}

.InfoCard {
  border-radius: 10px;
  padding: 0px 5px;
  box-sizing: border-box;
  border: 5px solid #c3c3c3;
}
</style>
