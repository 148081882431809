<template>
  <!--  带搜索的下拉选择组件-->
  <!--  ****************** popper-append-to-body 是否将弹出框插入至 body 元素。在弹出框的定位出现问题时，可将该属性设置为 false 如果需要覆盖当前默认样式  需要加该属性，***************-->
  <div
    class="about"
    style="position: relative"
    :style="{ textAlign: position }"
  >
    <div v-if="rightText" style="position: absolute; z-index: 999; right: 25px">
      {{ rightText }}
    </div>
    <el-select
      :style="{ width: width }"
      :disabled="disabled"
      v-model="selectVal"
      :popper-append-to-body="false"
      ref="select"
      @blur="clearData"
      @visible-change="visibleChange"
      @clear="selectClearChange"
      clearable
    >
      <!-- // 设置一个input框用作模糊搜索选项功能 -->
      <el-input
        class="input"
        placeholder="关键词"
        prefix-icon="el-icon-search"
        v-model="keyWordFilter"
        @input="handleInput"
        @clear="clear"
        clearable
      ></el-input>
      <!-- // 设置一个隐藏的下拉选项,选项显示的是汉字label,值是value //
      如果不设置一个下拉选项，下面的树形组件将无法正常使用 -->
      <el-option hidden key="id" :value="selectVal" :label="selectName">
      </el-option>
      <div class="down" style="">
        <ul style="">
          <li
            v-for="(item, index) in chldrenSearchList"
            :key="index"
            @click.stop="handleSearchList(item)"
            style="
              padding: 5px 0 5px 0px;
              color: #848689;
              font-size: 13px;
              display: flex;
            "
          >
            {{ item.label }}
          </li>
          <li v-show="searchResult" style="text-align: center">暂无搜索结果</li>
        </ul>
      </div>
    </el-select>
  </div>
</template>
<script>
//引入工具函数
import tools from "@/utils/tools";

export default {
  name: "selectOptions",
  props: {
    // 选择框的宽度
    width: {
      type: String,
      default: "100%",
    },
    position: {
      type: String,
      default: "",
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 标志属于哪个区块的下拉搜索列表
    optionsType: {
      type: String,
    },
    // 属于哪个区域下的下拉搜索列表 设计信息 工程信息  合约信息
    moduleType: {
      type: String,
    },
    value: {
      type: String,
      default: "",
    },
    url: {
      type: String,
    },
    searchList: {
      type: Array,
      default: () => [],
    },
    //   下拉数据框右侧的单位
    rightText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 用于改变子组件
      chldrenSearchList: this.searchList,
      selectVal: "", // select框的绑定值
      selectName: "", // select框显示的name
      keyWordFilter: "", // 搜索框绑定值，用作过滤
      solist: [], //搜索存储列表
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: "",
      },
      // 总条数
      total: 0,
      // 搜索出来是否有数据
      searchResult: false,
      timer: null,
    };
  },
  created() {
    this.selectVal = this.value;
  },

  watch: {
    keyWordFilter: {
      handler(v) {
        if (!v) {
          // 监听输入结果 。如果删除了所有输入 根据监听的结果重新渲染下拉列表 避免输入清空时下拉列表不更新
          this.chldrenSearchList = [];
          let arr = this.searchList;
          this.chldrenSearchList = arr;
          // 如果搜任何内容 设置显示暂无搜索结果
          this.searchResult = false;
        }
      },
    },
    value: {
      handler(v) {
        this.selectVal = this.value;
      },
    },
    searchList: {
      handler(v) {
        this.chldrenSearchList = this.searchList;
      },
    },
    searchResult(n) {
      console.log("是否有数据", n);
    },
  },
  methods: {
    //   下拉下拉数据的清空按钮
    selectClearChange() {
      console.log("清空了");
      this.$emit("clear", this.optionsType);
    },
    // 输入框失去焦点事件
    clearData() {
      this.keyWordFilter = "";
    },
    // 下拉框出现/隐藏时触发	出现则为 true，隐藏则为 false
    visibleChange(e) {
      if (!e) {
        setTimeout(() => {
          this.clearData();
        }, 150);
      } else {
        this.search();
      }
    },
    //   根据关键字搜索
    handleInput() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.search();
      }, 300);
    },

    // 模拟搜索防抖
    async search() {
      this.queryParams.keyword = this.keyWordFilter;
      if (this.keyWordFilter) {
        let newKewordsArr = [];
        // 查询匹配到的关键词
        this.searchList.forEach((item) => {
          // 判断当前输入的搜索的关键词再下拉列表中是否存在存在则过滤出来重新赋值
          if (this.keyWordFilter) {
            if (item.label.indexOf(this.keyWordFilter) != -1) {
              newKewordsArr.push(item);
            } else {
            }
          }
        });

        // 数组去重
        let newSearchList = tools.arrayDeduplication(newKewordsArr);
        // 将最终搜索好的数组重新赋值下拉列表数据
        this.chldrenSearchList = newSearchList;
        if (this.chldrenSearchList.length == 0) {
          //如果没有匹配到的关键词  显示暂无搜索结果
          this.searchResult = true;
        } else {
          this.searchResult = false;
        }
      }
    },
    // 清空输入框
    clear() {
      this.keyWordFilter = "";
      // this.$emit("clear");
    },
    //   搜索列表点击
    handleSearchList(item) {
      console.log("列表点击", item);
      this.selectVal = item.label;
      this.$emit("handleSearchList", item, this.optionsType, this.moduleType);
      this.$emit("modelOrigin", item, this.optionsType, this.moduleType);
      this.visibleChange();
      this.$refs.select.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
/**覆蓋下拉选择里的输入框的下角标*/
::v-deep .el-input__icon {
  line-height: 0 !important;
}

/**********************************由于当前下拉框区域是隐藏  所以需要再标签上追加popper-append-to-body属性 才能再当前页面覆盖样式 否则会影响全局
参考网址https://blog.csdn.net/weixin_44487537/article/details/119387367**************************************8888*/
::v-deep .el-select-dropdown.el-popper {
  width: 123px !important;
}

::v-deep .el-scrollbar__bar {
  overflow-x: hidden;
}

::v-deep .el-input--mini .el-input__inner {
  height: 32px;
}

.input {
  width: 80%;
  margin: 10px;
}

//副歌el-select  輸入框的寬度
::v-deep .selected {
  .el-input {
    border: 2px solid red !important;
    width: 30% !important;
  }
}

.down {
  ul {
    max-height: 100px;
    overflow-y: scroll;
    margin: 0;
    padding: 0 12px;
    list-style: none;
    //*****************************************覆盖局部区域的滚动条样式*****************************************
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      background: #fc0f0a;
      box-shadow: inset 0 0 5px rgb(59, 193, 230);
    }

    //滚动条底层颜色!
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #ededed;

      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    }

    li {
      overflow: hidden; // 溢出隐藏
      white-space: nowrap; // 强制一行
      //text-overflow: ellipsis; // 文字溢出显示省略号
      cursor: pointer;

      &:hover {
        color: rgb(0, 81, 255);
      }
    }
  }
}
</style>
