<!-- tableItem里的表格最外层递归子组件 -->
<template>
  <!-- 表格最外层递归子组件 -->
  <div style="width:100%">
    <el-table
        border
        :show-summary="isShowSum"
        :data="tableData"
        :height="height"
        :style="{width: windowWidth+ 'px'}"
        :header-cell-style="{
        background: '#eff2f6',
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '14px',
        'text-align': alignType,
      }"
    >
      <!--  @setIsShow="setIsShow" -->
      <template>
        <column-item-copy
            v-for="item in col"
            :key="item.DicKey"
            :col="item"
            :isShow="isShow"
            @setIsShow="setIsShow"
        >
        </column-item-copy>
      </template>

      <!-- 后续需要打开 -->
      <!-- <el-table-column
      v-if="isShowBox"
      type="selection"
      width="55"
      align="center"
      @selection-change="handleSelectionChange"
    >
    </el-table-column> -->
      <template #empty>
        <div class="empty" style="height:200px;">
          <img
              class="empty-img"
              style="margin:30px auto;height:102px;width:134px;"
              src=""
              alt
          />
          <span class="empty-desc">暂无数据</span>
        </div>
      </template>
    </el-table>
  </div>
</template>
<script>
import ColumnItemCopy from "../view/columnltem.vue";

export default {
  name: "TableItem",
  components: {
    ColumnItemCopy,
  },
  props: {
    //表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    //树型结构表头数据
    col: {
      type: Array,
      default: () => [],
    },
    // 是否显示表格区域快
    isShow: {
      type: Number,
      default: 1,
    },
    //是否在表格下方显示合计
    isShowSum: {
      type: Boolean,
      default: false,
    },
    //判断单元格文字是居中还是左对齐显示,默认居中
    alignType: {
      type: String,
      default: "center",
    },
    //设置表格高度,固定表头
    height: {
      type: String,
      default: null,//默认不固定表头
    },
    //判断是否显示多选框
    isShowBox: {
      type: Boolean,
      default: true,//默认不展示
    },
  },
  data() {
    return {
      // 浏览器当前可视窗口高度
      windowHeight: 0,
      // 浏览器当前可视窗口宽度
      windowWidth: 0,
    };
  },
  created() {
  },
  mounted() {
    // 动态获取当前浏览器窗口的高度
    this.getWindowHeight(); // 在初始化时获取窗口高度
    window.addEventListener('resize', this.getWindowHeight); // 在窗口大小改变时重新获取窗口高度

  }, //生命周期 - 挂载完成
  activated() {
  }, //若页面有keep-alive缓存，此函数会触发
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowHeight); // 组件销毁时移除resize事件监听器
  },
  methods: {

    // 动态获取当前浏览器窗口的高度 宽度  -30  是减去其余头部的区域部分   剩余需要显示的，，后续可以调整减号后的数字调整高度大小
    getWindowHeight() {
      this.windowHeight = window.innerHeight - 340;
      this.windowWidth = window.innerWidth - 130;
    },
    // 表格编辑
    handleEdit(val) {
      this.$emit('handleSelectionChange', '编辑')
    },
    // 表格查看
    handleDetail(val) {
      this.$emit('handleDetailChangeCopy', val)
    },
    setIsShow(data) {
      this.$emit('parentTableDetail', data)

    }
  },
};
</script>
<style>
/* 处理表格表头和内容错位问题 */
.el-table th.gutter {
  display: table-cell !important;
}

.el-table th,
.el-table td {
  padding: 0px 0 !important;
}

/**
修改表格样式
改变边框颜色
 */
.el-table--border, .el-table--group {
  border: 2px solid #ddd !important;
}

/**
改变表格内竖线颜色
*/
.el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ddd !important;
}

/**
改变表格内行线颜色
*/
.el-table td, .el-table th, .el-table th.is-leaf {
  border-bottom: 1px solid #ddd !important;
}

.el-table thead tr th {
  border-color: #8c939d;
}

/* g改变表头字体颜色 */
.el-table thead {
  color: black;
}
</style>


