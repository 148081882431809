<template>
  <!--  基本信息部分-->
  <div>
    <div class="flex" style="border: 2px solid #000">
      <div class="flex8 flexCol">
        <!--              顶部备注区域布局站位-->
        <div class="flex" style="height: 22px">
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
        </div>
        <!--              内容区域-->
        <div class="flex" style="">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <div>商铺编号</div>
            </div>
            <div class="flex5 height30 borderTop borderRight font16">
              <div>{{ entity.ShopCode ? entity.ShopCode : "" }}</div>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 flexAcneter borderTop borderRight font16">
              <div>商铺品牌</div>
            </div>
            <div class="flex5 flexAcneter borderTop borderRight font16">
              <div>{{ entity.ShopBrand ? entity.ShopBrand : "" }}</div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <div>业态级别</div>
            </div>
            <div class="flex5 height30 borderTop borderRight font16">
              <div>
                {{ entity.ShopFormatClass ? entity.ShopFormatClass : "" }}
              </div>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              业态类型
            </div>
            <div class="flex5 height30 borderTop borderRight font16">
              <div>
                {{ entity.ShopFormatName ? entity.ShopFormatName : "" }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <div>楼栋信息</div>
            </div>
            <div class="flex5 height30 borderTop borderRight font16">
              <div>{{ entity.BuildingCode ? entity.BuildingCode : "" }}</div>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 height30 borderTop borderRight font16">
              <div>地上地下</div>
            </div>
            <div class="flex5 height30 borderTop borderRight font16">
              <div v-if="entity.IsOnGround">
                {{
                  entity.IsOnGround == 1
                    ? "地上"
                    : entity.IsOnGround == 2
                    ? "地下"
                    : "屋面"
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div
              class="flex5 height30 borderTop borderRight borderBottom font16"
            >
              <div>楼层信息</div>
            </div>
            <div
              class="flex5 height30 borderTop borderRight borderBottom font16"
            >
              <div>{{ entity.Storey ? entity.Storey : "" }}</div>
            </div>
          </div>
          <div class="flex flex5">
            <div
              class="flex5 height30 borderTop borderRight borderBottom font16"
            >
              <div>商铺面积</div>
            </div>
            <div
              class="flex jbetween flex5 height30 borderTop borderRight borderBottom font16"
            >
              <div>{{ entity.ShopArea ? entity.ShopArea : "" }}</div>
              <span v-if="entity.ShopArea" class="paddingRight5 color font14"
                >(m²)</span
              >
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="text-center font16">备注</div>
        <div style="padding: 1px 0 0 0">
          <InputComponent
            ref="RemarkShopBasePrintInput"
            :placeHolder="'请输入备注'"
            :rows="4"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkShopBasePrint')"
          ></InputComponent>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputComponent from "@/views/Project/view/components/inputComponents/inputComponent.vue";

export default {
  props: {
    parentObj: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  components: { InputComponent },
  data() {
    return {
      // 基础信息备注
      RemarkInputValue: "",
      // 输入框绑定的值
      // 下拉列框绑定列别
      options: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
    };
  },
  methods: {
    input(row) {
      this.$refs.RemarkShopBasePrintInput.keyWords = row.RemarkShopBasePrint
        ? row.RemarkShopBasePrint
        : "";
    },
    // 相关下拉参数处理回调
    inputSelectChange(event, type) {
      this.parentObj.openSetPrintSelectValue(event, type);
    },
    // 相关备注参数处理回调
    inputChange(event, type) {
      this.parentObj.openSetPrintInputValue(event, type); //基础信息备注
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
/*覆盖card样式*/
.el-card {
  border: none !important;
}
/*取消card的阴影*/
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}

/*覆盖element card 卡片样式*/
::v-deep .recordContent {
  .el-card {
    .el-card__body {
      padding: 0px !important;
    }
  }
}
</style>
