var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex",staticStyle:{"border":"2px solid #000"}},[_c('div',{staticClass:"flex8 flexCol"},[_vm._m(0),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex5"},[_vm._m(1),_c('div',{staticClass:"flex5"},[_c('InputComponent',{ref:"IndoorFire",attrs:{"type":'select',"options":_vm.dianOtions},on:{"change":function($event){return _vm.inputSelectChange($event, 'IndoorFireSelectValue')}}})],1)]),_c('div',{staticClass:"flex flex5"},[_vm._m(2),_c('div',{staticClass:"flex5"},[_c('InputComponent',{ref:"IndoorFireNumberInput",attrs:{"suffixText":'个',"type":'input'},on:{"input":function($event){return _vm.inputChange($event, 'IndoorFireNumber')}}})],1)])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex5"},[_vm._m(3),_c('div',{staticClass:"flex5"},[_c('InputComponent',{ref:"SpraySystem",attrs:{"type":'select',"options":_vm.dianOtions},on:{"change":function($event){return _vm.inputSelectChange($event, 'SpraySystemSelectValue')}}})],1)]),_c('div',{staticClass:"flex flex5"},[_vm._m(4),_c('div',{staticClass:"flex5"},[_c('InputComponent',{ref:"SmokeDetectionAlarmSystem",attrs:{"type":'select',"options":_vm.dianOtions},on:{"change":function($event){return _vm.inputSelectChange(
                  $event,
                  'SmokeDetectionAlarmSystemSelectValue'
                )}}})],1)])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex5"},[_vm._m(5),_c('div',{staticClass:"flex5"},[_c('InputComponent',{ref:"FireSmokeExhaustInletPipe",attrs:{"type":'select',"options":_vm.dianOtions},on:{"change":function($event){return _vm.inputSelectChange(
                  $event,
                  'FireSmokeExhaustInletPipeSelectValue'
                )}}})],1)]),_c('div',{staticClass:"flex flex5"},[_vm._m(6),_c('div',{staticClass:"flex5"},[_c('InputComponent',{ref:"TotalAirVolumeOfFireSmokeExhaustInput",attrs:{"suffixText":'m³/h',"type":'input'},on:{"input":function($event){return _vm.inputChange($event, 'TotalAirVolumeOfFireSmokeExhaust')}}})],1)])])]),_c('div',[_c('div',{staticClass:"text-center font16"},[_vm._v("备注")]),_c('div',[_c('InputComponent',{ref:"RemarkIndoorFireInput",attrs:{"placeHolder":'请输入备注',"rows":1,"type":'textarea'},on:{"input":function($event){return _vm.inputChange($event, 'RemarkIndoorFire')}}}),_c('InputComponent',{ref:"RemarkSpraySystemInput",attrs:{"placeHolder":'请输入备注',"rows":1,"type":'textarea'},on:{"input":function($event){return _vm.inputChange($event, 'RemarkSpraySystem')}}}),_c('InputComponent',{ref:"RemarkFireSmokeExhaustInletPipeInput",attrs:{"placeHolder":'请输入备注',"rows":1,"type":'textarea'},on:{"input":function($event){return _vm.inputChange($event, 'RemarkFireSmokeExhaustInletPipe')}}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex",staticStyle:{"height":"22px"}},[_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex5"}),_c('div',{staticClass:"flex5"})]),_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex5"}),_c('div',{staticClass:"flex5"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 flexAcneter borderTop font16"},[_c('span',[_vm._v(" 户内消火栓")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 flexAcneter borderTop font16"},[_c('span',[_vm._v(" 户内消火栓数量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 flexAcneter borderTop font16"},[_c('span',[_vm._v(" 喷淋系统")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 flexAcneter borderTop font16"},[_c('span',[_vm._v(" 烟感报警系统")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 flexAcneter borderTop borderBottom font16"},[_c('span',[_vm._v("消防排烟入户管道")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex5 flexAcneter borderTop borderBottom font16"},[_c('span',[_vm._v("消防排烟总风量")])])
}]

export { render, staticRenderFns }