<template>
  <!-- 编辑页面基础信息 -->
  <div class="shopBaseInfo">
    <!-- <h3 style="padding-left: 10px">{{ title }}</h3> -->
    <div style="
        text-align: right;
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
        padding-top: 10px;
      ">
      <el-button type="primary" plain size="mini" @click="handleSubmitSave">保存</el-button>
    </div>
    <el-form ref="Form" size="mini" :model="Form" :hide-required-asterisk="false" label-position="right"
      label-width="auto" style="flex-wrap: wrap">
      <div style="display: flex; margin-top: 10px">
        <div style="width: 67%; margin-right: 5px">
          <el-row :gutter="8">
            <!-- 商铺编号 -->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple">
                <el-form-item label="商铺编号" prop="ShopCode" :rules="$rules.handle.ShopCode">
                  <!--oninput="value=value.replace(/[\W]/g,'')" 只能输入字母和数字-->
                  <el-input v-model="Form.ShopCode" placeholder="请输入商铺编号" autocomplete="off" @input="ShopCodeInputBlur"
                    oninput="value=value.replace(/[^a-zA-Z0-9_\-]/g, '')" :disabled="ShopCodeDisabled"
                    :rules="$rules.handle.ShopCode"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <!-- 商铺名称 -->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <el-form-item label="商铺名称" prop="ShopName" :rules="$rules.handle.ShopName">
                  <el-input v-model="Form.ShopName" autocomplete="off" placeholder="请输入商铺名称">
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
            <!-- 商铺面积 -->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--             :rules="$rules.handle.ShopArea"-->
                <el-form-item label="商铺面积" prop="ShopArea">
                  <el-input class="area" v-model="Form.ShopArea" placeholder="请输入商铺面积" autocomplete="off"
                    oninput="value=value.replace(/[^\d.]/g,'')">
                    <span slot="suffix">m²</span>
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
            <!--          品牌信息-->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--            :rules="$rules.handle.ShopBrand"-->
                <el-form-item label="品牌信息" prop="ShopBrand">
                  <el-input v-model="Form.ShopBrand" autocomplete="off" placeholder="请输入品牌信息">
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
            <!--        布局站位-->
            <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <el-form-item label="" prop=""> </el-form-item>
              </div>
            </el-col>
            <!-- 业态级别 -->
            <!--              :rules="$rules.handle.ShopFormatClass"-->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <el-form-item label="业态级别" prop="ShopFormatClass">
                  <SelectOptions ref="SelectOptions" :optionsType="'业态级别'" @handleSearchList="handleSearchList"
                    :searchList="ShopFormatClassOptions" />
                </el-form-item>
              </div>
            </el-col>
            <!-- 业态类型 -->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--            :rules="$rules.handle.ShopFormatName"-->
                <el-form-item label="业态类型" prop="ShopFormatName">
                  <SelectOptions :optionsType="'业态类型'" @handleSearchList="handleSearchList"
                    ref="SelectOptionsShopFormatName" :searchList="ShopFormatNameOptions" />
                </el-form-item>
              </div>
            </el-col>
            <!--        布局站位-->
            <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <el-form-item label="" prop=""> </el-form-item>
              </div>
            </el-col>

            <!--        工程编号-->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--            :rules="$rules.handle.ProjectCode"-->
                <el-form-item label="工程编号" prop="ProjectCode">
                  <el-input v-model="Form.ProjectCode" placeholder="请输入工程编号" autocomplete="off"
                    oninput="value=value.replace(/[\W]/g,'')">
                  </el-input>
                </el-form-item>
              </div>
            </el-col>

            <!--        楼栋-->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple">
                <!--             :rules="$rules.handle.BuildingId"-->
                <el-form-item label="楼栋编号" prop="BuildingId" :rules="[
                  {
                    required: true,
                    validator: validEmpty(
                      Form,
                      'BuildingId',
                      '请选择楼栋编号'
                    ),
                    trigger: 'change',
                  },
                ]">
                  <SelectOptions :optionsType="'楼栋'" @handleSearchList="handleSearchList" ref="SelectOptionsBuildingId"
                    :searchList="buildingOptions" />
                </el-form-item>
              </div>
            </el-col>

            <!--        地上地下-->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--             :rules="$rules.handle.IsOnGround"-->
                <el-form-item label="地上/地下/屋面" prop="IsOnGround" :rules="$rules.handle.IsOnGround">
                  <el-select v-model="Form.IsOnGround" placeholder="地上地下" @change="IsOnGroundChange">
                    <el-option v-for="v in IsOnGroundOptions" :key="v.value" :label="v.label" :value="v.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>

            <!--        楼层-->
            <el-col :span="6" style="margin-bottom: 20px">
              <div class="grid-content bg-purple-light">
                <!--            :rules="$rules.handle.Storey"-->
                <el-form-item label="楼层" prop="Storey" style="position: relative">
                  <el-input v-if="this.Form.IsOnGround != 3" class="floorFirst" v-model="Form.Storey" autocomplete="off"
                    placeholder="请输入楼层" type="number" @input="StoreyInputChange"
                    oninput="value=value.replace(/[\W]/g,'')">
                  </el-input>
                  <el-input v-else disabled v-model="Form.Storey"></el-input>
                  <span v-if="Form.IsOnGround == 2" style="position: absolute; left: 10px">-</span>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <!--      备注-->
          <el-row :gutter="5" style="margin-bottom: 20px">
            <el-col :span="24">
              <div class="grid-content bg-purple-light" style="margin-top: 30px">
                <el-form-item label="备注">
                  <el-input type="textarea" v-model="Form.Remark" autocomplete="off" maxlength="200" :rows="8"
                    resize="none" show-word-limit>
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="picUpload" style="
            width: 36%;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
          <Upload ref="Upload" :height="'440px'" :width="'440px'" :url="url" :isUploadType="true"
            :is_disabled="isFileDisabled" @uploadSuccess="uploadSuccessFileChange" @imageDelete="imageDelete"
            :fileData="fileListData" :uoloadTypeImage="uoloadTypeImage"></Upload>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
// 引入工具素具
import { IsOnGroundOptions } from "../mock.js";
// 上传组件
import Upload from "@/components/upload.vue";
// 下拉搜索列表组件
import SelectOptions from "@/components/SelectOptions.vue";
export default {
  name: "WorkspaceJsonShopBaseInfo",
  components: {
    Upload,
    SelectOptions,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      // uploadType 上传图片列表
      fileListData: [
        "http://api.test.zytsaas.com/Shop_ExtensionInfo/202…b74124fe0a84c71b37c3beca5/1729426236368752640.png",
      ],
      // uploadType上传组件的图片
      uoloadTypeImage: "",
      // 是否禁止上传图片
      isFileDisabled: false,
      // 图片上传的地址
      url: "https://api.zytsaas.com/Base_Manage/Upload/ProjectImgOssUploadFileByForm?ProjectId='+this.entity.Id+'&Type=PC",
      // 首页传递过来的项目，任务，应用id对象
      newTaskObj: {},
      // 项目id
      ProjectId: "",
      // 任务id
      TaskId: "",
      // 标题类型
      title: "",
      loading: false,
      show: false,
      // 表单
      Form: {},
      // 校验规则
      Rules: {},
      // 地上地下下拉数据
      IsOnGroundOptions,
      // 业态级别选中好的下拉数据 用于传参
      ShopFormatClassData: [],
      // 业态类型选中好的下拉数据 用于传参
      ShopFormatNameData: [],
      // 楼栋选中好的下拉数据 用于传参
      BuildingIdData: [],
      // 业态级别下拉数据
      // 楼栋信息
      buildingOptions: [],
      // 业态级别
      ShopFormatClassOptions: [],
      // 业态类型下拉选择
      ShopFormatNameOptions: [],
      // 原商铺id下拉列表数据
      ShopLastIdsOptions: [],
      // 默认禁用地上地下输入框  楼层号  只有选择楼栋后才能放开
      IsOnGroundDisabled: true,
      // 默认禁用业态名称输入框
      ShopFormatNameDisabled: true,
      // 默认商铺编号禁止输入
      ShopCodeDisabled: false,
      // 默认禁用楼层输入框
      StoreyDisabled: true,
      //   上传成功后的图片路径
      newFileData: [],
    };
  },

  mounted() { },

  methods: {
    // 业态级别 业态类型  楼栋 下拉函数
    handleSearchList(options, optionsType) {
      // 根据返回的类型 判断属于哪个区域的下拉
      // 判断如果是业态级别条件下，，随时清空业态类型 避免重新选择还是原来的值
      if (optionsType === "业态级别") {
        this.ShopFormatClassData = options;
        this.$refs.SelectOptionsShopFormatName.selectVal = "";
        this.$set(this.Form, "ShopFormatClass", options.label)//业态级别名称赋值
      }
      if (optionsType === "业态类型") {
        this.ShopFormatNameData = options;
        this.$set(this.Form, "ShopFormatName", options.label)//业态级别名称赋值

      }
      // 如果区块属于楼栋下拉  放开地上地下选择框
      if (optionsType === "楼栋") {
        this.BuildingIdData = options;
        this.IsOnGroundDisabled = false;
        this.$set(this.Form, "BuildingId", options.value)//选择好的楼栋id赋值
        this.$set(this.Form, "BuildingCode", options.label)//选择好的楼栋id赋值

        console.log(
          "楼栋编号选择了",
          options,
          optionsType,
          this.Form,
          this.buildingOptions
        );
      }
      if (options) {
        // 如果有选中业态级别  同时获取业态名称
        this.ShopFormatNameDisabled = false;
      }
      let optionsData = this.ShopFormatClassOptions;
      if (this.ShopFormatClassOptions.length !== 0) {
        var newShopFormClassData = optionsData.filter(function (
          item,
          index,
          arr
        ) {
          return item.label === options.label;
        });
      }
      // 赋值业态级别下拉数据参数  以获取对应业态类型下拉数据
      this.parentObj.ShopFormatClassQueryParams.Keyword =
        newShopFormClassData.length == 0 ? "" : newShopFormClassData[0].value;

      this.$emit("ShopFormatClassChange", options.label);
    },
    // 上传图片成功
    uploadSuccessFileChange(fileData, newFileData) {
      this.uoloadTypeImage = newFileData[0];
      this.newFileData = newFileData;
    },
    // 刪除圖片
    imageDelete() {
      this.uoloadTypeImage = "";
    },
    // 上传图片失败
    handleRemoveFileChange(fileList, newFileListData) {
      // console.log("上传图片失败", fileList, newFileListData);
    },
    // form表单初始化
    resetForm() {
      this.Form = {};
      // 重新打开弹窗初始化业态级别，业态类型和楼栋， 地上地下 下拉列表
      this.$refs.SelectOptions.selectVal = "";
      this.$refs.SelectOptionsShopFormatName.selectVal = "";
      this.$refs.SelectOptionsBuildingId.selectVal = "";
      this.uoloadTypeImage = "";
    },
    // 默认先赋值业态级别下拉。避免第一次进去没有下拉数据****************************************
    openSelectOptions(type) {
      this.$nextTick(function () {
        // 默认先赋值业态级别下拉。避免第一次进去没有下拉数据****************************************
        if (type === "业态级别") {
          // 如果过来的是业态级别  赋值业态级别下拉数据
          this.$refs.SelectOptions.chldrenSearchList =
            this.ShopFormatClassOptions;
        } else if (type === "业态类型") {
          // 如果过来的是业态类型 赋值业态类型下拉数据
          this.$refs.SelectOptionsShopFormatName.chldrenSearchList =
            this.ShopFormatNameOptions;
        } else {
          // 如果过来的是楼栋 赋值楼栋下拉数据
          this.$refs.SelectOptionsBuildingId.chldrenSearchList =
            this.buildingOptions;
        }
      });
    },

    // 所在楼层实时输入事件
    StoreyInputChange(e) { },
    // 商铺编号实时输入事件
    ShopCodeInputBlur(e) { },

    // 地上地下选择事件
    IsOnGroundChange(e) {
      if (e == "3") {
        this.$set(this.Form, 'Storey', 'RF')
        // this.Form.Storey = "RF";
      } else {
        this.$set(this.Form, 'Storey', '')
      }
    },
    // 清空表单校验
    clearValidate() {
      var _this = this;
      setTimeout(function () {
        _this.$refs["Form"].clearValidate(); //消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
      }, 30);

      // setTimeout(function () {//消除某个表单的校验,这里的setTimeOut不能去掉,去掉之后会不生效在clearValidate()里以数组形式 填写表单字段的 prop属性值,
      // _this.$refs['Form'].clearValidate(['sn']);
      //     },30);
    },

    // 保存
    handleSubmitSave() {
      // 单独处理楼栋。业态级别下。和业态类型下拉数据   图片上传数据 --》Form中
      this.$set(this.Form, "ProjectId", this.newTaskObj.ProjectId); //项目id
      this.$set(this.Form, "TaskId", this.newTaskObj.TaskId); //任务id
      this.$set(this.Form, "ShopImage", this.newFileData[0]); //上传图片路径
      var that = this;
      console.log("提交新建的form", this.Form);

      this.$refs["Form"].validate((valid) => {
        if (valid) {
          // console.log("this.form", this.Form, this.newTaskObj);
          // console.log("可以执行新建了", this.newFileData);
          const loading = this.$loading({
            lock: true,
            text: "新建中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          //  插入项目id和任务id
          this.Form["ProjectId"] = this.newTaskObj.ProjectId;
          this.Form["TaskId"] = this.newTaskObj.TaskId;

          var that = this;
          // 确认提示框请求
          that.$http
            .post("/Shop_Manage/Shop_BaseInfo/SaveData", { ...this.Form })
            .then((res) => {
              if (res.Success) {
                let storeShopInfo = that.$store.state.shopInfo;
                if (storeShopInfo.Id) {
                  Object.keys(storeShopInfo).map((key1) => {
                    Object.keys(res.Data).map((key2) => {
                      if (key1 === key2) {
                        storeShopInfo[key1] = res.Data[key2];
                      }
                    });
                  });
                  // 存储商铺基础信息到vuex
                  that.$store.commit("getShopInfo", storeShopInfo);
                } else {
                  // 存储商铺基础信息到vuex
                  that.$store.commit("getShopInfo", res.Data);
                }

                // 关闭弹出层
                this.$emit("closeDialog", true);
                setTimeout(() => {
                  loading.close();
                  that.$message.success("新建成功");
                  that.$parent.$parent.$parent.GetShopSummaryInfo();
                }, 500);
              } else {
                that.$message.error(res.Msg);
                // 关闭edit弹出层
                this.parentObj.dialogVisible = false;
                loading.close();
                // that.$parent.$parent.$parent.GetShopSummaryInfo();
                this.ShopFormatClassOptions = [];
              }
            });
        } else {
          return false;
        }
      });
    },

    // 非空校验
    validEmpty(Form, name, tips) {
      return (rule, value, callback) => {
        const Form = this.Form[name];
        if (!Form) {
          // this.$message.warning('列表第' + (index + 1) + '行：' + tips)
          callback(tips || "必填");
        } else {
          callback();
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.shopBaseInfo {
  padding: 0px 0;

  /*覆盖element input输入框的宽高*/
  ::v-deep .el-form-item {
    margin-bottom: 5px !important;

    .el-form-item__content {

      /*覆盖element textarea输入框的行高度*/
      .el-input--mini {
        .el-textarea__inner {
          line-height: 1.5 !important;
        }
      }

      .el-input {

        /* 可以隐藏上下加减号
  input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
 -webkit-appearance: none !important;
   	margin-left: 20px;
   }*/
        .el-input__inner {
          height: 28px !important;
          line-height: 28px !important;
        }

        .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .edit:hover {
    color: #aaa7a7 !important;
    cursor: pointer;
  }

  .save {
    color: red;
  }

  .save:hover {
    color: rgb(253, 127, 127);
    cursor: pointer;
  }

  .el-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 20px;

    ::v-deep .el-button {
      padding: 8px 20px;
    }
  }
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}

.floorFirst {
  ::v-deep .el-input__inner {
    padding: 0 25px !important;
  }
}
</style>
