var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"HVACMajor",staticStyle:{"margin-top":"30px"}},[_c('div',[_c('div',{staticStyle:{"padding-left":"20px","margin-bottom":"10px"}},[_vm._v(" "+_vm._s(_vm.im.DicName)+" ")]),_c('div',{},_vm._l((_vm.im.children),function(v,i){return (_vm.im.children && v.DicKey === 'AirConditioningCondition')?_c('div',{},[_c('div',{staticStyle:{"padding":"15px","width":"97%","text-align":"center","border":"1px solid #ccc","margin":"10px 0"}},[_vm._v(" "+_vm._s(v.DicName)+" ")]),_c('div',{staticClass:"flex"},_vm._l((v.children),function(m,mi){return (
              m.DicKey === 'AirConditioningForm' ||
              m.DicKey === 'VRFConditionReservation'
            )?_c('div',{key:mi,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),(m.DicKey === 'AirConditioningForm')?_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(_vm.entity.AirConditioningCondition ? _vm.entity.AirConditioningCondition.AirConditioningForm : "")+" ")]):_vm._e(),(m.DicKey === 'VRFConditionReservation')?_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(_vm.entity.AirConditioningCondition?(_vm.entity.AirConditioningCondition.VRFConditionReservation ? "已预留" : "未预留"):'')+" ")]):_vm._e()]):_vm._e()}),0),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"flex flex5"},_vm._l((v.children),function(m,mi){return (m.DicKey === 'TotalCoolingCapacity')?_c('div',{key:mi,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(_vm.entity.AirConditioningCondition?_vm.entity.AirConditioningCondition.TotalCoolingCapacity:'')+" ")])]):_vm._e()}),0),_c('div',{staticClass:"flex flex5"},_vm._l((v.children),function(m,mi){return (m.DicKey === 'RefrigerationLoadIndex')?_c('div',{key:mi,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(_vm.entity.VentilationCondition?(_vm.entity.VentilationCondition.RefrigerationLoadIndex ? _vm.$tools.momenyFormat( _vm.entity.VentilationCondition.RefrigerationLoadIndex ) : ""):'')+" ")])]):_vm._e()}),0)]),_c('div',{staticClass:"flex"},_vm._l((_vm.entity.AirConditioningCondition?_vm.entity.AirConditioningCondition
              .AC_ColdWaterPipeDiameter:''),function(item,index){return _c('div',{key:index,staticStyle:{"width":"25%"}},_vm._l((v.children),function(m,mi){return (m.DicKey === 'AC_ColdWaterPipeDiameter')?_c('div',{key:mi,staticClass:"flex"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(_vm._s(item))])]):_vm._e()}),0)}),0),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"flex flex5"},_vm._l((v.children),function(m,mi){return (m.DicKey === 'TotalHeatingCapacity')?_c('div',{key:mi,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(_vm.entity.AirConditioningCondition?_vm.entity.AirConditioningCondition.TotalHeatingCapacity:'')+" ")])]):_vm._e()}),0),_c('div',{staticClass:"flex flex5"},_vm._l((v.children),function(m,mi){return (m.DicKey === 'HeatingLoadIndex')?_c('div',{key:mi,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(_vm.entity.AirConditioningCondition?(_vm.entity.AirConditioningCondition.HeatingLoadIndex ? _vm.$tools.momenyFormat( _vm.entity.AirConditioningCondition.HeatingLoadIndex ) : ""):'')+" ")])]):_vm._e()}),0)]),_c('div',{staticClass:"flex"},_vm._l((_vm.entity.AirConditioningCondition?_vm.entity.AirConditioningCondition
              .AC_HotWaterPipeDiameter:''),function(item,index){return _c('div',{key:index,staticStyle:{"width":"25%"}},_vm._l((v.children),function(m,mi){return (m.DicKey === 'AC_HotWaterPipeDiameter')?_c('div',{key:mi,staticClass:"flex"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(_vm._s(item))])]):_vm._e()}),0)}),0),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"flex flex5"},_vm._l((v.children),function(m,mi){return (m.DicKey === 'AirChangeFlow')?_c('div',{key:mi,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(_vm.entity.AirConditioningCondition?_vm.entity.AirConditioningCondition.AirChangeFlow:'')+" ")])]):_vm._e()}),0),_vm._m(0,true)]),_c('div',{staticClass:"flex"},_vm._l((_vm.entity.AirConditioningCondition?_vm.entity.AirConditioningCondition
              .FreshAirPipelineSize:''),function(item,index){return _c('div',{key:index,staticStyle:{"width":"25%"}},_vm._l((v.children),function(m,mi){return (m.DicKey === 'FreshAirPipelineSize')?_c('div',{key:mi,staticClass:"flex"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(item.FAPS_A?item.FAPS_A:'' + " X " + item.FAPS_B?item.FAPS_B:'')+" ")])]):_vm._e()}),0)}),0)]):_vm._e()}),0)]),_c('div',[_c('div',{},_vm._l((_vm.im.children),function(v,i){return (_vm.im.children && v.DicKey === 'VentilationCondition')?_c('div',{},[_c('div',{staticStyle:{"padding":"15px","width":"100%","text-align":"center","border":"1px solid #ccc","margin":"10px 0"}},[_vm._v(" "+_vm._s("厨房" + v.DicName)+" ")]),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"flex flex5"},_vm._l((v.children),function(m,mi){return (m.DicKey === 'KitchenOilFumeDischarge')?_c('div',{key:mi,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(_vm.entity.VentilationCondition?_vm.entity.VentilationCondition.KitchenOilFumeDischarge:"")+" ")])]):_vm._e()}),0),_vm._m(1,true)]),_c('div',{staticClass:"flex"},_vm._l((_vm.entity.VentilationCondition?_vm.entity.VentilationCondition
              .KitchenExhaustPipeSize:''),function(item,index){return _c('div',{key:index,staticStyle:{"width":"25%"}},_vm._l((v.children),function(m,mi){return (m.DicKey === 'KitchenExhaustPipeSize')?_c('div',{key:mi,staticClass:"flex"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(item.KEPS_A?item.KEPS_A:'' + " X " + item.KEPS_B?item.KEPS_B:'')+" ")])]):_vm._e()}),0)}),0),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"flex flex5"},_vm._l((v.children),function(m,mi){return (m.DicKey === 'KitchenNormalAccidentExhaustVolume')?_c('div',{key:mi,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(_vm.entity.VentilationCondition?_vm.entity.VentilationCondition .KitchenNormalAccidentExhaustVolume:'')+" ")])]):_vm._e()}),0),_vm._m(2,true)]),_c('div',{staticClass:"flex"},_vm._l((_vm.entity.VentilationCondition?_vm.entity.VentilationCondition
              .KitchenNormalAccidentExhaustPipeSize:''),function(item,index){return _c('div',{key:index,staticStyle:{"width":"25%"}},_vm._l((v.children),function(m,mi){return (m.DicKey === 'KitchenNormalAccidentExhaustPipeSize')?_c('div',{key:mi,staticClass:"flex"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(item.KNAEPS_A?item.KNAEPS_A:'' + " X " + item.KNAEPS_B?item.KNAEPS_B:'')+" ")])]):_vm._e()}),0)}),0),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"flex flex5"},_vm._l((v.children),function(m,mi){return (m.DicKey === 'KitchenAirReplenishmentVolume')?_c('div',{key:mi,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(_vm.entity.VentilationCondition?_vm.entity.VentilationCondition.KitchenAirReplenishmentVolume:'')+" ")])]):_vm._e()}),0),_vm._m(3,true)]),_c('div',{staticClass:"flex"},_vm._l((_vm.entity.VentilationCondition?_vm.entity.VentilationCondition
              .KitchenAirReplenishmentPipeSize:''),function(item,index){return _c('div',{key:index,staticStyle:{"width":"25%"}},_vm._l((v.children),function(m,mi){return (m.DicKey === 'KitchenAirReplenishmentPipeSize')?_c('div',{key:mi,staticClass:"flex"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(item.KARPS_A?item.KARPS_A:'' + " X " + item.KARPS_B?item.KARPS_B:'')+" ")])]):_vm._e()}),0)}),0)]):_vm._e()}),0)]),_c('div',[_c('div',{},_vm._l((_vm.im.children),function(v,i){return (_vm.im.children && v.DicKey === 'VentilationCondition')?_c('div',{},[_c('div',{staticStyle:{"padding":"15px","width":"100%","text-align":"center","border":"1px solid #ccc","margin":"10px 0"}},[_vm._v(" 个护通风条件 ")]),_c('div',{staticClass:"flex",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"flex flex5"},_vm._l((v.children),function(m,mi){return (m.DicKey === 'IndividualProtectiveExhaustAirVolume')?_c('div',{key:mi,staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(_vm.entity.VentilationCondition?_vm.entity.VentilationCondition .IndividualProtectiveExhaustAirVolume:'')+" ")])]):_vm._e()}),0),_vm._m(4,true)]),_c('div',{staticClass:"flex"},_vm._l((_vm.entity.VentilationCondition?_vm.entity.VentilationCondition
              .IndividualProtectiveExhaustAirPipeSize:''),function(item,index){return _c('div',{key:index,staticStyle:{"width":"25%"}},_vm._l((v.children),function(m,mi){return (m.DicKey === 'IndividualProtectiveExhaustAirPipeSize')?_c('div',{key:mi,staticClass:"flex"},[_c('div',{staticClass:"flex5 design-left background"},[_vm._v(" "+_vm._s(m.DicName)+" ")]),_c('div',{staticClass:"flex5 design-right background"},[_vm._v(" "+_vm._s(item.IPEAPS_A?item.IPEAPS_A:'' + " X " + item.IPEAPS_B?item.IPEAPS_B:'')+" ")])]):_vm._e()}),0)}),0)]):_vm._e()}),0)]),_vm._l((_vm.im.children),function(v,vi){return (_vm.im.children && v.DicKey === 'HVACRemark')?_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex design-left background",staticStyle:{"flex":"1.18","justify-content":"flex-end"}},[_vm._v(" 备注 ")]),_c('div',{staticClass:"design-right background",staticStyle:{"flex":"8.82"}},[_vm._v(" "+_vm._s(_vm.entity.HVACRemark)+" ")])]):_vm._e()})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"}),_c('div',{staticClass:"flex5 design-right background"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"}),_c('div',{staticClass:"flex5 design-right background"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"}),_c('div',{staticClass:"flex5 design-right background"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"}),_c('div',{staticClass:"flex5 design-right background"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex flex5"},[_c('div',{staticClass:"flex5 design-left background"}),_c('div',{staticClass:"flex5 design-right background"})])])
}]

export { render, staticRenderFns }