<template>
  <!--  这里是弱电模块区域组件-->
  <div class="IntelligentMajor" style="margin-top: 30px">
    <span style="padding-left: 20px">{{ im.DicName }}</span>

    <!--      给水条件-->
    <div class="">
      <div class="" style="margin-top: 10px">
        <div v-for="(m, l) in im.children" :key="l">
          <!--          弱电箱信息-->
          <div
            style="
              padding: 15px;
              width: 97%;
              text-align: center;
              border: 1px solid #ccc;
              margin: 20px 0 20px 0;
            "
            v-if="m.DicKey === 'WeakCurrentBoxInfo'"
          >
            {{ m.DicName }}
          </div>

          <!--                给水管径-->
          <div>
            <div
              style="display: flex"
              v-for="(item, index) in entity.WeakCurrentBoxInfo"
              :key="index"
            >
              <div
                class="flex"
                v-for="(v, i) in m.children"
                :key="i"
                style="width: 25%"
              >
                <div class="flex5 IntelligentMajor-left background">
                  {{ v.DicName }}
                </div>
                <div class="flex5 IntelligentMajor-right background">
                  {{ item[v.DicKey] }}
                </div>
              </div>
            </div>
            <!--    备注-->
            <div class="flex" v-if="m.DicKey === 'RemarkIntelligent'">
              <div style="flex: 0.9" class="IntelligentMajor-left background">
                {{ m.DicName }}
              </div>
              <div style="flex: 9.1" class="IntelligentMajor-right background">
                {{ entity.RemarkIntelligent }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
  name: "HVACMajor.vue",
  data() {
    // 这里存放数据
    return {};
  },
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    updateRecordType: {
      type: String,
    },
    parentObj: {
      type: Object,
      default: () => {},
    },
    im: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  // 方法集合
  methods: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  //过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {},
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  //进入的时候触发
  activated() {},
  //离开的时候触发
  deactivated() {},
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
.border {
  border: 1px solid #ccc;
}
.border-top {
  border-top: 1px solid #ccc;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}
.border-left {
  border-left: 1px solid #ccc;
}
.border-right {
  border-right: 1px solid #ccc;
}
.IntelligentMajor {
  .IntelligentMajor-left.background {
    background-color: #f7eded;
    border: none !important;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .IntelligentMajor-right.background {
    background-color: #ffe3e3;
    border: none !important;
    padding-left: 10px;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
