<!-- 更新记录组件 -->
<template>
  <el-dialog
    title="打印预览"
    :visible.sync="visible"
    center
    width="80%"
    class="record"
    append-to-body
    modal-append-to-body
  >
    <!-- 具体区域内容 -->
    <div class="recordContent">
      <el-card :body-style="bodyStyle">
        <!--      打印区域-->
        <div style="text-align: right">
          <!--          -->
          <!--          <el-button-->
          <!--            size="mini"-->
          <!--            :loading="buttonLoadding"-->
          <!--            v-print="'#printContent'"-->
          <!--            >开始打印</el-button-->
          <!--          >-->
          <el-button @click="onPrint" size="mini" :loading="buttonLoadding"
            >开始打印</el-button
          >
          <el-button
            @click="onPrintSave"
            size="mini"
            type="primary"
            :loading="buttonLoadding"
            >保存</el-button
          >
        </div>
        <!--         ref="printContent"-->
        <div
          ref="printContent"
          style="border: 2px solid #000; padding: 0 20px; margin-top: 30px"
        >
          <div class="print-input" style="margin: 50px; text-align: center">
            <span class="font20" style="font-weight: bold; color: #303133"
              >商铺技术交付标准条件</span
            >
          </div>
          <div
            class="print-input"
            style="
              margin: 50px 50px 50px 0;
              display: flex;
              font-weight: bold;
              color: #303133;
              font-size: 18px;
              align-items: center;
            "
          >
            商铺名称:
            <div class="title-input" style="margin-left: 100px">
              <el-input
                v-model="entity.ShopName"
                placeholder="请输入商铺名称"
              ></el-input>
            </div>
          </div>
          <div style="">
            <!--         基本信息区域-->
            <div>
              <div
                class="bold font16"
                style="padding: 20px 0; text-align: left"
              >
                1. 基本信息
              </div>
              <ShopBasePrint
                :entity="entity"
                ref="ShopBasePrint"
                :parentObj="{ ...this }"
              ></ShopBasePrint>
            </div>

            <!--电气部分-->
            <div>
              <div
                style="padding: 30px 0; text-align: left"
                class="bold font16"
              >
                2. 交付技术条件——机电部分
              </div>
              <div
                style="padding: 20px 0; text-align: left"
                class="bold font16"
              >
                a.电气部分
              </div>
              <ElctricalMajor
                :entity="entity"
                ref="ElctricalMajor"
                :parentObj="{ ...this }"
              ></ElctricalMajor>
            </div>

            <!--给排水部分-->
            <div>
              <div
                style="padding: 20px 0; text-align: left"
                class="bold font16"
              >
                b.给水排水部分
              </div>
              <WSADMajor
                :entity="entity"
                ref="WSADMajor"
                :parentObj="{ ...this }"
              ></WSADMajor>
            </div>

            <!--        供热通风及空调部分-->
            <div>
              <div
                style="padding: 20px 0; text-align: left"
                class="bold font16"
              >
                c.供热通风及空调部分
              </div>
              <HVACMajor ref="HVACMajor" :parentObj="{ ...this }"></HVACMajor>
            </div>

            <!--     消防部分-->
            <div>
              <div
                style="padding: 20px 0; text-align: left"
                class="bold font16"
              >
                d.消防部分
              </div>
              <FireInfo ref="FireInfo" :parentObj="{ ...this }"></FireInfo>
            </div>
            <!--          弱电部分-->
            <div>
              <div
                style="padding: 20px 0; text-align: left"
                class="bold font16"
              >
                e.弱电部分
              </div>
              <IntelligentMajor
                ref="IntelligentMajor"
                :parentObj="{ ...this }"
              ></IntelligentMajor>
            </div>

            <!--     燃气部分-->
            <div>
              <div
                style="padding: 20px 0; text-align: left"
                class="bold font16"
              >
                f.燃气部分
              </div>
              <NaturalGas
                ref="NaturalGas"
                :parentObj="{ ...this }"
              ></NaturalGas>
            </div>

            <!--        结构部分-->
            <div>
              <div
                style="padding: 20px 0; text-align: left"
                class="bold font16"
              >
                g.结构条件
              </div>
              <StructureMajor
                ref="StructureMajor"
                :parentObj="{ ...this }"
              ></StructureMajor>
            </div>

            <!--        尾部部门会签-->
            <div class="flex" style="align-items: center">
              <div style="font-weight: bold; margin: 30px" class="font16">
                部门会签 :
              </div>
            </div>

            <div class="flex" style="align-items: center">
              <div style="font-weight: bold; margin: 30px" class="font16">
                日期 :
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import InputComponent from "@/views/Project/view/components/inputComponents/inputComponent.vue";
//引入基本信息区域组件
import ShopBasePrint from "./components/print/ShopBasePrint.vue";
//引入电气模块区域组件
import ElctricalMajor from "./components/print/ElectricalMajor.vue";
// 引入给排水区域组件
import WSADMajor from "./components/print/WSADMajor.vue";
// 供热通风空调部分
import HVACMajor from "./components/print/HVACMajor.vue";
// 消防部分
import FireInfo from "./components/print/FireInfo.vue";
// 弱电部分
import IntelligentMajor from "./components/print/IntelligentMajor.vue";
// 燃气部分
import NaturalGas from "./components/print/NaturalGas.vue";
// 结构部分
import StructureMajor from "./components/print/StructureMajor.vue";
// 模拟数据
import {} from "./mock.js";
export default {
  //父组件通过 props 向下传递数据给子组件
  props: {
    //attractInvestment父组件传递过来的树型结构表头数据  有多少个表头就有多少个展开关闭模板
    // col: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  //import引入的组件需要注入此处能使用
  components: {
    InputComponent,
    ShopBasePrint,
    ElctricalMajor,
    WSADMajor,
    HVACMajor,
    FireInfo,
    IntelligentMajor,
    NaturalGas,
    StructureMajor,
  },
  //此处存放数据
  data() {
    return {
      isPrint: false, //是否打印
      buttonLoadding: false, //打印按钮加载
      // attractInvertment传递过来的col数据
      col: [],
      bodyStyle: {
        // padding: "20px",
      },
      size: "",
      // 浏览器当前可视窗口高度
      windowHeight: 0,
      // 浏览器当前可视窗口宽度
      windowWidth: 0,
      visible: false,
      activeName: [0, 1, 2, 3, 4],
      entity: {},
      entityData: {},
      newEntityDataObj: {},

      // 下载的附件
      downLoadFileListData: [],
      //   部门会签
      signValue: "",
    };
  },
  //计算属性
  computed: {},
  //监听data中数据变化
  watch: {},
  created() {},
  methods: {
    // 子组件传递过来的插入相关下拉的参数
    openSetPrintSelectValue(event, type) {
      console.log("子组件回传下拉", event, type);
      console.log("有吗", this.entityData);
      this.entity[type] = event;
    },
    // 子组件过来的插入相关备注参数
    openSetPrintInputValue(event, type) {
      console.log("父组件配电箱输入获取--", event, type);
      this.entity[type] = event;
    },
    // 打印保存
    onPrintSave() {
      var that = this;
      var newEntityDataObj;
      if (this.entityData.Id) {
        //更新json数据
        newEntityDataObj = {
          Code: this.entityData.Code,
          CreateTime: this.entityData.CreateTime,
          CreatorId: this.entityData.CreatorId,
          Deleted: this.entityData.Deleted,
          Id: this.entityData.Id,
          Remark: this.entityData.Remark,
          ShopId: this.entityData.ShopId,
          Sort: this.entityData.Sort,
          Status: this.entityData.Status,
          UpdateTime: this.entityData.UpdateTime,
          PrintDataJson: JSON.stringify(this.entity),
        };
        console.log("走有值的", this.entityData, this.entity, newEntityDataObj);
      } else {
        newEntityDataObj = {
          ShopId: this.entity.Id,
          PrintDataJson: JSON.stringify(this.entity),
        };
      }
      console.log("传参--------", this.entity, newEntityDataObj);

      const loading = this.$loading({
        lock: true,
        text: "保存中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$http
        .post("/Shop_Manage/Shop_PrintData/SaveData", newEntityDataObj)
        .then((res) => {
          if (res.Success) {
            setTimeout(() => {
              loading.close();
              this.$message.success("已保存");
            }, 1000);
          } else {
            this.$message.error(res.Msg);
            setTimeout(() => {
              loading.close();
            }, 1000);
          }
        });
    },
    // 打印按钮
    onPrint() {
      console.log("开始打印");
      var that = this;
      this.buttonLoadding = true;
      const loading = this.$loading({
        lock: true,
        text: "正在打开预览...请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$nextTick(() => {
        //   关闭打印按钮加载
        setTimeout(function () {
          that.buttonLoadding = false;
          loading.close();
        }, 5000);
        this.$print(this.$refs.printContent);
      });
    },

    // 折叠展开
    handleChange(e) {
      console.log("折叠展开", e);
    },
    open(row, colData) {
      this.visible = true;
      this.col = colData;
      this.GetDataListBySingleCondition(row);
    },
    setEntityObj(type, data) {
      this.$set(this.entity, type, data);
    },
    //获取打印模版整体内容详情
    GetDataListBySingleCondition(row) {
      this.entity = {};
      this.$http
        .post("/Shop_Manage/Shop_PrintData/GetTheDataByShopId?shopId=" + row.Id)
        .then((res) => {
          if (res.Success) {
            // 如果没有返回 说明第一次添加
            if (res.Data == null) {
              this.entityData = {}; //初始化
              this.entity = row;
              this.$nextTick(function () {
                // 判断提供非提供逻辑
                this.setEntityObj(
                  "GasConnectingPipeSizeSelectValue",
                  this.entity.GasConnectingPipeSize ? "提供" : "不提供"
                );
                // 判断结构条件厨房区域降板（有/无）
                this.setEntityObj(
                  "LoweringPlateHeightSelectValue",
                  this.entity.LoweringPlateHeight ? "有" : "无"
                );
                // 判断弱电里的 电话网络  pos系统  有线电视提供非提供
                this.setEntityObj(
                  "NetworkPointsAndCablesSelectValue",
                  this.entity.NetworkPointsAndCablesSelectValue
                    ? this.entity.NetworkPointsAndCablesSelectValue
                    : ""
                );
                this.setEntityObj(
                  "CableTVPointsAndCablesSelectValue",
                  this.entity.CableTVPointsAndCablesSelectValue
                    ? this.entity.CableTVPointsAndCablesSelectValue
                    : ""
                );
                this.setEntityObj(
                  "POSPointsAndCablesSelectValue",
                  this.entity.POSPointsAndCablesSelectValue
                    ? this.entity.POSPointsAndCablesSelectValue
                    : ""
                );
                // 判断消防   户内消火栓 喷淋系统 烟感报警系统（提供/不提供） 消防排烟入户管道（提供/不提供）
                this.setEntityObj(
                  "IndoorFireSelectValue",
                  this.entity.IndoorFireSelectValue
                    ? this.entity.IndoorFireSelectValue
                    : ""
                );
                this.setEntityObj(
                  "SpraySystemSelectValue",
                  this.entity.SpraySystemSelectValue
                    ? this.entity.SpraySystemSelectValue
                    : ""
                );
                this.setEntityObj(
                  "SmokeDetectionAlarmSystemSelectValue",
                  this.entity.SmokeDetectionAlarmSystemSelectValue
                    ? this.entity.SmokeDetectionAlarmSystemSelectValue
                    : ""
                );
                this.setEntityObj(
                  "FireSmokeExhaustInletPipeSelectValue",
                  this.entity.FireSmokeExhaustInletPipeSelectValue
                    ? this.entity.FireSmokeExhaustInletPipeSelectValue
                    : ""
                );
                // 电气---- 电表提供不提供 电表安装位置提供不提供  是否应急电源提供不提供
                this.setEntityObj(
                  "ElectricityMeterSelectValue",
                  this.entity.ElectricalBox ? "提供" : "不提供"
                );
                this.setEntityObj(
                  "ElectricityMeterPositionSelectValue",
                  this.entity.ElectricityMeterPositionSelectValue
                    ? this.entity.ElectricityMeterPositionSelectValue
                    : ""
                );
                this.setEntityObj(
                  "EmergencySupplySelectValue",
                  this.entity.EmergencySupplySelectValue
                    ? this.entity.EmergencySupplySelectValue
                    : ""
                );

                // 给排水   给水管道提供不提供  餐饮排水提供不提供 非餐饮排水提供不提供
                this.setEntityObj(
                  "WaterSupplyPipeSizeSelectValue",
                  this.entity.WaterSupplyPipeSize ? "提供" : "不提供"
                );
                this.setEntityObj(
                  "CateringDrainagePipeSizeSelectValue",
                  this.entity.CateringDrainagePipeSize ? "提供" : "不提供"
                );
                this.setEntityObj(
                  "NonCateringDrainagePipeSizeSelectValue",
                  this.entity.NonCateringDrainagePipeSize ? "提供" : "不提供"
                );
                this.setEntityObj(
                  "WaterOilSeparatorSelectValue",
                  this.entity.WaterOilSeparatorSelectValue
                    ? this.entity.WaterOilSeparatorSelectValue
                    : ""
                ); //专用隔油池提供非提供
                this.setEntityObj(
                  "WaterMeterPositionSelectValue",
                  this.entity.WaterMeterPositionSelectValue
                    ? this.entity.WaterMeterPositionSelectValue
                    : ""
                ); //水表安装位置提供不提供

                // 暖通
                //空调（提供/不提供）
                if (this.entity.AirConditioningCondition) {
                  this.setEntityObj(
                    "AirConditioningConditionSelectValue",
                    this.entity.AirConditioningConditionSelectValue
                      ? this.entity.AirConditioningConditionSelectValue
                      : ""
                  );
                  //采暖供热（提供/不提供）
                  this.setEntityObj(
                    "AC_ColdWaterPipeDiameterSelectValue",
                    this.entity.AC_ColdWaterPipeDiameterSelectValue
                      ? this.entity.AC_ColdWaterPipeDiameterSelectValue
                      : ""
                  );
                  //空调及供热管制（两管/四管））
                  this.setEntityObj(
                    "AirConditioningHeatingControlSelectValue",
                    this.entity.AirConditioningHeatingControlSelectValue
                      ? this.entity.AirConditioningHeatingControlSelectValue
                      : ""
                  );
                  ////独立VRV安装条件（提供/不提供）
                  this.setEntityObj(
                    "VRFConditionReservationSelectValue",
                    this.entity.AirConditioningCondition
                      .VRFConditionReservation === true
                      ? "提供"
                      : "不提供"
                  );
                  // //新风管道（提供/不提供）
                  this.setEntityObj(
                    "FreshAirPipelineSizeSelectValue",
                    this.entity.AirConditioningCondition
                      ? this.entity.AirConditioningCondition
                          .FreshAirPipelineSize.length > 0
                        ? "提供"
                        : "不提供"
                      : "不提供"
                  );
                } else {
                  this.setEntityObj(
                    "AirConditioningConditionSelectValue",
                    this.entity.AirConditioningConditionSelectValue
                      ? this.entity.AirConditioningConditionSelectValue
                      : ""
                  );
                  //采暖供热（提供/不提供）
                  this.setEntityObj(
                    "AC_ColdWaterPipeDiameterSelectValue",
                    this.entity.AC_ColdWaterPipeDiameterSelectValue
                      ? this.entity.AC_ColdWaterPipeDiameterSelectValue
                      : ""
                  );
                  //空调及供热管制（两管/四管 ））
                  this.setEntityObj(
                    "AirConditioningHeatingControlSelectValue",
                    this.entity.AirConditioningHeatingControlSelectValue
                      ? this.entity.AirConditioningHeatingControlSelectValue
                      : ""
                  );
                  ////独立VRV安装条件（提供/不提供）
                  this.setEntityObj(
                    "VRFConditionReservationSelectValue",
                    "不提供"
                  );
                  // //新风管道（提供/不提供）
                  this.setEntityObj(
                    "FreshAirPipelineSizeSelectValue",
                    this.entity.AirConditioningCondition
                      ? this.entity.AirConditioningCondition
                          .FreshAirPipelineSize.length > 0
                        ? "提供"
                        : "不提供"
                      : "不提供"
                  );
                }

                // //排油烟管道（提供/不提供）
                this.setEntityObj(
                  "KitchenExhaustPipeSizeSelectValue",
                  this.entity.VentilationCondition
                    ? this.entity.VentilationCondition.KitchenExhaustPipeSize
                        .length > 0
                      ? "提供"
                      : "不提供"
                    : "不提供"
                );
                //排油烟补风管道（提供/不提供）
                this.setEntityObj(
                  "KitchenAirReplenishmentPipeSizeSelectValue",
                  this.entity.VentilationCondition
                    ? this.entity.VentilationCondition
                        .KitchenAirReplenishmentPipeSize.length > 0
                      ? "提供"
                      : "不提供"
                    : "不提供"
                );
                //厨房排风兼事故排风管道（提供/不提供）
                this.setEntityObj(
                  "KitchenNormalAccidentExhaustPipeSizeSelectValue",
                  this.entity.VentilationCondition
                    ? this.entity.VentilationCondition
                        .KitchenNormalAccidentExhaustPipeSize.length > 0
                      ? "提供"
                      : "不提供"
                    : "不提供"
                );
                ////个护排风管道（提供/不提供）
                this.setEntityObj(
                  "IndividualProtectiveExhaustAirPipeSizeSelectValue",
                  this.entity.VentilationCondition
                    ? this.entity.VentilationCondition
                        .IndividualProtectiveExhaustAirPipeSize.length > 0
                      ? "提供"
                      : "不提供"
                    : "不提供"
                );

                this.$nextTick(function () {
                  this.$refs.ShopBasePrint.input(this.entity); //基础信息备注回显
                  this.$refs.ElctricalMajor.input(this.entity); //电气备注回显
                  this.$refs.WSADMajor.input(this.entity); //给排水备注回显
                  this.$refs.HVACMajor.input(this.entity); //暖通备注回显
                  this.$refs.FireInfo.input(this.entity); //消防户内消火栓 喷淋系统 烟感报警系统 备注回显
                  this.$refs.IntelligentMajor.input(this.entity); //弱电备注回显
                  this.$refs.NaturalGas.input(this.entity); //燃氣備注會先
                  this.$refs.StructureMajor.input(this.entity); //结构备注回显
                });

                console.log(
                  "走的是没有--------------------",
                  row,
                  this.$refs.NaturalGas
                );
              });

              return;
            }
            // 否则走修改
            this.entityData = res.Data;
            this.newEntityDataObj = Object.assign(
              this.newEntityDataObj,
              this.entityData
            );
            this.entity = JSON.parse(res.Data.PrintDataJson);
            // 判断提供非提供逻辑
            this.setEntityObj(
              "GasConnectingPipeSizeSelectValue",
              this.entity.GasConnectingPipeSize ? "提供" : "不提供"
            );
            // 判断结构条件厨房区域降板（有/无）
            this.setEntityObj(
              "LoweringPlateHeightSelectValue",
              this.entity.LoweringPlateHeight ? "有" : "无"
            );
            // 判断弱电里的 电话网络  pos系统  有线电视提供非提供
            this.setEntityObj(
              "NetworkPointsAndCablesSelectValue",
              this.entity.NetworkPointsAndCablesSelectValue
                ? this.entity.NetworkPointsAndCablesSelectValue
                : ""
            );
            this.setEntityObj(
              "CableTVPointsAndCablesSelectValue",
              this.entity.CableTVPointsAndCablesSelectValue
                ? this.entity.CableTVPointsAndCablesSelectValue
                : ""
            );
            this.setEntityObj(
              "POSPointsAndCablesSelectValue",
              this.entity.POSPointsAndCablesSelectValue
                ? this.entity.POSPointsAndCablesSelectValue
                : ""
            );
            // 判断消防   户内消火栓 喷淋系统 烟感报警系统（提供/不提供） 消防排烟入户管道（提供/不提供）
            this.setEntityObj(
              "IndoorFireSelectValue",
              this.entity.IndoorFireSelectValue
                ? this.entity.IndoorFireSelectValue
                : ""
            );
            this.setEntityObj(
              "SpraySystemSelectValue",
              this.entity.SpraySystemSelectValue
                ? this.entity.SpraySystemSelectValue
                : ""
            );
            this.setEntityObj(
              "SmokeDetectionAlarmSystemSelectValue",
              this.entity.SmokeDetectionAlarmSystemSelectValue
                ? this.entity.SmokeDetectionAlarmSystemSelectValue
                : ""
            );
            this.setEntityObj(
              "FireSmokeExhaustInletPipeSelectValue",
              this.entity.FireSmokeExhaustInletPipeSelectValue
                ? this.entity.FireSmokeExhaustInletPipeSelectValue
                : ""
            );

            // 电气---- 电表提供不提供 电表安装位置提供不提供  是否应急电源提供不提供
            this.setEntityObj(
              "ElectricityMeterSelectValue",
              this.entity.ElectricalBox ? "提供" : "不提供"
            );
            this.setEntityObj(
              "ElectricityMeterPositionSelectValue",
              this.entity.ElectricityMeterPositionSelectValue
                ? this.entity.ElectricityMeterPositionSelectValue
                : ""
            );
            this.setEntityObj(
              "EmergencySupplySelectValue",
              this.entity.EmergencySupplySelectValue
                ? this.entity.EmergencySupplySelectValue
                : ""
            );

            // 给排水   给水管道提供不提供  餐饮排水提供不提供 非餐饮排水提供不提供
            this.setEntityObj(
              "WaterSupplyPipeSizeSelectValue",
              this.entity.WaterSupplyPipeSize ? "提供" : "不提供"
            );
            this.setEntityObj(
              "CateringDrainagePipeSizeSelectValue",
              this.entity.CateringDrainagePipeSize ? "提供" : "不提供"
            );
            this.setEntityObj(
              "NonCateringDrainagePipeSizeSelectValue",
              this.entity.NonCateringDrainagePipeSize ? "提供" : "不提供"
            );
            this.setEntityObj(
              "WaterOilSeparatorSelectValue",
              this.entity.WaterOilSeparatorSelectValue
                ? this.entity.WaterOilSeparatorSelectValue
                : ""
            ); //专用隔油池提供非提供
            this.setEntityObj(
              "WaterMeterPositionSelectValue",
              this.entity.WaterMeterPositionSelectValue
                ? this.entity.WaterMeterPositionSelectValue
                : ""
            ); //水表安装位置提供不提供
            // 暖通
            //空调（提供/不提供）
            if (this.entity.AirConditioningCondition) {
              this.setEntityObj(
                "AirConditioningConditionSelectValue",
                this.entity.AirConditioningConditionSelectValue
                  ? this.entity.AirConditioningConditionSelectValue
                  : ""
              );
              //采暖供热（提供/不提供）
              this.setEntityObj(
                "AC_ColdWaterPipeDiameterSelectValue",
                this.entity.AirConditioningCondition
                  ? this.entity.AirConditioningCondition.AC_HotWaterPipeDiameter
                    ? "提供"
                    : "不提供"
                  : "不提供"
              );
              //空调及供热管制（两管/四管））
              this.setEntityObj(
                "AirConditioningHeatingControlSelectValue",
                this.entity.AirConditioningHeatingControlSelectValue
                  ? this.entity.AirConditioningHeatingControlSelectValue
                  : ""
              );
              ////独立VRV安装条件（提供/不提供）
              this.setEntityObj(
                "VRFConditionReservationSelectValue",
                this.entity.AirConditioningCondition.VRFConditionReservation ===
                  true
                  ? "提供"
                  : "不提供"
              );
              // //新风管道（提供/不提供）
              this.setEntityObj(
                "FreshAirPipelineSizeSelectValue",
                this.entity.AirConditioningCondition
                  ? this.entity.AirConditioningCondition.FreshAirPipelineSize
                      .length > 0
                    ? "提供"
                    : "不提供"
                  : "不提供"
              );
            } else {
              this.setEntityObj(
                "AirConditioningConditionSelectValue",
                this.entity.AirConditioningConditionSelectValue
                  ? this.entity.AirConditioningConditionSelectValue
                  : ""
              );
              //采暖供热（提供/不提供）
              this.setEntityObj(
                "AC_ColdWaterPipeDiameterSelectValue",
                "不提供"
              );
              //空调及供热管制（两管/四管））
              this.setEntityObj(
                "AirConditioningHeatingControlSelectValue",
                this.entity.AirConditioningHeatingControlSelectValue
                  ? this.entity.AirConditioningHeatingControlSelectValue
                  : ""
              );
              ////独立VRV安装条件（提供/不提供）
              this.setEntityObj("VRFConditionReservationSelectValue", "不提供");
              // //新风管道（提供/不提供）
              this.setEntityObj(
                "FreshAirPipelineSizeSelectValue",
                this.entity.AirConditioningCondition
                  ? this.entity.AirConditioningCondition.FreshAirPipelineSize
                      .length > 0
                    ? "提供"
                    : "不提供"
                  : "不提供"
              );
            }

            // //排油烟管道（提供/不提供）
            this.setEntityObj(
              "KitchenExhaustPipeSizeSelectValue",
              this.entity.VentilationCondition
                ? this.entity.VentilationCondition.KitchenExhaustPipeSize
                    .length > 0
                  ? "提供"
                  : "不提供"
                : "不提供"
            );
            //排油烟补风管道（提供/不提供）
            this.setEntityObj(
              "KitchenAirReplenishmentPipeSizeSelectValue",
              this.entity.VentilationCondition
                ? this.entity.VentilationCondition
                    .KitchenAirReplenishmentPipeSize.length > 0
                  ? "提供"
                  : "不提供"
                : "不提供"
            );
            //厨房排风兼事故排风管道（提供/不提供）
            this.setEntityObj(
              "KitchenNormalAccidentExhaustPipeSizeSelectValue",
              this.entity.VentilationCondition
                ? this.entity.VentilationCondition
                    .KitchenNormalAccidentExhaustPipeSize.length > 0
                  ? "提供"
                  : "不提供"
                : "不提供"
            );
            ////个护排风管道（提供/不提供）
            this.setEntityObj(
              "IndividualProtectiveExhaustAirPipeSizeSelectValue",
              this.entity.VentilationCondition
                ? this.entity.VentilationCondition
                    .IndividualProtectiveExhaustAirPipeSize.length > 0
                  ? "提供"
                  : "不提供"
                : "不提供"
            );

            this.$nextTick(function () {
              console.log(
                "走的是有--------------",
                this.$refs.ShopBasePrint,
                this.entity
              );

              this.$refs.ShopBasePrint.input(this.entity); //基础信息备注回显
              this.$refs.ElctricalMajor.input(this.entity); //电气备注回显
              this.$refs.WSADMajor.input(this.entity); //给排水备注回显
              this.$refs.HVACMajor.input(this.entity); //暖通备注回显
              this.$refs.FireInfo.input(this.entity); //消防户内消火栓 喷淋系统 烟感报警系统 备注回显
              this.$refs.IntelligentMajor.input(this.entity); //弱电备注回显
              this.$refs.NaturalGas.input(this.entity); //燃氣備注會先
              this.$refs.StructureMajor.input(this.entity); //结构备注回显
            });
          } else this.$message.error(res.Msg);
        });
    },
    // 返回
    recordBack() {
      this.$emit("recordBack");
    },

    // 下载附件处理函数
    getDownLoadFile(file) {
      if (file) {
        let arr = file.split(",");
        this.downLoadFileListData = arr;
        console.log("要处理", this.downLoadFileListData);
      }

      return this.downLoadFileListData;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep.title-input {
  .el-input {
    .el-input__inner {
      color: #000 !important;
      font-size: 18px !important;
    }
  }
}
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
.border-top {
  border-top: 2px solid #e8f5ff;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}
.border-right {
  border-right: 1px solid #ccc;
}
.border-left {
  border-left: 1px solid #ccc;
}
.ahover:hover {
  color: #1a73e8;
  border-bottom: 1px solid #1a73e8;
  padding-bottom: 5px;
}
/*覆盖element  el-drawer 头部的下边距样式*/
::v-deep .el-drawer__container.el-drawer__open {
  .el-drawer {
    .el-drawer__header {
      margin-bottom: 0px;
    }
  }
}
/*覆盖card样式*/
.el-card {
  border: none !important;
}
/*取消card的阴影*/
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}
::v-deep .el-descriptions .el-descriptions__header {
  padding: 10px;
  background-color: #f7d1d1;
}
/* justify-content: flex-end;*/
.flex {
  display: flex;
}
.record {
  .colMargin {
    margin: 3px 0;
    .flexBox {
      display: flex;
      .boxLeft {
        flex: 1;
        padding: 10px;
        background-color: #f2f2f2;
        .title {
          font-weight: bold;
          font-size: 13px;
        }
        .boxChildren {
          padding-left: 50px;
          .childrenRow {
            display: flex;
            margin: 5px 0;
            font-size: 13px;
            .rowLeft {
              flex: 1;
              padding: 5px;
              background-color: #fcf7f7;
            }
          }
        }
      }
      .boxRight {
        padding: 10px;
        flex: 1;
        text-align: right;
        background-color: #f7eded;
      }
    }
  }
  .back {
    cursor: pointer;
    width: 30px;
    font-size: 25px;
  }
  /*覆盖element card 卡片样式*/
  ::v-deep .recordContent {
    .el-card {
      .el-card__body {
        padding: 0px !important;
      }
    }
  }
  ::v-deep .recordContent {
    .print-input {
      .title-input {
        .el-input {
          .el-input__inner {
            border: none;
            border-bottom: 3px solid #303133;
            border-radius: 0;
            width: 300px;
          }
        }
      }
    }
  }
  .recordContent {
    overflow: auto;

    .rowFlex {
      .top {
        display: flex;
        width: 100%;
        .top-item {
        }
        .top-item.name {
          flex: 4;
          border-top: 1px solid #ccc;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
          padding: 6px 0 5px 10px;
          text-align: right;
          padding-right: 10px;
        }
        .top-item.key {
          border-top: 1px solid #ccc;
          //border-right: 1px solid #ccc;
          padding: 6px 0 5px 10px;
          flex: 6;
        }
      }
      .top.margin {
        margin-left: 20px;
      }
      .bottom-span {
      }
      /*justify-content: space-between; */
      .bottom-box {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        flex-wrap: wrap;
      }
      .bottom.margin {
        //margin-left: 42px;
      }
      .bottom {
        display: flex;
        width: 50%;
        .bottom-item {
          flex: 5;
        }
        .bottom-item.name {
          border-top: 1px solid #ccc;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
          padding: 6px 0px 6px 10px;
          flex: 4;
          text-align: right;
          padding-right: 10px;
        }
        .bottom-item.key {
          border-top: 1px solid #ccc;
          border-right: 1px solid #ccc;
          padding: 6px 0px 6px 10px;
          flex: 6;
        }
      }
    }
  }
}
</style>
