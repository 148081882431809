<!-- 日志组件 -->
<template>
  <div class="log">
    <!-- tab切换 -->
    <TabCup ref="tabCup" :titleData="titleData" @setTabCup="setTabCup" :num="num"></TabCup>
    <div class="infinite-list-wrapper" style="padding: 5px 10px">
      <ul v-infinite-scroll="loadMore" :infinite-scroll-immediate="'false'" :infinite-scroll-disabled="allLoaded"
        infinite-scroll-distance="10">
        <li v-for="(item, index) in logListData" :key="index">
          <div class="infinite-list">
            <div class="infinite-list-item">
              <i v-if="item.type === '编辑'" class="el-icon-edit icon" style="font-size: 20px; color: #9b9999"></i>
              <i v-else class="el-icon-plus icon" style="font-size: 22px; color: #9b9999"></i>
              <el-tooltip class="item" popper-class="my-tooltip" placement="top" :open-delay="450">
                <div style="width: 200px;" slot="content">{{ item.LogContent }}</div>
                <div style="
                    cursor: pointer;
                    width: 80%;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                  ">
                  {{ item.LogContent }}
                </div>
              </el-tooltip>
            </div>
            <div class="infinite-list-time" style="text-align: left; padding-left: 30px; margin-top: 18px">
              {{ item.UpdateTime.substring(0, 19) }}
            </div>
          </div>
        </li>
      </ul>
      <!-- <div class="infiniteBox" v-show="isloding == false" title="" style="margin-bottom:30px">
                <el-timeline>
                    <el-timeline-item v-for="item in logListData" :key="item.Id" :timestamp="item.ModuleTypeName"
                        icon='el-icon-check' color='#67C23A'>
                        <div class="timelineItem">
                            <div class="spanFirst"><span  style="padding-right:15px;font-weight:bold">{{ item.CreatorRealName + ' :'}}</span> <span>{{ item.LogContent }}</span></div>

                            <span ></span>
                        </div>
                    </el-timeline-item>
                </el-timeline>
            </div> -->
    </div>
    <!-- <div class="noneData" v-if="isloding">
      <img
        src="https://img.zcool.cn/community/0143ef58fdbed0a8012160f7d3f977.gif"
        alt=""
      />
      <div style="margin-top: -100px; margin-left: 5px">加载中...</div>
    </div> -->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
//引入tab切换
import TabCup from "@/components/TabCup.vue";

export default {
  //父组件通过 props 向下传递数据给子组件
  props: {
    Search: {
      type: Array,
      default: [
        {
          Condition: "ModuleType",
          Keyword: "",
        },
        {
          Condition: "ShopId",
          Keyword: "",
        },
      ],
    },
  },
  //import引入的组件需要注入此处能使用
  components: {
    Pagination,
    TabCup,
  },
  //此处存放数据
  data() {
    return {
      // 是否显示加载
      isloding: false,
      // tab切换子组件下标
      num: 0,
      active: 0,
      count: 10,
      loading: false,
      allLoaded: false,
      requestFail: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "",
        order: "",
      },
      logListData: [],
      reverse: true,
      // tab切换数据
      titleData: ["所有动态"],
      count: 0,
    };
  },
  //计算属性
  computed: {
    noMore() {
      // return this.count >= 20;
    },
    disabled() {
      // return this.loading || this.noMore;
    },
  },
  //监听data中数据变化
  watch: {},
  created() {
    // 获取日志列表
    // this.getLogListData()
  }, //生命周期 - 创建完成
  mounted() {
  }, //生命周期 - 挂载完成
  activated() {
  }, //若页面有keep-alive缓存，此函数会触发
  //方法集合
  methods: {
    loadMore() {
      // 第一次禁止加载  防止重复加载
      this.allLoaded = true;
      //  获取日志列表数据
      this.getLogListData();
    },
    // tab切换
    setTabCup(e) {
      this.num = e;
    },
    // 分页事件
    currentChange(page) {
      this.pagination.current = page;
      // 获取修改日志列表数据
      this.logListData = [];
      this.getLogListData();
    },
    // 分页函数
    handlePageChange(data) {
      this.pagination.current = data.pageIndex;
      this.pagination.pageSize = data.pageSize;
      this.getLogListData(); //这是重新获取修改日志列表
    },
    // 获取修改日志列表
    getLogListData() {
      var that = this;
      this.isloding = true;
      this.$http
        .post("/Shop_Manage/Shop_ChangeLog/GetDataListByMoreCondition", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          ...this.sorter,
          Search: this.Search,
        })
        .then((res) => {
          if (res.Success) {
            // 总页数赋值
            this.pagination.total = res.Total;

            if (res.Data.length == 0) {
              if (this.pagination.current != 1) {
                this.allLoaded = true;
                return;
              }
            }

            res.Data.forEach((item) => {
              item.ModuleTypeName = "";
            });

            let tableListData = this.$store.state.tableListData;

            // 修改的日志区块名字处理
            let newArr = [];
            let newArr1 = [];
            tableListData.forEach((item) => {
              if (item.children) {
                item.children.forEach((v) => {
                  if (v.children) {
                    let obj = {
                      DicKey: v.DicKey,
                      DicName: v.DicName,
                    };
                    newArr.push(obj);
                  } else {
                    let obj = {
                      DicKey: item.DicKey,
                      DicName: item.DicName,
                    };
                    newArr1.push(obj);
                  }
                });
              }
            });
            // 对象数组去重处理
            const removeDuplicateObj = (newArr1) => {
              let obj = {};
              newArr1 = newArr1.reduce((newArr, next) => {
                obj[next.DicKey]
                  ? ""
                  : (obj[next.DicKey] = true && newArr.push(next));
                return newArr;
              }, []);
              return newArr1;
            };
            var newObjArr = [...removeDuplicateObj(newArr1), ...newArr];

            let logListData = res.Data;
            logListData.forEach((item) => {
              //   处理列表前面的图标枚举
              if (item.LogContent.substring(0, 2) === "编辑") {
                item["type"] = "编辑";
              } else {
                item["type"] = "添加";
              }
              newObjArr.forEach((v) => {
                if (item.ModuleType === v.DicKey) {
                  // 将对应名字插入到结果中进行渲染ModuleTypeName
                  item.ModuleTypeName = v.DicName;
                }
              });
            });
            if (this.pagination.current == 1) {
              that.logListData = logListData;
            } else {
              that.logListData = that.logListData.concat(res.Data);
            }
            this.pagination.current += 1;
            // 禁止加载loadmore方法请求
            this.allLoaded = false;
            // 隐藏加载中
            // this.isloding = true;
          } else {
            setTimeout(function () {
              that.isloding = false;
              that.logListData = [];
            }, 1000);
          }
        });
    },

    // tab切换事件
    tabClick(event) {
      this.pagination.PageIndex = 1;
      this.active = event.index;
      this.$emit("tabClick", event.index);
    },
  },
};
</script>
<style lang="scss">
::v-deep .el-timeline-item__tail {
  left: 12px;
}

/*覆盖element时间轴线的样式  el-timeline-item:nth-of-type(1) {  修改时间轴线的第一个图标样式*/
::v-deep .el-timeline-item {
  .el-timeline-item__timestamp.is-bottom {
    padding-left: 13px;
  }

  .el-timeline-item__node {
    margin-left: 5px;
    margin-top: 5px;

    .el-timeline-item__icon {
      font-size: 18px;
      background-color: #007fff !important;
      border-radius: 50%;
      margin-left: 5px;
    }
  }
}

// font-size: 29px;

.log {
  height: 100%;
  position: relative;

  .infinite-list-wrapper {
    li {
      margin-bottom: 23px;
    }

    .infinite-list {
      display: flex;
      flex-direction: column;

      .infinite-list-item {
        display: flex;
        flex: 7;

        p {
          margin: 0 !important;
        }

        .icon {
          margin-right: 10px;
        }
      }

      .infinite-list-time {
        text-align: right;
        flex: 3;
      }
    }
  }

  .noneData {
    width: 260px;
    margin: 0 auto;
    text-align: center;

    img {
      width: 100%;
    }
  }

  .tabs {
    margin: 0 20px;

    .infinite-list-wrapper {
      margin: 0 20px;
      height: 100%;
      overflow: auto; // 2. 内容超过指定高度 出现滚动条

      .infiniteItem {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
      }

      .infiniteBox {
        overflow: auto; // 2. 内容超过指定高度 出现滚动条

        .timelineItem {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          padding: 3px 15px;

          .spanFirst {
            padding-bottom: 10px;
            padding-right: 20px;
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.my-tooltip {
  background-color: #ccc !important;
  z-index: 10000 !important;

}
</style>
