<template>
  <!-- 右边招商GO tab外层父组件-->
  <div class="board">
    <div class="taskMenu" v-show="LeftMenuShow" :style="{ width: width + 'px' }">
      <div v-show="width" :class="menuKey == index ? 'menuItem menuActive' : 'menuItem'"
        v-for="(item, index) in TaskMenu" :key="item.name" @click="menuTaskChange(item, index)">
        {{ item.label }}
      </div>
      <div class="shrink" @click="shrink">
        <i :class="width ? 'el-icon-s-fold' : 'el-icon-s-unfold'"> </i>
      </div>
    </div>
    <!--    overflow-y: auto;-->
    <div style="flex: 1; margin-left: 10px">
      <el-tabs v-model="curValue" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in List" :key="item.Id" :label="item.ApplyName" :name="item.ApplyCode">
        </el-tab-pane>
      </el-tabs>
      <div v-if="List.length > 0">
        <TaskList ref="TaskList" :taskObj="{
          ProjectId: pId || $route.query.id,
          AppyId: List[ListIndex].Id,
          AppyName: List[ListIndex].ApplyName,
          ApplyCode: List[ListIndex].ApplyCode,
          ClassType: menuKey
        }" :ClassType="menuKey" @task="changeTask" v-if="!TaskInfoShow"></TaskList>

        <PatGoForm ref="PatGoForm"></PatGoForm>

        <AttractInvestment ref="AttractInvestment" :taskObj="{
          ProjectId: pId || $route.query.id,
          AppyId: List[ListIndex].Id,
          AppyName: List[ListIndex].ApplyName,
          ApplyCode: List[ListIndex].ApplyCode,
          TaskId: TaskId
          ,
        }" v-if="TaskInfoShow && curValue == '10002'" @task="changeTask"></AttractInvestment>
        <RGList ref="RGList" :taskObj="{
          ProjectId: pId || $route.query.id,
          AppyId: List[ListIndex].Id,
          AppyName: List[ListIndex].ApplyName,
          ApplyCode: List[ListIndex].ApplyCode,
          TaskId: TaskId

        }" v-if="TaskInfoShow && curValue == '10004'" @task="changeTask"></RGList>
        <SchemeStageMainList ref="SchemeStageMainList" :taskObj="{
          ProjectId: pId || $route.query.id,
          AppyId: List[ListIndex].Id,
          AppyName: List[ListIndex].ApplyName,
          ApplyCode: List[ListIndex].ApplyCode,
          TaskId: TaskId,
          TaskTypeKey: TaskTypeKey
        }" v-if="TaskInfoShow && curValue == '10005'" @task="changeTask">
        </SchemeStageMainList>
        <!-- && TaskTypeKey == 'SchemeStage' -->
        <!-- <InitialDesignStageMainList ref="InitialDesignStageMainList" :taskObj="{
          ProjectId: pId,
          AppyId: List[ListIndex].Id,
          AppyName: List[ListIndex].ApplyName,
          ApplyCode: List[ListIndex].ApplyCode,
          TaskId: TaskId

        }" v-if="TaskInfoShow && curValue == '10005' && TaskTypeKey == 'InitialDesignStage'" @task="changeTask">
        </InitialDesignStageMainList> -->

      </div>
    </div>
  </div>
</template>

<script>
import TaskList from "./TaskList";

// import PatGO from "@/components/PatGO/PatGO.vue";
import PatGoForm from "@/components/PatGO/PatGoForm.vue"; //巡检GO
// import AllTask from "@/components/AllTask.vue";
// import AttractTask from "../newView/AttractTask.vue";
import AttractInvestment from "../newView/AttractInvestment.vue"; //招商

// import RGTask from '@/views/Project/ReviewGO/RG_Task.vue'
import RGList from '@/views/Project/ReviewGO/RG_List.vue' //审核GO
// import ElectricalGO_Task from '@/views/Project/ElectricalGO/ElectricalGO_Task.vue'
import SchemeStageMainList from '@/views/Project/ElectricalGO/SchemeStage_MainList.vue' //电气GO方案阶段
// import InitialDesignStageMainList from '@/views/Project/ElectricalGO/InitialDesignStage_MainList.vue'  //电气GO初设阶段


// import ElevatorGO from "@/components/ElevatorGO/ElevatorGO.vue";电梯go注释

export default {
  props: {
    pId: { type: String, require: true },
    parentOBJ: { type: Object },
  },
  components: {
    TaskList,
    PatGoForm,
    AttractInvestment,
    RGList,
    SchemeStageMainList,
    // InitialDesignStageMainList
    // PatGO,

    // AllTask,
    // AttractInvestment,
    // AttractTask,
    // RGTask,
    // RGList,
    // ElectricalGO_Task,
    // SchemeStage_MainList
    // ElevatorGO,电梯go注释

  },
  data() {
    return {

      LeftMenuShow: true,//左侧列表是否显示
      TaskMenu: [
        { name: "TaskAll", label: "全部任务" },
        { name: "Tasksecond", label: "参与任务" },
        { name: "Taskthird", label: "我的任务" },
      ],
      curValue: "0",
      List: [],
      menuKey: 0,
      ListIndex: 0,//当前List的index


      width: 100,

      TaskId: "",//任务ID
      TaskInfoShow: false, //任务详情是否显示
      // 电气任务类型
      TaskTypeKey: "", //任务类型
    };
  },
  async mounted() {
    await this.getDataList();
    // 任务点击进入 自动切换模块
    if (this.$route.query.AppyId && this.$route.query.id && this.$route.query.ApplyCode && this.$route.query.TaskId && this.$route.query.Status) {
      if (this.$route.query.ApplyCode == "10005") {
        if (this.$route.query.TaskTypeKey == "SchemeStage" || this.$route.query.TaskTypeKey == "InitialDesignStage") {
          this.changeTask({
            ProjectId: this.$route.query.id || '',
            AppyId: this.$route.query.AppyId || '',
            ApplyCode: this.$route.query.ApplyCode || '',
            Id: this.$route.query.TaskId || '',
            Status: this.$route.query.Status || '',
            TaskTypeKey: this.$route.query.TaskTypeKey || '',
          })
        }
        return
      }
      this.changeTask({
        ProjectId: this.$route.query.id || '',
        AppyId: this.$route.query.AppyId || '',
        ApplyCode: this.$route.query.ApplyCode || '',
        Id: this.$route.query.TaskId || '',
        Status: this.$route.query.Status || '',
        TaskTypeKey: this.$route.query.TaskTypeKey || '',
      })
      // this.handleClick({
      //   name: this.curValue,
      // })
    }
    console.log(11111)
  },
  methods: {
    openInfoForm(entity) {
      this.$http
        .post("/Base_ProjectWork/Base_Project/SaveData", entity)
        .then((res) => {
          console.log("board组件获取保存结果", res, this.parentOBJ);
          this.loading = false;
          if (res.Success) {
            // 重新调用父组件里的详情
            let Id = this.parentOBJ.entity.Id;
            this.parentOBJ.getProjectInfo(Id);
            this.disabled = true;
            this.$emit("infoFormClose", true);
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    shrink() {
      if (this.width > 0) this.width = 0;
      else this.width = 100;
    },
    //组件内点击某个任务 切换任务第二面板并切换左侧菜单 与返回当前事件 
    changeTask(item) {
      console.log(item, '2222222222222222')
      let finIndex = this.List.findIndex(val => {
        return val.ApplyCode == item.ApplyCode
      })
      this.ListIndex = finIndex
      this.curValue = item.ApplyCode;
      if (item.ApplyCode === '10001') {
        this.$nextTick(() => {
          console.log(this.$refs.PatGoForm)
          this.$refs.PatGoForm.openForm(item)
        })
      } else if (item.ApplyCode === '10002' || item.ApplyCode === '10004' || item.ApplyCode === '10005') {
        //监听是否返回到任务列表
        if (item.status) {

          this.TaskInfoShow = false;
          this.LeftMenuShow = true;
          return
        }

        this.TaskInfoShow = true;
        this.TaskId = item.Id;
        this.LeftMenuShow = false;
        if (item.ApplyCode === '10002') {
          this.$nextTick(() => {
            if (this.$refs.AttractInvestment.tableData.length > 0) return;
            this.$refs.AttractInvestment.scrollLoad(item.ApplyCode);
          })
          return
        }
        if (item.ApplyCode === '10005') {
          // item.TaskTypeKey == "InitialDesignStage" //初设阶段
          // item.TaskTypeKey == "SchemeStage" //方案阶段
          this.TaskTypeKey = item.TaskTypeKey
          this.$nextTick(() => {

          })
        }
      }
    },
    menuTaskChange(e, index) {
      this.menuKey = index;
    },
    //点击顶部菜单 巡检GO 审核GO 等---------
    handleClick(e) {
      this.curValue = e.name;
      this.ListIndex = this.List.findIndex((item) => {
        return item.ApplyCode == e.name
      });
      console.log(this.ListIndex, 'this.ListIndex')
      //切换
      this.LeftMenuShow = true
      this.TaskInfoShow = false
      return


    },
    async getDataList() {
      await this.$http
        .post("/Base_BasicData/Base_Apply/GetDataList", {})
        .then((res) => {
          if (res.Success) {
            let arr = []
            if (res.Data && res.Data.length > 0) {
              arr = res.Data.filter((item) => item.ApplyDisplay)
            }
            this.List = [
              {
                ApplyCode: "0",
                ApplyName: "全部",
                ApplyDisplay: true,
                CreateTime: "2023-02-28 15:20:59.000",
                CreatorId: "Admin",
                Deleted: false,
                Id: "",
                ModifyDate: null,
                ModifyID: null,
              },
            ];
            this.List = this.List.concat(arr);
            this.ListIndex = 0
            //电梯go注释
            // this.List.push({
            //   ApplyCode: "0",
            //   ApplyName: "电梯GO",
            //   CreateTime: "2023-02-28 15:20:59.000",
            //   CreatorId: "123",
            //   Deleted: false,
            //   Id: "1111",
            //   ModifyDate: null,
            //   ModifyID: null,
            // });
            this.curValue = this.List[0].ApplyCode;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.board {
  padding-top: 10px;
  display: flex;
  height: calc(100vh - 135px);

  .taskMenu {
    border-right: 1px solid #ededed;
    width: 100px;
    box-sizing: border-box;
    padding: 0 10px;
    position: relative;
    transition: all 70ms ease 0s;

    .menuItem {
      font-size: 14px;
      padding: 7px 10px;
      border-radius: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background: #fae7e7;
        color: #d1100b;
        font-weight: 600;
      }
    }

    .menuActive {
      background: #fae7e7;
      color: #d1100b;
      font-weight: 600;
    }

    .shrink {
      position: absolute;
      bottom: 10px;
      right: -12px;
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 23px;

      &:hover {
        color: #d1100b;
        border-color: #d1100b;
      }
    }
  }
}
</style>
