<template>
  <div class="box" style="overflow: hidden;">
    <div style="display: flex;margin-bottom: 5px;">
      <div
        style="display: flex;align-items: center;justify-content: center;height: 30px;line-height: 30px;width: 40px;">
        <i size="30" class="el-icon-back" style="font-size: 23px;cursor: pointer;" @click="handleBack"></i>
      </div>
      <div style="font-size: 20px;font-weight: bold;line-height: 30px; margin-left: 20px;">数据分析-技术信息</div>
    </div>
    <el-row :gutter="10">
      <!-- 用电负荷平均指标 -->
      <el-col :span="8">
        <el-row style="display: flex; margin-bottom: 5px">
          <el-card style="
              height: 60px;
              background-color: #068d74;
              color: #fff;
              border-radius: 6px;
              width: 100%;
            " body-style="padding: 0 10px;line-height:30px;text-align: center;font-size: 18px;font-weight:bold">
            <i class="iconfont icon-leijiyongdianliang" style="font-size: 18px"></i>&nbsp;整体用电负荷平均指标<br /><span>{{
              homeData.AvgLoadQuota_Electricity
                ? homeData.AvgLoadQuota_Electricity.toFixed(2)
                : 0
            }}W/㎡</span>
          </el-card>
        </el-row>

        <el-card v-loading="MG_TechnicalData_ElectricityLoading" class="InfoCard">
          <div style="border-bottom: 1px solid #EBEEF5;padding-bottom: 5px;">
            <el-row style="line-height: 30px">
              <el-col :span="16">
                <span style="font-weight: bold; font-size: 18px" v-if="leftInfo.type == 1">不同业态商铺的用电负荷 统计</span>
                <span style="font-weight: bold; font-size: 18px" v-if="leftInfo.type == 2">不同业态商铺的用电负荷 占比</span>
              </el-col>
              <el-col :span="8" style="text-align: right;">
                <el-radio-group v-model="leftInfo.type" size="small" style="margin-left: 10px;" @change="InfoChange(1)">
                  <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;柱状图</el-radio-button>
                  <el-radio-button :label="2"><i class="el-icon-s-help"></i>&nbsp;饼图</el-radio-button>
                </el-radio-group>
              </el-col>
            </el-row>

          </div>

          <!-- 不同业态商铺的用电负荷 统计 -->

          <div v-if="leftInfo.type == 1">
            <EchartsIndex :option="electricityOptionsBar" styleStr="width: 100%;height: 350px;"
              v-if="this.MG_TechnicalData_Electricity.LoadQuota_Electricity_All && this.MG_TechnicalData_Electricity.LoadQuota_Electricity_All.length > 0" />
            <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
          </div>
          <div v-if="leftInfo.type == 2">
            <EchartsIndex :option="electricityOptionsPie" styleStr="width: 100%;height: 350px;"
              v-if="this.MG_TechnicalData_Electricity.LoadQuota_Electricity_All && this.MG_TechnicalData_Electricity.LoadQuota_Electricity_All.length > 0" />
            <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
          </div>
        </el-card>
        <el-card v-loading="MG_TechnicalData_ElectricityLoading" class="InfoCard" style="margin-top: 8px;">
          <el-row style="line-height: 30px;border-bottom: 1px solid #EBEEF5;padding-bottom: 5px;">
            <el-col :span="16">
              <span style="font-weight: bold; font-size: 18px">不同业态商铺的用电负荷 平均指标</span>
            </el-col>
            <!-- <el-col :span="8">
              <el-radio-group v-model="leftInfo.average" size="small" @change="InfoChange(3)">
                <el-radio-button v-for="item in typeList" :key="item.DicValue" :label="item.DicName">{{ item.DicName
                  }}</el-radio-button>
              </el-radio-group>
            </el-col> -->
          </el-row>
          <EchartsIndex :option="electricityOptionsBar2" styleStr="width: 100%;height: 350px;"
            v-if="this.MG_TechnicalData_Electricity.LoadQuota_Electricity_Avg && this.MG_TechnicalData_Electricity.LoadQuota_Electricity_Avg.length > 0" />
          <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
        </el-card>
        <el-card v-loading="MG_TechnicalData_ElectricityLoading" class="InfoCard" style="margin-top: 8px;">
          <el-row style="line-height: 30px;border-bottom: 1px solid #EBEEF5;padding-bottom: 5px;">
            <el-col :span="16">
              <span style="font-weight: bold; font-size: 18px">不同业态商铺的用电负荷 时间变化曲线</span>
            </el-col>
            <!-- <el-col :span="8">
              <el-radio-group v-model="leftInfo.time" size="small" @change="InfoChange(4)">
                <el-radio-button v-for="item in typeList" :key="item.DicValue" :label="item.DicName">{{ item.DicName
                  }}</el-radio-button>
              </el-radio-group>
            </el-col> -->
          </el-row>
          <EchartsIndex :option="electricityOptionsLine" styleStr="width: 100%;height: 350px;"
            v-if="this.MG_TechnicalData_Electricity.LoadQuota_Electricity_Line && this.MG_TechnicalData_Electricity.LoadQuota_Electricity_Line.length > 0" />
          <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-row style="margin-bottom: 5px">
          <el-card style="
              height: 60px;
              background-color: #f84d4d;
              color: #fff;
              border-radius: 6px;
              width: 100%;
            " body-style="padding: 0 10px;line-height:30px;text-align: center;font-size: 18px;font-weight:bold">
            <i class="iconfont icon-refuhe" style="font-size: 18px"></i>&nbsp;整体热负荷平均指标<br /><span>{{
              homeData.AvgLoadQuota_Heat ? homeData.AvgLoadQuota_Heat.toFixed(2) : 0
            }}W/㎡</span>
          </el-card>
        </el-row>

        <el-card v-loading="MG_TechnicalData_HeatLoading" class="InfoCard">
          <div style="border-bottom: 1px solid #EBEEF5;padding-bottom: 5px;">
            <el-row style="line-height: 30px">
              <el-col :span="16">
                <span style="font-weight: bold; font-size: 18px" v-if="centerInfo.type == 1">不同业态商铺的热负荷 统计</span>
                <span style="font-weight: bold; font-size: 18px" v-if="centerInfo.type == 2">不同业态商铺的热负荷 占比</span>
              </el-col>
              <el-col :span="8" style="text-align: right;">
                <el-radio-group v-model="centerInfo.type" size="small" style="margin-left: 10px;"
                  @change="InfoChange(5)">
                  <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;柱状图</el-radio-button>
                  <el-radio-button :label="2"><i class="el-icon-s-help"></i>&nbsp;饼图</el-radio-button>
                </el-radio-group>
              </el-col>
            </el-row>

          </div>

          <!-- 不同业态商铺的用电负荷 统计 -->
          <div v-if="centerInfo.type == 1">
            <EchartsIndex :option="electricityOptionsBarCenter" styleStr="width: 100%;height: 350px;"
              v-if="this.MG_TechnicalData_Heat.LoadQuota_Heat_All && this.MG_TechnicalData_Heat.LoadQuota_Heat_All.length > 0" />
            <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
          </div>
          <div v-if="centerInfo.type == 2">
            <EchartsIndex :option="electricityOptionsPieCenter" styleStr="width: 100%;height: 350px;"
              v-if="this.MG_TechnicalData_Heat.LoadQuota_Heat_All && this.MG_TechnicalData_Heat.LoadQuota_Heat_All.length > 0" />
            <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
          </div>

        </el-card>

        <el-card v-loading="MG_TechnicalData_HeatLoading" class="InfoCard" style="margin-top: 8px;">
          <el-row style="line-height: 30px;border-bottom: 1px solid #EBEEF5;padding-bottom: 5px;">
            <el-col :span="16">
              <span style="font-weight: bold; font-size: 18px">不同业态商铺的热负荷 平均指标</span>
            </el-col>
            <!-- <el-col :span="8">
              <el-radio-group v-model="centerInfo.average" size="small" @change="InfoChange(7)">
                <el-radio-button v-for="item in typeList" :key="item.DicValue" :label="item.DicName">{{ item.DicName
                  }}</el-radio-button>
              </el-radio-group>
            </el-col> -->
          </el-row>
          <EchartsIndex :option="electricityOptionsBar2Center" styleStr="width: 100%;height: 350px;"
            v-if="this.MG_TechnicalData_Heat.LoadQuota_Heat_Avg && this.MG_TechnicalData_Heat.LoadQuota_Heat_Avg.length > 0" />
          <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
        </el-card>
        <el-card v-loading="MG_TechnicalData_HeatLoading" class="InfoCard" style="margin-top: 8px;">
          <el-row style="line-height: 30px;border-bottom: 1px solid #EBEEF5;padding-bottom: 5px;">
            <el-col :span="16">
              <span style="font-weight: bold; font-size: 18px">不同业态商铺的热负荷 时间变化曲线</span>
            </el-col>
            <!-- <el-col :span="8">
              <el-radio-group v-model="centerInfo.time" size="small" @change="InfoChange(8)">
                <el-radio-button v-for="item in typeList" :key="item.DicValue" :label="item.DicName">{{ item.DicName
                  }}</el-radio-button>
              </el-radio-group>
            </el-col> -->
          </el-row>
          <EchartsIndex :option="electricityOptionsLineCenter" styleStr="width: 100%;height: 350px;"
            v-if="this.MG_TechnicalData_Heat.LoadQuota_Heat_Line && this.MG_TechnicalData_Heat.LoadQuota_Heat_Line.length > 0" />
          <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-row style="margin-bottom: 5px">
          <el-card style="
              height: 60px;
              background-color: #7395d3;
              color: #fff;
              border-radius: 6px;
              width: 100%;
            " body-style="padding: 0 10px;line-height:30px;text-align: center;font-size: 18px;font-weight:bold">
            <i class="iconfont icon-lengfuhe" style="font-size: 18px"></i>&nbsp;整体冷负荷平均指标<br /><span>{{
              homeData.AvgLoadQuota_Cooling
                ? homeData.AvgLoadQuota_Cooling.toFixed(2)
                : 0
            }}W/㎡</span>
          </el-card>
        </el-row>

        <el-card v-loading="MG_TechnicalData_CoolingLoading" class="InfoCard">
          <div style="border-bottom: 1px solid #EBEEF5;padding-bottom: 5px;">
            <el-row style="line-height: 30px">
              <el-col :span="16">
                <span style="font-weight: bold; font-size: 18px" v-if="rightInfo.type === 1">不同业态商铺的冷负荷 统计</span>
                <span style="font-weight: bold; font-size: 18px" v-if="rightInfo.type === 2">不同业态商铺的冷负荷 占比</span>
              </el-col>
              <el-col :span="8" style="text-align: left;">
                <el-radio-group v-model="rightInfo.type" size="small" style="margin-left: 10px;"
                  @change="InfoChange(9)">
                  <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;柱状图</el-radio-button>
                  <el-radio-button :label="2"><i class="el-icon-s-help"></i>&nbsp;饼图</el-radio-button>
                </el-radio-group>
              </el-col>
            </el-row>
            <el-row style="text-align: right;">
              <!-- <el-radio-group v-model="rightInfo.num" size="small" @change="InfoChange(9)">
                <el-radio-button v-for="item in typeList" :key="item.DicValue" :label="item.DicName">{{ item.DicName
                  }}</el-radio-button>
              </el-radio-group> -->

            </el-row>
          </div>
          <!-- 不同业态商铺的用电负荷 统计 -->
          <div v-if="rightInfo.type === 1">
            <EchartsIndex :option="electricityOptionsBarRight" styleStr="width: 100%;height: 350px;"
              v-if="this.MG_TechnicalData_Cooling.LoadQuota_Cooling_All && this.MG_TechnicalData_Cooling.LoadQuota_Cooling_All.length > 0" />
            <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
          </div>
          <div v-if="rightInfo.type === 2">
            <EchartsIndex :option="electricityOptionsPieRight" styleStr="width: 100%;height: 350px;"
              v-if="this.MG_TechnicalData_Cooling.LoadQuota_Cooling_All && this.MG_TechnicalData_Cooling.LoadQuota_Cooling_All.length > 0" />
            <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
          </div>

        </el-card>

        <el-card v-loading="MG_TechnicalData_CoolingLoading" class="InfoCard" style="margin-top: 8px;">
          <el-row style="line-height: 30px;border-bottom: 1px solid #EBEEF5;padding-bottom: 5px;">
            <el-col :span="16">
              <span style="font-weight: bold; font-size: 18px">不同业态商铺的冷负荷 平均指标</span>
            </el-col>
            <!-- <el-col :span="8">
              <el-radio-group v-model="rightInfo.average" size="small" @change="InfoChange(11)">
                <el-radio-button v-for="item in typeList" :key="item.DicValue" :label="item.DicName">{{ item.DicName
                  }}</el-radio-button>
              </el-radio-group>
            </el-col> -->
          </el-row>
          <EchartsIndex :option="electricityOptionsBar2Right" styleStr="width: 100%;height: 350px;"
            v-if="this.MG_TechnicalData_Cooling.LoadQuota_Cooling_Avg && this.MG_TechnicalData_Cooling.LoadQuota_Cooling_Avg.length > 0" />
          <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
        </el-card>
        <el-card v-loading="MG_TechnicalData_CoolingLoading" class="InfoCard" style="margin-top: 8px;">
          <el-row style="line-height: 30px;border-bottom: 1px solid #EBEEF5;padding-bottom: 5px;">
            <el-col :span="16">
              <span style="font-weight: bold; font-size: 18px">不同业态商铺的冷负荷 时间变化曲线</span>
            </el-col>
          </el-row>
          <EchartsIndex :option="electricityOptionsLineRight" styleStr="width: 100%;height: 350px;"
            v-if="this.MG_TechnicalData_Cooling.LoadQuota_Cooling_Line && this.MG_TechnicalData_Cooling.LoadQuota_Cooling_Line.length > 0" />
          <el-empty description="暂无数据" style="width: 100%; height: 350px;" v-else></el-empty>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
// 引入echarts图表组件
import EchartsIndex from "@/components/Echarts/index";
export default {
  name: "MG_TechnicalDataPage", // 技术数据模块
  components: {
    EchartsIndex,
  },
  props: {
    typeList: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: String,
      default: "",
    },
    homeData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      // 用电
      MG_TechnicalData_Electricity: {},
      MG_TechnicalData_ElectricityLoading: false,
      //冷
      MG_TechnicalData_Cooling: {},
      MG_TechnicalData_CoolingLoading: false,
      //热
      MG_TechnicalData_Heat: {},
      MG_TechnicalData_HeatLoading: false,

      leftInfo: {
        num: "",
        ratio: "",
        average: "",
        time: "",
        type: 1,
      },
      rightInfo: {
        num: "",
        ratio: "",
        average: "",
        time: "",
        type: 1,
      },
      centerInfo: {
        num: "",
        ratio: "",
        average: "",
        time: "",
        type: 1,
      },
      // 1234
      electricityOptionsBar: {
        grid: {
          top: 100, // 设置图表距离容器顶部的距离为10%\
          left: 100,
          bottom: 50
          // 其他grid属性...
        },
        legend: {
          // type: 'scroll',
          //图例系列
          show: true,
          top: 0,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{c}kW",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          axisLabel: {
            formatter: "{value} kW",
          },
          name: '用电负荷（kW）',
          namelocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          type: "value",
        },
        series: [],
      },
      electricityOptionsBar2: {
        grid: {
          top: 100, // 设置图表距离容器顶部的距离为10%\
          left: 100,
          bottom: 50
          // 其他grid属性...
        },
        legend: {
          //图例系列
          show: true,
          top: 0,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}W/㎡",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} W/㎡",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          name: '指标值（W/㎡）',
          namelocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          data: [],

          type: "value",
        },
        series: [],
      },
      electricityOptionsPie: {
        grid: {
          left: 50,
          top: 80,
        },
        tooltip: {},
        label: {
          show: true,
          formatter: (a) => {
            console.log(a)
            return a.value ? a.name + '\n' + a.value.toFixed(2) + 'kW' : ''
          }
        },
        series: [
          {
            type: 'sunburst',
            center: ['50%', '50%'],
            // radius: [0, '80%'],
            data: [],

            label: {
              rotate: 'radial',

            },
            clockwise: true,
            itemStyle: {
              borderRadius: 8,
              borderWidth: 2
            },
            levels: [
              {
                // rotate: 0,
                radius: [0, '10%'],
                label: {
                  rotate: 0,
                  formatter: (a) => {
                    console.log(a)
                    return a.value ? a.name + '\n\n' + a.value.toFixed(2) + 'kW' : ''
                  }
                },
              },
              {

                radius: ['25%', '50%'],
                label: {
                  rotate: 0,
                },

              },
              {
                radius: ['55%', '80%'],
                label: {
                  rotate: 0,
                  position: 'outside'
                }
              },

            ]
          }
        ]

      },
      electricityOptionsLine: {
        grid: {
          top: 120, // 设置图表距离容器顶部的距离为10%\
          left: 80,
          bottom: 80
        },
        xAxis: {
          show: true,
          type: "category",
          // boundaryGap: false,
          axisLabel: {
            // 坐标轴刻度标签的相关设置。
            show: true,
            interval: 0,
            rotate: "-50",
          },
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} kW",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          name: '用电负荷（kW）',
          namelocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          type: "value",
        },
        tooltip: {
          trigger: "item",
          formatter: (a) => {
            return a.name.substring(0, 10) + "<br/>" + a.seriesName + " : " + a.value + 'kW';
          },
        },
        legend: {
          //图例系列
          show: true,
          top: 0,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        series: [],
      },
      // 5678
      electricityOptionsBarCenter: {
        grid: {
          top: 100, // 设置图表距离容器顶部的距离为10%\
          left: 100,
          bottom: 50
          // 其他grid属性...
        },
        legend: {
          //图例系列
          show: true,
          top: 0,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}kW",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} kW",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          name: '热负荷（kW）',
          namelocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          type: "value",
        },
        series: [],
      },
      electricityOptionsBar2Center: {
        grid: {
          top: 100, // 设置图表距离容器顶部的距离为10%\
          left: 100,
          bottom: 50
          // 其他grid属性...
        },
        legend: {
          //图例系列
          show: true,
          top: 0,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}W/㎡",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} W/㎡",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          name: '指标值（W/㎡）',
          namelocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          data: [],
          type: "value",
        },
        series: [],
      },
      electricityOptionsPieCenter: {
        grid: {
          left: 50,
          top: 80,
        },
        tooltip: {},
        label: {
          show: true,
          formatter: (a) => {
            console.log(a)
            return a.value ? a.name + '\n' + a.value.toFixed(2) + 'kW' : ''
          }
        },
        series: [
          {
            type: 'sunburst',
            center: ['50%', '50%'],
            // radius: [0, '80%'],
            data: [],

            label: {
              rotate: 'radial',

            },
            clockwise: true,
            itemStyle: {
              borderRadius: 8,
              borderWidth: 2
            },
            levels: [
              {
                // rotate: 0,
                radius: [0, '10%'],
                label: {
                  rotate: 0,
                  formatter: (a) => {
                    console.log(a)
                    return a.value ? a.name + '\n\n' + a.value.toFixed(2) + 'kW' : ''
                  }
                },
              },
              {

                radius: ['25%', '50%'],
                label: {
                  rotate: 0,
                },

              },
              {
                radius: ['55%', '80%'],
                label: {
                  rotate: 0,
                  position: 'outside'
                }
              },

            ]
          }
        ]

      },
      electricityOptionsLineCenter: {
        grid: {
          top: 120, // 设置图表距离容器顶部的距离为10%\
          left: 80,
          bottom: 80

        },
        xAxis: {
          show: true,
          type: "category",
          // boundaryGap: false,
          axisLabel: {
            // 坐标轴刻度标签的相关设置。
            show: true,
            interval: 0,
            rotate: "-50",
          },
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} kW",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          name: '热负荷（kW）',
          namelocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          type: "value",
        },
        tooltip: {
          trigger: "item",
          formatter: (a) => {
            return a.name.substring(0, 10) + "<br/>" + a.seriesName + " : " + a.value + 'kW';
          },
        },
        legend: {
          //图例系列
          show: true,
          top: 0,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        series: [],
      },
      //9101112
      electricityOptionsBarRight: {
        grid: {
          top: 100, // 设置图表距离容器顶部的距离为10%\
          left: 100,
          bottom: 50
          // 其他grid属性...
        },
        legend: {
          //图例系列
          show: true,
          top: 0,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}kW",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} kW",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          name: '冷负荷（kW）',
          namelocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          type: "value",
        },
        series: [],
      },
      electricityOptionsBar2Right: {
        grid: {
          top: 100, // 设置图表距离容器顶部的距离为10%\
          left: 100,
          bottom: 50
          // 其他grid属性...
        },
        legend: {
          //图例系列
          show: true,
          top: 0,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}W/㎡",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} W/㎡",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          name: '指标值（W/㎡）',
          namelocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          data: [],

          type: "value",
        },
        series: [],
      },
      electricityOptionsPieRight: {
        grid: {
          left: 50,
          top: 80,
        },
        tooltip: {},
        label: {
          show: true,
          formatter: (a) => {
            console.log(a)
            return a.value ? a.name + '\n' + a.value.toFixed(2) + 'kW' : ''
          }
        },
        series: [
          {
            type: 'sunburst',
            center: ['50%', '50%'],
            // radius: [0, '80%'],
            data: [],

            label: {
              rotate: 'radial',

            },
            clockwise: true,
            itemStyle: {
              borderRadius: 8,
              borderWidth: 2
            },
            levels: [
              {
                // rotate: 0,
                radius: [0, '10%'],
                label: {
                  rotate: 0,
                  formatter: (a) => {
                    console.log(a)
                    return a.value ? a.name + '\n\n' + a.value.toFixed(2) + 'kW' : ''
                  }
                },
              },
              {

                radius: ['25%', '50%'],
                label: {
                  rotate: 0,
                },

              },
              {
                radius: ['55%', '80%'],
                label: {
                  rotate: 0,
                  position: 'outside'
                }
              },

            ]
          }
        ]

      },
      electricityOptionsLineRight: {
        grid: {
          top: 120, // 设置图表距离容器顶部的距离为10%\
          left: 80,
          bottom: 80
        },
        xAxis: {
          name: '日期信息',
          nameLocation: 'center',
          nameGap: 65,
          show: true,
          type: "category",
          // boundaryGap: false,
          axisLabel: {
            // 坐标轴刻度标签的相关设置。
            show: true,
            interval: 0,
            rotate: "-50",
          },
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} kW",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
            interval: 0
          },
          name: '冷负荷（kW）',
          namelocation: 'end',
          nameTextStyle: {
            width: 200,
            align: 'right',
          },
          type: "value",
        },
        tooltip: {
          trigger: "item",
          formatter: (a) => {
            return a.name.substring(0, 10) + "<br/>" + a.seriesName + " : " + a.value + 'kW';
          },
        },
        legend: {
          //图例系列
          show: true,
          top: 0,
          itemWidth: 14,
          itemHeight: 14,
          data: [],
        },
        series: [],
      },
    };
  },
  mounted() { },
  created() {
    //默认
    this.leftInfo = {
      num: this.typeList[0].DicName,
      average: this.typeList[0].DicName,
      ratio: this.typeList[0].DicName,
      time: this.typeList[0].DicName,
      type: 1
    };
    this.rightInfo = {
      num: this.typeList[0].DicName,
      average: this.typeList[0].DicName,
      ratio: this.typeList[0].DicName,
      time: this.typeList[0].DicName,
      type: 1
    };
    this.centerInfo = {
      num: this.typeList[0].DicName,
      average: this.typeList[0].DicName,
      ratio: this.typeList[0].DicName,
      time: this.typeList[0].DicName,
      type: 1
    };
    this.getMG_TechnicalData_Electricity()
    this.getMG_TechnicalData_Heat()
    this.getMG_TechnicalData_Cooling()
  },
  methods: {
    InfoChange(num) {
      if (num === 1) {
        if (this.leftInfo.type === 1) {
          if (this.MG_TechnicalData_Electricity.LoadQuota_Electricity_All && this.MG_TechnicalData_Electricity.LoadQuota_Electricity_All.length > 0) {
            this.electricityOptionsBar.legend.data = []
            this.electricityOptionsBar.series = []
            this.MG_TechnicalData_Electricity.LoadQuota_Electricity_All.forEach((item, index) => {
              item.data.forEach(item2 => {
                this.electricityOptionsBar.legend.data.push(item2.name)
                this.electricityOptionsBar.series.push({
                  name: item2.name,
                  type: "bar",
                  barGap: '100%',
                  // stack: index == 0 ? '1' : '2',
                  showBackground: true,
                  emphasis: {
                    focus: "series",
                  },
                  data: [{
                    name: item2.name,
                    value: item2.value ? item2.value.toFixed(2) : 0,
                    label: {
                      show: true,
                      position: 'top',
                      rotate: "0",
                      formatter: (a) => {
                        if (a.value > 0) {
                          return a.value + 'kW'
                        } else {
                          return ''
                        }
                      }
                    },
                  }]
                })
              })

            })
            if (this.electricityOptionsBar.legend.data.length > 12) {
              let num = Math.ceil(this.electricityOptionsBar.legend.data.length / 5)
              let topnum = 0
              for (let i = 0; i < num; i++) {
                topnum = 30 + topnum
              }
              this.electricityOptionsBar.grid.top = topnum
            } else {
              this.electricityOptionsBar.grid.top = 90
            }
            console.log(this.electricityOptionsBar, 'this.electricityOptionsBar')
          }
        } else if (this.leftInfo.type === 2) {
          if (this.MG_TechnicalData_Electricity.LoadQuota_Electricity_All && this.MG_TechnicalData_Electricity.LoadQuota_Electricity_All.length > 0) {
            let arr = JSON.parse(JSON.stringify(this.MG_TechnicalData_Electricity.LoadQuota_Electricity_All))
            arr.forEach(item => {

              item.name = item.shopFormatClass
              item.children = item.data
            })
            this.electricityOptionsPie.tooltip = {
              //tip提示框
              trigger: "item",
              formatter: (a) => {

                if (a.treePathInfo.length == 1) {
                  return `${a.name}：${a.value ? a.value.toFixed(2) : 0}`
                }
                if (a.treePathInfo.length == 2) {
                  let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                  return `${a.name}：${a.value.toFixed(2) + 'kW'}<br/>${a.treePathInfo[0].name}占比：${num}%`
                }
                if (a.treePathInfo.length == 3) {
                  let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                  let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
                  return `${a.name}：${a.value.toFixed(2) + 'kW'}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
                }
                if (a.treePathInfo.length == 4) {
                  let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                  let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
                  let num3 = (a.value / a.treePathInfo[2].value * 100).toFixed(2)
                  return `${a.name}：${a.value.toFixed(2) + 'kW'}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%<br/>${a.treePathInfo[2].name}占比：${num3}%`
                }
              }
            }
            this.electricityOptionsPie.series[0].data = [...arr];
            this.electricityOptionsPie.series[0].name = '总用电负荷'

          }
        }


      } else if (num === 2) {
        // 12合并
      } else if (num === 3) {
        if (this.MG_TechnicalData_Electricity.LoadQuota_Electricity_Avg && this.MG_TechnicalData_Electricity.LoadQuota_Electricity_Avg.length > 0) {

          this.electricityOptionsBar2.legend.data = []
          this.electricityOptionsBar2.series = []
          this.MG_TechnicalData_Electricity.LoadQuota_Electricity_Avg.forEach(item => {
            item.data.forEach(item2 => {
              this.electricityOptionsBar2.legend.data.push(item2.name)
              this.electricityOptionsBar2.series.push({
                name: item2.name,
                type: "bar",
                emphasis: {
                  focus: "series",
                },
                barGap: '100%',
                showBackground: true,
                data: [{
                  name: item2.name,
                  value: item2.value ? item2.value.toFixed(2) : 0,
                  label: {
                    show: true,
                    position: 'top',
                    formatter: () => {
                      if (item2.value > 0) {
                        return item2.value.toFixed(2) + 'W/㎡'
                      } else {
                        return ''
                      }
                    }
                  },
                }],
              })
            })

          })
          if (this.electricityOptionsBar2.legend.data.length > 12) {
            let num = Math.ceil(this.electricityOptionsBar2.legend.data.length / 5)
            let topnum = 0
            for (let i = 0; i < num; i++) {
              topnum = 30 + topnum
            }
            this.electricityOptionsBar2.grid.top = topnum
          } else {
            this.electricityOptionsBar2.grid.top = 90
          }
        }
      } else if (num === 4) {
        if (this.MG_TechnicalData_Electricity.LoadQuota_Electricity_Line && this.MG_TechnicalData_Electricity.LoadQuota_Electricity_Line.length > 0) {
          this.electricityOptionsLine.legend.data = []
          this.electricityOptionsLine.xAxis.data = []
          this.electricityOptionsLine.series = []
          this.MG_TechnicalData_Electricity.LoadQuota_Electricity_Line[0].data[0].value.forEach(item => {
            this.electricityOptionsLine.xAxis.data.push(item.name.substring(0, 10))
          })
          this.MG_TechnicalData_Electricity.LoadQuota_Electricity_Line.forEach(item => {
            item.data.forEach(item => {
              this.electricityOptionsLine.legend.data.push(item.name)
              this.electricityOptionsLine.series.push({
                name: item.name,
                type: "line",
                smooth: true,
                areaStyle: {},
                emphasis: {
                  focus: 'series'
                },
                data: item.value || [],
              })
            })

          })
          if (this.electricityOptionsLine.legend.data.length > 12) {
            let num = Math.ceil(this.electricityOptionsLine.legend.data.length / 5)
            let topnum = 0
            for (let i = 0; i < num; i++) {
              topnum = 30 + topnum
            }
            this.electricityOptionsLine.grid.top = topnum
          } else {
            this.electricityOptionsLine.grid.top = 90
          }
        }
      } else if (num === 5) {
        if (this.centerInfo.type == 1) {
          if (this.MG_TechnicalData_Heat.LoadQuota_Heat_All && this.MG_TechnicalData_Heat.LoadQuota_Heat_All.length > 0) {
            // let obj = this.MG_TechnicalData_Heat.LoadQuota_Heat_All.find(item => item.shopFormatClass == this.centerInfo.num)
            this.electricityOptionsBarCenter.legend.data = []
            this.electricityOptionsBarCenter.series = []
            this.MG_TechnicalData_Heat.LoadQuota_Heat_All.forEach(item => {
              item.data.forEach(item2 => {
                this.electricityOptionsBarCenter.legend.data.push(item2.name)
                this.electricityOptionsBarCenter.series.push({
                  name: item2.name,
                  type: "bar",
                  emphasis: {
                    focus: "series",
                  },
                  barGap: '100%',
                  showBackground: true,
                  data: [{
                    name: item2.name,
                    value: item2.value,
                    label: {
                      show: true,
                      position: 'top',
                      formatter: () => {
                        if (item2.value > 0) {
                          return item2.value + 'kW'
                        } else {
                          return ''
                        }
                      }
                    }
                  }],
                })
              })
            })
            if (this.electricityOptionsBarCenter.legend.data.length > 12) {
              let num = Math.ceil(this.electricityOptionsBarCenter.legend.data.length / 5)
              let topnum = 0
              for (let i = 0; i < num; i++) {
                topnum = 30 + topnum
              }
              this.electricityOptionsBarCenter.grid.top = topnum
            } else {
              this.electricityOptionsBarCenter.grid.top = 90
            }
          }
        } else if (this.centerInfo.type == 2) {
          if (this.MG_TechnicalData_Heat.LoadQuota_Heat_All && this.MG_TechnicalData_Heat.LoadQuota_Heat_All.length > 0) {

            let arr = JSON.parse(JSON.stringify(this.MG_TechnicalData_Heat.LoadQuota_Heat_All))
            arr.forEach(item => {

              item.name = item.shopFormatClass
              item.children = item.data
            })
            this.electricityOptionsPieCenter.tooltip = {
              //tip提示框
              trigger: "item",
              formatter: (a) => {

                if (a.treePathInfo.length == 1) {
                  return `${a.name}：${a.value ? a.value.toFixed(2) + 'kW' : 0}`
                }
                if (a.treePathInfo.length == 2) {
                  let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                  return `${a.name}：${a.value.toFixed(2) + 'kW'}<br/>${a.treePathInfo[0].name}占比：${num}%`
                }
                if (a.treePathInfo.length == 3) {
                  let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                  let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
                  return `${a.name}：${a.value.toFixed(2) + 'kW'}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
                }
                if (a.treePathInfo.length == 4) {
                  let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                  let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
                  let num3 = (a.value / a.treePathInfo[2].value * 100).toFixed(2)
                  return `${a.name}：${a.value.toFixed(2) + 'kW'}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%<br/>${a.treePathInfo[2].name}占比：${num3}%`
                }
              }
            }
            this.electricityOptionsPieCenter.series[0].data = [...arr];
            this.electricityOptionsPieCenter.series[0].name = '总热负荷'
          }
        }
      } else if (num === 6) {
        // 56合并
      } else if (num === 7) {
        if (this.MG_TechnicalData_Heat.LoadQuota_Heat_Avg && this.MG_TechnicalData_Heat.LoadQuota_Heat_Avg.length > 0) {

          this.electricityOptionsBar2Center.legend.data = []
          this.electricityOptionsBar2Center.series = []
          this.MG_TechnicalData_Heat.LoadQuota_Heat_Avg.forEach(item => {
            item.data.forEach(item2 => {
              this.electricityOptionsBar2Center.legend.data.push(item2.name)
              this.electricityOptionsBar2Center.series.push({
                name: item2.name,
                type: "bar",
                emphasis: {
                  focus: "series",
                },
                barGap: '100%',
                showBackground: true,
                data: [{
                  name: item2.name,
                  value: item2.value ? item2.value.toFixed(2) : 0,
                  label: {
                    show: true,
                    position: 'top',
                    formatter: () => {
                      if (item2.value > 0) {
                        return item2.value.toFixed(2) + 'W/㎡'
                      } else {
                        return ''
                      }
                    }
                  },
                }],
              })
            })
          })
          if (this.electricityOptionsBar2Center.legend.data.length > 12) {
            let num = Math.ceil(this.electricityOptionsBar2Center.legend.data.length / 5)
            let topnum = 0
            for (let i = 0; i < num; i++) {
              topnum = 30 + topnum
            }
            this.electricityOptionsBar2Center.grid.top = topnum
          } else {
            this.electricityOptionsBar2Center.grid.top = 90
          }
        }
      } else if (num === 8) {
        if (this.MG_TechnicalData_Heat.LoadQuota_Heat_Line && this.MG_TechnicalData_Heat.LoadQuota_Heat_Line.length > 0) {
          this.electricityOptionsLineCenter.legend.data = []
          this.electricityOptionsLineCenter.xAxis.data = []
          this.electricityOptionsLineCenter.series = []
          this.MG_TechnicalData_Heat.LoadQuota_Heat_Line[0].data[0].value.forEach(item => {
            this.electricityOptionsLineCenter.xAxis.data.push(item.name.substring(0, 10))
          })
          this.MG_TechnicalData_Heat.LoadQuota_Heat_Line.forEach(item => {
            item.data.forEach(item2 => {
              this.electricityOptionsLineCenter.legend.data.push(item2.name)
              this.electricityOptionsLineCenter.series.push({
                name: item2.name,
                type: "line",
                smooth: true,
                areaStyle: {},
                emphasis: {
                  focus: 'series'
                },
                data: item2.value || [],
              })
            })
          })
          if (this.electricityOptionsLineCenter.legend.data.length > 12) {
            let num = Math.ceil(this.electricityOptionsLineCenter.legend.data.length / 5)
            let topnum = 0
            for (let i = 0; i < num; i++) {
              topnum = 30 + topnum
            }
            this.electricityOptionsLineCenter.grid.top = topnum
          } else {
            this.electricityOptionsLineCenter.grid.top = 90
          }
        }
      } else if (num === 9) {
        if (this.rightInfo.type == 1) {
          if (this.MG_TechnicalData_Cooling.LoadQuota_Cooling_All && this.MG_TechnicalData_Cooling.LoadQuota_Cooling_All.length > 0) {

            this.electricityOptionsBarRight.legend.data = []
            this.electricityOptionsBarRight.series = []
            this.MG_TechnicalData_Cooling.LoadQuota_Cooling_All.forEach(item => {
              item.data.forEach(item2 => {
                this.electricityOptionsBarRight.legend.data.push(item2.name)
                this.electricityOptionsBarRight.series.push({
                  name: item2.name,
                  type: "bar",
                  emphasis: {
                    focus: "series",
                  },
                  barGap: '100%',
                  showBackground: true,
                  data: [{
                    name: item2.name,
                    value: item2.value,
                    label: {
                      show: true,
                      position: 'top',
                      formatter: () => {
                        if (item2.value > 0) {
                          return item2.value + 'kW'
                        } else {
                          return ''
                        }
                      }
                    }
                  }],
                })
              })
            })
            if (this.electricityOptionsBarRight.legend.data.length > 12) {
              let num = Math.ceil(this.electricityOptionsBarRight.legend.data.length / 5)
              let topnum = 0
              for (let i = 0; i < num; i++) {
                topnum = 30 + topnum
              }
              this.electricityOptionsBarRight.grid.top = topnum
            } else {
              this.electricityOptionsBarRight.grid.top = 90
            }
          }
        } else if (this.rightInfo.type == 2) {
          if (this.MG_TechnicalData_Cooling.LoadQuota_Cooling_All && this.MG_TechnicalData_Cooling.LoadQuota_Cooling_All.length > 0) {
            let arr = JSON.parse(JSON.stringify(this.MG_TechnicalData_Cooling.LoadQuota_Cooling_All))
            arr.forEach(item => {
              item.name = item.shopFormatClass
              item.children = item.data
            })
            this.electricityOptionsPieRight.tooltip = {
              //tip提示框
              trigger: "item",
              formatter: (a) => {

                if (a.treePathInfo.length == 1) {
                  return `${a.name}：${a.value ? a.value.toFixed(2) : 0}`
                }
                if (a.treePathInfo.length == 2) {
                  let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                  return `${a.name}：${a.value.toFixed(2) + 'kW'}<br/>${a.treePathInfo[0].name}占比：${num}%`
                }
                if (a.treePathInfo.length == 3) {
                  let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                  let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
                  return `${a.name}：${a.value.toFixed(2) + 'kW'}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
                }
                if (a.treePathInfo.length == 4) {
                  let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                  let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
                  let num3 = (a.value / a.treePathInfo[2].value * 100).toFixed(2)
                  return `${a.name}：${a.value.toFixed(2) + 'kW'}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%<br/>${a.treePathInfo[2].name}占比：${num3}%`
                }
              }
            }
            this.electricityOptionsPieRight.series[0].data = [...arr];
            this.electricityOptionsPieRight.series[0].name = '总冷负荷'


          }
        }


      } else if (num === 10) {
        // 910合并
      } else if (num === 11) {
        if (this.MG_TechnicalData_Cooling.LoadQuota_Cooling_Avg && this.MG_TechnicalData_Cooling.LoadQuota_Cooling_Avg.length > 0) {

          this.electricityOptionsBar2Right.legend.data = []
          this.electricityOptionsBar2Right.series = []
          this.MG_TechnicalData_Cooling.LoadQuota_Cooling_Avg.forEach(item => {
            item.data.forEach(item2 => {
              this.electricityOptionsBar2Right.legend.data.push(item2.name)
              this.electricityOptionsBar2Right.series.push({
                name: item2.name,
                type: "bar",
                emphasis: {
                  focus: "series",
                },
                barGap: '100%',
                showBackground: true,

                data: [{
                  name: item2.name,
                  value: item2.value ? item2.value.toFixed(2) : 0,
                  label: {
                    show: true,
                    position: 'top',
                    formatter: () => {
                      if (item2.value > 0) {
                        return item2.value.toFixed(2) + 'W/㎡'
                      } else {
                        return ''
                      }
                    }
                  },

                }],
              })
            })
          })
          if (this.electricityOptionsBar2Right.legend.data.length > 12) {
            let num = Math.ceil(this.electricityOptionsBar2Right.legend.data.length / 5)
            let topnum = 0
            for (let i = 0; i < num; i++) {
              topnum = 30 + topnum
            }
            this.electricityOptionsBar2Right.grid.top = topnum
          } else {
            this.electricityOptionsBar2Right.grid.top = 90
          }


        }
      } else if (num === 12) {
        if (this.MG_TechnicalData_Cooling.LoadQuota_Cooling_Line && this.MG_TechnicalData_Cooling.LoadQuota_Cooling_Line.length > 0) {

          this.electricityOptionsLineRight.legend.data = []
          this.electricityOptionsLineRight.xAxis.data = []
          this.electricityOptionsLineRight.series = []

          this.MG_TechnicalData_Cooling.LoadQuota_Cooling_Line[0].data[0].value.forEach(item => {
            this.electricityOptionsLineRight.xAxis.data.push(item.name.substring(0, 10))
          })
          this.MG_TechnicalData_Cooling.LoadQuota_Cooling_Line.forEach(item => {
            item.data.forEach(item2 => {
              this.electricityOptionsLineRight.legend.data.push(item2.name)

              this.electricityOptionsLineRight.series.push({
                name: item2.name,
                type: "line",
                smooth: true,
                areaStyle: {},
                emphasis: {
                  focus: 'series'
                },
                data: item2.value || [],

              })
            })
          })
          if (this.electricityOptionsLineRight.legend.data.length > 12) {
            let num = Math.ceil(this.electricityOptionsLineRight.legend.data.length / 5)
            let topnum = 0
            for (let i = 0; i < num; i++) {
              topnum = 30 + topnum
            }
            this.electricityOptionsLineRight.grid.top = topnum
          } else {
            this.electricityOptionsLineRight.grid.top = 90
          }
        }
      }


    },
    //1234
    getMG_TechnicalData_Electricity() {
      this.MG_TechnicalData_ElectricityLoading = true
      this.$http
        .post("/Base_ProjectManage/Project_DataAnalysis/GetMG_TechnicalData_Electricity?projectId=" + this.projectId,).then(res => {
          this.MG_TechnicalData_ElectricityLoading = false
          if (res.Success) {
            this.MG_TechnicalData_Electricity = res.Data || {}

            this.InfoChange(1)
            this.InfoChange(2)
            this.InfoChange(3)
            this.InfoChange(4)
          } else {
            this.MG_TechnicalData_Electricity = {}
            this.$message.error('用电负荷数据获取失败');

          }
        }).catch(() => {
          this.MG_TechnicalData_ElectricityLoading = false

          this.MG_TechnicalData_Electricity = {}
          this.$message.error('用电负荷数据获取失败');

        });

    },
    //5678
    getMG_TechnicalData_Heat() {
      this.MG_TechnicalData_HeatLoading = true
      this.$http
        .post("/Base_ProjectManage/Project_DataAnalysis/GetMG_TechnicalData_Heat?projectId=" + this.projectId,).then(res => {
          this.MG_TechnicalData_HeatLoading = false
          if (res.Success) {
            this.MG_TechnicalData_Heat = res.Data || {}
            this.InfoChange(5)
            this.InfoChange(6)
            this.InfoChange(7)
            this.InfoChange(8)
          } else {
            this.MG_TechnicalData_Electricity = {}
            this.$message.error('整体热负荷数据获取失败');

          }
        }).catch(() => {
          this.MG_TechnicalData_HeatLoading = false
          this.MG_TechnicalData_Heat = {}
          this.$message.error('整体冷负荷数据获取失败');

        });

    },
    //9101112
    getMG_TechnicalData_Cooling() {
      this.MG_TechnicalData_CoolingLoading = true
      this.$http
        .post("/Base_ProjectManage/Project_DataAnalysis/GetMG_TechnicalData_Cooling?projectId=" + this.projectId,).then(res => {
          this.MG_TechnicalData_CoolingLoading = false
          if (res.Success) {
            this.MG_TechnicalData_Cooling = res.Data || {}
            this.InfoChange(9)
            this.InfoChange(10)
            this.InfoChange(11)
            this.InfoChange(12)
          } else {
            this.MG_TechnicalData_Cooling = {}
            this.$message.error('整体冷负荷数据获取失败');
          }
        }).catch(() => {
          this.MG_TechnicalData_CoolingLoading = false
          this.MG_TechnicalData_Cooling = {}
          this.$message.error('整体冷负荷数据获取失败');
        });

    },
    handleBack() {
      this.$emit("changePageblock", "");
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  padding: 0px;
  box-sizing: border-box;
}

.dataStripe_i {
  font-size: 40px;
  color: #ffffff;
  line-height: 90px;
  margin-left: 20px;
}

// 数据条卡片内标题
.dataStripe_title {
  display: block;
  line-height: 40px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

// 数据条卡片内显示值
.dataStripe_value {
  display: block;
  line-height: 50px;
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
}

.InfoCard {
  border-radius: 10px;
  padding: 0px 5px;
  box-sizing: border-box;
  border: 5px solid #c3c3c3;
}
</style>
