<template>
  <div style="width: 100%">
    <el-input
      class="input"
      v-if="type === 'input'"
      ref="input"
      type="text"
      v-model="keyWords"
      :placeholder="placeHolder"
      @input="updateValue"
      @focus="onFocus"
      @blur="onBlur"
      @click="clickInput"
    >
      <div
        v-if="suffixText"
        slot="suffix"
        style="
          height: 100%;
          align-items: center;
          display: flex;
          margin-top: -1px;
        "
      >
        {{ suffixText }}
      </div>
    </el-input>

    <el-input
      :rows="rows"
      resize="none"
      class="textarea"
      :class="{
        top: position === 'top',
        bottom: position === 'bottom',
        left: position === 'left',
        right: position === 'right',
      }"
      style="width: 100%"
      v-if="type === 'textarea'"
      ref="input"
      type="textarea"
      v-model="keyWords"
      :placeholder="placeHolder"
      @input="updateValue"
      @focus="onFocus"
      @blur="onBlur"
      @click="clickInput"
    >
    </el-input>
    <el-select
      class="input-select iconActive"
      v-model="value"
      v-if="type === 'select'"
      @change="changeSelect"
    >
      <el-option
        v-for="(item, index) in options"
        :key="item.value"
        :label="item.label"
        :value="item.label"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import Board from "@/views/Project/Page/Board.vue";

export default {
  name: "formCell",
  components: { Board },
  props: {
    type: {}, //输入框的类型
    selectValue: {
      type: String,
    },
    inputValue: {
      //textarea输入框的值
      type: String,
    },
    suffixText: {
      type: String, //輸入框尾部文字或图标
      default: "m",
    },
    label: {},
    leftIcon: {},
    clearIcon: {}, // 清除图标
    successIcon: {}, // 校验成功时显示的图标
    placeHolder: {}, // input的placeholder
    errorMsg: {}, // 显示错误信息
    inputBgColor: {
      // input输入框的背景
      type: String,
      default: "#F5F5F5",
    },
    rows: {
      //textara可输入的行数
      type: Number,
    },
    cellBgColor: {}, // 整行背景
    success: {
      // 校验成功
      type: Boolean,
      default: false,
    },
    top: {
      //是否上边框
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "top",
    },
    kewWordsType: {
      type: String,
    },
    bottom: {
      //是否下边框
      type: Boolean,
      default: false,
    },
    left: {
      //是否左边框
      type: Boolean,
      default: false,
    },
    right: {
      //是否右边框
      type: Boolean,
      default: false,
    },
    isPrint: {
      // 是否進行打印
      type: Boolean,
      default: false,
    },
    showVerify: {}, // 显示验证码
    showCodeBtn: {}, // 显示获取验证码按钮
    authTime: {},
    options: {
      // 下拉选项
      type: Array,
    },
  },
  data() {
    return {
      showClear: false,
      inputRef: "",
      value: this.selectValue, //下拉框默认值
      keyWords: this.inputValue, //输入框的值
    };
  },
  methods: {
    setValue(val) {
      this.$emit("input", val);
    },

    // 更新选择项
    changeSelect(val) {
      console.log("获取选择的值", val, this.value);
      this.$emit("change", val, this.label);
    },

    // 更新input的value值
    updateValue(event) {
      console.log("获取val", event);
      this.setValue(event);
    },

    // 点击input无延迟聚焦
    clickInput() {
      this.inputRef.focus();
    },

    // 聚焦事件
    onFocus() {
      this.$emit("watchFocus");
    },

    // 失焦事件
    onBlur() {
      this.$emit("watchBlur");
    },

    // 清空input的value值
    clearValue() {
      this.inputRef.value = "";
      this.inputRef.focus();
      this.updateValue("");
    },

    getVerifyCode() {
      this.$emit("getVerifyCode");
    },
  },
  created() {
    let _this = this;
    this.$nextTick(() => {
      _this.inputRef = this.$refs.input;
    });
  },
};
</script>

<style scoped lang="scss" type="text/scss">
$theme: #d1100b;
::v-deep.textarea.top.el-textarea {
  .el-textarea__inner {
    border: none;
    border-radius: 0;
    min-height: 40px;
    min-height: 40px !important;
    border-top: 1px solid #ccc;
  }
}
::v-deep.textarea.bottom.el-textarea {
  .el-textarea__inner {
    border: none;
    border-radius: 0;
    min-height: 40px;
    min-height: 40px !important;
    border-bottom: 1px solid #ccc;
  }
}
::v-deep.textarea.left.el-textarea {
  .el-textarea__inner {
    border: none;
    min-height: 40px;
    border-radius: 0;
    min-height: 40px !important;
    border-left: 1px solid #ccc;
  }
}
::v-deep.textarea.right.el-textarea {
  .el-textarea__inner {
    border: none;
    border-radius: 0;
    min-height: 40px !important;
    border-right: 1px solid #ccc;
  }
}
::v-deep.el-select.input-select {
  .el-input {
    .el-input__inner {
      border-radius: 0px;
    }
  }
}
::v-deep.input.el-input {
  .el-input__inner {
    border-radius: 0px;
    font-size: 16px !important;
  }
}
//覆盖element 下拉列表右侧的图标样式
::v-deep.input-select.iconActive {
  .el-input {
    .el-input__suffix {
      .el-input__suffix-inner {
        .el-input__icon {
          color: #ffffff;
        }
      }
    }
  }
}
//覆盖element 下拉列表内容文字的位置  后续需要打开即可
::v-deep.el-select.input-select {
  //.el-input__inner {
  //  padding-right: 10px !important; //覆蓋element下拉列表内容的右边距
  //}
  .el-input {
    .el-input__inner {
      //text-align: right !important;
      font-size: 16px !important;
    }
    //.el-input__suffix {
    //  //top ----align  这段代码是为了解决最右边的图标透明覆盖了文字样式的问题
    //  right: -15px !important; //-15边距为了能够将挡住文字的白色图标向右移动
    //  top: 0px !important;
    //  display: flex !important;
    //  justify-content: center !important;
    //  flex-wrap: nowrap !important;
    //  flex-direction: row !important;
    //  align-content: flex-start !important;
    //  padding-right: 10px !important; //覆蓋element下拉列表内容的右边距
  }
}
//}
//覆盖element 输入框后面的图标样式
::v-deep.input.el-input {
  .el-input__suffix {
    .el-input__suffix-inner {
      div {
        margin-top: 10px !important;
      }
    }
  }
}

::v-deep .el-input {
  .el-input__inner {
    //height: 30px;
  }
  .el-input__prefix,
  .el-input__suffix {
    height: 30px;
  }

  /* 下面设置右侧按钮居中 */
  .el-input__suffix {
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: flex-start;
  }

  /* 输入框加上上下边是 60px + 2px =62px */
  .el-input__icon {
    line-height: 62px;
  }
}
</style>
