<template>
  <el-row style="width: 1320px; padding-bottom: 10px; position: relative">
    <el-col style="text-align: right; margin-bottom: 10px">
      <el-button size="mini" type="primary" @click="addLouDon">添加楼栋</el-button>
    </el-col>
    <el-table :max-height="height - 50 + 'px'" :data="data" style="width: 100%">
      <el-table-column prop="BuildingCode" label="楼栋编号" sortable align="left">
      </el-table-column>
      <el-table-column prop="ProjectSubregion" label="楼栋所在分区" sortable align="left">

      </el-table-column>
      <el-table-column prop="OperateArea" label="经营面积(m²)" align="right" sortable>
        <template slot-scope="scope">
          <div class="list_column">{{ scope.row.OperateArea ? scope.row.OperateArea : 0 }}㎡</div>
        </template>
      </el-table-column>
      <el-table-column prop="OnGround_Area" label="地上面积(m²)" align="right" sortable>
        <template slot-scope="scope">
          <div class="list_column">{{ scope.row.OnGround_Area ? scope.row.OnGround_Area : 0 }}㎡</div>
        </template>
      </el-table-column>
      <el-table-column prop="UnderGround_Area" label="地下面积(m²)" align="right" sortable>
        <template slot-scope="scope">
          <div class="list_column">{{ scope.row.UnderGround_Area ? scope.row.UnderGround_Area : 0 }}㎡</div>
        </template>
      </el-table-column>
      <el-table-column prop="TotalHeight" label="建筑总高度(m)" align="right" sortable>
        <template slot-scope="scope">
          <div class="list_column">{{ scope.row.TotalHeight ? scope.row.TotalHeight : 0 }}m</div>
        </template>
      </el-table-column>
      <el-table-column prop="OnGround_Floor" label="地上层数" align="right" sortable>
        <template slot-scope="scope">
          <div class="list_column">{{ scope.row.OnGround_Floor ? scope.row.OnGround_Floor : 0 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="UnderGround_Floor" label="地下层数" align="right" sortable>
        <template slot-scope="scope">
          <div class="list_column">{{ scope.row.UnderGround_Floor ? scope.row.UnderGround_Floor : 0 }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="right">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="itemClick(scope.row)">编辑</el-button>
          <el-button size="mini" type="text" @click="itemDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer title="楼栋维护" :visible.sync="dialogVisible" direction="rtl" size="70%">
      <el-form class="form" v-show="dialogVisible" :model="entity" ref="entity" label-width="120px"
        label-position="right">
        <el-card v-for="(item, index) in formData" :key="index" style="margin-bottom: 10px">
          <el-collapse v-model="activeBuildingNames">
            <el-collapse-item :title="`楼栋：${formData[index].BuildingCode ? formData[index].BuildingCode : ''}`"
              :name="`楼栋${index}`">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="楼栋编号" prop="BuildingCode">
                    <el-input v-model="item.BuildingCode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="楼栋所在分区" prop="ProjectSubregion">
                    <template #label>
                      <span>楼栋所在分区</span>
                      <el-tooltip class="item" effect="dark" content="当前整栋楼所在项目区域" placement="top">
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </template>
                    <el-input :placeholder="index == 0 ? '' : '同上'" v-model="item.ProjectSubregion">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="建筑总高度" prop="TotalHeight">
                    <el-input :placeholder="index == 0 ? '' : '同上'" v-model="item.TotalHeight" type="number">
                      <span slot="suffix">m</span>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="经营面积" prop="OperateArea">
                    <el-input :placeholder="index == 0 ? '' : '同上'" v-model="item.OperateArea" type="number">
                      <span slot="suffix">m²</span>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="地上层数" prop="OnGround_Floor">
                    <el-input :placeholder="index == 0 ? '' : '同上'" v-model="item.OnGround_Floor" type="number"
                      :min="0">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="地下层数" prop="UnderGround_Floor">
                    <el-input :placeholder="index == 0 ? '' : '同上'" v-model="item.UnderGround_Floor" type="number"
                      :max="0">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="地上面积" prop="OnGround_Area">
                    <el-input :placeholder="index == 0 ? '' : '同上'" v-model="item.OnGround_Area" type="number">
                      <span slot="suffix">m²</span>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="地下面积" prop="UnderGround_Area">
                    <el-input :placeholder="index == 0 ? '' : '同上'" v-model="item.UnderGround_Area" type="number">
                      <span slot="suffix">m²</span>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="业态" prop="yetai">
                    <el-select :placeholder="index == 0 ? '' : '同上'" v-model="item.yetai" multiple
                      @change="YetaiChange($event, item)" filterable>
                      <el-option v-for="im in YetaiList" :key="im.DicKey" :label="im.DicName" :value="im.DicKey">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4" class="textAlignRight">
                  <el-button size="mini" v-if="item.yetai && item.yetai.length > 0"
                    @click="item.yetaiShow = !item.yetaiShow">
                    {{ item.yetaiShow ? "隐藏" : "更多" }}
                  </el-button>
                  <el-button size="mini" v-if="addAndRemove" type="warning" @click="BuildingRemoval(index)">移 除
                  </el-button>
                </el-col>
                <el-col :span="24" v-show="item.yetai && item.yetai.length > 0 && item.yetaiShow">
                  <el-collapse v-model="itemYtShow">
                    <el-collapse-item title="业态" :name="index">
                      <el-card v-for="(collapse, cIndex) in YetaiList" :key="collapse.DicKey"
                        v-show="item.Formats && item.Formats[collapse.DicKey] && item.Formats[collapse.DicKey].show"
                        style="margin: 10px auto">
                        <div slot="header" class="clearfix">
                          <span>{{ collapse.DicName }}</span>
                        </div>
                        <el-col :span="8" v-for="im in collapse.children" :key="im.DicKey">
                          <el-form-item :label="im.DicName" v-if="item.Formats[collapse.DicKey]">
                            <!-- 业态下的每一项(每一项视为对象继续向下延伸)业态项下面的子项 -->
                            <!-- item.Formats[collapse.DicKey][im.DicKey] -->
                            <el-input v-if="!im.DictionaryDetailsList || im.DictionaryDetailsList.length <= 0"
                              v-model="item.Formats[collapse.DicKey][im.DicKey]"></el-input>
                            <el-select v-else :placeholder="index == 0 ? '' : '同上'"
                              v-model="item.Formats[collapse.DicKey][im.DicKey]" filterable>
                              <el-option v-for="option in im.DictionaryDetailsList" :key="option.DicName"
                                :label="option.DicName" :value="option.DicName"></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                      </el-card>
                    </el-collapse-item>
                  </el-collapse>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </el-form>
      <div class="buttonGroup">
        <el-button size="mini" v-if="addAndRemove" type="success" @click="BuildingAddition">追 加</el-button>
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="louDonConfirm">确 定</el-button>
      </div>
    </el-drawer>
  </el-row>
</template>

<script>
import louDonImg from "@/assets/ProjectInfo/LouDon.png";

export default {
  data() {
    return {
      dialogVisible: false,
      entity: {},
      data: [],
      formData: [],
      YetaiList: [],
      itemYtShow: [],
      louDonImg,
      addAndRemove: true,
      activeBuildingNames: ['楼栋0']
    };
  },
  mounted() {
    this.getDataList();
    this.getYeTaiList();
  },
  computed: {
    height() {
      return document.documentElement.clientHeight - 210;
    },
  },
  methods: {
    // 业态选择
    YetaiChange(e, obj) {

      for (const key in obj.Formats) {
        obj.Formats[key].show = e.includes(key);
      }
      e.forEach((item) => {
        if (!obj.Formats[item]) this.$set(obj.Formats, item, { show: true });
      });
      console.log(obj.Formats);
      console.log(obj, 'obj')
      this.itemYtShow = this.formData.map((item, index) => index)
    },
    // 添加楼栋
    addLouDon() {
      this.addAndRemove = true;
      if (this.dialogVisible) this.dialogVisible = false;
      this.formData = [{ yetaiShow: true, Formats: {} }];
      this.activeBuildingNames = []
      this.formData.forEach((item, index) => {
        this.activeBuildingNames.push('楼栋' + index)
        this.itemYtShow.push(index)
      })
      setTimeout(() => {
        this.dialogVisible = true;
      }, 200);
    },
    // 删除
    itemDel(obj) {
      this.$http
        .post("/Base_ProjectManage/Project_Building/DeleteData", [obj.Id])
        .then((res) => {
          if (res.Success) {
            this.getDataList();
            this.$message.success("操作成功");
          } else this.$message.error(res.Msg);
        });
    },
    // 编辑
    itemClick(obj) {
      this.addAndRemove = false;
      if (this.dialogVisible) this.dialogVisible = false;
      setTimeout(() => {
        this.formData = [JSON.parse(JSON.stringify(obj))];
        this.dialogVisible = true;
        this.itemYtShow = this.formData.map((item, index) => index)
      }, 200);
    },
    //追加
    BuildingAddition() {
      this.formData.push({ yetaiShow: true, Formats: {} })
      this.activeBuildingNames.push('楼栋' + `${this.formData.length - 1}`)
    },
    //移除
    BuildingRemoval(index) {
      this.formData.splice(index, 1)
      this.activeBuildingNames = this.activeBuildingNames.filter(item => item !== ('楼栋') + index)
    },
    // 保存楼栋
    louDonConfirm() {
      console.log('打印所有楼栋批量信息', this.formData)
      let codeFlag = this.formData.every((item) => item.BuildingCode);
      if (!codeFlag) return this.$message.error("请填写楼栋编号");
      let arr = [];
      this.formData.length >= 1 &&
        this.formData.forEach(async (item, index) => {
          if (item.Formats) item.Formats = JSON.stringify(item.Formats);
          if (index !== 0) {
            for (const key in this.formData[0]) {
              if (
                (!item[key] && item[key] != "BuildingCode") ||
                (key == "Formats" && item[key] == "{}")
              )
                item[key] = this.formData[0][key];
            }
          }
          let flag = await this.saveData({
            ...item,
            ProjectId: this.$route.query.id,
          });
          if (flag) arr.push(index + 1);
        });
      let str = arr.join(",");
      if (arr.length > 0) this.$message.error(str + "未能保存成功");
      else this.$message.success("操作成功");

      this.dialogVisible = false;
      console.log('打印结果旁边的this.formData', this.formData)
      setTimeout(() => {
        this.formData = [];
        this.getDataList();
      }, 200);
    },
    getYeTaiList() {
      this.$http.post("/Base_ProjectManage/Project_DictionaryMain/GetTreeDataListByDicKey?DicKey=BuildingFormats")
        .then((res) => {
          this.YetaiList = res.Data;
        });
    },
    getDataList() {
      this.$http.post("/Base_ProjectManage/Project_Building/GetDataList", {
        Search: {
          Condition: "ProjectId",
          Keyword: this.$route.query.id,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.Success) {
            if (res.Data) {
              res.Data.forEach((item) => {
                if (item.Formats) {
                  item.Formats = JSON.parse(item.Formats);
                  item.yetai = [];
                  for (const key in item.Formats) {
                    item.yetai.push(key);
                  }
                  item.yetaiShow = true;
                }
              });
            }
            this.data = res.Data;
          }
        });
    },
    async saveData(obj) {
      let flag = false;
      await this.$http
        .post("/Base_ProjectManage/Project_Building/SaveData", obj)
        .then((res) => {
          console.log('获取saveData接口结果数据', res)
          if (res.Success) flag = false;
          else flag = true;
        });
      return flag;
    },
  },
};
</script>

<style lang="scss" scoped>
/*覆盖element表格的表头字体样式*/
::v-deep.el-table {
  .el-table__header-wrapper {
    .el-table__header {
      .el-table__cell {
        font-weight: normal;

      }
    }
  }

  .el-table__body-wrapper {
    .el-table__body {
      .el-table__cell {
        padding: 10px 0 !important;

      }
    }
  }


}

.list_column {
  text-align: right;
  margin-right: 20px;

}

::v-deep .el-drawer__header {
  font-size: 20px !important;
  /* 修改字体大小 */
  text-align: center !important;
  /* 修改文字居中对齐 */
  margin-bottom: 20px !important;
  /* 修改标题下外边距 */
}

::v-deep .el-drawer__body {
  margin: 0 10px 10px;
}

.louDon {
  display: flex;
  flex-wrap: wrap;

  .addLouDon {
    display: block;
    border: 2px dashed #ccc;
    text-align: center;
    line-height: 90px;
    font-size: 40px;
    color: #999;

    &:hover {
      border-color: #d1100b;
      color: #d1100b;
    }
  }
}


.form {
  padding: 0 30px 60px;
  background: #fff;
  position: relative;
  overflow-y: scroll;


  ::v-deep .el-collapse,
  .el-collapse-item__wrap {
    border: none !important;
  }

  ::v-deep .el-card__body:first-child {
    padding: 5px 20px !important;
  }

  // ::v-deep .el-collapse-item__header {
  //   border-bottom: none !important;
  // }

  ::v-deep .el-collapse-item__wrap {
    border-bottom: none !important;

    .el-collapse-item__content {
      padding-bottom: 10px;
    }
  }

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    /* 滚动条里面小方块 */
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e0e0e0;
  }

  &::-webkit-scrollbar-track {
    /* 滚动条里面轨道 */
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    // border-radius: 10px;
    background: transparent;
  }

  h4 {
    margin: 0;
    margin-bottom: 10px;
    padding-left: 5px;
  }

  .textAlignRight {
    text-align: left;
    line-height: 40px;

  }
}

.buttonGroup {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  margin: 0;
  padding: 10px;
  border-top: 1px solid #ccc;
  width: 100%;
}
</style>
