<template>
    <div>
        <el-drawer :visible.sync="drawer" direction="rtl" size="100%" :with-header="false">
            <el-row style="padding: 10px;text-align: right;margin-top: 20px;">

                <el-button type="primary" style="margin-right: 20px;" size="small"
                    @click="getEXPortName">导出&nbsp;Excel</el-button>
                <el-button type="primary" style="margin-right: 20px;" size="small" @click="printChange('123')">
                    打印</el-button>
                <el-button type="primary" style="margin-right: 20px;" size="small"
                    @click="drawer = false">返回</el-button>

            </el-row>
            <div style="padding: 10px;">
                <el-table :data="data" border :span-method="objectSpanMethod" empty-text="暂无数据" ref="tableApproval"
                    Id="tableApproval" v-if="isTask">
                    <el-table-column prop="sort" label="序号" align="center" width="50">
                    </el-table-column>
                    <el-table-column prop="ApprovalCode" :label="ApprovalType === '1' ? '设备/材料报审单编号' : '深化图纸报审单编号'"
                        align="left" header-align="center"></el-table-column>
                    <el-table-column prop="ApprovalName" :label="ApprovalType === '1' ? '设备/材料报审单名称' : '深化图纸报审单名称'"
                        align="left" header-align="center"></el-table-column>
                    <el-table-column prop="ApprovalBatch" label="报审批次" align="center" width="100">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ApprovalBatch">第 <span
                                    style="color: red;font-weight: bold;font-size: 14px;">{{ scope.row.ApprovalBatch
                                    }}</span>
                                批次</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ApprovalDateTime" label="报审时间" align="center" width="150">
                        <template slot-scope="scope">
                            {{ scope.row.CreateTime ? scope.row.CreateTime.substring(0, 10) : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="ApprovalDateTime" label="审批时间" align="center" width="150">
                        <template slot-scope="scope">
                            {{ scope.row.ApprovalDateTime ? scope.row.ApprovalDateTime.substring(0, 10) : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="审核结果" align="center">
                        <el-table-column prop="ApprovalUserRole" label="角色" align="center" width="100" v-if="isRole">

                        </el-table-column>
                        <el-table-column prop="ApprovalEvaluate" label="评价" align="center" width="100">
                            <template slot-scope="scope">
                                <span
                                    :style="`color:${(scope.row.ApprovalEvaluate === 'B' || scope.row.ApprovalEvaluate === 'A') ? '#67C23A' : '#D1100B'};font-weight:bold;font-size:16px`">{{
                                        scope.row.ApprovalEvaluate }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ApprovalAnnotation" label="意见" align="left"
                            header-align="center"></el-table-column>

                    </el-table-column>
                    <el-table-column prop="ApprovalARemark" label="备注" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ApprovalStatus == 0" style="color: red;"> 未审核或暂存 </span>
                            <span v-else>{{ scope.row.ApprovalARemark }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table :data="data" border :span-method="objectSpanMethod" empty-text="暂无数据" ref="tableApproval"
                    Id="tableApproval" v-if="!isTask">
                    <el-table-column prop="sort" label="序号" align="center" width="50">
                    </el-table-column>

                    <el-table-column prop="ApprovalCode" :label="ApprovalType === '1' ? '设备/材料报审单编号' : '深化图纸报审单编号'"
                        align="left" header-align="center"></el-table-column>
                    <el-table-column prop="ApprovalName" :label="ApprovalType === '1' ? '设备/材料报审单名称' : '深化图纸报审单名称'"
                        align="left" header-align="center"></el-table-column>
                    <el-table-column prop="TaskName" label="任务名称" align="left" header-align="center"></el-table-column>

                    <el-table-column prop="ApprovalMajor" label="专业" align="center"></el-table-column>
                    <el-table-column prop="ApprovalBatch" label="报审批次" align="center" width="100">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ApprovalBatch">第 <span
                                    style="color: red;font-weight: bold;font-size: 14px;">{{ scope.row.ApprovalBatch
                                    }}</span>
                                批次</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ApprovalDateTime" label="报审时间" align="center" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.CreateTime ? scope.row.CreateTime.substring(0, 10) : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="ApprovalDateTime" label="审批时间" align="center" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.SignUpdateTime ? scope.row.SignUpdateTime.substring(0, 10) : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="审核结果" align="center">
                        <el-table-column align="center" width="50" v-for="item in ColumnData"
                            :key="item.OrganizationKey" :label="item.OrganizationName" :prop="item.OrganizationKey">
                            <template slot-scope="scope">
                                <span
                                    :style="`color:${(scope.row[item.OrganizationKey] === 'B' || scope.row[item.OrganizationKey] === 'A') ? '#67C23A' : '#D1100B'};font-weight:bold;font-size:16px`">{{
                                        scope.row[item.OrganizationKey] }}</span>
                            </template>
                        </el-table-column>


                    </el-table-column>
                    <el-table-column prop="ApprovalARemark" label="备注" align="center">
                        <template slot-scope="scope">
                            <!-- <span v-if="scope.row.ApprovalStatus == 0" style="color: red;"> 未审核或暂存 </span>
                            <span v-else>{{ scope.row.ApprovalARemark }}</span> -->
                            <span style="font-size: 12px;color: #F56C6C;" v-if="scope.row.Status == 0">未提审
                            </span>
                            <span type="success" style="font-size: 12px;color: #67C23A;" v-if="scope.row.Status == 1">{{
                                item.ApprovalBatch == 1 ? '已提审' : '再提审' }}
                            </span>
                            <span style="font-size: 12px;color: #909399;" type="info" v-if="scope.row.Status == 2">审核中
                            </span>
                            <span style="font-size: 12px;color: #F56C6C;" type="danger" v-if="scope.row.Status == 3">已驳回
                            </span>
                            <span style="font-size: 12px;color: #67C23A;" type="success"
                                v-if="scope.row.Status == 4">已通过
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

        </el-drawer>

        <div class="print-container">

        </div>
    </div>
</template>
<script>
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default {
    data() {
        return {
            print: {
                id: 'tableApproval',
                popTitle: '打印', // 打印配置页上方标题
                extraHead: '', //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
                preview: '', // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
                previewTitle: '', // 打印预览的标题（开启预览模式后出现）,
                previewPrintBtnLabel: '', // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
                zIndex: '', // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）   
                previewBeforeOpenCallback() { }, //预览窗口打开之前的callback（开启预览模式调用）
                previewOpenCallback() { }, // 预览窗口打开之后的callback（开启预览模式调用）
                beforeOpenCallback() { }, // 开启打印前的回调事件
                openCallback() { }, // 调用打印之后的回调事件
                closeCallback() { }, //关闭打印的回调事件（无法确定点击的是确认还是取消）
                url: '',
                standard: '',
                extraCss: '',
            },
            ApprovalType: '',
            data: [],
            drawer: false,
            isTask: false,
            isRole: false,
            ColumnData: [],
        };
    },
    props: {},
    components: {},
    methods: {
        MergeCol(TableData, itemName, rowIndex) {
            var idName = TableData[rowIndex][itemName]; // 获取当前单元格的值
            // 合并单元格
            // id：属性名
            // rowIndex：行索引值

            if (rowIndex > 0) {
                // 判断是不是第一行
                // eslint-disable-next-line eqeqeq
                if (TableData[rowIndex][itemName] != TableData[rowIndex - 1][itemName]) {
                    // 先判断当前单元格的值是不是和上一行的值相等
                    var i = rowIndex;
                    var num = 0; // 定义一个变量i，用于记录行索引值并进行循环，num用于计数
                    while (i < TableData.length) {
                        // 当索引值小于table的数组长度时，循环执行
                        if (TableData[i][itemName] === idName) {
                            // 判断循环的单元格的值是不是和当前行的值相等
                            i++; // 如果相等，则索引值加1
                            num++; // 合并的num计数加1
                        } else {
                            i = TableData.length; // 如果不相等，将索引值设置为table的数组长度，跳出循环
                        }
                    }


                    return {
                        rowspan: num, // 最终将合并的行数返回
                        colspan: 1,
                    };
                } else {
                    return {
                        rowspan: 0, // 如果相等，则将rowspan设置为0
                        colspan: 1,
                    };
                }
            } else {
                // 如果是第一行，则直接返回
                let i = rowIndex;
                let num = 0;
                while (i < TableData.length) {
                    // 当索引值小于table的数组长度时，循环执行
                    if (TableData[i][itemName] === idName) {
                        i++;
                        num++;
                    } else {
                        i = TableData.length;
                    }
                }


                return {
                    rowspan: num,
                    colspan: 1,
                };
            }

        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            let data = this.data
            if (columnIndex == 2) {
                return this.MergeCol(data, 'ApprovalName', rowIndex)
            } else if (columnIndex == 1) {
                return this.MergeCol(data, 'ApprovalCode', rowIndex)
            } else if (columnIndex == 3) {
                return this.MergeCol(data, 'TaskName', rowIndex)
            } else if (columnIndex == 0) {
                return this.MergeCol(data, 'sort', rowIndex)
            }


        },
        printChange() {
            this.$print(this.$refs.tableApproval)
        },
        getEXPortName() {
            this.$prompt('请输入导出名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                inputValue: this.ApprovalType == '1' ? '设备/材料台账' : '深化图纸台账',
            }).then(({ value }) => {
                this.exportChange(value)
            })
        },
        exportChange(excelName) {
            try {
                const $e = this.$refs["tableApproval"].$el;
                let $table = $e
                // if (!$table) {
                //     $table = $e;
                // }

                const wb = XLSX.utils.table_to_book($table, { raw: true });
                const wbout = XLSX.write(wb, {
                    bookType: "xlsx",
                    bookSST: true,
                    type: "array",
                });
                FileSaver.saveAs(
                    new Blob([wbout], { type: "application/octet-stream" }),
                    `${excelName}.xlsx`
                );
            } catch (e) {
                if (typeof console !== "undefined") console.error(e);
            }
        },
        openDrawer(data, ApprovalType, isTask, ColumnData, isRole) {
            this.data = data
            this.ApprovalType = ApprovalType
            console.log(this.ApprovalType, 'this.ApprovalTypethis.ApprovalTypethis.ApprovalTypethis.ApprovalTypethis.ApprovalType')
            this.drawer = true;
            this.isTask = isTask
            this.isRole = isRole
            if (isTask) {
                this.ColumnData = []
            } else {
                this.ColumnData = ColumnData
            }
        },
        closeDrawer() {
            this.drawer = false;
        }
    },
    mounted() { },
    created() { },
    computed: {},
    watch: {},
};
</script>
<style lang='scss' scoped></style>