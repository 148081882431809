<template>
  <!--  消防部分-->
  <div>
    <!--    空调（提供/不提供）-->
    <div class="flex" style="border: 2px solid #000">
      <div class="flex8 flexCol">
        <!--              顶部备注区域布局站位-->
        <div class="flex" style="height: 22px">
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
          <div class="flex flex5">
            <div class="flex5"></div>
            <div class="flex5"></div>
          </div>
        </div>
        <!--        内容区域 这里的height 30px 和下拉列表数据点额高度一致 否则回出现高度不一致情况-->
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 flexAcneter borderTop font16">
              <span> 户内消火栓</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="IndoorFire"
                :type="'select'"
                :options="dianOtions"
                @change="inputSelectChange($event, 'IndoorFireSelectValue')"
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 flexAcneter borderTop font16">
              <span> 户内消火栓数量</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="IndoorFireNumberInput"
                :suffixText="'个'"
                :type="'input'"
                @input="inputChange($event, 'IndoorFireNumber')"
              ></InputComponent>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 flexAcneter borderTop font16">
              <span> 喷淋系统</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="SpraySystem"
                :type="'select'"
                :options="dianOtions"
                @change="inputSelectChange($event, 'SpraySystemSelectValue')"
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 flexAcneter borderTop font16">
              <span> 烟感报警系统</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="SmokeDetectionAlarmSystem"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange(
                    $event,
                    'SmokeDetectionAlarmSystemSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex5">
            <div class="flex5 flexAcneter borderTop borderBottom font16">
              <span>消防排烟入户管道</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="FireSmokeExhaustInletPipe"
                :type="'select'"
                :options="dianOtions"
                @change="
                  inputSelectChange(
                    $event,
                    'FireSmokeExhaustInletPipeSelectValue'
                  )
                "
              ></InputComponent>
            </div>
          </div>
          <div class="flex flex5">
            <div class="flex5 flexAcneter borderTop borderBottom font16">
              <span>消防排烟总风量</span>
            </div>
            <div class="flex5">
              <InputComponent
                ref="TotalAirVolumeOfFireSmokeExhaustInput"
                :suffixText="'m³/h'"
                :type="'input'"
                @input="inputChange($event, 'TotalAirVolumeOfFireSmokeExhaust')"
              ></InputComponent>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="text-center font16">备注</div>
        <!--        户内消火栓（提供/不提供） 备注-->
        <div>
          <InputComponent
            ref="RemarkIndoorFireInput"
            :placeHolder="'请输入备注'"
            :rows="1"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkIndoorFire')"
          ></InputComponent>
          <!--  喷淋系统（提供/不提供） 备注-->
          <InputComponent
            ref="RemarkSpraySystemInput"
            :placeHolder="'请输入备注'"
            :rows="1"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkSpraySystem')"
          ></InputComponent>

          <!--          消防排烟入户管道（提供/不提供）备注-->
          <InputComponent
            ref="RemarkFireSmokeExhaustInletPipeInput"
            :placeHolder="'请输入备注'"
            :rows="1"
            :type="'textarea'"
            @input="inputChange($event, 'RemarkFireSmokeExhaustInletPipe')"
          ></InputComponent>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputComponent from "@/views/Project/view/components/inputComponents/inputComponent.vue";
// 模拟数据
import {
  dianbiaoOptions,
  dianbiaoWeizhiOptions,
  kongtiaoguanzhiOptions,
} from "../../mock.js";
export default {
  props: {
    parentObj: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  components: { InputComponent },
  data() {
    return {
      // 输入框绑定的值
      selectValue: "提供",
      // 电表区域备注绑定
      RemarkElectrical: "",
      // 空调管制绑定
      kongtiaoguanzhiSelectValue: "两管",
      // 电表下拉列框绑定列别
      dianOtions: dianbiaoOptions,
    };
  },
  methods: {
    input(row) {
      console.log("消防组件input函数返回", row);
      // 判断相关备注和下拉提供非提供逻辑  如果没有对应的数组  提供  否则 不提供
      this.$refs.IndoorFire.value = "";
      this.$refs.SpraySystem.value = "";
      this.$refs.SmokeDetectionAlarmSystem.value = "";
      this.$refs.FireSmokeExhaustInletPipe.value = "";
      this.$refs.IndoorFire.value = row.IndoorFireSelectValue; //户内消火栓（提供/不提供）
      this.$refs.SpraySystem.value = row.SpraySystemSelectValue; // 喷淋系统提供非提供
      this.$refs.SmokeDetectionAlarmSystem.value =
        row.SmokeDetectionAlarmSystemSelectValue; //烟感报警系统提供非提供
      this.$refs.FireSmokeExhaustInletPipe.value =
        row.FireSmokeExhaustInletPipeSelectValue; //消防排烟入户管道（提供/不提供）

      this.$refs.RemarkIndoorFireInput.keyWords = row.RemarkIndoorFire; //户内消火栓（提供/不提供） 备注
      this.$refs.RemarkSpraySystemInput.keyWords = row.RemarkSpraySystem; //喷淋系统（提供/不提供）备注
      this.$refs.RemarkFireSmokeExhaustInletPipeInput.keyWords =
        row.RemarkFireSmokeExhaustInletPipe; // 消防排烟入户管道（提供/不提供）备注-
      this.$refs.IndoorFireNumberInput.keyWords = row.IndoorFireNumber; //户内消火栓数量
      this.$refs.TotalAirVolumeOfFireSmokeExhaustInput.keyWords =
        row.TotalAirVolumeOfFireSmokeExhaust; //消防排烟总风量备注
    },
    // 相关下拉参数处理回调
    inputSelectChange(event, type) {
      this.parentObj.openSetPrintSelectValue(event, type);
    },
    // 相关备注参数处理回调
    inputChange(event, type) {
      this.parentObj.openSetPrintInputValue(event, type);
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}
.flex5 {
  flex: 5;
}
.flex3 {
  flex: 3;
}
/*覆盖card样式*/
.el-card {
  border: none !important;
}
/*取消card的阴影*/
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}

/*覆盖element card 卡片样式*/
::v-deep .recordContent {
  .el-card {
    .el-card__body {
      padding: 0px !important;
    }
  }
}
</style>
